import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as asyncStates from "../../constants/asyncStates";
import {
  createQualificationApiService,
  deleteQualificationApiService,
  getAllQualificationApiService,
  restoreArchivedQualificationApiService,
  updateQualificationApiService,
} from "../../../services/applicationBackendApis/qualifications";
import { formateDateToISOString } from "../../../utiles/dateformate";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allQualification: [],
  singleQualification: null,
  moreQualificationItemsLeftInPagination: false,
  qualificationUploadPercentage: null,
  errorMessages: {
    restoreQualificationErrorMessage: null,
    createQualificationErrorMessage: null,
    deleteQualificationErrorMessage: null,
    updateQualificationErrorMessage: null,
    getAllQualificationErrorMessage: null,
    getSingleQualificationErrorMessage: null,
  },
  statuses: {
    restoreQualificationStatus: null,
    createQualificationStatus: null,
    deleteQualificationStatus: null,
    updateQualificationStatus: null,
    getAllQualificationStatus: null,
    getSingleQualificationStatus: null,
  },
};

//---------------- to get all the qualification from database ------------
export const getAllQualificationReducer = createAsyncThunk(
  "qualification/getAllQualification",
  async (qualificationData) => {
    try {
      const response = await getAllQualificationApiService({
        staffId: qualificationData.staffId,
        startAfter: qualificationData.startAfter,
        limit: qualificationData.limit ?? PAGINATION_LIMITS.QUALIFICATION,
        isArchived: qualificationData.isArchived,
      });
      return {
        data: response.data,
        limit: qualificationData.limit ?? PAGINATION_LIMITS.QUALIFICATION,
        startAfter: qualificationData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// ------------create compilance ------------------
export const createQualificationReducer = createAsyncThunk(
  "qualification/createQualification",
  async (qualificationData, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      const response = await createQualificationApiService({
        staffId: qualificationData.staffId,
        name: qualificationData.name,
        grade: qualificationData.grade,
        date: qualificationData.date ? formateDateToISOString(qualificationData.date) : null,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const restoreArchivedQualificationReducer = createAsyncThunk(
  "qualification/restoreArchivedQualification",
  async (qualificationData,) => {
    try {
      const response = await restoreArchivedQualificationApiService({
        staffId: qualificationData.staffId,
        id: qualificationData.qualificationId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------deleting qualification ---------------------
export const deleteQualificationReducer = createAsyncThunk(
  "qualification/deleteQualification",
  async (qualificationData) => {
    try {
      const response = await deleteQualificationApiService({
        staffId: qualificationData.staffId,
        qualificationId: qualificationData.qualificationId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------updating qualification ---------------------
export const updateQualificationReducer = createAsyncThunk(
  "qualification/updateQualification",
  async (qualificationData) => {
    try {
      const response = await updateQualificationApiService({
        staffId: qualificationData.staffId,
        qualificationId: qualificationData.qualificationId,
        name: qualificationData.name,
        name: qualificationData.name,
        grade: qualificationData.grade,
        date: qualificationData.date ? formateDateToISOString(qualificationData.date) : null,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const qualificationSlice = createSlice({
  name: "files",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    qualificationUploadPercentageUpdatedReducer: (state, action) => {
      state.qualificationUploadPercentage = action.payload;
    },
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalQualificationsReducer: (state, action) => {
      state.allQualification = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling get files async calls
    builder.addCase(getAllQualificationReducer.pending, (state, action) => {
      state.statuses.getAllQualificationStatus = asyncStates.PENDING;
      state.errorMessages.getAllQualificationErrorMessage = null;
    });
    builder.addCase(getAllQualificationReducer.rejected, (state, action) => {
      state.statuses.getAllQualificationStatus = asyncStates.FAILURE;
      state.errorMessages.getAllQualificationErrorMessage = action.error?.message;
    });
    builder.addCase(getAllQualificationReducer.fulfilled, (state, action) => {
      state.statuses.getAllQualificationStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllQualificationErrorMessage = null;
      state.allQualification = action.payload.startAfter ? [...state.allQualification, ...action.payload.data.qualifications] : action.payload.data.qualifications;

      if (action.payload.data.qualifications.length >= action.payload.limit) {
        state.moreQualificationItemsLeftInPagination = true;
      } else {
        state.moreQualificationItemsLeftInPagination = false;
      }
    });

    // start handling create file async calls
    builder.addCase(createQualificationReducer.pending, (state, action) => {
      state.statuses.createQualificationStatus = asyncStates.PENDING;
      state.errorMessages.createQualificationErrorMessage = null;
    });
    builder.addCase(createQualificationReducer.rejected, (state, action) => {
      state.statuses.createQualificationStatus = asyncStates.FAILURE;
      state.errorMessages.createQualificationErrorMessage = action.error?.message;
    });
    builder.addCase(createQualificationReducer.fulfilled, (state, action) => {
      state.statuses.createQualificationStatus = asyncStates.SUCCESS;
      state.errorMessages.createQualificationErrorMessage = null;
    });

    builder.addCase(restoreArchivedQualificationReducer.pending, (state, action) => {
      state.statuses.restoreQualificationStatus = asyncStates.PENDING;
      state.errorMessages.restoreQualificationErrorMessage = null;
    });
    builder.addCase(restoreArchivedQualificationReducer.rejected, (state, action) => {
      state.statuses.restoreQualificationStatus = asyncStates.FAILURE;
      state.errorMessages.restoreQualificationErrorMessage =
        action.error?.message;
    });
    builder.addCase(restoreArchivedQualificationReducer.fulfilled, (state, action) => {
      state.statuses.restoreQualificationStatus = asyncStates.SUCCESS;
      state.errorMessages.restoreQualificationErrorMessage = null;
    });

    // start handling delete file async calls
    builder.addCase(deleteQualificationReducer.pending, (state, action) => {
      state.statuses.deleteQualificationStatus = asyncStates.PENDING;
      state.errorMessages.deleteQualificationErrorMessage = null;
    });
    builder.addCase(deleteQualificationReducer.rejected, (state, action) => {
      state.statuses.deleteQualificationStatus = asyncStates.FAILURE;
      state.errorMessages.deleteQualificationErrorMessage = action.error?.message;
    });
    builder.addCase(deleteQualificationReducer.fulfilled, (state, action) => {
      state.statuses.deleteQualificationStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteQualificationErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateQualificationReducer.pending, (state, action) => {
      state.statuses.updateQualificationStatus = asyncStates.PENDING;
      state.errorMessages.updateQualificationErrorMessage = null;
    });
    builder.addCase(updateQualificationReducer.rejected, (state, action) => {
      state.statuses.updateQualificationStatus = asyncStates.FAILURE;
      state.errorMessages.updateQualificationErrorMessage = action.error?.message;
    });
    builder.addCase(updateQualificationReducer.fulfilled, (state, action) => {
      state.statuses.updateQualificationStatus = asyncStates.SUCCESS;
      state.errorMessages.updateQualificationErrorMessage = null;
    });
  },
});

export const {
  qualificationUploadPercentageUpdatedReducer,
  clearAllLocalQualificationsReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
} = qualificationSlice.actions;

export default qualificationSlice.reducer;
