import { useSelector } from "react-redux"

export const useTeamsSelector = () => useSelector((state) => {
    return state.teams.teams
})

export const useFilterTeamsSelector = () => useSelector((state) => {
    const filtered = state.teams.filterTeams ?? [];
    // if (!filtered || filtered.length <= 0) {
    //     return state.teams.teams ?? []
    // }

    return filtered;
})

export const useTeamSelector = (teamId) => useSelector((state) => {
    return state.teams.teams.find(team => team.id === teamId)
})

// ------------------- selector error messages ------------------ //
export const useTeamCreateErrorMessageSelector = () => useSelector((state) => {
    return state.teams.errorMessages.createTeamErrorMessage
})

export const useTeamDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.teams.errorMessages.deleteTeamErrorMessage
})

export const useTeamUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.teams.errorMessages.updateTeamErrorMessage
})

export const useTeamsGetErrorMessageSelector = () => useSelector((state) => {
    return state.teams.errorMessages.getTeamsErrorMessage
})

export const useTeamGetErrorMessageSelector = () => useSelector((state) => {
    return state.teams.errorMessages.getTeamErrorMessage
})

// ------------ status selectors ------------ //
export const useTeamCreateStatusSelector = () => useSelector((state) => {
    return state.teams.statuses.createTeamStatus
})

export const useTeamDeleteStatusSelector = () => useSelector((state) => {
    return state.teams.statuses.deleteTeamStatus
})

export const useTeamUpdateStatusSelector = () => useSelector((state) => {
    return state.teams.statuses.updateTeamStatus
})

export const useTeamsGetStatusSelector = () => useSelector((state) => {
    return state.teams.statuses.getTeamsStatus
})

export const useTeamGetStatusSelector = () => useSelector((state) => {
    return state.teams.statuses.getTeamStatus
})
