import { useSelector } from "react-redux"

export const useDiariesSelector = () => useSelector((state) => {
    return state.staffDiary.diaries
})

// ------------------- selector error messages ------------------ //
export const useDiaryCreateErrorMessageSelector = () => useSelector((state) => {
    return state.staffDiary.errorMessages.createDiaryErrorMessage
})

export const useDiaryDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.staffDiary.errorMessages.deleteDiaryErrorMessage
})

export const useDiaryUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.staffDiary.errorMessages.updateDiaryErrorMessage
})

export const useDiariesGetErrorMessageSelector = () => useSelector((state) => {
    return state.staffDiary.errorMessages.getDiariesErrorMessage
})

export const useDiaryGetErrorMessageSelector = () => useSelector((state) => {
    return state.staffDiary.errorMessages.getDiaryErrorMessage
})

// ------------ status selectors ------------ //
export const useDiaryCreateStatusSelector = () => useSelector((state) => {
    return state.staffDiary.statuses.createDiaryStatus
})

export const useDiaryDeleteStatusSelector = () => useSelector((state) => {
    return state.staffDiary.statuses.deleteDiaryStatus
})

export const useDiaryUpdateStatusSelector = () => useSelector((state) => {
    return state.staffDiary.statuses.updateDiaryStatus
})

export const useDiariesGetStatusSelector = () => useSelector((state) => {
    return state.staffDiary.statuses.getDiariesStatus
})

export const useDiaryGetStatusSelector = () => useSelector((state) => {
    return state.staffDiary.statuses.getDiaryStatus
})

export const useMoreDirayItemsLeftInPaginationSelector = () => useSelector((state) => {
    return state.staffDiary.moreDiaryItemsLeftInPagination
})