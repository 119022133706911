import React, { useMemo, useState } from "react";
import RightSideBar from "./RightSideBar";
import { Box, FlexBox } from "../../Components/Boxes";
import Clients from "./Clients/Clients";
import Staff from "./Staff/Staff";
import { useResponsive } from "../../Components/Hooks/index";
import Tabs from "../../Components/Input/Tabs";
import GridContainer from "../../Components/Grid/GridContainer";
import Grid from "../../Components/Grid/Grid";
import usePermissionsController from "../../permissions";
import TimeOff from "./TimeOff";

const Team = ({ defaultTab = "Clients" }) => {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const {
    accessTimeOffPage,
    accessStaffList,
    accessClientList,
  } = usePermissionsController()

  const tabOptions = useMemo(() => {
    const opts = []
    if (accessClientList)
      opts.push({ name: "Clients", path: "clients" })
    if (accessStaffList)
      opts.push({ name: "Staff", path: "staff" })
    if (accessTimeOffPage)
      opts.push({ name: "Time Off", path: "timeoff" })

    return opts
  }, [])
  const [tabIndex, setTabIndex] = useState(() => {
    return tabOptions.find((o) => o.name === defaultTab) ? tabOptions.findIndex((o) => o.name === defaultTab) : 0
  })

  const select = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  return (
    <GridContainer>
      <Grid $xs={12} $md={9}>
        <FlexBox
          $width={"100%"}
          $minHeight={"70vh"}
          $backgroundColor="#fff"
          $borderRadius="10px"
          $flexDirection="column"
        >
          <Tabs
            $onChanged={(val) => {
              setTabIndex(val)
              // append path
              const path = tabOptions[val].path
              window.history.pushState({}, "", `/team/${path}`)
            }}
            $value={tabIndex}
            $tabs={tabOptions}
          />

          <Box $marginTop={2} />

          {select === "Clients" && <Clients />}
          {select === "Staff" && <Staff />}
          {select === "Time Off" && accessTimeOffPage && <TimeOff />}
        </FlexBox>
      </Grid>
      <Grid $xs={12} $md={3}>
        <FlexBox
          $width={"100%"}
          $marginLeft={isDesktop ? 2 : 0}
          $marginTop={isDesktop ? 0 : 3}
        >
          <RightSideBar />
        </FlexBox>
      </Grid>
    </GridContainer>
  );
};

export default Team;
