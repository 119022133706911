import useStaffDispathers from "../../redux/dispatchers/useStaffDispatchers"
import useClientDispatchers from "../../redux/dispatchers/useClientDispatchers"
import { SelectBox } from "../../Components/Input"
import { useMemo } from "react"

export const SearchUserSelectBox = ({
    searchByStaff,
    searchByClient,
    disabled,
    selected,
    onChange,
}) => {
    const { getNewStaff } = useStaffDispathers()
    const { getAllClients } = useClientDispatchers()

    const usersSearched = (users, cb) => {
        cb(users ? users?.map(us => {
            return { value: us.id, label: us.name }
        }) : [])
    }

    return <SelectBox
        $disabled={disabled}
        $async
        $value={selected ? { value: selected.id, label: selected.name } : null}
        $onChange={(val) => {
            onChange({ id: val.value, name: val.label })
        }}
        $asyncOnLoad={(inputText, cb) => {
            if (searchByClient) {
                getAllClients({
                    query: inputText,
                    cb: (users) => { usersSearched(users, cb) },
                    limit: 5,
                })
            } else if (searchByStaff) {
                getNewStaff({
                    query: inputText,
                    cb: (users) => { usersSearched(users, cb) },
                    limit: 5,
                })
            }
        }}
    />
}

export const MultiSearchUserSelectBox = ({
    searchByStaff,
    searchByClient,
    disabled,
    selected,
    onChange,
    maxNumberOptionsEntered,
}) => {
    const { getNewStaff } = useStaffDispathers()
    const { getAllClients } = useClientDispatchers()

    const usersSearched = (users, cb) => {
        cb(users ? users?.map(us => {
            return { value: us.id, label: us.name }
        }) : [])
    }

    const mappedItems = useMemo(() => {
        return selected?.map(item => {
            return { value: item.id, label: item.name }
        }) ?? []
    }, [selected])

    return <SelectBox
        $isMulti
        $disabled={disabled}
        $async
        $value={mappedItems}
        $onChange={(val) => {
            const t = val?.map(item => {
                return { id: item.value, name: item.label }
            }) ?? []
            onChange(t)
        }}
        $isOptionsDisabled={() => {
            if (!maxNumberOptionsEntered) return false
            return mappedItems.length >= maxNumberOptionsEntered
        }}
        $asyncOnLoad={(inputText, cb) => {
            if (searchByClient) {
                getAllClients({
                    query: inputText,
                    cb: (users) => { usersSearched(users, cb) },
                    limit: 5,
                })
            } else if (searchByStaff) {
                getNewStaff({
                    query: inputText,
                    cb: (users) => { usersSearched(users, cb) },
                    limit: 5,
                })
            }
        }}
    />
}