import React, { useState } from "react";
import Icon from "../../Icons";
import moment from "moment";
import { useResponsive } from "../../Hooks";
import { FlexBox } from "../../Boxes";
import { REPETITION_PERIODS } from "../../../constants";

function CalendarControlls(props) {
  const { date, onPrev, onNext, onViewChanged, currentView, availableViews, hideViews } = props
  const isDesktop = useResponsive({ xs: false, md: true });

  let processedViews = [REPETITION_PERIODS.DAY]
  if (availableViews != null) {
    processedViews = [...availableViews]
  }

  return (
    <FlexBox $justifyContent="space-between" $width="100%">
      <div
        style={{
          width: "100%",
          height: "65px",
          display: "flex",
          flexDirection: "row",
          justifyContent: !hideViews ? "space-between" : "center",
          alignItems: "center",
          // padding: isDesktop ? "0 25px" : "3px",
        }}
      >
        {/* day and week buttons */}
        {!hideViews && <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {processedViews?.map(view => {
            return (
              <button
                onClick={() => onViewChanged(view)}
                style={{
                  width: isDesktop ? 116 : 50,
                  height: 36,
                  border: "1px solid #697A8D",
                  borderRadius: "10px 10px 10px 10px",
                  cursor: "pointer",
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: 400,
                  backgroundColor: currentView === view ? "#E6E6E6" : "#fff",
                  marginRight: 10,
                }}
              >
                {view == REPETITION_PERIODS.DAY ? "Day" : view == REPETITION_PERIODS.WEEK ? "Week" : view == REPETITION_PERIODS.MONTH ? "Month" : "Year"}
              </button>
            )
          })}
        </span>}

        {/* date with forward and backward buttons */}
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              width: isDesktop ? "41px" : "30px",
              height: "36px",
              border: "1px solid #697A8D",
              borderRadius: "10px 0px 0px 10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              onPrev()
            }}
          >
            <Icon $icon={"ChevronLeft"} $size="24px" />
          </button>
          <div
            style={{
              width: isDesktop ? "187px" : "175px",
              height: "36px",
              border: "1px solid #697A8D",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #111",
              backgroundColor: "#fff",
            }}
          >
            {moment(date).format(currentView == REPETITION_PERIODS.MONTH ? "MMMM YYYY" : currentView == REPETITION_PERIODS.DAY ? "ddd DD MMMM YYYY" : currentView == REPETITION_PERIODS.WEEK ? "ddd DD MMMM YYYY" : "YYYY")}
          </div>
          <button
            style={{
              width: isDesktop ? "41px" : "30px",
              height: "36px",
              border: "1px solid #697A8D",
              borderRadius: "0px 10px 10px 0px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              onNext();
            }}
          >
            <Icon $icon={"ChevronRight"} $size="24px" />
          </button>
        </span>
      </div>
    </FlexBox>
  );
}

export default CalendarControlls;
