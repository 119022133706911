import ApiService from "../../restapi";

// --------------- Services
export const getServicesConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/services`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveServicesConfigsApiService = async ({ services }) => {
  return await ApiService.sendRequest({
    url: `/admin/services`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      services: services
    }
  })
}

// --------------- Compliance
export const getComplianceConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/compliances`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveComplianceConfigsApiService = async ({ compliances }) => {
  return await ApiService.sendRequest({
    url: `/admin/compliances`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      compliances: compliances,
    }
  })
}

// --------------- Careplans
export const getCareplanConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/careplans`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveCareplanConfigsApiService = async ({ careplans }) => {
  return await ApiService.sendRequest({
    url: `/admin/careplans`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      careplans: careplans
    }
  })
}

// --------------- Policy
export const getPolicyConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/policy`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const savePolicyConfigsApiService = async ({ policy }) => {
  return await ApiService.sendRequest({
    url: `/admin/policy`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      policy: policy
    }
  })
}

// --------------- EventCheckLists
export const getEventCheckListConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/eventCheckLists`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveEventCheckListConfigsApiService = async ({ eventCheckLists }) => {
  return await ApiService.sendRequest({
    url: `/admin/eventCheckLists`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      eventCheckLists: eventCheckLists
    }
  })
}

// --------------- Riskassessments
export const getRiskassessmentConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/riskassessments`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveRiskassessmentConfigsApiService = async ({ riskassessments }) => {
  return await ApiService.sendRequest({
    url: `/admin/riskassessments`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      riskassessments: riskassessments,
    }
  })
}

// ---------------- Job roles
export const getJobRolesConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/jobRoles`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveJobRolesConfigsApiService = async ({ jobRoles }) => {
  return await ApiService.sendRequest({
    url: `/admin/jobRoles`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      jobRoles: jobRoles,
    }
  })
}

// ---------------- Job roles
export const getTimeOffApproversConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/timeOffApprovers`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveTimeOffApproversConfigsApiService = async ({ timeOffApprovers }) => {
  return await ApiService.sendRequest({
    url: `/admin/timeOffApprovers`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      timeOffApprovers: timeOffApprovers,
    }
  })
}

// --------------- Medication
export const getMedicationConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/medicines`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveMedicationConfigsApiService = async ({ medicines }) => {
  return await ApiService.sendRequest({
    url: `/admin/medicines`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      medicines: medicines,
    }
  })
}

// ---------------- Medication refual reasons
export const getMedicineRefusalReasonsConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/medicineRefusalReasons`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveMedicineRefusalReasonsConfigsApiService = async ({ medicineRefusalReasons }) => {
  return await ApiService.sendRequest({
    url: `/admin/medicineRefusalReasons`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      medicineRefusalReasons: medicineRefusalReasons,
    }
  })
}

// --------------- Custom forms
export const getAdditionalFormConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/admin/additionalForms`,
    query: {},
    method: "GET",
    headers: {},
  })
}

export const saveAdditionalFormConfigsApiService = async ({ additionalForms }) => {
  return await ApiService.sendRequest({
    url: `/admin/additionalForms`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      additionalForms: additionalForms,
    }
  })
}

// --------------- Default Qualificiations
export const getDefaultQualificationsConfigsApiService = async ({
  startAfter,
  limit,
}) => {
  return await ApiService.sendRequest({
    url: `/admin/defaultQualifications`,
    query: {
      startAfter,
      limit,
    },
    method: "GET",
    headers: {},
  })
}

export const createDefaultQualificationsConfigsApiService = async ({
  name,
  grade,
}) => {
  return await ApiService.sendRequest({
    url: `/admin/defaultQualifications`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      name: name,
      grade: grade,
    }
  })
}

export const updateDefaultQualificationsConfigsApiService = async ({
  id,
  name,
  grade,
}) => {
  return await ApiService.sendRequest({
    url: `/admin/defaultQualifications/${id}`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      name: name,
      grade: grade,
    }
  })
}

export const deleteDefaultQualificationsConfigsApiService = async ({
  id,
}) => {
  return await ApiService.sendRequest({
    url: `/admin/defaultQualifications/${id}`,
    query: {},
    method: "DELETE",
    headers: {},
  })
}

// --------------- Configs
export const getConfigsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/configs`,
    query: {},
    method: "GET",
    headers: {},
  })
}
