import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function BasicAlerts({ children, $type, }) {
  return (
    <Stack sx={{ width: '100%' }}>
      {/* for error severity = "error" and for success severity="success" */}
      <Alert severity={$type}>{children}</Alert>
    </Stack>
  );
}