
export const getTotalAmountOFObjectsMatchingKey = (list, key, value) => {
    return getObjectsMatchingKey(list, key, value).length
}

export const getObjectsMatchingKey = (list, key, value) => {
    return list.filter(item => item[key] === value)
}

export const mapObjectsMatchingKey = (list, key, value, func) => {
    return getObjectsMatchingKey(list, key, value).map(func)
}