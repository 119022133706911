import {
    DefaultSpacingModifiers,
    DefaultTextModifiers,
    DefaultSizeModifiers,
  } from "../Modifiers";
  import { color } from "../Theme";
  import styled from "styled-components";
  import { useResponsive } from "../Hooks"
  
  const TextWrapper = styled.span`
      ${(props) => props.$hightLight && `
        border-bottom: 5px solid ${color('primary')};
        border-radius: 10px;
        color: ${color('primary')} !important;
        font-weight: bold;
      `}
      :hover {
        ${(props) => props.$link && `
          border-bottom: 5px solid ${color('primary')};
          border-radius: 10px;
          color: ${color('primary')} !important;
          font-weight: bold;
          cursor: pointer;
        `}
      }
    
    `;
  
  export default function Text({
    $pointer,
    $color,
    $link,
    $textAlign,
    $hightLight,
    children,
    onClick,
    ...rest
  }) {
    return (
      <TextWrapper
        $link={$link == true}
        $hightLight={$hightLight == true}
        style={{
          ...DefaultTextModifiers(rest),
          ...DefaultSpacingModifiers(rest),
          ...DefaultSizeModifiers(rest),
          cursor: $pointer ? "pointer" : null,
          textAlign: $textAlign,
          color: color(useResponsive($color || "text")),
        }}
        onClick={onClick}
      >
        {children}
      </TextWrapper>
    );
  }