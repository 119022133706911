import { useDispatch } from "react-redux"
import {
    createPolicyReducer,
    deletePolicyReducer,
    getPoliciesReducer,
    getPolicyReducer,
    updatePolicyReducer,
    clearAllLocalPoliciesReducer,
    clearAllErrorMessagesReducer,
    restoreArchivedPolicyReducer,
} from "../../slices/policySlice"

const usePolicyDispathers = () => {
    const dispatch = useDispatch()

    const createPolicy = (data) => {
        return dispatch(createPolicyReducer(data))
    }

    const restoreArchivedPolicy = (data) => {
        return dispatch(restoreArchivedPolicyReducer(data))
    }

    const updatePolicy = (data) => {
        return dispatch(updatePolicyReducer(data))
    }

    const deletePolicy = (data) => {
        return dispatch(deletePolicyReducer(data))
    }

    const getPolicies = (data) => {
        return dispatch(getPoliciesReducer(data))
    }

    const getPolicy = (data) => {
        return dispatch(getPolicyReducer(data))
    }

    const clearAllLocalPolicies = (data) => {
        return dispatch(clearAllLocalPoliciesReducer(data))
    }

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data))
    }

    return {
        restoreArchivedPolicy,
        createPolicy,
        updatePolicy,
        deletePolicy,
        getPolicies,
        getPolicy,
        clearAllLocalPolicies,
        clearAllErrorMessages,
    }

}

export default usePolicyDispathers