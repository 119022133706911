
import ApiService from "../../restapi";
import { formateDateToISOString } from "../../../utiles/dateformate";

export const getAllMedicineApiService = async ({
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines`,
        query: {
            startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
};

export const getSingleMedicineApiService = async ({
    clientId,
    medicineId,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines/${medicineId}`,
        query: {},
        method: "GET",
        headers: {},
    });
};

export const createMedicineApiService = async ({
    clientId,
    startDate,
    endDate,
    repetitionEndDateType,
    notes,
    dosageInstructions,
    medicine,
    dosage,
    repetitionType,
    days,
    repetitionPeriod,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines`,
        query: {},
        method: "POST",
        headers: {},
        body: {
            startDate: startDate ? formateDateToISOString(startDate) : null,
            endDate: endDate ? formateDateToISOString(endDate) : null,
            repetitionEndDateType,
            notes,
            dosageInstructions,
            medicine,
            dosage,
            repetitionType,
            days,
            repetitionPeriod,
        },
    });
};

export const updateMedicineApiService = async ({
    clientId,
    medicineId,
    startDate,
    endDate,
    repetitionEndDateType,
    notes,
    dosageInstructions,
    medicine,
    dosage,
    repetitionType,
    days,
    repetitionPeriod,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines/${medicineId}`,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            startDate,
            endDate,
            repetitionEndDateType,
            notes,
            dosageInstructions,
            medicine,
            dosage,
            repetitionType,
            days,
            repetitionPeriod,
        },
    });
};

export const deleteMedicineApiService = async ({
    clientId,
    medicineId,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines/${medicineId}`,
        query: {},
        method: "DELETE",
        headers: {},
    });
};

export const restoreArchivedMedicineApiService = async ({
    clientId,
    id,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/medicines/${id}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    })
}