import React from 'react'
import { Box, CircleBox, FlexBox, ListBox } from '../../../Components/Boxes';
import { formateDateToReadableFormatWithoutTime, formateDateToTime } from '../../../utiles/dateformate';
import { Text } from '../../../Components/Text';
import { TIME_SHEET_STATUS, TIME_SHEET_TIME_AMMEND_STATUS } from '../../../constants';
import { getTimeSheetPdfApiService } from '../../../services/applicationBackendApis/timesheets';
import { useState } from 'react';
import CircularLoader from '../../../Components/Icons/circularLoader';
import Icon from '../../../Components/Icons';
import { color } from '../../../Components/Theme';

export default function TimesheetCard({
    clientId,
    staffId,
    timesheet,
    index,
    onClick,
}) {
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadTimehseetPdf = async () => {
        try {
            setIsDownloading(true);

            const response = await getTimeSheetPdfApiService({
                timesheetId: timesheet.id,
            });

            const timesheetPdf = response.data.base64File;

            // Download profile base 64 as pdf file
            const linkSource = `data:application/pdf;base64,${timesheetPdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `Timesheet_${timesheet.refId}.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        } catch (error) {
            console.log(error)
        }

        setIsDownloading(false);
    }

    const actionRequired = () => {
        if (timesheet?.timeAmmendmentRequested) {
            return timesheet?.timeAmmendmentRequest?.status == TIME_SHEET_TIME_AMMEND_STATUS.PENDING;
        }
    }

    return (
        <ListBox
            $width="100%"
            $marginTop={2}
            key={index}
            $index={index}
            $onClick={onClick}
            $position="relative"
            $border={actionRequired() ? `1px solid ${color("orange")}` : null}
        >
            {actionRequired() && <Box $top={-10} $left={-10} $position="absolute">
                <Icon
                    $icon="Warning"
                    $size="large"
                    $color="orange"
                />
            </Box>}

            <FlexBox $width="100%" >

                {!staffId && !clientId && <>

                    <Box $width="20%">
                        <Text $fontWeight="bold">
                            {timesheet?.staff?.name}
                        </Text>
                    </Box>
                    <Box $width="20%">
                        <Text $fontWeight="bold">
                            {timesheet?.client?.name}
                        </Text>
                    </Box>
                </>}

                {(clientId || staffId) && <Box $width="40%">
                    <Text $fontWeight="bold">
                        {clientId ? timesheet?.staff?.name : timesheet?.client?.name}
                    </Text>
                </Box>}
                <Box $width="15%" $display="flex" $justifyContent="center">
                    <Text
                        $fontWeight="bold"
                        $wordWrap="anywhere"
                    >
                        {formateDateToReadableFormatWithoutTime(timesheet.startDate)}
                    </Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text $fontWeight="bold" $wordWrap="anywhere">
                        {formateDateToTime(timesheet.startDate)}
                    </Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text $fontWeight="bold" $wordWrap="anywhere">
                        {formateDateToTime(timesheet.endDate)}
                    </Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text $fontWeight="bold" $wordWrap="anywhere">
                        {timesheet.breakDuration} mins
                    </Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <CircleBox
                        $size={10}
                        $backgroundColor={timesheet.timesheetStatus === TIME_SHEET_STATUS.ACCEPTED ? "green" : "orange"}
                        $color={timesheet.timesheetStatus === TIME_SHEET_STATUS.ACCEPTED ? "green" : "orange"}
                    />
                </Box>
                <FlexBox $width="5%" $display="flex" $justifyContent="center">
                    {/* <DropDownButton
                        $icon="MoreVert"
                        $sx={{ borderRadius: 2 }}
                        $marginRight={3}
                        $loading={isDownloading}
                    >
                        <DropDownMenuItem> */}
                    {!isDownloading && <Icon
                        $onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            downloadTimehseetPdf()
                        }}
                        $icon="Download"
                        $size="large"
                        $color="primary"
                    />}
                    {isDownloading && <CircularLoader $size={20} />}
                    {/* <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                                Download
                            </Text>
                        </DropDownMenuItem>
                    </DropDownButton> */}
                </FlexBox>
            </FlexBox>
        </ListBox>
    )
}
