import React from 'react'
import { CircleBox } from '../Boxes'
import Icon from '../Icons'
import DefaultImage from '.'
import CircularLoader from '../Icons/circularLoader'
import NoProfile from "../../Images/Profile/no_profile.png"
import { resizeImage } from '../../utiles/files'

export default function ProfilePhoto({
    $size,
    $url,
    $loading,
    $borderRadius = "100%",
    $onImageChanged,
    ...rest
}) {
    return (
        <CircleBox
            $width={$size ?? "50px"}
            $height={$size ?? "50px"}
            $borderRadius={$borderRadius}
            // $backgroundColor="#E6E6E6"
            $position="relative"
            {...rest}
        >
            {/* <label htmlFor="file-input"> */}
            {!$loading && !$url && <DefaultImage
                $width={"100%"}
                $height={"100%"}
                $overflow="hidden"
                // $src={"https://www.w3schools.com/howto/img_avatar.png"}
                $src={NoProfile}
                $borderRadius={$borderRadius}
                $objectFit={"cover"}
            />}
            {!$loading && $url && <DefaultImage
                $width={"100%"}
                $height={"100%"}
                $overflow="hidden"
                $src={$url}
                $borderRadius={$borderRadius}
            />}
            {$loading && <CircularLoader $color="primary" />}
            {/*  */}
            {!$loading && $onImageChanged && <label htmlFor="profile-img-upload" style={{ cursor: "pointer" }}>
                <Icon $position="absolute" $top="0" $right="0" $size="20px" $icon="AddPhotoAlternate" />
            </label>}

            <input
                id="profile-img-upload"
                type="file"
                accept="image/*"
                hidden
                onChange={async (e) => {
                    const file = await resizeImage(e.target.files[0])
                    if (file.size > 1000000) {
                        alert('This file is too big, please pick another')
                    } else {
                        // this.state.personalAccountData.id = URL.createObjectURL(e.target.files[0])
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = () => {
                            $onImageChanged(file, reader.result)
                        }
                    }
                }}
            />
        </CircleBox>
    )
}
