import React, { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FlexBox } from "../../Components/Boxes";
import Icon from "../../Components/Icons";
import styled from "styled-components";
import { spacing } from "../../Components/Theme/index";
import usePermissionsController from "../../permissions";

const BottomNavigation = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #ffffff;
  padding: ${spacing(3)} ${spacing(0)};
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const BottomNav = ({ selected, handleSelect }) => {
  const {
    accessWeb,
    accessHomePage,
    accessAnalyticsPage,
    accessTeamsPage,
    accessSchedulePage,
    accessSettingsPage,
    accessTimesheetsPage,
    accessSubscriptionPage,
  } = usePermissionsController()

  const pages = useMemo(() => {
    const p = []
    if (accessHomePage)
      p.push({ name: "Home", icon: "Home", href: "/" })
    if (accessTeamsPage)
      p.push({ name: "Team", icon: "Group", href: "/team" })
    if (accessSchedulePage)
      p.push({ name: "Schedule", icon: "AddTask", href: "/schedule" })
    if (accessTimesheetsPage)
      p.push({ name: "Timesheets", icon: "PointOfSale", href: "/timesheets" })
    if (accessSubscriptionPage)
      p.push({ name: "Subscription", icon: "Payment", href: "/subscription" })
    if (accessAnalyticsPage)
      p.push({ name: "Analytics", icon: "Analytics", href: "/analytics" })
    if (accessSettingsPage)
      p.push({ name: "Settings", icon: "Settings", href: "/settings" })

    return p
  }, [accessHomePage, accessWeb, accessAnalyticsPage, accessTeamsPage, accessSchedulePage, accessSettingsPage, accessTimesheetsPage])
  return (
    <BottomNavigation>
      {pages?.map((value, index) => {
        const highlight = value.href == "/" ? selected === "/" || selected === "" : selected.toLowerCase().startsWith(value.href.toLowerCase());
        return (
          <Link key={index} to={value.href} style={{ textDecoration: "none" }}>
            <FlexBox
              $width={"36px"}
              $height="42px"
              $flexDirection="row"
              $justifyContent="flex-start"
              $backgroundColor={highlight ? "primary-light" : "#fff"}
              $onClick={() => handleSelect(value.name)}
              $paddingLeft={2}
              $borderRadius={"10px"}
              $position="relative"
            >
              <Icon
                $icon={value.icon}
                $size="28px"
                $color={highlight ? "primary" : "#697A8D"}
              />
            </FlexBox>
          </Link>
        );
      })}
    </BottomNavigation>
  );
};

export default BottomNav;
