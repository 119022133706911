import ApiService from "../../restapi"

export const getEventsApiService = async ({
    startDateRange,
    endDateRange,
    clientId,
    staffId,
    limit,
    startAfter,
    staffUnassigned,
    clientUnassigned,
    isDistributePendingApproval,
    isClientVisit,
    isShift,
    isAvailability,
    isCustomEvent,
    isAbsense,
    sortBy,

}) => {
    let url = ""
    if (clientId) {
        url = `/clients/${clientId}/visits`
    } else if (staffId && !Array.isArray(staffId)) {
        url = `/staff/${staffId}/events`
    } else {
        url = `/events`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {
            startDateRange: startDateRange,
            endDateRange: endDateRange,
            clientId,
            staffId,
            limit,
            startAfter,
            staffUnassigned,
            clientUnassigned,
            isDistributePendingApproval,
            isClientVisit,
            isShift,
            isAvailability,
            isCustomEvent,
            isAbsense,
            sortBy,
        },
        method: "GET",
        headers: {},
    })
}

export const getEventApiService = async ({
    id,
    clientId,
    staffId,
}) => {
    let url = ""
    if (clientId) {
        url = `/clients/${clientId}/visits/${id}`
    } else if (staffId) {
        url = `/staff/${staffId}/events/${id}`
    } else {
        url = `/events/${id}`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "GET",
        headers: {},
    })
}

export const createEventApiService = async ({
    clientId,
    staffId,

    eventType,
    name, // only for custom events types
    isRepeating,
    startDate,
    endDate,
    distribute,

    services,
    flexibleStart,
    instructions,
    notice,
    additionalStaff,
    requiredNumStaff,

    address,
    repetition,
    breakDuration,
    jobRole,
    eventColor,
    requiredChecklistAcceptance,
}) => {
    let url = ""
    if (clientId) {
        url = `/clients/${clientId}/visits`
    } else if (staffId) {
        url = `/staff/${staffId}/events`
    }

    return await ApiService.sendRequest({
        url: url,
        query: {},
        body: {
            clientId,
            staffId,

            eventType,
            name, // only for custom events types
            isRepeating,
            startDate,
            endDate,
            distribute,

            services,
            flexibleStart,
            breakDuration,
            jobRole,
            instructions,
            notice,
            additionalStaff,
            requiredNumStaff,

            address: address ? {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                region: address.region,
                postcode: address.postcode,
                country: address.country,
            } : null,
            repetition: isRepeating ? {
                repetitionType: repetition.repetitionType,
                days: repetition.days,
                repetitionPeriod: repetition.repetitionPeriod ? {
                    value: repetition.repetitionPeriod.value,
                    every: repetition.repetitionPeriod.every
                } : null,
                repetitionStartDate: repetition.repetitionStartDate,
                repetitionEndDate: repetition.repetitionEndDate,
                repetitionEndDateType: repetition.repetitionEndDateType,
            } : null,
            eventColor: eventColor,
            requiredChecklistAcceptance: requiredChecklistAcceptance
        },
        method: "POST",
        headers: {},
    })
}

export const updateEventApiService = async ({
    id,
    clientId,
    staffId,

    eventType,
    name,
    isRepeating,
    startDate,
    endDate,
    distribute,
    onlyThisEvent,

    services,
    flexibleStart,
    breakDuration,
    jobRole,
    instructions,
    notice,
    additionalStaff,
    requiredNumStaff,

    address,
    repetition,
    eventColor,
    distributeStatus,
    requiredChecklistAcceptance
}) => {
    let url = ""
    if (clientId) {
        url = `/clients/${clientId}/visits/${id}`
    } else if (staffId) {
        url = `/staff/${staffId}/events/${id}`
    }

    return await ApiService.sendRequest({
        url: url,
        query: {},
        body: {
            clientId,
            staffId,

            eventType,
            name, // only for custom events types
            isRepeating,
            startDate,
            endDate,
            distribute,
            onlyThisEvent,

            services,
            flexibleStart,
            breakDuration,
            jobRole,
            instructions,
            notice,
            additionalStaff,
            requiredNumStaff,

            address: address ? {
                line1: address.line1,
                line2: address.line2,
                city: address.city,
                region: address.region,
                postcode: address.postcode,
                country: address.country,
            } : null,

            repetition: isRepeating ? {
                repetitionType: repetition.repetitionType,
                days: repetition.days,
                repetitionPeriod: repetition.repetitionPeriod ? {
                    value: repetition.repetitionPeriod.value,
                    every: repetition.repetitionPeriod.every
                } : null,
                repetitionEndDate: repetition.repetitionEndDate,
                repetitionEndDateType: repetition.repetitionEndDateType,
            } : null,
            eventColor: eventColor,
            distributeStatus:  distributeStatus,
            requiredChecklistAcceptance: requiredChecklistAcceptance,
        },
        method: "PUT",
        headers: {},
    })
}

export const deleteEventApiService = async ({
    id,
    clientId,
    staffId,
    cancel,
    cancellationReason,
    onlyThisEvent,
}) => {
    let url = ""

    if (clientId) {
        url = `/clients/${clientId}/visits/${id}`
    } else if (staffId) {
        url = `/staff/${staffId}/events/${id}`
    }

    if (cancel) {
        url += `/cancel`
    }

    const query = {}
    if (onlyThisEvent) {
        query.onlyThisEvent = true
    }

    return await ApiService.sendRequest({
        url: url,
        query: query,
        body: cancel ? {
            cancellationReason,
        } : {},
        method: cancel ? "PUT" : "DELETE",
        headers: {},
    })
}

export const autoPilotEventsApiService = async ({
    dateToCopyFrom,
    dateToCopyTo,
    numDaysToCopyToInSeries,
}) => {
    return await ApiService.sendRequest({
        url: `/events/schedule/autopilot`,
        body: {
            dateToCopyFrom,
            dateToCopyTo,
            numDaysToCopyToInSeries,
        },
        method: "PUT",
        headers: {},
    })
}