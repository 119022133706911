import React from "react";
import Address from "../../Components/BasicComponents/ProfileCardAddress";
import { Box, Card, CircleBox, FlexBox } from "../../Components/Boxes";
import Icon from "../../Components/Icons";
import { Text } from "../../Components/Text";
import ListBox from "../../Components/Boxes/ListBox";
import { color } from "../../Components/Theme";
import ProfilePhoto from "../../Components/Images/ProfilePhoto";

const StaffAndClientCard = ({ $value, $index, $type }) => {
  return (
    <ListBox
      $width="100%"
      $index={$index}
    >
      <FlexBox $width="100%" $justifyContent="flex-start">
        <FlexBox $width="15%">
          <ProfilePhoto $marginRight="2" $size="30px" $url={$value?.profilePhotoUrl}/>
          <Text $fontSize="body-sm" $wordWrap="anywhere">
            {$value?.name}
          </Text>
        </FlexBox>

        <Box $width="30%">
          <Text $fontSize="body-sm" $wordWrap="anywhere">
            {$value?.email}
          </Text>
        </Box>

        <Box $width="35%">
          {$type === "staff" ? (
            <FlexBox
              $backgroundColor={color("primary")}
              $padding={"2px"}
              $paddingLeft="8px"
              $paddingRight="8px"
              $marginTop="3px"
              $borderRadius="10px"
              $alignItems="center"
              $justifyContent="center"
              $width="fit-content"
            >
              <Text $color="white" $fontWeight={"bold"} $fontSize="body-sm">
                {$value?.role}
              </Text>
            </FlexBox>
          ) : (
            <Text $fontSize="body-sm" $wordWrap="anywhere">
              {/* <div style={{ display: "inline-block" }}> */}
                {$value?.address ? (
                  <Address data={$value?.address} />
                ) : (
                  "Not Provided"
                )}
              {/* </div> */}
            </Text>
          )}
        </Box>

        <Box $width="20%">
          <FlexBox
            $alignItems="flex-start"
            $flexWrap="wrap"
            $width="100%"
          >
            {$value?.teams?.length < 1 ? (
              <Text $fontSize="body-sm" $wordWrap="anywhere">
                Not Provided
              </Text>
            ) : (
              <>
                {$value?.teams?.map((team, index) => {
                  return (
                    <FlexBox
                      key={index}
                      $backgroundColor={color("primary")}
                      $padding={"2px"}
                      $paddingLeft="8px"
                      $paddingRight="8px"
                      $marginRight="2px"
                      $borderRadius="10px"
                      $alignItems="center"
                      $justifyContent="center"
                      $width="fit-content"
                    >
                      <Text
                        key={index}
                        $color="white"
                        $fontWeight={"bold"}
                        $fontSize="body-sm"
                      >
                        {team?.name}
                      </Text>
                    </FlexBox>
                  );
                })}
              </>
            )}
          </FlexBox>
        </Box>
      </FlexBox>
    </ListBox >
  );
};

export default StaffAndClientCard;
