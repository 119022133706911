import ApiService from "../../restapi";

export const createAdditionalFormApiService = async ({
    clientId,
    title,
    submittedData,
    notes,
    name,
    instructions,
    files,
}) => {
    const json = {
        title,
        submittedData,
        notes,
        name,
        instructions,
        files: files,
    }

    let finalData = json

    if (files && files.length > 0) {
        let formData = new FormData();
        for (var key in json) {
            if (key == "files") {
                files.forEach((file) => {
                    formData.append("files", file);
                });
            } else if (json[key] != null) {
                formData.append(key, typeof json[key] == "object" ? JSON.stringify(json[key]) : json[key]);
            }
        }
        finalData = formData
    }
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/additionalForms`,
        query: {},
        method: "POST",
        headers: {
            "Content-Type": files && files.length > 0 ? "multipart/form-data" : undefined,
        },
        body: finalData,
    })
}

export const updateAdditionalFormApiService = async ({
    clientId,
    additionalFormId,
    title,
    submittedData,
    notes,
    name,
    instructions,
    files,
}) => {
    const json = {
        title,
        submittedData,
        notes,
        name,
        instructions,
        files: files,
    }

    let finalData = json

    if (files && files.length > 0) {
        let formData = new FormData();
        for (var key in json) {
            if (key == "files") {
                files.forEach((file) => {
                    formData.append("files", file);
                });
            } else if (json[key] != null) {
                formData.append(key, typeof json[key] == "object" ? JSON.stringify(json[key]) : json[key]);
            }
        }
        finalData = formData
    }
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/additionalForms/${additionalFormId}`,
        query: {},
        method: "PUT",
        headers: {
            "Content-Type": files && files.length > 0 ? "multipart/form-data" : undefined,
        },
        body: finalData,
    });
}

export const getAllAdditionalFormApiService = async ({
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    console.log(clientId);
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/additionalForms`,
        query: {
            startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    })
}

export const getSingleAdditionalFormApiService = async ({
    clientId,
    additionalFormId,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}}/additionalForms/${additionalFormId}`,
        query: {},
        method: "GET",
        headers: {},
    })
}

export const deleteAdditionalFormApiService = async ({
    clientId,
    additionalFormId
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}}/additionalForms/${additionalFormId}`,
        query: {},
        method: "DELETE",
        headers: {},
    });
}

export const restoreArchivedAdditionalFormApiService = async ({
    clientId,
    id,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}}/additionalForms/${id}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    })
}