import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalTimesheetsReducer,
  clearAllSuccessMessagesReducer,
  clearTimesheetPdfReducer,
  createTimesheetReducer,
  deleteTimesheetReducer,
  getAllTimesheetReducer,
  getTimesheetPdfReducer,
  restoreArchivedTimesheetReducer,
  updateTimeSheetInLocalTimesheetListReducer,
  updateTimesheetReducer,

  updateTimesheetStatusFilterReducer,
} from "../../slices/timesheetSlices";

const useTimesheetDispatchers = () => {
  const dispatch = useDispatch();

  const createTimesheet = (data) => {
    return dispatch(createTimesheetReducer(data));
  };

  const restoreArchivedTimesheet = (data) => {
    return dispatch(restoreArchivedTimesheetReducer(data))
  }

  const updateTimesheet = (data) => {
    return dispatch(updateTimesheetReducer(data));
  };

  const getTimesheets = (data) => {
    return dispatch(getAllTimesheetReducer(data));
  };

  const getTimesheetPdf = (data) => {
    return dispatch(getTimesheetPdfReducer(data));
  };

  const deleteTimesheet = (data) => {
    return dispatch(deleteTimesheetReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  const clearAllLocalTimesheets = (data) => {
    return dispatch(clearAllLocalTimesheetsReducer(data));
  };

  const clearLocalTimesheetPdf = (data) => {
    return dispatch(clearTimesheetPdfReducer(data));
  };

  const updateTimesheetStatusFilter = (data) => {
    return dispatch(updateTimesheetStatusFilterReducer(data));
  };

  const updateTimeSheetInLocalTimesheetList = (data) => {
    return dispatch(updateTimeSheetInLocalTimesheetListReducer(data));
  };

  return {
    restoreArchivedTimesheet,
    createTimesheet,
    getTimesheets,
    getTimesheetPdf,
    updateTimesheetStatusFilter,
    clearAllErrorMessages,
    updateTimesheet,
    deleteTimesheet,
    clearAllSuccessMessages,
    clearAllLocalTimesheets,
    clearLocalTimesheetPdf,
    updateTimeSheetInLocalTimesheetList,
  };
};

export default useTimesheetDispatchers;
