import React, {useMemo} from 'react'
import {EVENT_STATUS} from '../../constants';
import {Box, CircleBox, FlexBox, ListBox} from '../../Components/Boxes';
import {Grid, GridContainer} from '../../Components/Grid';
import {formateDateToReadableFormatWithTime} from '../../utiles/dateformate';
import {Text} from '../../Components/Text';
import * as asyncStates from '../../redux/constants/asyncStates';
import {DropDownMenuItem} from '../../Components/Modals';
import Icon from '../../Components/Icons';
import {DropDownButton} from '../../Components/Input';

export default function EventDistributableCard(props) {
    const {
        event,
        index,

        onClick,
    } = props

    return (
        <ListBox
            $width="100%"
            $marginTop={2}
            key={index}
            $index={index}
            $onClick={() => {
                onClick()
            }}
        >
            <GridContainer>
                <Grid $xs={3}>
                    <Text $fontWeight="bold">
                        {event.staff?.name || " - "}
                    </Text>
                </Grid>
                <Grid $xs={3}>
                    <Text $fontWeight="bold">
                        {event.client?.name || " - "}
                    </Text>
                </Grid>
                <Grid $xs={2}>
                    <Text $fontWeight="bold">
                        {formateDateToReadableFormatWithTime(event.startDate)}
                    </Text>
                </Grid>
                <Grid $xs={2}>
                    <Text $fontWeight="bold">
                        {formateDateToReadableFormatWithTime(event.endDate)}
                    </Text>
                </Grid>
                <Grid $xs={2}>
                    <Text $fontWeight="bold">
                        {formateDateToReadableFormatWithTime(event.distributeAcceptedAt)}
                    </Text>
                </Grid>
            </GridContainer>
        </ListBox>
    )
}
