/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../Components/Boxes";
import * as asyncStates from "../../../redux/constants/asyncStates";
import { formateTimeMinutesToTimeTextboxFormat, formateTimeTextboxFormatToTimeMinutes, getHoursAndMinutesFromMinutes } from "../../../utiles/timeformate";
import { Text } from "../../../Components/Text";
import Button from "../../../Components/Input/Button"
import { CheckBox, DragAndDrop, DropDownButton, SelectBox, TextBox, Textarea } from "../../../Components/Input";
import { useEffect } from "react";
import { TIME_SHEET_STATUS, TIME_SHEET_TIME_AMMEND_STATUS } from "../../../constants";
import { ConfirmationDialog, DropDownMenuItem, FormDialog } from "../../../Components/Modals";
import Icon from "../../../Components/Icons";
import { SearchUserSelectBox } from "../../components/searchSelectBox";
import { Grid, GridContainer } from "../../../Components/Grid";
import { formateDateTextboxFormatToDate, formateDateToDateTextboxFormat, formateDateToReadableFormatWithTime } from "../../../utiles/dateformate";
import Image from "../../../Components/Images"
import { useResponsive } from "../../../Components/Hooks";
import useTimesheetDispatchers from "../../../redux/dispatchers/useTimesheetDispatchers";
import { useTimesheetDeleteErrorMessageSelector, useTimesheetDeleteStatusSelector, useTimesheetUpdateErrorMessageSelector, useTimesheetUpdateStatusSelector } from "../../../redux/selectors/useTimesheetSelectors";
import { useGlobalJobRolesSelector } from "../../../redux/selectors/useConfigsSelectors";
import { useMemo } from "react";
import { getSingleTimesheetApiService, updateTimesheetAmmendmentStatusApiService, updateTimesheetApiService } from "../../../services/applicationBackendApis/timesheets";
import {ratingEmoji} from '../../../Components/Theme';

export default function EditTimesheet({ $handleDialog, clientId, staffId, timesheet }) {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const [client, setClient] = useState(timesheet.client)
    const [staff, setStaff] = useState(timesheet.staff)
    const [startDate, setStartDate] = useState(timesheet?.startDate ? new Date(timesheet?.startDate) : new Date())
    const [endDate, setEndDate] = useState(timesheet?.endDate ? new Date(timesheet?.endDate) : new Date())
    const [signatureSubmitted, setSignatureSubmitted] = useState(timesheet?.signatureSubmitted ? timesheet?.signatureSubmitted : false)
    const [missingSignatureReason, setMissingSignatureReason] = useState(timesheet?.missingSignatureReason ? timesheet?.missingSignatureReason : null)
    const [notes, setNotes] = useState(timesheet?.notes ? timesheet?.notes : null)
    const [breakDuration, setBreakDuration] = useState(timesheet?.breakDuration ? timesheet?.breakDuration : 0)
    const [timesheetStatus, setTimesheetStatus] = useState(timesheet?.timesheetStatus ? timesheet?.timesheetStatus : TIME_SHEET_STATUS.PENDING)
    const [signatureUrl, setSignatureUrl] = useState(timesheet?.signatureUrl ? timesheet?.signatureUrl : null)
    const [signatureFile, setSignatureFile] = useState(timesheet?.signatureFile ? timesheet?.signatureFile : null)
    const [signedByName, setSignedByName] = useState(timesheet?.signedByName ? timesheet?.signedByName : null)
    const [signedByPosition, setSignedByPosition] = useState(timesheet?.signedByPosition ? timesheet?.signedByPosition : null)
    const [jobRole, setJobRole] = useState(timesheet?.jobRole ? timesheet?.jobRole : null)
    const [shiftReviewRating, setShiftReviewRating] = useState(timesheet?.shiftReview?.rating)
    const [uploadFile, setUploadFile] = useState(null)

    const [timesheetAmmendedRequested, setTimesheetAmmendedRequested] = useState(timesheet?.timeAmmendmentRequested && timesheet?.timeAmmendmentRequest?.status == TIME_SHEET_TIME_AMMEND_STATUS.PENDING)
    const [timesheetAmmendedRequest, setTimesheetAmmendedRequest] = useState(timesheet?.timeAmmendmentRequested && timesheet?.timeAmmendmentRequest)

    const [confirmationDialog, setConfirmationDialog] = useState(null)

    // ---------------- reducers ----------------------
    const {
        getTimesheets,
        updateTimesheet,
        deleteTimesheet,
        clearAllErrorMessages,

        updateTimeSheetInLocalTimesheetList,
    } = useTimesheetDispatchers();

    // ---------------- selectors ---------------------  
    const globalJobRoles = useGlobalJobRolesSelector()
    const jobRoleOptions = useMemo(() => {
        return globalJobRoles?.map(name => {
            return { value: name, label: name }
        })
    }, [globalJobRoles]);

    const deleteTimesheetStatus = useTimesheetDeleteStatusSelector();
    const updateTimesheetStatus = useTimesheetUpdateStatusSelector()

    const timesheetDeleteErrorMessage = useTimesheetDeleteErrorMessageSelector()
    const timesheetUpdateErrorMessage = useTimesheetUpdateErrorMessageSelector()

    const errorMessage = timesheetDeleteErrorMessage || timesheetUpdateErrorMessage;

    const [timesheetAmmendedRequestIsLoading, setTimesheetAmmendedRequestIsLoading] = useState(false)
    const [timesheetAmmededRequestErrorMessage, setTimesheetAmmededRequestErrorMessage] = useState(null)

    useEffect(() => {
        clearAllErrorMessages()
    }, []);

    const approveTimesheetFunc = async () => {
        await updateTimesheet({
            timesheetId: timesheet.id,
            clientId: client.id,
            staffId: staff.id,
            timesheetStatus: TIME_SHEET_STATUS.ACCEPTED,
        }).unwrap();
        getTimesheets({
            clientId: clientId,
            staffId: staffId,
            timesheetId: timesheet.id,
        });
        return $handleDialog();
    }

    const saveTimesheetFunc = async () => {
        await updateTimesheet({
            timesheetId: timesheet.id,
            clientId: client.id,
            staffId: staff.id,
            startDate: startDate,
            endDate: endDate,
            breakDuration: breakDuration,
            signatureSubmitted: signatureSubmitted,
            missingSignatureReason: missingSignatureReason,
            signedByName: signedByName,
            signedByPosition: signedByPosition,
            signatureFile: signatureFile,
            jobRole: jobRole,
            notes: notes,
        }).unwrap();
        getTimesheets({
            clientId: client.id,
            staffId: staff.id,
            timesheetId: timesheet.id,
        });
        return $handleDialog();
    }

    const deleteTimesheetFunc = async () => {
        await deleteTimesheet({
            clientId: client.id,
            staffId: staff.id,
        }).unwrap();
        getTimesheets({
            clientId: client.id,
            staffId: staff.id,
            timesheetId: timesheet.id,
        });
        return $handleDialog();
    }

    const changeTimeAmmendmentStatus = async (status) => {
        try {
            setTimesheetAmmededRequestErrorMessage(null)
            setTimesheetAmmendedRequestIsLoading(true)
            await updateTimesheetAmmendmentStatusApiService({
                timesheetId: timesheet.id,
                clientId: client.id,
                staffId: staff.id,
                status: status,
            })

            const result = await getSingleTimesheetApiService({
                timesheetId: timesheet.id,
                clientId: client.id,
                staffId: staff.id,
            })

            const newTimesheet = result.data

            updateTimeSheetInLocalTimesheetList({
                timesheetId: newTimesheet.id,
                timesheet: newTimesheet
            })

            setTimesheetAmmendedRequested(false)

            setStartDate(newTimesheet?.startDate ? new Date(newTimesheet?.startDate) : new Date())
            setEndDate(newTimesheet?.endDate ? new Date(newTimesheet?.endDate) : new Date())
            setSignatureSubmitted(newTimesheet?.signatureSubmitted ? newTimesheet?.signatureSubmitted : false)
            setMissingSignatureReason(newTimesheet?.missingSignatureReason ? newTimesheet?.missingSignatureReason : null)
            setNotes(newTimesheet?.notes ? newTimesheet?.notes : null)
            setBreakDuration(newTimesheet?.breakDuration ? newTimesheet?.breakDuration : 0)
            setTimesheetStatus(newTimesheet?.newTimesheetStatus ? newTimesheet?.newTimesheetStatus : TIME_SHEET_STATUS.PENDING)
            setSignatureUrl(newTimesheet?.signatureUrl ? newTimesheet?.signatureUrl : null)
            setSignatureFile(newTimesheet?.signatureFile ? newTimesheet?.signatureFile : null)
            setSignedByName(newTimesheet?.signedByName ? newTimesheet?.signedByName : null)
            setSignedByPosition(newTimesheet?.signedByPosition ? newTimesheet?.signedByPosition : null)
            setJobRole(newTimesheet?.jobRole ? newTimesheet?.jobRole : null)

        } catch (error) {
            console.log(error)
            setTimesheetAmmededRequestErrorMessage(error?.data?.error?.message || "Error")
        }

        setTimesheetAmmendedRequestIsLoading(false)
    }


    return (
        <>
            <Box $padding={5} $marginRight={3}>
                <FlexBox $width="100%" $justifyContent="flex-end">
                    <Box
                        $width="20%"
                        $display="flex"
                        $justifyContent="center"
                        $backgroundColor={timesheetStatus === TIME_SHEET_STATUS.PENDING ? "orange" : "green"}
                        $marginBottom={3}
                        $borderRadius={10}
                    >
                        <Text
                            $fontWeight="bold"
                            $wordWrap="anywhere"
                            $padding={1}
                            $color={"white"}
                        >
                            {timesheetStatus === TIME_SHEET_STATUS.PENDING ? "Pending" : "Accepted"}
                        </Text>
                    </Box>
                </FlexBox>

                <Text $fontSize="body" $marginBottom={1} >
                    Client
                </Text>
                <SearchUserSelectBox
                    disabled
                    searchByClient
                    selected={client}
                    onChange={(user) => {
                        setClient(user)
                    }}
                />

                <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                    Staff
                </Text>
                <SearchUserSelectBox
                    disabled
                    searchByStaff
                    selected={staff}
                    onChange={(user) => {
                        setStaff(user)
                    }}
                />

                {timesheetAmmendedRequested && <Box $marginTop={3} $padding={2} $backgroundColor="note" $borderRadius="5px">
                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        A timesheet modification has been requested for the shift. Please review the request and accept or reject it.
                    </Text>

                    <hr />
                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        Start Date: {formateDateToReadableFormatWithTime(timesheetAmmendedRequest.startDate)}
                    </Text>
                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        End Date: {formateDateToReadableFormatWithTime(timesheetAmmendedRequest.endDate)}
                    </Text>
                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        Break Duration: {timesheetAmmendedRequest.breakDuration} mins
                    </Text>

                    {timesheetAmmendedRequest.notes && <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        Notes: {timesheetAmmendedRequest.notes}
                    </Text>}

                    {timesheetAmmededRequestErrorMessage && <FlexBox $marginTop={2} $width="100%">
                        <ErrorAndSuccess $type="error">{timesheetAmmededRequestErrorMessage}</ErrorAndSuccess>
                    </FlexBox>}

                    <FlexBox $justifyContent="flex-end" $paddingBottom={1}>
                        <Button
                            $type="submit"
                            $variant={"outlined"}
                            $size="small"
                            $loading={timesheetAmmendedRequestIsLoading}
                            $onClick={() => {
                                changeTimeAmmendmentStatus(TIME_SHEET_TIME_AMMEND_STATUS.REJECTED)
                            }}
                        >
                            Reject
                        </Button>
                        <Box $marginRight={3} />
                        <Button
                            $type="submit"
                            $variant={"contained"}
                            $size="small"
                            $loading={timesheetAmmendedRequestIsLoading}
                            $onClick={() => {
                                changeTimeAmmendmentStatus(TIME_SHEET_TIME_AMMEND_STATUS.ACCEPTED)
                            }}
                        >
                            Accept
                        </Button>
                    </FlexBox>
                </Box>}

                <Box $width="100%" $marginTop={3} $marginBottom={3}>
                    <GridContainer $spacing={3}>
                        <Grid $xs={8}>
                            <Text $fontSize="body" $marginBottom={1}>
                                Start Date
                            </Text>
                            <TextBox
                                $type={"date"}
                                $value={formateDateToDateTextboxFormat(startDate)}
                                $onChange={(e) => {
                                    const val = formateDateTextboxFormatToDate(e.target.value);
                                    startDate.setMonth(val.getMonth())
                                    startDate.setFullYear(val.getFullYear())
                                    startDate.setDate(val.getDate())
                                    setStartDate(new Date(`${startDate}`))
                                }}
                                $width="100%"
                            />
                        </Grid>
                        <Grid $xs={4}>
                            <Text $fontSize="body" $marginBottom={1} >
                                Start Time
                            </Text>
                            <TextBox
                                $type={"time"}
                                $value={formateTimeMinutesToTimeTextboxFormat(startDate ? (startDate.getHours() * 60) + startDate.getMinutes() : 0)}
                                $onChange={(e) => {
                                    const val = formateTimeTextboxFormatToTimeMinutes(e.target.value)
                                    const data = getHoursAndMinutesFromMinutes(val)
                                    startDate.setHours(data.hours)
                                    startDate.setMinutes(data.minutes)
                                    setStartDate(new Date(`${startDate}`))
                                }}
                                $borderRadius="8px"
                                $border="1px solid black"
                            />
                        </Grid>
                    </GridContainer>
                </Box>

                <Box $width="100%" $marginBottom={3}>
                    <GridContainer $spacing={3}>
                        <Grid $xs={8}>
                            <Text $fontSize="body" $marginBottom={1}>
                                End Date
                            </Text>
                            <TextBox
                                $type={"date"}
                                $value={formateDateToDateTextboxFormat(endDate)}
                                $onChange={(e) => {
                                    const val = formateDateTextboxFormatToDate(e.target.value);
                                    endDate.setMonth(val.getMonth())
                                    endDate.setFullYear(val.getFullYear())
                                    endDate.setDate(val.getDate())
                                    setEndDate(new Date(`${endDate}`))
                                }}
                                $width="100%"
                            />
                        </Grid>
                        <Grid $xs={4}>
                            <Text $fontSize="body" $marginBottom={1} >
                                End Time
                            </Text>
                            <TextBox
                                $type={"time"}
                                $value={formateTimeMinutesToTimeTextboxFormat(endDate ? (endDate.getHours() * 60) + endDate.getMinutes() : 0)}
                                $onChange={(e) => {
                                    const val = formateTimeTextboxFormatToTimeMinutes(e.target.value)
                                    const data = getHoursAndMinutesFromMinutes(val)
                                    endDate.setHours(data.hours)
                                    endDate.setMinutes(data.minutes)
                                    setEndDate(new Date(`${endDate}`))
                                }}
                                $borderRadius="8px"
                                $border="1px solid black"
                            />
                        </Grid>
                    </GridContainer>
                </Box>

                <Text $fontSize="body" $marginBottom={1} >
                    Job Role
                </Text>
                <SelectBox
                    $onChange={(e) => {
                        setJobRole(e.value)
                    }}
                    $trailing={<Icon $icon="ArrowDropDown" />}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                    $value={jobRole != null ? { value: jobRole, label: jobRole } : null}
                    $options={jobRoleOptions}
                />

                <Box $marginTop={3} />

                <Box $width="100%" $marginBottom={3}>
                    <Text $fontSize="body" $marginBottom={1}>
                        Break duration (mins)
                    </Text>
                    <TextBox
                        $type={"number"}
                        $value={breakDuration}
                        $onChange={(e) => {
                            setBreakDuration(Number(e.target.value))
                        }}
                        $width="100%"
                    />
                </Box>

                <FlexBox>
                    <Text
                        $color={"text"}
                        $fontSize={"body-sm"}
                        $fontWeight={"600"}
                    >
                        Singature submitted
                    </Text>
                    <CheckBox
                        $checked={signatureSubmitted}
                        $size={"medium"}
                        $onChange={(val) => {
                            setSignatureSubmitted(val.target.checked)
                        }}
                    />
                </FlexBox>

                {signatureSubmitted && <Box $marginBottom={3}>
                    <Box $width="fit-content" $marginBottom={3}>
                        <Text $color="#0000FF" $fontWeight="bold" $link $onClick={() => {
                            setUploadFile({
                                onFileUpload: (file) => {
                                    setSignatureUrl(URL.createObjectURL(file))
                                    setSignatureFile(file)
                                }
                            })
                        }}>Select new signature image</Text>
                    </Box>
                    {signatureUrl && <Box
                        $marginBottom={3}
                        $maxWidth={isDesktop ? "30%" : "100%"}
                        $display="flex"
                        $flexDirection="column"
                        $alignItems="center"
                        $justifyContent="center"
                    >
                        <Image $width="100%" $objectFit="contain" $src={signatureUrl} />
                    </Box>}
                    <Text $fontSize="body" $marginBottom={1} >
                        Signed by
                    </Text>
                    <TextBox
                        $type={"text"}
                        $value={signedByName}
                        $onChange={(e) => setSignedByName(e.target.value)}
                        $width="100%"
                    />
                    <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                        Position of Signer
                    </Text>
                    <TextBox
                        $type={"text"}
                        $value={signedByPosition}
                        $onChange={(e) => setSignedByPosition(e.target.value)}
                        $width="100%"
                    />

                </Box>}

                {!signatureSubmitted && <Box $marginBottom={3} $width="100%">
                    <Text $fontSize="body" $marginBottom={1} >
                        Signature not submitted reason
                    </Text>
                    <Textarea
                        $type={"text"}
                        $value={missingSignatureReason}
                        $onChange={(e) => setMissingSignatureReason(e.target.value)}
                        $rows={5}
                        $width="100%"
                    />
                </Box>}

                {shiftReviewRating && <Box $marginBottom={3} $width="100%">
                    <Text $fontSize="body" $marginBottom={1} >
                        Shift review: {ratingEmoji(shiftReviewRating)}
                    </Text>
                </Box>}

                <Box $marginBottom={3} $width="100%">
                    <Text $fontSize="body" $marginBottom={1} >
                        Additional notes
                    </Text>
                    <Textarea
                        $type={"text"}
                        $value={notes}
                        $onChange={(e) => setNotes(e.target.value)}
                        $rows={5}
                        $width="100%"
                    />
                </Box>

                {errorMessage && <FlexBox $marginTop={2} $width="100%">
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                </FlexBox>}
            </Box>

            <FlexBox
                $justifyContent={"flex-end"}
                $padding={4}
                $backgroundColor="list"
            >
                {<DropDownButton
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $loading={deleteTimesheetStatus == asyncStates.PENDING || updateTimesheetStatus == asyncStates.PENDING}
                >
                    {timesheetStatus == TIME_SHEET_STATUS.PENDING && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to approve this timesheet?",
                                onOk: ({ reason }) => {
                                    approveTimesheetFunc()
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Done" $size="large" $color="primary" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Approve timesheet
                        </Text>
                    </DropDownMenuItem>}

                    <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to save this timesheet?",
                                onOk: ({ reason }) => {
                                    saveTimesheetFunc()
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="DoneAll" $size="large" $color="primary" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Save
                        </Text>
                    </DropDownMenuItem>

                    <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to delete this timesheet?",
                                onOk: ({ reason }) => {
                                    deleteTimesheetFunc()
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Delete" $size="large" $color="red" />
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Delete
                        </Text>
                    </DropDownMenuItem>
                </DropDownButton>}
            </FlexBox>

            {uploadFile && <FormDialog
                $open={true}
                $handleDialog={() => {
                    setUploadFile(null)
                }}
                $title={"Image"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={false}
            >
                <Box $display="flex" $flexDirection="column" $alignItems="center" $justifyContent="center" $marginBottom="3">
                    <Text $fontSize="heading-3" $marginTop="3" $marginBottom="3">Upload Image</Text>
                    <DragAndDrop
                        $fileTypes={["JPG", "PNG", "GIF"]}
                        $onFileUpload={(file) => {
                            uploadFile.onFileUpload(file)
                            setUploadFile(null)
                        }}
                    />
                </Box>
            </FormDialog>}

            {confirmationDialog && <ConfirmationDialog
                $title={confirmationDialog.title}
                $description={confirmationDialog.description}
                $onClose={() => {
                    setConfirmationDialog(null)
                }}
                $onConfirm={({ reason }) => {
                    setConfirmationDialog(null)
                    confirmationDialog.onOk({ reason })
                }}
                $enterReason={confirmationDialog.enterReason}
            />}
        </>
    )
}
