import React, { useEffect, useMemo, useState } from 'react'
import { Box, FlexBox } from '../../Components/Boxes'
import { useResponsive } from '../../Components/Hooks';
import ScheduleCalendar from '../../Components/Calendar/ScheduleCalendar';
import {
    useAutoPilotEventsErrorMessagesSelector,
    useEventsSelector,
    useGetEventsStatusSelector,
    useStaffAvailabilitiesSelector
} from '../../redux/selectors/useEventsSelectors';
import useEventsDispathers from '../../redux/dispatchers/useEventsDispatcher';
import { EVENT_STATUS, EVENT_TYPE, REPETITION_PERIODS } from '../../constants';
import FormDialog from '../../Components/Modals/FormDialog';
import Events from "../Team/Events/EditEvents";
import * as asyncStates from "../../redux/constants/asyncStates"
import usePermissionsController from '../../permissions';
import { color } from '../../Components/Theme';
import {
    formateDateTextboxFormatToDate,
    formateDateToDateTextboxFormat,
    formateDateToReadableFormatWithoutTime,
    getDaysNotWorkingFromWorkingHours
} from '../../utiles/dateformate';
import BasicAlerts from '../../Components/Boxes/ErrorAndSuccess';
import { Button, TextBox } from '../../Components/Input';
import CircularLoader from '../../Components/Icons/circularLoader';
import { Text } from '../../Components/Text';
import ScheduleAnalytics from './ScheduleAnalytics';

export default function CalendarScheduleView({ onEventClick }) {
    const {
        scheduleStaffCentered,
        scheduleClientCentered,
        defaultScheduleView,
        defaultCreateEventModelTitle,
        shiftsRequireCheckIn,

        scheduleDuplicateEventDayView,
        scheduleDuplicateEventWeekView,
    } = usePermissionsController()
    const {
        getEvents,
        getStaffAvailabilities,
        autoPilotEvents,
        clearFilters,
        updateFilterClientId,
        updateFilterStaffId,
        updateFilterStartDate,
        updateFilterEndDate,
        updateFilterIsAvailability,
        updateFilterIsClientVisit,
        updateFilterIsCustomEvent,
        updateFilterIsShift,
    } = useEventsDispathers()

    const isDesktop = useResponsive({ xs: false, sm: true });
    const [startDate, setStartDate] = useState(new Date())
    const [calendarView, setCalendarView] = useState(defaultScheduleView ?? REPETITION_PERIODS.DAY)
    const [scrollToTime, setScrollToTime] = useState(new Date())
    const [selectDateToCopyFrom, setSelectDateToCopyFrom] = useState(null);

    const events = useEventsSelector()
    const staffAvailabilities = useStaffAvailabilitiesSelector()

    const everyIsFilled = (event) => {
        if (scheduleClientCentered) {
            if (!event.client) return false
        }

        if (scheduleStaffCentered) {
            if (!event.staff) return false
        }

        return true
    }

    const duplicateEventsWithMultipleStaff = useMemo(() => {
        const duplicatedEvents = []

        for (let i = 0; i < events.length; i++) {
            const event = events[i]
            // only staff entered
            // only additional entered

            let allStaff = []
            if (event.staff) {
                allStaff.push(event.staff)
            }

            if (event.additionalStaff?.length > 0) {
                allStaff = [...allStaff, ...event.additionalStaff]
            }

            if (allStaff?.length > 0) {
                for (let j = 0; j < allStaff.length; j++) {
                    const additionalStaff = allStaff[j]
                    const newEvent = {
                        event: event,
                        staff: additionalStaff,
                        client: event.client,
                    }
                    duplicatedEvents.push(newEvent)
                }
            }

            // get all events related to event from duplicated Events
            const relatedEvents = duplicatedEvents.filter(e => e.event.id === event.id)

            if (relatedEvents.length < (event.requiredNumStaff || 1)) {
                const newEvent = {
                    event: event,
                    staff: null,
                    client: event.client,
                }

                duplicatedEvents.push(newEvent)
            }
        }

        return duplicatedEvents
    }, [events])

    const getMaxServiceNameLength = (event) => {
        if (calendarView === REPETITION_PERIODS.DAY) {
            const start = new Date(event.startDate)
            const end = new Date(event.endDate)
            const duration = end.getTime() - start.getTime()
            return Math.floor(duration / 100000)
        }
        return Math.floor(window.innerWidth / 100)
    }


    const [calendarEvents, unassignedCalendarEvents] = useMemo(() => {
        const processedEventsObject = {}
        const unAssignedEvents = []

        for (let i = 0; i < duplicateEventsWithMultipleStaff.length; i++) {
            const eventUserInfo = duplicateEventsWithMultipleStaff[i]
            if (everyIsFilled(eventUserInfo)) {
                const user = scheduleStaffCentered ? eventUserInfo?.staff : eventUserInfo?.client
                const otherUser = scheduleStaffCentered ? eventUserInfo?.client : eventUserInfo?.staff
                const event = eventUserInfo.event
                let serviceName = otherUser?.name || event.name || "" // || "Custom event"
                if (scheduleClientCentered && !serviceName) serviceName = "*Unassigned*"
                const start = new Date(event.startDate)
                const end = new Date(event.endDate)

                const requiredNumStaff = event.requiredNumStaff || event.additionalStaff?.length || 1
                let allStaff = []
                if (event.staff) {
                    allStaff.push(event.staff)
                }

                if (event.additionalStaff?.length > 0) {
                    allStaff = [...allStaff, ...event.additionalStaff]
                }

                const maxServiceNameLength = getMaxServiceNameLength(event)
                if (serviceName.length > maxServiceNameLength) {
                    serviceName = serviceName.substring(0, maxServiceNameLength);
                    serviceName += "...";
                }

                if (allStaff.length < requiredNumStaff) {
                    serviceName += ` (${allStaff.length} / ${requiredNumStaff})`
                }

                const eventDetails = {
                    startDate: start,
                    endDate: end,
                    title: serviceName,
                    type: "EVENT",
                    assigned: allStaff.length >= requiredNumStaff,
                    metadata: {
                        ...event,
                    }
                }

                if (processedEventsObject[user?.id]) {
                    // append
                    processedEventsObject[user?.id].events.push({ ...eventDetails })
                } else {
                    // create new
                    processedEventsObject[user?.id] = {
                        user: user,
                        events: [
                            eventDetails
                        ],
                    }
                }
            } else {
                const event = eventUserInfo.event
                let serviceName = eventUserInfo?.client?.name || event.name || "" // || "Custom event")
                const start = new Date(event.startDate)
                const end = new Date(event.endDate)

                const requiredNumStaff = event.requiredNumStaff || event.additionalStaff?.length || 1
                let allStaff = []
                if (event.staff) {
                    allStaff.push(event.staff)
                }

                if (event.additionalStaff?.length > 0) {
                    allStaff = [...allStaff, ...event.additionalStaff]
                }

                const maxServiceNameLength = getMaxServiceNameLength(event)
                if (serviceName.length > maxServiceNameLength) {
                    serviceName = serviceName.substring(0, maxServiceNameLength);
                    serviceName += "...";
                }

                if (allStaff.length < requiredNumStaff) {
                    serviceName += ` (${allStaff.length} / ${requiredNumStaff})`
                }


                const eventDetails = {
                    startDate: start,
                    endDate: end,
                    title: serviceName,
                    type: "EVENT",
                    assigned: allStaff.length >= requiredNumStaff,
                    metadata: {
                        ...event,
                    }
                }
                unAssignedEvents.push({ ...eventDetails })
            }
        }

        const processedEvents = []

        for (const key in processedEventsObject) {
            processedEvents.push({ ...processedEventsObject[key] })
        }

        if (scheduleStaffCentered && calendarView === REPETITION_PERIODS.DAY) {
            const staffIds = processedEvents.map((event) => event.user.id)
            if (staffIds.length > 0) {
                getStaffAvailabilities({
                    staffIds: staffIds
                })
            }
        }

        return [processedEvents, unAssignedEvents]
    }, [duplicateEventsWithMultipleStaff])

    const backgroundEvents = useMemo(() => {
        // return []
        if (calendarView !== REPETITION_PERIODS.DAY) return []
        if (scheduleClientCentered) return []

        return calendarEvents?.map((event) => {
            console.log(">>>>>>>>>: ", staffAvailabilities)

            const bgAvailabilityEvents = staffAvailabilities?.filter(e => e.eventType === EVENT_TYPE.AVAILABILITY && e.staff.id === event.user.id)?.map(e => {
                return {
                    start: new Date(e.startDate),
                    end: new Date(e.endDate),
                }
            }) ?? []

            const bgAbsenceEvents = staffAvailabilities?.filter(e => e.eventType === EVENT_TYPE.ABSENCE && e.staff.id === event.user.id)?.map(e => {
                return {
                    start: new Date(e.startDate),
                    end: new Date(e.endDate),
                }
            }) ?? []

            const prevMonday = new Date(`${startDate}`);
            prevMonday.setHours(0)
            prevMonday.setMinutes(0)
            prevMonday.setSeconds(0)
            prevMonday.setMilliseconds(0)

            const nextSunday = new Date(`${prevMonday}`);
            nextSunday.setHours(23)
            nextSunday.setMinutes(59)
            nextSunday.setSeconds(59)
            nextSunday.setMilliseconds(59)
            let processedBgEvents = []

            processedBgEvents = getDaysNotWorkingFromWorkingHours({
                workingHoursList: bgAvailabilityEvents,
                start: prevMonday,
                end: nextSunday,
            }).map(e => {
                return {
                    startDate: e.start,
                    endDate: e.end,
                    title: "",
                    type: EVENT_TYPE.AVAILABILITY,
                    assigned: true,
                    metadata: {
                        eventType: EVENT_TYPE.AVAILABILITY,
                    }
                }
            })
            console.log(processedBgEvents)
            bgAbsenceEvents.forEach(e => {
                processedBgEvents.push({
                    startDate: e.start,
                    endDate: e.end,
                    title: "",
                    type: EVENT_TYPE.ABSENCE,
                    assigned: true,
                    metadata: {
                        eventType: EVENT_TYPE.ABSENCE,
                    }
                })
            })
            return {
                user: event.user,
                events: processedBgEvents,
            }
        }) ?? []
    }, [staffAvailabilities, calendarEvents])

    const getEventsStatus = useGetEventsStatusSelector()
    const autoPilotEventsStatus = useGetEventsStatusSelector()

    const autoPilotEventsErrorMessages = useAutoPilotEventsErrorMessagesSelector()

    const refreshEvents = ({ date = startDate, view = calendarView }) => {
        const prevMonday = new Date(`${date}`);
        if (view === REPETITION_PERIODS.WEEK) prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
        prevMonday.setHours(0)
        prevMonday.setMinutes(0)
        prevMonday.setSeconds(0)
        prevMonday.setMilliseconds(0)

        const nextSunday = new Date(`${prevMonday}`);
        if (view === REPETITION_PERIODS.WEEK) nextSunday.setDate(nextSunday.getDate() + 6)
        nextSunday.setHours(23)
        nextSunday.setMinutes(59)
        nextSunday.setSeconds(59)
        nextSunday.setMilliseconds(59)

        updateFilterStartDate(`${prevMonday}`)
        updateFilterEndDate(`${nextSunday}`)

        getEvents({})
    }

    const onAutoPilotSave = async ({
        dateToCopyFrom,
        dateToCopyTo,
        numDaysToCopyToInSeries,
    }) => {
        await autoPilotEvents({
            dateToCopyFrom,
            dateToCopyTo,
            numDaysToCopyToInSeries,
        }).unwrap()
        setSelectDateToCopyFrom(null)
        getEvents({})
    }

    useEffect(() => {
        clearFilters({})
        updateFilterClientId(null)
        updateFilterStaffId(null)
        updateFilterIsAvailability(false)
        updateFilterIsClientVisit(true)
        updateFilterIsCustomEvent(true)
        updateFilterIsShift(true)
        refreshEvents({ date: startDate })
    }, [])

    return (
        <>
            <ScheduleCalendar
                scrollToTime={scrollToTime}
                label={scheduleStaffCentered ? "Staff" : "Clients"}
                selectedDate={startDate}
                events={calendarEvents}
                backgroundEvents={backgroundEvents}
                unAssignedEvents={unassignedCalendarEvents}
                calendarView={calendarView}
                onStyle={(event) => {
                    const isInPast = event.startDate.getTime() < new Date().getTime()
                    const styles = {
                        backgroundColor: isInPast ? "#ccc" : color("primary"),
                    }
                    if (event.metadata.eventType === EVENT_TYPE.AVAILABILITY) {
                        styles.backgroundColor = color("#ccc")
                    } else if (event.metadata.eventType === EVENT_TYPE.ABSENCE) {
                        styles.backgroundColor = color("#ccc")
                    } else if (event.metadata.eventStatus === EVENT_STATUS.ACTIVE) {
                        styles.backgroundColor = event?.metadata?.eventColor || color("event-active")
                    } else if (event.metadata.eventStatus === EVENT_STATUS.CANCELLED) {
                        styles.backgroundColor = color("event-cancelled");
                    } else if (event.metadata.eventStatus === EVENT_STATUS.COMPLETED) {
                        styles.backgroundColor = color("event-completed");
                    } else if (event.metadata.eventStatus === EVENT_STATUS.CHECKED_IN) {
                        styles.backgroundColor = color("event-checked-in");
                    } else if (event.metadata.eventStatus === EVENT_STATUS.MISSED) {
                        styles.backgroundColor = color("event-missed");
                    } else {
                        styles.backgroundColor = event?.metadata?.eventColor || color("primary");
                    }

                    return styles
                }}
                onNavigate={(newDate, view) => {
                    console.log(newDate)
                    setStartDate(newDate)
                    setCalendarView(view)
                    refreshEvents({ date: newDate, view })
                }}
                onSelectEvent={(event) => {
                    if (event.metadata.eventType === EVENT_TYPE.AVAILABILITY) return
                    if (event.metadata.eventType === EVENT_TYPE.ABSENCE) return
                    onEventClick(event.metadata)
                }}
                loading={getEventsStatus === asyncStates.PENDING}
                actionLoading={autoPilotEventsStatus === asyncStates.PENDING}
                actions={((calendarView === REPETITION_PERIODS.WEEK && scheduleDuplicateEventWeekView) || (calendarView === REPETITION_PERIODS.DAY && scheduleDuplicateEventDayView)) && startDate.getTime() >= new Date().getTime() ? [{
                    label: "Copy schedule from another day",
                    value: "AUTOPILOT"
                }] : []}
                onActionClick={(action) => {
                    if (action === "AUTOPILOT") {
                        setSelectDateToCopyFrom({
                            dateToCopyFrom: new Date(startDate.getTime() - (1000 * 60 * 60 * 24)),
                            numDaysToCopyToInSeries: 1,
                        })
                    }
                }}
            />

            <Box $marginTop={5} />
            <Box $width="100%" $height="1px" $backgroundColor="list" $marginTop={5} $marginBottom={5} />
            <Box $width="100%">
                <Box $paddingLeft="5" $paddingRight="5">
                    <ScheduleAnalytics
                        events={events}
                        showCheckedIn={shiftsRequireCheckIn}
                        getStyle={(eventStatus) => {
                            const styles = {
                                backgroundColor: color("primary"),
                            }
                            if (eventStatus === EVENT_STATUS.ACTIVE) {
                                styles.backgroundColor = color("event-active")
                            } else if (eventStatus === EVENT_STATUS.CANCELLED) {
                                styles.backgroundColor = color("event-cancelled");
                            } else if (eventStatus === EVENT_STATUS.COMPLETED) {
                                styles.backgroundColor = color("event-completed");
                            } else if (eventStatus === EVENT_STATUS.CHECKED_IN) {
                                styles.backgroundColor = color("event-checked-in");
                            } else if (eventStatus === EVENT_STATUS.MISSED) {
                                styles.backgroundColor = color("event-missed");
                            }

                            return styles
                        }}

                    />
                </Box>
            </Box>
            <Box $marginTop={5} />

            {selectDateToCopyFrom && <FormDialog
                $open={true}
                $handleDialog={() => {
                    setSelectDateToCopyFrom(null)
                }}
                $title={"Select Date"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
                $headerColor={color("primary")}
            >
                <Box $padding={5}>
                    <Text $fontSize="body" $marginBottom={1} $width="100%" $textAlign={"center"}>
                        Select Date To Copy from and apply to {formateDateToReadableFormatWithoutTime(startDate)}
                    </Text>
                    <Box $marginTop={5} />
                    <Text $fontSize="body" $marginBottom={1}>
                        Date to copy from
                    </Text>
                    <TextBox
                        $type={"date"}
                        $value={formateDateToDateTextboxFormat(selectDateToCopyFrom.dateToCopyFrom)}
                        $onChange={(e) => {
                            selectDateToCopyFrom.dateToCopyFrom = formateDateTextboxFormatToDate(e.target.value)
                            setSelectDateToCopyFrom({ ...selectDateToCopyFrom })
                        }}
                        $width="100%"
                    />
                    <Box $marginTop={5} />
                    <Text $fontSize="body" $marginBottom={1}>
                        Number of days to copy to in series
                    </Text>
                    <TextBox
                        $type={"number"}
                        $value={selectDateToCopyFrom.numDaysToCopyToInSeries}
                        $onChange={(e) => {
                            selectDateToCopyFrom.numDaysToCopyToInSeries = parseInt(e.target.value)
                            setSelectDateToCopyFrom({ ...selectDateToCopyFrom })
                        }}
                        $width="100%"
                    />

                    {autoPilotEventsErrorMessages && <FlexBox $marginTop={2} $marginBottom={2} $marginRight={3} $marginLeft={3} $justifyContent="center">
                        <BasicAlerts $type="error">{autoPilotEventsErrorMessages}</BasicAlerts>
                    </FlexBox>}
                </Box>
                <FlexBox
                    $justifyContent={"flex-end"}
                    $padding={4}
                    $backgroundColor="list"
                >

                    {<Button $sx={{ borderRadius: 2 }} $marginRight={3} $onClick={() => {
                        if (autoPilotEventsStatus !== asyncStates.PENDING) {
                            onAutoPilotSave({
                                dateToCopyFrom: selectDateToCopyFrom.dateToCopyFrom,
                                dateToCopyTo: startDate,
                                numDaysToCopyToInSeries: selectDateToCopyFrom.numDaysToCopyToInSeries,
                            })
                        }
                    }}>
                        <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                            {autoPilotEventsStatus !== asyncStates.PENDING ? "Save" : (
                                <CircularLoader $color="white" $size={25} />
                            )}
                        </Text>
                    </Button>}
                </FlexBox>
            </FormDialog>}
        </>
    )
}
