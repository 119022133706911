import React, { useEffect, useState } from "react";
import {
  Box,
  ErrorAndSuccess,
  FlexBox,
  ListBox,
} from "../../../../../Components/Boxes/index";
import { useResponsive } from "../../../../../Components/Hooks";
import Icon from "../../../../../Components/Icons";
import { Button } from "../../../../../Components/Input";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import { NewVersionforCarePlan } from "../Dialogs/CarePlan/NewVersionforCarePlan";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import { formateDateToReadableFormatWithoutTime } from "../../../../../utiles/dateformate";
import useCarePlanDispatchers from "../../../../../redux/dispatchers/useClientCarePlanDispatchers";
import {
  useCarePlansGetErrorMessageSelector,
  useCarePlansGetStatusSelector,
  useCarePlansSelector,
  useMoreCareplanItemsLeftInPaginationSelector,
} from "../../../../../redux/selectors/useClientCarePlanSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";
import { GridContainer, Grid } from "../../../../../Components/Grid";
import { CustomTable } from "../../../../../Components/Table";
import { displayMedicineText } from "../../../../../utiles/medicineFormatter";

const CarePlan = ({ clientId }) => {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const {
    accessArchives
  } = usePermissionsController()

  // ---------------- states ------------------------
  const [open, setOpen] = useState(false);
  function HandleCarePlanDialog() {
    setOpen(!open);
  }
  const [type, setType] = useState("");
  //storing the selected carePlan and sending to the dialog
  const [carePlanToEdit, setCarePlanToEdit] = useState(null);
  const [isArchived, setIsArchived] = useState(false)

  // ---------------- reducers ----------------------
  const { getCarePlans, clearAllLocalCarePlans } = useCarePlanDispatchers();
  // ---------------- selectors ---------------------
  const allCarePlans = useCarePlansSelector();
  const getCarePlansStatus = useCarePlansGetStatusSelector();
  const complianceGetErrorMessage = useCarePlansGetErrorMessageSelector();
  const errorMessage = complianceGetErrorMessage;
  const moreCareplanItemsLeftInPagination = useMoreCareplanItemsLeftInPaginationSelector()

  // ---------------- useEffect ---------------------
  //Fetching the client from database

  const loadMore = () => {
    getCarePlans({
      clientId: clientId,
      startAfter: allCarePlans[allCarePlans.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalCarePlans();
    getCarePlans({
      clientId: clientId,
      isArchived: isArchived,
    });
  }, [isArchived]);

  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}

        <Button
          $onClick={() => {
            setType("add");
            setCarePlanToEdit(null);
            HandleCarePlanDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>New Care Plan</Text>
        </Button>
      </FlexBox>

      {/* <FlexBox $width="100%" $marginTop={3} $padding="3">
        <Box $width="40%">
          <Text $fontWeight="bold">
            Name
          </Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Urgency</Text>
        </Box>
        <Box $width="15%" $display="flex" $justifyContent="center">
          <Text>Last Updated</Text>
        </Box>
        <Box $width="25%" $display="flex" $justifyContent="center">
          <Text>Updated By</Text>
        </Box>
      </FlexBox> */}

      {getCarePlansStatus === asyncStates.SUCCESS && allCarePlans.length < 1 && <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
        <EmptyState />
      </FlexBox>}

      {allCarePlans?.map((value, index) => {
        const services = []

        if (value.services) {
          value?.services?.forEach(service => {
            services.push(service)
          })
        }

        if (value.medicines && value.medicines.length > 0) {
          services.push("---- Medicines ----")
          value?.medicines?.forEach(medicine => {
            services.push(displayMedicineText(medicine))
          })
        }

        return (
          <>
            <Box $marginTop={5} />
            <GridContainer $spacing={3}>
              <Grid $xs={9}>
                <FlexBox $verticalAlign="bottom">
                  <Text $fontSize="body" $fontWeight="bold" $padding="2">
                    {value.careplanName}
                  </Text>
                </FlexBox>
              </Grid>
              <Grid $xs={3}>
                <FlexBox>
                  <Box $border="1px solid #E0E0E0" $borderRadius="5px" $padding="2" $width="fit-content" $marginLeft="auto">
                    <Text
                      $fontWeight="bold"
                      $wordWrap="anywhere"
                      $textAlign={"right"}
                      $color={
                        value.urgency === "URGENT"
                          ? "red"
                          : value.urgency === "NEEDS_ATTENTION"
                            ? "orange"
                            : value.urgency === "NON_URGENT"
                              ? "#26E074"
                              : "black"
                      }
                    >
                      {value.urgency}
                    </Text>
                  </Box>

                  <Box $marginLeft={5} />

                  <Icon
                    $icon="Edit"
                    $size={20}
                    $color={"primary"}
                    $pointer={"pointer"}
                    $onClick={() => {
                      setCarePlanToEdit(value);
                      setType("edit");
                      HandleCarePlanDialog();
                    }}
                  />

                </FlexBox>
              </Grid>
            </GridContainer>

            <Box $marginTop={3} />

            <CustomTable
              $headers={[
                "Need",
                "Goal",
                "Interventions",
                "Tasks and Medicines",
              ]}
              $rows={[
                [
                  value.need ? value.need : "-",
                  value.goal ? value.goal : "-",
                  value.interventions ? value.interventions : "-",
                  services ? <Box>
                    {services?.map(service => {
                      return (
                        <Text $marginBottom={1} $wordWrap="anywhere"> {service} </Text>
                      )
                    })}
                  </Box> : "-",
                ]
              ]}
              $minRowHeight={500}
            />

          </>
        );
      })}

      {
        getCarePlansStatus === asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <Box $width="100%" $marginTop="2" key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={40}
              ></MuiSkeletonLoader>
            </Box>
          );
        })
      }
      {
        errorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )
      }

      {
        moreCareplanItemsLeftInPagination && <FlexBox $marginTop="6">
          <Button $onClick={() => {
            loadMore()
          }} >Load More</Button>
        </FlexBox>
      }

      <FormDialog
        $open={open}
        $handleDialog={HandleCarePlanDialog}
        $title={"Care Plan"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <NewVersionforCarePlan
          clientId={clientId}
          $handleDialog={HandleCarePlanDialog}
          $type={type}
          $carePlanToEdit={type === "edit" && carePlanToEdit}
        />
      </FormDialog>
    </Box >
  );
};

export default CarePlan;
