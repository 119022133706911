import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useJobRolesSettingsController } from '../SettingsControllers/useJobRolesController'

export default function JobRolesSettings() {
  const controller = useJobRolesSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
