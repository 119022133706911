
import ApiService from "../../restapi";

export const getAllComplianceApiService = async ({
  staffId,
  startAfter,
  limit,
  isArchived,
}) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/compliance`,
    query: {
      startAfter: startAfter,
      limit: limit,
      isArchived: isArchived,
    },
    method: "GET",
    headers: {},
  });
};

export const createComplianceApiService = async ({
  staffId,
  complianceName,
  notes,
  validFrom,
  validTo,
  files,
  onUploadProgress,
}) => {
  let formData = new FormData();
  formData.append("complianceName", complianceName);
  formData.append("notes", notes);
  if (validFrom) formData.append("validFrom", validFrom);
  if (validTo) formData.append("validTo", validTo);
  files.forEach((file) => {
    formData.append("files", file);
  });
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/compliance`,
    query: {},
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: formData,
    onUploadProgress: onUploadProgress,
  });
};

export const deleteComplianceApiService = async ({
  staffId,
  complianceId,
}) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/compliance/${complianceId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};

export const updateComplianceApiService = async ({
  staffId,
  complianceId,
  complianceName,
  notes,
  validFrom,
  validTo,
  files,
  filesToRemove,
  onUploadProgress,
}) => {
  let formData = new FormData();
  formData.append("complianceName", complianceName);
  formData.append("notes", notes);
  formData.append("validFrom", validFrom);
  formData.append("validTo", validTo);
  files.forEach((file) => {
    formData.append("files", file);
  });
  filesToRemove.forEach((filesToRemove) => {
    formData.append("filesToRemove", filesToRemove);
  });
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/compliance/${complianceId}`,
    query: {},
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: formData,
    onUploadProgress: onUploadProgress,
  });
};

export const restoreArchivedComplianceApiService = async ({
  staffId,
  id,
}) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/compliance/${id}/restore`,
    query: {},
    method: "PUT",
    headers: {},
  })
}