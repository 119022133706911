import React, { useState } from "react";
import { Box, FlexBox } from "../../../Components/Boxes";
import {
  CheckBox,
  SelectBox,
  TextBox,
} from "../../../Components/Input";
import { Text } from "../../../Components/Text";
import Icon from "../../../Components/Icons";
import { formateDateTextboxFormatToDate, formateDateToDateTextboxFormat } from "../../../utiles/dateformate";
import { DAYS, REPETITION_END_DATE_TYPE, REPETITION_PERIODS, REPETITION_TYPE } from "../../../constants";

const Repetition = (props) => {
  const [name, setName] = useState("");
  const {
    repetitionType,
    repetitionPeriodOptions,
    repetitionStartDate,
    repetitionEndDate,
    repetitionEndDateType,
    repetitionPeriod,
    days,

    onRepetitionTypeChanged,
    onRepetitionEndDateChanged,
    onRepetitionEndDateTypeChanged,
    onRepetitionStartDateChanged,
    onDaysChanged,
    onRepetitionPeriodChange,
  } = props

  const handleRepetitionPeriodEveryChange = ({ value, label }) => {
    onRepetitionPeriodChange({
      every: value,
      value: repetitionPeriod.value
    })
  }
  const handleRepetitionPeriodValueChange = ({ value, label }) => {
    onRepetitionPeriodChange({
      every: repetitionPeriod.every,
      value: Number(value)
    })
  }

  const repetitionTypeOptions = [
    { value: REPETITION_TYPE.NONE, label: "No Repetition" },
    { value: REPETITION_TYPE.SELECT_DAYS, label: "Selected days" },
    { value: REPETITION_TYPE.CUSTOM, label: "Custom" },
  ]

  const repetitionEndDateTypeOptions = [
    { value: REPETITION_END_DATE_TYPE.NEVER, label: "Never" },
    { value: REPETITION_END_DATE_TYPE.END_AFTER, label: "End After" },
  ]

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
      >
        <Text $fontSize="body" $marginBottom={1} >
          Repetition
        </Text>
        <SelectBox
          $onChange={({ value }) => {
            onRepetitionTypeChanged(value)
          }}
          $trailing={<Icon $icon="ArrowDropDown" />}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $value={repetitionType ? repetitionTypeOptions.filter(e => e.value == repetitionType)[0] : null}
          $options={repetitionTypeOptions}
        />
        {(repetitionType == REPETITION_TYPE.SELECT_DAYS || repetitionType == REPETITION_TYPE.CUSTOM) && <Box $width="100%">
          {repetitionType == REPETITION_TYPE.SELECT_DAYS && <Box>
            <br />
            <Text $fontSize="body" $marginBottom={1} >
              Days
            </Text>
            <FlexBox $justifyContent="space-between">
              {[
                { label: "Mon", value: DAYS.MON },
                { label: "Tue", value: DAYS.TUE },
                { label: "Wed", value: DAYS.WED },
                { label: "Thu", value: DAYS.THU },
                { label: "Fri", value: DAYS.FRI },
                { label: "Sat", value: DAYS.SAT },
                { label: "Sun", value: DAYS.SUN }
              ].map(
                (value, index) => {
                  return (
                    <Box key={index}>
                      <Text
                        $color={"text"}
                        $fontSize={"body-sm"}
                        $fontWeight={"600"}
                        $marginLeft={2}
                      >
                        {value.label}
                      </Text>
                      <CheckBox
                        $checked={days && days.includes(value.value) ? true : false}
                        $size={"medium"}
                        $onChange={(val) => {
                          const tempDays = new Set(days)
                          if (val.target.checked) {
                            tempDays.add(value.value)
                          } else {
                            tempDays.delete(value.value)
                          }
                          const daysArray = []
                          tempDays.forEach((d) => {
                            daysArray.push(d)
                          })
                          onDaysChanged(daysArray)
                        }}
                      />
                    </Box>
                  );
                }
              )}
            </FlexBox>
          </Box>}
          <br />
          <Text $fontSize="body" $marginBottom={1} >
            Repeats every
          </Text>
          <FlexBox $width="100%" $justifyContent="space-between">
            <SelectBox
              $onChange={handleRepetitionPeriodValueChange}
              $trailing={<Icon $icon="ArrowDropDown" />}
              $width="20%"
              $borderRadius="8px"
              $border="1px solid black"
              $value={repetitionPeriod.value != null ? { value: repetitionPeriod.value, label: repetitionPeriod.value } : null}
              $options={Array.from(Array(365), (val, i) => {
                const index = i + 1
                return { value: index, label: `${index}` }
              })}
            />

            <SelectBox
              $onChange={handleRepetitionPeriodEveryChange}
              $trailing={<Icon $icon="ArrowDropDown" />}
              $width="75%"
              $borderRadius="8px"
              $border="1px solid black"
              $value={repetitionPeriod.every ? {
                value: repetitionPeriod.every,
                label: repetitionPeriod.every == REPETITION_PERIODS.DAY ? "Day" :
                  repetitionPeriod.every == REPETITION_PERIODS.WEEK ? "Week" :
                    repetitionPeriod.every == REPETITION_PERIODS.MONTH ? "Month" :
                      repetitionPeriod.every == REPETITION_PERIODS.YEAR ? "Year" : ""
              } : null}
              $options={repetitionPeriodOptions ? repetitionPeriodOptions?.map(rpo => {
                let label = ``
                if (rpo == REPETITION_PERIODS.DAY) label = "Day"
                if (rpo == REPETITION_PERIODS.WEEK) label = "Week"
                if (rpo == REPETITION_PERIODS.MONTH) label = "Month"
                if (rpo == REPETITION_PERIODS.YEAR) label = "Year"

                return { value: rpo, label: `${label}` }
              }) : []}
            />
          </FlexBox>
          <br />
          <Text $fontSize="body" $marginBottom={1} >
            Starts
          </Text>
          <TextBox
            $type={"date"}
            $value={formateDateToDateTextboxFormat(repetitionStartDate)}
            $onChange={(e) => {
              if (onRepetitionStartDateChanged) onRepetitionStartDateChanged(formateDateTextboxFormatToDate(e.target.value))
            }}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1} >
            Ends
          </Text>
          <SelectBox
            $onChange={({ value }) => {
              onRepetitionEndDateTypeChanged(value)
            }}
            $trailing={<Icon $icon="ArrowDropDown" />}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
            $value={repetitionEndDateTypeOptions ? repetitionEndDateTypeOptions.filter(e => e.value == repetitionEndDateType)[0] : null}
            $options={repetitionEndDateTypeOptions}
          />
          <Box $marginTop={5} />
          {repetitionEndDateType == REPETITION_END_DATE_TYPE.END_AFTER && <TextBox
            $hint="name"
            $type={"date"}
            $value={formateDateToDateTextboxFormat(repetitionEndDate)}
            $onChange={(e) => {
              onRepetitionEndDateChanged(formateDateTextboxFormatToDate(e.target.value));
            }}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />}
        </Box>}
      </FlexBox>
    </>
  );
};

export default Repetition;
