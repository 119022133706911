import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useCompliancesSettingsController } from '../SettingsControllers/useCompliancesController'

export default function CompliancesSettings() {
  const controller = useCompliancesSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
