import React, { useRef, useEffect, useState } from 'react';
import Chart from "chart.js/auto";
import { color } from '../Theme';
/**
 * 
 * @param {*} data
 *  data = [{
 *  label: "label",
 *  value: 4
 * }] 
 * @returns 
 */
export function BarChart({ data, title, units }) {
    const canvas = useRef()
    const [myChart, setMyChart] = useState(null)

    const renderChat = () => {
        let chart
        if (myChart) {
            myChart.destroy()
        }

        chart = new Chart(canvas.current, {
            type: 'bar',
            data: {
                labels: data?.map(d => d.label),
                datasets: [{
                    data: data?.map(d => d.value),
                    backgroundColor: '#FF9900',
                    barThickness: 30,
                    fill: false,
                    backgroundColor: `${color("primary")}30`,
                    borderColor: color("primary"),
                    borderWidth: 1,
                }]
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    title: {
                        display: title ? true : false,
                        text: title
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                if (units) return `${tooltipItem.formattedValue} ${units}`
                                return tooltipItem.formattedValue;
                            }
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                label: "label",
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        setMyChart(chart)
    }

    useEffect(() => {
        renderChat()
    }, [data, title])

    return (
        <canvas ref={canvas} style={{ height: "100%", width: "100%" }}></canvas>
    )
}
