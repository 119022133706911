import React, { useState } from 'react'
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes'
import { Button, SelectBox, TextBox } from '../../../Components/Input'
import { Text } from '../../../Components/Text'
import CircularLoader from '../../../Components/Icons/circularLoader'
import { useGlobalMedicineFormsSelector } from '../../../redux/selectors/useConfigsSelectors'
import Icon from '../../../Components/Icons'
import FormDialog from '../../../Components/Modals/FormDialog'
import AddConfigMedicationsIngredientsDialog from '../Dialogs/AddConfigMedicationsIngredientsDialog';
import { useResponsive } from '../../../Components/Hooks'
import { DOCUMENT_STATUS } from '../../../constants'

export default function AddConfigMedicationsDialog(props) {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        ingredient: editingIngredient,
        loading,
        errorMessage,
        onDelete,
        onSave,
    } = props

    const [name, setName] = useState(editingIngredient ? editingIngredient.name : null)
    const [status, setStatus] = useState(editingIngredient ? editingIngredient.status : DOCUMENT_STATUS.ACTIVE)
    const [strengthByUnit, setStrengthByUnit] = useState(editingIngredient ? {
        value: {
            value: editingIngredient?.strengthByUnit?.value?.value,
            units: editingIngredient?.strengthByUnit?.value?.units,
        },
        per: {
            value: editingIngredient?.strengthByUnit?.per?.value,
            units: editingIngredient?.strengthByUnit?.per?.units,
        }
    } : {
        value: {},
        per: {}
    })
    console.log(strengthByUnit)
    /*
        name: ingredient?.name,
        status: ingredient?.status,
        strengthByUnit: {
            value: {
                value: ingredient?.strengthByUnit?.value?.value,
                units: ingredient?.strengthByUnit?.value?.units,
            },
            per: {
                value: ingredient?.strengthByUnit?.per?.value,
                units: ingredient?.strengthByUnit?.per?.units,
            }
        }
     */

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Name
                </Text>
                <TextBox
                    $hint={"Paracetamol"}
                    $type={"text"}
                    $name={"text"}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />

                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Strength
                </Text>
                <FlexBox>
                    <TextBox
                        $hint={"2"}
                        $type={"number"}
                        $name={"text"}
                        $value={strengthByUnit.value.value}
                        $onChange={(e) => {
                            strengthByUnit.value.value = parseInt(e.target.value)
                            setStrengthByUnit({ ...strengthByUnit })
                        }}
                        $width="100%"
                        $borderRadius="8px"
                        $border="1px solid black"
                        $marginRight={1}
                    />
                    <TextBox
                        $hint={"mg"}
                        $type={"text"}
                        $name={"text"}
                        $value={strengthByUnit.value.units}
                        $onChange={(e) => {
                            strengthByUnit.value.units = e.target.value
                            setStrengthByUnit({ ...strengthByUnit })
                        }}
                        $width="100%"
                        $borderRadius="8px"
                        $border="1px solid black"
                        $marginRight={3}
                    />
                    <Text> / </Text>
                    <TextBox
                        $hint={"2"}
                        $type={"number"}
                        $name={"text"}
                        $value={strengthByUnit.per.value}
                        $onChange={(e) => {
                            strengthByUnit.per.value =  parseInt(e.target.value)
                            setStrengthByUnit({ ...strengthByUnit })
                        }}
                        $width="100%"
                        $borderRadius="8px"
                        $border="1px solid black"
                        $marginRight={1}
                        $marginLeft={3}
                    />
                    <TextBox
                        $hint={"Tablets"}
                        $type={"text"}
                        $name={"text"}
                        $value={strengthByUnit.per.units}
                        $onChange={(e) => {
                            strengthByUnit.per.units = e.target.value
                            setStrengthByUnit({ ...strengthByUnit })
                        }}
                        $width="100%"
                        $borderRadius="8px"
                        $border="1px solid black"
                    />
                </FlexBox>
            </FlexBox>

            <Box
                $padding={2}
            >
                {errorMessage && (
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                )}
            </Box>

            <FlexBox
                $justifyContent={!editingIngredient ? "flex-end" : "space-between"}
                $padding={2}
                $backgroundColor="list"
            >
                {editingIngredient && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onDelete()
                    }}
                >
                    {!loading ? 'Delete' : <CircularLoader $color="white" $size={25} />}
                </Button>}
                <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onSave({
                            name,
                            status,
                            strengthByUnit,
                        })
                    }}
                >
                    {!loading ? 'Save' : <CircularLoader $color="white" $size={25} />}
                </Button>
            </FlexBox>
        </>
    )
}
