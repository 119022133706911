import React from 'react'
import { useTimeOffApproversSettingsController } from '../SettingsControllers/useTimeOffApproversController'
import AddConfigsNames from '../AddConfigsNames'

export default function TimeOffApproversSettings() {
    const controller = useTimeOffApproversSettingsController()
    return (
      <AddConfigsNames {...controller} isStaffList={true}/>
    )
}
