import ApiService from "../../restapi";
import { formateDateToISOString } from "../../../utiles/dateformate";

export const createRiskAssessmentApiService = async ({
  name,
  riskRating,
  assessments,
  carriedOutBy,
  assessmentDate,
  nextReviewDate,
  clientId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      name: name,
      riskRating: riskRating,
      assessments: assessments,
      carriedOutBy: carriedOutBy,
      assessmentDate: assessmentDate ? formateDateToISOString(assessmentDate) : undefined,
      nextReviewDate: nextReviewDate ? formateDateToISOString(nextReviewDate) : undefined,
    },
  });
};

export const getAllRiskAssessmentApiService = async ({
  clientId,
  startAfter,
  limit,
  isArchived,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments`,
    query: {
      startAfter,
      limit: limit,
      isArchived: isArchived,
    },
    method: "GET",
    headers: {},
  });
};

export const getSingleRiskAssessmentApiService = async ({
  clientId,
  fileId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments/${fileId}`,
    query: {},
    method: "GET",
    headers: {},
  });
};

export const updateRiskAssessmentApiService = async ({
  clientId,
  riskAssessmentId,
  assessmentDate,
  nextReviewDate,
  carriedOutBy,
}) => {
  const body = {
    carriedOutBy: carriedOutBy,
  };
  if (assessmentDate) body.assessmentDate = formateDateToISOString(assessmentDate);
  if (nextReviewDate) body.nextReviewDate = formateDateToISOString(nextReviewDate);
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments/${riskAssessmentId}`,
    query: {},
    method: "PUT",
    headers: {},
    body: body,
  });
};

export const deleteRiskAssessmentApiService = async ({
  clientId,
  riskAssessmentId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments/${riskAssessmentId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};

export const restoreArchivedRiskAssessmentApiService = async ({
  clientId,
  id,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/riskassessments/${id}/restore`,
    query: {},
    method: "PUT",
    headers: {},
  })
}