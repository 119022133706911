import ApiService from "../../restapi"

export const getFilesApiService = async ({
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files`,
        query: {
            startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    })
}

export const getFileApiService = async ({
    clientId,
    fileId,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files/${fileId}`,
        query: {},
        method: "GET",
        headers: {},
    })
}

export const createFileApiService = async ({
    clientId,
    fileName,
    notes,
    shareWithCarers,
    file,
    onUploadProgress,
}) => {
    let formData = new FormData();
    formData.append("fileName", fileName);
    formData.append("notes", notes);
    formData.append("shareWithCarers", shareWithCarers);
    formData.append("file", file);

    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files`,
        query: {},
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        body: formData,
        onUploadProgress: onUploadProgress,
    })
}

export const updateFileApiService = async ({
    clientId,
    fileId,
    fileName,
    notes,
    shareWithCarers,
    onUploadProgress,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files/${fileId}`,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            fileName: fileName,
            notes: notes,
            shareWithCarers: shareWithCarers,
        },
        onUploadProgress: onUploadProgress,
    })
}

export const deleteFileApiService = async ({
    clientId,
    fileId,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files/${fileId}`,
        query: {},
        method: "DELETE",
        headers: {},
    })
}

export const restoreArchivedFileApiService = async ({
    clientId,
    id,
}) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/files/${id}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    })
}