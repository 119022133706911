import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';
import anime from 'animejs';
import { color } from '../Theme';

const EmptyState = () => {
    const messageRef = useRef(null);

    useEffect(() => {
        anime({
            targets: messageRef.current,
            opacity: [0, 1],
            translateY: [-200, 0], // Change the start position of the animation to -100
            duration: 1000,
            easing: 'easeOutExpo',
        });
    }, []);

    return (
        <>
            <Typography ref={messageRef} variant="h6" gutterBottom color={color("primary")}>
              No records found
            </Typography>
        </>
    );
}

export default EmptyState;