import { useSelector } from "react-redux"

export const useFilesSelector = () => useSelector((state) => {
    return state.files.files
})

export const useFileSelector = (fileId) => useSelector((state) => {
    return state.files.files.find(file => file.id === fileId)
})

export const useFileUploadPercentageSelector = () => useSelector((state) => {
    return state.files.fileUploadPercentage
})

// ------------------- selector error messages ------------------ //
export const useFileCreateErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.createFileErrorMessage
})

export const useFileDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.deleteFileErrorMessage
})

export const useFileUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.updateFileErrorMessage
})

export const useFilesGetErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.getFilesErrorMessage
})

export const useFileGetErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.getFileErrorMessage
})

export const useFileRestoreErrorMessageSelector = () => useSelector((state) => {
    return state.files.errorMessages.restoreFileErrorMessage;
});

// ------------ status selectors ------------ //
export const useFileCreateStatusSelector = () => useSelector((state) => {
    return state.files.statuses.createFileStatus
})

export const useFileDeleteStatusSelector = () => useSelector((state) => {
    return state.files.statuses.deleteFileStatus
})

export const useFileUpdateStatusSelector = () => useSelector((state) => {
    return state.files.statuses.updateFileStatus
})

export const useFilesGetStatusSelector = () => useSelector((state) => {
    return state.files.statuses.getFilesStatus
})

export const useFileGetStatusSelector = () => useSelector((state) => {
    return state.files.statuses.getFileStatus
})

export const useMoreFilesLeftInPaginationSelector = () => useSelector((state) => {
    return state.files.moreFilesLeftInPagination
})

export const useFileRestoreStatusSelector = () => useSelector((state) => {
    return state.files.statuses.restoreFileStatus;
});