import React from "react";
import {
  DefaultFlexModifiers,
  DefaultSpacingModifiers,
  DefaultSizeModifiers,
  DefaultBackgroundModifier,
  DefaultPositionModifiers,
  DefaultRadiusModifiers,
  DefaultBorderModifiers,
  DefaultModifiers,
} from "../Modifiers/index";
import { useResponsive } from "../Hooks/index";
import styled from "styled-components";
import { useState } from "react";
import { useMemo } from "react";

const Wrapper = styled.div`
  ${(props) =>
    props.$horizontalScroll
      ? `
    overflow-x: scroll;
    white-space: nowrap;
    ${props.$showScrollBar
        ? ""
        : `
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
      ::-webkit-scrollbar {
      display: none;
    }`
      }`
      : ""}

  ${(props) =>
    props.$verticalScroll
      ? `
      overflow-y: auto;
      white-space: nowrap;
      ${props.$showScrollBar
        ? ""
        : `
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;
        ::-webkit-scrollbar {
        display: none;
      }`
      }`
      : ""}

  ${(props) =>
    props.onClick
      ? `
    cursor: pointer;
  `
      : ""}
`;

export default function Index({ children, ...rest }) {
  return (
    <Wrapper
      style={{
        ...DefaultPositionModifiers(rest),
        ...DefaultSpacingModifiers(rest),
        ...DefaultFlexModifiers(rest),
        ...DefaultSizeModifiers(rest),
        ...DefaultBackgroundModifier(rest),
        ...DefaultRadiusModifiers(rest),
        ...DefaultBorderModifiers(rest),
        ...DefaultModifiers(rest),
      }}
      onChange={rest.$onChange}
      onClick={rest.$onClick}
      $horizontalScroll={useResponsive(rest.$horizontalScroll)}
      $verticalScroll={useResponsive(rest.$verticalScroll)}
      $showScrollBar={useResponsive(rest.$showScrollBar)}
      ref={rest.$ref}
      onMouseEnter={rest.$onMouseEnter}
      onMouseLeave={rest.$onMouseLeave}
    >
      {children}
    </Wrapper>
  );
}
