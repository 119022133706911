import React, {useMemo} from "react";
import {Box, ErrorAndSuccess, FlexBox} from "../../../Components/Boxes";
import {
    CheckBox,
    SelectBox,
    Textarea,
    TextBox,
} from "../../../Components/Input";
import {Text} from "../../../Components/Text";
import Icon from "../../../Components/Icons";
import {formateDateTextboxFormatToDate, formateDateToDateTextboxFormat, formateDateToReadableFormatWithTime} from "../../../utiles/dateformate";
import {formateTimeMinutesToTimeTextboxFormat, formateTimeTextboxFormatToTimeMinutes} from "../../../utiles/timeformate";
import {DISTRIBUTABLE_STATUS, EVENT_TYPE, TIME_SHEET_TIME_AMMEND_STATUS} from "../../../constants";
import {MultiSearchUserSelectBox, SearchUserSelectBox} from "../../components/searchSelectBox"
import {useGlobalEventCheckListsSelector, useGlobalJobRolesSelector, useGlobalServicesSelector} from "../../../redux/selectors/useConfigsSelectors";
import {GridContainer, Grid} from "../../../Components/Grid";
import usePermissionsController from "../../../permissions";
import {ratingEmoji} from '../../../Components/Theme';
import Button from '../../../Components/Input/Button';

const General = (props) => {
    const {
        loading,
        editEvent,
        shiftReviewRating,
        eventType,
        staff,
        flexibleStart,
        breakDuration,
        jobRole,
        instructions,
        notice,
        services,
        additionalStaff,
        requiredNumStaff,
        distribute,

        startDate,
        startTime,
        endDate,
        endTime,

        name,
        distributeStatus,
        distributeAcceptedAt,
        requiredChecklistAcceptance,

        onStaffChanged,
        onFlexibleStartChanged,
        onBreakDurationChanged,
        onJobRoleChanged,
        onInstructionsChanged,
        onNoticeChanged,
        onServiceChanged,
        onAdditionalStaffChanged,
        onRequiredNumStaffChanged,
        onDistributeChanged,

        onStartDateChanged,
        onStartTimeChanged,
        onEndDateChanged,
        onEndTimeChanged,

        onNameChanged,

        onDistributeAssignmentAccepted,
        onDistributeAssignmentRejected,
        onRequiredChecklistAcceptanceChanged,
    } = props

    const globalServiceName = useGlobalServicesSelector()
    const globalJobRoles = useGlobalJobRolesSelector()
    const checklists = useGlobalEventCheckListsSelector()

    const options = useMemo(() => {
        return globalServiceName?.map(name => {
            return {value: name, label: name}
        })
    }, [globalServiceName]);

    const jobRoleOptions = useMemo(() => {
        return globalJobRoles?.map(name => {
            return {value: name, label: name}
        })
    }, [globalJobRoles]);

    const mappedItems = services?.map(item => ({
        value: item,
        label: item
    }));

    // EVENT_TYPE.AVAILABILITY

    const handleServicesChange = (selected) => {
        const values = selected?.map((item) => item.value) || [];
        onServiceChanged(values)
    };

    function handleFlexibleStartChange({value}) {
        onFlexibleStartChanged(Number(value))
    }

    function handleBreakDurationChange({value}) {
        onBreakDurationChanged(Number(value))
    }

    const {
        allowShiftDistribution,
        allowCreateEventSelectServices,
        defaultCreateEventUserTitle,
        allowEventFlexibleStartTime,
        allowEventBreakDuration,
        allowSelectAdditionalStaff,
        allowMultiDayEvent,
        allowShiftJobRoles,
        accessViewSettingsEventCheckLists,
    } = usePermissionsController()

    const enterEndDate = useMemo(() => {
        if (eventType === EVENT_TYPE.ABSENCE) return false
        if (eventType === EVENT_TYPE.AVAILABILITY) return false
        return allowMultiDayEvent
    }, [eventType, allowMultiDayEvent])

    const flexibleStartOptions = useMemo(() => [
        {value: 0, label: "None"},
        {value: 10, label: "10 Mins"},
        {value: 15, label: "15 Mins"},
        {value: 20, label: "20 Mins"},
        {value: 30, label: "30 Mins"},
        {value: 45, label: "45 Mins"},
        {value: 60, label: "60 Mins"},
    ], [])

    const breakDurationOptions = useMemo(() => [
        {value: 0, label: "None"},
        {value: 10, label: "10 Mins"},
        {value: 15, label: "15 Mins"},
        {value: 20, label: "20 Mins"},
        {value: 30, label: "30 Mins"},
        {value: 45, label: "45 Mins"},
        {value: 60, label: "1hr"},
        {value: 70, label: "1hr 10 mins"},
        {value: 80, label: "1hr 20 mins"},
        {value: 90, label: "1hr 30 mins"},
        {value: 105, label: "1hr 45 mins"},
        {value: 120, label: "2"},
        {value: 130, label: "2hr 10 mins"},
        {value: 140, label: "2hr 20 mins"},
        {value: 150, label: "2hr 30 mins"},
        {value: 165, label: "2hr 45 mins"},
        {value: 180, label: "3hr"},
    ], [])

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
            >
                {distributeStatus === DISTRIBUTABLE_STATUS.PENDING_APPROVAL && <Box $width={"100%"} $marginBottom={3} $padding={2} $backgroundColor="note" $borderRadius="5px">
                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        This shift was recently picked by { staff?.name ||  'a staff'} on {formateDateToReadableFormatWithTime(distributeAcceptedAt)}.
                    </Text>

                    <Text $wordWrap="anywhere" $marginBottom={1} $paddingLeft={1} $paddingRight={1} $paddingBottom={1}>
                        Please approve or reject the assignment within 2 hours of the shift being picked
                    </Text>

                    <hr />

                    <FlexBox $justifyContent="flex-end" $paddingBottom={1}>
                        <Button
                            $type="submit"
                            $variant={"outlined"}
                            $size="small"
                            $loading={loading}
                            $onClick={() => {
                                onDistributeAssignmentRejected()
                            }}
                        >
                            Reject
                        </Button>
                        <Box $marginRight={3} />
                        <Button
                            $type="submit"
                            $variant={"contained"}
                            $size="small"
                            $loading={loading}
                            $onClick={() => {
                                onDistributeAssignmentAccepted()
                            }}
                        >
                            Accept
                        </Button>
                    </FlexBox>

                    <Box $marginTop={"3"} />
                </Box>}

                {allowSelectAdditionalStaff && (eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <>
                    <Text $fontSize="body" $marginBottom={1}>
                        Required Number Of {defaultCreateEventUserTitle ?? "Carer"}s
                    </Text>
                    <SelectBox
                        $onChange={(e) => {
                            onRequiredNumStaffChanged(Number(e.value))
                        }}
                        $trailing={<Icon $icon="ArrowDropDown"/>}
                        $width="100%"
                        $borderRadius="8px"
                        $border="1px solid black"
                        $value={requiredNumStaff != null ? {value: requiredNumStaff, label: requiredNumStaff} : null}
                        $options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(e => ({value: e, label: e}))}
                    />
                    <Box $marginTop={3}/>
                </>}

                {(eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <>
                    <Text $fontSize="body" $marginBottom={1}>
                        Main {defaultCreateEventUserTitle ?? "Carer"} {/*on startDate ? formateDateToReadableFormatWithoutTime(startDate) : ""*/}
                    </Text>

                    <GridContainer>
                        <Grid $xs={staff ? 11 : 12}>
                            <SearchUserSelectBox
                                searchByStaff
                                selected={staff}
                                onChange={(user) => {
                                    onStaffChanged(user)
                                }}
                            />
                        </Grid>
                        {staff && <Grid $xs={1}>
                            <FlexBox $marginTop="2" $justifyContent="flex-end" $width="100%">
                                <Icon $icon="Close" $color="red" $pointer="pointer" $onClick={() => onStaffChanged(null)}/>
                            </FlexBox>
                        </Grid>}

                    </GridContainer>
                </>}

                {allowSelectAdditionalStaff && (eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <>
                    {requiredNumStaff > 1 && <>
                        <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                            Additional {defaultCreateEventUserTitle ?? "Carer"}s ({additionalStaff.length} / {requiredNumStaff - 1})
                        </Text>
                        <MultiSearchUserSelectBox
                            searchByStaff
                            selected={additionalStaff}
                            onChange={(e) => {
                                onAdditionalStaffChanged(e)
                            }}
                            maxNumberOptionsEntered={requiredNumStaff - 1}
                        />
                    </>}
                    <Box $marginTop={3}/>
                    <hr style={{width: "100%"}}/>
                </>}

                {(eventType === EVENT_TYPE.ABSENCE || eventType === EVENT_TYPE.CUSTOM) && <Text $fontSize="body" $marginBottom={1}>
                    Title
                </Text>}
                {(eventType === EVENT_TYPE.ABSENCE || eventType === EVENT_TYPE.CUSTOM) && <TextBox
                    $type={"text"}
                    $value={name}
                    $onChange={(e) => onNameChanged(e.target.value)}
                />}

                {allowShiftJobRoles && (eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <Box $marginTop={3}/>}

                {allowShiftJobRoles && (eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <Text $fontSize="body" $marginBottom={1}>
                    Job Role
                </Text>}
                {allowShiftJobRoles && (eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && <SelectBox
                    $onChange={(e) => {
                        onJobRoleChanged(e.value)
                    }}
                    $trailing={<Icon $icon="ArrowDropDown"/>}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                    $value={jobRole != null ? {value: jobRole, label: jobRole} : null}
                    $options={jobRoleOptions}
                />}

                <Box $marginTop={3}/>

                <GridContainer $marginTop={3} $spacing={1}>
                    <Grid $xs={enterEndDate ? 8 : 12}>
                        <Text $fontSize="body" $marginBottom={1}>
                            Start Date
                        </Text>
                        <TextBox
                            $type={"date"}
                            $value={formateDateToDateTextboxFormat(startDate)}
                            $onChange={(e) => {
                                const date = formateDateTextboxFormatToDate(e.target.value)
                                onStartDateChanged(date);
                                if (!enterEndDate) {
                                    onEndDateChanged(date);
                                }
                            }}
                            $width="100%"
                        />
                    </Grid>
                    <Grid $xs={enterEndDate ? 4 : 6}>
                        <Text $fontSize="body" $marginBottom={1}>
                            Start Time
                        </Text>
                        <TextBox
                            $type={"time"}
                            $value={formateTimeMinutesToTimeTextboxFormat(startTime)}
                            $onChange={(e) => {
                                onStartTimeChanged(formateTimeTextboxFormatToTimeMinutes(e.target.value))
                            }}
                            $width="100%"
                        />
                    </Grid>

                    {enterEndDate && <Grid $xs={8}>
                        <Text $fontSize="body" $marginBottom={1}>
                            End Date
                        </Text>
                        <TextBox
                            $type={"date"}
                            $value={formateDateToDateTextboxFormat(endDate)}
                            $onChange={(e) => {
                                onEndDateChanged(formateDateTextboxFormatToDate(e.target.value));
                            }}
                            $width="100%"
                        />
                    </Grid>}

                    <Grid $xs={enterEndDate ? 4 : 6}>
                        <Text $fontSize="body" $marginBottom={1}>
                            End Time
                        </Text>
                        <TextBox
                            $type={"time"}
                            $value={formateTimeMinutesToTimeTextboxFormat(endTime)}
                            $onChange={(e) => {
                                onEndTimeChanged(formateTimeTextboxFormatToTimeMinutes(e.target.value))
                            }}
                            $width="100%"
                        />
                    </Grid>

                </GridContainer>

                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && allowEventFlexibleStartTime &&
                    <Box $width={{xs: "100%"}} $marginTop={3}>
                        <Text $fontSize="body" $marginBottom={1} $width="100%">
                            Flexible Start (mins)
                        </Text>
                        <SelectBox
                            $onChange={handleFlexibleStartChange}
                            $trailing={<Icon $icon="ArrowDropDown"/>}
                            $width="100%"
                            $borderRadius="8px"
                            $border="1px solid black"
                            $value={flexibleStart != null ? flexibleStartOptions.filter(e => e.value === flexibleStart)[0] : null}
                            $options={flexibleStartOptions}
                        />
                    </Box>}

                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && allowEventBreakDuration &&
                    <Box $width={{xs: "100%"}} $marginTop={3}>
                        <Text $fontSize="body" $marginBottom={1} $width="100%">
                            Break duration (mins)
                        </Text>
                        <SelectBox
                            $onChange={handleBreakDurationChange}
                            $trailing={<Icon $icon="ArrowDropDown"/>}
                            $width="100%"
                            $borderRadius="8px"
                            $border="1px solid black"
                            $value={breakDuration != null ? breakDurationOptions.filter(e => e.value === breakDuration)[0] : null}
                            $options={breakDurationOptions}
                        />
                    </Box>}

                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                    Instruction
                </Text>}
                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && <Textarea
                    $type={"text"}
                    $value={instructions}
                    $onChange={(e) => onInstructionsChanged(e.target.value)}
                    $rows={5}
                    $width="100%"
                />}

                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                    Notice (Max 50 characters)
                </Text>}
                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && <TextBox
                    $value={notice}
                    $onChange={(e) => onNoticeChanged(e.target.value)}
                    $width="100%"
                    $maxLength={100}
                />}

                {shiftReviewRating && <Box $marginTop={3} $width="100%">
                    <Text $fontSize="body" $marginBottom={1}>
                        Shift review: {ratingEmoji(shiftReviewRating)}
                    </Text>
                </Box>}

                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && allowCreateEventSelectServices &&
                    <Text $fontSize="body" $marginBottom={1} $marginTop={3}>
                        Services
                    </Text>}
                {eventType !== EVENT_TYPE.ABSENCE && eventType !== EVENT_TYPE.AVAILABILITY && allowCreateEventSelectServices && <Box $width="100%">
                    <SelectBox
                        $isMulti={true}
                        $options={options}
                        $onChange={handleServicesChange}
                        $value={mappedItems}
                    ></SelectBox>
                </Box>}

                {(eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && checklists && checklists.length > 0 && accessViewSettingsEventCheckLists && <Box $marginTop="4">
                    <FlexBox>
                        <CheckBox
                            $checked={requiredChecklistAcceptance === true}
                            $size={"medium"}
                            $onChange={(val) => {
                                onRequiredChecklistAcceptanceChanged(!requiredChecklistAcceptance)
                            }}
                        />
                        <Text $fontSize="body">Should staff accept shift checklist before viewing shift?</Text>
                    </FlexBox>
                </Box>}

                {(eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT) && allowShiftDistribution && !staff && <Box $marginTop="4">
                    <FlexBox>
                        <CheckBox
                            $checked={distribute === true}
                            $size={"medium"}
                            $onChange={(val) => {
                                onDistributeChanged(!distribute)
                            }}
                        />
                        <Text $fontSize="body">Distribute as pickable shift?</Text>
                    </FlexBox>
                </Box>}
            </FlexBox>
        </>
    );
};

export default General;
