import React from "react";
import { Box, FlexBox, CircleBox } from "../Boxes/index";
import { Text, SpanText } from "../Text/index";
import Icon from "../Icons/index";
import { useResponsive } from "../Hooks/index";
import { Link } from "react-router-dom";

export function BreadCrumbs({ $crumbs }) {
    const isDesktop = useResponsive({ xs: false, sm: true });

    return (
        <FlexBox
            $width="100%"
            $height="39px"
            $paddingLeft={isDesktop ? 4 : 2}
        // $border="1px solid red"
        >
            <Text $fontSize={"body"} $color="primary" $fontWeight={"600"}>
                {$crumbs?.map((crumb, index) => {
                    return (
                        <Link to={crumb.path} style={{ textDecoration: "none" }}>
                            {" "}
                            <SpanText $fontSize={"body"} $color={index < ($crumbs.length - 1) ? "primary" : null}>
                                {crumb.label}
                            </SpanText>{" "}

                            {index < ($crumbs.length - 1) && <SpanText>
                                <Icon
                                    $icon="ArrowForwardIos"
                                    $size={12}
                                    $color="primary"
                                    $marginLeft={"6px"}
                                />
                                &nbsp;
                            </SpanText>}
                        </Link>
                    )
                })}
            </Text>
        </FlexBox>
    );
}
