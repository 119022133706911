import { useSelector } from "react-redux";

export const useAllBlockListSelector = () => useSelector((state) => {
  return state.blockList.allBlockList;
});

// ------------------- selector error messages ------------------ //
export const useBlockListCreateErrorMessageSelector = () => useSelector((state) => {
  return state.blockList.errorMessages.createBlockListErrorMessage;
});

export const useBlockListGetErrorMessageSelector = () => useSelector((state) => {
  return state.blockList.errorMessages.getAllBlockListErrorMessage;
});

export const useBlockListDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.blockList.errorMessages.deleteBlockListErrorMessage;
});

export const useBlockListUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.blockList.errorMessages.updateBlockListErrorMessage;
});

export const useBlockListRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.blockList.errorMessages.restoreBlockListErrorMessage;
});

// ------------ status selectors ------------ //
export const useBlockListCreateStatusSelector = () => useSelector((state) => {
  return state.blockList.statuses.createBlockListStatus;
});

export const useAllBlockListGetStatusSelector = () => useSelector((state) => {
  return state.blockList.statuses.getAllBlockListStatus;
});

export const useBlockListDeleteStatusSelector = () => useSelector((state) => {
  return state.blockList.statuses.deleteBlockListStatus;
});

export const useBlockListUpdateStatusSelector = () => useSelector((state) => {
  return state.blockList.statuses.updateBlockListStatus;
});

export const useMoreBlockListItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.blockList.moreBlockListItemsLeftInPagination
})

export const useBlockListRestoreStatusSelector = () => useSelector((state) => {
  return state.blockList.statuses.restoreBlockListStatus;
});