import React, { useEffect, useState } from "react";
import {
    Box,
    CircleBox,
    ErrorAndSuccess,
    FlexBox,
    ListBox,
} from "../../../../../Components/Boxes/index";
import { useResponsive } from "../../../../../Components/Hooks";
import Icon from "../../../../../Components/Icons";
import { Button, DropDownButton } from "../../../../../Components/Input";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import { ClientAccessDialog } from "../Dialogs/ClientAccess/ClientAccessDialog";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import { formateDateToReadableFormatWithoutTime } from "../../../../../utiles/dateformate";
import useClientAccessDispatchers from "../../../../../redux/dispatchers/useClientAccessDispatchers";
import {
    useClientAccessDeleteErrorMessageSelector,
    useClientAccessDeleteStatusSelector,
    useClientAccessGetErrorMessageSelector,
    useClientAccessGetStatusSelector,
    useClientAccessResendErrorMessageSelector,
    useClientAccessResendStatusSelector,
    useClientAccessSelector,
    useMoreClientAccessItemsLeftInPaginationSelector,
} from "../../../../../redux/selectors/useClientAccessSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";
import { CLIENT_ACCESS_STATUS } from "../../../../../constants";
import { ConfirmationDialog, DropDownMenuItem } from "../../../../../Components/Modals";
import CircularLoader from "../../../../../Components/Icons/circularLoader";

const ClientViewAccess = ({ clientId }) => {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const [confirmationDialog, setConfirmationDialog] = useState(null)
    const {
        accessArchives
    } = usePermissionsController()

    // ---------------- states ------------------------
    const [open, setOpen] = useState(false);
    function HandleClientAccessDialog() {
        setOpen(!open);
    }

    const [type, setType] = useState("");

    //storing the selected clientAccess and sending to the dialog
    const [clientAccessToEdit, setClientAccessToEdit] = useState(null);
    const [isArchived, setIsArchived] = useState(false)

    // ---------------- reducers ----------------------
    const { getClientAccess, deleteClientAccess, clearAllLocalClientAccess, clearAllErrorMessages, resendClientAccess } = useClientAccessDispatchers();

    // ---------------- selectors ---------------------
    const allClientAccess = useClientAccessSelector();
    const getClientAccessStatus = useClientAccessGetStatusSelector();
    const deleteClientAccessStatus = useClientAccessDeleteStatusSelector();
    const resendClientAccessStatus = useClientAccessResendStatusSelector();

    const clientAccessGetErrorMessage = useClientAccessGetErrorMessageSelector();
    const clientAccessDeleteErrorMessage = useClientAccessDeleteErrorMessageSelector();
    const clientAccessResendErrorMessage = useClientAccessResendErrorMessageSelector();

    const errorMessage = clientAccessGetErrorMessage || clientAccessDeleteErrorMessage || clientAccessResendErrorMessage;
    const moreClientAccessItemsLeftInPagination = useMoreClientAccessItemsLeftInPaginationSelector()

    // ---------------- useEffect ---------------------
    //Fetching the client from database

    const loadMore = () => {
        clearAllErrorMessages()
        getClientAccess({
            clientId: clientId,
            startAfter: allClientAccess[allClientAccess.length - 1].id,
            isArchived: isArchived,
        });
    }

    useEffect(() => {
        clearAllLocalClientAccess();
        clearAllErrorMessages()
        getClientAccess({
            clientId: clientId,
            isArchived: isArchived,
        });
    }, [isArchived]);

    const handleDeleteClientAccess = async (id) => {
        await deleteClientAccess({
            clientId: clientId,
            clientAccessId: id,
        }).unwrap()
        getClientAccess({
            clientId: clientId,
            isArchived: isArchived,
        });
    }

    const handleResendClientAccess = async (id) => {
        await resendClientAccess({
            clientId: clientId,
            clientAccessId: id,
        }).unwrap()
    }

    return (
        <Box
            $width="100%"
            $backgroundColor="#fff"
        >
            <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
                {accessArchives && <Tabs
                    $onChanged={(val) => {
                        if (val == 0) {
                            setIsArchived(false)
                        } else {
                            setIsArchived(true)
                        }
                    }}
                    $value={isArchived ? 1 : 0}
                    $tabs={[
                        { name: "Active" },
                        { name: "Archived" },
                    ]}
                    $width={"fit-content"}
                />}

                <Button
                    $onClick={() => {
                        HandleClientAccessDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>Client App access</Text>
                </Button>
            </FlexBox>

            {errorMessage && <FlexBox $marginTop={2} $width="100%">
                <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
            </FlexBox>}

            <FlexBox $width="100%" $marginTop={3} $padding="3">
                <Box $width="30%">
                    <Text $fontWeight="bold">
                        Name
                    </Text>
                </Box>
                <Box $width="30%">
                    <Text $fontWeight="bold">
                        Email
                    </Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text>Date created</Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text>Date accepted</Text>
                </Box>
                <Box $width="10%" $display="flex" $justifyContent="center">
                    <Text>Status</Text>
                </Box>
            </FlexBox>

            {getClientAccessStatus === asyncStates.SUCCESS && allClientAccess.length < 1 ? <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
                <EmptyState />
            </FlexBox> : allClientAccess?.map((value, index) => {
                return (
                    <ListBox
                        $width="100%"
                        $marginTop={2}
                        key={index}
                        $index={index}
                        $onClick={() => {
                        }}
                    >
                        <FlexBox $width="100%" >
                            <Box $width="30%">
                                <Text $fontWeight="bold">
                                    {value.name}
                                </Text>
                            </Box>
                            <Box $width="30%">
                                <Text $fontWeight="bold">
                                    {value.email}
                                </Text>
                            </Box>
                            <Box $width="10%" $display="flex" $justifyContent="center">
                                <Text
                                    $fontWeight="bold"
                                    $wordWrap="anywhere"
                                >
                                    {value.creationDate ? formateDateToReadableFormatWithoutTime(value.creationDate) : "-"}
                                </Text>
                            </Box>
                            <Box $width="10%" $display="flex" $justifyContent="center">
                                <Text
                                    $fontWeight="bold"
                                    $wordWrap="anywhere"
                                >
                                    {value.acceptionDate ? formateDateToReadableFormatWithoutTime(value.acceptionDate) : "-"}
                                </Text>
                            </Box>
                            <Box $width="10%" $display="flex" $justifyContent="center">
                                <CircleBox
                                    $size={10}
                                    $backgroundColor={value.invitationStatus === CLIENT_ACCESS_STATUS.ACCEPTED ? "green" : "orange"}
                                    $color={value.invitationStatus === CLIENT_ACCESS_STATUS.ACCEPTED ? "green" : "orange"}
                                />
                            </Box>

                            <Box $width="10%" $display="flex" $justifyContent="center">
                                <DropDownButton
                                    $sx={{ borderRadius: 2 }}
                                    $marginRight={3}
                                    $loading={deleteClientAccessStatus == asyncStates.PENDING || resendClientAccessStatus == asyncStates.PENDING}
                                    $icon={"MoreVert"}
                                >
                                    {value.invitationStatus === CLIENT_ACCESS_STATUS.PENDING && <DropDownMenuItem
                                        $onClick={() => {
                                            setConfirmationDialog({
                                                title: "Confirmation",
                                                description: `Are you sure you want to resend this client access to ${value.email}?`,
                                                onOk: ({ reason }) => {
                                                    handleResendClientAccess(value.id)
                                                },
                                                enterReason: false
                                            })
                                        }}
                                    >
                                        <Icon $icon="Send" $size="large" $color="primary" />
                                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                                            Resend access email
                                        </Text>
                                    </DropDownMenuItem>}
                                    <Box $padding="1" $borderTop="1px solid #f1f1f1"></Box>
                                    {[CLIENT_ACCESS_STATUS.PENDING, CLIENT_ACCESS_STATUS.ACCEPTED].includes(value.invitationStatus) && <DropDownMenuItem
                                        $onClick={() => {
                                            setConfirmationDialog({
                                                title: "Confirmation",
                                                description: "Are you sure you want to remove this client access?",
                                                onOk: ({ reason }) => {
                                                    handleDeleteClientAccess(value.id)
                                                },
                                                enterReason: false
                                            })
                                        }}
                                    >
                                        <Icon $icon="Delete" $size="large" $color="red" />
                                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                                            Remove access
                                        </Text>
                                    </DropDownMenuItem>}
                                </DropDownButton>
                            </Box>
                        </FlexBox>
                    </ListBox>
                );
            })}

            {getClientAccessStatus === asyncStates.PENDING && [1, 2, 3, 4].map((value) => {
                return (
                    <Box $width="100%" $marginTop="2" key={value}>
                        <MuiSkeletonLoader
                            $variant="rectangular"
                            $width={"100%"}
                            $height={40}
                        ></MuiSkeletonLoader>
                    </Box>
                );
            })}

            {moreClientAccessItemsLeftInPagination && <FlexBox $marginTop="6">
                <Button $onClick={() => {
                    loadMore()
                }} >Load More</Button>
            </FlexBox>}

            {open && <FormDialog
                $open={open}
                $handleDialog={HandleClientAccessDialog}
                $title={"Client app access view"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <ClientAccessDialog
                    clientId={clientId}
                    $handleDialog={HandleClientAccessDialog}
                />
            </FormDialog>}

            {confirmationDialog && <ConfirmationDialog
                $title={confirmationDialog.title}
                $description={confirmationDialog.description}
                $onClose={() => {
                    setConfirmationDialog(null)
                }}
                $onConfirm={({ reason }) => {
                    setConfirmationDialog(null)
                    confirmationDialog.onOk({ reason })
                }}
                $enterReason={confirmationDialog.enterReason}
            />}
        </Box >
    );
};

export default ClientViewAccess;
