import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogonWithName from "../../Images/Logos/gps_care_logo.png";
import DefaultImage from "../../Components/Images/index";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { AuthContext } from "../../contextAPI's/authContext";
import BasicAlerts from "../../Components/Boxes/ErrorAndSuccess";
import { Box, FlexBox, ListBox } from "../../Components/Boxes/index";
import CircularLoader from "../../Components/Icons/circularLoader";
import { useResponsive } from "../../Components/Hooks/index";
import { color } from "../../Components/Theme";
import { SpanText, Text } from "../../Components/Text";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color("primary")};
`;

const LoginWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 10px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function SelectAccount(props) {
    const {
        businessAccounts,
        errorMessage,
        loading,
        onSelect,
    } = props
    console.log(businessAccounts)
    const isDesktop = useResponsive({ xs: false, md: true });
    return (
        <Wrapper>
            <LoginWrapper>
                {/* <DefaultImage $width={"20%"} $src={LogonWithName} $objectFit="contain" /> */}
                <FlexBox $width={isDesktop ? "50%" : "70%"} $alignItems="center" $justifyContent="center">
                    <Text $marginBottom="5" $fontSize={"heading-3"} $textAlign={"center"}>Select a business account to log into</Text>
                </FlexBox>
                {businessAccounts && businessAccounts?.map((businessAccount, index) => {
                    return (
                        <ListBox
                            key={businessAccount.id}
                            $index={index}
                            $onClick={() => {
                                if (!loading)
                                    onSelect(index)
                            }}
                        >
                            <FlexBox>
                                <DefaultImage $width={"10%"} $src={businessAccount.businessLogo ? businessAccount.businessLogo : LogonWithName} $objectFit="contain" />
                                <Box $margin="2" />
                                {businessAccount.isClientAccess && <Text $fontSize="body">
                                    {businessAccount.client?.name ?? "Client Access"} <SpanText $fontStyle="italic" > ({businessAccount.businessName})</SpanText>
                                </Text>}
                                {!businessAccount.isClientAccess && <Text $fontSize="body">
                                    {businessAccount.businessName}
                                </Text>}
                            </FlexBox>
                        </ListBox>
                    )
                })}

                {loading && <CircularLoader $size={25} />}
                {errorMessage && (
                    <FlexBox $marginTop={2}>
                        <BasicAlerts $type="error">{errorMessage}</BasicAlerts>
                    </FlexBox>
                )}
                {/* <Link to="/auth/signup">SignUp</Link> */}
            </LoginWrapper>
        </Wrapper>
    )
}
