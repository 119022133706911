import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalAdditionalFormsReducer,
  clearAllSuccessMessagesReducer,
  createAdditionalFormReducer,
  deleteAdditionalFormReducer,
  getAllAdditionalFormReducer,
  restoreArchivedAdditionalFormReducer,
  updateAdditionalFormReducer,
} from "../../slices/additionalFormsSlice";

const useAdditionalFormDispatchers = () => {
  const dispatch = useDispatch();

  const createAdditionalForm = (data) => {
    return dispatch(createAdditionalFormReducer(data));
  };

  const restoreArchivedAdditionalForm = (data) => {
    return dispatch(restoreArchivedAdditionalFormReducer(data))
  }

  const updateAdditionalForm = (data) => {
    return dispatch(updateAdditionalFormReducer(data));
  };

  const getAdditionalForms = (data) => {
    return dispatch(getAllAdditionalFormReducer(data));
  };

  const deleteAdditionalForm = (data) => {
    return dispatch(deleteAdditionalFormReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  const clearAllLocalAdditionalForms = (data) => {
    return dispatch(clearAllLocalAdditionalFormsReducer(data));
  };

  return {
    restoreArchivedAdditionalForm,
    createAdditionalForm,
    getAdditionalForms,
    clearAllErrorMessages,
    updateAdditionalForm,
    deleteAdditionalForm,
    clearAllSuccessMessages,
    clearAllLocalAdditionalForms,
  };
};

export default useAdditionalFormDispatchers;
