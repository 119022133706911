import { useDispatch } from "react-redux";
import {
  createClientReducer,
  getAllClientsReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusReducer,
  getSingleClientReducer,
  updateClientReducer,
  deleteClientReducer,
  clearAllSuccessMessagesReducer,
  sendStaffProfileReducer,
  clearAllLocalClientsReducer,
} from "../../slices/clientSlice";

const useClientDispatchers = () => {
  const dispatch = useDispatch();

  const createClient = (data) => {
    return dispatch(createClientReducer(data));
  };
  const getAllClients = (data) => {
    return dispatch(getAllClientsReducer(data));
  };
  const getSingleClient = (data) => {
    console.log(data)
    return dispatch(getSingleClientReducer(data));
  }

  const updateClient = (data) => {
    console.log(data)
    return dispatch(updateClientReducer(data));
  }

  const deleteClient = (data) => {
    return dispatch(deleteClientReducer(data));
  };

  const sendStaffProfile = (data) => {
    return dispatch(sendStaffProfileReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };
  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };
  const clearAllStatusMessages = (data) => {
    return dispatch(clearAllStatusReducer(data));
  };
  const clearAllLocalClients = (data) => {
    return dispatch(clearAllLocalClientsReducer(data))
  }

  return {
    createClient,
    getAllClients,
    deleteClient,
    updateClient,
    getSingleClient,
    sendStaffProfile,
    clearAllErrorMessages,
    clearAllStatusMessages,
    clearAllSuccessMessages,
    clearAllLocalClients,
  };
};

export default useClientDispatchers;
