import React from 'react'
import { useBusinessAccountEditErrorMessageSelector, useBusinessAccountEditStatusSelector, useBusinessAccountEditSuccessMessageSelector, useBusinessAccountSelector } from "../../../redux/selectors/useBusinessAccountSelectors";
import useBusinessAccountDispatchers from '../../../redux/dispatchers/useBusinessAccountDispatchers';
import { useMemo } from 'react';
import { useState } from 'react';
import { Text } from '../../../Components/Text';
import { Button, Textarea, TextBox } from '../../../Components/Input';
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes';
import * as asyncStates from "../../../redux/constants/asyncStates"
import { useEffect } from 'react';
import { Grid, GridContainer } from '../../../Components/Grid';
import ProfilePhoto from '../../../Components/Images/ProfilePhoto';
import usePermissionsController from '../../../permissions';
// timeOffApprovers
export default function BusinessAccountSettings() {
    const businessAccount = useBusinessAccountSelector()

    const editBusinessAccountSuccessMessage = useBusinessAccountEditSuccessMessageSelector()
    const editBusinessAccountErrorMessage = useBusinessAccountEditErrorMessageSelector()
    const editBusinessAccountStatus = useBusinessAccountEditStatusSelector()
    const { accessEditSettingsBusinessAccount } = usePermissionsController()

    const [id, setId] = useState(businessAccount.id)
    const [name, setName] = useState(businessAccount.businessName)
    const [email, setEmail] = useState(businessAccount.businessEmail)
    const [timesheetsEmail, setTimesheetsEmail] = useState(businessAccount.timesheetsEmail)
    const [documentExpiryEmail, setDocumentExpiryEmail] = useState(businessAccount.documentExpiryEmail)
    const [timesheetsDisclaimer, setTimesheetsDisclaimer] = useState(businessAccount.timesheetsDisclaimer)
    const [logoUrl, setLogoUrl] = useState(businessAccount.businessLogo)
    const [logo, setLogo] = useState(null)

    const dataEdited = useMemo(() => {
        return name !== businessAccount.businessName ||
            email !== businessAccount.businessEmail ||
            logoUrl !== businessAccount.businessLogo || 
            timesheetsEmail !== businessAccount.timesheetsEmail || 
            documentExpiryEmail !== businessAccount.documentExpiryEmail ||
            timesheetsDisclaimer !== businessAccount.timesheetsDisclaimer
    }, [name, email, timesheetsEmail, logoUrl, businessAccount, documentExpiryEmail, timesheetsDisclaimer])

    useEffect(() => {
        setLogoUrl(businessAccount.businessLogo)
        setId(businessAccount.id)
        setName(businessAccount.businessName)
        setEmail(businessAccount.businessEmail)
        setTimesheetsEmail(businessAccount.timesheetsEmail)
        setDocumentExpiryEmail(businessAccount.documentExpiryEmail)
        setTimesheetsDisclaimer(businessAccount.timesheetsDisclaimer)
    }, [businessAccount])

    const {
        getBusinessAccount,
        editBusinessAccount,
        clearAllErrorMessages,
        clearAllSuccessMessages,
    } = useBusinessAccountDispatchers()

    const saveBusinessAccount = async () => {
        await editBusinessAccount({
            businessAccountId: id,
            name,
            email,
            timesheetsEmail,
            documentExpiryEmail,
            timesheetsDisclaimer,
            logo,
        }).unwrap()
        await getBusinessAccount({
            businessAccountId: id,
        }).unwrap()
    }

    useEffect(() => {
        clearAllErrorMessages()
        clearAllSuccessMessages()
    }, [])

    const updateImage = async ($file, $fileUrl) => {
        setLogo($file)
        setLogoUrl($fileUrl)
    }

    return (
        <>
            <Text $fontSize="heading-3">Business account</Text>

            <Box $marginTop="5" />


            <FlexBox $justifyContent="center" $marginLeft={3}>
                <ProfilePhoto
                    $borderRadius='0%'
                    $size={"200px"}
                    $loading={editBusinessAccountStatus === asyncStates.PENDING}
                    $url={logoUrl}
                    $onImageChanged={accessEditSettingsBusinessAccount == true ? ($file, $fileLocalUrl) => updateImage($file, $fileLocalUrl) : null}
                />
            </FlexBox>

            <Box $marginTop="5" />

            <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                Business Name
            </Text>
            <TextBox
                $disabled={accessEditSettingsBusinessAccount != true}
                $hint={"Business Name"}
                $type={"text"}
                $name={"name"}
                $value={name}
                $onChange={(event) => setName(event.target.value)}
                $width="100%"
                $borderRadius="8px"
                $border="1px solid black"
            />

            <Box $marginTop="5" />

            <Text $fontSize="body" $marginBottom={1}>
                Business Email
            </Text>
            <TextBox
                $disabled={accessEditSettingsBusinessAccount != true}
                $hint={"Business Email"}
                $type={"text"}
                $name={"email"}
                $value={email}
                $onChange={(event) => setEmail(event.target.value)}
                $width="100%"
                $borderRadius="8px"
                $border="1px solid black"
            />

            <Box $marginTop="5" />

            <Text $fontSize="body" $marginBottom={1}>
                Timesheets Email
            </Text>
            <TextBox
                $disabled={accessEditSettingsBusinessAccount != true}
                $hint={"Timesheets Email"}
                $type={"text"}
                $name={"email"}
                $value={timesheetsEmail}
                $onChange={(event) => setTimesheetsEmail(event.target.value)}
                $width="100%"
                $borderRadius="8px"
                $border="1px solid black"
            />

            <Box $marginTop="5" />

            <Text $fontSize="body" $marginBottom={1}>
                Document Expiry Email
            </Text>

            <TextBox
                $disabled={accessEditSettingsBusinessAccount != true}
                $hint={"Document Expiry Email"}
                $type={"text"}
                $name={"email"}
                $value={documentExpiryEmail}
                $onChange={(event) => setDocumentExpiryEmail(event.target.value)}
                $width="100%"
                $borderRadius="8px"
                $border="1px solid black"
            />

            <Box $marginTop="5" />

            <Text $fontSize="body" $marginBottom={1}>
                Timesheets Disclaimer
            </Text>

            <Textarea
                $disabled={accessEditSettingsBusinessAccount != true}
                $hint={"Timesheets Disclaimer"}
                $type={"text"}
                $rows={10}
                $name={"disclaimer"}
                $value={timesheetsDisclaimer}
                $onChange={(event) => setTimesheetsDisclaimer(event.target.value)}
                $width="100%"
                $borderRadius="8px"
                $border="1px solid black"
            />

            {editBusinessAccountErrorMessage && (
                <ErrorAndSuccess $type="error">{editBusinessAccountErrorMessage}</ErrorAndSuccess>
            )}

            {editBusinessAccountSuccessMessage && (
                <ErrorAndSuccess $type="success">{editBusinessAccountSuccessMessage}</ErrorAndSuccess>
            )}

            <Box $marginTop="5" />

            <GridContainer>
                <Grid $xs={12}>
                    <Button
                        $width={"100%"}
                        $marginRight="5"
                        $onClick={() => saveBusinessAccount()}
                        $loading={editBusinessAccountStatus === asyncStates.PENDING}
                        $disabled={!dataEdited || accessEditSettingsBusinessAccount != true}
                    >
                        SAVE
                    </Button>
                </Grid>
            </GridContainer>
        </>
    )
}
