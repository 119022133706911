import { configureStore } from "@reduxjs/toolkit"
import filesReducer from "../slices/fileSlices"
import policiesReducer from "../slices/policySlice"
import riskAssessmentReducer from "../slices/riskAssessmentSlice"
import staffReducer from "../slices/staffSlice"
import complianceReducer from "../slices/staffComplianceSlices"
import eventReducer from "../slices/eventSlices"
import carePlanReducer from "../slices/clientCareplanSlice"
import timesheetReducer from "../slices/timesheetSlices"
import clientReducer from "../slices/clientSlice"
import teamReducer from "../slices/teamSlice"
import staffDiaryReducer from "../slices/staffDiarySlice"
import clientDiaryReducer from "../slices/clientDiarySlice"
import configReducer from "../slices/configSlices"
import medicineReducer from "../slices/medicineSlices"
import businessAccountReducer from "../slices/businessAccountSlice"
import eMARReducer from "../slices/eMARSlices"
import additionalFormReducer from "../slices/additionalFormsSlice"
import clientAccessReducer from "../slices/clientAccessSlice"
import analyticsReducer from "../slices/analyticsSlice"
import qualificationReducer from "../slices/qualificationsSlice"
import blockListReducer from "../slices/blockListSlice"
import emailListReducer from "../slices/emailListSlice"
import timeOffReducer from "../slices/timeOffSlice"

export default configureStore({
  reducer: {
    files: filesReducer,
    policies: policiesReducer,
    staff: staffReducer,
    client: clientReducer,
    staffDiary: staffDiaryReducer,
    clientDiary: clientDiaryReducer,
    riskAssessment: riskAssessmentReducer,
    compliance: complianceReducer,
    qualification: qualificationReducer,
    blockList: blockListReducer,
    emailList: emailListReducer,
    medicine: medicineReducer,
    events: eventReducer,
    carePlan: carePlanReducer,
    teams: teamReducer,
    configs: configReducer,
    businessAccount: businessAccountReducer,
    eMAR: eMARReducer,
    additionalForm: additionalFormReducer,
    timesheet: timesheetReducer,
    clientAccess: clientAccessReducer,
    analytics: analyticsReducer,
    timeOff: timeOffReducer,
  },
});
