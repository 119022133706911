import React, { useMemo } from 'react'
import { EVENT_STATUS } from '../../../constants'
import { Box, CircleBox, FlexBox, ListBox } from '../../../Components/Boxes'
import { Text } from '../../../Components/Text'
import { Grid, GridContainer } from '../../../Components/Grid'
import { formateDateToReadableFormatWithTime } from '../../../utiles/dateformate'

export default function EventCard(props) {
  const {
    event,
    index,

    onClick,
  } = props

  const eventColor = useMemo(() => {
    if (event.metadata.eventStatus === EVENT_STATUS.ACTIVE) {
      return event?.metadata?.eventColor || "event-active"
    } else if (event.metadata.eventStatus === EVENT_STATUS.CANCELLED) {
      return "event-cancelled"
    } else if (event.metadata.eventStatus === EVENT_STATUS.COMPLETED) {
      return "event-completed"
    } else if (event.metadata.eventStatus === EVENT_STATUS.CHECKED_IN) {
      return "event-checked-in"
    } else if (event.metadata.eventStatus === EVENT_STATUS.MISSED) {
      return "event-missed-calendar"
    } else {
      return event?.metadata?.eventColor || "primary"
    }
  }, [event])

  const title = useMemo(() => {
    if (event.metadata.eventStatus === EVENT_STATUS.ACTIVE) {
      return "Active"
    } else if (event.metadata.eventStatus === EVENT_STATUS.CANCELLED) {
      return "Cancelled"
    } else if (event.metadata.eventStatus === EVENT_STATUS.COMPLETED) {
      return "Completed"
    } else if (event.metadata.eventStatus === EVENT_STATUS.CHECKED_IN) {
      return "Checked In"
    } else if (event.metadata.eventStatus === EVENT_STATUS.MISSED) {
      return "Missed"
    } else {
      return "Active"
    }
  }, [event])

  return (
    <ListBox
      $width="100%"
      $marginTop={2}
      key={index}
      $index={index}
      $onClick={() => {
        onClick()
      }}
    >
      <GridContainer>
        <Grid $xs={4}>
          <Text $fontWeight="bold">
            {event.title ? event.title : " - "}
          </Text>
        </Grid>
        <Grid $xs={3}>
          <Text $fontWeight="bold">
            {formateDateToReadableFormatWithTime(event.start)}
          </Text>
        </Grid>
        <Grid $xs={3}>
          <Text $fontWeight="bold">
            {formateDateToReadableFormatWithTime(event.end)}
          </Text>
        </Grid>
        <Grid $xs={2}>
          <FlexBox>
            <CircleBox
              $size={10}
              $backgroundColor={eventColor}
              $color={eventColor}
            />
            <Box $marginLeft={1} />
            <Text $fontWeight="bold" $color={eventColor}>
              {title}
            </Text>
          </FlexBox>
        </Grid>
      </GridContainer>
    </ListBox>
  )
}
