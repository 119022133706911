import React, { useState, useEffect, useMemo } from "react";
import { Box, FlexBox } from "../../../Components/Boxes/index";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FormDialog from "../../../Components/Modals/FormDialog";
import Events from "./EditEvents";
import { useResponsive } from "../../../Components/Hooks";
import { useEventSelector, useEventsSelector, useGetEventStatusSelector, useGetEventsStatusSelector, useMoreEventsLeftInPaginationSelector } from "../../../redux/selectors/useEventsSelectors";
import useEventsDispathers from "../../../redux/dispatchers/useEventsDispatcher";
import { color } from "../../../Components/Theme";
import { EVENT_STATUS, EVENT_TYPE } from "../../../constants";
import { getDaysNotWorkingFromWorkingHours, splitStartAndEndDateIntoMultipleDays } from "../../../utiles/dateformate";
import usePermissionsController from "../../../permissions";
import { useSearchParams } from "react-router-dom";
import * as asyncStates from "../../../redux/constants/asyncStates";
import CalendarListView from "./CalendarListView";

const Calender = ({ clientId, staffId, eventType, isListView }) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [id, setId] = useState(searchParams.get("event_id"))
  const isDesktop = useResponsive({ xs: false, sm: true });
  const events = useEventsSelector()
  const highlightedEvent = useEventSelector()
  const moreEventsLeft = useMoreEventsLeftInPaginationSelector()
  const getEventsStatus = useGetEventsStatusSelector()

  const [startDate, setStartDate] = useState(new Date())
  const [scrollToTime, setScrollToTime] = useState(new Date())
  const [calendarView, setCalendarView] = useState("week")
  const [pastEvents, setPastEvents] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const {
    defaultCreateEventModelTitle,
  } = usePermissionsController()

  const { calendarEvents, backgroundEvents } = useMemo(() => {

    const cal = events.map(event => {
      let serviceName = ""
      if (event.staff && event.staff.name) {
        serviceName = staffId ? event?.client?.name : event?.staff?.name
      }

      return {
        // allDay: true,
        start: new Date(event.startDate),
        end: new Date(event.endDate),
        title: serviceName,
        type: event.eventType, // == EVENT_TYPE.AVAILABILITY ? EVENT_TYPE.AVAILABILITY : event.eventType == EVENT_TYPE.ABSENCE ? EVENT_TYPE.ABSENCE : "EVENT",
        metadata: {
          ...event,
        }
      }
    })

    if (isListView) return { calendarEvents: cal, backgroundEvents: [] }

    let processedBgEvents = []

    if (eventType !== EVENT_TYPE.AVAILABILITY && eventType !== EVENT_TYPE.ABSENCE) {
      if (staffId) {
        const prevMonday = new Date(`${startDate}`);
        if (calendarView === "week") prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
        prevMonday.setHours(0)
        prevMonday.setMinutes(0)
        prevMonday.setSeconds(0)
        prevMonday.setMilliseconds(0)

        const nextSunday = new Date(`${prevMonday}`);
        if (calendarView === "week") nextSunday.setDate(nextSunday.getDate() + 6)
        nextSunday.setHours(23)
        nextSunday.setMinutes(59)
        nextSunday.setSeconds(59)
        nextSunday.setMilliseconds(59)

        const bgEvents = cal?.filter(event => event.type === EVENT_TYPE.AVAILABILITY).map(e => {
          return {
            start: e.start,
            end: e.end,
          }
        })

        processedBgEvents = getDaysNotWorkingFromWorkingHours({
          workingHoursList: bgEvents,
          start: prevMonday,
          end: nextSunday,
        }).map(e => {
          return {
            start: e.start,
            end: e.end,
            title: "",
            type: EVENT_TYPE.AVAILABILITY,
            metadata: {}
          }
        })

      }

      cal?.filter(event => event.type === EVENT_TYPE.ABSENCE).forEach(e => {
        processedBgEvents.push({
          start: e.start,
          end: e.end,
          title: "Absent",
          type: EVENT_TYPE.ABSENCE,
          metadata: {}
        })
      })
    }

    const calendarEvents = cal.filter(event => {
      if (eventType === EVENT_TYPE.ABSENCE) return event.type === EVENT_TYPE.ABSENCE;
      if (eventType === EVENT_TYPE.AVAILABILITY) return event.type === EVENT_TYPE.AVAILABILITY;
      return event.type !== EVENT_TYPE.ABSENCE && event.type !== EVENT_TYPE.AVAILABILITY;
    })


    const finalCalendarEvents = []
    const finalBgEvents = []

    for (let i = 0; i < calendarEvents.length; i++) {
      const event = calendarEvents[i]
      const splitDates = splitStartAndEndDateIntoMultipleDays({ start: event.start, end: event.end })
      for (let j = 0; j < splitDates.length; j++) {
        const date = splitDates[j]
        finalCalendarEvents.push({
          ...event,
          start: date.start,
          end: date.end,
        })
      }
    }

    for (let i = 0; i < processedBgEvents.length; i++) {
      const event = processedBgEvents[i]
      const splitDates = splitStartAndEndDateIntoMultipleDays({ start: event.start, end: event.end })
      for (let j = 0; j < splitDates.length; j++) {
        const date = splitDates[j]
        finalBgEvents.push({
          ...event,
          start: date.start,
          end: date.end,
        })
      }
    }

    return {
      calendarEvents: finalCalendarEvents,
      backgroundEvents: finalBgEvents,
    }
  }, [events])

  const {
    getEvent,
    getEvents,
    clearFilters,
    clearLocalEvent,
    clearLocalEvents,
    updateFilterClientId,
    updateFilterStaffId,
    updateFilterIsClientVisit,
    updateFilterIsShift,
    updateFilterIsAvailability,
    updateFilterIsCustomEvent,
    updateFilterIsAbsenseEvent,
    updateFilterStartDate,
    updateFilterEndDate,
    updateSortBy,
  } = useEventsDispathers()

  function handleEditEventDialog(event) {
    setOpenDialog({
      editEvent: true,
      eventData: event
    });
  }

  function handleAddNewEventDialog(event) {
    setOpenDialog({
      editEvent: false,
      eventData: event
    })
  }

  function handleCloseEventDialog(event) {
    setOpenDialog(null);
  }

  moment.locale("en-GB", {
    week: {
      dow: 1,
      doy: 1,
    },
  });

  const localizer = momentLocalizer(moment);

  const refreshEvents = ({
    date = startDate,
    view = calendarView,
    fetchFromPast = pastEvents,
    paginate = false
  }) => {
    if (!isListView) {
      const prevMonday = new Date(`${date}`);
      if (view === "week") prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
      prevMonday.setHours(0)
      prevMonday.setMinutes(0)
      prevMonday.setSeconds(0)
      prevMonday.setMilliseconds(0)

      const nextSunday = new Date(`${prevMonday}`);
      if (view === "week") nextSunday.setDate(nextSunday.getDate() + 6)
      nextSunday.setHours(23)
      nextSunday.setMinutes(59)
      nextSunday.setSeconds(59)
      nextSunday.setMilliseconds(59)

      updateFilterStartDate(`${prevMonday}`)
      updateFilterEndDate(`${nextSunday}`)
    } else {
      if (fetchFromPast) {
        updateFilterStartDate(null)
        updateFilterEndDate(new Date().toISOString())
        updateSortBy("desc(startDate)")
      } else {
        updateFilterStartDate(new Date().toISOString())
        updateFilterEndDate(null)
        updateSortBy(null)
      }
    }

    if (!paginate) {
      clearLocalEvents({})
    }

    getEvents({
      startAfter: paginate ? events[events.length - 1]?.id : null,
    })
  }

  useEffect(() => {
    clearFilters({})
    clearLocalEvent({})
    updateFilterClientId(clientId)
    updateFilterStaffId(staffId)

    if (!isListView) {
      if (eventType === EVENT_TYPE.AVAILABILITY) {
        updateFilterIsAvailability(true)
      }

      if (eventType === EVENT_TYPE.ABSENCE) {
        updateFilterIsAbsenseEvent(true)
      }
    } else {
      updateFilterIsAbsenseEvent(false)
      updateFilterIsAvailability(false)
      updateFilterIsClientVisit(true)
      updateFilterIsCustomEvent(true)
      updateFilterIsShift(true)
    }

    if (eventType === EVENT_TYPE.CLIENT_VISIT) {
      updateFilterIsClientVisit(true)
    }

    if (eventType === EVENT_TYPE.CUSTOM) {
      updateFilterIsCustomEvent(true)
    }

    if (eventType === EVENT_TYPE.SHIFT) {
      updateFilterIsShift(true)
    }

    refreshEvents({
      date: startDate
    })

    if (id) {
      getEvent({ id })
      // 👇️ delete each query param
      searchParams.delete("event_id")
      // 👇️ update state after
      setSearchParams(searchParams);
    }
  }, [])

  useEffect(() => {
    if (highlightedEvent) {
      if (highlightedEvent.startDate) {
        const prevMonday = new Date(highlightedEvent.startDate);
        if (calendarView === "week") prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
        prevMonday.setHours(0)
        prevMonday.setMinutes(0)
        prevMonday.setSeconds(0)
        prevMonday.setMilliseconds(0)

        const nextSunday = new Date(`${prevMonday}`);
        if (calendarView === "week") nextSunday.setDate(nextSunday.getDate() + 6)
        nextSunday.setHours(23)
        nextSunday.setMinutes(59)
        nextSunday.setSeconds(59)
        nextSunday.setMilliseconds(59)

        // check current startDate is between the prevmonday and nextSunday variables
        if (startDate < prevMonday || startDate > nextSunday) {
          setStartDate(prevMonday)
          refreshEvents({ date: prevMonday })
        }

        setScrollToTime(new Date(highlightedEvent.startDate))
        handleEditEventDialog(highlightedEvent)
      }
    }
  }, [highlightedEvent])

  return (
    <FlexBox
      $width="100%"
      $height={!isListView ? "70vh" : null}
      $minHeight={!isListView ? "70vh" : "100%"}
      $backgroundColor="#fff"
      $borderRadius="10px"
      $flexDirection="column"
      $position="relative"
    >
      {/* // backgroundEvents */}
      {isListView && <CalendarListView
        events={calendarEvents}
        pastEvents={pastEvents}
        moreEventsLeft={moreEventsLeft}
        isLoading={getEventsStatus === asyncStates.PENDING}

        onLoadMore={() => {
          refreshEvents({
            paginate: true
          })
        }}
        onClick={(event) => {
          if (eventType === EVENT_TYPE.ABSENCE) {
            handleEditEventDialog(event.metadata)
          } else if (eventType === EVENT_TYPE.AVAILABILITY) {
            handleEditEventDialog(event.metadata)
          } else if (event.type !== EVENT_TYPE.ABSENCE && event.type !== EVENT_TYPE.AVAILABILITY) {
            handleEditEventDialog(event.metadata)
          }
        }}
        onNewEvent={() => {
          handleAddNewEventDialog({
            startDate: new Date(),
            endDate: new Date()
          })
        }}
        toggleView={() => {
          if (pastEvents) {
            setPastEvents(false)
            refreshEvents({
              fetchFromPast: false
            })
          } else {
            setPastEvents(true)
            refreshEvents({
              fetchFromPast: true
            })
          }
        }}
      />}

      {!isListView && <Calendar
        date={startDate}
        localizer={localizer}
        events={calendarEvents}
        backgroundEvents={backgroundEvents}
        step={10}
        timeslots={6}
        views={["week", "day"]}
        view={calendarView}
        defaultView="week"
        startAccessor="start"
        endAccessor="end"
        defaultDate={moment().toDate()}
        scrollToTime={scrollToTime}
        onSelectEvent={(event) => {
          if (eventType === EVENT_TYPE.ABSENCE) {
            handleEditEventDialog(event.metadata)
          } else if (eventType === EVENT_TYPE.AVAILABILITY) {
            handleEditEventDialog(event.metadata)
          } else if (event.type !== EVENT_TYPE.ABSENCE && event.type !== EVENT_TYPE.AVAILABILITY) {
            handleEditEventDialog(event.metadata)
          }
        }}
        selectable={true}
        onSelectSlot={(slotInfo) => {
          console.log(slotInfo)
          // convert 5 days to milliseconds
          const fiveDays = 5 * 24 * 60 * 60 * 1000;

          if (new Date(slotInfo.start).getTime() >= (new Date().getTime() - fiveDays)) {
            handleAddNewEventDialog({
              startDate: new Date(slotInfo.start),
              endDate: new Date(slotInfo.end)
            })
          }
        }}
        eventPropGetter={(event, start, end, isSelected) => {
          let style = {
            color: "black",
            borderRadius: "10px",
            border: "none",
          }

          if (eventType === EVENT_TYPE.ABSENCE) {
            style.backgroundColor = event?.metadata?.eventColor || color("primary");
          } else if (eventType === EVENT_TYPE.AVAILABILITY) {
            style.backgroundColor = event?.metadata?.eventColor || color("primary");
          } else {
            if (event.type !== EVENT_TYPE.ABSENCE && event.type !== EVENT_TYPE.AVAILABILITY) {
              if (event.metadata.eventStatus === EVENT_STATUS.ACTIVE) {
                style.backgroundColor = event?.metadata?.eventColor || color("event-active")
              } else if (event.metadata.eventStatus === EVENT_STATUS.CANCELLED) {
                style.backgroundColor = color("event-cancelled");
              } else if (event.metadata.eventStatus === EVENT_STATUS.COMPLETED) {
                style.backgroundColor = color("event-completed");
              } else if (event.metadata.eventStatus === EVENT_STATUS.CHECKED_IN) {
                style.backgroundColor = color("event-checked-in");
              } else if (event.metadata.eventStatus === EVENT_STATUS.MISSED) {
                style.backgroundColor = color("event-missed-calendar");
              } else {
                style.backgroundColor = event?.metadata?.eventColor || color("primary");
              }
            } else {
              style.backgroundColor = event.type === EVENT_TYPE.ABSENCE ? "#ff000016" : "#cccccc90"
            }
          }

          style.color = "white"
          style.borderRadius = 0
          style.fontSize = "small"

          return {
            style,
          };
        }}
        onNavigate={(newDate, view, action) => {
          setStartDate(newDate)
          setCalendarView(view)
          refreshEvents({ date: newDate, view })
        }}
        onView={(view) => {
          console.log(view)
          setCalendarView(view)
          refreshEvents({ view })
        }}
        style={{ height: "100%", width: "100%" }}
      />}

      {openDialog && <FormDialog
        $open={true}
        $handleDialog={handleCloseEventDialog}
        $title={eventType === EVENT_TYPE.AVAILABILITY ? "Availability" : eventType === EVENT_TYPE.ABSENCE ? "Absense" : (() => {
          let title = defaultCreateEventModelTitle ?? "Event"
          if (openDialog.eventData) {
            if (openDialog.eventData.eventStatus === EVENT_STATUS.CANCELLED) {
              title = title + " (Cancelled)"
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.COMPLETED) {
              title = title + " (Completed)"
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.CHECKED_IN) {
              title = title + " (Currently Checked In)"
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.MISSED) {
              title = title + " (Missed)"
            }
          }
          return title
        })()}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
        $headerColor={(() => {
          if (openDialog.eventData) {
            if (openDialog.eventData.eventStatus === EVENT_STATUS.ACTIVE) {
              return openDialog?.eventData?.eventColor || color("event-active")
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.CANCELLED) {
              return color("event-cancelled");
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.COMPLETED) {
              return color("event-completed");
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.CHECKED_IN) {
              return color("event-checked-in");
            } else if (openDialog.eventData.eventStatus === EVENT_STATUS.MISSED) {
              return color("event-missed");
            } else {
              return openDialog?.eventData?.eventColor || color("primary");
            }
          }
          return openDialog?.eventData?.eventColor || color("primary");
        })()}
      >
        <Events
          handleDialog={handleCloseEventDialog}
          editEvent={openDialog.editEvent}
          eventData={openDialog.eventData}
          clientId={clientId}
          staffId={staffId}
          eventType={eventType}
        />
      </FormDialog>}
    </FlexBox>
  );
};

export default Calender;
