import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as asyncStates from "../../constants/asyncStates";
import {
  createComplianceApiService,
  deleteComplianceApiService,
  getAllComplianceApiService,
  restoreArchivedComplianceApiService,
  updateComplianceApiService,
} from "../../../services/applicationBackendApis/staffCompliance";
import { formateDateToISOString } from "../../../utiles/dateformate";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allCompliance: [],
  singleCompliance: null,
  moreComplianceItemsLeftInPagination: false,
  complianceUploadPercentage: null,
  errorMessages: {
    restoreComplianceErrorMessage: null,
    createComplianceErrorMessage: null,
    deleteComplianceErrorMessage: null,
    updateComplianceErrorMessage: null,
    getAllComplianceErrorMessage: null,
    getSingleComplianceErrorMessage: null,
  },
  statuses: {
    restoreComplianceStatus: null,
    createComplianceStatus: null,
    deleteComplianceStatus: null,
    updateComplianceStatus: null,
    getAllComplianceStatus: null,
    getSingleComplianceStatus: null,
  },
};

//---------------- to get all the compliance from database ------------
export const getAllComplianceReducer = createAsyncThunk(
  "compliance/getAllCompliance",
  async (complianceData) => {
    try {
      const response = await getAllComplianceApiService({
        staffId: complianceData.staffId,
        startAfter: complianceData.startAfter,
        limit: complianceData.limit ?? PAGINATION_LIMITS.COMPLIANCE,
        isArchived: complianceData.isArchived,
      });
      return {
        data: response.data,
        limit: complianceData.limit ?? PAGINATION_LIMITS.COMPLIANCE,
        startAfter: complianceData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// ------------create compilance ------------------
export const createComplianceReducer = createAsyncThunk(
  "compliance/createCompliance",
  async (complianceData, thunkApi) => {
    const { dispatch } = thunkApi;
    try {
      const response = await createComplianceApiService({
        staffId: complianceData.staffId,
        complianceName: complianceData.complianceName,
        notes: complianceData.notes,
        validFrom: complianceData.validFrom ? formateDateToISOString(complianceData.validFrom) : null,
        validTo: complianceData.validTo ? formateDateToISOString(complianceData.validTo) : null,
        files: complianceData.files,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            dispatch(complianceUploadPercentageUpdatedReducer(percent));
          } else {
            dispatch(complianceUploadPercentageUpdatedReducer(null));
          }
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const restoreArchivedComplianceReducer = createAsyncThunk(
  "compliance/restoreArchivedCompliance",
  async (complianceData,) => {
    try {
      const response = await restoreArchivedComplianceApiService({
        staffId: complianceData.staffId,
        id: complianceData.complianceId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------deleting compliance ---------------------
export const deleteComplianceReducer = createAsyncThunk(
  "compliance/deleteCompliance",
  async (complianceData) => {
    try {
      const response = await deleteComplianceApiService({
        staffId: complianceData.staffId,
        complianceId: complianceData.complianceId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------updating compliance ---------------------
export const updateComplianceReducer = createAsyncThunk(
  "compliance/updateCompliance",
  async (complianceData) => {
    try {
      const response = await updateComplianceApiService({
        staffId: complianceData.staffId,
        complianceId: complianceData.complianceId,
        complianceName: complianceData.complianceName,
        notes: complianceData.notes,
        validFrom: complianceData.validFrom ? formateDateToISOString(complianceData.validFrom) : null,
        validTo: complianceData.validTo ? formateDateToISOString(complianceData.validTo) : null,
        files: complianceData.files,
        filesToRemove: complianceData.filesToRemove,
        onUploadProgress: complianceData.onUploadProgress,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const complianceSlice = createSlice({
  name: "files",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    complianceUploadPercentageUpdatedReducer: (state, action) => {
      state.complianceUploadPercentage = action.payload;
    },
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalCompliancesReducer: (state, action) => {
      state.allCompliance = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling get files async calls
    builder.addCase(getAllComplianceReducer.pending, (state, action) => {
      state.statuses.getAllComplianceStatus = asyncStates.PENDING;
      state.errorMessages.getAllComplianceErrorMessage = null;
    });
    builder.addCase(getAllComplianceReducer.rejected, (state, action) => {
      state.statuses.getAllComplianceStatus = asyncStates.FAILURE;
      state.errorMessages.getAllComplianceErrorMessage = action.error?.message;
    });
    builder.addCase(getAllComplianceReducer.fulfilled, (state, action) => {
      state.statuses.getAllComplianceStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllComplianceErrorMessage = null;
      state.allCompliance = action.payload.startAfter ? [...state.allCompliance, ...action.payload.data.compliances] : action.payload.data.compliances;

      if (action.payload.data.compliances.length >= action.payload.limit) {
        state.moreComplianceItemsLeftInPagination = true;
      } else {
        state.moreComplianceItemsLeftInPagination = false;
      }
    });

    // start handling create file async calls
    builder.addCase(createComplianceReducer.pending, (state, action) => {
      state.statuses.createComplianceStatus = asyncStates.PENDING;
      state.errorMessages.createComplianceErrorMessage = null;
    });
    builder.addCase(createComplianceReducer.rejected, (state, action) => {
      state.statuses.createComplianceStatus = asyncStates.FAILURE;
      state.errorMessages.createComplianceErrorMessage = action.error?.message;
    });
    builder.addCase(createComplianceReducer.fulfilled, (state, action) => {
      state.statuses.createComplianceStatus = asyncStates.SUCCESS;
      state.errorMessages.createComplianceErrorMessage = null;
    });

    builder.addCase(restoreArchivedComplianceReducer.pending, (state, action) => {
      state.statuses.restoreComplianceStatus = asyncStates.PENDING;
      state.errorMessages.restoreComplianceErrorMessage = null;
    });
    builder.addCase(restoreArchivedComplianceReducer.rejected, (state, action) => {
      state.statuses.restoreComplianceStatus = asyncStates.FAILURE;
      state.errorMessages.restoreComplianceErrorMessage =
        action.error?.message;
    });
    builder.addCase(restoreArchivedComplianceReducer.fulfilled, (state, action) => {
      state.statuses.restoreComplianceStatus = asyncStates.SUCCESS;
      state.errorMessages.restoreComplianceErrorMessage = null;
    });

    // start handling delete file async calls
    builder.addCase(deleteComplianceReducer.pending, (state, action) => {
      state.statuses.deleteComplianceStatus = asyncStates.PENDING;
      state.errorMessages.deleteComplianceErrorMessage = null;
    });
    builder.addCase(deleteComplianceReducer.rejected, (state, action) => {
      state.statuses.deleteComplianceStatus = asyncStates.FAILURE;
      state.errorMessages.deleteComplianceErrorMessage = action.error?.message;
    });
    builder.addCase(deleteComplianceReducer.fulfilled, (state, action) => {
      state.statuses.deleteComplianceStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteComplianceErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateComplianceReducer.pending, (state, action) => {
      state.statuses.updateComplianceStatus = asyncStates.PENDING;
      state.errorMessages.updateComplianceErrorMessage = null;
    });
    builder.addCase(updateComplianceReducer.rejected, (state, action) => {
      state.statuses.updateComplianceStatus = asyncStates.FAILURE;
      state.errorMessages.updateComplianceErrorMessage = action.error?.message;
    });
    builder.addCase(updateComplianceReducer.fulfilled, (state, action) => {
      state.statuses.updateComplianceStatus = asyncStates.SUCCESS;
      state.errorMessages.updateComplianceErrorMessage = null;
    });
  },
});

export const {
  complianceUploadPercentageUpdatedReducer,
  clearAllLocalCompliancesReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
} = complianceSlice.actions;

export default complianceSlice.reducer;
