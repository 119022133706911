import { useSelector } from "react-redux";

export const useCarePlansSelector = () =>
  useSelector((state) => {
    return state.carePlan.carePlans;
  });

// ------------------- selector error messages ------------------ //
export const useCarePlanCreateErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.createCarePlanErrorMessage;
});

export const useCarePlanDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.deleteCarePlanErrorMessage;
});

export const useCarePlanUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.updateCarePlanErrorMessage;
});

export const useCarePlansGetErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.getCarePlansErrorMessage;
});

export const useCarePlanGetErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.getCarePlanErrorMessage;
});

export const useCarePlanRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.carePlan.errorMessages.restoreCarePlanErrorMessage;
});

// ------------ status selectors ------------ //
export const useCarePlanCreateStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.createCarePlanStatus;
  });

export const useCarePlanDeleteStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.deleteCarePlanStatus;
  });

export const useCarePlanUpdateStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.updateCarePlanStatus;
  });

export const useCarePlansGetStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.getCarePlansStatus;
  });

export const useCarePlanGetStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.getCarePlanStatus;
  });

export const useCarePlanRestoreStatusSelector = () =>
  useSelector((state) => {
    return state.carePlan.statuses.restoreCarePlanStatus;
  });

// ------------ success selectors ------------ //
export const useCarePlanUpdateSuccessSelector = () => useSelector((state) => {
  return state.carePlan.sucessMessages.updateCarePlanSuccessMessage
})

export const useMoreCareplanItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.carePlan.moreCareplanItemsLeftInPagination
})