import { useSelector } from "react-redux"

export const useEventsSelector = () => useSelector((state) => {
    return state.events.events
})
export const useEventSelector = () => useSelector((state) => {
    return state.events.event
})
export const useStaffAvailabilitiesSelector = () => useSelector((state) => {
    return state.events.staffAvailabilities
})
export const useMoreEventsLeftInPaginationSelector = () => useSelector((state) => {
    return state.events.moreEventsLeftInPagination
})

// -------------- status ---------------- //
export const useGetEventStatusSelector = () => useSelector((state) => {
    return state.events.statuses.getEventStatus
})
export const useGetEventsStatusSelector = () => useSelector((state) => {
    return state.events.statuses.getEventsStatus
})
export const useDeleteEventStatusSelector = () => useSelector((state) => {
    return state.events.statuses.deleteEventStatus
})
export const useUpdateEventStatusSelector = () => useSelector((state) => {
    return state.events.statuses.updateEventStatus
})
export const useCreateEventStatusSelector = () => useSelector((state) => {
    return state.events.statuses.createEventStatus
})
export const useAutoPilotEventsStatusSelector = () => useSelector((state) => {
    return state.events.statuses.autoPilotEventsStatus
})
export const useGetStaffAvailabilitiesStatusSelector = () => useSelector((state) => {
    return state.events.statuses.getStaffAvailabilitiesStatus
})

// ------------- errorMEssages ---------------//
export const useGetEventErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.getEventErrorMessage
})
export const useGetEventsErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.getEventsErrorMessage
})
export const useDeleteEventErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.deleteEventErrorMessage
})
export const useUpdateEventErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.updateEventErrorMessage
})
export const useCreateEventErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.createEventErrorMessage
})
export const useAutoPilotEventsErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.autoPilotEventsErrorMessage
})
export const useGetStaffAvailabilitiesErrorMessagesSelector = () => useSelector((state) => {
    return state.events.errorMessages.getStaffAvailabilitiesErrorMessage
})

// ------------- successMessages -------------//
export const useGetEventSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.getEventSuccessMessage
})
export const useGetEventsSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.getEventsSuccessMessage
})
export const useDeleteEventSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.deleteEventSuccessMessage
})
export const useUpdateEventSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.updateEventSuccessMessage
})
export const useCreateEventSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.createEventSuccessMessage
})
export const useAutoPilotEventsSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.autoPilotEventsSuccessMessage
})
export const useGetStaffAvailabilitiesSuccessMessagesSelector = () => useSelector((state) => {
    return state.events.successMessages.getStaffAvailabilitiesSuccessMessage
})

// ----------------------- filters -------------------//
export const useFilterStartDateSelector = () => useSelector((state) => {
    return state.events.searchFilters.startDateFilter
})

export const useFilterEndDateSelector = () => useSelector((state) => {
    return state.events.searchFilters.endDateFilter
})

export const useFilterClientIdSelector = () => useSelector((state) => {
    return state.events.searchFilters.clientIdFilter
})

export const useFilterStaffIdSelector = () => useSelector((state) => {
    return state.events.searchFilters.staffIdFilter
})