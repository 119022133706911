import { ADDITIONAL_FORM_FIELD_TYPE } from "../constants"

export const convertAdditionalFormFieldToText = (val) => {
    if (val == ADDITIONAL_FORM_FIELD_TYPE.DATE_TIME) return "Date"
    if (val == ADDITIONAL_FORM_FIELD_TYPE.NUMBER) return "Number"
    if (val == ADDITIONAL_FORM_FIELD_TYPE.OPTIONS) return "Options list"
    if (val == ADDITIONAL_FORM_FIELD_TYPE.IMAGE) return "Upload image"
    if (val == ADDITIONAL_FORM_FIELD_TYPE.BODYMAP) return "Bodymap"
    return "Text"
}

export const convertAdditionalFormFieldValueToType = ({ val, type }) => {
    if (type == ADDITIONAL_FORM_FIELD_TYPE.DATE_TIME) return new Date(val)
    if (type == ADDITIONAL_FORM_FIELD_TYPE.NUMBER) {
        if (typeof val == "number") return val
        return 0
    }
    if (type == ADDITIONAL_FORM_FIELD_TYPE.OPTIONS) {
        if (typeof val == "string") return val
        return ""
    }
    return val
}