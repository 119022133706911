import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useRiskassessmentsSettingsController } from '../SettingsControllers/useRiskassessmentsController'

export default function RiskassessmentsSettings() {
  const controller = useRiskassessmentsSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
