import { useSelector } from "react-redux";

export const useAdditionalFormsSelector = () => useSelector((state) => {
    return state.additionalForm.additionalForms;
});

// ------------------- selector error messages ------------------ //
export const useAdditionalFormCreateErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.createAdditionalFormErrorMessage;
});

export const useAdditionalFormDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.deleteAdditionalFormErrorMessage;
});

export const useAdditionalFormUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.updateAdditionalFormErrorMessage;
});

export const useAdditionalFormsGetErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.getAdditionalFormsErrorMessage;
});

export const useAdditionalFormGetErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.getAdditionalFormErrorMessage;
});

export const useAdditionalFormRestoreErrorMessageSelector = () => useSelector((state) => {
    return state.additionalForm.errorMessages.restoreAdditionalFormErrorMessage;
});

// ------------ status selectors ------------ //
export const useAdditionalFormCreateStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.createAdditionalFormStatus;
});

export const useAdditionalFormDeleteStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.deleteAdditionalFormStatus;
});

export const useAdditionalFormUpdateStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.updateAdditionalFormStatus;
});

export const useAdditionalFormsGetStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.getAdditionalFormsStatus;
});

export const useAdditionalFormGetStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.getAdditionalFormStatus;
});

export const useAdditionalFormRestoreStatusSelector = () => useSelector((state) => {
    return state.additionalForm.statuses.restoreAdditionalFormStatus;
});

// ------------ success selectors ------------ //
export const useAdditionalFormUpdateSuccessSelector = () => useSelector((state) => {
    return state.additionalForm.sucessMessages.updateAdditionalFormSuccessMessage
})

export const useMoreAdditionalFormItemsLeftInPaginationSelector = () => useSelector((state) => {
    return state.additionalForm.moreAdditionalFormItemsLeftInPagination
})