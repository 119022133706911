import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant={props.variant} {...props} />
      </Box>
      {props.variant == "determinate" && <Box minWidth={35}>
        <Typography variant="body2" color="#12133D">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>}
    </Box>
  );
}

export default function LinearWithValueLabel(props) {
  return (
    <Box width="100%">
      <LinearProgressWithLabel variant={props.$loadsContinuosly ? "indeterminate" : "determinate"} value={props.value} />
    </Box>
  );
}