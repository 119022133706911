import { Box, Card, CircleBox, FlexBox } from "../../../Components/Boxes";
import Icon from "../../../Components/Icons";
import { Text } from "../../../Components/Text";
import { Link } from "react-router-dom";
import { useResponsive } from "../../../Components/Hooks/index";
import { Button, TextBox } from "../../../Components/Input";
import { useEffect, useState } from "react";
import FormDialog from "../../../Components/Modals/FormDialog";
import { AddStaff } from "./Profile/Dialogs/AddStaff";
import useStaffDispathers from "../../../redux/dispatchers/useStaffDispatchers";
import {
  useAllStaffSelector,
  useNewStaffStatusSelector,
  useMoreStaffLeftInPaginationSelector,
  useStaffPaginationNextPage,
} from "../../../redux/selectors/useStaffSelectors";
import MuiSkeletonLoader from "../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import * as asyncStates from "../../../redux/constants/asyncStates";
import EmptyState from "../../../Components/NoRecordFound";
import StaffAndClientCard from "../StaffAndClientCard";
import StaffAndClientHeader from "../StaffAndClientHeader";
import { useFilterTeamsSelector } from "../../../redux/selectors/useTeamSelectors";
import usePermissionsController from "../../../permissions";

export default function Staff() {
  // see whether it's desktop size or not
  const isDesktop = useResponsive({ xs: false, sm: true });

  const { addStaff } = usePermissionsController();

  const {
    getNewStaff,
    clearAllLocalStaff,
    clearAllErrorMessages,
    clearAllSuccessMessages,
  } = useStaffDispathers();

  //selectors
  const newStaff = useAllStaffSelector();
  const newStaffLoader = useNewStaffStatusSelector();
  const moreStaffLeftInPagination = useMoreStaffLeftInPaginationSelector()
  const filteredTeams = useFilterTeamsSelector()
  const nextPage = useStaffPaginationNextPage();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTimer, setSearchQueryTimer] = useState(null);

  const [open, setOpen] = useState(false);
  function HandleDialog() {
    setOpen(!open);
  }

  const loadMore = () => {
    getNewStaff({
      query: searchQuery,
      startAfter: nextPage,// newStaff[newStaff.length - 1].id,
      teams: filteredTeams?.map(e => e.id),
    });
  }

  const handleQuery = (e) => {
    setSearchQuery(e.target.value);
    if (searchQueryTimer) {
      clearTimeout(searchQueryTimer);
    }

    const timeout = setTimeout(() => {
      getNewStaff({
        query: e.target.value,
        teams: filteredTeams?.map(e => e.id),
      });
    }, 500);
    setSearchQueryTimer(timeout);
  };

  useEffect(() => {
    clearAllLocalStaff();
    clearAllErrorMessages();
    clearAllSuccessMessages();
    getNewStaff({
      query: searchQuery,
      teams: filteredTeams?.map(e => e.id),
    });
  }, [filteredTeams]);

  return (
    <>
      <FlexBox
        $justifyContent="space-between"
        $width="98%"
        $marginTop={2}
        $marginBottom={2}
      >
        {/* Search box */}
        <TextBox
          $width="40%"
          $hint={"Search ..."}
          $type={"text"}
          $name={"search"}
          $value={searchQuery}
          $onChange={handleQuery}
          $borderRadius="8px"
          $border="1px solid black"
          $trailing={
            <Icon $icon="Search" $size="20px" />
          }
        />

        {addStaff && <Button
          $onClick={() => {
            HandleDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Add</Text>
        </Button>}
      </FlexBox>

      <Box $width="100%">
        <StaffAndClientHeader $type="staff" />
        <Box $width="100%" $marginTop="2">
          {newStaff && newStaff?.map((value, index) => {
            return (
              <Link
                key={index}
                to={`/team/staff/${value.id}`}
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <StaffAndClientCard $value={value} $index={index} $type="staff" />
              </Link>
            );
          })}
        </Box>

        {newStaffLoader === asyncStates.PENDING && <FlexBox $width="97%" $marginTop={2} $flexDirection="column">
          {[1, 2, 3].map((val, index) => {
            return (
              <Box
                $marginBottom="2"
                $width="100%"
              >
                <MuiSkeletonLoader
                  $variant="rectangular"
                  $width={"100%"}
                  $height={"40px"}
                />
              </Box>
            );
          })}
        </FlexBox>}
      </Box>

      {newStaffLoader === asyncStates.SUCCESS && newStaff.length < 1 && (
        <FlexBox $marginTop={7} $justifyContent="center">
          <EmptyState />
        </FlexBox>
      )}

      {moreStaffLeftInPagination && <FlexBox $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Add Staff Member"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <AddStaff $handleDialog={HandleDialog} />
      </FormDialog>
    </>
  );
}
