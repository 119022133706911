import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthContextWrapper } from "./contextAPI's/authContext";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ThemeProvider, createTheme } from "@mui/material";
import { color } from "./Components/Theme";

const theme = createTheme({
  palette: {
    primary: {
      main: color("primary")
    },
    secondary: {
      main: color("secondary")
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <AuthContextWrapper>
          <App />
        </AuthContextWrapper>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);
