import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  // createTimesheetApiService,
  deleteTimesheetApiService,
  getAllTimesheetApiService,
  getTimeSheetPdfApiService,
  restoreArchivedTimesheetApiService,
  updateTimesheetApiService,
} from "../../../services/applicationBackendApis/timesheets";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  timesheets: [],
  timesheet: null,
  moreTimesheetItemsLeftInPagination: false,
  timesheetPdf: null,
  searchFilters: {
    timesheetStatus: null,
  },
  errorMessages: {
    restoreTimesheetErrorMessage: null,
    createTimesheetErrorMessage: null,
    deleteTimesheetErrorMessage: null,
    updateTimesheetErrorMessage: null,
    getTimesheetsErrorMessage: null,
    getTimesheetPdfErrorMessage: null,
  },
  statuses: {
    restoreTimesheetStatus: null,
    createTimesheetStatus: null,
    deleteTimesheetStatus: null,
    updateTimesheetStatus: null,
    getTimesheetsStatus: null,
    getTimesheetPdfStatus: null,
  },
  sucessMessages: {
    restoreTimesheetSuccessMessage: null,
    updateTimesheetSuccessMessage: null,
    timesheetPdfSuccessMessage: null,
  },
};

//------- getting all timesheet from database ------------\\
export const getAllTimesheetReducer = createAsyncThunk(
  "Timesheet/getAllTimesheet",
  async (timesheetData, thunkApi) => {
    try {
      const { getState } = thunkApi
      const { timesheet } = getState()
      const { searchFilters: {
        timesheetStatus,
      } } = timesheet
      const response = await getAllTimesheetApiService({
        clientId: timesheetData.clientId,
        staffId: timesheetData.staffId,
        startAfter: timesheetData.startAfter,
        limit: timesheetData.limit ?? PAGINATION_LIMITS.TIMESHEET,
        isArchived: timesheetData.isArchived,
        timesheetStatus: timesheetStatus,
      });
      return {
        data: response.data,
        limit: timesheetData.limit ?? PAGINATION_LIMITS.TIMESHEET,
        startAfter: timesheetData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const getTimesheetPdfReducer = createAsyncThunk(
  "Timesheet/getTimesheetPdf",
  async (timesheetData) => {
    try {
      const response = await getTimeSheetPdfApiService({
        clientId: timesheetData.clientId,
        staffId: timesheetData.staffId,
        timesheetId: timesheetData.timesheetId,

      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------------- creating new risk Assessment ---------------\\
export const createTimesheetReducer = createAsyncThunk(
  "timesheet/createTimesheetReducer",
  async (timesheetData) => {
    try {
      // const response = await createTimesheetApiService({
      //   timesheetName: timesheetData.timesheetName,
      //   urgency: timesheetData.urgency,
      //   need: timesheetData.need,
      //   goal: timesheetData.goal,
      //   interventions: timesheetData.interventions,
      //   services: timesheetData.services,
      //   clientId: timesheetData.clientId,
      // });
      // return response.data;
      return null;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const restoreArchivedTimesheetReducer = createAsyncThunk(
  "timesheet/restoreArchivedTimesheet",
  async (timesheetData) => {
    try {
      const response = await restoreArchivedTimesheetApiService({
        clientId: timesheetData.clientId,
        staffId: timesheetData.staffId,
        id: timesheetData.timesheetId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateTimesheetReducer = createAsyncThunk(
  "timesheet/updateTimesheetReducer",
  async (timesheetData) => {
    try {
      const response = await updateTimesheetApiService({
        timesheetId: timesheetData.timesheetId,
        clientId: timesheetData.clientId,
        staffId: timesheetData.staffId,
        startDate: timesheetData.startDate,
        endDate: timesheetData.endDate,
        breakDuration: timesheetData.breakDuration,
        timesheetStatus: timesheetData.timesheetStatus,
        signatureSubmitted: timesheetData.signatureSubmitted,
        missingSignatureReason: timesheetData.missingSignatureReason,
        signedByName: timesheetData.signedByName,
        signedByPosition: timesheetData.signedByPosition,
        signatureFile: timesheetData.signatureFile,
        jobRole: timesheetData.jobRole,
        notes: timesheetData.notes,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const deleteTimesheetReducer = createAsyncThunk(
  "timesheet/deleteTimesheetReducer",
  async (timesheetData) => {
    try {
      const response = await deleteTimesheetApiService({
        clientId: timesheetData.clientId,
        staffId: timesheetData.staffId,
        timesheetId: timesheetData.timesheetId,
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const timesheetSlice = createSlice({
  name: "timesheetSlice",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalTimesheetsReducer: (state, action) => {
      state.timesheets = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.sucessMessages = {};
    },
    clearTimesheetPdfReducer: (state, action) => {
      state.timesheetPdf = null;
    },
    updateTimesheetStatusFilterReducer: (state, action) => {
      state.searchFilters.timesheetStatus = action.payload;
    },

    updateTimeSheetInLocalTimesheetListReducer: (state, action) => {
      const { timesheetId, timesheet } = action.payload;
      const index = state.timesheets.findIndex(
        (timesheet) => timesheet.id === timesheetId
      );
      if (index !== -1) {
        state.timesheets[index] = timesheet;
      }
    }
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createTimesheetReducer.pending, (state, action) => {
      state.statuses.createTimesheetStatus = asyncStates.PENDING;
      state.errorMessages.createTimesheetErrorMessage = null;
    });
    builder.addCase(createTimesheetReducer.rejected, (state, action) => {
      state.statuses.createTimesheetStatus = asyncStates.FAILURE;
      state.errorMessages.createTimesheetErrorMessage = action.error?.message;
    });
    builder.addCase(createTimesheetReducer.fulfilled, (state, action) => {
      state.statuses.createTimesheetStatus = asyncStates.SUCCESS;
      state.errorMessages.createTimesheetErrorMessage = null;
    });

    builder.addCase(restoreArchivedTimesheetReducer.pending, (state, action) => {
      state.statuses.restoreTimesheetStatus = asyncStates.PENDING;
      state.errorMessages.restoreTimesheetErrorMessage = null;
    });
    builder.addCase(restoreArchivedTimesheetReducer.rejected, (state, action) => {
      state.statuses.restoreTimesheetStatus = asyncStates.FAILURE;
      state.errorMessages.restoreTimesheetErrorMessage =
        action.error?.message;
    });
    builder.addCase(restoreArchivedTimesheetReducer.fulfilled, (state, action) => {
      state.statuses.restoreTimesheetStatus = asyncStates.SUCCESS;
      state.errorMessages.restoreTimesheetErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateTimesheetReducer.pending, (state, action) => {
      state.statuses.updateTimesheetStatus = asyncStates.PENDING;
      state.errorMessages.updateTimesheetErrorMessage = null;
    });
    builder.addCase(updateTimesheetReducer.rejected, (state, action) => {
      state.statuses.updateTimesheetStatus = asyncStates.FAILURE;
      state.errorMessages.updateTimesheetErrorMessage = action.error?.message;
    });
    builder.addCase(updateTimesheetReducer.fulfilled, (state, action) => {
      state.statuses.updateTimesheetStatus = asyncStates.SUCCESS;
      state.errorMessages.updateTimesheetErrorMessage = null;
      state.sucessMessages.updateTimesheetSuccessMessage =
        action.payload.message;
    });

    // start handling get files async calls
    builder.addCase(getAllTimesheetReducer.pending, (state, action) => {
      state.statuses.getTimesheetsStatus = asyncStates.PENDING;
      state.errorMessages.getTimesheetsErrorMessage = null;
    });
    builder.addCase(getAllTimesheetReducer.rejected, (state, action) => {
      state.statuses.getTimesheetsStatus = asyncStates.FAILURE;
      state.errorMessages.getTimesheetsErrorMessage = action.error?.message;
    });
    builder.addCase(getAllTimesheetReducer.fulfilled, (state, action) => {
      state.statuses.getTimesheetsStatus = asyncStates.SUCCESS;
      state.errorMessages.getTimesheetsErrorMessage = null;
      state.timesheets = action.payload.startAfter ? [...state.timesheets, ...action.payload.data.timesheets] : action.payload.data.timesheets;

      if (action.payload.data.timesheets.length >= action.payload.limit) {
        state.moreTimesheetItemsLeftInPagination = true;
      } else {
        state.moreTimesheetItemsLeftInPagination = false;
      }
    });

    // start handling get file async calls
    builder.addCase(getTimesheetPdfReducer.pending, (state, action) => {
      state.statuses.getTimesheetPdfStatus = asyncStates.PENDING;
      state.errorMessages.getTimesheetPdfErrorMessage = null;
    });
    builder.addCase(getTimesheetPdfReducer.rejected, (state, action) => {
      state.statuses.getTimesheetPdfStatus = asyncStates.FAILURE;
      state.errorMessages.getTimesheetPdfErrorMessage = action.error?.message;
    });
    builder.addCase(getTimesheetPdfReducer.fulfilled, (state, action) => {
      state.statuses.getTimesheetPdfStatus = asyncStates.SUCCESS;
      state.errorMessages.getTimesheetPdfErrorMessage = null;
      state.timesheetPdf = action.payload.base64File;
    });

    // start handling delete file async calls
    builder.addCase(deleteTimesheetReducer.pending, (state, action) => {
      state.statuses.deleteTimesheetStatus = asyncStates.PENDING;
      state.errorMessages.deleteTimesheetErrorMessage = null;
    });
    builder.addCase(deleteTimesheetReducer.rejected, (state, action) => {
      state.statuses.deleteTimesheetStatus = asyncStates.FAILURE;
      state.errorMessages.deleteTimesheetErrorMessage = action.error?.message;
    });
    builder.addCase(deleteTimesheetReducer.fulfilled, (state, action) => {
      state.statuses.deleteTimesheetStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteTimesheetErrorMessage = null;
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllLocalTimesheetsReducer,
  clearAllSuccessMessagesReducer,
  clearTimesheetPdfReducer,
  updateTimesheetStatusFilterReducer,
  updateTimeSheetInLocalTimesheetListReducer,
} = timesheetSlice.actions;

export default timesheetSlice.reducer;
