import React, { useContext, useEffect, useState } from "react";
import { FlexBox } from "../../Components/Boxes";
import { Text } from "../../Components/Text";
import Icon from "../../Components/Icons";
import { CheckBox, TextField } from "../../Components/Input/index";
import useTeamDispathers from "../../redux/dispatchers/useTeamDispatchers";
import {
  useFilterTeamsSelector,
  useTeamsGetStatusSelector,
  useTeamsSelector,
} from "../../redux/selectors/useTeamSelectors";
import * as asyncStates from "../../redux/constants/asyncStates";
import MuiSkeletonLoader from "../../Components/LoadingSkeleton/MuiSkeletonLoader";
import { Box } from "@mui/system";
import { color } from "../../Components/Theme";

const RightSideBar = () => {
  //----------------- states --------------------------
  const [addTeamInput, setAddTeamInput] = useState(false);
  const [teamToEdit, setTeamToEdit] = useState(null);
  const [type, setType] = useState(null);
  const [teamName, setTeamName] = useState(teamToEdit ? teamToEdit.name : "");
  //----------------- selectors -----------------------
  const getAllTeams = useTeamsSelector();
  const filteredTeams = useFilterTeamsSelector()
  const getAllTeamsStatus = useTeamsGetStatusSelector();
  //----------------- dispatchers ---------------------
  const { getTeams, createTeam, clearAllLocalTeams, deleteTeam, updateTeam, filterByTeam } = useTeamDispathers();

  //----------------- functions -----------------------
  const onCreateTeamClick = async () => {
    await createTeam({
      name: teamName,
    }).unwrap();
    clearAllLocalTeams();
    getTeams({});
    // return props.$handleDialog();
    setAddTeamInput(false);
  };

  const onDeleteTeamClick = async () => {
    await deleteTeam({
      teamId: teamToEdit.id,
    }).unwrap();
    clearAllLocalTeams();
    getTeams({});
    // return props.$handleDialog();
    setTeamToEdit(null);
  };

  const onUpdateTeamClick = async () => {
    await updateTeam({
      name: teamToEdit.name,
      teamId: teamToEdit.id,
    }).unwrap();
    clearAllLocalTeams();
    getTeams({});
    // return props.$handleDialog();
    setTeamToEdit(null);
  };

  const onFilterClick = async (addToFilter, team) => {
    let tempFilterList = [...filteredTeams]
    if (addToFilter) {
      tempFilterList.push(team)
    } else {
      tempFilterList = tempFilterList.filter(e => e.id != team.id)
    }
    console.log(tempFilterList)
    filterByTeam({
      teams: tempFilterList
    })
  }
  //----------------- useEffect -----------------------
  useEffect(() => {
    clearAllLocalTeams();
    setTeamToEdit(null);
    setAddTeamInput(false);
    getTeams({});
  }, []);

  return (
    <FlexBox
      $width="100%"
      $height="100%"
      $backgroundColor="#ffff"
      $flexDirection="column"
      $justifyContent="flex-start"
      $alignItems="center"
      $borderRadius="10px"
      $marginTop={{ xs: "30px", sm: "0px" }}
      $padding={4}
    >
      <FlexBox $justifyContent="space-between" $width="100%" $paddingLeft={2}>
        <Text $color={"text"} $fontSize={"body"} $fontWeight={"600"}>
          Teams
        </Text>
        <Icon
          $pointer={"pointer"}
          $icon={"Add"}
          $size={"24px"}
          $color={"text"}
          $onClick={() => {
            setAddTeamInput(!addTeamInput);
            setTeamToEdit(null);
            setType(null)
          }}
        />
      </FlexBox>

      {addTeamInput && (
        <FlexBox
          $width="100%"
          $alignItems="center"
          $justifyContent="flex-start"
          $paddingLeft={4}
        >
          <TextField
            $onChange={(e) => {
              setTeamName(e.target.value);
            }}
          />
          <Icon
            $pointer={"pointer"}
            $icon={"AddTask"}
            $size={"24px"}
            $color={color("primary")}
            $onClick={() => {
              onCreateTeamClick();
            }}
          />
        </FlexBox>
      )}
      <br />
      {getAllTeams?.map((value, index) => {
        const isInFilteredTeam = (filteredTeams?.filter(e => {
          return e.id == value.id
        }) ?? []).length > 0
        return (
          <FlexBox
            key={value.id}
            $width="100%"
            $alignItems="center"
            $justifyContent="flex-start"
          >
            {teamToEdit?.id !== value.id && <CheckBox
              $onChange={(val) => {
                onFilterClick(val.target.checked, value)
              }}
              $checked={isInFilteredTeam}
              $size={"medium"}
            />}

            {teamToEdit?.id !== value.id && (
              <Text
                $onClick={() => {
                  setTeamToEdit(value);
                  setAddTeamInput(false)
                  setType("edit")
                }}
                $color={"text"} $fontSize={"body"} $fontWeight={"600"}>
                {value.name}
              </Text>
            )}

            {type === "edit" && teamToEdit?.id === value.id && (
              <>
                <TextField
                  $value={teamToEdit?.name}
                  $onChange={(e) => {
                    setTeamToEdit(prevValue => ({ ...prevValue, name: e.target.value }));
                  }}
                />
                <Icon
                  $pointer={"pointer"}
                  $icon={"TaskAlt"}
                  $size={"24px"}
                  $color={color("primary")}
                  $onClick={() => {
                    onUpdateTeamClick();
                  }}
                />
                <Box>
                  {
                    <Icon
                      $pointer={"pointer"}
                      $icon={"DeleteForever"}
                      $size={"24px"}
                      $color={"red"}
                      $onClick={() => {
                        onDeleteTeamClick();
                      }}
                    />
                  }
                </Box>
              </>
            )}
          </FlexBox>
        );
      })}
      {getAllTeamsStatus === asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <div style={{ width: "100%", marginTop: "8px" }} key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={20}
              />
            </div>
          );
        })}
    </FlexBox>
  );
};

export default RightSideBar;
