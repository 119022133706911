import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { usePolicySettingsController } from '../SettingsControllers/usePolicyListController'

export default function PolicyListSettings() {
  const controller = usePolicySettingsController()
  return (
    <AddConfigsNames {...controller}/>
  )
}
