import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetTimeOffApproversErrorMessageSelector, useGetTimeOffApproversStatusSelector, useTimeOffApproversSelector, useTimeOffApproversUpdateStatusSelector, useUpdateTimeOffApproversErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useTimeOffApproversSettingsController() {
    const { accessAddSettingsTimeOffApprovers, accessEditSettingsTimeOffApprovers } = usePermissionsController()
    const timeOffApproverNames = useTimeOffApproversSelector()
    const getTimeOffApproversErrorMessages = useGetTimeOffApproversErrorMessageSelector()
    const updateTimeOffApproversErrorMessages = useUpdateTimeOffApproversErrorMessageSelector()

    const getTimeOffApproversStatuses = useGetTimeOffApproversStatusSelector()
    const updateTimeOffApproversStatuses = useTimeOffApproversUpdateStatusSelector()

    const names = useMemo(() => [...timeOffApproverNames], [timeOffApproverNames])

    const {
        updateTimeOffApprovers,
        getTimeOffApprovers,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getTimeOffApprovers()
    }, [])

    const onSave = async (val) => {
        const newNames = names.map((val, i) => {
            return val.id
        })
        newNames.push(val.id)
        await updateTimeOffApprovers({
            timeOffApprovers: newNames
        }).unwrap();
        getTimeOffApprovers()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = names.map((val, i) => {
            return val.id
        })
        if (index >= 0) {
            newNames[index] = newValue.id
        }
        await updateTimeOffApprovers({
            timeOffApprovers: newNames
        }).unwrap();
        getTimeOffApprovers()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = names.map((val, i) => {
            return val.id
        })
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateTimeOffApprovers({
            timeOffApprovers: newNames
        }).unwrap();
        getTimeOffApprovers()
        return
    }

    return {
        title: "TimeOffApprovers",
        names: names,

        
        canEdit: accessEditSettingsTimeOffApprovers,
        canAdd: accessAddSettingsTimeOffApprovers,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateTimeOffApproversStatuses == asyncStates.PENDING,
        isDeletingConfig: updateTimeOffApproversStatuses == asyncStates.PENDING,
        isGettingConfigs: getTimeOffApproversStatuses == asyncStates.PENDING,

        updateErrorMessage: updateTimeOffApproversErrorMessages,
        deleteErrorMessage: updateTimeOffApproversErrorMessages,
        gettingErrorMessage: getTimeOffApproversErrorMessages,
    }
}