import React, { useState, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import LogonWithName from "../../Images/Logos/gps_care_logo.png";
import DefaultImage from "../../Components/Images/index";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { AuthContext } from "../../contextAPI's/authContext";
import BasicAlerts from "../../Components/Boxes/ErrorAndSuccess";
//import asyncStates from "../../../../../redux/constants/asyncStates";
import { Box, FlexBox } from "../../Components/Boxes/index";
import CircularLoader from "../../Components/Icons/circularLoader";
import { useResponsive } from "../../Components/Hooks/index";
import { Link, useSearchParams } from "react-router-dom";
import useStaffDispathers from "../../redux/dispatchers/useStaffDispatchers";
import { useGetStaffInviteErrorMessageSelector, useStaffInviteDataSelector } from "../../redux/selectors/useStaffSelectors";
import { color } from "../../Components/Theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color("primary")};
`;

const LoginWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 10px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function StaffSignUp() {
  const isDesktop = useResponsive({ xs: false, md: true });
  const { getStaffInvite, clearAllErrorMessages, clearAllSuccessMessages } = useStaffDispathers();

  //auth context
  const { loading, loginError, setLoginError, SignUp } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [oobCodeData, setData] = useState(searchParams.get('oobCode'));
  //selectors
  const newStaffGetErrorMsg = useGetStaffInviteErrorMessageSelector();
  const inviteData = useStaffInviteDataSelector();

  //console.log(newStaffSuccessMsg?.email)
  const errorMessage = useMemo(() => {
    return newStaffGetErrorMsg;
  }, [newStaffGetErrorMsg]);


  //States
  const [values, setValues] = useState({
    email: "",
    password: "",
    staffname: "",
    confirm: "",
    oobCode: null
  });

  const [showPassword, setShowPassword] = useState(false);

  //event change func
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //Separating the OobCode
  useEffect(() => {
    values.oobCode = oobCodeData;
    getStaffInvite(oobCodeData);
  }, []);


  //handle submit func
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (values.password === values.confirm) {
      SignUp(values);
    }
    else {
      setLoginError("Confirm Password and Password don't match.");
    }
  };


  return (
    <Wrapper>
      <LoginWrapper>
        <DefaultImage $width={"20%"} $src={LogonWithName} $objectFit="contain" />
        <br />
        <br />
        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: isDesktop ? "80%" : "90%",
            // border:"1px solid red"
          }}
        >
          <TextField
            id="email"
            type="email"
            value={inviteData?.email}
            onChange={handleChange("email")}
            label=""
            required
            fullWidth
          />
          <br />
          <TextField
            id="staffname"
            type="name"
            value={values.staffname}
            onChange={handleChange("staffname")}
            label="Name"
            required
            fullWidth
          />
          <br />
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              required
            />
            <br />
          </FormControl>
          <FormControl
            variant="outlined"
            style={{
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password*
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={values.confirm}
              onChange={handleChange("confirm")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              required
            />
          </FormControl>
          <br />
          {loginError && (
            <FlexBox $marginTop={2}>
              <BasicAlerts $type="error">{loginError}</BasicAlerts>
            </FlexBox>
          )}
          <br />
          {!loading ? (
            <Button
              type="submit"
              variant="contained"
              size="large"
            >
              Submit
            </Button>
          ) : (
            <Button variant="contained" size="large" disabled >
              {/* {newStaffGetStatus !== asyncStates.PENDING ? 'Send Invitation Code' : <CircularLoader $color="white" $size={25} />} */}
              <CircularLoader $color="white" $size={25} />
            </Button>
          )}
          <br />
          <Link to="/">Already have an account? Login</Link>
        </form>
      </LoginWrapper>
    </Wrapper>
  );
}
