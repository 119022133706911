import React from "react";
const Contact = ({ data }) => {
  const contact = data?.contact ?? "";
  // const phonecode = data?.phonecode ?? "";
  return (
    <>
       {contact}
    </>
  );
};
export default Contact;