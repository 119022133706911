import React, { useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { FlexBox, Box, CircleBox } from "../../Components/Boxes";
import Icon from "../../Components/Icons";
import DefaultImage from "../../Components/Images";
import { Text } from "../../Components/Text";
// import LogoWithName from "../../Images/Logos/LogoWithName.png";
import Logo from "../../Images/Logos/gps_care_logo.png";
import usePermissionsController from "../../permissions";
import { useBusinessAccountSelector } from "../../redux/selectors/useBusinessAccountSelectors";

const SideBar = ({
  selected,
  handleSelect,
  sideBarCollapse,
  handleSideBar,
}) => {
  const {
    accessWeb,
    accessHomePage,
    accessAnalyticsPage,
    accessTeamsPage,
    accessSchedulePage,
    accessSettingsPage,
    accessTimesheetsPage,
    accessSubscriptionPage,
  } = usePermissionsController()

  const pages = useMemo(() => {
    const p = []
    if (accessHomePage)
      p.push({ name: "Home", icon: "Home", href: "/" })
    if (accessTeamsPage)
      p.push({ name: "Team", icon: "Group", href: "/team" })
    if (accessSchedulePage)
      p.push({ name: "Schedule", icon: "AddTask", href: "/schedule" })
    if (accessTimesheetsPage)
      p.push({ name: "Timesheets", icon: "PointOfSale", href: "/timesheets" })
    if (accessSubscriptionPage)
      p.push({ name: "Subscription", icon: "Payment", href: "/subscription" })
    if (accessAnalyticsPage)
      p.push({ name: "Analytics", icon: "Analytics", href: "/analytics" })
    if (accessSettingsPage)
      p.push({ name: "Settings", icon: "Settings", href: "/settings" })

    return p
  }, [accessHomePage, accessWeb, accessAnalyticsPage, accessTeamsPage, accessSchedulePage, accessSettingsPage, accessTimesheetsPage])


  const businessAccount = useBusinessAccountSelector()

  return (
    <FlexBox
      $width="80%"
      $height="100%"
      $backgroundColor="#fff"
      $flexDirection="column"
      $justifyContent="flex-start"
      $alignItems="flex-start"
      $position="relative"
    >
      <FlexBox
        $width="100%"
        $flexDirection="row"
        $justifyContent="center"
        $alignItems="center"
        $marginTop={5}
        $marginBottom={6}
        // $border="1px solid red"
        $position="relative"
      >
        <FlexBox
          $width={
            sideBarCollapse ? "50px" : { md: "100%", lg: "90%", xl: "83%" }
          }
          $paddingRight={sideBarCollapse ? { lg: 2 } : { lg: 0 }}
          $paddingLeft={sideBarCollapse ? { md: 0 } : { md: 2 }}
          $justifyContent={sideBarCollapse ? "center" : "flex-start"}
        // $border="1px solid blue"
        >
          <DefaultImage
            $width={!sideBarCollapse ? "156px" : "50px"}
            $height={!sideBarCollapse ? "67px" : "57px"}
            $objectFit={"contain"}
            $src={businessAccount.businessLogo ? businessAccount.businessLogo : Logo}
          />
        </FlexBox>
      </FlexBox>

      <FlexBox
        $width="100%"
        $flexDirection="column"
        $justifyContent="center"
        $alignItems={sideBarCollapse ? "center" : "flex-start"}
        $position="relative"
      >
        {pages?.map((value, index) => {
          const highlight = value.href == "/" ? selected === "/" || selected === "" : selected.toLowerCase().startsWith(value.href.toLowerCase());
          return (
            <FlexBox
              $width="100%"
              $justifyContent={sideBarCollapse ? "center" : "flex-end"}
              $marginBottom={4}
              $position="relative"
            // $border="1px solid red"
            >
              <FlexBox
                key={index}
                $width={sideBarCollapse ? "36px" : "70%"}
                $height="37px"
                $backgroundColor={highlight ? "primary-light" : "#fff"}
                $onClick={() => handleSelect(value.href.toLowerCase())}
                $borderRadius={"10px"}
                $paddingLeft={2}
                $marginRight={"15%"}
              // $border="1px solid red"
              >
                <Link
                  to={value.href}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <FlexBox $flexDirection="row" $justifyContent="flex-start">
                    <Icon
                      $icon={value.icon}
                      $size="28px"
                      $color={highlight ? "primary" : "#697A8D"}
                    />
                    {!sideBarCollapse && (
                      <Text
                        $fontSize="body"
                        $fontWeight="600"
                        $color={highlight ? "primary" : "text"}
                        $marginLeft={2}
                      >
                        {value.name}
                      </Text>
                    )}
                  </FlexBox>
                </Link>
              </FlexBox>

              {!sideBarCollapse && (
                <Box
                  $width="12px"
                  $height="42px"
                  $backgroundColor={
                    highlight ? "primary" : "#fff"
                  }
                  $borderRadius="10px 0px 0px 10px"
                ></Box>
              )}
            </FlexBox>
          );
        })}
      </FlexBox>

      <CircleBox
        $backgroundColor={"primary"}
        $width="36px"
        $height="36px"
        $position="absolute"
        $right={"-18px"}
        $top={"44px"}
        $alignItems="center"
        $justifyContent="center"
        $onClick={() => handleSideBar()}
      >
        <Icon
          $icon={!sideBarCollapse ? "ArrowBackIos" : "ArrowForwardIos"}
          $size={17}
          $color={"#fff"}
          $marginLeft={"6px"}
          $marginRight={!sideBarCollapse ? "0px" : "4px"}
        />
      </CircleBox>
    </FlexBox>
  );
};

export default SideBar;
