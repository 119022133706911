import { useState } from "react";
import Box from "./Box";

export default function ListBox({ $pointer, $index, $width, children, ...rest }) {
    const [hovering, setHovering] = useState(false)
    function handleMouseEnter() {
        setHovering(true)
    }
    function handleMouseLeave() {
        setHovering(false)
    }
    return (
        <Box
            $padding="3"
            $backgroundColor={hovering ? "primary-light" : $index % 2 === 0 ? "list" : null}
            style={{ cursor: $pointer }}
            $width={ $width ?? "100%" }
            {...rest}
            $onMouseEnter={handleMouseEnter}
            $onMouseLeave={handleMouseLeave}
        >
            {children}
        </Box>
    );
}