import React, { useState } from 'react'
import { useMedicationsSettingsController } from '../SettingsControllers/useMedicationsController'
import { useResponsive } from '../../../Components/Hooks';
import { Text } from '../../../Components/Text';
import { Box, FlexBox, ListBox } from '../../../Components/Boxes';
import { Button } from '../../../Components/Input';
import Icon from '../../../Components/Icons';
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader';
import EmptyState from '../../../Components/NoRecordFound';
import FormDialog from '../../../Components/Modals/FormDialog';
import AddConfigMedicationsDialog from '../Dialogs/AddConfigMedicationsDialog';
import { displayMedicineText } from '../../../utiles/medicineFormatter';

export default function MedicationSettings() {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        title,
        medicines,

        canEdit,
        canAdd,

        onSave,
        onUpdate,
        onDelete,

        isUpdatingConfig,
        isDeletingConfig,
        isGettingConfigs,

        updateErrorMessage,
        deleteErrorMessage,
        gettingErrorMessage,
    } = useMedicationsSettingsController()
    
    const [open, setOpen] = useState(false);
    const [editingMedicine, setEditingMedicine] = useState("")

    function handleDialog(medicine) {
        setOpen(!open)
        setEditingMedicine(medicine)
    }

    return (
        <Box>
            <Text $fontSize="heading-3">{title}</Text>
            {canAdd && <FlexBox
                $justifyContent="flex-end"
                $width="100%"
                $marginTop={2}
                $marginRight={4}
            >
                <Button
                    $onClick={() => {
                        handleDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>Add</Text>
                </Button>
            </FlexBox>}
            <Box $marginTop="2" />
            {!isGettingConfigs && medicines?.map((medicine, index) => {
                return (
                    <ListBox
                        $index={index}
                        $onClick={() => {
                            if (canEdit != true) return
                            handleDialog(medicine)
                        }}
                    >
                        <Text $color="text">{displayMedicineText(medicine)}</Text>
                    </ListBox>
                )
            })}

            {isGettingConfigs && <FlexBox $width="100%" $marginTop={2} $flexDirection="column">
                {[1, 2, 3].map((val, index) => {
                    return (
                        <Box
                            $marginBottom="2"
                            $width="100%"
                        >
                            <MuiSkeletonLoader
                                $variant="rectangular"
                                $width={"100%"}
                                $height={"40px"}
                            />
                        </Box>
                    );
                })}
            </FlexBox>}

            {!isGettingConfigs && medicines?.length <= 0 && <FlexBox $marginTop={7} $justifyContent="center">
                <EmptyState />
            </FlexBox>}

            {open && <FormDialog
                $open={open}
                $handleDialog={handleDialog}
                $title={`Add ${title}`}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <AddConfigMedicationsDialog
                    medicine={editingMedicine}
                    loading={isUpdatingConfig || isDeletingConfig}
                    onDelete={async () => {
                        await onDelete(editingMedicine)
                        handleDialog()
                    }}
                    onSave={async (val) => {
                        if (editingMedicine) {
                            await onUpdate(editingMedicine, {...val})
                        } else {
                            await onSave({ ...val })
                        }
                        handleDialog()
                    }}
                    handleDialog={handleDialog}
                    errorMessage={updateErrorMessage || deleteErrorMessage}
                />
            </FormDialog>}
        </Box>
    )
}
