import { useDispatch } from "react-redux";
import {
    clearAllLocalDiariesReducer,
    clearAllErrorMessagesReducer, 
    clearAllStatusesReducer,
    getDiariesReducer,
    createDiaryReducer,
} from "../../slices/staffDiarySlice";

const useTeamDispathers = () => {
  const dispatch = useDispatch();

  const createDiary = (data) => {
    return dispatch(createDiaryReducer(data));
  };

  const getDiaries = (data) => {
    return dispatch(getDiariesReducer(data));
  };

  const clearAllLocalDiaries = (data) => {
    return dispatch(clearAllLocalDiariesReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllStatuses = (data) => {
    return dispatch(clearAllStatusesReducer(data));
  };

  return {
    createDiary,
    getDiaries,
    clearAllLocalDiaries,
    clearAllErrorMessages,
    clearAllStatuses,
    clearAllLocalDiaries,
  };
};

export default useTeamDispathers;
