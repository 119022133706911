import Resizer from "react-image-file-resizer";

export function resizeImage(file) {
    return new Promise((resolve, reject) => {
        try {
            Resizer.imageFileResizer(
                file,
                500,
                500,
                "JPEG",
                100,
                0,
                (uri) => {
                    resolve(uri)
                },
                "file"
            );
        } catch (err) {
            reject(err)
        }
    })
}