import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createRiskAssessmentApiService,
  deleteRiskAssessmentApiService,
  getAllRiskAssessmentApiService,
  restoreArchivedRiskAssessmentApiService,
  updateRiskAssessmentApiService,
} from "../../../services/applicationBackendApis/riskAssessment";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  riskAssessments: [],
  riskAssessment: null,
  moreRiskAssessmentsLeftInPagination: false,
  errorMessages: {
    restoreRiskAssessmentErrorMessage: null,
    createRiskAssessmentErrorMessage: null,
    deleteRiskAssessmentErrorMessage: null,
    updateRiskAssessmentErrorMessage: null,
    getRiskAssessmentsErrorMessage: null,
    getRiskAssessmentErrorMessage: null,
  },
  statuses: {
    restoreRiskAssessmentStatus: null,
    createRiskAssessmentStatus: null,
    deleteRiskAssessmentStatus: null,
    updateRiskAssessmentStatus: null,
    getRiskAssessmentsStatus: null,
    getRiskAssessmentStatus: null,
  },
  sucessMessages: {
    restoreRiskAssessmentSuccessMessage: null,
    updateRiskAssessmentSuccessMessage: null,
  },
};

//------------- creating new risk Assessment ---------------\\
export const createRiskAssessmentReducer = createAsyncThunk(
  "riskAssessment/createRiskAssessment",
  async (riskAssessmnetData) => {
    try {
      const response = await createRiskAssessmentApiService({
        name: riskAssessmnetData.name,
        riskRating: riskAssessmnetData.riskRating,
        assessments: riskAssessmnetData.assessments,
        assessmentDate: riskAssessmnetData.assessmentDate,
        nextReviewDate: riskAssessmnetData.nextReviewDate,
        carriedOutBy: riskAssessmnetData.carriedOutBy,
        clientId: riskAssessmnetData.clientId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const restoreArchivedRiskAssessmentReducer = createAsyncThunk(
  "riskAssessment/restoreArchivedRiskAssessment",
  async (riskAssessmnetData) => {
    try {
      const response = await restoreArchivedRiskAssessmentApiService({
        clientId: riskAssessmnetData.clientId,
        id: riskAssessmnetData.riskAssessmentId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------- getting all riskAssessment from database ------------\\
export const getAllRiskAssessmentReducer = createAsyncThunk(
  "riskAssessment/getAllRiskAssessment",
  async (riskAssessmnetData) => {
    try {
      const response = await getAllRiskAssessmentApiService({
        clientId: riskAssessmnetData.clientId,
        startAfter: riskAssessmnetData.startAfter,
        limit: riskAssessmnetData.limit ?? PAGINATION_LIMITS.RISKASSESSMENTS,
        isArchived: riskAssessmnetData.isArchived,
      });
      return {
        data: response.data,
        limit: riskAssessmnetData.limit ?? PAGINATION_LIMITS.RISKASSESSMENTS,
        startAfter: riskAssessmnetData.startAfter,
    }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateRiskAssessmentReducer = createAsyncThunk(
  "riskAssessment/updateRiskAssessment",
  async (riskAssessmnetData) => {
    try {
      const response = await updateRiskAssessmentApiService({
        clientId: riskAssessmnetData.clientId,
        riskAssessmentId: riskAssessmnetData.riskAssessmentId,
        assessmentDate: riskAssessmnetData.assessmentDate,
        nextReviewDate: riskAssessmnetData.nextReviewDate,
        carriedOutBy: riskAssessmnetData.carriedOutBy,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const deleteRiskAssessmentReducer = createAsyncThunk(
  "riskAssessment/deleteRiskAssessment",
  async (riskAssessmnetData) => {
    try {
      const response = await deleteRiskAssessmentApiService({
        clientId: riskAssessmnetData.clientId,
        riskAssessmentId: riskAssessmnetData.riskAssessmentId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const riskAssessmentSlice = createSlice({
  name: "riskAssessment",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalRiskAssessmentsReducer: (state, action) => {
      state.riskAssessments = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.sucessMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createRiskAssessmentReducer.pending, (state, action) => {
      state.statuses.createRiskAssessmentStatus = asyncStates.PENDING;
      state.errorMessages.createRiskAssessmentErrorMessage = null;
    });
    builder.addCase(createRiskAssessmentReducer.rejected, (state, action) => {
      state.statuses.createRiskAssessmentStatus = asyncStates.FAILURE;
      state.errorMessages.createRiskAssessmentErrorMessage =
        action.error?.message;
    });
    builder.addCase(createRiskAssessmentReducer.fulfilled, (state, action) => {
      state.statuses.createRiskAssessmentStatus = asyncStates.SUCCESS;
      state.errorMessages.createRiskAssessmentErrorMessage = null;
    });

    builder.addCase(restoreArchivedRiskAssessmentReducer.pending, (state, action) => {
      state.statuses.restoreRiskAssessmentStatus = asyncStates.PENDING;
      state.errorMessages.restoreRiskAssessmentErrorMessage = null;
    });
    builder.addCase(restoreArchivedRiskAssessmentReducer.rejected, (state, action) => {
      state.statuses.restoreRiskAssessmentStatus = asyncStates.FAILURE;
      state.errorMessages.restoreRiskAssessmentErrorMessage =
        action.error?.message;
    });
    builder.addCase(restoreArchivedRiskAssessmentReducer.fulfilled, (state, action) => {
      state.statuses.restoreRiskAssessmentStatus = asyncStates.SUCCESS;
      state.errorMessages.restoreRiskAssessmentErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateRiskAssessmentReducer.pending, (state, action) => {
      state.statuses.updateRiskAssessmentStatus = asyncStates.PENDING;
      state.errorMessages.updateRiskAssessmentErrorMessage = null;
    });
    builder.addCase(updateRiskAssessmentReducer.rejected, (state, action) => {
      state.statuses.updateRiskAssessmentStatus = asyncStates.FAILURE;
      state.errorMessages.updateRiskAssessmentErrorMessage =
        action.error?.message;
    });
    builder.addCase(updateRiskAssessmentReducer.fulfilled, (state, action) => {
      state.statuses.updateRiskAssessmentStatus = asyncStates.SUCCESS;
      state.errorMessages.updateRiskAssessmentErrorMessage = null;
      state.sucessMessages.updateRiskAssessmentSuccessMessage =
        action.payload.message;
    });

    // start handling get files async calls
    builder.addCase(getAllRiskAssessmentReducer.pending, (state, action) => {
      state.statuses.getRiskAssessmentsStatus = asyncStates.PENDING;
      state.errorMessages.getRiskAssessmentsErrorMessage = null;
    });
    builder.addCase(getAllRiskAssessmentReducer.rejected, (state, action) => {
      state.statuses.getRiskAssessmentsStatus = asyncStates.FAILURE;
      state.errorMessages.getRiskAssessmentsErrorMessage =
        action.error?.message;
    });
    builder.addCase(getAllRiskAssessmentReducer.fulfilled, (state, action) => {
      state.statuses.getRiskAssessmentsStatus = asyncStates.SUCCESS;
      state.errorMessages.getRiskAssessmentsErrorMessage = null;
      state.riskAssessments = action.payload.startAfter ? [...state.riskAssessments, ...action.payload.data.riskassessments] : action.payload.data.riskassessments;

      if (action.payload.data.riskassessments.length >= action.payload.limit) {
        state.moreRiskAssessmentsLeftInPagination = true;
      } else {
        state.moreRiskAssessmentsLeftInPagination = false;
      }
    });

    // start handling get file async calls
    // builder.addCase(getSingleRiskAssessmentReducer.pending, (state, action) => {
    //     state.statuses.getFileStatus = asyncStates.PENDING
    //     state.errorMessages.getFileErrorMessage = null
    // })
    // builder.addCase(getSingleRiskAssessmentReducer.rejected, (state, action) => {
    //     state.statuses.getFileStatus = asyncStates.FAILURE
    //     state.errorMessages.getFileErrorMessage = action.error?.message
    // })
    // builder.addCase(getSingleRiskAssessmentReducer.fulfilled, (state, action) => {
    //     state.statuses.getFileStatus = asyncStates.SUCCESS
    //     state.errorMessages.getFileErrorMessage = null
    //     state.file = action.payload
    // })

    // start handling delete file async calls
    builder.addCase(deleteRiskAssessmentReducer.pending, (state, action) => {
      state.statuses.deleteRiskAssessmentStatus = asyncStates.PENDING;
      state.errorMessages.deleteRiskAssessmentErrorMessage = null;
    });
    builder.addCase(deleteRiskAssessmentReducer.rejected, (state, action) => {
      state.statuses.deleteRiskAssessmentStatus = asyncStates.FAILURE;
      state.errorMessages.deleteRiskAssessmentErrorMessage =
        action.error?.message;
    });
    builder.addCase(deleteRiskAssessmentReducer.fulfilled, (state, action) => {
      state.statuses.deleteRiskAssessmentStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteRiskAssessmentErrorMessage = null;
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllLocalRiskAssessmentsReducer,
  clearAllSuccessMessagesReducer,
} = riskAssessmentSlice.actions;

export default riskAssessmentSlice.reducer;
