import React from 'react'
import { Box, FlexBox } from '../../../Components/Boxes'
import EventCard from './EventCard'
import Tabs from '../../../Components/Input/Tabs'
import { Button } from '../../../Components/Input'
import Icon from '../../../Components/Icons'
import { Text } from '../../../Components/Text'
import { Grid, GridContainer } from '../../../Components/Grid'
import EmptyState from "../../../Components/NoRecordFound";
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader'

export default function CalendarListView(props) {
    const {
        events,
        pastEvents,
        moreEventsLeft,
        isLoading,

        toggleView,
        onNewEvent,
        onClick,
        onLoadMore,
    } = props
    console.log(events)
    return (
        <Box $width="100%">
            <FlexBox $justifyContent={"space-between"} $width="100%" $marginTop={2}>
                <Tabs
                    $onChanged={(val) => {
                        toggleView()
                    }}
                    $value={pastEvents ? 1 : 0}
                    $tabs={[
                        { name: "Upcoming" },
                        { name: "Past" },
                    ]}
                    $width={"fit-content"}
                />

                <Button
                    $onClick={() => {
                        onNewEvent()
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>New Shift</Text>
                </Button>
            </FlexBox>

            <Box $marginTop={3} />

            <FlexBox $width="100%" $marginTop={3} $padding="3">
                <GridContainer $spacing={1}>
                    <Grid $xs={4}>
                        <Text $fontWeight="bold">
                            Staff
                        </Text>
                    </Grid>
                    <Grid $xs={3}>
                        <Text $fontWeight="bold">
                            Start Date
                        </Text>
                    </Grid>
                    <Grid $xs={3}>
                        <Text $fontWeight="bold">
                            End Date
                        </Text>
                    </Grid>
                    <Grid $xs={2}>
                        <Text $fontWeight="bold">
                            Status
                        </Text>
                    </Grid>
                </GridContainer>
            </FlexBox>

            <Box $marginTop={3} />

            {events && events?.map((event, index) => {
                return <EventCard key={event.metadata.id} event={event} index={index} onClick={() => onClick(event)} />
            })}

            {isLoading && [1, 2, 3, 4].map((value) => {
                return (
                    <Box $width="100%" $marginBottom="2" key={value}>
                        <MuiSkeletonLoader
                            $variant="rectangular"
                            $width={"100%"}
                            $height={40}
                        />
                    </Box>
                );
            })}

            {moreEventsLeft && <FlexBox $justifyContent="center" $marginTop="6" $width="100%">
                <Button $onClick={() => {
                    onLoadMore()
                }} >Load More</Button>
            </FlexBox>}

            {!isLoading && events?.length < 1 && <FlexBox $width="100%" $justifyContent="center" $marginTop={7}>
                <EmptyState />
            </FlexBox>}

            <Box $marginTop={5} />
        </Box>
    )
}
