import { Checkbox } from "@mui/material";

export default function CheckBox({
  children,
  $checked,
  $color,
  $onChange,
  $onClick,
  $required,
  $size,
}) {
  return (
    <Checkbox
      checked={$checked}
      color={$color}
      onChange={$onChange}
      required={$required}
      size={$size}
      onClick={$onClick}
    >
      {children}
    </Checkbox>
  );
}
