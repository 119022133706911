import React from 'react'
import useBlockListDispathers from '../../../redux/dispatchers/useBlockListDispatchers'
import { useEffect } from 'react'
import * as asyncStates from "../../../redux/constants/asyncStates";
import { useBlockListCreateErrorMessageSelector, useBlockListCreateStatusSelector, useBlockListDeleteErrorMessageSelector, useBlockListDeleteStatusSelector } from '../../../redux/selectors/useBlockListSelectors'
import { ErrorAndSuccess, FlexBox } from '../../../Components/Boxes';
import { Button, Textarea } from '../../../Components/Input';
import { Text } from '../../../Components/Text';
import CircularLoader from '../../../Components/Icons/circularLoader';
import { useState } from 'react';
import { SearchUserSelectBox } from '../../components/searchSelectBox';

export default function EditBlockList(props) {
    const {
        $handleDialog,
        $clientId,
        $staffId,
        $blockList,
    } = props

    const [blockList, setBlockList] = useState($blockList)
    const [staff, setStaff] = useState(blockList?.staff)
    const [client, setClient] = useState(blockList?.client)
    const [reason, setReason] = useState(blockList?.reason)
    const [notes, setNotes] = useState(blockList?.notes)

    const {
        getAllBlockList,
        createBlockList,
        deleteBlockList,
        clearAllErrorMessages,
    } = useBlockListDispathers()

    const createBlockListStatus = useBlockListCreateStatusSelector()
    const deleteBlockListStatus = useBlockListDeleteStatusSelector()
    const loading = createBlockListStatus === asyncStates.PENDING || deleteBlockListStatus === asyncStates.PENDING

    const createBlockListError = useBlockListCreateErrorMessageSelector()
    const deleteBlockListError = useBlockListDeleteErrorMessageSelector()
    const errorMessage = createBlockListError || deleteBlockListError


    useEffect(() => {
        clearAllErrorMessages()
    }, [])

    const onDeleteBlockListClicked = async () => {
        await deleteBlockList({
            clientId: blockList?.staff?.id,
            staffId: $blockList?.client?.id,
            blockListId: blockList?.id,
        }).unwrap()
        await getAllBlockList({
            clientId: $clientId,
            staffId: $staffId,
        }).unwrap()
        $handleDialog()
    }

    const onCreateBlockListClicked = async () => {
        await createBlockList({
            clientId: $clientId ?? client?.id,
            staffId: $staffId ?? staff?.id,
            reason,
            notes,
        }).unwrap()
        await getAllBlockList({
            clientId: $clientId,
            staffId: $staffId,
        }).unwrap()
        $handleDialog()
    }


    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1}>
                    {$staffId ? "Client" : "Staff"}
                </Text>
                <SearchUserSelectBox
                    disabled={$blockList ? true : false}
                    searchByStaff={$staffId ? false : true}
                    searchByClient={$staffId ? true : false}
                    selected={$staffId ? client : staff}
                    onChange={(user) => {
                        if ($staffId) setClient(user)
                        else setStaff(user)
                    }}
                />

                <br />
                <Text $fontSize="body" $marginBottom={1}>
                    Reason for blocking
                </Text>
                <Textarea
                    $type={"text"}
                    $name={"reason"}
                    $value={reason}
                    $onChange={(e) => setReason(e.target.value)}
                    $width="100%"
                    $rows={5}
                />
                <br />
                <Text $fontSize="body" $marginBottom={1}>
                    Notes
                </Text>
                <Textarea
                    $type={"text"}
                    $name={"notes"}
                    $value={notes}
                    $onChange={(e) => setNotes(e.target.value)}
                    $width="100%"
                    $rows={5}
                />

                {errorMessage && (
                    <FlexBox $width="100%" $marginBottom={1} $marginTop={1}>
                        <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                    </FlexBox>
                )}
            </FlexBox>

            <FlexBox
                $justifyContent={"flex-end"}
                $padding={4}
                $backgroundColor="list"
            >
                {blockList && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        if (loading) return;
                        onDeleteBlockListClicked();
                    }}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {deleteBlockListStatus !== asyncStates.PENDING ? (
                            "Delete"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>
                }
                {!blockList && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        if (loading) return;
                        onCreateBlockListClicked();
                    }}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {createBlockListStatus !== asyncStates.PENDING ? (
                            "Save"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}
            </FlexBox>
        </>
    )
}
