import { useSelector } from "react-redux";

export const useBusinessAccountsSelector = () => useSelector((state) => {
    return state.businessAccount.allBusinessAccounts;
});

export const useBusinessAccountSelector = () => useSelector((state) => {
    return state.businessAccount.singleBusinessAccount;
});

// ------------------- selector error messages ------------------ //
export const useBusinessAccountsGetErrorMessageSelector = () => useSelector((state) => {
    return state.businessAccount.errorMessages.getAllBusinessAccountsErrorMessage;
});

export const useBusinessAccountGetErrorMessageSelector = () => useSelector((state) => {
    return state.businessAccount.errorMessages.getSingleBusinessAccountErrorMessage;
});

export const useBusinessAccountEditErrorMessageSelector = () => useSelector((state) => {
    return state.businessAccount.errorMessages.editBusinessAccountErrorMessage;
});

// ------------ status selectors ------------ //
export const useBusinessAccountsGetStatusSelector = () => useSelector((state) => {
    return state.businessAccount.statuses.getAllBusinessAccountsStatus;
});

export const useBusinessAccountGetStatusSelector = () => useSelector((state) => {
    return state.businessAccount.statuses.getSingleBusinessAccountStatus;
});

export const useBusinessAccountEditStatusSelector = () => useSelector((state) => {
    return state.businessAccount.statuses.editBusinessAccountStatus;
});

// ------------ success messages ------------ //
export const useBusinessAccountsGetSuccessMessageSelector = () => useSelector((state) => {
    return state.businessAccount.successMessages.getAllBusinessAccountsSuccessMessage;
});

export const useBusinessAccountGetSuccessMessageSelector = () => useSelector((state) => {
    return state.businessAccount.successMessages.getSingleBusinessAccountSuccessMessage;
});

export const useBusinessAccountEditSuccessMessageSelector = () => useSelector((state) => {
    return state.businessAccount.successMessages.editBusinessAccountSuccessMessage;
});
