import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllBusinessAccountsApiService,
    getBusinessAccountApiService,
    editBusinessAccountApiService,
} from "../../../services/applicationBackendApis/businessAccount";
import * as asyncStates from "../../constants/asyncStates";

const initialState = {
    allBusinessAccounts: [],
    singleBusinessAccount: null,
    errorMessages: {
        getAllBusinessAccountsErrorMessage: null,
        getSingleBusinessAccountErrorMessage: null,
        editBusinessAccountErrorMessage: null,
    },
    statuses: {
        getAllBusinessAccountsStatus: null,
        getSingleBusinessAccountStatus: null,
        editBusinessAccountStatus: null,
    },
    successMessages: {
        getAllBusinessAccountsSuccessMessage: null,
        getSingleBusinessAccountSuccessMessage: null,
        editBusinessAccountSuccessMessage: null,
    },
};

export const getBusinessAccountsReducer = createAsyncThunk(
    "businessAccounts/getAllBusinessAccountsMember",
    async (businessAccount) => {
        try {
            const response = await getAllBusinessAccountsApiService();
            return {
                data: response.data,
            };
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

//Single BusinessAccount Reducer
export const getBusinessAccountReducer = createAsyncThunk(
    "businessAccounts/getBusinessAccount",
    async (businessAccount) => {
        try {
            const response = await getBusinessAccountApiService({
                id: businessAccount.businessAccountId,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

//Edit BusinessAccount Reducer
export const editBusinessAccountReducer = createAsyncThunk(
    "businessAccounts/editBusinessAccount",
    async (businessAccount) => {
        try {
            const response = await editBusinessAccountApiService({
                id: businessAccount.businessAccountId,
                name: businessAccount.name,
                email: businessAccount.email,
                timesheetsEmail: businessAccount.timesheetsEmail,
                documentExpiryEmail: businessAccount.documentExpiryEmail,
                timesheetsDisclaimer: businessAccount.timesheetsDisclaimer,
                file: businessAccount.logo,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

const businessAccountSlice = createSlice({
    name: "businessAccounts",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {};
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {};
        },
        clearAllSuccessMessagesReducer: (state, action) => {
            state.successMessages = {};
        },
        clearAllLocalBusinessAccountsReducer: (state, action) => {
            state.allBusinessAccounts = [];
        },
        clearSelectedBusinessAccountReducer: (state, action) => {
            state.singleBusinessAccount = null;
        },
        selectBusinessAccountReducer: (state, action) => {
            state.singleBusinessAccount = action.payload
        }
    },

    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {
        // Get the New BusinessAccount Data
        builder.addCase(getBusinessAccountsReducer.pending, (state, action) => {
            state.statuses.getAllBusinessAccountsStatus = asyncStates.PENDING;
            state.errorMessages.getAllBusinessAccountsErrorMessage = null;
        });
        builder.addCase(getBusinessAccountsReducer.rejected, (state, action) => {
            state.statuses.getAllBusinessAccountsStatus = asyncStates.FAILURE;
            state.errorMessages.getAllBusinessAccountsErrorMessage = action.error?.message;
        });
        builder.addCase(getBusinessAccountsReducer.fulfilled, (state, action) => {
            state.statuses.getAllBusinessAccountsStatus = asyncStates.SUCCESS;
            state.errorMessages.getAllBusinessAccountsErrorMessage = null;
            state.allBusinessAccounts = action.payload.data.businessAccounts;
        });

        //Get the Single BusinessAccount ID
        builder.addCase(getBusinessAccountReducer.pending, (state, action) => {
            state.statuses.getSingleBusinessAccountStatus = asyncStates.PENDING;
            state.errorMessages.getSingleBusinessAccountErrorMessage = null;
        });
        builder.addCase(getBusinessAccountReducer.rejected, (state, action) => {
            state.statuses.getSingleBusinessAccountStatus = asyncStates.FAILURE;
            state.errorMessages.getSingleBusinessAccountErrorMessage = action.error?.message;
        });
        builder.addCase(getBusinessAccountReducer.fulfilled, (state, action) => {
            state.statuses.getSingleBusinessAccountStatus = asyncStates.SUCCESS;
            state.errorMessages.getSingleBusinessAccountErrorMessage = null;
            state.singleBusinessAccount = action.payload;
        });

        //Edit the BusinessAccount ID
        builder.addCase(editBusinessAccountReducer.pending, (state, action) => {
            state.statuses.editBusinessAccountStatus = asyncStates.PENDING;
            state.errorMessages.editBusinessAccountErrorMessage = null;
        });
        builder.addCase(editBusinessAccountReducer.rejected, (state, action) => {
            state.statuses.editBusinessAccountStatus = asyncStates.FAILURE;
            state.errorMessages.editBusinessAccountErrorMessage = action.error?.message;
        });
        builder.addCase(editBusinessAccountReducer.fulfilled, (state, action) => {
            state.statuses.editBusinessAccountStatus = asyncStates.SUCCESS;
            state.errorMessages.editBusinessAccountErrorMessage = null;
            state.successMessages.editBusinessAccountSuccessMessage = action.payload.message;
        });
    },
});

export const {
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
    clearAllSuccessMessagesReducer,
    clearAllLocalBusinessAccountsReducer,
    selectBusinessAccountReducer,
    clearSelectedBusinessAccountReducer,
} = businessAccountSlice.actions;

export default businessAccountSlice.reducer;
