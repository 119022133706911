import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as asyncStates from "../../constants/asyncStates";
import {
  createTeamApiService,
  deleteTeamApiService,
  getTeamApiService,
  getTeamsApiService,
  updateTeamApiService,
} from "../../../services/applicationBackendApis/teams";

const initialState = {
  teams: [],
  team: null,
  filterTeams: [],
  fileUploadPercentage: null,
  errorMessages: {
    createTeamErrorMessage: null,
    deleteTeamErrorMessage: null,
    updateTeamErrorMessage: null,
    getTeamsErrorMessage: null,
    getTeamErrorMessage: null,
  },
  statuses: {
    createTeamStatus: null,
    deleteTeamStatus: null,
    updateTeamStatus: null,
    getTeamsStatus: null,
    getTeamStatus: null,
  },
};

export const createTeamReducer = createAsyncThunk(
  "teams/createTeam",
  async (teamData) => {
    try {
      const response = await createTeamApiService({
        name: teamData.name,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateTeamReducer = createAsyncThunk(
  "teams/updateTeam",
  async (teamData) => {
    try {
      const response = await updateTeamApiService({
        name: teamData.name,
        teamId :teamData.teamId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const getTeamsReducer = createAsyncThunk(
  "teams/getTeams",
  async (teamData) => {
    try {
      const response = await getTeamsApiService({
        startAfter: teamData.startAfter,
        limit: teamData.limit,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const getTeamReducer = createAsyncThunk(
  "teams/getTeam",
  async (teamData) => {
    try {
      const response = await getTeamApiService({
        teamId :teamData.teamId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const deleteTeamReducer = createAsyncThunk(
  "teams/deleteTeam",
  async (teamData) => {
    try {
      const response = await deleteTeamApiService({
        teamId :teamData.teamId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalTeamsReducer: (state, action) => {
      state.teams = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    filterByTeamReducer: (state, action) => {
      state.filterTeams = action.payload.teams ?? []
    }
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createTeamReducer.pending, (state, action) => {
      state.statuses.createTeamStatus = asyncStates.PENDING;
      state.errorMessages.createTeamErrorMessage = null;
    });
    builder.addCase(createTeamReducer.rejected, (state, action) => {
      state.statuses.createTeamStatus = asyncStates.FAILURE;
      state.errorMessages.createTeamErrorMessage = action.error?.message;
    });
    builder.addCase(createTeamReducer.fulfilled, (state, action) => {
      state.statuses.createTeamStatus = asyncStates.SUCCESS;
      state.errorMessages.createTeamErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateTeamReducer.pending, (state, action) => {
      state.statuses.updateTeamStatus = asyncStates.PENDING;
      state.errorMessages.updateTeamErrorMessage = null;
    });
    builder.addCase(updateTeamReducer.rejected, (state, action) => {
      state.statuses.updateTeamStatus = asyncStates.FAILURE;
      state.errorMessages.updateTeamErrorMessage = action.error?.message;
    });
    builder.addCase(updateTeamReducer.fulfilled, (state, action) => {
      state.statuses.updateTeamStatus = asyncStates.SUCCESS;
      state.errorMessages.updateTeamErrorMessage = null;
    });

    // start handling get files async calls
    builder.addCase(getTeamsReducer.pending, (state, action) => {
      state.statuses.getTeamsStatus = asyncStates.PENDING;
      state.errorMessages.getTeamsErrorMessage = null;
    });
    builder.addCase(getTeamsReducer.rejected, (state, action) => {
      state.statuses.getTeamsStatus = asyncStates.FAILURE;
      state.errorMessages.getTeamsErrorMessage = action.error?.message;
    });
    builder.addCase(getTeamsReducer.fulfilled, (state, action) => {
      state.statuses.getTeamsStatus = asyncStates.SUCCESS;
      state.errorMessages.getTeamsErrorMessage = null;
      state.teams = action.payload.teams;
    });

    // start handling get file async calls
    builder.addCase(getTeamReducer.pending, (state, action) => {
      state.statuses.getTeamStatus = asyncStates.PENDING;
      state.errorMessages.getTeamErrorMessage = null;
    });
    builder.addCase(getTeamReducer.rejected, (state, action) => {
      state.statuses.getTeamStatus = asyncStates.FAILURE;
      state.errorMessages.getTeamErrorMessage = action.error?.message;
    });
    builder.addCase(getTeamReducer.fulfilled, (state, action) => {
      state.statuses.getTeamStatus = asyncStates.SUCCESS;
      state.errorMessages.getTeamErrorMessage = null;
      state.team = action.payload;
    });

    // start handling delete file async calls
    builder.addCase(deleteTeamReducer.pending, (state, action) => {
      state.statuses.deleteTeamStatus = asyncStates.PENDING;
      state.errorMessages.deleteTeamErrorMessage = null;
    });
    builder.addCase(deleteTeamReducer.rejected, (state, action) => {
      state.statuses.deleteTeamStatus = asyncStates.FAILURE;
      state.errorMessages.deleteTeamErrorMessage = action.error?.message;
    });
    builder.addCase(deleteTeamReducer.fulfilled, (state, action) => {
      state.statuses.deleteTeamStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteTeamErrorMessage = null;
    });
  },
});

export const {
  clearAllLocalTeamsReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  filterByTeamReducer,
} = teamSlice.actions;

export default teamSlice.reducer;
