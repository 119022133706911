import React, { useEffect } from "react";
import { useState } from "react";
import { ErrorAndSuccess, FlexBox } from "../../../../../../Components/Boxes";
import { Button, TextBox } from "../../../../../../Components/Input";
import { Text } from "../../../../../../Components/Text";
import useClientAccessDispathers from "../../../../../../redux/dispatchers/useClientAccessDispatchers";
import {
  useClientAccessCreateErrorMessageSelector,
  useClientAccessCreateStatusSelector,
} from "../../../../../../redux/selectors/useClientAccessSelectors";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import { useMemo } from "react";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";

export const ClientAccessDialog = ({ clientId, $handleDialog }) => {
  // ---------------- states ----------------------
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  // ---------------- selectors --------------------
  const clientAccessCreateErrorMessage = useClientAccessCreateErrorMessageSelector();
  const clientAccessCreateStatus = useClientAccessCreateStatusSelector();

  // ---------------- useMemoStates ----------------
  const errorMessage = useMemo(() => {
    return clientAccessCreateErrorMessage;
  }, [clientAccessCreateErrorMessage]);

  const status = useMemo(() => {
    return clientAccessCreateStatus;
  }, [clientAccessCreateStatus]);

  const successMessage = useMemo(() => {
    return null;
  }, [])

  // ------------------ dispatchers --------------
  const { createClientAccess, getClientAccess, clearAllErrorMessages, clearAllSuccessMessages } = useClientAccessDispathers();

  const onCreateClientAccessClicked = async () => {
    console.log(clientId)
    await createClientAccess({
      email: email,
      name: name,
      clientId: clientId,
    }).unwrap();
    getClientAccess({ clientId });
    $handleDialog();
  };

  // ------------------ useEffect -----------------

  useEffect(() => {
    clearAllErrorMessages();
    clearAllSuccessMessages();
  }, [])

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Email
        </Text>
        <TextBox
          $hint={"xyz@gmail.com"}
          $type={"email"}
          $name={"email"}
          $value={email}
          $onChange={(e) => setEmail(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Name
        </Text>
        <TextBox
          $hint={"Joe Doe"}
          $type={"text"}
          $name={"name"}
          $value={name}
          $onChange={(e) => setName(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />

        {errorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {(successMessage && status === asyncStates.SUCCESS) && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="success">{successMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
      </FlexBox>

      {/* The last buttons */}
      <FlexBox
        $justifyContent={"flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (status === asyncStates.PENDING) return;
            onCreateClientAccessClicked();
          }}
        >
          {status !== asyncStates.PENDING ? 'Send Invitation Code' : <CircularLoader $color="white" $size={25} />}
        </Button>
      </FlexBox>
    </>
  );
};
