import React, { useMemo, useState } from 'react'
import { Box, FlexBox } from '../../Components/Boxes';
import { useResponsive } from '../../Components/Hooks';
import Tabs from '../../Components/Input/Tabs';
import { Grid, GridContainer } from '../../Components/Grid';
import { CareplansSettings, CompliancesSettings, AdditionalFormsSettings, MedicineRefusalReasonsSettings, RiskassessmentsSettings, ServicesSettings, EventCheckListSettings, JobRolesSettings } from "./SettingsUI"
import MedicationSettings from './SettingsUI/MedicationSettings';
import usePermissionsController from '../../permissions';
import PolicyListSettings from './SettingsUI/PolicyListSettings';
import BusinessAccountSettings from './SettingsUI/BusinessAccountSettings';
import TimeOffApproversSettings from './SettingsUI/TimeOffApproversSettings';
import DefaultQualificationsSettings from './SettingsUI/DefaultQualificationsSettings';

export default function Settings() {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        accessViewSettingsRiskassessments,
        accessViewSettingsTasks,
        accessViewSettingsCompliance,
        accessViewSettingsCareplans,
        accessViewSettingsMedication,
        accessViewSettingsRefusalReaosns,
        accessViewSettingsAdditionalForms,
        accessViewSettingsEventCheckLists,
        accessViewSettingsPolicy,
        accessViewSettingsJobroles,
        accessViewSettingsTimeOffApprovers,
        accessViewSettingsBusinessAccount,
        accessViewSettingsDefaultQualifications,
    } = usePermissionsController()
    const [tabIndex, setTabIndex] = useState(0)

    const tabOptions = useMemo(() => {
        const opts = []

        if (accessViewSettingsBusinessAccount) {
            opts.push({ name: "Business Account" })
        }

        if (accessViewSettingsTasks) {
            opts.push({ name: "Tasks" })
        }
        if (accessViewSettingsCompliance) {
            opts.push({ name: "Compliances" })
        }
        if (accessViewSettingsCareplans) {
            opts.push({ name: "Care Plan" })
        }
        if (accessViewSettingsRiskassessments) {
            opts.push({ name: "Risk Assessments" })
        }
        if (accessViewSettingsMedication) {
            opts.push({ name: "Medication" })
        }
        if (accessViewSettingsRefusalReaosns) {
            opts.push({ name: "Missed Medication Reasons" })
        }
        if (accessViewSettingsAdditionalForms) {
            opts.push({ name: "Custom Forms" })
        }
        if (accessViewSettingsEventCheckLists) {
            opts.push({ name: "Shift Checklist" })
        }
        if (accessViewSettingsPolicy) {
            opts.push({ name: "Policy List" })
        }
        if (accessViewSettingsJobroles) {
            opts.push({ name: "Job Roles" })
        }
        if (accessViewSettingsTimeOffApprovers) {
            opts.push({ name: "Time Off Approvers" })
        }
        if (accessViewSettingsDefaultQualifications) {
            opts.push({ name: "Default Qualifications" })
        }

        return opts
    }, [
        accessViewSettingsRiskassessments,
        accessViewSettingsTasks,
        accessViewSettingsCompliance,
        accessViewSettingsCareplans,
        accessViewSettingsMedication,
        accessViewSettingsRefusalReaosns,
        accessViewSettingsAdditionalForms,
        accessViewSettingsEventCheckLists,
        accessViewSettingsPolicy,
        accessViewSettingsJobroles,
        accessViewSettingsBusinessAccount,
    ])

    return (
        <FlexBox
            $width="100%"
            $height="100%"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $flexDirection="column"
        >
            <GridContainer>
                <Grid $xs={4} $md={2}>
                    <Tabs
                        $isVertical
                        $onChanged={(val) => {
                            setTabIndex(val)
                        }}
                        $value={tabIndex}
                        $tabs={tabOptions}
                    />
                </Grid>

                <Grid $xs={8} $md={10}>
                    <Box $padding="5">
                        {tabOptions[tabIndex].name == "Business Account" && <BusinessAccountSettings />}
                        {tabOptions[tabIndex].name == "Missed Medication Reasons" && <MedicineRefusalReasonsSettings />}
                        {tabOptions[tabIndex].name == "Tasks" && <ServicesSettings />}
                        {tabOptions[tabIndex].name == "Compliances" && <CompliancesSettings />}
                        {tabOptions[tabIndex].name == "Care Plan" && <CareplansSettings />}
                        {tabOptions[tabIndex].name == "Risk Assessments" && <RiskassessmentsSettings />}
                        {tabOptions[tabIndex].name == "Medication" && <MedicationSettings />}
                        {tabOptions[tabIndex].name == "Custom Forms" && <AdditionalFormsSettings />}
                        {tabOptions[tabIndex].name == "Shift Checklist" && <EventCheckListSettings />}
                        {tabOptions[tabIndex].name == "Policy List" && <PolicyListSettings/>}
                        {tabOptions[tabIndex].name == "Job Roles" && <JobRolesSettings />}
                        {tabOptions[tabIndex].name == "Time Off Approvers" && <TimeOffApproversSettings />}
                        {tabOptions[tabIndex].name == "Default Qualifications" && <DefaultQualificationsSettings />}
                    </Box>
                </Grid>
            </GridContainer>
        </FlexBox>
    )
}
