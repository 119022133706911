import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetAdditionalFormsErrorMessageSelector, useGetAdditionalFormsStatusSelector, useAdditionalFormsSelector, useAdditionalFormsUpdateStatusSelector, useUpdateAdditionalFormsErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useAdditionalFormsSettingsController() {
    const { accessAddSettingsAdditionalForms, accessEditSettingsAdditionalForms } = usePermissionsController()
    const additionalForms = useAdditionalFormsSelector()
    const getAdditionalFormsErrorMessages = useGetAdditionalFormsErrorMessageSelector()
    const updateAdditionalFormsErrorMessages = useUpdateAdditionalFormsErrorMessageSelector()

    const getAdditionalFormsStatuses = useGetAdditionalFormsStatusSelector()
    const updateAdditionalFormsStatuses = useAdditionalFormsUpdateStatusSelector()

    const {
        updateAdditionalForms,
        getAdditionalForms,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getAdditionalForms()
    }, [])

    const onSave = async (val) => {
        const newAdditionalForms = [...additionalForms]
        newAdditionalForms.push(val)
        await updateAdditionalForms({
            additionalForms: newAdditionalForms
        }).unwrap();
        getAdditionalForms()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = additionalForms.indexOf(prevValue)
        const newAdditionalForms = [...additionalForms]
        if (index >= 0) {
            newAdditionalForms[index] = newValue
        }
        await updateAdditionalForms({
            additionalForms: newAdditionalForms
        }).unwrap();
        getAdditionalForms()
        return
    }

    const onDelete = async (prevValue) => {
        const index = additionalForms.indexOf(prevValue)
        const newAdditionalForms = [...additionalForms]
        if (index >= 0) {
            newAdditionalForms.splice(index, 1);
        }
        await updateAdditionalForms({
            additionalForms: newAdditionalForms
        }).unwrap();
        getAdditionalForms()
        return
    }

    return {
        title: "Custom Forms",
        additionalForms: additionalForms,

        canEdit: accessEditSettingsAdditionalForms,
        canAdd: accessAddSettingsAdditionalForms,

        isUpdatingConfig: updateAdditionalFormsStatuses == asyncStates.PENDING,
        isDeletingConfig: updateAdditionalFormsStatuses == asyncStates.PENDING,
        isGettingConfigs: getAdditionalFormsStatuses == asyncStates.PENDING,

        updateErrorMessage: updateAdditionalFormsErrorMessages,
        deleteErrorMessage: updateAdditionalFormsErrorMessages,
        gettingErrorMessage: getAdditionalFormsErrorMessages,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,
    }
}