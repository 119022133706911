import ApiService from "../../restapi";

export const createClientAccessApiService = async ({
    clientId,
    name,
    email,
}) => {
    return await ApiService.sendRequest({
        url: `/clientAccess/invites`,
        query: {},
        method: "POST",
        headers: {},
        body: {
            clientId,
            name,
            email,
        },
    });
};

export const resendClientAccessInviteApiService = async ({
    clientAccessId,
}) => {
    return await ApiService.sendRequest({
        url: `/clientAccess/${clientAccessId}/invites/resend`,
        query: {},
        method: "POST",
        headers: {},
    });
};

export const getAllClientAccessApiService = async ({
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    return await ApiService.sendRequest({
        url: `/clientAccess`,
        query: {
            clientId,
            startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
};

export const getSingleClientAccessInviteApiService = async ({
    clientAccessId,
}) => {
    return await ApiService.sendRequest({
        url: `/clientAccess/invite/${clientAccessId}`,
        query: {},
        method: "GET",
        headers: {},
    });
};

export const deleteClientAccessApiService = async ({
    clientId,
    clientAccessId
}) => {
    return await ApiService.sendRequest({
        url: `/clientAccess/${clientAccessId}`,
        query: {
            clientId
        },
        method: "DELETE",
        headers: {},
    });
};