import ApiService from "../../restapi"

export const getPoliciesApiService = async ({
    staffId,
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    let url = `/staff/${staffId}/policy`;
    if (clientId) url = `/clients/${clientId}/policy`;

    return await ApiService.sendRequest({
        url: url,
        query: {
            startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    })
}

export const getPolicyApiService = async ({
    staffId,
    clientId,
    policyId,
}) => {
    let url = `/staff/${staffId}/policy/${policyId}`;
    if (clientId) url = `/clients/${clientId}/policy/${policyId}`;
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "GET",
        headers: {},
    })
}

export const createPolicyApiService = async ({
    staffId,
    clientId,
    name,
    notes,
    policyUrl,
    policyFile,
    adminSignature,
    assignedSignature,
    onUploadProgress,
}) => {
    let url = `/staff/${staffId}/policy`;
    if (clientId) url = `/clients/${clientId}/policy`;
    let formData = new FormData();
    formData.append("name", name);
    formData.append("notes", notes);
    if (policyUrl) formData.append("policyUrl", policyUrl);
    if (policyFile) formData.append("file", policyFile);
    if (adminSignature) formData.append("adminSignature", adminSignature);
    if (assignedSignature) formData.append("assignedSignature", assignedSignature);

    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data",
        },
        body: formData,
        onUploadProgress: onUploadProgress,
    })
}

export const updatePolicyApiService = async ({
    staffId,
    clientId,
    policyId,
    name,
    notes,
    adminSignature,
    assignedSignature,
    onUploadProgress,
}) => {
    let url = `/staff/${staffId}/policy/${policyId}`;
    if (clientId) url = `/clients/${clientId}/policy/${policyId}`;
    let formData = new FormData();
    if (name != undefined) formData.append("name", name);
    if (notes != undefined) formData.append("notes", notes);
    if (adminSignature) formData.append("adminSignature", adminSignature);
    if (assignedSignature) formData.append("assignedSignature", assignedSignature);
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "PUT",
        headers: {},
        body: formData,
        onUploadProgress: onUploadProgress,
    })
}

export const deletePolicyApiService = async ({
    staffId,
    clientId,
    policyId,
}) => {
    let url = `/staff/${staffId}/policy/${policyId}`;
    if (clientId) url = `/clients/${clientId}/policy/${policyId}`;
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "DELETE",
        headers: {},
    })
}

export const restoreArchivedPolicyApiService = async ({
    staffId,
    clientId,
    id,
}) => {
    let url = `/staff/${staffId}/policy/${id}/restore`;
    if (clientId) url = `/clients/${clientId}/policy/${id}/restore`;
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/policy/${id}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    })
}