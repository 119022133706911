import React, { useEffect, useState, useContext, useMemo } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import {
  Button,
  DragAndDrop,
  Textarea,
  TextBox,
} from "../../../../../Components/Input";
import { Text } from "../../../../../Components/Text";
import Icon from "../../../../../Components/Icons";
import LinearWithValueLabel from "../../../../../Components/Icons/linearLoader";
import useFileDispathers from "../../../../../redux/dispatchers/useFileDispatchers";
import {
  useFileUpdateErrorMessageSelector,
  useFileCreateErrorMessageSelector,
  useFileCreateStatusSelector,
  useFileDeleteErrorMessageSelector,
  useFileDeleteStatusSelector,
  useFileUpdateStatusSelector,
  useFileUploadPercentageSelector,
  useFileRestoreStatusSelector,
  useFileRestoreErrorMessageSelector,
} from "../../../../../redux/selectors/useFilesSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import { DOCUMENT_STATUS } from "../../../../../constants";

const EditFile = (props) => {
  const { clientId } = props
  const fileToEdit = useMemo(() => props.$file, []);
  const [name, setName] = useState(fileToEdit ? fileToEdit.fileName : "");
  const [notes, setNotes] = useState(fileToEdit ? fileToEdit.notes : "");
  const [shareWithCarers, setShareWithCarers] = useState(
    fileToEdit ? fileToEdit.shareWithCarers == true : ""
  );
  const [file, setFile] = useState(fileToEdit);
  const editingFile = useMemo(() => (fileToEdit ? true : false), []);

  const {
    restoreArchivedFile,
    createFile,
    updateFile,
    deleteFile,
    getFiles,
    clearAllErrorMessages,
  } = useFileDispathers();
  const fileCreateErrorMessage = useFileCreateErrorMessageSelector();
  const fileDeleteErrorMessage = useFileDeleteErrorMessageSelector();
  const fileUpdateErrorMessage = useFileUpdateErrorMessageSelector();
  const fileRestoreErrorMessage = useFileRestoreErrorMessageSelector();

  const errorMessage = useMemo(() => {
    return (
      fileCreateErrorMessage || fileDeleteErrorMessage || fileUpdateErrorMessage || fileRestoreErrorMessage
    );
  }, [fileCreateErrorMessage, fileDeleteErrorMessage, fileUpdateErrorMessage, fileRestoreErrorMessage]);

  const fileCreateStatus = useFileCreateStatusSelector();
  const fileDeleteStatus = useFileDeleteStatusSelector();
  const fileUpdateStatus = useFileUpdateStatusSelector();
  const fileRestoreStatus = useFileRestoreStatusSelector()

  const status = useMemo(() => {
    return fileCreateStatus || fileDeleteStatus || fileUpdateStatus;
  }, [fileCreateStatus, fileDeleteStatus, fileUpdateStatus]);

  const uploadProgressPercentage = useFileUploadPercentageSelector();

  const onDeleteFileClicked = async () => {
    await deleteFile({
      clientId: clientId,
      fileId: fileToEdit.id,
    }).unwrap();
    getFiles({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onUpdateFileClicked = async () => {
    await updateFile({
      clientId: clientId,
      fileId: fileToEdit.id,
      fileName: name,
      notes: notes,
      shareWithCarers: shareWithCarers,
    }).unwrap();
    getFiles({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onCreateFileClicked = async () => {
    await createFile({
      clientId: clientId,
      file: file,
      fileName: name,
      notes: notes,
      shareWithCarers: shareWithCarers,
    }).unwrap();
    getFiles({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const restoreFileClicked = async () => {
    await restoreArchivedFile({
      clientId: clientId,
      fileId: fileToEdit.id,
    }).unwrap();
    getFiles({
      clientId: clientId,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  useEffect(() => {
    clearAllErrorMessages();
  }, []);

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1}>
          File name
        </Text>
        <TextBox
          $hint="name"
          $type={"text"}
          $name={name}
          $value={name}
          $onChange={(e) => setName(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Notes
        </Text>
        <Textarea
          $type={"text"}
          $name={""}
          $value={notes}
          $onChange={(e) => setNotes(e.target.value)}
          $width="100%"
        />
        <br />

        {!editingFile && (
          <FlexBox $justifyContent="center" $alignItems="center" $width="100%">
            <DragAndDrop
              $onFileUpload={(file) => {
                console.log(file);
                setFile(file);
                // setShowSelectFile(event.target.files[0].name);
                if (!name) setName(file.name);
              }}
            />
          </FlexBox>
        )}

        {editingFile && (
          <Text $fontWeight="bold" $onClick={(e) => { }}>
            <a href={fileToEdit.fileUrl} target="_blank">
              {" "}
              {"View file"} <Icon $icon="Launch" $color="blue" $size={10} />
            </a>
          </Text>
        )}
        {errorMessage && (
          <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
        )}
        <Box $width="100%" $marginTop="2" $marginBottom="2">
          {/* {(fileUpdateStatus == asyncStates.PENDING || fileDeleteStatus == asyncStates.PENDING) && <LinearWithValueLabel $loadsContinuosly={true} />} */}
          {fileCreateStatus == asyncStates.PENDING &&
            uploadProgressPercentage != null && (
              <LinearWithValueLabel
                $loadsContinuosly={false}
                value={uploadProgressPercentage}
              />
            )}
        </Box>
      </FlexBox>
      <FlexBox
        $justifyContent={editingFile && file?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {editingFile && file?.status !== DOCUMENT_STATUS.ARCHIVED && (
          <Button
            $color={"error"}
            $sx={{ borderRadius: 2 }}
            $marginRight={3}
            $onClick={() => {
              if (
                fileUpdateStatus == asyncStates.PENDING ||
                fileDeleteStatus == asyncStates.PENDING
              )
                return;
              onDeleteFileClicked();
            }}
          >
            <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
              {fileDeleteStatus != asyncStates.PENDING ? (
                "Delete"
              ) : (
                <CircularLoader $color="white" $size={25} />
              )}
            </Text>
          </Button>
        )}

        {file?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (
              fileUpdateStatus == asyncStates.PENDING ||
              fileDeleteStatus == asyncStates.PENDING
            )
              return;
            if (editingFile) {
              onUpdateFileClicked();
            } else {
              onCreateFileClicked();
            }
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {fileUpdateStatus != asyncStates.PENDING &&
              fileCreateStatus != asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {file?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restoreFileClicked()
          }}
        >
          <Text $color={"white"}>
            {fileRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
      </FlexBox>
    </>
  );
};

export default EditFile;
