/* eslint-disable no-lone-blocks */
import React, {useMemo, useState} from "react";
import {Box, FlexBox} from "../../../Components/Boxes";
import General from "./GeneralTab";
import Repetition from "./RepetitionTab";
import Tabs from "../../../Components/Input/Tabs";
import * as asyncStates from "../../../redux/constants/asyncStates";
import {getHoursAndMinutesFromMinutes} from "../../../utiles/timeformate";
import {Text} from "../../../Components/Text";
import {Button, DropDownButton} from "../../../Components/Input";
import {
    useCreateEventErrorMessagesSelector,
    useCreateEventStatusSelector,
    useCreateEventSuccessMessagesSelector,
    useDeleteEventErrorMessagesSelector,
    useDeleteEventStatusSelector,
    useDeleteEventSuccessMessagesSelector,
    useUpdateEventErrorMessagesSelector,
    useUpdateEventStatusSelector,
    useUpdateEventSuccessMessagesSelector
} from "../../../redux/selectors/useEventsSelectors";
import CircularLoader from "../../../Components/Icons/circularLoader";
import {useEffect} from "react";
import useEventsDispathers from "../../../redux/dispatchers/useEventsDispatcher";
import {DISTRIBUTABLE_STATUS, EVENT_STATUS, EVENT_TYPE, REPETITION_END_DATE_TYPE, REPETITION_PERIODS, REPETITION_TYPE} from "../../../constants";
import BasicAlerts from "../../../Components/Boxes/ErrorAndSuccess";
import usePermissionsController from "../../../permissions";
import {ConfirmationDialog, DropDownMenu, DropDownMenuItem} from "../../../Components/Modals";
import Icon from "../../../Components/Icons";
import Customization from "./CustomizationTab";

const EditEvents = (props) => {
    const {eventData, editEvent, clientId, staffId, handleDialog, eventType: passedEventType} = props
    const {
        defaultClientEventType,
        defaultStaffEventType,
    } = usePermissionsController()

    const [eventId, setEventId] = useState(eventData?.id)
    const [name, setName] = useState(eventData?.name)
    const [eventType, setEventType] = useState(passedEventType ? passedEventType : eventData?.eventType ?? (staffId ? defaultStaffEventType : defaultClientEventType))
    const [staff, setStaff] = useState(eventData?.staff)
    const [additionalStaff, setAdditionalStaff] = useState(eventData?.additionalStaff || [])
    const [flexibleStart, setFlexibleStart] = useState(eventData?.flexibleStart || 0)
    const [breakDuration, setBreakDuration] = useState(eventData?.breakDuration || 0)
    const [instructions, setInstructions] = useState(eventData?.instructions)
    const [notice, setNotice] = useState(eventData?.notice)
    const [services, setServices] = useState(eventData?.services || [])
    const [jobRole, setJobRole] = useState(eventData?.jobRole)
    const [tabIndex, setTabIndex] = useState(0)
    const [startDate, setStartDate] = useState(eventData?.startDate ? new Date(eventData?.startDate) : new Date())
    const [endDate, setEndDate] = useState(eventData?.endDate ? new Date(eventData?.endDate) : new Date())
    const [repetitionType, setRepetitionType] = useState(eventData?.isRepeating && eventData?.repetition?.repetitionType ? eventData?.repetition?.repetitionType : REPETITION_TYPE.NONE)
    const [days, setDays] = useState(eventData?.isRepeating ? eventData?.repetition?.days || [] : [])
    const [repetitionPeriod, setRepetitionPeriod] = useState(eventData?.isRepeating && eventData?.repetition?.repetitionPeriod ? eventData?.repetition?.repetitionPeriod : {
        value: 1,
        every: REPETITION_PERIODS.WEEK
    })
    const [repetitionStartDate, setRepetitionStartDate] = useState(!editEvent ? startDate : eventData?.isRepeating && eventData?.repetition?.repetitionStartDate ? new Date(eventData?.repetition?.repetitionStartDate) : new Date(eventData?.startDate))
    const [repetitionEndDate, setRepetitionEndDate] = useState(eventData?.isRepeating && eventData?.repetition?.repetitionEndDate ? new Date(eventData?.repetition?.repetitionEndDate) : new Date())
    const [repetitionEndDateType, setRepetitionEndDateType] = useState(eventData?.isRepeating && eventData?.repetition?.repetitionEndDateType ? eventData?.repetition?.repetitionEndDateType : REPETITION_END_DATE_TYPE.NEVER)
    const [confirmationDialog, setConfirmationDialog] = useState(null)
    const [distribute, setDistribute] = useState(eventData?.distribute === true)
    const [isCancelled, setIsCancelled] = useState(eventData?.eventStatus === EVENT_STATUS.CANCELLED)
    const [isCheckedIn, setIsCheckedIn] = useState(eventData?.eventStatus === EVENT_STATUS.CHECKED_IN)
    const [isCompleted, setIsCompleted] = useState(eventData?.eventStatus === EVENT_STATUS.COMPLETED)
    const [cancellationReason, setCancellationReason] = useState(eventData?.cancellationReason)
    const [requiredNumStaff, setRequiredNumStaff] = useState(eventData?.requiredNumStaff || eventData?.additionalStaff?.length || 1)
    const [eventColor, setEventColor] = useState(eventData?.eventColor)
    const [shiftReviewRating, setShiftReviewRating] = useState(eventData?.shiftReview?.rating)
    const [distributeStatus, setDistributeStatus] = useState(eventData?.distributeStatus)
    const [distributeAcceptedAt, setDistributeAcceptedAt] = useState(eventData?.distributeAcceptedAt)
    const [requiredChecklistAcceptance, setRequiredChecklistAcceptance] = useState(eventData?.requiredChecklistAcceptance)

    const repetitionPeriodOptions = useMemo(() => {
        if (repetitionType === REPETITION_TYPE.NONE) {
            return [REPETITION_PERIODS.WEEK]
        }

        if (repetitionType === REPETITION_TYPE.SELECT_DAYS) {
            return [REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH]
        }

        if (repetitionType === REPETITION_TYPE.CUSTOM) {
            return [REPETITION_PERIODS.DAY, REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH, REPETITION_PERIODS.YEAR]
        }
    }, [repetitionType])

    const tabOptions = useMemo(() => {
        const s = [
            {name: "General"},
        ]
        if ((!editEvent || eventData.isRepeating)) {
            s.push({name: "Repetition"})
        }
        s.push({name: 'Customization'})
        return s
    }, [])

    const select = useMemo(() => {
        return tabOptions[tabIndex].name
    }, [tabIndex])

    const eventCreateStatus = useCreateEventStatusSelector();
    const eventDeleteStatus = useDeleteEventStatusSelector();
    const eventUpdateStatus = useUpdateEventStatusSelector();

    const eventCreateSuccessMessage = useCreateEventSuccessMessagesSelector()
    const eventUpdateSuccessMessage = useUpdateEventSuccessMessagesSelector()
    const eventDeleteSuccessMessage = useDeleteEventSuccessMessagesSelector()

    const successMessages = useMemo(() => {
        return eventCreateSuccessMessage || eventUpdateSuccessMessage || eventDeleteSuccessMessage
    }, [eventCreateSuccessMessage, eventUpdateSuccessMessage, eventDeleteSuccessMessage])

    const eventCreateErrorMessage = useCreateEventErrorMessagesSelector()
    const eventUpdateErrorMessage = useUpdateEventErrorMessagesSelector()
    const eventDeleteErrorMessage = useDeleteEventErrorMessagesSelector()

    const errorMessages = useMemo(() => {
        return eventCreateErrorMessage || eventUpdateErrorMessage || eventDeleteErrorMessage
    }, [eventCreateErrorMessage, eventUpdateErrorMessage, eventDeleteErrorMessage])

    const {clearAllErrorMessages, getEvents, createEvent, updateEvent, deleteEvent, clearAllSuccessMessages,} = useEventsDispathers()

    const status = useMemo(() => {
        return eventCreateStatus || eventDeleteStatus || eventUpdateStatus;
    }, [eventCreateStatus, eventDeleteStatus, eventUpdateStatus]);

    const onSave = async ({onlyThisEvent}) => {
        if (status !== asyncStates.PENDING) {
            if (editEvent) {
                await updateEvent({
                    id: eventId,
                    clientId: eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT ? clientId : null,
                    staffId: staff?.id ?? null,

                    eventType: eventType,
                    name: eventType === EVENT_TYPE.CUSTOM ? name : null,
                    isRepeating: repetitionType !== REPETITION_TYPE.NONE,
                    startDate: startDate,
                    endDate: endDate,
                    distribute: distribute,
                    services: services,
                    flexibleStart: flexibleStart,
                    breakDuration: breakDuration,
                    jobRole: jobRole,
                    instructions: instructions,
                    notice: notice,
                    additionalStaff: additionalStaff ? additionalStaff?.map(s => s.id) : null,
                    requiredNumStaff: requiredNumStaff,

                    repetition: repetitionType !== REPETITION_TYPE.NONE ? {
                        repetitionType: repetitionType,
                        days: repetitionType === REPETITION_TYPE.SELECT_DAYS ? days : null,
                        repetitionPeriod: repetitionPeriod ? {
                            value: repetitionPeriod.value,
                            every: repetitionPeriod.every,
                        } : null,
                        repetitionEndDate: repetitionEndDate,
                        repetitionEndDateType: repetitionEndDateType,
                    } : null,
                    onlyThisEvent: onlyThisEvent,
                    eventColor: eventColor,
                    requiredChecklistAcceptance: requiredChecklistAcceptance,
                }).unwrap()
            } else {
                await createEvent({
                    clientId: clientId,
                    staffId: staffId || staff?.id,

                    eventType: eventType,
                    name: eventType === EVENT_TYPE.CUSTOM ? name : null,
                    isRepeating: repetitionType !== REPETITION_TYPE.NONE,
                    startDate: startDate,
                    endDate: endDate,
                    distribute: distribute,
                    services: services,
                    flexibleStart: flexibleStart,
                    breakDuration: breakDuration,
                    jobRole: jobRole,
                    instructions: instructions,
                    notice: notice,
                    additionalStaff: additionalStaff ? additionalStaff?.map(s => s.id) : null,
                    requiredNumStaff: requiredNumStaff,

                    repetition: repetitionType !== REPETITION_TYPE.NONE ? {
                        repetitionType: repetitionType,
                        days: repetitionType === REPETITION_TYPE.SELECT_DAYS ? days : null,
                        repetitionPeriod: repetitionPeriod ? {
                            value: repetitionPeriod.value,
                            every: repetitionPeriod.every,
                        } : null,
                        repetitionStartDate: repetitionStartDate,
                        repetitionEndDate: repetitionEndDate,
                        repetitionEndDateType: repetitionEndDateType,
                    } : null,
                    eventColor: eventColor,
                    requiredChecklistAcceptance: requiredChecklistAcceptance,
                }).unwrap()
            }
            handleDialog()
            getEvents({})
        }
    }

    const onApproveDistribute = async () => {
        await updateEvent({
            id: eventId,
            clientId: eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT ? clientId : null,
            staffId: staff?.id ?? null,
            distributeStatus: DISTRIBUTABLE_STATUS.ACCEPTED,
        }).unwrap()
        handleDialog()
        getEvents({})
    }

    const onRejectDistribute = async () => {
        await updateEvent({
            id: eventId,
            clientId: eventType === EVENT_TYPE.CLIENT_VISIT || eventType === EVENT_TYPE.SHIFT ? clientId : null,
            staffId: staff?.id ?? null,
            distributeStatus: DISTRIBUTABLE_STATUS.REJECTED,
        }).unwrap()
        handleDialog()
        getEvents({})
    }

    const onDelete = async ({cancel, reason, onlyThisEvent}) => {
        if (status !== asyncStates.PENDING) {
            await deleteEvent({
                id: eventId,
                clientId: clientId,
                staffId: staff?.id,
                cancel: cancel,
                cancellationReason: reason,
                onlyThisEvent: onlyThisEvent,
            }).unwrap()
            handleDialog()
            getEvents({})
        }
    }

    useEffect(() => {
        clearAllErrorMessages()
        clearAllSuccessMessages()
    }, [])

    return (
        <Box
            $width="100%"
            $height="100%"
        >
            <Tabs
                $onChanged={(val) => {
                    setTabIndex(val)
                }}
                $value={tabIndex}
                $tabs={tabOptions}
            />
            <Box $marginTop={2}/>

            {select === "General" && <General
                loading={eventUpdateStatus === asyncStates.PENDING || eventCreateStatus === asyncStates.PENDING}
                editEvent={editEvent}
                eventType={eventType}
                name={name}
                allowEditStaff={!staffId}
                staff={staff}
                flexibleStart={flexibleStart}
                breakDuration={breakDuration}
                instructions={instructions}
                notice={notice}
                services={services}
                additionalStaff={additionalStaff}
                requiredNumStaff={requiredNumStaff}
                startDate={startDate}
                startTime={startDate ? (startDate.getHours() * 60) + startDate.getMinutes() : 0}
                endDate={endDate}
                endTime={endDate ? (endDate.getHours() * 60) + endDate.getMinutes() : 0}
                distribute={distribute}
                jobRole={jobRole}
                shiftReviewRating={shiftReviewRating}
                distributeStatus={distributeStatus}
                distributeAcceptedAt={distributeAcceptedAt}
                requiredChecklistAcceptance={requiredChecklistAcceptance}
                onDistributeChanged={(val) => {
                    setDistribute(val)
                }}
                onStaffChanged={(val) => {
                    setStaff(val)
                }}
                onFlexibleStartChanged={(val) => {
                    setFlexibleStart(val)
                }}
                onBreakDurationChanged={(val) => {
                    setBreakDuration(val)
                }}
                onInstructionsChanged={(val) => {
                    setInstructions(val)
                }}
                onNoticeChanged={(val) => {
                    setNotice(val)
                }}
                onServiceChanged={(val) => {
                    setServices(val)
                }}
                onAdditionalStaffChanged={(val) => {
                    setAdditionalStaff(val)
                }}
                onRequiredNumStaffChanged={(val) => {
                    setRequiredNumStaff(val)
                }}
                onJobRoleChanged={(val) => {
                    setJobRole(val)
                }}
                onStartDateChanged={(val) => {
                    startDate.setMonth(val.getMonth())
                    startDate.setFullYear(val.getFullYear())
                    startDate.setDate(val.getDate())
                    setStartDate(new Date(`${startDate}`))

                    if (!editEvent) {
                        setRepetitionStartDate(startDate)
                    }
                }}
                onStartTimeChanged={(val) => {
                    const data = getHoursAndMinutesFromMinutes(val)
                    startDate.setHours(data.hours)
                    startDate.setMinutes(data.minutes)
                    setStartDate(new Date(`${startDate}`))

                    if (!editEvent) {
                        setRepetitionStartDate(startDate)
                    }
                }}
                onEndDateChanged={(val) => {
                    endDate.setMonth(val.getMonth())
                    endDate.setFullYear(val.getFullYear())
                    endDate.setDate(val.getDate())
                    setEndDate(new Date(`${endDate}`))
                }}
                onEndTimeChanged={(val) => {
                    const data = getHoursAndMinutesFromMinutes(val)
                    endDate.setHours(data.hours)
                    endDate.setMinutes(data.minutes)
                    setEndDate(new Date(`${endDate}`))
                }}
                onNameChanged={(val) => setName(val)}
                onDistributeAssignmentAccepted={async () => {
                    await onApproveDistribute();
                }}
                onDistributeAssignmentRejected={async () => {
                    await onRejectDistribute()
                }}
                onRequiredChecklistAcceptanceChanged={(val) => {
                    setRequiredChecklistAcceptance(val)
                }}
            />}
            {select === "Repetition" && <Repetition
                repetitionType={repetitionType}
                days={days}
                repetitionPeriod={repetitionPeriod}
                repetitionStartDate={repetitionStartDate}
                repetitionEndDate={repetitionEndDate}
                repetitionEndDateType={repetitionEndDateType}
                repetitionPeriodOptions={repetitionPeriodOptions}

                onRepetitionTypeChanged={(val) => {
                    if (repetitionType === REPETITION_TYPE.NONE) {
                        setRepetitionPeriod({value: 1, every: REPETITION_PERIODS.WEEK})
                    }

                    if (repetitionType === REPETITION_TYPE.SELECT_DAYS) {
                        setRepetitionPeriod({value: 1, every: REPETITION_PERIODS.WEEK})
                    }

                    if (repetitionType === REPETITION_TYPE.CUSTOM) {
                        setRepetitionPeriod({value: 1, every: REPETITION_PERIODS.WEEK})
                    }

                    setRepetitionType(val)
                }}
                onRepetitionEndDateChanged={(val) => {
                    setRepetitionEndDate(new Date(`${val}`))
                }}
                onRepetitionEndDateTypeChanged={(val) => {
                    setRepetitionEndDateType(val)
                }}
                onDaysChanged={(val) => {
                    setDays([...val])
                }}
                onRepetitionPeriodChange={(val) => {
                    console.log(val)
                    setRepetitionPeriod(val)
                }}
            />}

            {select === "Customization" && <Customization
                eventColor={eventColor}
                onEventColorChanged={(val) => {
                    setEventColor(val)
                }}
            />}

            {errorMessages && (
                <FlexBox $marginTop={2} $marginBottom={2} $marginRight={3} $marginLeft={3} $justifyContent="center">
                    <BasicAlerts $type="error">{errorMessages}</BasicAlerts>
                </FlexBox>
            )}

            {isCancelled && <FlexBox $marginTop={2} $marginBottom={2} $marginRight={3} $marginLeft={3} $justifyContent="center">
                <BasicAlerts $type="info">CANCELLED: {cancellationReason ?? ""}</BasicAlerts>
            </FlexBox>}

            <FlexBox
                $justifyContent={/*editEvent ? "space-between" : */ "flex-end"}
                $padding={4}
                $backgroundColor="list"
            >

                {!editEvent && <Button $sx={{borderRadius: 2}} $marginRight={3} $onClick={() => {
                    if (status !== asyncStates.PENDING) {
                        onSave({onlyThisEvent: true})
                    }
                }}>
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {eventUpdateStatus !== asyncStates.PENDING && eventCreateStatus !== asyncStates.PENDING ? (
                            editEvent ? "Update" : "Save"
                        ) : (
                            <CircularLoader $color="white" $size={25}/>
                        )}
                    </Text>
                </Button>}

                {editEvent && !isCancelled && !isCheckedIn && !isCompleted && <DropDownButton
                    $sx={{borderRadius: 2}}
                    $marginRight={3}
                    $loading={eventUpdateStatus === asyncStates.PENDING || eventCreateStatus === asyncStates.PENDING}
                >
                    <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to update only this event?",
                                onOk: ({reason}) => {
                                    onSave({onlyThisEvent: true})
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Done" $size="large" $color="primary"/>
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Update single event
                        </Text>
                    </DropDownMenuItem>

                    {eventData.isRepeating && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to update all events in this series?",
                                onOk: ({reason}) => {
                                    onSave({onlyThisEvent: false})
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="DoneAll" $size="large" $color="primary"/>
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Update series
                        </Text>
                    </DropDownMenuItem>}

                    {eventType !== EVENT_TYPE.AVAILABILITY && eventType !== EVENT_TYPE.ABSENCE && <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to cancel this event?",
                                onOk: ({reason}) => {
                                    onDelete({
                                        cancel: true,
                                        reason: reason,
                                    })
                                },
                                enterReason: true
                            })
                        }}
                    >
                        <Icon $icon="Cancel" $size="large" $color="red"/>
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Cancel visit/shift
                        </Text>
                    </DropDownMenuItem>}

                    <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to delete only this event?",
                                onOk: ({reason}) => {
                                    onDelete({
                                        cancel: false,
                                        onlyThisEvent: true,
                                        reason: reason,
                                    })
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Delete" $size="large" $color="red"/>
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Delete current visits/shifts
                        </Text>
                    </DropDownMenuItem>

                    <DropDownMenuItem
                        $onClick={() => {
                            setConfirmationDialog({
                                title: "Confirmation",
                                description: "Are you sure you want to delete all future events in this series?",
                                onOk: ({reason}) => {
                                    onDelete({
                                        cancel: false,
                                        reason: reason,
                                    })
                                },
                                enterReason: false
                            })
                        }}
                    >
                        <Icon $icon="Delete" $size="large" $color="red"/>
                        <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                            Delete all future visits/shifts
                        </Text>
                    </DropDownMenuItem>
                </DropDownButton>}

            </FlexBox>

            {confirmationDialog && <ConfirmationDialog
                $title={confirmationDialog.title}
                $description={confirmationDialog.description}
                $onClose={() => {
                    setConfirmationDialog(null)
                }}
                $onConfirm={({reason}) => {
                    setConfirmationDialog(null)
                    confirmationDialog.onOk({reason})
                }}
                $enterReason={confirmationDialog.enterReason}
            />}
        </Box>
    );
}

export default EditEvents;
