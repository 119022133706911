import ApiService from "../../restapi";

export const getAllBlockListApiService = async ({
    staffId,
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/blockList`;
    } else if (clientId) {
        url = `/clients/${clientId}/blockList`;
    } else {
        url = `/blockList`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {
            startAfter: startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
}

export const createBlockListApiService = async ({
    staffId,
    clientId,
    notes,
    reason,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/blockList`;
    } else if (clientId) {
        url = `/clients/${clientId}/blockList`;
    } else {
        url = `/blockList`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "POST",
        headers: {},
        body: {
            staffId,
            clientId,
            notes,
            reason,
        },
    });
}

export const deleteBlockListApiService = async ({
    staffId,
    clientId,
    blockListId,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/blockList/${blockListId}`;
    } else if (clientId) {
        url = `/clients/${clientId}/blockList/${blockListId}`;
    } else {
        url = `/blockList/${blockListId}`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "DELETE",
        headers: {},
    });
}