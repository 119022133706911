import React, { useState } from 'react'
import { Box, FlexBox } from '../../../../../../Components/Boxes'
import CalendarControlls from '../../../../../../Components/Calendar/ScheduleCalendar/CalendarControlls'
import { MEDICINE_STATUS, REPETITION_PERIODS } from '../../../../../../constants'
import { formateDateToReadableFormatWithTime, formateDateToReadableFormatWithoutTime, getAllDatesInAMonth } from "../../../../../../utiles/dateformate"
import { GridContainer, Grid } from "../../../../../../Components/Grid"
import { useMemo } from 'react'
import { Text } from '../../../../../../Components/Text'
import { color } from '../../../../../../Components/Theme'

export default function EMARComponent(props) {
    const {
        isHeader,
        selectedDate,
        dateRanges,
        eMAR,
    } = props

    /*
        [{
            timeOfDay: 
            data: [{
                date:
                eMARs: status
            }]
        }]
    */
    const processedEMARs = useMemo(() => {
        const timeOfDayArray = {}

        const dosages = Object.keys(eMAR?.medicineDetails?.dosage ?? {})
        dosages.forEach(dosage => {
            if (eMAR.medicineDetails.dosage[dosage] > 0) {
                timeOfDayArray[dosage.toUpperCase()] = []
            }
        })

        for (let i = 0; i < eMAR?.medicinesGiven.length; i++) {
            if (timeOfDayArray[eMAR?.medicinesGiven[i].timeOfDay]) {
                timeOfDayArray[eMAR?.medicinesGiven[i].timeOfDay] = [...timeOfDayArray[eMAR?.medicinesGiven[i].timeOfDay], eMAR?.medicinesGiven[i]]
            } else {
                timeOfDayArray[eMAR?.medicinesGiven[i].timeOfDay] = [eMAR?.medicinesGiven[i]]
            }
        }

        const times = Object.keys(timeOfDayArray)

        const finalTimes = times.map(e => {
            const dataArr = timeOfDayArray[e]

            return {
                timeOfDay: e,
                ranges: dateRanges?.map(date => {
                    const found = dataArr.filter(e => {
                        return new Date(e.dateGiven).getDate() == date.getDate() && new Date(e.dateGiven).getMonth() == date.getMonth() && new Date(e.dateGiven).getFullYear() == date.getFullYear()
                    })
                    return {
                        date: date,
                        eMARs: found
                    }
                })
            }
        })

        return finalTimes
    }, [eMAR])

    console.log(eMAR)

    if (isHeader) {
        return (
            <FlexBox $width="100%">
                <Box $width="20%">
                    <FlexBox $width="100%">
                        <FlexBox $width="50%">Medication</FlexBox>
                        <FlexBox $width="50%">Time</FlexBox>
                    </FlexBox>
                </Box>
                <Box $width="80%">
                    <FlexBox>
                        {dateRanges?.map(date => {
                            return (
                                <Box $width="50px" $height="25px">
                                    <Text $textAlign={"center"}>{date.getDate()}</Text>
                                </Box>
                            )
                        })}
                    </FlexBox>
                </Box>
            </FlexBox>
        )
    }

    return (
        <FlexBox $borderBottom={`1px solid ${color("primary")}30`} $alignItems={"start"} $width="100%" $marginTop={2} $paddingBottom={2}>
            <Box $width="20%">
                <FlexBox $width="100%" $alignItems={"start"}>
                    <Box $width="50%">
                        <Text $fontWeight={"bold"}>{eMAR?.medicineDetails?.medicine?.name}</Text>
                        {eMAR?.medicineDetails?.dosageInstructions && <Text $marginTop="2" $fontStyle="italic">{eMAR?.medicineDetails?.dosageInstructions}</Text>}
                    </Box>
                    <Box $width="50%">
                        {processedEMARs?.map(e => {
                            return (
                                <Box $width="50px" $height="25px">
                                    <Text $fontWeight={"bold"}>{e.timeOfDay}</Text>
                                </Box>
                            )
                        })}
                    </Box>
                </FlexBox>
            </Box>
            <Box $width="80%">
                {processedEMARs?.map(e => {
                    return (
                        <FlexBox>
                            {e.ranges?.map(date => {
                                // date.eMARs[].medicineStatus: "TAKEN"
                                const isAnyMissed = date.eMARs?.filter(e => {
                                    return e.medicineStatus == MEDICINE_STATUS.MISSED || e.medicineStatus == MEDICINE_STATUS.REFUSED
                                })
                                return (
                                    <Box $border={`0.1px solid ${color("primary")}30`} $width="50px" $height="25px">
                                        {date.eMARs.length > 0 && <Box $margin="1" $borderRadius={100} $backgroundColor={isAnyMissed.length > 0 ? "red" : "green"} $width="70%" $height="70%"></Box>}
                                    </Box>
                                )
                            })}
                        </FlexBox>
                    )
                })}
            </Box>
        </FlexBox>
    )
}
