import React from 'react'
import * as asyncStates from "../../../redux/constants/asyncStates";
import useEmailListDispathers from '../../../redux/dispatchers/useEmailListDispatchers';
import { useAllEmailListGetStatusSelector, useAllEmailListSelector, useEmailListGetErrorMessageSelector, useMoreEmailListItemsLeftInPaginationSelector } from '../../../redux/selectors/useEmailListSelectors';
import { useEffect } from 'react';
import { Box, ErrorAndSuccess, FlexBox, ListBox } from '../../../Components/Boxes';
import { Text } from '../../../Components/Text';
import EmptyState from '../../../Components/NoRecordFound';
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader';
import { Button } from "../../../Components/Input/index";
import { useState } from 'react';
import Icon from '../../../Components/Icons';
import { FormDialog } from '../../../Components/Modals';
import { useResponsive } from '../../../Components/Hooks';
import EditEmailList from './EditEmailList';
import { formateDateToReadableFormatWithTime } from '../../../utiles/dateformate';
import { EMAIL_LIST_PURPOSE } from '../../../constants';

export default function EmailListView(props) {
    const { clientId, staffId } = props
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        getAllEmailList,
        clearAllErrorMessages,
        clearAllLocalEmailList,
    } = useEmailListDispathers()

    const emailLists = useAllEmailListSelector()

    const moreEmailListItemsLeftInPagination = useMoreEmailListItemsLeftInPaginationSelector()
    const getAllEmailListStatus = useAllEmailListGetStatusSelector()
    const getAllEmailListError = useEmailListGetErrorMessageSelector()

    const [open, setOpen] = useState(false);
    const [emailListToEdit, setEmailListToEdit] = useState(null);

    function HandleDialog() {
        setOpen(!open);
        setEmailListToEdit(null);
    }

    const loadMore = () => {
        getAllEmailList({
            clientId,
            staffId,
            startAfter: emailLists[emailLists.length - 1].id,
        });
    }

    useEffect(() => {
        clearAllErrorMessages()
        clearAllLocalEmailList()
        getAllEmailList({
            clientId,
            staffId,
        })
    }, [clientId, staffId])

    const getPurposeDisplayName = (purpose) => {
        const purposeId = purpose?.id ?? ""
        switch (purposeId) {
            case EMAIL_LIST_PURPOSE.TIMESHEETS:
                return "Timesheets"
            case EMAIL_LIST_PURPOSE.STAFF_PROFILE:
                return "Staff Profile"
            case EMAIL_LIST_PURPOSE.SHIFT_CHANGES:
                return "Shift Changes"
            case EMAIL_LIST_PURPOSE.OTHER:
                return purpose?.name || "Other"
            default:
                return ""
        }
    }

    return (
        <Box
            $width="100%"
            $backgroundColor="#fff"
        >
            <FlexBox $justifyContent={"flex-end"} $width="100%" $marginTop={2}>
                <Button
                    $onClick={() => {
                        HandleDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>Add New Client Email</Text>
                </Button>
            </FlexBox>

            <FlexBox $width="100%" $marginTop={3} $padding="3">
                <Box $width="30%">
                    <Text $fontWeight="bold">Email</Text>
                </Box>
                <Box $width="20%">
                    <Text>
                        Email Purpose
                    </Text>
                </Box>
                <Box $width="30%">
                    <Text>
                        Notes
                    </Text>
                </Box>
                <Box $width="20%">
                    <Text>Creation Date</Text>
                </Box>
            </FlexBox>

            {getAllEmailListStatus === asyncStates.SUCCESS && emailLists < 1 && <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
                <EmptyState />
            </FlexBox>}

            {emailLists?.map((value, index) => {
                return (
                    <ListBox
                        $width="100%"
                        key={index}
                        $index={index}
                        $onClick={() => {
                            HandleDialog();
                            setEmailListToEdit(value);
                        }}
                    >
                        <FlexBox $width="100%" >
                            <Box $width="30%">
                                <Text $fontWeight="bold">{value?.email ?? ""}</Text>
                            </Box>
                            <Box $width="20%">
                                <Text>
                                    {getPurposeDisplayName(value.purpose)}
                                </Text>
                            </Box>
                            <Box $width="30%">
                                <Text>
                                    {value?.notes || "-"}
                                </Text>
                            </Box>
                            <Box $width="20%">
                                <Text>{value.creationDate ? formateDateToReadableFormatWithTime(new Date(value.creationDate)) : "-"}</Text>
                            </Box>
                        </FlexBox>
                    </ListBox>
                );
            })}

            {getAllEmailListStatus === asyncStates.PENDING &&
                [1, 2, 3, 4].map((value) => {
                    return (
                        <Box $width="100%" $marginTop="2" key={value}>
                            <MuiSkeletonLoader
                                $variant="rectangular"
                                $width={"100%"}
                                $height={40}
                            />
                        </Box>
                    );
                })}

            {getAllEmailListError && (
                <FlexBox $marginTop={2} $width="100%">
                    <ErrorAndSuccess $type="error">{getAllEmailListError}</ErrorAndSuccess>
                </FlexBox>
            )}

            {moreEmailListItemsLeftInPagination && <FlexBox $marginTop="6" $justifyContent="center">
                <Button $onClick={() => {
                    loadMore()
                }} >Load More</Button>
            </FlexBox>}

            <FormDialog
                $open={open}
                $handleDialog={HandleDialog}
                $title={"Block List"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <EditEmailList
                    $handleDialog={HandleDialog}
                    $clientId={clientId}
                    $staffId={staffId}
                    $emailList={emailListToEdit}
                />
            </FormDialog>
        </Box>
    )
}
