import ApiService from "../../restapi";

export const getAllTimesheetApiService = async ({
    clientId,
    staffId,
    startAfter,
    limit,
    isArchived,
    timesheetStatus,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets`
    } else {
        url = `/timesheets`
    }

    return await ApiService.sendRequest({
        url: url,
        query: {
            startAfter,
            limit: limit,
            isArchived: isArchived,
            timesheetStatus: timesheetStatus,
        },
        method: "GET",
        headers: {},
    });
};

export const getSingleTimesheetApiService = async ({
    clientId,
    staffId,
    timesheetId,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${timesheetId}`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${timesheetId}`
    } else {
        url = `/timesheets/${timesheetId}`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "GET",
        headers: {},
    });
};

export const getTimeSheetPdfApiService = async ({
    clientId,
    staffId,
    timesheetId,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${timesheetId}/pdf`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${timesheetId}/pdf`
    } else {
        url = `/timesheets/${timesheetId}/pdf`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "GET",
        headers: {},
    });
};

export const updateTimesheetApiService = async ({
    timesheetId,
    clientId,
    staffId,
    startDate,
    endDate,
    breakDuration,
    timesheetStatus,
    signatureSubmitted,
    missingSignatureReason,
    signedByName,
    signedByPosition,
    jobRole,
    signatureFile,
    notes,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${timesheetId}`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${timesheetId}`
    } else {
        url = `/timesheets/${timesheetId}}`
    }

    const json = {
        startDate,
        endDate,
        breakDuration,
        timesheetStatus,
        signatureSubmitted,
        missingSignatureReason,
        signedByName,
        signedByPosition,
        jobRole,
        notes,
        file: signatureFile,
    }
    let finalData = json

    if (signatureFile) {
        let formData = new FormData();
        for (var key in json) {
            if (key == "file") {
                formData.append("file", signatureFile);
            } else if (json[key] != null) {
                formData.append(key, typeof json[key] == "object" ? JSON.stringify(json[key]) : json[key]);
            }
        }
        finalData = formData
    }

    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "PUT",
        headers: {},
        body: finalData,
    });
};

export const deleteTimesheetApiService = async ({
    clientId,
    staffId,
    timesheetId
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${timesheetId}`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${timesheetId}`
    } else {
        url = `/timesheets/${timesheetId}`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "DELETE",
        headers: {},
    });
};

export const restoreArchivedTimesheetApiService = async ({
    clientId,
    staffId,
    id,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${id}`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${id}/restore`
    } else {
        url = `/timesheets/${id}/restore`
    }
    return await ApiService.sendRequest({
        url: `/clients/${clientId}}/timesheets/${id}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    })
}

export const updateTimesheetAmmendmentStatusApiService = async ({
    clientId,
    staffId,
    timesheetId,
    status,
    startDate,
    endDate,
    breakDuration,
    notes,
}) => {
    let url
    if (clientId) {
        url = `/clients/${clientId}/timesheets/${timesheetId}/timeAmmendment`
    } else if (staffId) {
        url = `/staff/${staffId}/timesheets/${timesheetId}/timeAmmendment`
    } else {
        url = `/timesheets/${timesheetId}/timeAmmendment`
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            status,
            startDate,
            endDate,
            breakDuration,
            notes,
        },
    });
}