import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import { Grid, GridContainer } from "../../../../../Components/Grid";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import {
  Button,
  SelectBox,
  Textarea,
  TextBox,
} from "../../../../../Components/Input";
import { SpanText, Text } from "../../../../../Components/Text";
import {
  useClientUpdateStatusSelector,
  useDeleteClientSuccessSelector,
  useClientDeleteStatusSelector,
  useClientDeleteErrorMessageSelector,
  useUpdateClientErrorMessageSelector,
  useUpdateClientSuccessSelector,
} from "../../../../../redux/selectors/useClientSelectors";
import useClientDispatchers from "../../../../../redux/dispatchers/useClientDispatchers";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import { useTeamsSelector } from "../../../../../redux/selectors/useTeamSelectors";
import useTeamDispathers from "../../../../../redux/dispatchers/useTeamDispatchers";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";

//CLIENT
export const ClientEditDialog = ({ clientData, $type, $handleDialog }) => {
  const [submitted, setSubmitted] = useState(false);
  const [clientUpdateChangeTracker, setClientUpdateChange] = useState(null);
  const dateFormat = clientData?.dateOfBirth ? moment(clientData?.dateOfBirth).format("YYYY-MM-DD") : null;

  const [selectedTeams, setSelectedTeams] = useState(clientData?.teams?.map(item => ({
    value: item.id,
    label: item.name
  })) || [])
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate();

  //----------------Client Dispatchers-------------//
  const { updateClient, getSingleClient, deleteClient, clearAllSuccessMessages, clearAllStatusMessages, clearAllErrorMessages } =
    useClientDispatchers();

  //----------------Client Selectors--------------//
  //Update
  const updateClientSuccessMessage = useUpdateClientSuccessSelector();
  const updateClientErrorMessage = useUpdateClientErrorMessageSelector();
  const clientUpdateStatus = useClientUpdateStatusSelector();
  //Delete
  const deleteClientSuccessMessage = useDeleteClientSuccessSelector();
  const deleteClientStatus = useClientDeleteStatusSelector();
  const deleteClientErrorMessage = useClientDeleteErrorMessageSelector();

  // ------------------------Team Dispatcher ------------------- //
  const { getTeams } = useTeamDispathers()
  // teams
  const allTeams = useTeamsSelector();


  //Client Response Messages
  const clientErrorMessage = useMemo(() => {
    return updateClientErrorMessage || deleteClientErrorMessage;
  }, [updateClientErrorMessage, deleteClientErrorMessage]);

  const clientSuccessMessage = useMemo(() => {
    return updateClientSuccessMessage || deleteClientSuccessMessage;
  }, [updateClientSuccessMessage, deleteClientSuccessMessage]);

  const clientStatus = useMemo(() => {
    return clientUpdateStatus;
  }, [clientUpdateStatus]);

  const {
    clientDoctorDetailsSpecified,
    clientNextOfKinDetailsSpecified,
    clientTherapistDetailsSpecified,
    clientSocialworkerDetailsSpecified,
    clientEmailSpecified,
    clientRefIdSpecified,
    clientClientNotesSpecified,
    clientDOBSpecified,
    clientGenderSpecified,
    clientPhoneNumberSpecified,
  } = usePermissionsController()

  //Form Data
  const [data, setData] = useState({
    id: clientData.id,
    about: clientData?.about ?? "",
    name: clientData?.name ?? "",
    email: clientData?.email ?? "",
    contact: clientData?.contact?.contact ?? "",
    phonecode: clientData?.contact?.phonecode || 0,
    dateOfBirth: dateFormat ?? null,
    gender: clientData?.gender,
    addressLine1: clientData?.address?.line1 ?? "",
    addressLine2: clientData?.address?.line2 ?? "",
    city: clientData?.address?.city ?? "",
    postcode: clientData?.address?.postcode ?? null,
    country: clientData?.address?.country ?? "",
    region: clientData?.address?.region ?? "",
    notes: clientData?.notes ?? "",
    doctorDetails: clientData?.doctorDetails ?? "",
    nextOfKin: clientData?.nextOfKin ?? "",
    therapistDetails: clientData?.therapistDetails ?? "",
    socialWorkerContacts: clientData?.socialWorkerContacts ?? "",
    refId: clientData?.refId,
  });

  const tabOptions = useMemo(() => {
    const opts = []

    opts.push({ name: "Details" })
    opts.push({ name: "Address" })
    if (clientDoctorDetailsSpecified && clientNextOfKinDetailsSpecified && clientTherapistDetailsSpecified && clientSocialworkerDetailsSpecified) {
      opts.push({ name: "Contacts" })
    }
    return opts
  }, [clientDoctorDetailsSpecified, clientNextOfKinDetailsSpecified, clientTherapistDetailsSpecified, clientSocialworkerDetailsSpecified])

  const selectedTab = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  //handling form data
  function handleChange({ target }) {
    const { name, value } = target
    console.log(name, value)
    setData({ ...data, [name]: value });
  }

  const mappedAllTeamItems = allTeams?.map(item => ({
    value: item.id,
    label: item.name
  }));

  const handleTeamChange = (selected) => {
    setSelectedTeams([...selected]);
  };

  function handleGenderChange({ name, value }) {
    setData({ ...data, [name]: value });
  }

  //Clear Messages
  useEffect(() => {
    clearAllStatusMessages();
    clearAllSuccessMessages();
    setClientUpdateChange(null);
    clearAllErrorMessages();
    if (!allTeams || allTeams.length <= 0) {
      getTeams({})
    }
  }, []);

  //--Update Functions--//
  const onUpdateClientClicked = async () => {
    await updateClient({
      ...data,
      teams: selectedTeams?.map(item => item.value),
    }).unwrap();
    await getSingleClient({
      clientId: clientData.id,
    }).unwrap();
    setClientUpdateChange(null);
    $handleDialog(false);
    setSubmitted(true);
  };

  //---Delete function---//
  const onDeleteClientClick = async () => {
    await deleteClient({
      id: data.id,
    }).unwrap();
    $handleDialog(false);
    navigate("/team");
  };

  return (
    <Box>
      <Tabs
        $onChanged={(val) => {
          setTabIndex(val)
        }}
        $value={tabIndex}
        $tabs={tabOptions}
      />

      <Box $marginTop={2} />
      <Box
        $padding={5}
        $marginRight={3}
      >

        {/* Client details */}
        {selectedTab == "Details" && <Box>
          <Text $fontSize="body" $marginBottom={1}>
            Name<SpanText $color={"red"}>*</SpanText>
          </Text>
          <TextBox
            $type={"text"}
            $name={"name"}
            $value={data?.name}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          {clientEmailSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Email
            </Text>
            <TextBox
              $hint={clientData?.email ? clientData?.email : "xyz@gmail.com"}
              $type={"email"}
              $name={"email"}
              $value={data.email}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {clientPhoneNumberSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Contact
            </Text>
            <TextBox
              $type={"text"}
              $name={"contact"}
              $onChange={handleChange}
              $value={data.contact}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {clientRefIdSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Client Ref ID
            </Text>
            <TextBox
              $type={"text"}
              $name={"refId"}
              $value={data.refId}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {(clientDOBSpecified || clientDOBSpecified) && <>
            <GridContainer $spacing={1}>
              {clientGenderSpecified && <Grid $xs={clientDOBSpecified ? 6 : 12}>
                <Text $fontSize="body" $marginBottom={1}>
                  Gender
                </Text>
                <SelectBox
                  $name={"gender"}
                  $value={{ value: data.gender, label: data.gender }}
                  $onChange={handleGenderChange}
                  $options={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Grid>}
              {clientDOBSpecified && <Grid $xs={clientGenderSpecified ? 6 : 12}>
                <Text $fontSize="body" $marginBottom={1}>
                  DOB
                </Text>
                <TextBox
                  $type={"date"}
                  $name={"dateOfBirth"}
                  $value={data.dateOfBirth}
                  $onChange={handleChange}
                  $width="100%"
                  $borderRadius="8px"
                  $border="1px solid black"
                />
              </Grid>}
            </GridContainer>
            <br />
          </>}

          <Text $fontSize="body" $marginBottom={1}>
            Teams
          </Text>
          <Box $width="100%">
            <SelectBox
              $isMulti={true}
              $options={mappedAllTeamItems}
              $value={selectedTeams}
              $onChange={handleTeamChange}
            ></SelectBox>
          </Box>
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            About
          </Text>
          <Textarea
            $name={"about"}
            $rows={5}
            $value={data.about}
            $onChange={handleChange}
            $width="100%"
          />
          <br />
          <br />
          {clientClientNotesSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Note
            </Text>
            <Textarea
              $name={"notes"}
              $rows={8}
              $value={data.notes}
              $onChange={handleChange}
              $width="100%"
            />
            <br />
          </>}

        </Box>}

        {/* Address details */}
        {
          selectedTab == "Address" && <Box>
            <Text $fontSize="body" $marginBottom={1}>
              Address Line 1
            </Text>
            <TextBox
              $type={"address"}
              $name={"addressLine1"}
              $value={data.addressLine1}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              Address Line 2
            </Text>
            <TextBox
              $type={"address"}
              $name={"addressLine2"}
              $value={data.addressLine2}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              City
            </Text>
            <TextBox
              $type={"text"}
              $name={"city"}
              $value={data.city}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              Region
            </Text>
            <TextBox
              $type={"text"}
              $name={"region"}
              $value={data.region}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              Postcode
            </Text>
            <TextBox
              $type={"text"}
              $name={"postcode"}
              $value={data.postcode}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              Country
            </Text>
            <TextBox
              $type={"text"}
              $name={"country"}
              $value={data.country}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </Box>
        }

        { /* Contacts */}
        {
          selectedTab == "Contacts" && clientDoctorDetailsSpecified && clientNextOfKinDetailsSpecified && clientTherapistDetailsSpecified && clientSocialworkerDetailsSpecified && <Box>
            {clientDoctorDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
              Doctor Details
            </Text>}
            {clientDoctorDetailsSpecified && <Textarea
              $type={"text"}
              $name={"doctorDetails"}
              $rows={4}
              $value={data?.doctorDetails}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />}
            <br />

            {clientNextOfKinDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
              Next Of Kin
            </Text>}
            {clientNextOfKinDetailsSpecified && <Textarea
              $type={"text"}
              $name={"nextOfKin"}
              $rows={4}
              $value={data?.nextOfKin}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />}
            <br />

            {clientTherapistDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
              Therapist Details
            </Text>}
            {clientTherapistDetailsSpecified && <Textarea
              $type={"text"}
              $name={"therapistDetails"}
              $rows={4}
              $value={data?.therapistDetails}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />}
            <br />

            {clientSocialworkerDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
              Social Worker Contacts
            </Text>}
            {clientSocialworkerDetailsSpecified && <Textarea
              $type={"text"}
              $name={"socialWorkerContacts"}
              $rows={4}
              $value={data?.socialWorkerContacts}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />}
            <br />
          </Box>
        }

        {
          clientErrorMessage && (
            <FlexBox $marginTop={2} $width="100%">
              {" "}
              <ErrorAndSuccess $type="error">
                {clientErrorMessage}
              </ErrorAndSuccess>
            </FlexBox>
          )
        }
        {
          clientUpdateChangeTracker === "you haven't made any changes" && !clientErrorMessage && (
            <FlexBox $marginTop={2} $width="100%">
              <ErrorAndSuccess $type="error">
                <Text>{clientUpdateChangeTracker}</Text>
              </ErrorAndSuccess>
            </FlexBox>
          )
        }
        {
          submitted &&
          clientSuccessMessage &&
          clientStatus === asyncStates.SUCCESS && (
            <FlexBox $marginTop={2} $width="100%">
              {" "}
              <ErrorAndSuccess $type="success">
                {clientSuccessMessage}
              </ErrorAndSuccess>
            </FlexBox>
          )
        }
      </Box >
      {/* Save and Delete buttons */}
      < FlexBox
        $justifyContent={"space-between"}
        $padding={4}
        $backgroundColor="list"
      >
        {
          < Button
            $color={"error"}
            $sx={{ borderRadius: 2 }}
            $marginRight={3}
            $onClick={() => onDeleteClientClick()}
          >
            <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
              {deleteClientStatus !== asyncStates.PENDING ? (
                "Delete"
              ) : (
                <CircularLoader $color="white" $size={25} />
              )}
            </Text>
          </Button >
        }
        {
          clientStatus === asyncStates.PENDING ? (
            <Button
              $sx={{
                borderRadius: 2,
                width: "100px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              $marginRight={100}
            >
              <CircularLoader $color="white" $size={25} />
            </Button>
          ) : (
            <Button
              $sx={{ borderRadius: 2 }}
              $marginRight={3}
              $onClick={() => {
                if (clientStatus === asyncStates.PENDING) return;
                $type === "Clientedit" || clientUpdateChangeTracker === "changed"
                  ? onUpdateClientClicked()
                  : console.log("Create Client");
              }}
            >
              <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                Save
              </Text>
            </Button>
          )
        }
      </FlexBox >
    </Box >
  );
};
