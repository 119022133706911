import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as asyncStates from "../../constants/asyncStates";
import {
    getAdditionalFormConfigsApiService,
    getCareplanConfigsApiService,
    getComplianceConfigsApiService,
    getConfigsApiService,
    getEventCheckListConfigsApiService,
    getMedicationConfigsApiService,
    getMedicineRefusalReasonsConfigsApiService,
    getRiskassessmentConfigsApiService,
    getServicesConfigsApiService,
    getPolicyConfigsApiService,
    getJobRolesConfigsApiService,
    saveAdditionalFormConfigsApiService,
    saveCareplanConfigsApiService,
    saveComplianceConfigsApiService,
    saveEventCheckListConfigsApiService,
    saveMedicationConfigsApiService,
    saveMedicineRefusalReasonsConfigsApiService,
    saveRiskassessmentConfigsApiService,
    saveServicesConfigsApiService,
    savePolicyConfigsApiService,
    saveJobRolesConfigsApiService,
    saveTimeOffApproversConfigsApiService,
    getTimeOffApproversConfigsApiService,
    createDefaultQualificationsConfigsApiService,
    updateDefaultQualificationsConfigsApiService,
    deleteDefaultQualificationsConfigsApiService,
    getDefaultQualificationsConfigsApiService,
} from "../../../services/applicationBackendApis/configs";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
    services: [],
    careplans: [],
    compliances: [],
    riskassessments: [],
    medicines: [],
    medicineRefusalReasons: [],
    policy: [],
    additionalForms: [],
    eventCheckLists: [],
    jobRoles: [],
    timeOffApprovers: [],
    defaultQualifications: [],

    moreDefaultQualificationsLeftInPagination: false,

    global: {
        services: [],
        careplans: [],
        compliances: [],
        riskassessments: [],
        medicines: [],
        medicineForms: [],
        medicineRefusalReasons: [],
        policy: [],
        additionalForms: [],
        featureAccessList: [],
        eventCheckLists: [],
        jobRoles: [],
    },
    errorMessages: {
        updateServicesErrorMessage: null,
        getServicesErrorMessage: null,

        updateCareplansErrorMessage: null,
        getCareplansErrorMessage: null,

        updatePolicyErrorMessage: null,
        getPolicyErrorMessage: null,

        updateCompliancesErrorMessage: null,
        getCompliancesErrorMessage: null,

        updateRiskassessmentsErrorMessage: null,
        getRiskassessmentsErrorMessage: null,

        updateMedicinesErrorMessage: null,
        getMedicinesErrorMessage: null,

        updateMedicineRefusalReasonsErrorMessage: null,
        getMedicineRefusalReasonsErrorMessage: null,

        updateAdditionalFormsErrorMessage: null,
        getAdditionalFormsErrorMessage: null,

        updateEventCheckListsErrorMessage: null,
        getEventCheckListsErrorMessage: null,

        updateJobRolesErrorMessage: null,
        getJobRolesErrorMessage: null,

        updateTimeOffApproversErrorMessage: null,
        getTimeOffApproversErrorMessage: null,

        createDefaultQualificationsErrorMessage: null,
        updateDefaultQualificationsErrorMessage: null,
        getDefaultQualificationsErrorMessage: null,
        deleteDefaultQualificationsErrorMessage: null,

        getGlobalConfigsErrorMessage: null,
    },
    statuses: {
        updateServicesStatus: null,
        getServicesStatus: null,

        updateCareplansStatus: null,
        getCareplansStatus: null,

        updatePolicyStatus: null,
        getPolicyStatus: null,

        updateCompliancesStatus: null,
        getCompliancesStatus: null,

        updateRiskassessmentsStatus: null,
        getRiskassessmentsStatus: null,

        updateMedicinesStatus: null,
        getMedicinesStatus: null,

        updateMedicineRefusalReasonsStatus: null,
        getMedicineRefusalReasonsStatus: null,

        updateAdditionalFormsStatus: null,
        getAdditionalFormsStatus: null,

        updateEventCheckListsStatus: null,
        getEventCheckListsStatus: null,

        updateJobRolesStatus: null,
        getJobRolesStatus: null,

        updateTimeOffApproversStatus: null,
        getTimeOffApproversStatus: null,

        createDefaultQualificationsStatus: null,
        updateDefaultQualificationsStatus: null,
        getDefaultQualificationsStatus: null,
        deleteDefaultQualificationsStatus: null,

        getGlobalConfigsStatus: null,
    },
};

// ---------- services reducer
export const updateServicesReducer = createAsyncThunk(
    "configs/updateServices",
    async (data) => {
        try {
            const response = await saveServicesConfigsApiService({
                services: data.services
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getServicesReducer = createAsyncThunk(
    "configs/getServices",
    async (data) => {
        try {
            const response = await getServicesConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// ---------- careplans reducer
export const updateCareplansReducer = createAsyncThunk(
    "configs/updateCareplans",
    async (data) => {
        try {
            const response = await saveCareplanConfigsApiService({
                careplans: data.careplans
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getCareplansReducer = createAsyncThunk(
    "configs/getCareplans",
    async (data) => {
        try {
            const response = await getCareplanConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// ---------- eventCheckLists reducer
export const updateEventCheckListsReducer = createAsyncThunk(
    "configs/updateEventCheckLists",
    async (data) => {
        try {
            const response = await saveEventCheckListConfigsApiService({
                eventCheckLists: data.eventCheckLists
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getEventCheckListsReducer = createAsyncThunk(
    "configs/getEventCheckLists",
    async (data) => {
        try {
            const response = await getEventCheckListConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// --------- riskassessments
export const updateRiskassessmentsReducer = createAsyncThunk(
    "configs/updateRiskassessments",
    async (data) => {
        try {
            const response = await saveRiskassessmentConfigsApiService({
                riskassessments: data.riskassessments
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getRiskassessmentsReducer = createAsyncThunk(
    "configs/getRiskassessments",
    async (data) => {
        try {
            const response = await getRiskassessmentConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// --------- compliances
export const updateCompliancesReducer = createAsyncThunk(
    "configs/updateCompliances",
    async (data) => {
        try {
            const response = await saveComplianceConfigsApiService({
                compliances: data.compliances
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getCompliancesReducer = createAsyncThunk(
    "configs/getCompliances",
    async (data) => {
        try {
            const response = await getComplianceConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// ---------- job roles
export const updateJobRolesReducer = createAsyncThunk(
    "configs/updateJobRoles",
    async (data) => {
        try {
            const response = await saveJobRolesConfigsApiService({
                jobRoles: data.jobRoles
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

export const getJobRolesReducer = createAsyncThunk(
    "configs/getJobRoles",
    async (data) => {
        try {
            const response = await getJobRolesConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

// ---------- time off approvers
export const updateTimeOffApproversReducer = createAsyncThunk(
    "configs/updateTimeOffApprovers",
    async (data) => {
        try {
            const response = await saveTimeOffApproversConfigsApiService({
                timeOffApprovers: data.timeOffApprovers
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

export const getTimeOffApproversReducer = createAsyncThunk(
    "configs/getTimeOffApprovers",
    async (data) => {
        try {
            const response = await getTimeOffApproversConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

// ---------- default qualifications
export const createDefaultQualificationsReducer = createAsyncThunk(
    "configs/createDefaultQualifications",
    async (data) => {
        try {
            const response = await createDefaultQualificationsConfigsApiService({
                name: data.name,
                grade: data.grade,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

export const updateDefaultQualificationsReducer = createAsyncThunk(
    "configs/updateDefaultQualifications",
    async (data) => {
        try {
            const response = await updateDefaultQualificationsConfigsApiService({
                id: data.id,
                name: data.name,
                grade: data.grade,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

export const getDefaultQualificationsReducer = createAsyncThunk(
    "configs/getDefaultQualifications",
    async (data) => {
        try {
            const response = await getDefaultQualificationsConfigsApiService({
                startAfter: data.startAfter,
                limit: data.limit ?? PAGINATION_LIMITS.DEFAULT_QUALIFICATIONS_LIST,
            });

            return {
                data: response.data,
                limit: data.limit ?? PAGINATION_LIMITS.DEFAULT_QUALIFICATIONS_LIST,
                startAfter: data.startAfter,
            };
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

export const deleteDefaultQualificationsReducer = createAsyncThunk(
    "configs/deleteDefaultQualifications",
    async (data) => {
        try {
            const response = await deleteDefaultQualificationsConfigsApiService({
                id: data.id,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
)

// --------- medicines
export const updateMedicinesReducer = createAsyncThunk(
    "configs/updateMedicines",
    async (data) => {
        try {
            const medicines = []
            if (data.medicines && data.medicines?.length > 0) {
                data.medicines.forEach(medicine => {
                    medicines.push({
                        name: medicine.name,
                        strength: medicine.strength,
                        status: medicine.status,
                        form: medicine.form,
                        ingredients: medicine.ingredients?.map(ingredient => {
                            return {
                                name: ingredient?.name,
                                status: ingredient?.status,
                                strengthByUnit: {
                                    value: {
                                        value: ingredient?.strengthByUnit?.value?.value,
                                        units: ingredient?.strengthByUnit?.value?.units,
                                    },
                                    per: {
                                        value: ingredient?.strengthByUnit?.per?.value,
                                        units: ingredient?.strengthByUnit?.per?.units,
                                    }
                                }
                            }
                        }),
                    })
                })
            }

            // if (medicines.length <= 0) {
            //     throw new Error(error?.data?.error?.message || "Error");
            // }

            const response = await saveMedicationConfigsApiService({
                medicines: medicines
            });
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getMedicinesReducer = createAsyncThunk(
    "configs/getMedicines",
    async (data) => {
        try {
            const response = await getMedicationConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// --------- medicineRefusalReasons
export const updateMedicineRefusalReasonsReducer = createAsyncThunk(
    "configs/updateMedicineRefusalReasons",
    async (data) => {
        try {
            const response = await saveMedicineRefusalReasonsConfigsApiService({
                medicineRefusalReasons: data.medicineRefusalReasons
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getMedicineRefusalReasonsReducer = createAsyncThunk(
    "configs/getMedicineRefusalReasons",
    async (data) => {
        try {
            const response = await getMedicineRefusalReasonsConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// ---------- policy reducer
export const updatePolicyReducer = createAsyncThunk(
    "configs/updatePolicy",
    async (data) => {
        try {
            const response = await savePolicyConfigsApiService({
                policy: data.policy
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getPolicyReducer = createAsyncThunk(
    "configs/getPolicy",
    async (data) => {
        try {
            const response = await getPolicyConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// --------- additional forms
export const updateAdditionalFormsReducer = createAsyncThunk(
    "configs/updateAdditionalForms",
    async (data) => {
        try {
            const additionalForms = []
            if (data.additionalForms && data.additionalForms?.length > 0) {
                data.additionalForms.forEach(additionalForm => {
                    additionalForms.push({
                        name: additionalForm.name,
                        instructions: additionalForm.instructions,
                        attachedImageUrl: additionalForm.attachedImageUrl,
                        fields: additionalForm.fields?.map(field => {
                            return {
                                name: field.name,
                                type: field.type,
                                options: field.options
                            }
                        }),
                    })
                })
            }

            const response = await saveAdditionalFormConfigsApiService({
                additionalForms: additionalForms
            });
            return response.data;
        } catch (error) {
            console.log(error)
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getAdditionalFormsReducer = createAsyncThunk(
    "configs/getAdditionalForms",
    async (data) => {
        try {
            const response = await getAdditionalFormConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// --------- configs
export const getGlobalConfigsReducer = createAsyncThunk(
    "configs/getConfigs",
    async (data) => {
        try {
            const response = await getConfigsApiService();
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

const configsSlice = createSlice({
    name: "team",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {};
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {};
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {
        // ---------------------- services
        builder.addCase(updateServicesReducer.pending, (state, action) => {
            state.statuses.updateServicesStatus = asyncStates.PENDING;
            state.errorMessages.updateServicesErrorMessage = null;
        });
        builder.addCase(updateServicesReducer.rejected, (state, action) => {
            state.statuses.updateServicesStatus = asyncStates.FAILURE;
            state.errorMessages.updateServicesErrorMessage = action.error?.message;
        });
        builder.addCase(updateServicesReducer.fulfilled, (state, action) => {
            state.statuses.updateServicesStatus = asyncStates.SUCCESS;
            state.errorMessages.updateServicesErrorMessage = null;
        });

        builder.addCase(getServicesReducer.pending, (state, action) => {
            state.statuses.getServicesStatus = asyncStates.PENDING;
            state.errorMessages.getServicesErrorMessage = null;
        });
        builder.addCase(getServicesReducer.rejected, (state, action) => {
            state.statuses.getServicesStatus = asyncStates.FAILURE;
            state.errorMessages.getServicesErrorMessage = action.error?.message;
        });
        builder.addCase(getServicesReducer.fulfilled, (state, action) => {
            state.statuses.getServicesStatus = asyncStates.SUCCESS;
            state.errorMessages.getServicesErrorMessage = null;
            state.services = action.payload.services;
        });

        // ------------------- careplans
        builder.addCase(updateCareplansReducer.pending, (state, action) => {
            state.statuses.updateCareplansStatus = asyncStates.PENDING;
            state.errorMessages.updateCareplansErrorMessage = null;
        });
        builder.addCase(updateCareplansReducer.rejected, (state, action) => {
            state.statuses.updateCareplansStatus = asyncStates.FAILURE;
            state.errorMessages.updateCareplansErrorMessage = action.error?.message;
        });
        builder.addCase(updateCareplansReducer.fulfilled, (state, action) => {
            state.statuses.updateCareplansStatus = asyncStates.SUCCESS;
            state.errorMessages.updateCareplansErrorMessage = null;
        });

        builder.addCase(getCareplansReducer.pending, (state, action) => {
            state.statuses.getCareplansStatus = asyncStates.PENDING;
            state.errorMessages.getCareplansErrorMessage = null;
        });
        builder.addCase(getCareplansReducer.rejected, (state, action) => {
            state.statuses.getCareplansStatus = asyncStates.FAILURE;
            state.errorMessages.getCareplansErrorMessage = action.error?.message;
        });
        builder.addCase(getCareplansReducer.fulfilled, (state, action) => {
            state.statuses.getCareplansStatus = asyncStates.SUCCESS;
            state.errorMessages.getCareplansErrorMessage = null;
            state.careplans = action.payload.careplans;
        });

        // ------------------- eventCheckLists
        builder.addCase(updateEventCheckListsReducer.pending, (state, action) => {
            state.statuses.updateEventCheckListsStatus = asyncStates.PENDING;
            state.errorMessages.updateEventCheckListsErrorMessage = null;
        });
        builder.addCase(updateEventCheckListsReducer.rejected, (state, action) => {
            state.statuses.updateEventCheckListsStatus = asyncStates.FAILURE;
            state.errorMessages.updateEventCheckListsErrorMessage = action.error?.message;
        });
        builder.addCase(updateEventCheckListsReducer.fulfilled, (state, action) => {
            state.statuses.updateEventCheckListsStatus = asyncStates.SUCCESS;
            state.errorMessages.updateEventCheckListsErrorMessage = null;
        });

        builder.addCase(getEventCheckListsReducer.pending, (state, action) => {
            state.statuses.getEventCheckListsStatus = asyncStates.PENDING;
            state.errorMessages.getEventCheckListsErrorMessage = null;
        });
        builder.addCase(getEventCheckListsReducer.rejected, (state, action) => {
            state.statuses.getEventCheckListsStatus = asyncStates.FAILURE;
            state.errorMessages.getEventCheckListsErrorMessage = action.error?.message;
        });
        builder.addCase(getEventCheckListsReducer.fulfilled, (state, action) => {
            state.statuses.getEventCheckListsStatus = asyncStates.SUCCESS;
            state.errorMessages.getEventCheckListsErrorMessage = null;
            state.eventCheckLists = action.payload.eventCheckLists;
        });

        // ------------------- compliances
        builder.addCase(updateCompliancesReducer.pending, (state, action) => {
            state.statuses.updateCompliancesStatus = asyncStates.PENDING;
            state.errorMessages.updateCompliancesErrorMessage = null;
        });
        builder.addCase(updateCompliancesReducer.rejected, (state, action) => {
            state.statuses.updateCompliancesStatus = asyncStates.FAILURE;
            state.errorMessages.updateCompliancesErrorMessage = action.error?.message;
        });
        builder.addCase(updateCompliancesReducer.fulfilled, (state, action) => {
            state.statuses.updateCompliancesStatus = asyncStates.SUCCESS;
            state.errorMessages.updateCompliancesErrorMessage = null;
        });

        builder.addCase(getCompliancesReducer.pending, (state, action) => {
            state.statuses.getCompliancesStatus = asyncStates.PENDING;
            state.errorMessages.getCompliancesErrorMessage = null;
        });
        builder.addCase(getCompliancesReducer.rejected, (state, action) => {
            state.statuses.getCompliancesStatus = asyncStates.FAILURE;
            state.errorMessages.getCompliancesErrorMessage = action.error?.message;
        });
        builder.addCase(getCompliancesReducer.fulfilled, (state, action) => {
            state.statuses.getCompliancesStatus = asyncStates.SUCCESS;
            state.errorMessages.getCompliancesErrorMessage = null;
            state.compliances = action.payload.compliances;
        });

        // ------------------- job roles
        builder.addCase(updateJobRolesReducer.pending, (state, action) => {
            state.statuses.updateJobRolesStatus = asyncStates.PENDING;
            state.errorMessages.updateJobRolesErrorMessage = null;
        });
        builder.addCase(updateJobRolesReducer.rejected, (state, action) => {
            state.statuses.updateJobRolesStatus = asyncStates.FAILURE;
            state.errorMessages.updateJobRolesErrorMessage = action.error?.message;
        });
        builder.addCase(updateJobRolesReducer.fulfilled, (state, action) => {
            state.statuses.updateJobRolesStatus = asyncStates.SUCCESS;
            state.errorMessages.updateJobRolesErrorMessage = null;
        });

        builder.addCase(getJobRolesReducer.pending, (state, action) => {
            state.statuses.getJobRolesStatus = asyncStates.PENDING;
            state.errorMessages.getJobRolesErrorMessage = null;
        });
        builder.addCase(getJobRolesReducer.rejected, (state, action) => {
            state.statuses.getJobRolesStatus = asyncStates.FAILURE;
            state.errorMessages.getJobRolesErrorMessage = action.error?.message;
        });
        builder.addCase(getJobRolesReducer.fulfilled, (state, action) => {
            state.statuses.getJobRolesStatus = asyncStates.SUCCESS;
            state.errorMessages.getJobRolesErrorMessage = null;
            state.jobRoles = action.payload.jobRoles;
        });


        // ------------------- job roles
        builder.addCase(updateTimeOffApproversReducer.pending, (state, action) => {
            state.statuses.updateTimeOffApproversStatus = asyncStates.PENDING;
            state.errorMessages.updateTimeOffApproversErrorMessage = null;
        });
        builder.addCase(updateTimeOffApproversReducer.rejected, (state, action) => {
            state.statuses.updateTimeOffApproversStatus = asyncStates.FAILURE;
            state.errorMessages.updateTimeOffApproversErrorMessage = action.error?.message;
        });
        builder.addCase(updateTimeOffApproversReducer.fulfilled, (state, action) => {
            state.statuses.updateTimeOffApproversStatus = asyncStates.SUCCESS;
            state.errorMessages.updateTimeOffApproversErrorMessage = null;
        });

        builder.addCase(getTimeOffApproversReducer.pending, (state, action) => {
            state.statuses.getTimeOffApproversStatus = asyncStates.PENDING;
            state.errorMessages.getTimeOffApproversErrorMessage = null;
        });
        builder.addCase(getTimeOffApproversReducer.rejected, (state, action) => {
            state.statuses.getTimeOffApproversStatus = asyncStates.FAILURE;
            state.errorMessages.getTimeOffApproversErrorMessage = action.error?.message;
        });
        builder.addCase(getTimeOffApproversReducer.fulfilled, (state, action) => {
            state.statuses.getTimeOffApproversStatus = asyncStates.SUCCESS;
            state.errorMessages.getTimeOffApproversErrorMessage = null;
            state.timeOffApprovers = action.payload.timeOffApprovers;
        });

        // ------------------- medicineRefusalReasons
        builder.addCase(updateMedicineRefusalReasonsReducer.pending, (state, action) => {
            state.statuses.updateMedicineRefusalReasonsStatus = asyncStates.PENDING;
            state.errorMessages.updateMedicineRefusalReasonsErrorMessage = null;
        });
        builder.addCase(updateMedicineRefusalReasonsReducer.rejected, (state, action) => {
            state.statuses.updateMedicineRefusalReasonsStatus = asyncStates.FAILURE;
            state.errorMessages.updateMedicineRefusalReasonsErrorMessage = action.error?.message;
        });
        builder.addCase(updateMedicineRefusalReasonsReducer.fulfilled, (state, action) => {
            state.statuses.updateMedicineRefusalReasonsStatus = asyncStates.SUCCESS;
            state.errorMessages.updateMedicineRefusalReasonsErrorMessage = null;
        });

        builder.addCase(getMedicineRefusalReasonsReducer.pending, (state, action) => {
            state.statuses.getMedicineRefusalReasonsStatus = asyncStates.PENDING;
            state.errorMessages.getMedicineRefusalReasonsErrorMessage = null;
        });
        builder.addCase(getMedicineRefusalReasonsReducer.rejected, (state, action) => {
            state.statuses.getMedicineRefusalReasonsStatus = asyncStates.FAILURE;
            state.errorMessages.getMedicineRefusalReasonsErrorMessage = action.error?.message;
        });
        builder.addCase(getMedicineRefusalReasonsReducer.fulfilled, (state, action) => {
            state.statuses.getMedicineRefusalReasonsStatus = asyncStates.SUCCESS;
            state.errorMessages.getMedicineRefusalReasonsErrorMessage = null;
            state.medicineRefusalReasons = action.payload.medicineRefusalReasons;
        });

        // ---------------------- policy
        builder.addCase(updatePolicyReducer.pending, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.PENDING;
            state.errorMessages.updatePolicyErrorMessage = null;
        });
        builder.addCase(updatePolicyReducer.rejected, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.FAILURE;
            state.errorMessages.updatePolicyErrorMessage = action.error?.message;
        });
        builder.addCase(updatePolicyReducer.fulfilled, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.SUCCESS;
            state.errorMessages.updatePolicyErrorMessage = null;
        });

        builder.addCase(getPolicyReducer.pending, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.PENDING;
            state.errorMessages.getPolicyErrorMessage = null;
        });
        builder.addCase(getPolicyReducer.rejected, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.FAILURE;
            state.errorMessages.getPolicyErrorMessage = action.error?.message;
        });
        builder.addCase(getPolicyReducer.fulfilled, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.SUCCESS;
            state.errorMessages.getPolicyErrorMessage = null;
            state.policy = action.payload.policy;
        });

        // ------------------- riskassessments
        builder.addCase(updateRiskassessmentsReducer.pending, (state, action) => {
            state.statuses.updateRiskassessmentsStatus = asyncStates.PENDING;
            state.errorMessages.updateRiskassessmentsErrorMessage = null;
        });
        builder.addCase(updateRiskassessmentsReducer.rejected, (state, action) => {
            state.statuses.updateRiskassessmentsStatus = asyncStates.FAILURE;
            state.errorMessages.updateRiskassessmentsErrorMessage = action.error?.message;
        });
        builder.addCase(updateRiskassessmentsReducer.fulfilled, (state, action) => {
            state.statuses.updateRiskassessmentsStatus = asyncStates.SUCCESS;
            state.errorMessages.updateRiskassessmentsErrorMessage = null;
        });

        builder.addCase(getRiskassessmentsReducer.pending, (state, action) => {
            state.statuses.getRiskassessmentsStatus = asyncStates.PENDING;
            state.errorMessages.getRiskassessmentsErrorMessage = null;
        });
        builder.addCase(getRiskassessmentsReducer.rejected, (state, action) => {
            state.statuses.getRiskassessmentsStatus = asyncStates.FAILURE;
            state.errorMessages.getRiskassessmentsErrorMessage = action.error?.message;
        });
        builder.addCase(getRiskassessmentsReducer.fulfilled, (state, action) => {
            state.statuses.getRiskassessmentsStatus = asyncStates.SUCCESS;
            state.errorMessages.getRiskassessmentsErrorMessage = null;
            state.riskassessments = action.payload.riskassessments;
        });

        // ------------------- Medicines
        builder.addCase(updateMedicinesReducer.pending, (state, action) => {
            state.statuses.updateMedicinesStatus = asyncStates.PENDING;
            state.errorMessages.updateMedicinesErrorMessage = null;
        });
        builder.addCase(updateMedicinesReducer.rejected, (state, action) => {
            state.statuses.updateMedicinesStatus = asyncStates.FAILURE;
            state.errorMessages.updateMedicinesErrorMessage = action.error?.message;
        });
        builder.addCase(updateMedicinesReducer.fulfilled, (state, action) => {
            state.statuses.updateMedicinesStatus = asyncStates.SUCCESS;
            state.errorMessages.updateMedicinesErrorMessage = null;
        });

        builder.addCase(getMedicinesReducer.pending, (state, action) => {
            state.statuses.getMedicinesStatus = asyncStates.PENDING;
            state.errorMessages.getMedicinesErrorMessage = null;
        });
        builder.addCase(getMedicinesReducer.rejected, (state, action) => {
            state.statuses.getMedicinesStatus = asyncStates.FAILURE;
            state.errorMessages.getMedicinesErrorMessage = action.error?.message;
        });
        builder.addCase(getMedicinesReducer.fulfilled, (state, action) => {
            state.statuses.getMedicinesStatus = asyncStates.SUCCESS;
            state.errorMessages.getMedicinesErrorMessage = null;
            state.medicines = action.payload.medicines;
        });

        // ------------------- Additional Forms
        builder.addCase(updateAdditionalFormsReducer.pending, (state, action) => {
            state.statuses.updateAdditionalFormsStatus = asyncStates.PENDING;
            state.errorMessages.updateAdditionalFormsErrorMessage = null;
        });
        builder.addCase(updateAdditionalFormsReducer.rejected, (state, action) => {
            state.statuses.updateAdditionalFormsStatus = asyncStates.FAILURE;
            state.errorMessages.updateAdditionalFormsErrorMessage = action.error?.message;
        });
        builder.addCase(updateAdditionalFormsReducer.fulfilled, (state, action) => {
            state.statuses.updateAdditionalFormsStatus = asyncStates.SUCCESS;
            state.errorMessages.updateAdditionalFormsErrorMessage = null;
        });

        builder.addCase(getAdditionalFormsReducer.pending, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.PENDING;
            state.errorMessages.getAdditionalFormsErrorMessage = null;
        });
        builder.addCase(getAdditionalFormsReducer.rejected, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.FAILURE;
            state.errorMessages.getAdditionalFormsErrorMessage = action.error?.message;
        });
        builder.addCase(getAdditionalFormsReducer.fulfilled, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.SUCCESS;
            state.errorMessages.getAdditionalFormsErrorMessage = null;
            state.additionalForms = action.payload.additionalForms;
        });

        // -------------------- Default Qualifications
        builder.addCase(createDefaultQualificationsReducer.pending, (state, action) => {
            state.statuses.createDefaultQualificationsStatus = asyncStates.PENDING;
            state.errorMessages.createDefaultQualificationsErrorMessage = null;
        });
        builder.addCase(createDefaultQualificationsReducer.rejected, (state, action) => {
            state.statuses.createDefaultQualificationsStatus = asyncStates.FAILURE;
            state.errorMessages.createDefaultQualificationsErrorMessage = action.error?.message;
        });
        builder.addCase(createDefaultQualificationsReducer.fulfilled, (state, action) => {
            state.statuses.createDefaultQualificationsStatus = asyncStates.SUCCESS;
            state.errorMessages.createDefaultQualificationsErrorMessage = null;
        });

        builder.addCase(updateDefaultQualificationsReducer.pending, (state, action) => {
            state.statuses.updateDefaultQualificationsStatus = asyncStates.PENDING;
            state.errorMessages.updateDefaultQualificationsErrorMessage = null;
        });
        builder.addCase(updateDefaultQualificationsReducer.rejected, (state, action) => {
            state.statuses.updateDefaultQualificationsStatus = asyncStates.FAILURE;
            state.errorMessages.updateDefaultQualificationsErrorMessage = action.error?.message;
        });
        builder.addCase(updateDefaultQualificationsReducer.fulfilled, (state, action) => {
            state.statuses.updateDefaultQualificationsStatus = asyncStates.SUCCESS;
            state.errorMessages.updateDefaultQualificationsErrorMessage = null;
        });

        builder.addCase(getDefaultQualificationsReducer.pending, (state, action) => {
            state.statuses.getDefaultQualificationsStatus = asyncStates.PENDING;
            state.errorMessages.getDefaultQualificationsErrorMessage = null;
        });
        builder.addCase(getDefaultQualificationsReducer.rejected, (state, action) => {
            state.statuses.getDefaultQualificationsStatus = asyncStates.FAILURE;
            state.errorMessages.getDefaultQualificationsErrorMessage = action.error?.message;
        });
        builder.addCase(getDefaultQualificationsReducer.fulfilled, (state, action) => {
            state.statuses.getDefaultQualificationsStatus = asyncStates.SUCCESS;
            state.errorMessages.getDefaultQualificationsErrorMessage = null;
            state.defaultQualifications = action.payload.startAfter ? [...state.defaultQualifications, ...action.payload.data.defaultQualifications] : action.payload.data.defaultQualifications;

            if (action.payload.data.defaultQualifications.length >= action.payload.limit) {
              state.moreDefaultQualificationsLeftInPagination = true;
            } else {
              state.moreDefaultQualificationsLeftInPagination = false;
            }
        });

        builder.addCase(deleteDefaultQualificationsReducer.pending, (state, action) => {
            state.statuses.deleteDefaultQualificationsStatus = asyncStates.PENDING;
            state.errorMessages.deleteDefaultQualificationsErrorMessage = null;
        });
        builder.addCase(deleteDefaultQualificationsReducer.rejected, (state, action) => {
            state.statuses.deleteDefaultQualificationsStatus = asyncStates.FAILURE;
            state.errorMessages.deleteDefaultQualificationsErrorMessage = action.error?.message;
        });
        builder.addCase(deleteDefaultQualificationsReducer.fulfilled, (state, action) => {
            state.statuses.deleteDefaultQualificationsStatus = asyncStates.SUCCESS;
            state.errorMessages.deleteDefaultQualificationsErrorMessage = null;
        });

        // ----------- global configs
        builder.addCase(getGlobalConfigsReducer.pending, (state, action) => {
            state.statuses.getGlobalConfigsStatus = asyncStates.PENDING;
            state.errorMessages.getGlobalConfigsErrorMessage = null;
        });
        builder.addCase(getGlobalConfigsReducer.rejected, (state, action) => {
            state.statuses.getGlobalConfigsStatus = asyncStates.FAILURE;
            state.errorMessages.getGlobalConfigsErrorMessage = action.error?.message;
        });
        builder.addCase(getGlobalConfigsReducer.fulfilled, (state, action) => {
            state.statuses.getGlobalConfigsStatus = asyncStates.SUCCESS;
            state.errorMessages.getGlobalConfigsErrorMessage = null;
            state.global = action.payload.configs;
        });
    },
});

export const {
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
} = configsSlice.actions;

export default configsSlice.reducer;
