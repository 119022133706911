import React, { useMemo } from "react";
import { Box, Card, FlexBox } from "../../Components/Boxes/index";
import { useBusinessAccountSelector } from "../../redux/selectors/useBusinessAccountSelectors";
import { Grid, GridContainer } from "../../Components/Grid";
import { Text } from "../../Components/Text";
import { formatteMinutesToReadableFormat } from "../../utiles/timeformate";

const Home = () => {

  const businessAccount = useBusinessAccountSelector()

  const totalNumCompletedEvents = useMemo(() => businessAccount.totalNumCompletedEvents, [businessAccount])
  const totalNumCancelledEvents = useMemo(() => businessAccount.totalNumCancelledEvents, [businessAccount])
  const totalNumStaff = useMemo(() => businessAccount.totalNumStaff, [businessAccount])
  const totalNumClients = useMemo(() => businessAccount.totalNumClients, [businessAccount])
  const totalNumMinutesCompleted = useMemo(() => businessAccount.totalNumMinutesCompleted, [businessAccount])
  const totalNumMissedEvents = useMemo(() => businessAccount.totalNumMissedEvents, [businessAccount])

  return (
    <>
      <GridContainer $spacing={2}>
        <Grid $xs={12}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={8}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Completed Time</Text>
            <Text $fontSize="heading-1">{formatteMinutesToReadableFormat(totalNumMinutesCompleted ?? 0)}</Text>
          </FlexBox>
        </Grid>
      </GridContainer>

      <Box $marginTop={5} />

      <GridContainer $spacing={2}>
        <Grid $xs={6}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={5}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Total Clients</Text>
            <Text $fontSize="heading-1">{totalNumClients ?? 0}</Text>
          </FlexBox>
        </Grid>
        <Grid $xs={6}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={5}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Total Staff</Text>
            <Text $fontSize="heading-1">{totalNumStaff ?? 0}</Text>
          </FlexBox>
        </Grid>
      </GridContainer>

      <Box $marginTop={5} />

      <GridContainer $spacing={2}>
        <Grid $xs={6} $sm={4}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={5}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Completed Events</Text>
            <Text $fontSize="heading-1">{totalNumCompletedEvents ?? 0}</Text>
          </FlexBox>
        </Grid>
        <Grid $xs={6} $sm={4}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={5}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Cancelled Events</Text>
            <Text $fontSize="heading-1">{totalNumCancelledEvents ?? 0}</Text>
          </FlexBox>
        </Grid>
        <Grid $xs={6} $sm={4}>
          <FlexBox
            $flexDirection="column"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding={5}
          >
            <Text $fontSize="heading-2" $marginBottom={2}>Missed Events</Text>
            <Text $fontSize="heading-1">{totalNumMissedEvents ?? 0}</Text>
          </FlexBox>
        </Grid>
      </GridContainer>
    </>
  );
};

export default Home;
