import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useMedicineRefusalReasonsSettingsController } from '../SettingsControllers/useMedicineRefusalReasonsController'

export default function MedicineRefusalReasonsSettings() {
  const controller = useMedicineRefusalReasonsSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
