import React from 'react'
import useEmailListDispathers from '../../../redux/dispatchers/useEmailListDispatchers'
import { useEffect } from 'react'
import * as asyncStates from "../../../redux/constants/asyncStates";
import { useEmailListCreateErrorMessageSelector, useEmailListCreateStatusSelector, useEmailListDeleteErrorMessageSelector, useEmailListDeleteStatusSelector, useEmailListUpdateErrorMessageSelector, useEmailListUpdateStatusSelector } from '../../../redux/selectors/useEmailListSelectors'
import { ErrorAndSuccess, FlexBox } from '../../../Components/Boxes';
import { Button, SelectBox, TextBox, Textarea } from '../../../Components/Input';
import { Text } from '../../../Components/Text';
import CircularLoader from '../../../Components/Icons/circularLoader';
import { useState } from 'react';
import { SearchUserSelectBox } from '../../components/searchSelectBox';
import { useMemo } from 'react';
import { EMAIL_LIST_PURPOSE } from '../../../constants';

export default function EditEmailList(props) {
  const {
    $handleDialog,
    $clientId,
    $staffId,
    $emailList,
  } = props

  const [emailList, setEmailList] = useState($emailList)
  const [staff, setStaff] = useState(emailList?.staff)
  const [client, setClient] = useState(emailList?.client)
  const [email, setEmail] = useState(emailList?.email)
  const [notes, setNotes] = useState(emailList?.notes)
  const [purposeId, setPurposeId] = useState(emailList?.purpose.id)
  const [purposeName, setPurposeName] = useState(emailList?.purpose.name)

  const {
    getAllEmailList,
    updateEmailList,
    createEmailList,
    deleteEmailList,
    clearAllErrorMessages,
  } = useEmailListDispathers()

  const updateEmailListStatus = useEmailListUpdateStatusSelector()
  const createEmailListStatus = useEmailListCreateStatusSelector()
  const deleteEmailListStatus = useEmailListDeleteStatusSelector()
  const loading = createEmailListStatus === asyncStates.PENDING || deleteEmailListStatus === asyncStates.PENDING || updateEmailListStatus === asyncStates.PENDING

  const updateEmailListError = useEmailListUpdateErrorMessageSelector()
  const createEmailListError = useEmailListCreateErrorMessageSelector()
  const deleteEmailListError = useEmailListDeleteErrorMessageSelector()
  const errorMessage = createEmailListError || deleteEmailListError || updateEmailListError

  const purposeList = useMemo(() => {
    return [{
      value: EMAIL_LIST_PURPOSE.TIMESHEETS,
      label: "Timesheets",
    }, {
      value: EMAIL_LIST_PURPOSE.STAFF_PROFILE,
      label: "Staff Profile",
    }, {
      value: EMAIL_LIST_PURPOSE.SHIFT_CHANGES,
      label: "Shift Changes",
    }, {
      value: EMAIL_LIST_PURPOSE.OTHER,
      label: "Other",
    }]
  }, [])


  useEffect(() => {
    clearAllErrorMessages()
  }, [])

  const onDeleteEmailListClicked = async () => {
    await deleteEmailList({
      clientId: $clientId ?? client?.id,
      staffId: $staffId ?? staff?.id,
      emailListId: emailList?.id,
    }).unwrap()
    await getAllEmailList({
      clientId: $clientId,
      staffId: $staffId,
    }).unwrap()
    $handleDialog()
  }

  const onCreateEmailListClicked = async () => {
    await createEmailList({
      clientId: $clientId ?? client?.id,
      staffId: $staffId ?? staff?.id,
      purpose: purposeId || purposeName ? {
        id: purposeId,
        name: purposeName,
      } : null,
      email,
      notes,
    }).unwrap()
    await getAllEmailList({
      clientId: $clientId,
      staffId: $staffId,
    }).unwrap()
    $handleDialog()
  }

  const onUpdateEmailListClicked = async () => {
    await updateEmailList({
      emailListId: emailList?.id,
      clientId: $clientId ?? client?.id,
      staffId: $staffId ?? staff?.id,
      purpose: purposeId || purposeName ? {
        id: purposeId,
        name: purposeName,
      } : null,
      email,
      notes,
    }).unwrap()
    await getAllEmailList({
      clientId: $clientId,
      staffId: $staffId,
    }).unwrap()
    $handleDialog()
  }


  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1}>
          Email
        </Text>
        <TextBox
          $type={"email"}
          $name={"email"}
          $value={email}
          $onChange={(e) => setEmail(e.target.value)}
          $width="100%"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Purpose
        </Text>
        <SelectBox
          $name={"purpose"}
          $value={purposeId ? purposeList.find((item) => item.value === purposeId) : null}
          $onChange={({ name, value }) => {
            setPurposeId(value)
          }}
          $options={purposeList}
        />
        <br />

        {purposeId == EMAIL_LIST_PURPOSE.OTHER && <>
          <Text $fontSize="body" $marginBottom={1}>
            Purpose Name
          </Text>
          <TextBox
            $type={"text"}
            $name={"purposeName"}
            $value={purposeName}
            $onChange={(e) => setPurposeName(e.target.value)}
            $width="100%"
          />
          <br />
        </>}

        <Text $fontSize="body" $marginBottom={1}>
          Notes
        </Text>
        <Textarea
          $type={"text"}
          $name={"notes"}
          $value={notes}
          $onChange={(e) => setNotes(e.target.value)}
          $width="100%"
          $rows={5}
        />

        {errorMessage && (
          <FlexBox $width="100%" $marginBottom={1} $marginTop={1}>
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        $justifyContent={emailList ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {emailList && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (loading) return;
            onDeleteEmailListClicked();
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {deleteEmailListStatus !== asyncStates.PENDING ? (
              "Delete"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>
        }
        {!emailList && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (loading) return;
            onCreateEmailListClicked();
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {createEmailListStatus !== asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {emailList && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (loading) return;
            onUpdateEmailListClicked();
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {updateEmailListStatus !== asyncStates.PENDING ? (
              "Update"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
      </FlexBox>
    </>
  )
}
