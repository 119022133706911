import { useSelector } from "react-redux"

export const usePoliciesSelector = () => useSelector((state) => {
    return state.policies.policies
})

export const usePolicySelector = (policyId) => useSelector((state) => {
    return state.policies.policies.find(policy => policy.id === policyId)
})

export const usePolicyUploadPercentageSelector = () => useSelector((state) => {
    return state.policies.policyUploadPercentage
})

// ------------------- selector error messages ------------------ //
export const usePolicyCreateErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.createPolicyErrorMessage
})

export const usePolicyDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.deletePolicyErrorMessage
})

export const usePolicyUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.updatePolicyErrorMessage
})

export const usePoliciesGetErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.getPoliciesErrorMessage
})

export const usePolicyGetErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.getPolicyErrorMessage
})

export const usePolicyRestoreErrorMessageSelector = () => useSelector((state) => {
    return state.policies.errorMessages.restorePolicyErrorMessage;
});

// ------------ status selectors ------------ //
export const usePolicyCreateStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.createPolicyStatus
})

export const usePolicyDeleteStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.deletePolicyStatus
})

export const usePolicyUpdateStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.updatePolicyStatus
})

export const usePoliciesGetStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.getPoliciesStatus
})

export const usePolicyGetStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.getPolicyStatus
})

export const useMorePoliciesLeftInPaginationSelector = () => useSelector((state) => {
    return state.policies.morePoliciesLeftInPagination
})

export const usePolicyRestoreStatusSelector = () => useSelector((state) => {
    return state.policies.statuses.restorePolicyStatus;
});