import React, { useMemo, useState, useEffect } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import {
  Button,
  SelectBox,
  Textarea,
  TextBox,
} from "../../../../../Components/Input";
import { SpanText, Text } from "../../../../../Components/Text";
import useClientDispatchers from "../../../../../redux/dispatchers/useClientDispatchers";
import {
  useCreateClientErrorMessageSelector,
  useCreateClientStatusSelector,
  useCreateClientSuccessMessageSelector,
} from "../../../../../redux/selectors/useClientSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import useTeamDispathers from "../../../../../redux/dispatchers/useTeamDispatchers";
import { useTeamsSelector } from "../../../../../redux/selectors/useTeamSelectors";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";
import { Grid, GridContainer } from "../../../../../Components/Grid"

export const AddClientDialog = ({ clientStaff, $handleDialog, $type }) => {
  const [selectedTeams, setSelectedTeams] = useState([])

  const [tabIndex, setTabIndex] = useState(0)
  //Client Selectors
  const createClientErrorMessage = useCreateClientErrorMessageSelector();
  const createClientStatus = useCreateClientStatusSelector();
  const createClientSuccessMessage = useCreateClientSuccessMessageSelector();

  const error = useMemo(() => {
    return createClientErrorMessage;
  }, [createClientErrorMessage]);
  const status = useMemo(() => {
    return createClientStatus;
  }, [createClientStatus]);
  const success = useMemo(() => {
    return createClientSuccessMessage;
  }, [createClientSuccessMessage]);

  // ------------------------Team Dispatcher ------------------- //
  const { getTeams } = useTeamDispathers()
  // teams
  const allTeams = useTeamsSelector();

  const mappedAllTeamItems = allTeams?.map(item => ({
    value: item.id,
    label: item.name
  }));

  const {
    clientDoctorDetailsSpecified,
    clientNextOfKinDetailsSpecified,
    clientTherapistDetailsSpecified,
    clientSocialworkerDetailsSpecified,
    clientEmailSpecified,
    clientRefIdSpecified,
    clientClientNotesSpecified,
    clientDOBSpecified,
    clientGenderSpecified,
    clientPhoneNumberSpecified,
  } = usePermissionsController()

  //Client Dispatchers
  const {
    createClient,
    getAllClients,
    clearAllErrorMessages,
    clearAllSuccessMessages,
  } = useClientDispatchers();

  //form data
  const [data, setData] = useState({
    id: "",
    name: "",
    email: "",
    contact: null,
    phonecode: null,
    dateOfBirth: null,
    gender: "Male",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: null,
    country: "",
    region: "",
    notes: "",
    doctorDetails: "",
    nextOfKin: "",
    therapistDetails: "",
    socialWorkerContacts: "",
    refId: ""
  });

  const tabOptions = useMemo(() => {
    const opts = []

    opts.push({ name: "Details" })
    opts.push({ name: "Address" })
    if (clientDoctorDetailsSpecified && clientNextOfKinDetailsSpecified && clientTherapistDetailsSpecified && clientSocialworkerDetailsSpecified) {
      opts.push({ name: "Contacts" })
    }
    return opts
  }, [clientDoctorDetailsSpecified, clientNextOfKinDetailsSpecified, clientTherapistDetailsSpecified, clientSocialworkerDetailsSpecified])

  const selectedTab = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  //handling form data
  function handleChange({ target }) {
    const { name, value } = target
    setData({ ...data, [name]: value });
  }

  function handleGenderChange({ name, value }) {
    setData({ ...data, [name]: value });
  }

  const handleTeamChange = (selected) => {
    setSelectedTeams([...selected]);
  };

  const onCreateClientClicked = async () => {
    await createClient({
      ...data,
      teams: selectedTeams?.map(item => item.value),
    }).unwrap();
    $handleDialog(false);
    await getAllClients({}).unwrap();
  };

  useEffect(() => {
    clearAllErrorMessages();
    clearAllSuccessMessages();
    if (!allTeams || allTeams.length <= 0) {
      getTeams({})
    }
  }, []);
  return (
    <Box>
      <Tabs
        $onChanged={(val) => {
          setTabIndex(val)
        }}
        $value={tabIndex}
        $tabs={tabOptions}
      />

      <Box $marginTop={2} />

      <Box
        $padding={5}
        $marginRight={3}
      >
        {selectedTab == "Details" && <Box>
          <Text $fontSize="body" $marginBottom={1}>
            Name<SpanText $color={"red"}>*</SpanText>
          </Text>
          <TextBox
            $hint={$type === "add" ? "name" : clientStaff?.name}
            $type={"text"}
            $name={"name"}
            $value={data.name}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          {clientEmailSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Email
            </Text>

            <TextBox
              $hint={$type === "add" ? "xyz@gmail.com" : clientStaff?.email}
              $type={"email"}
              $name={"email"}
              $value={data.email}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {clientPhoneNumberSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Contact
            </Text>
            <TextBox
              $hint={$type === "add" ? "021 2332 343" : clientStaff?.phonecode}
              $type={"mobileNumber"}
              $name={"phonecode"}
              $onChange={handleChange}
              $value={data.phonecode}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {clientRefIdSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Client Ref ID
            </Text>
            <TextBox
              $hint={$type === "add" ? "name" : clientStaff?.refId}
              $type={"text"}
              $name={"refId"}
              $value={data.refId}
              $onChange={handleChange}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
            <br />
          </>}
          {(clientDOBSpecified || clientDOBSpecified) && <>
            <GridContainer $spacing={1}>
              {clientGenderSpecified && <Grid $xs={clientDOBSpecified ? 6 : 12}>
                <Text $fontSize="body" $marginBottom={1}>
                  Gender
                </Text>
                <SelectBox
                  $name={"gender"}
                  $value={{ value: data.gender, label: data.gender }}
                  $onChange={handleGenderChange}
                  $options={[
                    { value: "Male", label: "Male" },
                    { value: "Female", label: "Female" },
                    { value: "Other", label: "Other" },
                  ]}
                />
              </Grid>}
              {clientDOBSpecified && <Grid $xs={clientGenderSpecified ? 6 : 12}>
                <Text $fontSize="body" $marginBottom={1}>
                  DOB
                </Text>
                <TextBox
                  $type={"date"}
                  $name={"dateOfBirth"}
                  $value={data.dateOfBirth}
                  $onChange={handleChange}
                  $width="100%"
                  $borderRadius="8px"
                  $border="1px solid black"
                />
              </Grid>}
            </GridContainer>
            <br />
          </>}


          <Text $fontSize="body" $marginBottom={1}>
            Teams
          </Text>
          <Box $width="100%">
            <SelectBox
              $isMulti={true}
              $options={mappedAllTeamItems}
              $value={selectedTeams}
              $onChange={handleTeamChange}
            ></SelectBox>
          </Box>

          <br />

          <Text $fontSize="body" $marginBottom={1}>
            About
          </Text>
          <Textarea
            $name={"about"}
            $rows={5}
            $value={data.about}
            $onChange={handleChange}
            $width="100%"
          />
          <br />
          <br />
          {clientClientNotesSpecified && <>
            <Text $fontSize="body" $marginBottom={1}>
              Note
            </Text>
            <Textarea
              $name={"notes"}
              $rows={5}
              $value={data.notes}
              $onChange={handleChange}
              $width="100%"
            />
            <br />
          </>}
        </Box>}

        {selectedTab == "Address" && <Box>
          <Text $fontSize="body" $marginBottom={1}>
            Address Line 1
          </Text>
          <TextBox
            $hint={$type === "add" ? "line 1" : ""}
            $type={"address"}
            $name={"addressLine1"}
            $value={data.addressLine1}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Address Line 2
          </Text>
          <TextBox
            $hint={$type === "add" ? "line 2" : ""}
            $type={"address"}
            $name={"addressLine2"}
            $value={data.addressLine2}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            City
          </Text>
          <TextBox
            $hint={$type === "add" ? "city" : ""}
            $type={"text"}
            $name={"city"}
            $value={data.city}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Region
          </Text>
          <TextBox
            $hint={$type === "add" ? "0997738838" : ""}
            $type={"text"}
            $name={"region"}
            $value={data.region}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Postcode
          </Text>
          <TextBox
            $hint={$type === "add" ? "****" : ""}
            $type={"text"}
            $name={"postcode"}
            $value={data.postcode}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Country
          </Text>
          <TextBox
            $hint={$type === "add" ? "country" : ""}
            $type={"text"}
            $name={"country"}
            $value={data.country}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
        </Box>}

        {selectedTab == "Contacts" && clientDoctorDetailsSpecified && clientNextOfKinDetailsSpecified && clientTherapistDetailsSpecified && clientSocialworkerDetailsSpecified && <Box>
          {clientDoctorDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
            Doctor Details
          </Text>}
          {clientDoctorDetailsSpecified && <Textarea
            $type={"text"}
            $name={"doctorDetails"}
            $rows={4}
            $value={data?.doctorDetails}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />}
          <br />

          {clientNextOfKinDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
            Next Of Kin
          </Text>}
          {clientNextOfKinDetailsSpecified && <Textarea
            $type={"text"}
            $name={"nextOfKin"}
            $rows={4}
            $value={data?.nextOfKin}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />}
          <br />

          {clientTherapistDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
            Therapist Details
          </Text>}
          {clientTherapistDetailsSpecified && <Textarea
            $type={"text"}
            $name={"therapistDetails"}
            $rows={4}
            $value={data?.therapistDetails}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />}
          <br />

          {clientSocialworkerDetailsSpecified && <Text $fontSize="body" $marginBottom={2}>
            Social Worker Contacts
          </Text>}
          {clientSocialworkerDetailsSpecified && <Textarea
            $type={"text"}
            $name={"socialWorkerContacts"}
            $rows={4}
            $value={data?.socialWorkerContacts}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />}
          <br />
        </Box>}

        {error && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="error">{error}</ErrorAndSuccess>
          </FlexBox>
        )}
        {success && status === asyncStates.SUCCESS && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="success">{success}</ErrorAndSuccess>
          </FlexBox>
        )}
      </Box>

      {/* The last buttons */}
      <FlexBox
        $justifyContent={"flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (status === asyncStates.PENDING) return;
            onCreateClientClicked();
          }}
        >
          {/* {"save"} */}
          {status !== asyncStates.PENDING ? (
            "Save"
          ) : (
            <CircularLoader $color="white" $size={20} />
          )}
        </Button>
      </FlexBox>
    </Box>
  );
};
