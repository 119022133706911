import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as asyncStates from "../../constants/asyncStates";
import {
  createBlockListApiService,
  deleteBlockListApiService,
  getAllBlockListApiService,
  restoreArchivedBlockListApiService,
  updateBlockListApiService,
} from "../../../services/applicationBackendApis/blockList";
import { formateDateToISOString } from "../../../utiles/dateformate";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allBlockList: [],
  singleBlockList: null,
  moreBlockListItemsLeftInPagination: false,
  errorMessages: {
    createBlockListErrorMessage: null,
    deleteBlockListErrorMessage: null,
    getAllBlockListErrorMessage: null,
    getSingleBlockListErrorMessage: null,
  },
  statuses: {
    createBlockListStatus: null,
    deleteBlockListStatus: null,
    getAllBlockListStatus: null,
    getSingleBlockListStatus: null,
  },
};

//---------------- to get all the blockList from database ------------
export const getAllBlockListReducer = createAsyncThunk(
  "blockList/getAllBlockList",
  async (blockListData) => {
    try {
      const response = await getAllBlockListApiService({
        staffId: blockListData.staffId,
        clientId: blockListData.clientId,
        startAfter: blockListData.startAfter,
        limit: blockListData.limit ?? PAGINATION_LIMITS.BLOCK_LIST,
        isArchived: blockListData.isArchived,
      });
      return {
        data: response.data,
        limit: blockListData.limit ?? PAGINATION_LIMITS.BLOCK_LIST,
        startAfter: blockListData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// ------------create compilance ------------------
export const createBlockListReducer = createAsyncThunk(
  "blockList/createBlockList",
  async (blockListData, thunkApi) => {
    try {
      const response = await createBlockListApiService({
        staffId: blockListData.staffId,
        clientId: blockListData.clientId,
        notes: blockListData.notes,
        reason: blockListData.reason,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------deleting blockList ---------------------
export const deleteBlockListReducer = createAsyncThunk(
  "blockList/deleteBlockList",
  async (blockListData) => {
    try {
      const response = await deleteBlockListApiService({
        staffId: blockListData.staffId,
        clientId: blockListData.clientId,
        blockListId: blockListData.blockListId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const blockListSlice = createSlice({
  name: "blockList",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalBlockListReducer: (state, action) => {
      state.allBlockList = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling get files async calls
    builder.addCase(getAllBlockListReducer.pending, (state, action) => {
      state.statuses.getAllBlockListStatus = asyncStates.PENDING;
      state.errorMessages.getAllBlockListErrorMessage = null;
    });
    builder.addCase(getAllBlockListReducer.rejected, (state, action) => {
      state.statuses.getAllBlockListStatus = asyncStates.FAILURE;
      state.errorMessages.getAllBlockListErrorMessage = action.error?.message;
    });
    builder.addCase(getAllBlockListReducer.fulfilled, (state, action) => {
      state.statuses.getAllBlockListStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllBlockListErrorMessage = null;
      state.allBlockList = action.payload.startAfter ? [...state.allBlockList, ...action.payload.data.blockList] : action.payload.data.blockList;

      if (action.payload.data.blockList.length >= action.payload.limit) {
        state.moreBlockListItemsLeftInPagination = true;
      } else {
        state.moreBlockListItemsLeftInPagination = false;
      }
    });

    // start handling create file async calls
    builder.addCase(createBlockListReducer.pending, (state, action) => {
      state.statuses.createBlockListStatus = asyncStates.PENDING;
      state.errorMessages.createBlockListErrorMessage = null;
    });
    builder.addCase(createBlockListReducer.rejected, (state, action) => {
      state.statuses.createBlockListStatus = asyncStates.FAILURE;
      state.errorMessages.createBlockListErrorMessage = action.error?.message;
    });
    builder.addCase(createBlockListReducer.fulfilled, (state, action) => {
      state.statuses.createBlockListStatus = asyncStates.SUCCESS;
      state.errorMessages.createBlockListErrorMessage = null;
    });

    // start handling delete file async calls
    builder.addCase(deleteBlockListReducer.pending, (state, action) => {
      state.statuses.deleteBlockListStatus = asyncStates.PENDING;
      state.errorMessages.deleteBlockListErrorMessage = null;
    });
    builder.addCase(deleteBlockListReducer.rejected, (state, action) => {
      state.statuses.deleteBlockListStatus = asyncStates.FAILURE;
      state.errorMessages.deleteBlockListErrorMessage = action.error?.message;
    });
    builder.addCase(deleteBlockListReducer.fulfilled, (state, action) => {
      state.statuses.deleteBlockListStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteBlockListErrorMessage = null;
    });
  },
});

export const {
  clearAllLocalBlockListReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
} = blockListSlice.actions;

export default blockListSlice.reducer;
