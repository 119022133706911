import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
    createPolicyApiService,
    deletePolicyApiService,
    getPoliciesApiService,
    restoreArchivedPolicyApiService,
    updatePolicyApiService
} from "../../../services/applicationBackendApis/policy"
import * as asyncStates from "../../constants/asyncStates"
import { PAGINATION_LIMITS } from "../../../constants"

const initialState = {
    policies: [],
    policy: null,
    morePoliciesLeftInPagination: false,
    policyUploadPercentage: null,
    errorMessages: {
        restorePolicyErrorMessage: null,
        createPolicyErrorMessage: null,
        deletePolicyErrorMessage: null,
        updatePolicyErrorMessage: null,
        getPoliciesErrorMessage: null,
        getPolicyErrorMessage: null,
    },
    statuses: {
        restorePolicyStatus: null,
        createPolicyStatus: null,
        deletePolicyStatus: null,
        updatePolicyStatus: null,
        getPoliciesStatus: null,
        getPolicyStatus: null,
    },
}

export const createPolicyReducer = createAsyncThunk(
    'policies/createPolicy',
    async (policyData, thunkApi) => {
        const { dispatch } = thunkApi
        try {
            const response = await createPolicyApiService({
                staffId: policyData.staffId,
                clientId: policyData.clientId,
                name: policyData.name,
                notes: policyData.notes,
                policyUrl: policyData.policyUrl,
                policyFile: policyData.policyFile,
                adminSignature: policyData.adminSignature,
                assignedSignature: policyData.assignedSignature,
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    if (percent < 100) {
                        dispatch(policyUploadPercentageUpdatedReducer(percent))
                    } else {
                        dispatch(policyUploadPercentageUpdatedReducer(null))
                    }
                },
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const updatePolicyReducer = createAsyncThunk(
    'policies/updatePolicy',
    async (policyData) => {
        try {
            const response = await updatePolicyApiService({
                staffId: policyData.staffId,
                clientId: policyData.clientId,
                policyId: policyData.policyId,
                name: policyData.name,
                notes: policyData.notes,
                adminSignature: policyData.adminSignature,
                assignedSignature: policyData.assignedSignature,
                onUploadProgress: policyData.onUploadProgress,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const restoreArchivedPolicyReducer = createAsyncThunk(
    "policies/restoreArchivedPolicy",
    async (policyData) => {
        try {
            const response = await restoreArchivedPolicyApiService({
                staffId: policyData.staffId,
                clientId: policyData.clientId,
                id: policyData.policyId,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const getPoliciesReducer = createAsyncThunk(
    'policies/getPolicies',
    async (policyData) => {
        try {
            const response = await getPoliciesApiService({
                staffId: policyData.staffId,
                clientId: policyData.clientId,
                startAfter: policyData.startAfter,
                limit: policyData.limit ?? PAGINATION_LIMITS.POLICY,
                isArchived: policyData.isArchived,
            })
            return {
                data: response.data,
                limit: policyData.limit ?? PAGINATION_LIMITS.POLICY,
                startAfter: policyData.startAfter,
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getPolicyReducer = createAsyncThunk(
    'policies/getPolicy',
    async (policyData, thunkApi) => {
        try {
            const { getState } = thunkApi
            const policies = getState()
            const policyFound = policies.find((policy) => policy.id === policyData.policyId)
            if (policyFound && Object.keys(policyFound) !== 0) {
                return policyFound
            } else {
                const response = await getPoliciesApiService({
                    staffId: policyData.staffId,
                    clientId: policyData.clientId,
                    policyId: policyData.policyId,
                })
                return response.data
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const deletePolicyReducer = createAsyncThunk(
    'policies/deletePolicies',
    async (policyData) => {
        try {
            const response = await deletePolicyApiService({
                staffId: policyData.staffId,
                clientId: policyData.clientId,
                policyId: policyData.policyId,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

const policiesSlice = createSlice({
    name: "policies",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        policyUploadPercentageUpdatedReducer: (state, action) => {
            state.policyUploadPercentage = action.payload
        },
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {}
        },
        clearAllLocalPoliciesReducer: (state, action) => {
            state.policies = []
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {}
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {

        // start handling create policy async calls
        builder.addCase(createPolicyReducer.pending, (state, action) => {
            state.statuses.createPolicyStatus = asyncStates.PENDING
            state.errorMessages.createPolicyErrorMessage = null
        })
        builder.addCase(createPolicyReducer.rejected, (state, action) => {
            state.statuses.createPolicyStatus = asyncStates.FAILURE
            state.errorMessages.createPolicyErrorMessage = action.error?.message
        })
        builder.addCase(createPolicyReducer.fulfilled, (state, action) => {
            state.statuses.createPolicyStatus = asyncStates.SUCCESS
            state.errorMessages.createPolicyErrorMessage = null
        })

        builder.addCase(restoreArchivedPolicyReducer.pending, (state, action) => {
            state.statuses.restorePolicyStatus = asyncStates.PENDING;
            state.errorMessages.restorePolicyErrorMessage = null;
        });
        builder.addCase(restoreArchivedPolicyReducer.rejected, (state, action) => {
            state.statuses.restorePolicyStatus = asyncStates.FAILURE;
            state.errorMessages.restorePolicyErrorMessage =
                action.error?.message;
        });
        builder.addCase(restoreArchivedPolicyReducer.fulfilled, (state, action) => {
            state.statuses.restorePolicyStatus = asyncStates.SUCCESS;
            state.errorMessages.restorePolicyErrorMessage = null;
        });


        // start handling update policy async calls
        builder.addCase(updatePolicyReducer.pending, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.PENDING
            state.errorMessages.updatePolicyErrorMessage = null
        })
        builder.addCase(updatePolicyReducer.rejected, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.FAILURE
            state.errorMessages.updatePolicyErrorMessage = action.error?.message
        })
        builder.addCase(updatePolicyReducer.fulfilled, (state, action) => {
            state.statuses.updatePolicyStatus = asyncStates.SUCCESS
            state.errorMessages.updatePolicyErrorMessage = null
        })

        // start handling get policies async calls
        builder.addCase(getPoliciesReducer.pending, (state, action) => {
            state.statuses.getPoliciesStatus = asyncStates.PENDING
            state.errorMessages.getPoliciesErrorMessage = null
        })
        builder.addCase(getPoliciesReducer.rejected, (state, action) => {
            state.statuses.getPoliciesStatus = asyncStates.FAILURE
            state.errorMessages.getPoliciesErrorMessage = action.error?.message
        })
        builder.addCase(getPoliciesReducer.fulfilled, (state, action) => {
            state.statuses.getPoliciesStatus = asyncStates.SUCCESS
            state.errorMessages.getPoliciesErrorMessage = null
            state.policies = action.payload.startAfter ? [...state.policies, ...action.payload.data.policies] : action.payload.data.policies;

            if (action.payload.data.policies.length >= action.payload.limit) {
                state.morePoliciesLeftInPagination = true;
            } else {
                state.morePoliciesLeftInPagination = false;
            }
        })

        // start handling get policy async calls
        builder.addCase(getPolicyReducer.pending, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.PENDING
            state.errorMessages.getPolicyErrorMessage = null
        })
        builder.addCase(getPolicyReducer.rejected, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.FAILURE
            state.errorMessages.getPolicyErrorMessage = action.error?.message
        })
        builder.addCase(getPolicyReducer.fulfilled, (state, action) => {
            state.statuses.getPolicyStatus = asyncStates.SUCCESS
            state.errorMessages.getPolicyErrorMessage = null
            state.policy = action.payload
        })

        // start handling delete policy async calls
        builder.addCase(deletePolicyReducer.pending, (state, action) => {
            state.statuses.deletePolicyStatus = asyncStates.PENDING
            state.errorMessages.deletePolicyErrorMessage = null
        })
        builder.addCase(deletePolicyReducer.rejected, (state, action) => {
            state.statuses.deletePolicyStatus = asyncStates.FAILURE
            state.errorMessages.deletePolicyErrorMessage = action.error?.message
        })
        builder.addCase(deletePolicyReducer.fulfilled, (state, action) => {
            state.statuses.deletePolicyStatus = asyncStates.SUCCESS
            state.errorMessages.deletePolicyErrorMessage = null
        })
    }
})

export const { policyUploadPercentageUpdatedReducer, clearAllLocalPoliciesReducer, clearAllErrorMessagesReducer, clearAllStatusesReducer } = policiesSlice.actions

export default policiesSlice.reducer