import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useServicesSettingsController } from '../SettingsControllers/useServicesController'

export default function ServicesSettings() {
  const controller = useServicesSettingsController()
  return (
    <AddConfigsNames {...controller}/>
  )
}
