import React, { useState, useContext } from "react";
import LogonWithName from "../../Images/Logos/gps_care_logo.png";
import DefaultImage from "../Images/index";
import styled from "styled-components";
import { useResponsive } from "../Hooks";
import { color } from "../Theme";
import { Text } from "../Text";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color("primary")};
`;

const LoginWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 10px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PermissionDeniedScreen = ({ title, subtitle, logout }) => {
    const isDesktop = useResponsive({ xs: false, md: true });
    return (
        <Wrapper>
        <LoginWrapper>
          <DefaultImage $width={"20%"} $src={LogonWithName} $objectFit="contain" />
          <Text $fontSize="heading-2" $marginTop="4">{title ? title : "Permission denied"}</Text>
          {subtitle && <Text $fontSize="body" $marginTop="4" $marginBottom="4">{subtitle}</Text>}

          {logout && <Link to="/" onClick={logout}>Logout</Link>}
        </LoginWrapper>
      </Wrapper>
    )
}