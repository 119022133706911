import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
    getStaffDiaryApiService,
    createStaffDiaryEntryApiService,
} from "../../../services/applicationBackendApis/staffDiary"
import * as asyncStates from "../../constants/asyncStates"
import { PAGINATION_LIMITS } from "../../../constants"

const initialState = {
    diaries: [],
    diary: null,
    moreDiaryItemsLeftInPagination: false,
    diaryUploadPercentage: null,
    errorMessages: {
        createDiaryErrorMessage: null,
        deleteDiaryErrorMessage: null,
        updateDiaryErrorMessage: null,
        getDiariesErrorMessage: null,
        getDiaryErrorMessage: null,
    },
    statuses: {
        createDiaryStatus: null,
        deleteDiaryStatus: null,
        updateDiaryStatus: null,
        getDiariesStatus: null,
        getDiaryStatus: null,
    },
}

export const createDiaryReducer = createAsyncThunk(
    'diaries/createDiary',
    async (diaryData, thunkApi) => {
        try {
            const response = await createStaffDiaryEntryApiService({
                staffId: diaryData.staffId,
                notes: diaryData.notes,
                additionalStaff: diaryData.additionalStaff,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getDiariesReducer = createAsyncThunk(
    'diaries/getDiaries',
    async (diaryData) => {
        try {
            const response = await getStaffDiaryApiService ({
                staffId: diaryData.staffId,
                startAfter: diaryData.startAfter,
                limit: diaryData.limit ?? PAGINATION_LIMITS.STAFF_DIARY,
            })
            return {
                data: response.data,
                limit: diaryData.limit ?? PAGINATION_LIMITS.STAFF_DIARY,
                startAfter: diaryData.startAfter,
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

const diariesSlice = createSlice({
    name: "diaries",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {}
        },
        clearAllLocalDiariesReducer: (state, action) => {
            state.diaries = []
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {}
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {

        // start handling create diary async calls
        builder.addCase(createDiaryReducer.pending, (state, action) => {
            state.statuses.createDiaryStatus = asyncStates.PENDING
            state.errorMessages.createDiaryErrorMessage = null
        })
        builder.addCase(createDiaryReducer.rejected, (state, action) => {
            state.statuses.createDiaryStatus = asyncStates.FAILURE
            state.errorMessages.createDiaryErrorMessage = action.error?.message
        })
        builder.addCase(createDiaryReducer.fulfilled, (state, action) => {
            state.statuses.createDiaryStatus = asyncStates.SUCCESS
            state.errorMessages.createDiaryErrorMessage = null
        })

        // start handling get diaries async calls
        builder.addCase(getDiariesReducer.pending, (state, action) => {
            state.statuses.getDiariesStatus = asyncStates.PENDING
            state.errorMessages.getDiariesErrorMessage = null
        })
        builder.addCase(getDiariesReducer.rejected, (state, action) => {
            state.statuses.getDiariesStatus = asyncStates.FAILURE
            state.errorMessages.getDiariesErrorMessage = action.error?.message
        })
        builder.addCase(getDiariesReducer.fulfilled, (state, action) => {
            state.statuses.getDiariesStatus = asyncStates.SUCCESS
            state.errorMessages.getDiariesErrorMessage = null
            state.diaries = action.payload.startAfter ? [...state.diaries, ...action.payload.data.diaries] : action.payload.data.diaries;

            if (action.payload.data.diaries.length >= action.payload.limit) {
              state.moreDiaryItemsLeftInPagination = true;
            } else {
              state.moreDiaryItemsLeftInPagination = false;
            }
        })
    }
})

export const { clearAllLocalDiariesReducer, clearAllErrorMessagesReducer, clearAllStatusesReducer } = diariesSlice.actions

export default diariesSlice.reducer