import ApiService from "../../restapi";

//Getting all business accounts
export const getAllBusinessAccountsApiService = async () => {
  return await ApiService.sendRequest({
    url: `/businessAccounts`,
    query: {},
    method: "GET",
    headers: {},
  })
}

//Get single business account
export const getBusinessAccountApiService = async ({
  id,
}) => {
  return await ApiService.sendRequest({
    url: `/businessAccounts/${id}`,
    query: {},
    method: "GET",
    headers: {},
  })
}

//Edit business account
export const editBusinessAccountApiService = async ({
  id,
  name,
  email,
  timesheetsEmail,
  documentExpiryEmail,
  timesheetsDisclaimer,
  file,
}) => {
  const json = {
    name: name,
    email: email,
    timesheetsEmail: timesheetsEmail,
    documentExpiryEmail: documentExpiryEmail,
    timesheetsDisclaimer: timesheetsDisclaimer,
    file: file,
  }

  let finalData = json

  if (file) {
    let formData = new FormData();
    for (var key in json) {
      if (key == "file") {
        formData.append("file", json[key]);
      } else if (json[key] != null) {
        formData.append(key, typeof json[key] == "object" ? JSON.stringify(json[key]) : json[key]);
      }
    }
    finalData = formData
  }
  return await ApiService.sendRequest({
    url: `/businessAccounts/${id}`,
    query: {},
    method: "PUT",
    headers: {
      "Content-Type": file ? "multipart/form-data" : undefined,
    },
    body: finalData,
  })
}