import React, { useState, useMemo, useEffect } from 'react'
import { Box, FlexBox } from '../../../../../../Components/Boxes'
import CalendarControlls from '../../../../../../Components/Calendar/ScheduleCalendar/CalendarControlls'
import { REPETITION_PERIODS } from '../../../../../../constants'
import { getAllDatesInAMonth } from "../../../../../../utiles/dateformate"
import EMARComponent from './EMARComponent'
import useEMARDispatchers from '../../../../../../redux/dispatchers/useEMAR'
import { useAllEMARsSelector, useMoreEMARsLeftInPaginationSelector } from "../../../../../../redux/selectors/useEMARSelectors"
import { Button } from '../../../../../../Components/Input'

export default function EMARChart({ clientId }) {
  const [selectedDate, setSelectedDate] = useState(new Date())

  const allDates = useMemo(() => getAllDatesInAMonth(selectedDate), [selectedDate])

  const { getAllEMARs } = useEMARDispatchers()

  const eMARs = useAllEMARsSelector()
  const moreEMARsLeftInPagination = useMoreEMARsLeftInPaginationSelector()

  const loadMore = () => {
    getAllEMARs({
      clientId: clientId,
      monthBeginningDate: selectedDate,
      startAfter: eMARs[eMARs.length - 1].id,
    });
  }

  useEffect(() => {
    getAllEMARs({
      clientId: clientId,
      monthBeginningDate: selectedDate
    })
  }, [selectedDate])

  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <CalendarControlls
        date={selectedDate}
        onPrev={() => {
          const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
          newDate.setMonth(newDate.getMonth() - 1)
          setSelectedDate(newDate)
        }}
        onNext={() => {
          const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
          newDate.setMonth(newDate.getMonth() + 1)
          setSelectedDate(newDate)
        }}
        currentView={REPETITION_PERIODS.MONTH}
        availableViews={[REPETITION_PERIODS.MONTH]}
      />

      <Box $width="100%" $horizontalScroll $showScrollBar>
        <EMARComponent
          isHeader
          selectedDate={selectedDate}
          dateRanges={allDates}
        />
        {eMARs?.map(eMAR => {
          return (
            <EMARComponent
              selectedDate={selectedDate}
              dateRanges={allDates}
              eMAR={eMAR}
            />
          )
        })}
        {moreEMARsLeftInPagination && <FlexBox $justifyContent="center" $marginTop="6">
          <Button $onClick={() => {
            loadMore()
          }} >Load More</Button>
        </FlexBox>}
      </Box>
    </Box>
  )
}
