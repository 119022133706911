import React, { useState } from 'react'
import { Button, Textarea } from '../../../Components/Input'
import { MultiSearchUserSelectBox } from '../../components/searchSelectBox'
import { Grid, GridContainer } from '../../../Components/Grid'
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes'
import CircularLoader from '../../../Components/Icons/circularLoader'

export default function AddDiaryNote(props) {

    const { onCancel, onSubmit, loading, errorMessage } = props

    const [users, setUsers] = useState([])
    const [note, setNote] = useState("")

    return (
        <>
            <GridContainer $spacing={2}>
                <Grid $xs={12}>
                    <Textarea
                        $hint={"Notes..."}
                        $name={"notes"}
                        $value={note}
                        $rows={5}
                        $onChange={(e) => {
                            setNote(e.target.value)
                        }}
                    />
                </Grid>
                <Grid $xs={12}>
                    <MultiSearchUserSelectBox
                        searchByStaff
                        selected={users}
                        onChange={(e) => {
                            setUsers(e)
                        }}
                    />
                </Grid>
                <Grid $xs={12}>
                    {errorMessage && <FlexBox $marginTop={2} $width="100%">
                        <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                    </FlexBox>}
                </Grid>
                <Grid $xs={6}></Grid>
                <Grid $xs={3}>
                    {!loading && <Button $variant={"outlined"} $onClick={onCancel} $width={"100%"}>Cancel</Button>}
                </Grid>
                <Grid $xs={3}>
                    <Button
                        $onClick={() => {
                            onSubmit({
                                note: note,
                                users: users?.map(u => u.id) ?? [],
                            })
                        }}
                        $width={"100%"}>
                        {loading ? <CircularLoader $color={"white"} $size={25} /> : "Submit"}
                    </Button>
                </Grid>
            </GridContainer>

            <Box $marginTop={5} />
        </>
    )
}
