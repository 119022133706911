import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useEventCheckListsSettingsController } from '../SettingsControllers/useEventCheckListsController'

export default function EventCheckListsSettings() {
  const controller = useEventCheckListsSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
