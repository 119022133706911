import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  ErrorAndSuccess,
  FlexBox,
} from "../../../../../../Components/Boxes";
import { Button, TextBox } from "../../../../../../Components/Input";
import { Text } from "../../../../../../Components/Text";
import useRiskAssessmentDispatchers from "../../../../../../redux/dispatchers/useRiskAssessmentDispatchers";
import {
  useRiskAssessmentDeleteErrorMessageSelector,
  useRiskAssessmentDeleteStatusSelector,
  useRiskAssessmentRestoreErrorMessageSelector,
  useRiskAssessmentRestoreStatusSelector,
  useRiskAssessmentUpdateErrorMessageSelector,
  useRiskAssessmentUpdateStatusSelector,
  useRiskAssessmentUpdateSuccessSelector,
} from "../../../../../../redux/selectors/useRiskAssessmentSlector";
import { formateDateToDateTextboxFormat } from "../../../../../../utiles/dateformate";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";
import { SearchUserSelectBox } from "../../../../../components/searchSelectBox";
import { DOCUMENT_STATUS } from "../../../../../../constants";

export const EditRiskAssessment = (props) => {
  // --------------- context Api -----------------------
  const { clientId } = props

  // --------------- states ----------------------------
  const riskAssessmentToEdit = useMemo(() => props.$riskAssessmentToEdit, []);
  const [assessmentDate, setAssessmentDate] = useState(
    riskAssessmentToEdit ? riskAssessmentToEdit.assessmentDate : ""
  );
  const [nextReviewDate, setNextReviewDate] = useState(
    riskAssessmentToEdit ? riskAssessmentToEdit.nextReviewDate : ""
  );

  const [carriedOutBy, setCarriedOutBy] = useState(
    riskAssessmentToEdit ? riskAssessmentToEdit.carriedOutBy : ""
  )

  const [dateChangeForUpdateTracker, setDateChangeForUpdateTracker] = useState(null);

  // ---------------- selectors ------------------------
  const riskAssessmentDeleteErrorMessage = useRiskAssessmentDeleteErrorMessageSelector();
  const riskAssessmentUpdateErrorMessage = useRiskAssessmentUpdateErrorMessageSelector();
  const riskAssessmentRestoreErrorMessage = useRiskAssessmentRestoreErrorMessageSelector()

  const riskAssessmentDeleteStatus = useRiskAssessmentDeleteStatusSelector();
  const riskAssessmentUpdateStatus = useRiskAssessmentUpdateStatusSelector();
  const riskAssessmentRestoreStatus = useRiskAssessmentRestoreStatusSelector();

  const riskAssessmentUpdateSuccessMessage = useRiskAssessmentUpdateSuccessSelector();

  const errorMessage = useMemo(() => {
    return riskAssessmentDeleteErrorMessage || riskAssessmentUpdateErrorMessage || riskAssessmentRestoreErrorMessage;
  }, [riskAssessmentDeleteErrorMessage, riskAssessmentUpdateErrorMessage, riskAssessmentRestoreErrorMessage]);

  const status = useMemo(() => {
    return riskAssessmentDeleteStatus || riskAssessmentUpdateStatus;
  }, [riskAssessmentDeleteStatus, riskAssessmentUpdateStatus]);

  // --------------- dispatchers -----------------------
  const {
    restoreArchivedRiskAssessment,
    getRiskAssessments,
    updateRiskAssessment,
    deleteRiskAssessment,
    clearAllErrorMessages,
    clearAllSuccessMessages,
  } = useRiskAssessmentDispatchers();

  // --------------- functions ------------------------
  const onUpdateRiskAssessmentClick = async () => {
    await updateRiskAssessment({
      assessmentDate: assessmentDate,
      nextReviewDate: nextReviewDate,
      carriedOutBy: carriedOutBy?.id,
      riskAssessmentId: riskAssessmentToEdit.id,
      clientId: clientId,
    }).unwrap();
    getRiskAssessments({
      clientId: clientId,
    });
    // return props.$handleDialog();
    setDateChangeForUpdateTracker(null);
  };

  const onDeleteRiskAssessmentClick = async () => {
    await deleteRiskAssessment({
      riskAssessmentId: riskAssessmentToEdit.id,
      clientId: clientId,
    }).unwrap();
    getRiskAssessments({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const restoreRiskAssessmentClick = async () => {
    await restoreArchivedRiskAssessment({
      riskAssessmentId: riskAssessmentToEdit.id,
      clientId: clientId,
    }).unwrap();
    getRiskAssessments({
      clientId: clientId,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  // --------------- usefect -----------------------
  useEffect(() => {
    clearAllErrorMessages();
    clearAllSuccessMessages();
    setDateChangeForUpdateTracker(null);
  }, []);
  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <FlexBox $width="100%" $justifyContent="space-between">
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Risk Assessment
            </Text>
            <Text $fontSize="body" $marginBottom={1} $color="black">
              {riskAssessmentToEdit.name}
            </Text>
          </Box>
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Risk rating
            </Text>
            <Box
              $backgroundColor={
                riskAssessmentToEdit.riskRating === "HIGH"
                  ? "red"
                  : riskAssessmentToEdit.riskRating === "MED"
                    ? "yellow"
                    : riskAssessmentToEdit.riskRating === "LOW"
                      ? "green"
                      : "black"
              }
              $width="27%"
              $borderRadius={7}
            >
              <Text
                $fontSize="body"
                $marginBottom={1}
                $fontWeight={500}
                $padding={1}
                $color={
                  riskAssessmentToEdit.riskRating === "HIGH"
                    ? "White"
                    : riskAssessmentToEdit.riskRating === "MED"
                      ? "black"
                      : riskAssessmentToEdit.riskRating === "LOW"
                        ? "white"
                        : "White"
                }
              >
                {riskAssessmentToEdit.riskRating}
              </Text>
            </Box>
          </Box>
        </FlexBox>
        {riskAssessmentToEdit?.assessments.map((val, index) => {
          return (
            <Box key={index} $width="100%">
              <br />
              <Text $fontSize="body">
                {val.question}
              </Text>
              <Text $fontSize="body" $color="black">
                {val.answer}
              </Text>
            </Box>
          );
        })}
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Carried out by
        </Text>
        <SearchUserSelectBox
          searchByStaff
          selected={carriedOutBy}
          onChange={(user) => {
            setCarriedOutBy(user)
            setDateChangeForUpdateTracker("changed");
          }}
        />
        <br />
        <FlexBox $width="100%" $justifyContent="space-between">
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Assessment date
            </Text>
            <TextBox
              $type={"date"}
              $name={"assessmentdate"}
              $value={formateDateToDateTextboxFormat(assessmentDate)}
              $onChange={(e) => {
                setAssessmentDate(e.target.value);
                setDateChangeForUpdateTracker("changed");
              }}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Box>
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Next review
            </Text>
            <TextBox
              $type={"date"}
              $name={"nextreviewdate"}
              $value={formateDateToDateTextboxFormat(nextReviewDate)}
              $onChange={(e) => {
                setNextReviewDate(e.target.value);
                setDateChangeForUpdateTracker("changed");
              }}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Box>
        </FlexBox>
        {errorMessage && riskAssessmentUpdateStatus === asyncStates.FAILURE && (
          <FlexBox $marginTop={2} $width="100%">
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {dateChangeForUpdateTracker === "You haven't made any changes" && !errorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            <ErrorAndSuccess $type="error">
              <Text>{dateChangeForUpdateTracker}</Text>
            </ErrorAndSuccess>
          </FlexBox>
        )}
        {riskAssessmentUpdateSuccessMessage &&
          riskAssessmentUpdateStatus === asyncStates.SUCCESS && (
            <FlexBox $marginTop={2} $width="100%">
              <ErrorAndSuccess $type="success">
                {riskAssessmentUpdateSuccessMessage}
              </ErrorAndSuccess>
            </FlexBox>
          )}
      </FlexBox>

      <FlexBox
        $justifyContent={riskAssessmentToEdit?.status === DOCUMENT_STATUS.ARCHIVED ? "flex-end" : "space-between"}
        $padding={4}
        $backgroundColor="list"
      >
        {riskAssessmentToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => onDeleteRiskAssessmentClick()}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {riskAssessmentDeleteStatus !== asyncStates.PENDING ? (
              "Delete"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
        {riskAssessmentToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            dateChangeForUpdateTracker === "changed"
              ? onUpdateRiskAssessmentClick()
              : setDateChangeForUpdateTracker("You haven't made any changes");
          }}
        >
          <Text $color={"white"}>
            {riskAssessmentUpdateStatus !== asyncStates.PENDING ? (
              "Save changes"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {riskAssessmentToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restoreRiskAssessmentClick()
          }}
        >
          <Text $color={"white"}>
            {riskAssessmentRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
      </FlexBox>
    </>
  );
};
