import React, { useState } from 'react'
import { Box, FlexBox } from '../../Components/Boxes'
import { Text } from '../../Components/Text'
import ListBox from '../../Components/Boxes/ListBox'
import AddConfigNamesDialog from './Dialogs/AddConfigNamesDialog'
import FormDialog from '../../Components/Modals/FormDialog'
import { useResponsive } from '../../Components/Hooks'
import Icon from '../../Components/Icons'
import { Button } from '../../Components/Input'
import EmptyState from '../../Components/NoRecordFound'
import MuiSkeletonLoader from '../../Components/LoadingSkeleton/MuiSkeletonLoader'

export default function AddConfigsNames(props) {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        canAdd,
        canEdit,

        title,
        names,

        onSave,
        onUpdate,
        onDelete,

        isUpdatingConfig,
        isDeletingConfig,
        isGettingConfigs,

        updateErrorMessage,
        deleteErrorMessage,
        gettingErrorMessage,

        isStaffList,
    } = props

    const [open, setOpen] = useState(false);
    const [editingName, setEditingName] = useState("")
    function handleDialog() {
        setOpen(!open)
    }

    return (
        <Box>
            <Text $fontSize="heading-3">{title}</Text>
            {canAdd && <FlexBox
                $justifyContent="flex-end"
                $width="100%"
                $marginTop={2}
                $marginRight={4}
            >
                <Button
                    $onClick={() => {
                        setEditingName(null)
                        handleDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>Add</Text>
                </Button>
            </FlexBox>}
            <Box $marginTop="2" />
            {!isGettingConfigs && names?.map((value, index) => {
                return (
                    <ListBox
                        $index={index}
                        $onClick={() => {
                            if (canEdit == false) return

                            setEditingName(value)
                            handleDialog()
                        }}
                    >
                        <Text $color="text">{isStaffList ? value?.name ?? "-" : value}</Text>
                    </ListBox>
                )
            })}

            {isGettingConfigs && <FlexBox $width="100%" $marginTop={2} $flexDirection="column">
                {[1, 2, 3].map((val, index) => {
                    return (
                        <Box
                            $marginBottom="2"
                            $width="100%"
                        >
                            <MuiSkeletonLoader
                                $variant="rectangular"
                                $width={"100%"}
                                $height={"40px"}
                            />
                        </Box>
                    );
                })}
            </FlexBox>}

            {!isGettingConfigs && names.length <= 0 && <FlexBox $marginTop={7} $justifyContent="center">
                <EmptyState />
            </FlexBox>}

            {open && <FormDialog
                $open={open}
                $handleDialog={handleDialog}
                $title={`Add ${title}`}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <AddConfigNamesDialog
                    isStaffList={isStaffList}
                    name={editingName}
                    loading={isUpdatingConfig || isDeletingConfig}
                    onDelete={async () => {
                        await onDelete(editingName)
                        handleDialog()
                    }}
                    onSave={async (val) => {
                        await onSave(val)
                        handleDialog()
                    }}
                    onUpdate={async (val) => {
                        await onUpdate(editingName, val)
                        handleDialog()
                    }}
                    handleDialog={handleDialog}
                    errorMessage={updateErrorMessage || deleteErrorMessage}
                />
            </FormDialog>}
        </Box>
    )
}
