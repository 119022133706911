import React, { useContext, useState } from "react";
import {
  Box,
  ErrorAndSuccess,
  FlexBox,
} from "../../../../../../Components/Boxes";
import Icon from "../../../../../../Components/Icons";
import { Button, SelectBox, TextBox, Textarea } from "../../../../../../Components/Input";
import { Text } from "../../../../../../Components/Text";
import useRiskAssessmentDispatchers from "../../../../../../redux/dispatchers/useRiskAssessmentDispatchers";
import {
  useRiskAssessmentCreateErrorMessageSelector,
  useRiskAssessmentCreateStatusSelector,
} from "../../../../../../redux/selectors/useRiskAssessmentSlector";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";
import { useMemo } from "react";
import { useEffect } from "react";
import { SearchUserSelectBox } from "../../../../../components/searchSelectBox";
import { useGlobalRiskassessmentsSelector } from "../../../../../../redux/selectors/useConfigsSelectors";

export const NewVersion = (props) => {
  //------------------------ context Api ------------------------
  const { clientId } = props

  //------------------------ dispatchers ------------------------
  const { createRiskAssessment, clearAllErrorMessages, getRiskAssessments } =
    useRiskAssessmentDispatchers();

  //------------------------ Selectors ------------------------
  const riskAssessmentCreateStatus = useRiskAssessmentCreateStatusSelector();
  const riskAssessmentCreateErrorMessage =
    useRiskAssessmentCreateErrorMessageSelector();

  //------------------- Error messages and selectors -------------
  const errorMessage = useMemo(() => {
    return riskAssessmentCreateErrorMessage;
  }, [riskAssessmentCreateErrorMessage]);

  //------------------------ States ---------------------------
  const [riskassessmentName, setRiskassessmentname] = useState();
  const [riskRating, setRiskrating] = useState("HIGH");
  const [assessmentDate, setAssessmentDate] = useState("");
  const [nextReviewDate, setNextReviewDate] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [carriedOutBy, setCarriedOutBy] = useState()

  const globalRiskassessmentNames = useGlobalRiskassessmentsSelector()
  const riskassessmentNames = useMemo(() => {
    return globalRiskassessmentNames?.map(name => {
      return { value: name, label: name }
    })
  }, [globalRiskassessmentNames])

  //------------- functions -------------------------------
  const onCreateRiskAssessmentClicked = async () => {
    await createRiskAssessment({
      name: riskassessmentName,
      riskRating: riskRating,
      assessments: [
        { question: "Who might be harmed and how?", answer: answer1 },
        {
          question: "What are you doing to control the risk?",
          answer: answer2,
        },
        {
          question:
            "What further action do you need to take to control the risk",
          answer: answer3,
        },
        { question: "Who needs to carry out the action", answer: answer4 },
        { question: "When is the action needed by", answer: answer5 },
      ],
      assessmentDate: assessmentDate,
      nextReviewDate: nextReviewDate,
      carriedOutBy: carriedOutBy?.id,
      clientId: clientId,
    }).unwrap();
    getRiskAssessments({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  //----------------------- clearing all error messages whenever the components render ----------------
  useEffect(() => {
    clearAllErrorMessages();
  }, []);

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1}>
          Risk assessment name
        </Text>
        <SelectBox
          $hint="Level of Understanding"
          $name={"name"}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $value={riskassessmentName ? { value: riskassessmentName, label: riskassessmentName } : null}
          $onChange={(e) => {
            setRiskassessmentname(e.value)
          }}
          $options={riskassessmentNames/*[
            { value: "Trip and Slip", label: "Trip and Slip" },
            { value: "Room cleaness", label: "Room cleaness" },
            { value: "Morning call Support", label: "Morning call Support" },
            { value: "Local Call Support", label: "Local Call Support" },
          ]*/}
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Risk rating
        </Text>
        <SelectBox
          $name={"riskRating"}
          $onChange={(e) => setRiskrating(e.value)}
          $width="30%"
          $options={[
            { value: "HIGH", label: "High" },
            { value: "MED", label: "Medium" },
            { value: "MED", label: "Low" },
          ]}
        />

        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Who might be harmed and how?",
        </Text>
        <Textarea
          $hint="Type..."
          $rows={5}
          $type={"text"}
          $name={"answer1"}
          $value={answer1}
          $onChange={(e) => setAnswer1(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          What are you doing to control the risk?
        </Text>
        <Textarea
          $hint="Type..."
          $rows={5}
          $type={"text"}
          $name={"answer2"}
          $value={answer2}
          $onChange={(e) => setAnswer2(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          What further action do you need to take to control the risk
        </Text>
        <Textarea
          $hint="Type..."
          $rows={5}
          $type={"text"}
          $name={"answer3"}
          $value={answer3}
          $onChange={(e) => setAnswer3(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Who needs to carry out the action"
        </Text>
        <Textarea
          $hint="Type..."
          $rows={5}
          $type={"text"}
          $name={"answer4"}
          $value={answer4}
          $onChange={(e) => setAnswer4(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          When is the action needed by
        </Text>
        <Textarea
          $hint="Type..."
          $rows={5}
          $type={"text"}
          $name={"answer5"}
          $value={answer5}
          $onChange={(e) => setAnswer5(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />

        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Carried out by
        </Text>
        <SearchUserSelectBox
          searchByStaff
          selected={carriedOutBy}
          onChange={(user) => {
            setCarriedOutBy(user)
          }}
        />
        <br />
        <FlexBox $width="100%" $justifyContent="space-between">
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Assessment date
            </Text>
            <TextBox
              $type={"date"}
              $name={"assessmentDate"}
              $value={assessmentDate}
              $onChange={(e) => setAssessmentDate(e.target.value)}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Box>
          <Box $width="35%">
            <Text $fontSize="body" $marginBottom={1}>
              Next review
            </Text>
            <TextBox
              $type={"date"}
              $name={"nextreviewDate"}
              $value={nextReviewDate}
              $onChange={(e) => setNextReviewDate(e.target.value)}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Box>
        </FlexBox>
        {errorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox $justifyContent="flex-end" $padding={4} $backgroundColor="list">
        <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => onCreateRiskAssessmentClicked()}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {riskAssessmentCreateStatus !== asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>
      </FlexBox>
    </>
  );
};
