import React from "react";
import {Box, FlexBox} from "../../../Components/Boxes";
import {Text} from "../../../Components/Text";
import {color, eventsPickableColors} from "../../../Components/Theme";
import {DropDownButton} from "../../../Components/Input";
import {DropDownMenuItem} from "../../../Components/Modals";

const Customization = (props) => {
    const {
        eventColor,
        onEventColorChanged,
    } = props

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
            >
                <FlexBox>
                    <Text $fontSize="body" $marginBottom={1}>
                        Shift schedule color
                    </Text>

                    <Box $marginRight={"3"}/>

                    <DropDownButton
                        $sx={{borderRadius: 2}}
                        $contentPadding={"0px"}
                        $title={eventColor ? <Box
                            $width={50}
                            $height={25}
                            $backgroundColor={eventColor}
                        >

                        </Box> : "Select color"}
                    >
                        {eventsPickableColors.map(color => {
                            return (
                                <DropDownMenuItem
                                    $onClick={() => {
                                        onEventColorChanged(color)
                                    }}
                                >
                                    <Box
                                        $marginRight={2}
                                        $width={50}
                                        $height={25}
                                        $backgroundColor={color}
                                    >

                                    </Box>
                                </DropDownMenuItem>
                            )
                        })}

                        <DropDownMenuItem
                            $onClick={() => {
                                onEventColorChanged("")
                            }}
                        >
                            <Box
                                $marginRight={2}
                                $border={`1px solid ${color('text')}`}
                                $borderRadius={10}
                                $padding={'2'}
                            >
                                <Text>Default</Text>
                            </Box>
                        </DropDownMenuItem>
                    </DropDownButton>
                </FlexBox>

                <Box $marginTop={3} $padding={2} $backgroundColor="note" $borderRadius="5px" $width={"100%"}>
                    <Text $wordWrap="anywhere" $paddingLeft={1} $paddingRight={1}>
                        Please note
                    </Text>
                    <ol>
                        <li>
                            <Text $wordWrap="anywhere" $paddingLeft={1} $paddingRight={1}>
                                The shift color will only show on the admin dashboard
                            </Text>
                        </li>
                        <li>
                            <Text $wordWrap="anywhere" $paddingLeft={1} $paddingRight={1}>
                                Completed/ Missed/ Cancelled shifts will show the default colors on the system
                            </Text>
                        </li>
                        <li>
                            <Text $wordWrap="anywhere" $paddingLeft={1} $paddingRight={1}>
                                To use default color, press the drop down and select 'Default'
                            </Text>
                        </li>
                    </ol>
                </Box>
            </FlexBox>
        </>
    );
};

export default Customization;