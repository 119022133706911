import React, { useMemo } from 'react'
import { getTotalAmountOFObjectsMatchingKey, mapObjectsMatchingKey } from '../../utiles/aggregator'
import { EVENT_STATUS } from '../../constants'
import { Box, CircleBox, FlexBox } from '../../Components/Boxes'
import { Grid, GridContainer } from '../../Components/Grid'
import { Text } from '../../Components/Text'
import { millisecondsToMinutes } from '../../utiles/dateformate'
import { formatteMinutesToReadableFormat } from '../../utiles/timeformate'

const AnalyticElement = ({ iconStyle, title, value, postfix = '' }) => {
    return (
        <Grid $xs={6} $sm={4} $md={3}>
            <FlexBox $alignItems="center">
                <CircleBox $size={16} $backgroundColor={iconStyle.backgroundColor} />
                <Box $marginLeft={2} $marginRight={2} />
                <Text $fontSize="body">
                    {title}: {value}{postfix}
                </Text>
            </FlexBox>
        </Grid>
    )
}

export default function ScheduleAnalytics(props) {
    const {
        events,
        getStyle,
        showCheckedIn,
    } = props

    const analytics = useMemo(() => {
        const totalMinutes = millisecondsToMinutes(mapObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.COMPLETED, 
            item => new Date(item.endDate).getTime() - new Date(item.startDate).getTime())
        .reduce((acc, curr) => acc + curr, 0))
        const totalBreaks = mapObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.COMPLETED, item => item.breakDuration ?? 0).reduce((acc, curr) => acc + curr, 0)
        return {
            totalEvents: events?.length ?? 0,
            activeEvents: getTotalAmountOFObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.ACTIVE),
            cancelledEvents: getTotalAmountOFObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.CANCELLED),
            completedEvents: getTotalAmountOFObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.COMPLETED),
            missedEvents: getTotalAmountOFObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.MISSED),
            checkedInEvents: getTotalAmountOFObjectsMatchingKey(events ?? [], 'eventStatus', EVENT_STATUS.CHECKED_IN),
            totalMinutes: totalMinutes,
            totalMinutesWithoutBreaks: totalMinutes - totalBreaks
        }
    }, [events])

    return (
        <>
            <GridContainer $spacing={3}>
                <Grid $xs={12}>
                    <Text $fontSize="heading-3">
                        Analytics
                    </Text>
                </Grid>
                <AnalyticElement title="Total Events" value={analytics.totalEvents} iconStyle={getStyle(null)} />
                <AnalyticElement title="Active Events" value={analytics.activeEvents} iconStyle={getStyle(EVENT_STATUS.ACTIVE)} />
                <AnalyticElement title="Cancelled Events" value={analytics.cancelledEvents} iconStyle={getStyle(EVENT_STATUS.CANCELLED)} />
                <AnalyticElement title="Completed Events" value={analytics.completedEvents} iconStyle={getStyle(EVENT_STATUS.COMPLETED)} />
                <AnalyticElement title="Missed Events" value={analytics.missedEvents} iconStyle={getStyle(EVENT_STATUS.MISSED)} />
                {showCheckedIn && <AnalyticElement title="Checked In Events" value={analytics.checkedInEvents} iconStyle={getStyle(EVENT_STATUS.CHECKED_IN)} />}
                <Grid $xs={12}>
                    <Text $fontSize="heading-3">
                        Completed Analytics
                    </Text>
                </Grid>
                <AnalyticElement title="Total completed time" value={formatteMinutesToReadableFormat(analytics.totalMinutes)} iconStyle={getStyle(EVENT_STATUS.COMPLETED)} />
                <AnalyticElement title="Total completed time (Without breaks)" value={formatteMinutesToReadableFormat(analytics.totalMinutesWithoutBreaks)} iconStyle={getStyle(EVENT_STATUS.COMPLETED)} />
            </GridContainer>
        </>
    )
}
