import React, { useEffect, useMemo, useState } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../../Components/Boxes";
import { Button, SelectBox, Textarea, TextBox } from "../../../../../../Components/Input";
import { Text } from "../../../../../../Components/Text";
import Icon from "../../../../../../Components/Icons";
import useMedicineDispathers from "../../../../../../redux/dispatchers/useMedicineDispatchers";
import { useParams } from "react-router-dom";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import {
  useMedicineCreateErrorMessageSelector,
  useMedicineCreateStatusSelector,
  useMedicineDeleteErrorMessageSelector,
  useMedicineDeleteStatusSelector,
  useMedicineRestoreErrorMessageSelector,
  useMedicineRestoreStatusSelector,
  useMedicineUpdateErrorMessageSelector,
  useMedicineUpdateStatusSelector,
  useMedicineUploadPercentageSelector,
} from "../../../../../../redux/selectors/useMedicinesSelectors";
import LinearWithValueLabel from "../../../../../../Components/Icons/linearLoader";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";
import { formateDateToReadableFormatWithoutTime, formateDateToDateTextboxFormat } from "../../../../../../utiles/dateformate";
import { v4 as uuidv4 } from "uuid";
import { DOCUMENT_STATUS, REPETITION_END_DATE_TYPE, REPETITION_PERIODS, REPETITION_TYPE } from "../../../../../../constants";
import { useGlobalMedicinesSelector } from "../../../../../../redux/selectors/useConfigsSelectors";
import Tabs from "../../../../../../Components/Input/Tabs";
import Repetition from "../../../../Events/RepetitionTab";

const EditMedicine = (props) => {
  const { clientId } = props
  const medicineToEdit = useMemo(() => props.$medicine, []);
  const editingMedicine = useMemo(
    () => (medicineToEdit ? true : false),
    []
  );
  const [tabIndex, setTabIndex] = useState(0)

  // --------------- states -----------------------
  const [medicine, setMedicine] = useState(
    medicineToEdit ? medicineToEdit.medicine : ""
  );
  const [startDate, setStartDate] = useState(
    medicineToEdit ? medicineToEdit.startDate : new Date()
  );
  const [endDate, setEndDate] = useState(
    medicineToEdit ? medicineToEdit.endDate : new Date()
  );
  const [repetitionEndDateType, setRepetitionEndDateType] = useState(
    medicineToEdit ? medicineToEdit.repetitionEndDateType : REPETITION_END_DATE_TYPE.NEVER
  );
  const [dosageInstructions, setDosageInstructions] = useState(
    medicineToEdit ? medicineToEdit.dosageInstructions : ""
  );
  const [dosage, setDosage] = useState(
    medicineToEdit ? medicineToEdit.dosage : {
      morning: 0,
      noon: 0,
      afternoon: 0,
      evening: 0,
      night: 0,
    }
  );
  const [repetitionType, setRepetitionType] = useState(medicineToEdit && medicineToEdit?.repetitionType ? medicineToEdit.repetitionType : REPETITION_TYPE.NONE)
  const [days, setDays] = useState(medicineToEdit ? medicineToEdit?.days || [] : [])
  const [repetitionPeriod, setRepetitionPeriod] = useState(medicineToEdit && medicineToEdit?.repetitionPeriod ? medicineToEdit?.repetitionPeriod : { value: 1, every: REPETITION_PERIODS.WEEK })

  const [notes, setNotes] = useState(
    medicineToEdit ? medicineToEdit.notes : ""
  );

  // ----------- getting client id from URL --------
  const params = useParams();

  // -------------- dispatchers -------------------
  const {
    restoreArchivedMedicine,
    createMedicine,
    getAllMedicine,
    deleteMedicine,
    updateMedicine,
    clearAllErrorMessages,
  } = useMedicineDispathers();

  // -------------- selectors ---------------------
  const uploadingPercentage = useMedicineUploadPercentageSelector();
  const medicineCreateStatus = useMedicineCreateStatusSelector();
  const medicineDeleteStatus = useMedicineDeleteStatusSelector();
  const medicineUpdateStatus = useMedicineUpdateStatusSelector();
  const medicineRestoreStatus = useMedicineRestoreStatusSelector();

  const medicineCreateErrorMessage = useMedicineCreateErrorMessageSelector();
  const medicineDeleteErrorMessage = useMedicineDeleteErrorMessageSelector();
  const medicineUpdateErrorMessage = useMedicineUpdateErrorMessageSelector();
  const medicineRestoreErrorMessage = useMedicineRestoreErrorMessageSelector()

  //-------------- useMemo for all errors ------------
  const errorMessage = useMemo(() => {
    return (
      medicineCreateErrorMessage ||
      medicineDeleteErrorMessage ||
      medicineUpdateErrorMessage ||
      medicineRestoreErrorMessage
    );
  }, [
    medicineCreateErrorMessage,
    medicineDeleteErrorMessage,
    medicineUpdateErrorMessage,
    medicineRestoreErrorMessage,
  ]);

  const globalMedicines = useGlobalMedicinesSelector()
  const medicineNames = useMemo(() => {
    return globalMedicines?.map(globalMedicine => {
      return { value: globalMedicine.name, label: globalMedicine.name }
    })
  }, [globalMedicines]);

  const tabOptions = useMemo(() => {
    const s = [
      { name: "General" },
      { name: "Repetition" }
    ]
    return s
  }, [])

  const select = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  const repetitionPeriodOptions = useMemo(() => {
    if (repetitionType == REPETITION_TYPE.NONE) {
      return [REPETITION_PERIODS.WEEK]
    }

    if (repetitionType == REPETITION_TYPE.SELECT_DAYS) {
      return [REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH]
    }

    if (repetitionType == REPETITION_TYPE.CUSTOM) {
      return [REPETITION_PERIODS.DAY, REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH, REPETITION_PERIODS.YEAR]
    }
  }, [repetitionType])

  // -------------- functions ---------------------
  const onCreateMedicineClicked = async () => {
    await createMedicine({
      clientId: clientId,
      medicine: medicine,
      startDate: startDate,
      endDate: endDate,
      repetitionEndDateType: repetitionEndDateType,
      notes: notes,
      dosageInstructions: dosageInstructions,
      dosage: dosage,
      repetitionType: repetitionType,
      days: days,
      repetitionPeriod: repetitionPeriod,
    }).unwrap();
    getAllMedicine({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onDeleteFileClicked = async () => {
    await deleteMedicine({
      clientId: clientId,
      medicineId: medicineToEdit.id,
    }).unwrap();
    getAllMedicine({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onUpdateMedicineClicked = async () => {
    await updateMedicine({
      clientId: clientId,
      medicineId: medicineToEdit.id,
      medicine: medicine,
      startDate: startDate,
      endDate: endDate,
      repetitionEndDateType: repetitionEndDateType,
      notes: notes,
      dosageInstructions: dosageInstructions,
      dosage: dosage,
      repetitionType: repetitionType,
      days: days,
      repetitionPeriod: repetitionPeriod,
    }).unwrap();
    getAllMedicine({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const restoreMedicineClicked = async () => {
    await restoreArchivedMedicine({
      clientId: clientId,
      medicineId: medicineToEdit.id,
    }).unwrap();
    getAllMedicine({
      clientId: clientId,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  // ----------------- useEffect -----------------------
  useEffect(() => {
    clearAllErrorMessages();
  }, []);

  return (
    <>
      <Tabs
        $onChanged={(val) => {
          setTabIndex(val)
        }}
        $value={tabIndex}
        $tabs={tabOptions}
      />

      <Box $marginTop={2} />

      <Box
        // $padding={5}
        // $marginRight={3}
        $width="100%"
      >
        {select === "General" && <Box
          $padding={5}
          $marginRight={3}
        >
          <Text $fontSize="body" $marginBottom={1}>
            Medicine
          </Text>
          <SelectBox
            $hint="Medicine name"
            $value={medicine ? { value: medicine.name, label: medicine.name } : null}
            $name={"medicineName"}
            $onChange={(e) => {
              const index = medicineNames.map(e => e.value).indexOf(e.value)
              if (index < 0) return
              setMedicine(globalMedicines[index])
            }}
            $trailing={<Icon $icon="ArrowDropDown" />}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
            $options={medicineNames}
          />
          <br />

          <Text $fontSize="body" $marginBottom={1}>
            Dosage
          </Text>
          <FlexBox $width="100%" $justifyContent="space-between">
            <Box $width="15%">
              <Text $marginBottom={1}>
                Morning
              </Text>
              <TextBox
                $type={"number"}
                $name={"dosage"}
                $value={dosage.morning}
                $onChange={(e) => setDosage({ ...dosage, morning: Number(e.target.value) })}
                $marginBottom={1}
              />
            </Box>
            <Box $width="15%">
              <Text $marginBottom={1}>
                Noon
              </Text>
              <TextBox
                $type={"number"}
                $name={"dosage"}
                $value={dosage.noon}
                $onChange={(e) => setDosage({ ...dosage, noon: Number(e.target.value) })}
                $marginBottom={1}
              />
            </Box>
            <Box $width="15%">
              <Text $marginBottom={1} >
                Afternnon
              </Text>
              <TextBox
                $type={"number"}
                $name={"dosage"}
                $value={dosage.afternoon}
                $onChange={(e) => setDosage({ ...dosage, afternoon: Number(e.target.value) })}
                $marginBottom={1}
              />
            </Box>
            <Box $width="15%">
              <Text $marginBottom={1} >
                Evening
              </Text>
              <TextBox
                $type={"number"}
                $name={"dosage"}
                $value={dosage.evening}
                $onChange={(e) => setDosage({ ...dosage, evening: Number(e.target.value) })}
                $marginBottom={1}
              />
            </Box>
            <Box $width="15%">
              <Text $marginBottom={1} >
                Night
              </Text>
              <TextBox
                $type={"number"}
                $name={"dosage"}
                $value={dosage.night}
                $onChange={(e) => setDosage({ ...dosage, night: Number(e.target.value) })}
                $marginBottom={1}
              />
            </Box>
          </FlexBox>

          <br />

          <Text $fontSize="body" $marginBottom={1} >
            Dosage instructions
          </Text>
          <Textarea
            $type={"text"}
            $name={"dosageInstructions"}
            $value={dosageInstructions}
            $onChange={(e) => setDosageInstructions(e.target.value)}
            $width="100%"
            $marginBottom={1}
          />
          <br />

          <Text $fontSize="body" $marginBottom={1} >
            Start Date
          </Text>
          <TextBox
            $type={"date"}
            $name={startDate}
            $value={formateDateToDateTextboxFormat(startDate)}
            $onChange={(e) => setStartDate(e.target.value)}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />

          {/*   <br />

          <Text $fontSize="body" $marginBottom={1} >
            End Date
          </Text>
          <TextBox
            $type={"date"}
            $name={"endDate"}
            $value={formateDateToDateTextboxFormat(endDate)}
            $onChange={(e) => setEndDate(e.target.value)}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          /> */}

          <br />

          <Text $fontSize="body" $marginBottom={1} >
            Additional Notes
          </Text>
          <Textarea
            $type={"text"}
            $name={"notes"}
            $value={notes}
            $onChange={(e) => setNotes(e.target.value)}
            $width="100%"
          />

          <br />
        </Box>}

        {select === "Repetition" ? <Repetition
          repetitionType={repetitionType}
          days={days}
          repetitionPeriod={repetitionPeriod}
          repetitionStartDate={startDate}
          repetitionEndDate={endDate}
          repetitionEndDateType={repetitionEndDateType}
          repetitionPeriodOptions={repetitionPeriodOptions}

          onRepetitionTypeChanged={(val) => {
            if (repetitionType == REPETITION_TYPE.NONE) {
              setRepetitionPeriod({ value: 1, every: REPETITION_PERIODS.WEEK })
            }

            if (repetitionType == REPETITION_TYPE.SELECT_DAYS) {
              setRepetitionPeriod({ value: 1, every: REPETITION_PERIODS.WEEK })
            }

            if (repetitionType == REPETITION_TYPE.CUSTOM) {
              setRepetitionPeriod({ value: 1, every: REPETITION_PERIODS.WEEK })
            }

            setRepetitionType(val)
          }}
          onRepetitionEndDateChanged={(val) => {
            setEndDate(new Date(`${val}`))
          }}
          onRepetitionEndDateTypeChanged={(val) => {
            setRepetitionEndDateType(val)
          }}
          onRepetitionStartDateChanged={(val) => {
            setStartDate(new Date(`${val}`))
          }}
          onDaysChanged={(val) => {
            setDays([...val])
          }}
          onRepetitionPeriodChange={(val) => {
            console.log(val)
            setRepetitionPeriod(val)
          }}
        /> : <></>}

        {errorMessage && (
          <FlexBox $width="100%" $marginBottom={1} $marginTop={1}>
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {medicineCreateStatus === asyncStates.PENDING &&
          uploadingPercentage != null && (
            <FlexBox $width="100%" $marginTop={3} $marginBottom={1}>
              <LinearWithValueLabel
                $loadsContinuosly={false}
                value={uploadingPercentage}
              />
            </FlexBox>
          )}
      </Box>

      <FlexBox
        $justifyContent={editingMedicine && medicineToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {editingMedicine && medicineToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (
              medicineUpdateStatus === asyncStates.PENDING ||
              medicineDeleteStatus === asyncStates.PENDING
            )
              return;
            onDeleteFileClicked();
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {medicineDeleteStatus !== asyncStates.PENDING ? (
              "Delete"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>
        }
        {medicineToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (editingMedicine) {
              onUpdateMedicineClicked();
            } else {
              onCreateMedicineClicked();
            }
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {medicineCreateStatus !== asyncStates.PENDING &&
              medicineUpdateStatus !== asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {medicineToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restoreMedicineClicked()
          }}
        >
          <Text $color={"white"}>
            {medicineRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
      </FlexBox>
    </>
  );
};

export default EditMedicine;
