import React, { useEffect, useState, useContext, useMemo } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../Components/Boxes";
import {
  Button,
  CheckBox,
  DragAndDrop,
  SelectBox,
  Textarea,
  TextBox,
} from "../../../Components/Input";
import { Text } from "../../../Components/Text";
import Icon from "../../../Components/Icons";
import Image from "../../../Components/Images"
import LinearWithValueLabel from "../../../Components/Icons/linearLoader";
import usePolicyDispathers from "../../../redux/dispatchers/usePolicyDispatchers";
import {
  usePolicyUpdateErrorMessageSelector,
  usePolicyCreateErrorMessageSelector,
  usePolicyCreateStatusSelector,
  usePolicyDeleteErrorMessageSelector,
  usePolicyDeleteStatusSelector,
  usePolicyUpdateStatusSelector,
  usePolicyUploadPercentageSelector,
  usePolicyRestoreStatusSelector,
  usePolicyRestoreErrorMessageSelector,
} from "../../../redux/selectors/usePoliciesSelectors";
import * as asyncStates from "../../../redux/constants/asyncStates";
import CircularLoader from "../../../Components/Icons/circularLoader";
import { DOCUMENT_STATUS } from "../../../constants";
import { useGlobalPolicySelector } from "../../../redux/selectors/useConfigsSelectors";
import { FormDialog } from "../../../Components/Modals";
import UploadSignature from "../../components/UploadSignature";
import { useResponsive } from "../../../Components/Hooks";
import styled from "styled-components"
import { Grid, GridContainer } from "../../../Components/Grid";
import { PolicyStatus } from "./PolicyStatus";

const SignatureWrapper = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: calc(7/3);
  position: relative;
  cursor: pointer;

  :hover {
    background-color: #888;
  }

  .admin-signature-tool-tip {
    display: none;
    color: #FFFFFF;
    font-weight: bolder;
  }

  :hover .admin-signature-tool-tip {
    display: block;
    position: absolute;
    margin-top: 25%;
    margin-bottom: 25%;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
  }
`

const EditPolicy = (props) => {
  const { staffId, clientId } = props
  const isDesktop = useResponsive({ xs: false, sm: true });
  const policyToEdit = useMemo(() => props.$policy, []);
  const [name, setName] = useState(policyToEdit ? policyToEdit.name : "");
  const [notes, setNotes] = useState(policyToEdit ? policyToEdit.notes : "");
  const [policyFile, setPolicyFile] = useState(null);
  const [policyUrl, setPolicyUrl] = useState(policyToEdit ? policyToEdit.policyUrl : "");
  const [uploadAsUrl, setUploadAsUrl] = useState(false);

  const [adminSignature, setAdminSignature] = useState(null);
  const [assignedSignature, setAssignedSignature] = useState(null);

  const [adminSignatureUrl, setAdminSignatureUrl] = useState(policyToEdit ? policyToEdit.adminSignatureUrl : "");
  const [assignedSignatureUrl, setAssignedSignatureUrl] = useState(policyToEdit ? policyToEdit.assignedSignatureUrl : "");
  const [uploadSignature, setUploadSignature] = useState(null)

  const [policyStatus, setPolicyStatus] = useState(policyToEdit ? policyToEdit.policyStatus : null);

  const editingPolicy = useMemo(() => (policyToEdit ? true : false), []);

  const {
    restoreArchivedPolicy,
    createPolicy,
    updatePolicy,
    deletePolicy,
    getPolicies,
    clearAllErrorMessages,
  } = usePolicyDispathers();

  const policyCreateErrorMessage = usePolicyCreateErrorMessageSelector();
  const policyDeleteErrorMessage = usePolicyDeleteErrorMessageSelector();
  const policyUpdateErrorMessage = usePolicyUpdateErrorMessageSelector();
  const policyRestoreErrorMessage = usePolicyRestoreErrorMessageSelector();

  const errorMessage = useMemo(() => {
    return (
      policyCreateErrorMessage || policyDeleteErrorMessage || policyUpdateErrorMessage || policyRestoreErrorMessage
    );
  }, [policyCreateErrorMessage, policyDeleteErrorMessage, policyUpdateErrorMessage, policyRestoreErrorMessage]);

  const policyCreateStatus = usePolicyCreateStatusSelector();
  const policyDeleteStatus = usePolicyDeleteStatusSelector();
  const policyUpdateStatus = usePolicyUpdateStatusSelector();
  const policyRestoreStatus = usePolicyRestoreStatusSelector()

  const uploadProgressPercentage = usePolicyUploadPercentageSelector();

  const globalPolicyNames = useGlobalPolicySelector()
  const policyNames = useMemo(() => {
    return globalPolicyNames?.map(name => {
      return { value: name, label: name }
    })
  }, [globalPolicyNames]);

  const onDeletePolicyClicked = async () => {
    await deletePolicy({
      staffId: staffId,
      clientId: clientId,
      policyId: policyToEdit.id,
    }).unwrap();
    getPolicies({
      staffId: staffId,
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  /**
   * adminSignature
   * assignedSignature
   */

  const onUpdatePolicyClicked = async () => {
    await updatePolicy({
      staffId: staffId,
      clientId: clientId,
      policyId: policyToEdit.id,
      name: name,
      notes: notes,
      adminSignature: adminSignature,
      assignedSignature: assignedSignature,
    }).unwrap();
    getPolicies({
      staffId: staffId,
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onCreatePolicyClicked = async () => {
    await createPolicy({
      staffId: staffId,
      clientId: clientId,
      policyFile: !uploadAsUrl ? policyFile : null,
      adminSignature: adminSignature,
      assignedSignature: assignedSignature,
      policyUrl: uploadAsUrl ? policyUrl : null,
      name: name,
      notes: notes,
    }).unwrap();
    getPolicies({
      staffId: staffId,
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const restorePolicyClicked = async () => {
    await restoreArchivedPolicy({
      staffId: staffId,
      clientId: clientId,
      policyId: policyToEdit.id,
    }).unwrap();
    getPolicies({
      staffId: staffId,
      clientId: clientId,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  useEffect(() => {
    clearAllErrorMessages();
  }, []);

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >

        {policyStatus && <FlexBox
          $flexDirection="column"
          $alignItems="flex-end"
          $width="100%"
        >
          <PolicyStatus status={policyStatus} />
        </FlexBox>}

        <Text $fontSize="body" $marginBottom={1}>
          Policy name
        </Text>
        <SelectBox
          $hint="Policy name"
          $value={name ? { value: name, label: name } : null}
          $name={"name"}
          $onChange={(e) => setName(e.value)}
          $trailing={<Icon $icon="ArrowDropDown" />}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $options={policyNames}
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Notes
        </Text>
        <Textarea
          $type={"text"}
          $name={""}
          $value={notes}
          $onChange={(e) => setNotes(e.target.value)}
          $width="100%"
          $rows={5}
        />

        <br />

        {!editingPolicy && <Box $width="100%">
          {/* <FlexBox>
            <CheckBox
              $checked={uploadAsUrl}
              $size={"medium"}
              $onChange={(val) => {
                setUploadAsUrl(val.target.checked)
              }}
            />
            <Text
              $color={"text"}
              $fontSize={"body"}
            >
              Upload policy as link?
            </Text>
          </FlexBox>
          <Box $marginTop={2} /> */}
          {uploadAsUrl && <Box $width="100%">
            <Text $fontSize="body" $marginBottom={1}>
              Link to policy
            </Text>
            <TextBox
              $type={"text"}
              $name={""}
              $value={policyUrl}
              $onChange={(e) => setPolicyUrl(e.target.value)}
              $width="100%"
              $rows={5}
              $marginBottom={2}
            />
          </Box>}
          {!uploadAsUrl && <FlexBox $justifyContent="center" $width="100%">
            <DragAndDrop
              $fileTypes={["PDF"]}
              $onFileUpload={(policy) => {
                console.log(policy)
                setPolicyFile(policy);
              }}
            />
          </FlexBox>}
        </Box>}

        <Box $marginTop={3} />

        <GridContainer $spacing={3}>
          {editingPolicy && <Grid $xs={12}>
            <Text $fontWeight="bold" $onClick={(e) => { }}>
              <a href={policyUrl} target="_blank">
                {"View policy"} <Icon $icon="Launch" $color="blue" $size={10} />
              </a>
            </Text>
          </Grid>}
          <Grid $xs={12} $sm={6}>
            <Text $fontSize="body" $marginBottom={1}>
              Admin Signature
            </Text>
            <SignatureWrapper
              onClick={() => {
                setUploadSignature({
                  onFileUpload: (file) => {
                    setAdminSignatureUrl(URL.createObjectURL(file))
                    setAdminSignature(file)
                  }
                })
              }}>
              <div className="admin-signature-tool-tip">Click to add signature</div>
              <Box
                $marginBottom={3}
                $width="100%"
                $height="100%"
                $display="flex"
                $flexDirection="column"
                $alignItems="center"
                $justifyContent="center"
                $border="1px dotted black"
              >
                {adminSignatureUrl && <Image $src={adminSignatureUrl} />}
              </Box>
            </SignatureWrapper>
          </Grid>
          <Grid $xs={12} $sm={6}>
            <Text $fontSize="body" $marginBottom={1}>
              {clientId ? "Client" : "Worker"} Signature
            </Text>
            <SignatureWrapper
              onClick={() => {
                setUploadSignature({
                  onFileUpload: (file) => {
                    setAssignedSignatureUrl(URL.createObjectURL(file))
                    setAssignedSignature(file)
                  }
                })
              }}>
              <div className="admin-signature-tool-tip">Click to add signature</div>
              <Box
                $marginBottom={3}
                $width="100%"
                $height="100%"
                $display="flex"
                $flexDirection="column"
                $alignItems="center"
                $justifyContent="center"
                $border="1px dotted black"
              >
                {assignedSignatureUrl && <Image $src={assignedSignatureUrl} />}
              </Box>
            </SignatureWrapper>
          </Grid>
        </GridContainer>

        {errorMessage && <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>}

        <Box $width="100%" $marginTop="2" $marginBottom="2">
          {policyCreateStatus == asyncStates.PENDING &&
            uploadProgressPercentage != null && (
              <LinearWithValueLabel
                $loadsContinuosly={false}
                value={uploadProgressPercentage}
              />
            )}
        </Box>
      </FlexBox>
      <FlexBox
        $justifyContent={editingPolicy && policyToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {editingPolicy && policyToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && (
          <Button
            $color={"error"}
            $sx={{ borderRadius: 2 }}
            $marginRight={3}
            $onClick={() => {
              if (
                policyUpdateStatus == asyncStates.PENDING ||
                policyDeleteStatus == asyncStates.PENDING
              )
                return;
              onDeletePolicyClicked();
            }}
          >
            <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
              {policyDeleteStatus != asyncStates.PENDING ? (
                "Delete"
              ) : (
                <CircularLoader $color="white" $size={25} />
              )}
            </Text>
          </Button>
        )}

        {policyToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (
              policyUpdateStatus == asyncStates.PENDING ||
              policyDeleteStatus == asyncStates.PENDING
            )
              return;
            if (editingPolicy) {
              onUpdatePolicyClicked();
            } else {
              onCreatePolicyClicked();
            }
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {policyUpdateStatus != asyncStates.PENDING &&
              policyCreateStatus != asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {policyToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restorePolicyClicked()
          }}
        >
          <Text $color={"white"}>
            {policyRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {uploadSignature && <FormDialog
          $open={true}
          $handleDialog={() => {
            setUploadSignature(null)
          }}
          $title={"Image"}
          $maxWidth="sm"
          $fullWidth
          $fullScreen={false}
        >
          <UploadSignature
            $onFileUpload={(file) => {
              uploadSignature.onFileUpload(file)
              setUploadSignature(null)
            }}
          />
        </FormDialog>}
      </FlexBox>
    </>
  );
};

export default EditPolicy;
