import React from "react";
import { Box, FlexBox } from "../../Components/Boxes";
import { Text } from "../../Components/Text";

const StaffAndClientHeader = ({ $type }) => {
  return (
    <FlexBox
      $width="100%"
      $padding="4px"
    >
      <Box $width="15%">
        <Text $fontWeight="bold" $wordWrap="anywhere">
          Name
        </Text>
      </Box>
      <Box $width="30%">
        <Text $fontWeight="bold" $wordWrap="anywhere">
          Email
        </Text>
      </Box>
      <Box $width="35%">
        <Text $fontWeight="bold" $wordWrap="anywhere">
          {$type === "staff" ? "Role" : "Address"}
        </Text>
      </Box>

      <Box $width="20%">
        <Text $fontWeight="bold">Team</Text>
      </Box>
    </FlexBox>
  );
};

export default StaffAndClientHeader;
