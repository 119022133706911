import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  FlexBox,
  ErrorAndSuccess,
  ListBox,
} from "../../../../../Components/Boxes/index";
import Icon from "../../../../../Components/Icons";
import { Button } from "../../../../../Components/Input";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import EditFile from "../Dialogs/EditFile";
import { useResponsive } from "../../../../../Components/Hooks/index";
import { formateDateToReadableFormatWithoutTime } from "../../../../../utiles/dateformate";
import {
  useFilesGetErrorMessageSelector,
  useFilesGetStatusSelector,
  useFilesSelector,
  useMoreFilesLeftInPaginationSelector,
} from "../../../../../redux/selectors/useFilesSelectors";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import useFileDispathers from "../../../../../redux/dispatchers/useFileDispatchers";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import { color } from "../../../../../Components/Theme";
import usePermissionsController from "../../../../../permissions";

const Files = ({ clientId }) => {

  //controlling of opening and closing the dialog
  const [isArchived, setIsArchived] = useState(false)
  const [editFileDialogOpen, setEditFileDialogOpen] = useState(null);
  const [fileToEdit, setFileToEdit] = useState(null);
  function HandleEditFileDialog() {
    setEditFileDialogOpen(null);
    setFileToEdit(null);
  }
  // see whether it's desltop size or not
  const isDesktop = useResponsive({ xs: false, sm: true });
  const {
      accessArchives
  } = usePermissionsController()

  const moreFilesLeftInPagination = useMoreFilesLeftInPaginationSelector()
  const filesGetErrorMessage = useFilesGetErrorMessageSelector();
  const errorMessage = filesGetErrorMessage;

  const filesGetStatus = useFilesGetStatusSelector();
  const files = useFilesSelector();

  const { getFiles, clearAllLocalFiles } = useFileDispathers();

  const loadMore = () => {
    getFiles({
      clientId: clientId,
      startAfter: files[files.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalFiles();
    getFiles({
      clientId: clientId,
      isArchived: isArchived,
    });
  }, [isArchived]);

  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}
        <Button
          $onClick={() => {
            setEditFileDialogOpen(true);
            setFileToEdit(null);
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Files</Text>
        </Button>
      </FlexBox>

      <FlexBox $width="100%" $marginTop={3} $padding="3">
        <Box $width="50%">
          <Text $fontWeight="bold">
            Name
          </Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Added On</Text>
        </Box>
        <Box $width="30%" $display="flex" $justifyContent="center">
          <Text>Share with carers</Text>
        </Box>
      </FlexBox>

      {filesGetStatus === asyncStates.SUCCESS &&
        files.length < 1 ? (
        <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
          <EmptyState />
        </FlexBox>
      ) : (
        files?.map((value, index) => {
          return (
            <ListBox
              $width="100%"
              $marginTop={2}
              key={index}
              $index={index}
              $onClick={() => {
                setEditFileDialogOpen(true);
                setFileToEdit(value);
              }}
            >
              <FlexBox $width="100%" >
                <Box $width="50%">
                  <Text $fontWeight="bold" $onClick={(e) => { }}>
                    <a href={value.fileUrl} target="_blank">
                      {" "}
                      {value.fileName}{" "}
                      <Icon $icon="Launch" $color="blue" $size={10} />
                    </a>
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {formateDateToReadableFormatWithoutTime(value.creationDate)}
                  </Text>
                </Box>
                <Box $width="30%" $display="flex" $justifyContent="center">
                  <Text
                    $fontWeight="bold"
                    $color={value.shareWithCarers ? color("primary") : "red"}
                  >
                    {value.shareWithCarers == true ? "Yes" : "No"}
                  </Text>
                </Box>
              </FlexBox>
            </ListBox>
          );
        })
      )}

      {filesGetStatus == asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <Box $width="100%" $marginBottom="2" key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={40}
              />
            </Box>
          );
        })}

      {errorMessage && (
        <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
      )}


      {moreFilesLeftInPagination && <FlexBox $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      {editFileDialogOpen && (
        <FormDialog
          $open={editFileDialogOpen}
          $handleDialog={HandleEditFileDialog}
          $title={"Edit File"}
          $maxWidth="sm"
          $fullWidth
          $fullScreen={!isDesktop}
        >
          <EditFile $handleDialog={HandleEditFileDialog} $file={fileToEdit} clientId={clientId} />
        </FormDialog>
      )}
    </Box>
  );
};

export default Files;
