import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  getAllBlockListReducer,
  createBlockListReducer,
  deleteBlockListReducer,
  clearAllLocalBlockListReducer,
} from "../../slices/blockListSlice";

const useBlockListDispathers = () => {
  const dispatch = useDispatch();

  const createBlockList = (data) => {
    return dispatch(createBlockListReducer(data));
  };

  const getAllBlockList = (data) => {
    return dispatch(getAllBlockListReducer(data));
  };

  const deleteBlockList = (data) => {
    return dispatch(deleteBlockListReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllLocalBlockList = (data) => {
    return dispatch(clearAllLocalBlockListReducer(data));
  };

  return {
    getAllBlockList,
    createBlockList,
    deleteBlockList,
    clearAllErrorMessages,
    clearAllLocalBlockList,
  };
};

export default useBlockListDispathers;
