import React, { useEffect, useMemo, useState } from "react";
import { ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import { Button, TextBox } from "../../../../../Components/Input";
import { Text } from "../../../../../Components/Text";
import useQualificationDispathers from "../../../../../redux/dispatchers/useQualificationDispatchers";
import { useParams } from "react-router-dom";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import {
    useQualificationCreateErrorMessageSelector,
    useQualificationCreateStatusSelector,
    useQualificationDeleteErrorMessageSelector,
    useQualificationDeleteStatusSelector,
    useQualificationRestoreErrorMessageSelector,
    useQualificationRestoreStatusSelector,
    useQualificationUpdateErrorMessageSelector,
    useQualificationUpdateStatusSelector,
} from "../../../../../redux/selectors/useQualificationsSelectors";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import { formateDateToDateTextboxFormat } from "../../../../../utiles/dateformate";
import { DOCUMENT_STATUS } from "../../../../../constants";

const EditQualifications = ({ $qualification, $staffId, $handleDialog }) => {
    const qualificationToEdit = useMemo(() => $qualification, []);
    const editingQualification = useMemo(() => (qualificationToEdit ? true : false), []);

    // --------------- states -----------------------
    const [name, setName] = useState(
        qualificationToEdit ? qualificationToEdit.name : ""
    );
    const [grade, setGrade] = useState(
        qualificationToEdit ? qualificationToEdit.grade : ""
    );
    const [date, setDate] = useState(
        qualificationToEdit ? qualificationToEdit.date : ""
    );

    // -------------- dispatchers -------------------
    const {
        restoreArchivedQualification,
        createQualification,
        getAllQualification,
        deleteQualification,
        updateQualification,
        clearAllErrorMessages,
    } = useQualificationDispathers();

    // -------------- selectors ---------------------
    const qualificationCreateStatus = useQualificationCreateStatusSelector();
    const qualificationDeleteStatus = useQualificationDeleteStatusSelector();
    const qualificationUpdateStatus = useQualificationUpdateStatusSelector();
    const qualificationRestoreStatus = useQualificationRestoreStatusSelector();

    const qualificationCreateErrorMessage = useQualificationCreateErrorMessageSelector();
    const qualificationDeleteErrorMessage = useQualificationDeleteErrorMessageSelector();
    const qualificationUpdateErrorMessage = useQualificationUpdateErrorMessageSelector();
    const qualificationRestoreErrorMessage = useQualificationRestoreErrorMessageSelector()


    //-------------- useMemo for all errors ------------
    const errorMessage = useMemo(() => {
        return (
            qualificationCreateErrorMessage ||
            qualificationDeleteErrorMessage ||
            qualificationUpdateErrorMessage ||
            qualificationRestoreErrorMessage
        );
    }, [
        qualificationCreateErrorMessage,
        qualificationDeleteErrorMessage,
        qualificationUpdateErrorMessage,
        qualificationRestoreErrorMessage,
    ]);

    // -------------- functions ---------------------

    const onCreateQualificationClicked = async () => {
        await createQualification({
            date: date,
            name: name,
            grade: grade,
            staffId: $staffId,
        }).unwrap();
        getAllQualification({
            staffId: $staffId,
        });
        return $handleDialog();
    };

    const onDeleteFileClicked = async () => {
        await deleteQualification({
            staffId: $staffId,
            qualificationId: qualificationToEdit.id,
        }).unwrap();
        getAllQualification({
            staffId: $staffId,
        });
        return $handleDialog();
    };

    const onUpdateQualificationClicked = async () => {
        await updateQualification({
            staffId: $staffId,
            qualificationId: qualificationToEdit.id,
            date: date,
            name: name,
            grade: grade,
        }).unwrap();
        getAllQualification({
            staffId: $staffId,
        });
        return $handleDialog();
    };

    const restoreQualificationClicked = async () => {
        await restoreArchivedQualification({
            staffId: $staffId,
            qualificationId: qualificationToEdit.id,
        }).unwrap();
        getAllQualification({
            staffId: $staffId,
            isArchived: true,
        });
        return $handleDialog();
    }

    // ----------------- useEffect -----------------------
    useEffect(() => {
        clearAllErrorMessages();
    }, []);

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1}>
                    Qualification name
                </Text>
                <TextBox
                    $type={"text"}
                    $name={name}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                />
                <br />
                <Text $fontSize="body" $marginBottom={1}>
                    Grade
                </Text>
                <TextBox
                    $type={"text"}
                    $name={grade}
                    $value={grade}
                    $onChange={(e) => setGrade(e.target.value)}
                    $width="100%"
                />
                <br />
                <Text $fontSize="body" $marginBottom={1}>
                    Date
                </Text>
                <TextBox
                    $type={"date"}
                    $name={date}
                    $value={formateDateToDateTextboxFormat(date)}
                    $onChange={(e) => setDate(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />

                {errorMessage && (
                    <FlexBox $width="100%" $marginBottom={1} $marginTop={1}>
                        <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                    </FlexBox>
                )}

            </FlexBox>

            <FlexBox
                $justifyContent={editingQualification && qualificationToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
                $padding={4}
                $backgroundColor="list"
            >
                {editingQualification && qualificationToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        if (
                            qualificationUpdateStatus === asyncStates.PENDING ||
                            qualificationDeleteStatus === asyncStates.PENDING
                        )
                            return;
                        onDeleteFileClicked();
                    }}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {qualificationDeleteStatus !== asyncStates.PENDING ? (
                            "Delete"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}

                {qualificationToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        if (editingQualification) {
                            onUpdateQualificationClicked();
                        } else {
                            onCreateQualificationClicked();
                        }
                    }}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {qualificationCreateStatus !== asyncStates.PENDING &&
                            qualificationUpdateStatus !== asyncStates.PENDING ? (
                            "Save"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}

                {qualificationToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        restoreQualificationClicked()
                    }}
                >
                    <Text $color={"white"}>
                        {qualificationRestoreStatus !== asyncStates.PENDING ? (
                            "Restore archive"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}
            </FlexBox>
        </>
    );
};

export default EditQualifications;
