import { useSelector } from "react-redux";

export const useRiskAssessmentsSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.riskAssessments;
  });

// ------------------- selector error messages ------------------ //
export const useRiskAssessmentCreateErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.createRiskAssessmentErrorMessage;
  });

export const useRiskAssessmentDeleteErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.deleteRiskAssessmentErrorMessage;
  });

export const useRiskAssessmentUpdateErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.updateRiskAssessmentErrorMessage;
  });

export const useRiskAssessmentsGetErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.getRiskAssessmentsErrorMessage;
  });

export const useRiskAssessmentGetErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.getRiskAssessmentErrorMessage;
  });

export const useRiskAssessmentRestoreErrorMessageSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.errorMessages.restoreRiskAssessmentErrorMessage;
  });

// ------------ status selectors ------------ //
export const useRiskAssessmentCreateStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.createRiskAssessmentStatus;
  });

export const useRiskAssessmentDeleteStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.deleteRiskAssessmentStatus;
  });

export const useRiskAssessmentUpdateStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.updateRiskAssessmentStatus;
  });

export const useRiskAssessmentsGetStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.getRiskAssessmentsStatus;
  });

export const useRiskAssessmentGetStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.getRiskAssessmentStatus;
  });

export const useRiskAssessmentRestoreStatusSelector = () =>
  useSelector((state) => {
    return state.riskAssessment.statuses.restoreRiskAssessmentStatus;
  });

// ------------ success selectors ------------ //
export const useRiskAssessmentUpdateSuccessSelector = () => useSelector((state) => {
  return state.riskAssessment.sucessMessages.updateRiskAssessmentSuccessMessage
})

export const useMoreRiskAssessmentsLeftInPaginationSelector = () => useSelector((state) => {
  return state.riskAssessment.moreRiskAssessmentsLeftInPagination
})