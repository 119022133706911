const colors = {
    "primary": "#043c54",
    "primary-light": "#D9EDBE",

    "secondary": "#adc245",
    "text": "#053F57", // #697A8D

    "bg-color": "#E6E6E6",

    "list": "#043c5416",

    "note": "#FFFF0026",

    "diary-completed": "#00FF0040",
    "diary-inprogressed": "#5BC0DE40",
    "diary-missed": "repeating-linear-gradient(-45deg, #FF000026, #FF000026 3px, #CCCCCC 3px, #CCCCCC 5px)",
    "diary-late-checkin": "#FFFF0040",

    "event-active": "#043C54",
    "event-cancelled": "#D9534F40",
    "event-completed": "#198754",// "#02AA20",
    "event-checked-in": "#5BC0DE",
    "event-missed": "repeating-linear-gradient(-45deg, #FF000080, #FF000080 3px, #CCCCCC 3px, #CCCCCC 5px)",
    "event-missed-calendar": "#FF000080",
};

export const eventsPickableColors = [
    "#043c54",
    "#3b7691",
    "#00b1ff",
    "#b600ff",
    "#440062",
    "#ff9400",
    "#ff0090",
]

export function color(val) {
    return colors[`${val}`] ?? val;
}

const space = {
    0: "0px",
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "24px",
    6: "32px",
    7: "48px",
    8: "64px",
    9: "128px",
    10: "164px",
    auto: "auto",
};

export function spacing(val) {
    return space[`${val}`] || val;
}

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};

export const sizes = {
    maxPage: 1100,
    maxPageSm: 960,
    maxPageXs: 600,
};

const ratingEmojis = {
    1: "🙁",
    3: "😊",
    5: "🤩",
}

export const ratingEmoji = (rating) => {
    return ratingEmojis[rating]
}