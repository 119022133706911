import React, { useEffect, useMemo, useState } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import { Button, SelectBox, Textarea, TextBox } from "../../../../../Components/Input";
import { SpanText, Text } from "../../../../../Components/Text";
import Icon from "../../../../../Components/Icons";
import useComplianceDispathers from "../../../../../redux/dispatchers/useStaffComplianceDispatcher";
import { useParams } from "react-router-dom";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import {
  useComplianceCreateErrorMessageSelector,
  useComplianceCreateStatusSelector,
  useComplianceDeleteErrorMessageSelector,
  useComplianceDeleteStatusSelector,
  useComplianceRestoreErrorMessageSelector,
  useComplianceRestoreStatusSelector,
  useComplianceUpdateErrorMessageSelector,
  useComplianceUpdateStatusSelector,
  useComplianceUploadPercentageSelector,
} from "../../../../../redux/selectors/useStaffComplianceSelector";
import LinearWithValueLabel from "../../../../../Components/Icons/linearLoader";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import { formateDateToReadableFormatWithoutTime, formateDateToDateTextboxFormat } from "../../../../../utiles/dateformate";
import { v4 as uuidv4 } from "uuid";
import { DOCUMENT_STATUS } from "../../../../../constants";
import { useGlobalCompliancesSelector } from "../../../../../redux/selectors/useConfigsSelectors";
import { GridContainer, Grid } from "../../../../../Components/Grid";

const EditCompliance = (props) => {
  const complianceToEdit = useMemo(() => props.$compliance, []);
  const editingCompliance = useMemo(
    () => (complianceToEdit ? true : false),
    []
  );

  // --------------- states -----------------------
  const [complianceName, setComplianceName] = useState(
    complianceToEdit ? complianceToEdit.complianceName : ""
  );
  const [validFrom, setValidFrom] = useState(
    complianceToEdit ? complianceToEdit.validFrom : null
  );
  const [validTo, setValidTo] = useState(
    complianceToEdit ? complianceToEdit.validTo : null
  );
  const [notes, setNotes] = useState(
    complianceToEdit ? complianceToEdit.notes : ""
  );
  const [files, setFiles] = useState([]);
  const [filesForUpdation, setFilesForUpdation] = useState(
    complianceToEdit ? complianceToEdit.files : []
  );
  const [removedDbFiles, setRemovedDbFiles] = useState([]);
  const [uploadedBy, setUploadedBy] = useState(
    complianceToEdit ? complianceToEdit?.files[0]?.uploadedBy?.name : ""
  );

  // ----------- getting staff id from URL --------
  const params = useParams();

  // -------------- dispatchers -------------------
  const {
    restoreArchivedCompliance,
    createCompliance,
    getAllCompliance,
    deleteCompliance,
    updateCompliance,
    clearAllErrorMessages,
  } = useComplianceDispathers();

  // -------------- selectors ---------------------
  const uploadingPercentage = useComplianceUploadPercentageSelector();
  const complianceCreateStatus = useComplianceCreateStatusSelector();
  const complianceDeleteStatus = useComplianceDeleteStatusSelector();
  const complianceUpdateStatus = useComplianceUpdateStatusSelector();
  const complianceRestoreStatus = useComplianceRestoreStatusSelector();

  const complianceCreateErrorMessage = useComplianceCreateErrorMessageSelector();
  const complianceDeleteErrorMessage = useComplianceDeleteErrorMessageSelector();
  const complianceUpdateErrorMessage = useComplianceUpdateErrorMessageSelector();
  const complianceRestoreErrorMessage = useComplianceRestoreErrorMessageSelector()


  //-------------- useMemo for all errors ------------
  const errorMessage = useMemo(() => {
    return (
      complianceCreateErrorMessage ||
      complianceDeleteErrorMessage ||
      complianceUpdateErrorMessage ||
      complianceRestoreErrorMessage
    );
  }, [
    complianceCreateErrorMessage,
    complianceDeleteErrorMessage,
    complianceUpdateErrorMessage,
    complianceRestoreErrorMessage,
  ]);

  const globalComplianceNames = useGlobalCompliancesSelector()
  const careplanNames = useMemo(() => {
    return globalComplianceNames?.map(name => {
      return { value: name, label: name }
    })
  }, [globalComplianceNames]);

  // -------------- functions ---------------------
  const handleRemoveFile = (fileId) => {
    console.log(fileId);
    let removedFile = filesForUpdation.find((file) => file.id === fileId);
    removedFile = removedFile ? removedFile : null;
    if (removedFile.origin !== "local") {
      setRemovedDbFiles([...removedDbFiles, removedFile.id]);
    }
    setFilesForUpdation(filesForUpdation.filter((file) => file.id !== fileId));
  };

  const onCreateComplianceClicked = async () => {
    await createCompliance({
      complianceName: complianceName,
      validFrom: validFrom,
      validTo: validTo,
      notes: notes,
      files: files,
      staffId: params.staffid,
    }).unwrap();
    getAllCompliance({
      staffId: params.staffid,
    });
    return props.$handleDialog();
  };

  const onDeleteFileClicked = async () => {
    await deleteCompliance({
      staffId: params.staffid,
      complianceId: complianceToEdit.id,
    }).unwrap();
    getAllCompliance({
      staffId: params.staffid,
    });
    return props.$handleDialog();
  };

  const onUpdateComplianceClicked = async () => {
    await updateCompliance({
      staffId: params.staffid,
      complianceId: complianceToEdit.id,
      complianceName: complianceName,
      validFrom: validFrom,
      validTo: validTo,
      notes: notes,
      files: files,
      filesToRemove: removedDbFiles,
    }).unwrap();
    getAllCompliance({
      staffId: params.staffid,
    });
    return props.$handleDialog();
  };

  const restoreComplianceClicked = async () => {
    await restoreArchivedCompliance({
      staffId: params.staffid,
      complianceId: complianceToEdit.id,
    }).unwrap();
    getAllCompliance({
      staffId: params.staffid,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  // ----------------- useEffect -----------------------
  useEffect(() => {
    clearAllErrorMessages();
  }, []);

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1}>
          Compliance name <SpanText $color="red">*</SpanText>
        </Text>
        <SelectBox
          $hint="Compliance name"
          $value={complianceName ? { value: complianceName, label: complianceName } : null}
          $name={"careplanName"}
          $onChange={(e) => setComplianceName(e.value)}
          $trailing={<Icon $icon="ArrowDropDown" />}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $options={careplanNames}
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Note
        </Text>
        <Textarea
          $type={"text"}
          $name={notes}
          $value={notes}
          $onChange={(e) => setNotes(e.target.value)}
          $width="100%"
          $rows={5}
        />
        <br />
        <GridContainer $spacing={3}>
          <Grid $xs={12}>
            <Text $fontSize="body" $marginBottom={1}>
              Valid From
            </Text>
            <TextBox
              $type={"date"}
              $name={validFrom}
              $value={validFrom ? formateDateToDateTextboxFormat(validFrom) : null}
              $onChange={(e) => setValidFrom(e.target.value)}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Grid>
          <Grid $xs={12}>
            <Text $fontSize="body" $marginBottom={1}>
              Expiry
            </Text>
            <TextBox
              $type={"date"}
              $name={validTo}
              $value={validTo ? formateDateToDateTextboxFormat(validTo) : null}
              $onChange={(e) => setValidTo(e.target.value)}
              $width="100%"
              $borderRadius="8px"
              $border="1px solid black"
            />
          </Grid>
        </GridContainer>
        <br />
        {!editingCompliance && (
          <>
            <FlexBox $width="100%" $justifyContent="space-bwtween">
              <Box $width="80%">
                <Text $fontWeight="bold">
                  Name
                </Text>
              </Box>
              <Box $width="20%" $display="flex" $justifyContent="center">
                <Text Text $fontWeight="bold">
                  Remove file
                </Text>
              </Box>
            </FlexBox>
            {files?.map((value, index) => {
              return (
                <FlexBox
                  $width="100%"
                  $marginTop={1}
                  key={index}
                  $backgroundColor={index % 2 === 0 ? "list" : "white"}
                  $borderRadius="5px"
                  $padding={1}
                >
                  <Box $width="80%">
                    <Text $fontWeight="bold" $wordWrap="anywhere">
                      {value.name}
                    </Text>
                  </Box>
                  <Box $width="20%" $display="flex" $justifyContent="center">
                    <Icon
                      $icon="Delete"
                      $color="red"
                      $size={20}
                      $marginRight={1}
                      $pointer="pointer"
                      $onClick={() => {
                        setFiles(files.filter((f) => f !== value));
                      }}
                    ></Icon>
                  </Box>
                </FlexBox>
              );
            })}
          </>
        )}
        {editingCompliance && (
          <>
            <Text>Files</Text>
            <FlexBox $width="100%" $marginTop={3}>
              <Box $width="50%">
                <Text $fontWeight="bold">
                  Name
                </Text>
              </Box>
              <Box $width="20%" $display="flex" $justifyContent="center">
                <Text>Date</Text>
              </Box>
              <Box $width="20%" $display="flex" $justifyContent="center">
                <Text>Added by</Text>
              </Box>
            </FlexBox>
            {complianceToEdit &&
              filesForUpdation.map((value, index) => {
                return (
                  <FlexBox
                    $width="100%"
                    $marginTop={1}
                    key={index}
                    $backgroundColor={index % 2 === 0 ? "list" : "white"}
                    $borderRadius="5px"
                    $padding={1}
                  >
                    <Box $width="50%">
                      <a
                        href={value?.fileUrl ? value?.fileUrl : ""}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Text
                          $wordWrap="anywhere"
                          $fontWeight="900"
                          $color={"blue"}
                        >
                          {value?.fileName ? value?.fileName : value.name}
                        </Text>
                        <Icon $icon="Launch" $color="blue" $size={10} />
                      </a>
                    </Box>
                    <Box $width="20%" $display="flex" $justifyContent="center">
                      <Text $fontWeight="bold">
                        {value?.creationDate
                          ? formateDateToReadableFormatWithoutTime(value?.creationDate)
                          : formateDateToReadableFormatWithoutTime(Date.now())}
                      </Text>
                    </Box>
                    <Box $width="20%" $display="flex" $justifyContent="center">
                      <Text $fontWeight="bold">
                        {uploadedBy ? uploadedBy : "Not provided"}
                      </Text>
                    </Box>
                    <Box
                      $width="10%"
                      $display="flex"
                      $justifyContent="flex-end"
                    >
                      <Icon
                        $icon="Delete"
                        $color="red"
                        $size={20}
                        $pointer="pointer"
                        $marginRight={1}
                        $onClick={() => {
                          console.log(value.origin);
                          handleRemoveFile(value.id);
                        }}
                      ></Icon>
                    </Box>
                  </FlexBox>
                );
              })}
          </>
        )}
        <FlexBox $justifyContent="flex-end" $width="100%" $marginTop={5}>
          <label htmlFor="file-input">
            <FlexBox>
              <Icon
                $icon="Add"
                $size={18}
                $pointer="pointer"
              ></Icon>
              <Text $fontWeight="600" $pointer="pointer">
                Add New File
              </Text>
            </FlexBox>
          </label>
          <input
            id="file-input"
            type="file"
            style={{ display: "none" }}
            multiple
            onChange={(e) => {
              if (!editingCompliance) {
                setFiles([...files, ...e.target.files]);
              } else {
                setFiles([...files, ...e.target.files]);

                const id = uuidv4();
                //converting file to array from fileStream because we are getting an array from DB
                const newFiles = Array.from(e.target.files).map((file) => {
                  return {
                    id: id, //need this while filtering
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    origin: "local",
                  };
                });
                setFilesForUpdation([...filesForUpdation, ...newFiles]);
              }
            }}
          />
        </FlexBox>
        {errorMessage && (
          <FlexBox $width="100%" $marginBottom={1} $marginTop={1}>
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {complianceCreateStatus === asyncStates.PENDING &&
          uploadingPercentage != null && (
            <FlexBox $width="100%" $marginTop={3} $marginBottom={1}>
              <LinearWithValueLabel
                $loadsContinuosly={false}
                value={uploadingPercentage}
              />
            </FlexBox>
          )}
      </FlexBox>

      <FlexBox
        $justifyContent={editingCompliance && complianceToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {editingCompliance && complianceToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (
              complianceUpdateStatus === asyncStates.PENDING ||
              complianceDeleteStatus === asyncStates.PENDING
            )
              return;
            onDeleteFileClicked();
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {complianceDeleteStatus !== asyncStates.PENDING ? (
              "Delete"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>
        }
        {complianceToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (editingCompliance) {
              onUpdateComplianceClicked();
            } else {
              onCreateComplianceClicked();
            }
          }}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {complianceCreateStatus !== asyncStates.PENDING &&
              complianceUpdateStatus !== asyncStates.PENDING ? (
              "Save"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

        {complianceToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restoreComplianceClicked()
          }}
        >
          <Text $color={"white"}>
            {complianceRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}
      </FlexBox>
    </>
  );
};

export default EditCompliance;
