import React, { useEffect, createRef, useState, useRef } from 'react'
import BodymapImg from "../../../../../../Images/Bodymap/bodymap_image.png"
import { Box, FlexBox } from '../../../../../../Components/Boxes';
import { Button } from '../../../../../../Components/Input';

export default function UploadBodymap({ $onFileUpload }) {
    const canvasRef = useRef(null);
    const [ctx, setCtx] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [lineWidth, setLineWidth] = useState(5);
    const [strokeColor, setStrokeColor] = useState("#FF0000");

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        setCtx(canvas.getContext('2d'));
    }, []);

    useEffect(() => {
        if (ctx) {
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = strokeColor;
        }
    }, [ctx, lineWidth, strokeColor])

    const startDrawing = () => {
        setIsDrawing(true);
        ctx.beginPath();
    }

    const stopDrawing = () => {
        setIsDrawing(false);
    }

    const draw = (e) => {
        if (!isDrawing) return;
        ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        ctx.stroke();
    }

    const handleImageLoad = () => {
        const canvas = canvasRef.current;
        const img = new Image();
        img.src = BodymapImg;
        img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        }
    }

    const handleLineWidthChange = (e) => {
        setLineWidth(e.target.value);
    }

    const handleStrokeColorChange = (e) => {
        setStrokeColor(e.target.value);
    }

    const handleFormSubmit = (e) => {
        const canvas = canvasRef.current;
        const dataURL = canvas.toDataURL('image/png'); // Retrieve the image data from the canvas
        var blobBin = atob(dataURL.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        $onFileUpload(file)
    }

    return (
        <div style={{ minHeight: "70vh" }}>
            <canvas
                ref={canvasRef}
                style={{ top: 0, left: 0, zIndex: 1, width: "100%", minHeight: "70vh" }}
                onMouseDown={startDrawing}
                onMouseUp={stopDrawing}
                onMouseMove={draw}
            />
            <img
                src={BodymapImg}
                onLoad={handleImageLoad}
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
                hidden
            />

            <FlexBox
                $justifyContent="space-between"
                // $position="absolute"
                $bottom="0"
                $width="100%"
                $zIndex={3}
                $paddingBottom="2"
            >
                <FlexBox $marginLeft="2">
                    <input
                        type="color"
                        value={strokeColor}
                        onChange={handleStrokeColorChange}
                    />
                    <Box $marginRight="2" />
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={lineWidth}
                        onChange={handleLineWidthChange}
                    />
                </FlexBox>
                <Box $marginRight="2">
                    <Button $onClick={handleFormSubmit}>Submit</Button>
                </Box>
            </FlexBox>
        </div>
    );
}
