import { useSelector } from "react-redux";

export const useTimesheetsSelector = () => useSelector((state) => {
  return state.timesheet.timesheets;
});

export const useTimesheetPdfSelector = () => useSelector((state) => {
  return state.timesheet.timesheetPdf;
});

// ------------------- selector error messages ------------------ //
export const useTimesheetCreateErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.createTimesheetErrorMessage;
});

export const useTimesheetDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.deleteTimesheetErrorMessage;
});

export const useTimesheetUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.updateTimesheetErrorMessage;
});

export const useTimesheetsGetErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.getTimesheetsErrorMessage;
});

export const useTimesheetGetErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.getTimesheetErrorMessage;
});

export const useTimesheetRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.restoreTimesheetErrorMessage;
});

export const useTimesheetPdfGetErrorMessageSelector = () => useSelector((state) => {
  return state.timesheet.errorMessages.getTimesheetPdfErrorMessage;
});

// ------------ status selectors ------------ //
export const useTimesheetCreateStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.createTimesheetStatus;
});

export const useTimesheetDeleteStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.deleteTimesheetStatus;
});

export const useTimesheetUpdateStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.updateTimesheetStatus;
});

export const useTimesheetsGetStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.getTimesheetsStatus;
});

export const useTimesheetGetStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.getTimesheetStatus;
});

export const useTimesheetRestoreStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.restoreTimesheetStatus;
});

export const useTimesheetPdfGetStatusSelector = () => useSelector((state) => {
  return state.timesheet.statuses.getTimesheetPdfStatus;
});

// ------------ success selectors ------------ //
export const useTimesheetUpdateSuccessSelector = () => useSelector((state) => {
  return state.timesheet.sucessMessages.updateTimesheetSuccessMessage
})

export const useMoreTimesheetItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.timesheet.moreTimesheetItemsLeftInPagination
})