import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  getAllMedicineReducer,
  createMedicineReducer,
  deleteMedicineReducer,
  updateMedicineReducer,
  clearAllLocalMedicinesReducer,
  restoreArchivedMedicineReducer,
} from "../../slices/medicineSlices";

const useMedicineDispathers = () => {
  const dispatch = useDispatch();

  const createMedicine = (data) => {
    return dispatch(createMedicineReducer(data));
  };

  const restoreArchivedMedicine = (data) => {
    return dispatch(restoreArchivedMedicineReducer(data))
  }

  const getAllMedicine = (data) => {
    return dispatch(getAllMedicineReducer(data));
  };

  const deleteMedicine = (data) => {
    return dispatch(deleteMedicineReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const updateMedicine = (data) => {
    console.log(data);
    return dispatch(updateMedicineReducer(data));
  };

  const clearAllLocalMedicine = (data) => {
    return dispatch(clearAllLocalMedicinesReducer(data));
  };

  return {
    restoreArchivedMedicine,
    getAllMedicine,
    createMedicine,
    deleteMedicine,
    clearAllErrorMessages,
    updateMedicine,
    clearAllLocalMedicine,
  };
};

export default useMedicineDispathers;
