import React, { useEffect } from 'react'
import { useGetStaffProfileErrorMessageSelector, useGetStaffProfileStatusSelector, useGetStaffProfileSuccessSelector, useSingleStaffSelector, useStaffProfileSelector } from '../../../../../redux/selectors/useStaffSelectors';
import { Grid, GridContainer } from '../../../../../Components/Grid';
import { Box, FlexBox } from '../../../../../Components/Boxes';
import ProfilePhoto from '../../../../../Components/Images/ProfilePhoto';
import Icon from '../../../../../Components/Icons';
import { Text } from '../../../../../Components/Text';
import styled from 'styled-components';
import { formateDateToReadableFormatWithoutTime } from '../../../../../utiles/dateformate';
import { color } from '../../../../../Components/Theme';
import { useAllQualificationSelector, useMoreQualificationItemsLeftInPaginationSelector } from '../../../../../redux/selectors/useQualificationsSelectors';
import useQualificationDispathers from '../../../../../redux/dispatchers/useQualificationDispatchers';
import { FormDialog } from '../../../../../Components/Modals';
import { useResponsive } from '../../../../../Components/Hooks';
import EditQualifications from '../Dialogs/EditQualifications';
import { useState } from 'react';
import useClientDispatchers from '../../../../../redux/dispatchers/useClientDispatchers';
import usePermissionsController from '../../../../../permissions';
import { useSendStaffProfileErrorMessageSelector, useSendStaffProfileStatusSelector, useSendStaffProfileSuccessSelector } from '../../../../../redux/selectors/useClientSelectors';
import BasicAlerts from '../../../../../Components/Boxes/ErrorAndSuccess';
import { SearchUserSelectBox } from '../../../../components/searchSelectBox';
import { Button } from '../../../../../Components/Input';
import { sleepForMilliseconds } from '../../../../../utiles/timers';
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import CircularLoader from '../../../../../Components/Icons/circularLoader';
import useStaffDispathers from '../../../../../redux/dispatchers/useStaffDispatchers';

const TableWrapper = styled.div`
  table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
  }

  td,
  th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
  }

  th {
      background-color: ${color("secondary")};
  }

  tr:nth-child(even) {
      background-color: #dddddd;
  }
`

export default function StaffProfileBuild({ staffId }) {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const staff = useSingleStaffSelector();
  const staffProfile = useStaffProfileSelector();
  const qualifications = useAllQualificationSelector()

  const { getAllQualification } = useQualificationDispathers()

  const {
    clearLocalStaffProfile,
    getStaffProfile,
  } = useStaffDispathers()

  const [qualificationToEdit, setQualificationToEdit] = useState(null)
  const [open, setOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const sendStaffProfileErrorMessage = useSendStaffProfileErrorMessageSelector()
  const sendStaffProfileStatus = useSendStaffProfileStatusSelector()
  const sendStaffProfileSuccessMessage = useSendStaffProfileSuccessSelector()

  const getStaffProfileErrorMessage = useGetStaffProfileErrorMessageSelector()
  const getStaffProfileStatus = useGetStaffProfileStatusSelector()
  const getStaffProfileSuccessMessage = useGetStaffProfileSuccessSelector()

  const moreQualificationsLeftInPagination = useMoreQualificationItemsLeftInPaginationSelector()

  const {
    accessSendStaffProfile,
  } = usePermissionsController()

  function HandleDialog() {
    setOpen(!open);
    setQualificationToEdit(null);
  }

  const {
    clearAllSuccessMessages,
    clearAllStatusMessages,
    clearAllErrorMessages,
    sendStaffProfile
  } = useClientDispatchers()

  const sendStaffProfileHandler = async () => {
    await sendStaffProfile({
      staffId: staffId,
      clientId: selectedUser.id,
    }).unwrap()
    await sleepForMilliseconds(1000 * 5)
    clearAllErrorMessages()
    clearAllStatusMessages()
    clearAllSuccessMessages()
    setSelectedUser(null)
    setSearchUser(null)
  }

  const loadMoreQualifications = async () => {
    await getAllQualification({
      staffId: staffId,
      startAfter: qualifications[qualifications.length - 1].id,
    }).unwrap()
  }

  useEffect(() => {
    clearLocalStaffProfile()
    getAllQualification({
      staffId: staffId,
    })
  }, [])

  useEffect(() => {
    if (staffProfile) {
      try {
        // Download profile base 64 as pdf file
        const linkSource = `data:application/pdf;base64,${staffProfile}`;
        const downloadLink = document.createElement("a");
        const fileName = `${staff?.name}_profile.pdf`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        console.log(error)
      }

      clearLocalStaffProfile()
    }
  }, [staffProfile])

  return (
    <>
      <GridContainer>
        {accessSendStaffProfile && <Grid $xs={12}>
          <FlexBox $width="100%" $justifyContent="flex-end" $marginBottom={3} $paddingBottom={3} $borderBottom="1px dashed black">
            <FlexBox $width="50%">
              {!searchUser && <Button $width="100%" $onClick={() => {
                setSearchUser(true)
              }}>Send profile to client</Button>}

              {searchUser && <Box $width="100%">
                <Text $fontSize="body" $marginBottom={1}>
                  Client
                </Text>

                {sendStaffProfileErrorMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
                  <BasicAlerts $type="error">{sendStaffProfileErrorMessage}</BasicAlerts>
                </FlexBox>}

                {sendStaffProfileSuccessMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
                  <BasicAlerts $type="success">{sendStaffProfileSuccessMessage}</BasicAlerts>
                </FlexBox>}

                <FlexBox $width="100%">
                  <SearchUserSelectBox
                    searchByClient
                    selected={selectedUser}
                    onChange={(user) => {
                      setSelectedUser(user)
                    }}
                  />
                  {sendStaffProfileStatus == asyncStates.PENDING ? <Box $marginLeft={2} $marginRight={2}>
                    <CircularLoader $size={20} />
                  </Box> : <Icon
                    $icon="Send"
                    $size={20}
                    $color={selectedUser ? "primary" : "grey"}
                    $pointer={selectedUser ? "pointer" : null}
                    $marginLeft={2}
                    $onClick={() => {
                      if (selectedUser) {
                        sendStaffProfileHandler()
                      }
                    }} />}
                  <Box $marginLeft={2} />
                </FlexBox>
              </Box>}
            </FlexBox>
          </FlexBox>
        </Grid>}
        <Grid $xs={4}>
          <FlexBox $minHeight="60vh" $flexDirection="column" $alignItems="center" $backgroundColor="primary" $padding="5">
            <Box $width="50%">
              <ProfilePhoto $size="100%" $url={staff?.profilePhotoUrl} />
            </Box>
            <Box $marginTop="5" />
            {staff?.contact?.contact && <FlexBox>
              <Icon $icon="Phone" $color="white" />
              <Text $fontSize="body" $marginLeft="2" $color="white">{staff?.contact?.contact}</Text>
            </FlexBox>}
            {staff?.contact?.contact && <Box $marginTop="5" />}

            {staff?.email && <FlexBox>
              <Icon $icon="Mail" $color="white" />
              <Text $fontSize="body" $marginLeft="2" $color="white">{staff?.email}</Text>
            </FlexBox>}
            {staff?.email && <Box $marginTop="5" />}

            {staff?.about && <hr style={{ width: "100%", color: "white" }} />}
            {staff?.about && <Box $width="100%">
              <Text $fontSize="heading-5" $marginLeft="2" $marginBottom="2" $color="white">Bio</Text>
              <Text $fontSize="body" $marginLeft="2" $color="white">{staff?.about}</Text>
            </Box>}
            {staff?.about && <Box $marginTop="5" />}
          </FlexBox>
        </Grid>
        <Grid $xs={8}>
          <Box $minHeight="60vh" $backgroundColor="#EEEEEE" $padding="5">
            <Text $fontSize="heading-2" $marginLeft="2">{staff?.name}</Text>
            <Box $marginTop="5" />
            <Text $fontSize="heading-3" $marginLeft="2">About</Text>
            <hr />
            <TableWrapper>
              <table>
                {staff?.preferredName && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Preferred Name
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.preferredName}
                    </Text>
                  </td>
                </tr>}

                {staff?.refId && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Ref ID
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.refId}
                    </Text>
                  </td>
                </tr>}

                {staff?.gender && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Gender
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.gender}
                    </Text>
                  </td>
                </tr>}

                {staff?.jobRole && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Job role
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.jobRole}
                    </Text>
                  </td>
                </tr>}

                {staff?.dbsNo && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      DBS No
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.dbsNo}
                    </Text>
                  </td>
                </tr>}

                {staff?.dateOfBirth && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Date Of Birth
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {formateDateToReadableFormatWithoutTime(staff?.dateOfBirth)} (DD/MM/YYYY)
                    </Text>
                  </td>
                </tr>}

                {staff?.rightToWork && <tr>
                  <td>
                    <Text $fontSize="body" $fontWeight="bold">
                      Right to work
                    </Text>
                  </td>
                  <td>
                    <Text $fontSize="body">
                      {staff?.rightToWork}
                    </Text>
                  </td>
                </tr>}
              </table>
            </TableWrapper>

            <Box $marginTop="5" />
            <Text $fontSize="heading-3" $marginLeft="2">Qualifications</Text>
            <hr />
            {qualifications && qualifications.length > 0 && <TableWrapper>
              <table>
                <tr>
                  <th><Text $fontSize="body" $fontWeight="bold"> Course </Text> </th>
                  <th> <Text $fontSize="body" $fontWeight="bold">Grade </Text></th>
                  <th><Text $fontSize="body" $fontWeight="bold">Date </Text> </th>
                </tr>
                {qualifications.map(qualification => {
                  return (
                    <tr style={{ cursor: 'pointer' }} onClick={() => {
                      HandleDialog();
                      setQualificationToEdit(qualification);
                    }}>
                      <td>
                        <Text $fontSize="body" $fontWeight="bold">
                          {qualification?.name}
                        </Text>
                      </td>
                      <td>
                        <Text $fontSize="body">
                          {qualification?.grade}
                        </Text>
                      </td>
                      <td>
                        <Text $fontSize="body">
                          {qualification?.date ? `${formateDateToReadableFormatWithoutTime(qualification?.date)}` : "-"}
                        </Text>
                      </td>
                    </tr>
                  )
                })}
              </table>
            </TableWrapper>}

            {moreQualificationsLeftInPagination && <FlexBox $width="100%" $justifyContent="center" $marginTop="6">
              <Button $variant="outlined" $onClick={() => {
                loadMoreQualifications()
              }} >Load More</Button>
            </FlexBox>}

            <FlexBox $justifyContent="flex-end" $width="100%" $marginTop={5}>
              <FlexBox
                $onClick={() => {
                  console.log("add new qualification")
                  HandleDialog();
                }}
              >
                <Icon
                  $icon="Add"
                  $size={18}
                  $pointer="pointer"
                ></Icon>
                <Text $fontWeight="600" $pointer="pointer">
                  Add New Qualitification
                </Text>
              </FlexBox>
            </FlexBox>
          </Box>
        </Grid>

        <Grid $xs={12}>
          {getStaffProfileErrorMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
            <BasicAlerts $type="error">{getStaffProfileErrorMessage}</BasicAlerts>
          </FlexBox>}

          {getStaffProfileSuccessMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
            <BasicAlerts $type="success">{getStaffProfileSuccessMessage}</BasicAlerts>
          </FlexBox>}

          <FlexBox $width="100%" $justifyContent="flex-end" $marginBottom={3} $paddingBottom={3} $marginTop={3} $borderBottom="1px dashed black">
            <Button
              $variant={"contained"}
              $loading={getStaffProfileStatus == asyncStates.PENDING}
              $onClick={async () => {
                await getStaffProfile({
                  staffId: staffId,
                }).unwrap()
              }}
            >
              <Icon
                $icon="Download"
                $size={20}
                $pointer={"pointer"}
              />
              <Text $fontSize="body" $marginLeft="2" $color="white">Download Profile</Text>
            </Button>
          </FlexBox>
        </Grid>
      </GridContainer>

      <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Compilance"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <EditQualifications
          $handleDialog={HandleDialog}
          $qualification={qualificationToEdit}
          $staffId={staffId}
        />
      </FormDialog>

    </>
  )
}
