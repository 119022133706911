import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetRiskassessmentsErrorMessageSelector, useGetRiskassessmentsStatusSelector, useRiskassessmentsSelector, useRiskassessmentsUpdateStatusSelector, useUpdateRiskassessmentsErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useRiskassessmentsSettingsController() {
    const { accessAddSettingsRiskassessments, accessEditSettingsRiskassessments } = usePermissionsController()
    const riskassessmentNames = useRiskassessmentsSelector()
    const getRiskassessmentsErrorMessages = useGetRiskassessmentsErrorMessageSelector()
    const updateRiskassessmentsErrorMessages = useUpdateRiskassessmentsErrorMessageSelector()

    const getRiskassessmentsStatuses = useGetRiskassessmentsStatusSelector()
    const updateRiskassessmentsStatuses = useRiskassessmentsUpdateStatusSelector()

    const names = useMemo(() => [...riskassessmentNames], [riskassessmentNames])

    const {
        updateRiskassessments,
        getRiskassessments,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getRiskassessments()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateRiskassessments({
            riskassessments: newNames
        }).unwrap();
        getRiskassessments()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateRiskassessments({
            riskassessments: newNames
        }).unwrap();
        getRiskassessments()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateRiskassessments({
            riskassessments: newNames
        }).unwrap();
        getRiskassessments()
        return
    }

    return {
        title: "Riskassessments",
        names: names,

        canEdit: accessEditSettingsRiskassessments,
        canAdd: accessAddSettingsRiskassessments,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateRiskassessmentsStatuses == asyncStates.PENDING,
        isDeletingConfig: updateRiskassessmentsStatuses == asyncStates.PENDING,
        isGettingConfigs: getRiskassessmentsStatuses == asyncStates.PENDING,

        updateErrorMessage: updateRiskassessmentsErrorMessages,
        deleteErrorMessage: updateRiskassessmentsErrorMessages,
        gettingErrorMessage: getRiskassessmentsErrorMessages,
    }
}