import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function useConnectionStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [toastId, setToastId] = useState(null);

  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
      if (toastId && toast.isActive(toastId)) {
        toast.update(toastId, {
          render: "Your Internet connection was restored",
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        setToastId(
          toast.success("Your Internet connection was restored", {
            position: toast.POSITION.BOTTOM_LEFT,
          })
        );
      }
    }

    function handleOffline() {
      setIsOnline(false);
      if (toastId && toast.isActive(toastId)) {
        toast.update(toastId, {
          render: "You are currently offline",
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        setToastId(
          toast.error("You are currently offline", {
            position: toast.POSITION.BOTTOM_LEFT,
          })
        );
      }
    }

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [toastId]);

  return isOnline;
}
