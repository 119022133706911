import ApiService from "../../restapi";

export const getAllEMARsApiService = async ({ clientId, startAfter, limit, monthBeginningDate, medicineId }) => {
    return await ApiService.sendRequest({
        url: `/clients/${clientId}/eMAR`,
        query: {
            startAfter,
            limit,
            monthBeginningDate,
            medicineId,
        },
        method: "GET",
        headers: {},
    })
}