import MUIGrid from "@mui/material/Grid";

export default function Grid({
  $xs,
  $sm,
  $md,
  $lg,
  $xl,
  $order,
  $style,
  children,
  ...rest
}) {
  return (
    <MUIGrid
      item
      xs={$xs}
      sm={$sm}
      md={$md}
      lg={$lg}
      xl={$xl}
      order={$order}
      display={rest.$display}
      justifyContent={rest.$justifyContent}
      alignItems={rest.$alignItems}
      direction={rest.$direction}
      style={$style}
    >
      {children}
    </MUIGrid>
  );
}
