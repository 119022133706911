import React from 'react'
import * as asyncStates from "../../../redux/constants/asyncStates";
import useBlockListDispathers from '../../../redux/dispatchers/useBlockListDispatchers';
import { useAllBlockListGetStatusSelector, useAllBlockListSelector, useBlockListGetErrorMessageSelector, useMoreBlockListItemsLeftInPaginationSelector } from '../../../redux/selectors/useBlockListSelectors';
import { useEffect } from 'react';
import { Box, ErrorAndSuccess, FlexBox, ListBox } from '../../../Components/Boxes';
import { Text } from '../../../Components/Text';
import EmptyState from '../../../Components/NoRecordFound';
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader';
import { Button } from "../../../Components/Input/index";
import { useState } from 'react';
import Icon from '../../../Components/Icons';
import { FormDialog } from '../../../Components/Modals';
import { useResponsive } from '../../../Components/Hooks';
import EditBlockList from './EditBlockList';
import { formateDateToReadableFormatWithTime } from '../../../utiles/dateformate';

export default function BlockListView(props) {
    const { clientId, staffId } = props
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        getAllBlockList,
        deleteBlockList,
        clearAllErrorMessages,
        clearAllLocalBlockList,
    } = useBlockListDispathers()

    const blockLists = useAllBlockListSelector()

    const moreBlockListItemsLeftInPagination = useMoreBlockListItemsLeftInPaginationSelector()
    const getAllBlockListStatus = useAllBlockListGetStatusSelector()
    const getAllBlockListError = useBlockListGetErrorMessageSelector()

    const [open, setOpen] = useState(false);
    const [blockListToEdit, setBlockListToEdit] = useState(null);

    function HandleDialog() {
        setOpen(!open);
        setBlockListToEdit(null);
    }

    const loadMore = () => {
        getAllBlockList({
            clientId,
            staffId,
            startAfter: blockLists[blockLists.length - 1].id,
        });
    }

    useEffect(() => {
        clearAllErrorMessages()
        clearAllLocalBlockList()
        getAllBlockList({
            clientId,
            staffId,
        })
    }, [clientId, staffId])

    return (
        <Box
            $width="100%"
            $backgroundColor="#fff"
        >
            <FlexBox $justifyContent={"flex-end"} $width="100%" $marginTop={2}>
                <Button
                    $onClick={() => {
                        HandleDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>{staffId ? "Block From Client" : "Block Staff"}</Text>
                </Button>
            </FlexBox>

            <FlexBox $width="100%" $marginTop={3} $padding="3">
                <Box $width="30%">
                    <Text $fontWeight="bold">
                        {staffId ? "Client" : "Staff"}
                    </Text>
                </Box>
                <Box $width="20%">
                    <Text>Creation Date</Text>
                </Box>
                <Box $width="50%">
                    <Text>Reason</Text>
                </Box>
            </FlexBox>

            {getAllBlockListStatus === asyncStates.SUCCESS && blockLists < 1 && <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
                <EmptyState />
            </FlexBox>}

            {blockLists?.map((value, index) => {
                return (
                    <ListBox
                        $width="100%"
                        key={index}
                        $index={index}
                        $onClick={() => {
                            HandleDialog();
                            setBlockListToEdit(value);
                        }}
                    >
                        <FlexBox $width="100%" >
                            <Box $width="30%">
                                <Text $fontWeight="bold">
                                    {staffId ? value?.client?.name : value?.staff?.name}
                                </Text>
                            </Box>
                            <Box $width="20%">
                                <Text>{value.creationDate ? formateDateToReadableFormatWithTime(new Date(value.creationDate)) : "-"}</Text>
                            </Box>
                            <Box $width="50%">
                                <Text>{value?.reason ?? ""}</Text>
                            </Box>
                        </FlexBox>
                    </ListBox>
                );
            })}

            {getAllBlockListStatus === asyncStates.PENDING &&
                [1, 2, 3, 4].map((value) => {
                    return (
                        <Box $width="100%" $marginTop="2" key={value}>
                            <MuiSkeletonLoader
                                $variant="rectangular"
                                $width={"100%"}
                                $height={40}
                            />
                        </Box>
                    );
                })}

            {getAllBlockListError && (
                <FlexBox $marginTop={2} $width="100%">
                    <ErrorAndSuccess $type="error">{getAllBlockListError}</ErrorAndSuccess>
                </FlexBox>
            )}

            {moreBlockListItemsLeftInPagination && <FlexBox $marginTop="6" $justifyContent="center">
                <Button $onClick={() => {
                    loadMore()
                }} >Load More</Button>
            </FlexBox>}

            <FormDialog
                $open={open}
                $handleDialog={HandleDialog}
                $title={"Block List"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <EditBlockList
                    $handleDialog={HandleDialog}
                    $clientId={clientId}
                    $staffId={staffId}
                    $blockList={blockListToEdit}
                />
            </FormDialog>
        </Box>
    )
}
