import React, {useMemo, useState} from 'react'
import CalendarScheduleView from './calendarScheduleView';
import Tabs from '../../Components/Input/Tabs';
import usePermissionsController from '../../permissions';
import {FlexBox} from '../../Components/Boxes';
import {Grid, GridContainer} from '../../Components/Grid';
import {PendingApprovalView} from './pendingApprovalView';
import FormDialog from '../../Components/Modals/FormDialog';
import {EVENT_STATUS} from '../../constants';
import {color} from '../../Components/Theme';
import Events from '../Team/Events/EditEvents';
import {useResponsive} from '../../Components/Hooks';

export default function Schedule() {
    const isDesktop = useResponsive({xs: false, sm: true});
    const {
        allowShiftDistribution,
        defaultCreateEventModelTitle,
    } = usePermissionsController()
    const tabOptions = useMemo(() => {
        const opts = []
        opts.push({name: "Calendar"})
        if (allowShiftDistribution)
            opts.push({name: "Approvals"})

        return opts
    }, [])

    const [tabIndex, setTabIndex] = useState(0)

    const select = useMemo(() => {
        return tabOptions[tabIndex].name
    }, [tabIndex])

    const [openDialog, setOpenDialog] = useState(false);

    function handleEditEventDialog(event) {
        setOpenDialog(event);
    }

    function handleCloseEventDialog() {
        setOpenDialog(null);
    }

    return (
        <>
            <GridContainer>
                <Grid $xs={12}>
                    <FlexBox
                        $width={"100%"}
                        $minHeight={"70vh"}
                        $backgroundColor="#fff"
                        $borderRadius="10px"
                        $flexDirection="column"
                        $paddingBottom="20px"
                    >
                        {tabOptions.length > 1 && <Tabs
                            $onChanged={(val) => {
                                setTabIndex(val)
                                // append path
                                const path = tabOptions[val].path
                            }}
                            $value={tabIndex}
                            $tabs={tabOptions}
                        />}

                        {select === "Calendar" && <CalendarScheduleView onEventClick={handleEditEventDialog}/>}
                        {select === "Approvals" && <PendingApprovalView onEventClick={handleEditEventDialog}/>}
                    </FlexBox>
                </Grid>
            </GridContainer>

            {openDialog && <FormDialog
                $open={true}
                $handleDialog={handleCloseEventDialog}
                $title={(() => {
                    let title = defaultCreateEventModelTitle ?? "Event"
                    if (openDialog) {
                        if (openDialog.eventStatus === EVENT_STATUS.CANCELLED) {
                            title = title + " (Cancelled)"
                        } else if (openDialog.eventStatus === EVENT_STATUS.COMPLETED) {
                            title = title + " (Completed)"
                        } else if (openDialog.eventStatus === EVENT_STATUS.CHECKED_IN) {
                            title = title + " (Currently Checked In)"
                        } else if (openDialog.eventStatus === EVENT_STATUS.MISSED) {
                            title = title + " (Missed)"
                        }
                    }
                    return title
                })()}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
                $headerColor={(() => {
                    if (openDialog) {
                        if (openDialog.eventStatus === EVENT_STATUS.ACTIVE) {
                            return openDialog?.eventColor || color("event-active")
                        } else if (openDialog.eventStatus === EVENT_STATUS.CANCELLED) {
                            return color("event-cancelled");
                        } else if (openDialog.eventStatus === EVENT_STATUS.COMPLETED) {
                            return color("event-completed");
                        } else if (openDialog.eventStatus === EVENT_STATUS.CHECKED_IN) {
                            return color("event-checked-in");
                        } else if (openDialog.eventStatus === EVENT_STATUS.MISSED) {
                            return color("event-missed");
                        } else {
                            return openDialog?.eventColor || color("primary");
                        }
                    }
                    return openDialog?.eventColor || color("primary");
                })()}
            >
                <Events
                    handleDialog={handleCloseEventDialog}
                    editEvent={true}
                    eventData={openDialog}
                    clientId={openDialog?.client?.id}
                    staffId={openDialog?.staff?.id}
                />
            </FormDialog>}
        </>
    )
}
