import ApiService from "../../restapi";

export const getAllEmailListApiService = async ({
    staffId,
    clientId,
    startAfter,
    limit,
    isArchived,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/emailList`;
    } else if (clientId) {
        url = `/clients/${clientId}/emailList`;
    } else {
        url = `/emailList`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {
            startAfter: startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
}

export const createEmailListApiService = async ({
    staffId,
    clientId,
    purpose,
    notes,
    email,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/emailList`;
    } else if (clientId) {
        url = `/clients/${clientId}/emailList`;
    } else {
        url = `/emailList`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "POST",
        headers: {},
        body: {
            staffId,
            clientId,
            purpose,
            notes,
            email,
        },
    });
}

export const updateEmailListApiService = async ({
    staffId,
    clientId,
    emailListId,
    purpose,
    notes,
    email,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/emailList/${emailListId}`;
    } else if (clientId) {
        url = `/clients/${clientId}/emailList/${emailListId}`;
    } else {
        url = `/emailList/${emailListId}`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            purpose,
            notes,
            email,
        },
    });
}

export const deleteEmailListApiService = async ({
    staffId,
    clientId,
    emailListId,
}) => {
    let url = "";
    if (staffId) {
        url = `/staff/${staffId}/emailList/${emailListId}`;
    } else if (clientId) {
        url = `/clients/${clientId}/emailList/${emailListId}`;
    } else {
        url = `/emailList/${emailListId}`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {},
        method: "DELETE",
        headers: {},
    });
}