import {
  DefaultFlexModifiers,
  DefaultSpacingModifiers,
  DefaultSizeModifiers,
  DefaultBackgroundModifier,
  DefaultPositionModifiers,
  DefaultTextModifiers,
} from "../Modifiers";
import { color, spacing } from "../Theme";
import styled from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/async"

const TextWrapper = styled.div`
  width: 100%;
`;

export default function SelectBox({
  $color,
  $onChange,
  $name,
  $value,
  $options,
  $isMulti,
  $borderRadius,
  $paddingLeft,
  $leading,
  $trailing,
  $disabled,
  $defaultValue,
  $async,
  $asyncOnLoad,
  $isOptionsDisabled,
  children,
  ...rest
}) {

  const handleChange = (val) => {
    // { value, label }
    console.log(val)
    if ($onChange) {
      if (Array.isArray(val)) {
        $onChange(val?.map(v => {
          return { ...v, name: $name }
        }))
      } else {
        $onChange({ ...val, name: $name })
      }
    }
  }

  const loadAsyncOptions = (
    inputValue,
    callback,
  ) => {
    $asyncOnLoad(inputValue, callback)
  }

  const selectStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid black !important",
      borderRadius: "8px",
      width: "100%",
      boxShadow: "none",
    }),
    option: (base, state) => ({
      ...base,
      "&:hover": {
        zIndex: 9999,
        backgroundColor: color("primary"),
        color: "white",
      },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: color("primary"),
      color: "white",
      borderRadius: "10px",
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "white",
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "white",
      cursor: "pointer",
      ':hover': { color: "white" }
    })
  }

  const selectableOptions = $options || []

  return (
    <TextWrapper
      style={{
        ...DefaultPositionModifiers(rest),
        ...DefaultSpacingModifiers({ $paddingLeft: 0, ...rest }),
        ...DefaultFlexModifiers({
          $justifyContent: rest.$justifyContent || "space-between",
          ...rest,
        }),
        ...DefaultSizeModifiers({ $width: "100%", ...rest }),
        ...DefaultBackgroundModifier(rest),
        borderRadius: $borderRadius || 10,
      }}
    >
      {!$async && <Select
        menuPortalTarget={document.querySelector('.MuiDialog-root')} // ?? document.querySelector('body')}
        value={$value}
        options={selectableOptions}
        isMulti={$isMulti}
        name={$name}
        defaultValue={$value}
        onChange={handleChange}
        styles={selectStyles}
        isDisabled={$disabled}
        isOptionDisabled={$isOptionsDisabled}
      />}

      {$async && <AsyncSelect
        menuPortalTarget={document.querySelector('.MuiDialog-root') } //?? document.querySelector('body')}
        value={$value}
        options={selectableOptions}
        isMulti={$isMulti}
        name={$name}
        defaultValue={$value}
        onChange={handleChange}
        styles={selectStyles}
        loadOptions={loadAsyncOptions}
        cacheOptions
        isDisabled={$disabled}
        isOptionDisabled={$isOptionsDisabled}
      // defaultOptions
      />}
    </TextWrapper>
  );
}
