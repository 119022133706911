import ApiService from "../../restapi";

export const createTimeOffApiService = async ({
    staffId,
    name,
    reason,
    notes,
    startDate,
    endDate,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/timeOff`,
        query: {},
        method: "POST",
        headers: {},
        body: {
            staffId,
            name,
            reason,
            notes,
            startDate,
            endDate,
        },
    });
};

export const getAllTimeOffApiService = async ({
    staffId,
    timeOffStatus,
    startAfter,
    limit,
    isArchived,
}) => {
    let url = `/timeOff`;
    if (staffId) {
        url = `/staff/${staffId}/timeOff`;
    }
    return await ApiService.sendRequest({
        url: url,
        query: {
            timeOffStatus: timeOffStatus,
            startAfter: startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
};

export const getSingleTimeOffApiService = async ({
    staffId,
    timeOffId,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/timeOff/${timeOffId}`,
        query: {},
        method: "GET",
        headers: {},
    });
};

export const updateTimeOffApiService = async ({
    staffId,
    timeOffId,
    timeOffStatus
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/timeOff/${timeOffId}`,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            timeOffStatus: timeOffStatus,
        },
    });
};