export const arrayEmpty = (val) => {
    if (!Array.isArray(val)) return true
    return val.length <= 0
}

export const areAllKeysEmpty = (val) => {
    if (!val) return true
    for (const [key, value] of Object.entries(val)) {
        if (typeof value == "object") {
            const isEmpty = areAllKeysEmpty(value)
            if (!isEmpty) {
                return false
            }
        } else {
            if (value) return false
        }
    }
    return true
}

export const isAnyKeyEmpty = (val) => {
    if (!val) return true
    for (const [key, value] of Object.entries(val)) {
        if (typeof value == "object") {
            const isEmpty = isAnyKeyEmpty(value)
            if (isEmpty) {
                return true
            }
        } else {
            if (!value) return true
        }
    }
    return false
}