import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  FlexBox,
  ErrorAndSuccess,
  ListBox,
} from "../../../Components/Boxes/index";
import Icon from "../../../Components/Icons";
import { Button } from "../../../Components/Input";
import FormDialog from "../../../Components/Modals/FormDialog";
import { Text } from "../../../Components/Text";
import EditPolicy from "./EditPolicy";
import { useResponsive } from "../../../Components/Hooks/index";
import { formateDateToReadableFormatWithoutTime } from "../../../utiles/dateformate";
import {
  usePoliciesGetErrorMessageSelector,
  usePoliciesGetStatusSelector,
  usePoliciesSelector,
  useMorePoliciesLeftInPaginationSelector,
} from "../../../redux/selectors/usePoliciesSelectors";
import MuiSkeletonLoader from "../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import usePolicyDispathers from "../../../redux/dispatchers/usePolicyDispatchers";
import * as asyncStates from "../../../redux/constants/asyncStates";
import EmptyState from "../../../Components/NoRecordFound";
import Tabs from "../../../Components/Input/Tabs";
import usePermissionsController from "../../../permissions";
import { PolicyStatus } from "./PolicyStatus";

const Policy = ({ staffId, clientId, canAdd, canEdit }) => {

  //controlling of opening and closing the dialog
  const [isArchived, setIsArchived] = useState(false)
  const [editPolicyDialogOpen, setEditPolicyDialogOpen] = useState(null);
  const [policyToEdit, setPolicyToEdit] = useState(null);
  function HandleEditPolicyDialog() {
    setEditPolicyDialogOpen(null);
    setPolicyToEdit(null);
  }
  // see whether it's desltop size or not
  const isDesktop = useResponsive({ xs: false, sm: true });
  const {
      accessArchives
  } = usePermissionsController()

  const morePoliciesLeftInPagination = useMorePoliciesLeftInPaginationSelector()
  const policiesGetErrorMessage = usePoliciesGetErrorMessageSelector();
  const errorMessage = policiesGetErrorMessage;

  const policiesGetStatus = usePoliciesGetStatusSelector();
  const policies = usePoliciesSelector();

  const { getPolicies, clearAllLocalPolicies } = usePolicyDispathers();

  const loadMore = () => {
    getPolicies({
      staffId: staffId,
      clientId: clientId,
      startAfter: policies[policies.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalPolicies();
    getPolicies({
      staffId: staffId,
      clientId: clientId,
      isArchived: isArchived,
    });
  }, [isArchived]);



  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}
        {canAdd && <Button
          $onClick={() => {
            setEditPolicyDialogOpen(true);
            setPolicyToEdit(null);
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Policies</Text>
        </Button>}
      </FlexBox>

      <FlexBox $width="100%" $marginTop={3} $padding="3">
        <Box $width="40%">
          <Text $fontWeight="bold">
            Name
          </Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Added On</Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Uploaded By</Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Status</Text>
        </Box>
      </FlexBox>

      {policiesGetStatus === asyncStates.SUCCESS &&
        policies.length < 1 ? (
        <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
          <EmptyState />
        </FlexBox>
      ) : (
        policies?.map((value, index) => {
          return (
            <ListBox
              $width="100%"
              $marginTop={2}
              key={index}
              $index={index}
              $onClick={() => {
                if (!canEdit) return;
                setEditPolicyDialogOpen(true);
                setPolicyToEdit(value);
              }}
            >
              <FlexBox $width="100%" >
                <Box $width="40%">
                  <Text $fontWeight="bold" $onClick={(e) => { }}>
                    <a href={value.policyUrl} target="_blank">
                      {" "}
                      {value.name}{" "}
                      <Icon $icon="Launch" $color="blue" $size={10} />
                    </a>
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {formateDateToReadableFormatWithoutTime(value.creationDate)}
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <Text
                    $fontWeight="bold"
                  >
                    {value.uploadedBy?.name ?? ""}
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <PolicyStatus status={value.policyStatus} />
                </Box>
              </FlexBox>
            </ListBox>
          );
        })
      )}

      {policiesGetStatus == asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <Box $width="100%" $marginBottom="2" key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={40}
              />
            </Box>
          );
        })}

      {errorMessage && (
        <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
      )}


      {morePoliciesLeftInPagination && <FlexBox $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      {editPolicyDialogOpen && (
        <FormDialog
          $open={editPolicyDialogOpen}
          $handleDialog={HandleEditPolicyDialog}
          $title={"Edit Policy"}
          $maxWidth="sm"
          $fullWidth
          $fullScreen={!isDesktop}
        >
          <EditPolicy $handleDialog={HandleEditPolicyDialog} $policy={policyToEdit} staffId={staffId} clientId={clientId}/>
        </FormDialog>
      )}
    </Box>
  );
};

export default Policy;
