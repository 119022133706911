import { useSelector } from "react-redux";

export const useAllEMARsSelector = () => useSelector((state) => {
    return state.eMAR.allEMARs;
});

//------------------------EMAR Error Messages-------------------//
export const useGetAllEMARsErrorMsgSelector = () => useSelector((state) => {
    return state.eMAR.errorMessages.getAllEMARsErrorMessage;
});

//-----------------------EMAR Status Selectors----------------//
export const useGetAllEMARsStatusSelector = () => useSelector((state) => {
    return state.eMAR.status.getAllEMARsStatus;
});

//------------------------Success EMAR Selectors---------------------//
export const useGetAllEMARsSuccessMsgSelector = () => useSelector((state) => {
    return state.eMAR.successMessages.getAllEMARsSuccessMessage;
});

export const useMoreEMARsLeftInPaginationSelector = () => useSelector((state) => {
    return state.eMAR.moreEMARsLeftInPagination
})