export const REPETITION_TYPE = {
    NONE: "NONE",
    SELECT_DAYS: "SELECT_DAYS",
    CUSTOM: "CUSTOM"
}

export const EVENT_TYPE = {
    CLIENT_VISIT: "CLIENT_VISIT", // client visit event
    SHIFT: "SHIFT", // This is a shift that can be selected
    CUSTOM: "CUSTOM", // custom visit event
    AVAILABILITY: "AVAILABILITY", // times when user is available to work, will show without grey on calendar
    ABSENCE: "ABSENCE", // When user is absert
}

export const REPETITION_PERIODS = {
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
}

export const DAYS = {
    MON: "MON",
    TUE: "TUE",
    WED: "WED",
    THU: "THU",
    FRI: "FRI",
    SAT: "SAT",
    SUN: "SUN"
}

export const DIARY_TYPE = {
    CHECK_IN: "CHECK_IN",
    NOTE: "NOTE"
}

export const DIARY_CHECKIN_STATUS = {
    CHECKED_IN: "CHECKED_IN",
    MISSED: "MISSED",
    LATE_CHECK_IN: "LATE_CHECK_IN",
    COMPLETED: "COMPLETED"
}

export const PAGINATION_LIMITS = {
    STAFF_DIARY: 10,
    CLIENT_DIARY: 10,
    STAFF_LIST: 10,
    CLIENT_LIST: 10,
    EMAR_LIST: 10,
    ADDITIONAL_FORM_LIST: 10,
    RISKASSESSMENTS: 10,
    CAREPLAN: 10,
    CLIENT_ACCESS: 10,
    TIMESHEET: 10,
    MEDICINE: 10,
    FILES: 10,
    POLICY: 10,
    COMPLIANCE: 10,
    QUALIFICATION: 10,
    EVENT_LIST: 10,
    BLOCK_LIST: 10,
    TIME_OFF_LIST: 10,
    DEFAULT_QUALIFICATIONS_LIST: 10,
    EMAIL_LIST: 10,
}

export const DOCUMENT_STATUS = {
    ACTIVE: "ACTIVE",
    ARCHIVED: "ARCHIVED",
}

export const BUSINESS_ACCOUNT_TYPE = {
    DOMICILIARY: "DOMICILIARY",
    AGENCY: "AGENCY",
}

export const STAFF_ROLES = {
    OWNER: "OWNER",
    ADMIN: "ADMIN",
    STAFF: "STAFF",
    MODERATOR: "MODERATOR",
}

export const TIME_OF_DAY = {
    MORNING: "MORNING",
    NOON: "NOON",
    AFTERNOON: "AFTERNOON",
    EVENING: "EVENING",
    NIGHT: "NIGHT",
}

export const MEDICINE_STATUS = {
    TAKEN: "TAKEN",
    MISSED: "MISSED",
    REFUSED: "REFUSED",
}

export const TASK_HISTORY_TYPE = {
    SERVICE: "SERVICE",
    MEDICINE: "MEDICINE"
}

export const PLATFORM = {
    MOBILE: "MOBILE",
    WEB: "WEB"
}

export const ADDITIONAL_FORM_FIELD_TYPE = {
    DATE_TIME: "DATE_TIME",
    TEXT: "TEXT",
    NUMBER: "NUMBER",
    OPTIONS: "OPTIONS",
    BODYMAP: "BODYMAP",
    IMAGE: "IMAGE",
}

export const FEATURE_LIST = {
    LIST_CLIENTS: "LIST_CLIENTS",
    LIST_ALL_CLIENTS: "LIST_ALL_CLIENTS",
    ADD_CLIENTS: "ADD_CLIENTS",
    EDIT_CLIENTS: "EDIT_CLIENTS",
    LIST_CLIENTS_DIARY: "LIST_CLIENTS_DIARY",
    ADD_CLIENTS_DIARY_ENTRY: "ADD_CLIENTS_DIARY_ENTRY",
    LIST_CLIENT_EVENTS: "LIST_CLIENT_EVENTS",
    ADD_CLIENT_EVENTS: "ADD_CLIENT_EVENTS",
    EDIT_CLIENT_EVENTS: "EDIT_CLIENT_EVENTS",
    LIST_CLIENT_RISKASSESSMENTS: "LIST_CLIENT_RISKASSESSMENTS",
    ADD_CLIENT_RISKASSESSMENTS: "ADD_CLIENT_RISKASSESSMENTS",
    EDIT_CLIENT_RISKASSESSMENTS: "EDIT_CLIENT_RISKASSESSMENTS",
    LIST_CLIENT_CAREPLANS: "LIST_CLIENT_CAREPLANS",
    ADD_CLIENT_CAREPLANS: "ADD_CLIENT_CAREPLANS",
    EDIT_CLIENT_CAREPLANS: "EDIT_CLIENT_CAREPLANS",
    LIST_CLIENT_MEDICATIONS: "LIST_CLIENT_MEDICATIONS",
    ADD_CLIENT_MEDICATIONS: "ADD_CLIENT_MEDICATIONS",
    EDIT_CLIENT_MEDICATIONS: "EDIT_CLIENT_MEDICATIONS",
    LIST_CLIENT_EMAR: "LIST_CLIENT_EMAR",
    LIST_CLIENT_ADDITIONAL_FORMS: "LIST_CLIENT_ADDITIONAL_FORMS",
    ADD_CLIENT_ADDITIONAL_FORMS: "ADD_CLIENT_ADDITIONAL_FORMS",
    EDIT_CLIENT_ADDITIONAL_FORMS: "EDIT_CLIENT_ADDITIONAL_FORMS",
    LIST_CLIENT_FILES: "LIST_CLIENT_FILES",
    ADD_CLIENT_FILES: "ADD_CLIENT_FILES",
    EDIT_CLIENT_FILES: "EDIT_CLIENT_FILES",
    LIST_CLIENT_TIMESHEETS: "LIST_CLIENT_TIMESHEETS",
    ADD_CLIENT_TIMESHEETS: "ADD_CLIENT_TIMESHEETS",
    EDIT_CLIENT_TIMESHEETS: "EDIT_CLIENT_TIMESHEETS",
    LIST_CLIENT_ACCESS: "LIST_CLIENT_ACCESS",
    ADD_CLIENT_ACCESS: "ADD_CLIENT_ACCESS",
    EDIT_CLIENT_ACCESS: "EDIT_CLIENT_ACCESS",
    LIST_CLIENT_BLOCK_LIST: "LIST_CLIENT_BLOCK_LIST",
    ADD_CLIENT_BLOCK_LIST: "ADD_CLIENT_BLOCK_LIST",
    EDIT_CLIENT_BLOCK_LIST: "EDIT_CLIENT_BLOCK_LIST",

    LIST_CLIENT_EMAIL_LIST: "LIST_CLIENT_EMAIL_LIST",
    ADD_CLIENT_EMAIL_LIST: "ADD_CLIENT_EMAIL_LIST",
    EDIT_CLIENT_EMAIL_LIST: "EDIT_CLIENT_EMAIL_LIST",

    CLIENT_DOCTOR_DETAILS_SPECIFIED: "CLIENT_DOCTOR_DETAILS_SPECIFIED",
    CLIENT_NEXT_OF_KIN_DETAILS_SPECIFIED: "CLIENT_NEXT_OF_KIN_DETAILS_SPECIFIED",
    CLIENT_THERAPIST_DETAILS_SPECIFIED: "CLIENT_THERAPIST_DETAILS_SPECIFIED",
    CLIENT_SOCIALWORKER_DETAILS_SPECIFIED: "CLIENT_SOCIALWORKER_DETAILS_SPECIFIED",
    CLIENT_DOB_SPECIFIED: "CLIENT_DOB_SPECIFIED",
    CLIENT_GENDER_SPECIFIED: "CLIENT_GENDER_SPECIFIED",
    CLIENT_PHONENUMBER_SPECIFIED: "CLIENT_PHONENUMBER_SPECIFIED",
    CLIENT_EMAIL_SPECIFIED: "CLIENT_EMAIL_SPECIFIED",
    CLIENT_REFID_SPECIFIED: "CLIENT_REFID_SPECIFIED",
    CLIENT_CLIENT_NOTES_SPECIFIED: "CLIENT_CLIENT_NOTES_SPECIFIED",
    SEND_STAFF_PROFILE: "SEND_STAFF_PROFILE",
    CLIENT_EVENTS_DASHBOARD_LIST_FOCUSED: "CLIENT_EVENTS_DASHBOARD_LIST_FOCUSED",

    LIST_CLIENT_POLICY: "LIST_CLIENT_POLICY",
    ADD_CLIENT_POLICY: "ADD_CLIENT_POLICY",
    EDIT_CLIENT_POLICY: "EDIT_CLIENT_POLICY",
    EDIT_CLIENT_POLICY_SIGNATURE: "EDIT_CLIENT_POLICY_SIGNATURE",

    WEB_ACCESS: "WEB_ACCESS",
    APP_ACCESS: "APP_ACCESS",

    IS_CLIENT_ACCESS: "IS_CLIENT_ACCESS",

    LIST_STAFF: "LIST_STAFF",
    ADD_STAFF: "ADD_STAFF",
    EDIT_STAFF: "EDIT_STAFF",
    EDIT_OWN_STAFF: "EDIT_OWN_STAFF",
    DELETE_STAFF: "DELETE_STAFF",
    LIST_STAFF_DIARY: "LIST_STAFF_DIARY",
    ADD_STAFF_DIARY_ENTRY: "ADD_STAFF_DIARY_ENTRY",
    LIST_STAFF_EVENTS: "LIST_STAFF_EVENTS",
    ADD_STAFF_EVENTS: "ADD_STAFF_EVENTS",
    EDIT_STAFF_EVENTS: "EDIT_STAFF_EVENTS",
    LIST_STAFF_AVAILABILITY: "LIST_STAFF_AVAILABILITY",
    ADD_STAFF_AVAILABILITY: "ADD_STAFF_AVAILABILITY",
    EDIT_STAFF_AVAILABILITY: "EDIT_STAFF_AVAILABILITY",
    LIST_STAFF_COMPLIANCE: "LIST_CLIENT_COMPLIANCE",
    ADD_STAFF_COMPLIANCE: "ADD_CLIENT_COMPLIANCE",
    EDIT_STAFF_COMPLIANCE: "EDIT_CLIENT_COMPLIANCE",
    LIST_STAFF_TIMESHEETS: "LIST_STAFF_TIMESHEETS",
    ADD_STAFF_TIMESHEETS: "ADD_STAFF_TIMESHEETS",
    EDIT_STAFF_TIMESHEETS: "EDIT_STAFF_TIMESHEETS",
    LIST_STAFF_QUALIFICATIONS: "LIST_STAFF_QUALIFICATIONS",
    ADD_STAFF_QUALIFICATIONS: "ADD_STAFF_QUALIFICATIONS",
    EDIT_STAFF_QUALIFICATIONS: "EDIT_STAFF_QUALIFICATIONS",
    LIST_STAFF_BLOCK_LIST: "LIST_STAFF_BLOCK_LIST",
    ADD_STAFF_BLOCK_LIST: "ADD_STAFF_BLOCK_LIST",
    EDIT_STAFF_BLOCK_LIST: "EDIT_STAFF_BLOCK_LIST",

    LIST_ALL_STAFF_TIME_OFF_LIST: "LIST_ALL_STAFF_TIME_OFF_LIST",
    LIST_STAFF_TIME_OFF_LIST: "LIST_STAFF_TIME_OFF_LIST",
    ADD_STAFF_TIME_OFF_LIST: "ADD_STAFF_TIME_OFF_LIST",
    EDIT_STAFF_TIME_OFF_LIST: "EDIT_STAFF_TIME_OFF_LIST",
    APPROVE_STAFF_TIME_OFF_LIST: "APPROVE_STAFF_TIME_OFF_LIST",

    LIST_STAFF_POLICY: "LIST_STAFF_POLICY",
    ADD_STAFF_POLICY: "ADD_STAFF_POLICY",
    EDIT_STAFF_POLICY: "EDIT_STAFF_POLICY",
    EDIT_STAFF_POLICY_SIGNATURE: "EDIT_STAFF_POLICY_SIGNATURE",
    STAFF_JOBROLE_SPECIFIED: "STAFF_JOBROLE_SPECIFIED",

    LIST_ALL_EVENTS: "LIST_ALL_EVENTS",

    EVENTS_DISTRIBUTABLE: "EVENTS_DISTRIBUTABLE",
    EVENTS_SERVICES_INPUT: "EVENTS_SERVICES_INPUT",
    LIST_DISTRIBUTED_EVENTS: "LIST_DISTRIBUTED_EVENTS",
    ACCEPT_DISTRIBUTED_EVENT: "ACCEPT_DISTRIBUTED_EVENT",
    EVENTS_BREAK_SPECIFICED: "EVENTS_BREAK_SPECIFICED",
    EVENTS_FLEXIBLE_START_SPECIFIED: "EVENTS_FLEXIBLE_START_SPECIFIED",
    EVENTS_JOBROLE_SPECIFIED: "EVENTS_JOBROLE_SPECIFIED",
    EVENTS_SCHEDULE_CLIENT_FOCUSED: "EVENTS_SCHEDULE_CLIENT_FOCUSED",
    EVENTS_SCHEDULE_STAFF_FOCUSED: "EVENTS_SCHEDULE_STAFF_FOCUSED",
    EVENTS_REQUIRE_CHECKIN: "EVENTS_REQUIRE_CHECKIN",
    EVENTS_REQUIRE_SIGNATURE: "EVENTS_REQUIRE_SIGNATURE",
    EVENTS_AUTO_SEND_TIMESHEET: "EVENTS_AUTO_SEND_TIMESHEET",
    EVENTS_ADDITIONAL_STAFF_INPUT: "EVENTS_ADDITIONAL_STAFF_INPUT",
    EVENTS_MULTI_DAY: "EVENTS_MULTI_DAY",
    EVENTS_SCHEDULE_DUPLICATE: "EVENTS_SCHEDULE_DUPLICATE",

    LIST_TEAMS: "LIST_TEAMS",
    ADD_TEAMS: "ADD_TEAMS",
    EDIT_TEAMS: "EDIT_TEAMS",

    LIST_BUSINESS_ANALYTICS: "LIST_BUSINESS_ANALYTICS",

    LIST_SYSTEM_SETTINGS: "LIST_SYSTEM_SETTINGS",
    LIST_SYSTEM_TASKS: "LIST_SYSTEM_TASKS",
    EDIT_SYSTEM_TASKS: "EDIT_SYSTEM_TASKS",
    ADD_SYSTEM_TASKS: "ADD_SYSTEM_TASKS",
    LIST_SYSTEM_COMPLIANCES: "LIST_SYSTEM_COMPLIANCES",
    EDIT_SYSTEM_COMPLIANCES: "EDIT_SYSTEM_COMPLIANCES",
    ADD_SYSTEM_COMPLIANCES: "ADD_SYSTEM_COMPLIANCES",
    LIST_SYSTEM_RISKASSESSMENTS: "LIST_SYSTEM_RISKASSESSMENTS",
    EDIT_SYSTEM_RISKASSESSMENTS: "EDIT_SYSTEM_RISKASSESSMENTS",
    ADD_SYSTEM_RISKASSESSMENTS: "ADD_SYSTEM_RISKASSESSMENTS",
    LIST_SYSTEM_CAREPLANS: "LIST_SYSTEM_CAREPLANS",
    EDIT_SYSTEM_CAREPLANS: "EDIT_SYSTEM_CAREPLANS",
    ADD_SYSTEM_CAREPLANS: "ADD_SYSTEM_CAREPLANS",
    LIST_SYSTEM_MEDICINES: "LIST_SYSTEM_MEDICINES",
    EDIT_SYSTEM_MEDICINES: "EDIT_SYSTEM_MEDICINES",
    ADD_SYSTEM_MEDICINES: "ADD_SYSTEM_MEDICINES",
    LIST_SYSTEM_MISSED_MEDICATION_REASONS: "LIST_SYSTEM_MISSED_MEDICATION_REASONS",
    EDIT_SYSTEM_MISSED_MEDICATION_REASONS: "EDIT_SYSTEM_MISSED_MEDICATION_REASONS",
    ADD_SYSTEM_MISSED_MEDICATION_REASONS: "ADD_SYSTEM_MISSED_MEDICATION_REASONS",
    LIST_SYSTEM_ADDITIONAL_FORMS: "LIST_SYSTEM_ADDITIONAL_FORMS",
    EDIT_SYSTEM_ADDITIONAL_FORMS: "EDIT_SYSTEM_ADDITIONAL_FORMS",
    ADD_SYSTEM_ADDITIONAL_FORMS: "ADD_SYSTEM_ADDITIONAL_FORMS",
    LIST_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST: "LIST_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST",
    EDIT_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST: "EDIT_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST",
    ADD_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST: "ADD_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST",
    LIST_SYSTEM_POLICY: "LIST_SYSTEM_POLICY",
    EDIT_SYSTEM_POLICY: "EDIT_SYSTEM_POLICY",
    ADD_SYSTEM_POLICY: "ADD_SYSTEM_POLICY",
    LIST_SYSTEM_JOBROLES: "LIST_SYSTEM_JOBROLES",
    EDIT_SYSTEM_JOBROLES: "EDIT_SYSTEM_JOBROLES",
    ADD_SYSTEM_JOBROLES: "ADD_SYSTEM_JOBROLES",
    LIST_SYSTEM_TIME_OFF_APPROVERS: "LIST_SYSTEM_TIME_OFF_APPROVERS",
    EDIT_SYSTEM_TIME_OFF_APPROVERS: "EDIT_SYSTEM_TIME_OFF_APPROVERS",
    ADD_SYSTEM_TIME_OFF_APPROVERS: "ADD_SYSTEM_TIME_OFF_APPROVERS",

    LIST_SYSTEM_DEFAULT_QUALIFICATIONS: "LIST_SYSTEM_QUALIFICATIONS",
    EDIT_SYSTEM_DEFAULT_QUALIFICATIONS: "EDIT_SYSTEM_QUALIFICATIONS",
    ADD_SYSTEM_DEFAULT_QUALIFICATIONS: "ADD_SYSTEM_QUALIFICATIONS",

    VIEW_ARCHIVES: "VIEW_ARCHIVES",

    EDIT_BUSINESS_ACCOUNT: "EDIT_BUSINESS_ACCOUNT",

    SUBSCRIPTIONS_ENABLED: "SUBSCRIPTIONS_ENABLED",
    VIEW_SUBSCRIPTION_DETAILS: "VIEW_SUBSCRIPTION_DETAILS",
}

export const EVENT_STATUS = {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
    MISSED: "MISSED",
    CHECKED_IN: "CHECKED_IN",
}

export const TIME_SHEET_STATUS = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING"
}

export const TIME_SHEET_TIME_AMMEND_STATUS = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
}

export const TIME_OFF_STATUS = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    REJECTED: "REJECTED",
}

export const CLIENT_ACCESS_STATUS = {
    ACCEPTED: "ACCEPTED",
    PENDING: "PENDING",
}

export const EVENT_ANALYTICS_EVENT_TYPE = {
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    MISSED: "MISSED"
}

export const USER_ANALYTICS_TYPE = {
    STAFF_ADDED: "STAFF_ADDED",
    CLIENT_ADDED: "CLIENT_ADDED",
    STAFF_ARCHIVED: "STAFF_ARCHIVED",
    CLIENT_ARCHIVED: "CLIENT_ARCHIVED"
}

export const REPETITION_END_DATE_TYPE = {
    NEVER: "NEVER",
    END_AFTER: "END_AFTER",
}

export const EMAIL_LIST_PURPOSE = {
    TIMESHEETS: "TIMESHEETS",
    STAFF_PROFILE: "STAFF_PROFILE",
    SHIFT_CHANGES: "SHIFT_CHANGES",
    OTHER: "OTHER"
}

export const CHECKOUT_SESSION_STATUS = {
    PAID: "PAID",
    UNPAID: "UNPAID",
    NO_PAYMENT_REQUIRED: "NO_PAYMENT_REQUIRED",
}

export const INVOICE_STATUS = {
    "DRAFT": "DRAFT",
    "OPEN": "OPEN",
    "PAID": "PAID",
    "UNCOLLECTIBLE": "UNCOLLECTIBLE",
    "VOID": "VOID",
}

export const SUBSCRIPTION_STATUS = {
    "ACTIVE": "ACTIVE",
    "CANCELLED": "CANCELLED",
    "INCOMPLETE": "INCOMPLETE",
    "INCOMPLETE_EXPIRED": "INCOMPLETE_EXPIRED",
    "PAST_DUE": "PAST_DUE",
    "PAUSED": "PAUSED",
    "TRIALING": "TRIALING",
    "UNPAID": "UNPAID",
}

export const DISTRIBUTABLE_STATUS = {
    "ACCEPTED": "ACCEPTED",
    "PENDING_APPROVAL": "PENDING_APPROVAL",
    "REJECTED": "REJECTED"
}

export const POLICY_STATUS = {
    "CREATED": "CREATED",
    "OPENED": "OPENED",
    "ACCEPTED": "ACCEPTED"
}