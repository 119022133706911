import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularLoader({$size,$color}) {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress size={$size} style={{color:$color,} }/>
    </Box>
  );
}