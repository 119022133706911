import React, { useState } from 'react'
import { useAdditionalFormsSettingsController } from '../SettingsControllers/useAdditionalFormsController'
import { Box, FlexBox, ListBox } from '../../../Components/Boxes';
import { Text } from '../../../Components/Text';
import { Button } from '../../../Components/Input';
import Icon from '../../../Components/Icons';
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader';
import EmptyState from '../../../Components/NoRecordFound';
import FormDialog from '../../../Components/Modals/FormDialog';
import AddConfigAdditionalFormsDialog from '../Dialogs/AddConfigAdditionalFormsDialog';
import { useResponsive } from '../../../Components/Hooks';

export default function CustomFormsSettings() {
  const {
    title,
    additionalForms,

    canEdit,
    canAdd,

    onSave,
    onUpdate,
    onDelete,

    isUpdatingConfig,
    isDeletingConfig,
    isGettingConfigs,

    updateErrorMessage,
    deleteErrorMessage,
    gettingErrorMessage,
  } = useAdditionalFormsSettingsController()
  const isDesktop = useResponsive({ xs: false, sm: true });

  const [open, setOpen] = useState(false);
  const [editingAdditionalForm, setEditingAdditionalForm] = useState("")

  function handleDialog(additionalForm) {
    setOpen(!open)
    setEditingAdditionalForm(additionalForm)
  }

  return (
    <Box>
      <Text $fontSize="heading-3">{title}</Text>
      {canAdd && <FlexBox
        $justifyContent="flex-end"
        $width="100%"
        $marginTop={2}
        $marginRight={4}
      >
        <Button
          $onClick={() => {
            handleDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Add</Text>
        </Button>
      </FlexBox>}
      <Box $marginTop="2" />
      {!isGettingConfigs && additionalForms?.map((additionalForm, index) => {
        return (
          <ListBox
            $index={index}
            $onClick={() => {
              if (canEdit != true) return
              handleDialog(additionalForm)
            }}
          >
            <Text $color="text">{additionalForm.name}</Text>
          </ListBox>
        )
      })}

      {isGettingConfigs && <FlexBox $width="100%" $marginTop={2} $flexDirection="column">
        {[1, 2, 3].map((val, index) => {
          return (
            <Box
              $marginBottom="2"
              $width="100%"
            >
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={"40px"}
              />
            </Box>
          );
        })}
      </FlexBox>}

      {!isGettingConfigs && additionalForms?.length <= 0 && <FlexBox $marginTop={7} $justifyContent="center">
        <EmptyState />
      </FlexBox>}

      {open && <FormDialog
        $open={open}
        $handleDialog={handleDialog}
        $title={`Add ${title}`}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <AddConfigAdditionalFormsDialog
          additionalForm={editingAdditionalForm}
          loading={isUpdatingConfig || isDeletingConfig}
          onDelete={async () => {
            await onDelete(editingAdditionalForm)
            handleDialog()
          }}
          onSave={async (val) => {
            if (editingAdditionalForm) {
              await onUpdate(editingAdditionalForm, { ...val })
            } else {
              await onSave({ ...val })
            }
            handleDialog()
          }}
          handleDialog={handleDialog}
          errorMessage={updateErrorMessage || deleteErrorMessage}
        />
      </FormDialog>}
    </Box>
  )
}
