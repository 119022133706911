import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
    createFileApiService,
    deleteFileApiService,
    getFilesApiService,
    restoreArchivedFileApiService,
    updateFileApiService
} from "../../../services/applicationBackendApis/files"
import * as asyncStates from "../../constants/asyncStates"
import { PAGINATION_LIMITS } from "../../../constants"

const initialState = {
    files: [],
    file: null,
    moreFilesLeftInPagination: false,
    fileUploadPercentage: null,
    errorMessages: {
        restoreFileErrorMessage: null,
        createFileErrorMessage: null,
        deleteFileErrorMessage: null,
        updateFileErrorMessage: null,
        getFilesErrorMessage: null,
        getFileErrorMessage: null,
    },
    statuses: {
        restoreFileStatus: null,
        createFileStatus: null,
        deleteFileStatus: null,
        updateFileStatus: null,
        getFilesStatus: null,
        getFileStatus: null,
    },
}

export const createFileReducer = createAsyncThunk(
    'files/createFile',
    async (fileData, thunkApi) => {
        const { dispatch } = thunkApi
        try {
            const response = await createFileApiService({
                clientId: fileData.clientId,
                fileName: fileData.fileName,
                notes: fileData.notes,
                shareWithCarers: fileData.shareWithCarers,
                file: fileData.file,
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    if (percent < 100) {
                        dispatch(fileUploadPercentageUpdatedReducer(percent))
                    } else {
                        dispatch(fileUploadPercentageUpdatedReducer(null))
                    }
                },
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const restoreArchivedFileReducer = createAsyncThunk(
    "files/restoreArchivedFile",
    async (fileData) => {
      try {
        const response = await restoreArchivedFileApiService({
          clientId: fileData.clientId,
          id: fileData.fileId,
        });
        return response.data;
      } catch (error) {
        throw new Error(error?.data?.error?.message || "Error");
      }
    }
  );

export const updateFileReducer = createAsyncThunk(
    'files/updateFile',
    async (fileData) => {
        try {
            const response = await updateFileApiService({
                clientId: fileData.clientId,
                fileId: fileData.fileId,
                fileName: fileData.fileName,
                notes: fileData.notes,
                shareWithCarers: fileData.shareWithCarers,
                file: fileData.file,
                onUploadProgress: fileData.onUploadProgress,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getFilesReducer = createAsyncThunk(
    'files/getFiles',
    async (fileData) => {
        try {
            const response = await getFilesApiService({
                clientId: fileData.clientId,
                startAfter: fileData.startAfter,
                limit: fileData.limit ?? PAGINATION_LIMITS.FILES,
                isArchived: fileData.isArchived,
            })
            return {
                data: response.data,
                limit: fileData.limit ?? PAGINATION_LIMITS.FILES,
                startAfter: fileData.startAfter,
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getFileReducer = createAsyncThunk(
    'files/getFile',
    async (fileData, thunkApi) => {
        try {
            const { getState } = thunkApi
            const files = getState()
            const fileFound = files.find((file) => file.id === fileData.fileId)
            if (fileFound && Object.keys(fileFound) !== 0) {
                return fileFound
            } else {
                const response = await getFilesApiService({
                    clientId: fileData.clientId,
                    fileId: fileData.fileId,
                })
                return response.data
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const deleteFileReducer = createAsyncThunk(
    'files/deleteFiles',
    async (fileData) => {
        try {
            const response = await deleteFileApiService({
                clientId: fileData.clientId,
                fileId: fileData.fileId,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

const filesSlice = createSlice({
    name: "files",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        fileUploadPercentageUpdatedReducer: (state, action) => {
            state.fileUploadPercentage = action.payload
        },
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {}
        },
        clearAllLocalFilesReducer: (state, action) => {
            state.files = []
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {}
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {

        // start handling create file async calls
        builder.addCase(createFileReducer.pending, (state, action) => {
            state.statuses.createFileStatus = asyncStates.PENDING
            state.errorMessages.createFileErrorMessage = null
        })
        builder.addCase(createFileReducer.rejected, (state, action) => {
            state.statuses.createFileStatus = asyncStates.FAILURE
            state.errorMessages.createFileErrorMessage = action.error?.message
        })
        builder.addCase(createFileReducer.fulfilled, (state, action) => {
            state.statuses.createFileStatus = asyncStates.SUCCESS
            state.errorMessages.createFileErrorMessage = null
        })

        builder.addCase(restoreArchivedFileReducer.pending, (state, action) => {
          state.statuses.restoreFileStatus = asyncStates.PENDING;
          state.errorMessages.restoreFileErrorMessage = null;
        });
        builder.addCase(restoreArchivedFileReducer.rejected, (state, action) => {
          state.statuses.restoreFileStatus = asyncStates.FAILURE;
          state.errorMessages.restoreFileErrorMessage =
            action.error?.message;
        });
        builder.addCase(restoreArchivedFileReducer.fulfilled, (state, action) => {
          state.statuses.restoreFileStatus = asyncStates.SUCCESS;
          state.errorMessages.restoreFileErrorMessage = null;
        });


        // start handling update file async calls
        builder.addCase(updateFileReducer.pending, (state, action) => {
            state.statuses.updateFileStatus = asyncStates.PENDING
            state.errorMessages.updateFileErrorMessage = null
        })
        builder.addCase(updateFileReducer.rejected, (state, action) => {
            state.statuses.updateFileStatus = asyncStates.FAILURE
            state.errorMessages.updateFileErrorMessage = action.error?.message
        })
        builder.addCase(updateFileReducer.fulfilled, (state, action) => {
            state.statuses.updateFileStatus = asyncStates.SUCCESS
            state.errorMessages.updateFileErrorMessage = null
        })

        // start handling get files async calls
        builder.addCase(getFilesReducer.pending, (state, action) => {
            state.statuses.getFilesStatus = asyncStates.PENDING
            state.errorMessages.getFilesErrorMessage = null
        })
        builder.addCase(getFilesReducer.rejected, (state, action) => {
            state.statuses.getFilesStatus = asyncStates.FAILURE
            state.errorMessages.getFilesErrorMessage = action.error?.message
        })
        builder.addCase(getFilesReducer.fulfilled, (state, action) => {
            state.statuses.getFilesStatus = asyncStates.SUCCESS
            state.errorMessages.getFilesErrorMessage = null
            state.files = action.payload.startAfter ? [...state.files, ...action.payload.data.files] : action.payload.data.files;

            if (action.payload.data.files.length >= action.payload.limit) {
              state.moreFilesLeftInPagination = true;
            } else {
              state.moreFilesLeftInPagination = false;
            }
        })

        // start handling get file async calls
        builder.addCase(getFileReducer.pending, (state, action) => {
            state.statuses.getFileStatus = asyncStates.PENDING
            state.errorMessages.getFileErrorMessage = null
        })
        builder.addCase(getFileReducer.rejected, (state, action) => {
            state.statuses.getFileStatus = asyncStates.FAILURE
            state.errorMessages.getFileErrorMessage = action.error?.message
        })
        builder.addCase(getFileReducer.fulfilled, (state, action) => {
            state.statuses.getFileStatus = asyncStates.SUCCESS
            state.errorMessages.getFileErrorMessage = null
            state.file = action.payload
        })

        // start handling delete file async calls
        builder.addCase(deleteFileReducer.pending, (state, action) => {
            state.statuses.deleteFileStatus = asyncStates.PENDING
            state.errorMessages.deleteFileErrorMessage = null
        })
        builder.addCase(deleteFileReducer.rejected, (state, action) => {
            state.statuses.deleteFileStatus = asyncStates.FAILURE
            state.errorMessages.deleteFileErrorMessage = action.error?.message
        })
        builder.addCase(deleteFileReducer.fulfilled, (state, action) => {
            state.statuses.deleteFileStatus = asyncStates.SUCCESS
            state.errorMessages.deleteFileErrorMessage = null
        })
    }
})

export const { fileUploadPercentageUpdatedReducer, clearAllLocalFilesReducer, clearAllErrorMessagesReducer, clearAllStatusesReducer } = filesSlice.actions

export default filesSlice.reducer