import React, { useEffect, useState } from "react";
import {
    Box,
    ErrorAndSuccess,
    FlexBox,
    ListBox,
} from "../../../../../Components/Boxes/index";
import { useResponsive } from "../../../../../Components/Hooks";
import Icon from "../../../../../Components/Icons";
import { Button } from "../../../../../Components/Input";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import { EditAdditionalForm } from "../Dialogs/AdditionalForm/EditAdditionalForm";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import { formateDateToReadableFormatWithoutTime } from "../../../../../utiles/dateformate";
import useAdditionalFormDispatchers from "../../../../../redux/dispatchers/useAdditionalFormDispatchers";
import {
    useAdditionalFormsGetErrorMessageSelector,
    useAdditionalFormsGetStatusSelector,
    useAdditionalFormsSelector,
    useMoreAdditionalFormItemsLeftInPaginationSelector,
} from "../../../../../redux/selectors/useAdditionalFormsSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";

const AdditionalForm = ({ clientId }) => {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        accessArchives
    } = usePermissionsController()

    // ---------------- states ------------------------
    const [open, setOpen] = useState(false);
    function HandleAdditionalFormDialog() {
        setOpen(!open);
    }
    const [type, setType] = useState("");
    //storing the selected additionalForm and sending to the dialog
    const [additionalFormToEdit, setAdditionalFormToEdit] = useState(null);
    const [isArchived, setIsArchived] = useState(false)

    // ---------------- reducers ----------------------
    const { getAdditionalForms, clearAllLocalAdditionalForms } = useAdditionalFormDispatchers();

    // ---------------- selectors ---------------------
    const allAdditionalForms = useAdditionalFormsSelector();
    const getAdditionalFormsStatus = useAdditionalFormsGetStatusSelector();
    const complianceGetErrorMessage = useAdditionalFormsGetErrorMessageSelector();
    const errorMessage = complianceGetErrorMessage;
    const moreAdditionalFormItemsLeftInPagination = useMoreAdditionalFormItemsLeftInPaginationSelector()

    // ---------------- useEffect ---------------------
    //Fetching the client from database

    const loadMore = () => {
        getAdditionalForms({
            clientId: clientId,
            startAfter: allAdditionalForms[allAdditionalForms.length - 1].id,
            isArchived: isArchived,
        });
    }

    useEffect(() => {
        clearAllLocalAdditionalForms();
        getAdditionalForms({
            clientId: clientId,
            isArchived: isArchived,
        });
    }, [isArchived]);

    return (
        <Box
            $width="100%"
            $backgroundColor="#fff"
        >
            <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
                {accessArchives && <Tabs
                    $onChanged={(val) => {
                        if (val == 0) {
                            setIsArchived(false)
                        } else {
                            setIsArchived(true)
                        }
                    }}
                    $value={isArchived ? 1 : 0}
                    $tabs={[
                        { name: "Active" },
                        { name: "Archived" },
                    ]}
                    $width={"fit-content"}
                />}

                <Button
                    $onClick={() => {
                        setType("add");
                        setAdditionalFormToEdit(null);
                        HandleAdditionalFormDialog();
                    }}
                >
                    <Icon $icon="Add" $color="white"></Icon>
                    <Text $color={"white"}>New form</Text>
                </Button>
            </FlexBox>

            <FlexBox $width="100%" $marginTop={3} $padding="3">
                <Box $width="40%">
                    <Text $fontWeight="bold">Title</Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                    <Text> Form type </Text>
                </Box>
                <Box $width="15%" $display="flex" $justifyContent="center">
                    <Text>Last Updated</Text>
                </Box>
                <Box $width="25%" $display="flex" $justifyContent="center">
                    <Text>Updated By</Text>
                </Box>
            </FlexBox>

            {getAdditionalFormsStatus === asyncStates.SUCCESS && allAdditionalForms.length < 1 ? (
                <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
                    <EmptyState />
                </FlexBox>
            ) : (
                allAdditionalForms?.map((value, index) => {
                    return (
                        <ListBox
                            $width="100%"
                            $marginTop={2}
                            key={index}
                            $index={index}
                            $onClick={() => {
                                setAdditionalFormToEdit(value);
                                setType("edit");
                                HandleAdditionalFormDialog();
                            }}
                        >
                            <FlexBox $width="100%" >
                                <Box $width="40%">
                                    <Text
                                        $fontWeight="bold"
                                        $wordWrap="anywhere"
                                    >
                                        {value.title}
                                    </Text>
                                </Box>
                                <Box $width="20%" $display="flex" $justifyContent="center">
                                    <Text $fontWeight="bold">
                                        {value.name}
                                    </Text>
                                </Box>
                                <Box $width="15%" $display="flex" $justifyContent="center">
                                    <Text $fontWeight="bold" $wordWrap="anywhere">
                                        {value.lastUpdationDate ? formateDateToReadableFormatWithoutTime(value.lastUpdationDate) : "-"}
                                    </Text>
                                </Box>
                                <Box $width="25%" $display="flex" $justifyContent="center">
                                    <Text $fontWeight="bold" $wordWrap="anywhere">
                                        {value.updatedBy?.name ?? "Not Provided"}
                                    </Text>
                                </Box>
                            </FlexBox>
                        </ListBox>
                    );
                })
            )}
            {getAdditionalFormsStatus === asyncStates.PENDING &&
                [1, 2, 3, 4].map((value) => {
                    return (
                        <Box $width="100%" $marginTop="2" key={value}>
                            <MuiSkeletonLoader
                                $variant="rectangular"
                                $width={"100%"}
                                $height={40}
                            ></MuiSkeletonLoader>
                        </Box>
                    );
                })}
            {errorMessage && (
                <FlexBox $marginTop={2} $width="100%">
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                </FlexBox>
            )}

            {moreAdditionalFormItemsLeftInPagination && <FlexBox $marginTop="6" $justifyContent="center" $width="100%">
                <Button $onClick={() => {
                    loadMore()
                }} >Load More</Button>
            </FlexBox>}

            <FormDialog
                $open={open}
                $handleDialog={HandleAdditionalFormDialog}
                $title={"Additional Form"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <EditAdditionalForm
                    clientId={clientId}
                    $handleDialog={HandleAdditionalFormDialog}
                    $type={type}
                    $additionalFormToEdit={type === "edit" && additionalFormToEdit}
                />
            </FormDialog>
        </Box>
    );
};

export default AdditionalForm;
