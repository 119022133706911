import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetCompliancesErrorMessageSelector, useGetCompliancesStatusSelector, useCompliancesSelector, useCompliancesUpdateStatusSelector, useUpdateCompliancesErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useCompliancesSettingsController() {
    const { accessAddSettingsCompliance, accessEditSettingsCompliance } = usePermissionsController()
    const complianceNames = useCompliancesSelector()
    const getCompliancesErrorMessages = useGetCompliancesErrorMessageSelector()
    const updateCompliancesErrorMessages = useUpdateCompliancesErrorMessageSelector()

    const getCompliancesStatuses = useGetCompliancesStatusSelector()
    const updateCompliancesStatuses = useCompliancesUpdateStatusSelector()

    const names = useMemo(() => [...complianceNames], [complianceNames])

    const {
        updateCompliances,
        getCompliances,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getCompliances()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateCompliances({
            compliances: newNames
        }).unwrap();
        getCompliances()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateCompliances({
            compliances: newNames
        }).unwrap();
        getCompliances()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateCompliances({
            compliances: newNames
        }).unwrap();
        getCompliances()
        return
    }

    return {
        title: "Compliances",
        names: names,

        canEdit: accessEditSettingsCompliance,
        canAdd: accessAddSettingsCompliance,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateCompliancesStatuses == asyncStates.PENDING,
        isDeletingConfig: updateCompliancesStatuses == asyncStates.PENDING,
        isGettingConfigs: getCompliancesStatuses == asyncStates.PENDING,

        updateErrorMessage: updateCompliancesErrorMessages,
        deleteErrorMessage: updateCompliancesErrorMessages,
        gettingErrorMessage: getCompliancesErrorMessages,
    }
}