import React, { useState } from "react";
import { Box, FlexBox } from "../../../Components/Boxes/index";
import * as asyncStates from "../../../redux/constants/asyncStates";
import MuiSkeletonLoader from "../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import EmptyState from "../../../Components/NoRecordFound";
import DiaryCard from "./DiaryCard"
import { Button, Textarea } from "../../../Components/Input";
import AddDiaryNote from "./AddDiaryNote";
import { useEffect } from "react";

// additionalStaff
export default function DiaryView(props) {
  const {
    diaries,
    getDiariesState,
    isStaff,
    isClient,
    moreDirayItemsLeftInPaginationSelector,
    loadMore,

    createDiaryEntry,
    createDiaryState,
    createDiaryErrorMessage,
  } = props.controller

  const [highlight, setHighlight] = useState(false)

  useEffect(() => {
    if (createDiaryState === asyncStates.SUCCESS) {
      setHighlight(false)
    }
  }, [createDiaryState])

  return (
    <Box
      $width="100%"
      $height="100%"
      $backgroundColor="#fff"
      $borderRadius="10px"
      $marginTop={3}
    >
      <Box
        $width="100%"
      >
        {!highlight && <Box $width="100%" $onClick={() => { setHighlight(true) }}>
          <Textarea
            $hint={"Add Notes..."}
            $name={"notes"}
            $value={""}
            $rows={2}
          />
        </Box>}
        {highlight && <AddDiaryNote
          onSubmit={({ note, users, }) => {
            createDiaryEntry({ note, users })
          }}
          onCancel={() => setHighlight(false)}
          loading={createDiaryState === asyncStates.PENDING}
          errorMessage={createDiaryErrorMessage}
        />}
      </Box>

      <Box $marginTop={5} />

      <hr />

      <Box $marginTop={5} />
      {diaries?.map((diary, index) => {
        return (
          <Box $width="100%" $marginTop={index > 0 ? 2 : 0}>
            <DiaryCard diary={diary} user={isClient ? diary.staff : diary.client} />
          </Box>
        );
      })}

      {getDiariesState == asyncStates.PENDING && [1, 2, 3, 4].map((value) => {
        return (
          <div style={{ width: "100%", marginTop: "8px" }} key={value}>
            <MuiSkeletonLoader
              $variant="rectangular"
              $width={"100%"}
              $height={80}
            ></MuiSkeletonLoader>
          </div>
        )
      })}

      {getDiariesState === asyncStates.SUCCESS && diaries?.length < 1 &&
        <FlexBox $width="100%" $justifyContent="center" $marginTop={7}>
          <EmptyState />
        </FlexBox>
      }

      {moreDirayItemsLeftInPaginationSelector && <FlexBox $width="100%" $justifyContent="center" $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}
    </Box>
  );
}
