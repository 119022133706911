import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetMedicinesErrorMessageSelector, useGetMedicinesStatusSelector, useMedicinesSelector, useMedicinesUpdateStatusSelector, useUpdateMedicinesErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useMedicationsSettingsController() {
    const { accessAddSettingsMedication, accessEditSettingsMedication } = usePermissionsController()
    const medicines = useMedicinesSelector()
    const getMedicinesErrorMessages = useGetMedicinesErrorMessageSelector()
    const updateMedicinesErrorMessages = useUpdateMedicinesErrorMessageSelector()

    const getMedicinesStatuses = useGetMedicinesStatusSelector()
    const updateMedicinesStatuses = useMedicinesUpdateStatusSelector()

    // const names = useMemo(() => {
    //     return medicines?.map(medicine => {
    //         return `${medicine.name}${medicine.strength}${medicine.form ? ` ${medicine.form}` : ""}`
    //     })
    // }, [medicines])

    const {
        updateMedicines,
        getMedicines,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getMedicines()
    }, [])

    const onSave = async (val) => {
        const newMedicines = [...medicines]
        newMedicines.push(val)
        await updateMedicines({
            medicines: newMedicines
        }).unwrap();
        getMedicines()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = medicines.indexOf(prevValue)
        const newMedicines = [...medicines]
        if (index >= 0) {
            newMedicines[index] = newValue
        }
        await updateMedicines({
            medicines: newMedicines
        }).unwrap();
        getMedicines()
        return
    }

    const onDelete = async (prevValue) => {
        const index = medicines.indexOf(prevValue)
        const newMedicines = [...medicines]
        if (index >= 0) {
            newMedicines.splice(index, 1);
        }
        await updateMedicines({
            medicines: newMedicines
        }).unwrap();
        getMedicines()
        return
    }

    return {
        title: "Medications",
        medicines: medicines,

        canEdit: accessEditSettingsMedication,
        canAdd: accessAddSettingsMedication,

        isUpdatingConfig: updateMedicinesStatuses == asyncStates.PENDING,
        isDeletingConfig: updateMedicinesStatuses == asyncStates.PENDING,
        isGettingConfigs: getMedicinesStatuses == asyncStates.PENDING,

        updateErrorMessage: updateMedicinesErrorMessages,
        deleteErrorMessage: updateMedicinesErrorMessages,
        gettingErrorMessage: getMedicinesErrorMessages,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,
    }
}