import axios from 'axios';
import { cookieStorageService } from '../../utiles/storage';
import { getBaseUrl } from './configs';
import { formateDateToISOString } from '../../utiles/dateformate';
var _ = require('lodash');

export const convertObjectToQueryString = (queryParams, prefix = '&', inRecursion = false) => {
    try {
        if (queryParams != null && Object.keys(queryParams).length !== 0) {
            let query = '';
            for (const [key, value] of Object.entries(queryParams)) {
                if (value != null) {
                    if (_.isString(value) || _.isNumber(value) || _.isBoolean(value)) {
                        query += `${prefix}${key}=${value}`
                    } else if (_.isArray(value)) {
                        value.forEach((k) => {
                            query += convertObjectToQueryString({ [key]: k }, `${prefix}`);
                        });
                    } else if (_.isDate(value)) {
                        query += `${prefix}${key}=${formateDateToISOString(value)}`
                    } else {
                        console.log("none matched")
                    }
                }
            }
            return query;
        } else {
            return "";
        }
    } catch (e) {
        console.log(e);
        return "";
    }
}

class ApiResponse {
    constructor({ status, data, headers }) {
        this.status = status;
        this.data = data;
        this.headers = headers;
        this.name = 'ApiResponse';
    }
}

// export const sendApiRequest = async ({
//     url,
//     query,
//     method,
//     body,
//     headers,
//     signal,
//     onUploadProgress,
//     onDownloadProgress,
// }) => {
//     try {
//         const baseUrl = `${url}?${convertObjectToQueryString(query)}`
//         console.log(`${method}`, ' | ', baseUrl, ' >> ', body)
//         let options = {
//             headers: { ...headers, "Access-Control-Allow-Origin": "*" },
//             signal: signal,
//             timeout: 60000,
//             onUploadProgress: onUploadProgress,
//             onDownloadProgress: onDownloadProgress,
//         }

//         let data;
//         console.log("hmmmm")
//         if (method === 'GET') {
//             data = await axios.get(baseUrl, options)
//         } else if (method === 'POST') {
//             data = await axios.post(baseUrl, body, options)
//         } else if (method === 'PUT') {
//             data = await axios.put(baseUrl, body, options)
//         } else if (method === 'DELETE') {
//             data = await axios.delete(baseUrl, options)
//         }

//         return new ApiResponse({
//             status: data.status,
//             data: data.data,
//             headers: data.headers
//         })
//     } catch (e) {
//         console.log(e)
//         if (e.response) {
//             throw new ApiResponse({
//                 status: e.response.status || 404,
//                 data: e.response.data,
//                 headers: e.response.headers,
//             })
//         }
//         throw new ApiResponse({
//             status: 404,
//             data: {},
//             headers: {},
//         })
//     }
// }

let instance;

class _ApiService {

    constructor() {
        if (instance) {
            return null
        }
        instance = this
    }

    getAccessToken() {
        return cookieStorageService.getItem("accessToken")
    }

    getRefreshToken() {
        return cookieStorageService.getItem("refreshToken")
    }

    saveAccessToken(token) {
        return cookieStorageService.setItem("accessToken", token)
    }

    saveRefreshToken(token) {
        return cookieStorageService.setItem("refreshToken", token)
    }

    logout() {
        cookieStorageService.deleteItem("accessToken")
        return cookieStorageService.deleteItem("refreshToken")
    }

    getInstance() {
        return this;
    }

    async sendRequest({
        url,
        query,
        method,
        body,
        headers,
        signal,
        useRefreshToken,
        onUploadProgress,
        onDownloadProgress,
        authOptional,
    }) {
        try {
            const baseUrl = `${getBaseUrl()}${url}?${convertObjectToQueryString(query)}`
            console.log(`${method}`, ' | ', baseUrl, ' | body >> ', body, ' | query >> ', query)
            let options = {
                headers: {
                    ...headers,
                    "Access-Control-Allow-Origin": "*",
                    Authorization: !authOptional ? `Bearer ${useRefreshToken ? this.getRefreshToken() : this.getAccessToken()}` : null,
                },
                signal: signal,
                timeout: 60000,
                onUploadProgress: onUploadProgress,
                onDownloadProgress: onDownloadProgress,
            }

            let data
            try {
                data = await this.#sendAxiosRequest({
                    baseUrl,
                    options,
                    body,
                    method,
                })
            } catch (e) {
                if (authOptional) throw e
                if (e.response.data?.error?.errorCode != "auth/token-expired" && e.response.data?.error?.errorCode != "auth/token-not-found") {
                    throw e
                }

                // get new token
                const newTokens = await this.#sendAxiosRequest({
                    baseUrl: `${getBaseUrl()}/auth/refreshToken`,
                    options: {
                        headers: {
                            Authorization: `Bearer ${this.getRefreshToken()}`,
                        },
                        "Access-Control-Allow-Origin": "*",
                    },
                    method: "POST",
                }).catch(e => {
                    this.logout()
                    throw e
                })

                this.saveAccessToken(newTokens.data.accessToken)
                options.headers.Authorization = !authOptional ? `Bearer ${this.getAccessToken()}` : null
                data = await this.#sendAxiosRequest({
                    baseUrl,
                    options,
                    body,
                    method,
                })
            }

            return new ApiResponse({
                status: data.status,
                data: data.data,
                headers: data.headers
            })
        } catch (e) {
            console.log(e)
            if (e.response) {
                throw new ApiResponse({
                    status: e.response.status || 404,
                    data: e.response.data,
                    headers: e.response.headers,
                })
            }
            throw new ApiResponse({
                status: 404,
                data: {},
                headers: {},
            })
        }
    }

    async #sendAxiosRequest({
        baseUrl,
        options,
        body,
        method,
    }) {
        let data;
        if (method === 'GET') {
            data = await axios.get(baseUrl, options)
        } else if (method === 'POST') {
            data = await axios.post(baseUrl, body, options)
        } else if (method === 'PUT') {
            data = await axios.put(baseUrl, body, options)
        } else if (method === 'DELETE') {
            data = await axios.delete(baseUrl, options)
        }

        return data
    }
}

const ApiService = Object.freeze(new _ApiService())

export default ApiService;