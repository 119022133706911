import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createClientApiService,
  getAllClientsApiService,
  updateClientApiService,
  getSingleClientApiService,
  deleteClientApiService,
  sendStaffProfile,
} from "../../../services/applicationBackendApis/client";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allClients: [],
  allClientsPagination: {
    nextPage: null,
  },
  singleClient: null,
  moreClientsLeftInPagination: false,
  errorMessages: {
    createClientErrorMessage: null,
    getAllClientsErrorMessage: null,
    getSingleClientErrorMessage: null,
    updateClientErrorMessage: null,
    deleteClientErrorMessage: null,
    sendStaffProfileErrorMessage: null,
  },
  status: {
    createClientStatus: null,
    getAllClientsStatus: null,
    getSingleClientStatus: null,
    updateClientStatus: null,
    deleteClientStatus: null,
    sendStaffProfileStatus: null,
  },
  successMessages: {
    createClientSuccessMessage: null,
    getAllClientsSuccessMessage: null,
    getSingleClientSuccessMessage: null,
    updateClientSuccessMessage: null,
    deleteClientSuccessMessage: null,
    sendStaffProfileSuccessMessage: null,
  },
};

//--------------------Create Client Reducer---------//
export const createClientReducer = createAsyncThunk(
  "client/createClient",
  async (data) => {
    try {
      const body = {};
      body.file = data.profilePhotoFile
      if (data?.name) {
        body.name = data?.name;
      }
      if (data?.about) {
        body.about = data.about
      }
      if (data?.email) {
        body.email = data?.email;
      }

      if (data.contact) {
        body.contact = {
          contact: data.contact,
          phonecode: data.phonecode,
        };
      }

      if (data.dateOfBirth) {
        body.dateOfBirth = data.dateOfBirth;
      }

      if (data.gender) {
        body.gender = data.gender;
      }

      if (data.notes) {
        body.notes = data.notes;
      }

      if (data.addressLine1) {
        console.log(body);
        body.address = {
          line1: data.addressLine1,
          line2: data.addressLine2,
          city: data.city,
          postcode: data.postcode,
          country: data.country,
          region: data.region,
        };
      }

      if (data.teams) {
        body.teams = data.teams;
      }

      if (data.doctorDetails) body.doctorDetails = data.doctorDetails;
      if (data.nextOfKin) body.nextOfKin = data.nextOfKin;
      if (data.therapistDetails) body.therapistDetails = data.therapistDetails;
      if (data.socialWorkerContacts) body.socialWorkerContacts = data.socialWorkerContacts;
      if (data.refId) body.refId = data.refId

      const response = await createClientApiService(body);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//-----------------Get All Clients---------------//
export const getAllClientsReducer = createAsyncThunk(
  "client/getAllClient",
  async (data) => {
    try {
      const response = await getAllClientsApiService({
        limit: data.limit ?? PAGINATION_LIMITS.CLIENT_LIST,
        startAfter: data.startAfter,
        id: data.id,
        query: data.query,
        teams: data.teams,
      });
      if (data.cb) data.cb(response.data.clients)
      return {
        data: response.data,
        limit: data.limit ?? PAGINATION_LIMITS.CLIENT_LIST,
        startAfter: data.startAfter,
      };
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);
//----------------Get Single Client--------------//
export const getSingleClientReducer = createAsyncThunk(
  "clients/getSingleClient",
  async (clientData) => {
    try {
      const response = await getSingleClientApiService({
        clientId: clientData.clientId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);
//----------------Update Client--------//
export const updateClientReducer = createAsyncThunk(
  "client/updateClient",
  async (data) => {
    try {
      const body = {};
      body.file = data.profilePhotoFile
      body.about = data.about
      body.name = data.name;
      body.contact = data.contact ? {
        contact: data.contact,
        phonecode: data.phonecode,
      } : null
      body.dateOfBirth = data.dateOfBirth;
      body.gender = data.gender;
      body.address = data.addressLine1 || data.addressLine2 || data.city || data.postcode || data.country || data.region ? {
        line1: data.addressLine1,
        line2: data.addressLine2,
        city: data.city,
        postcode: data.postcode,
        country: data.country,
        region: data.region,
      } : null
      body.notes = data.notes;
      body.teams = data.teams;
      body.doctorDetails = data.doctorDetails;
      body.nextOfKin = data.nextOfKin;
      body.therapistDetails = data.therapistDetails;
      body.socialWorkerContacts = data.socialWorkerContacts;
      body.refId = data.refId
      body.email = data.email

      const response = await updateClientApiService(body, data.id);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//----------------Delete Client-------//
export const deleteClientReducer = createAsyncThunk(
  "clients/deleteClient",
  async (clientData) => {
    try {
      const response = await deleteClientApiService({
        clientId: clientData.id,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// --------------- Staff Profile ----- //
export const sendStaffProfileReducer = createAsyncThunk(
  "clients/sendStaffProfile",
  async (data) => {
    try {
      const response = await sendStaffProfile({
        staffId: data.staffId,
        clientId: data.clientId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//-----------------Slices-------------//
const clientSlice = createSlice({
  name: "client",
  initialState,
  //Reducers that do not require Api calls
  reducers: {
    clearAllStatusReducer: (state) => {
      state.status = {};
    },
    clearAllErrorMessagesReducer: (state) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state) => {
      state.successMessages = {};
    },
    clearAllLocalClientsReducer: (state) => {
      state.allClients = []
      state.allClientsPagination = {}
    }
  },
  //Reducers that require Api Calls
  extraReducers: (builder) => {
    //Create Client Async Calls
    builder.addCase(createClientReducer.pending, (state, action) => {
      state.status.createClientStatus = asyncStates.PENDING;
      state.errorMessages.createClientErrorMessage = null;
    });
    builder.addCase(createClientReducer.rejected, (state, action) => {
      state.status.createClientStatus = asyncStates.FAILURE;
      state.errorMessages.createClientErrorMessage = action.error?.message;
    });
    builder.addCase(createClientReducer.fulfilled, (state, action) => {
      state.status.createClientStatus = asyncStates.SUCCESS;
      state.errorMessages.createClientErrorMessage = null;
      state.successMessages.createClientSuccessMessage = action.payload?.message;
    });

    //Get All Clients Async Calls
    builder.addCase(getAllClientsReducer.rejected, (state, action) => {
      state.status.getAllClientsStatus = asyncStates.FAILURE;
      state.errorMessages.getAllClientsErrorMessage = action.error?.message;
    });
    builder.addCase(getAllClientsReducer.pending, (state) => {
      state.status.getAllClientsStatus = asyncStates.PENDING;
      state.errorMessages.createClientErrorMessage = null;
    });
    builder.addCase(getAllClientsReducer.fulfilled, (state, action) => {
      state.status.getAllClientsStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllClientsErrorMessage = null;
      state.allClients = action.payload.startAfter ? [...state.allClients, ...action.payload.data.clients] : action.payload.data.clients;

      if (action.payload.data.clients.length >= action.payload.limit && action?.payload?.data?.pagination?.nextPage) {
        state.moreClientsLeftInPagination = true;
        state.allClientsPagination.nextPage = action?.payload?.data?.pagination?.nextPage;
      } else {
        state.moreClientsLeftInPagination = false;
      }

    });

    // Get Single Client
    builder.addCase(getSingleClientReducer.pending, (state, action) => {
      state.status.getSingleClientStatus = asyncStates.PENDING;
      state.errorMessages.getSingleClientErrorMessage = null;
    });
    builder.addCase(getSingleClientReducer.rejected, (state, action) => {
      state.status.getSingleClientStatus = asyncStates.FAILURE;
      state.errorMessages.getSingleClientErrorMessage = action.error?.message;
    });
    builder.addCase(getSingleClientReducer.fulfilled, (state, action) => {
      state.status.getSingleClientStatus = asyncStates.SUCCESS;
      state.errorMessages.getSingleClientErrorMessage = null;
      state.singleClient = action.payload;
    });

    // start handling update Staff async calls
    builder.addCase(updateClientReducer.pending, (state, action) => {
      state.status.updateClientStatus = asyncStates.PENDING;
      state.errorMessages.updateClientErrorMessage = null;
    });
    builder.addCase(updateClientReducer.rejected, (state, action) => {
      state.status.updateClientStatus = asyncStates.FAILURE;
      state.errorMessages.updateClientErrorMessage = action.error?.message;
    });
    builder.addCase(updateClientReducer.fulfilled, (state, action) => {
      state.status.updateClientStatus = asyncStates.SUCCESS;
      state.errorMessages.updateClientErrorMessage = null;
      state.successMessages.updateClientSuccessMessage =
        action.payload.message;
    });

    //Delete Client
    builder.addCase(deleteClientReducer.pending, (state, action) => {
      state.status.deleteClientStatus = asyncStates.PENDING;
      state.errorMessages.deleteClientErrorMessage = null;
    });
    builder.addCase(deleteClientReducer.rejected, (state, action) => {
      state.status.deleteClientStatus = asyncStates.FAILURE;
      state.errorMessages.deleteClientErrorMessage = action.error?.message;
    });
    builder.addCase(deleteClientReducer.fulfilled, (state, action) => {
      state.status.deleteClientStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteClientErrorMessage = null;
      state.successMessages.deleteClientSuccessMessage =
        action.payload?.message;
    });

    // Send Staff Profile
    builder.addCase(sendStaffProfileReducer.pending, (state, action) => {
      state.status.sendStaffProfileStatus = asyncStates.PENDING;
      state.errorMessages.sendStaffProfileErrorMessage = null;
    });
    builder.addCase(sendStaffProfileReducer.rejected, (state, action) => {
      state.status.sendStaffProfileStatus = asyncStates.FAILURE;
      state.errorMessages.sendStaffProfileErrorMessage = action.error?.message;
    });
    builder.addCase(sendStaffProfileReducer.fulfilled, (state, action) => {
      state.status.sendStaffProfileStatus = asyncStates.SUCCESS;
      state.errorMessages.sendStaffProfileErrorMessage = null;
      state.successMessages.sendStaffProfileSuccessMessage =
        action.payload?.message ?? "Staff profile sent to the client";
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusReducer,
  clearAllSuccessMessagesReducer,
  clearAllLocalClientsReducer,
} = clientSlice.actions;
export default clientSlice.reducer;
