import { useSelector } from "react-redux";

export const useEventAnalyticsSelector = () => useSelector((state) => {
    return state.analytics.eventAnalytics;
})

export const useUserAnalyticsSelector = () => useSelector((state) => {
    return state.analytics.userAnalytics;
})

//------------------------ Analytics Error Messages -------------------//
export const useGetEventAnalyticsErrorMessageSelector = () => useSelector((state) => {
    return state.analytics.errorMessages.getEventAnalyticsErrorMessage;
})

export const useGetUserAnalyticsErrorMsgSelector = () => useSelector((state) => {
    return state.analytics.errorMessages.getUserAnalyticsErrorMessage;
})

//----------------------- Analytics Status Selectors ----------------//
export const useGetEventAnalyticsStatusSelector = () => useSelector((state) => {
    return state.analytics.status.getEventAnalyticsStatus;
})

export const useGetUserAnalyticsStatusSelector = () => useSelector((state) => {
    return state.analytics.status.getUserAnalyticsStatus;
})

//------------------------ Analytics Client Selectors ---------------------//
export const useGetEventAnalyticsSuccessMessageSelector = () => useSelector((state) => {
    return state.analytics.successMessages.getEventAnalyticsSuccessMessage;
})

export const useGetUserAnalyticsSuccessMsgSelector = () => useSelector((state) => {
    return state.analytics.successMessages.getUserAnalyticsSuccessMessage;
})
