import { useSelector } from "react-redux";

// ------------------ local configs
export const useServicesSelector = () => useSelector((state) => {
    return state.configs.services;
});

export const useCareplansSelector = () => useSelector((state) => {
    return state.configs.careplans;
});

export const useEventCheckListsSelector = () => useSelector((state) => {
    return state.configs.eventCheckLists;
});

export const useCompliancesSelector = () => useSelector((state) => {
    return state.configs.compliances;
});

export const useRiskassessmentsSelector = () => useSelector((state) => {
    return state.configs.riskassessments;
});

export const useMedicinesSelector = () => useSelector((state) => {
    return state.configs.medicines;
});

export const useAdditionalFormsSelector = () => useSelector((state) => {
    return state.configs.additionalForms;
});

export const useMedicineRefusalReasonsSelector = () => useSelector((state) => {
    return state.configs.medicineRefusalReasons;
})

export const usePolicySelector = () => useSelector((state) => {
    return state.configs.policy;
})

export const useJobRolesSelector = () => useSelector((state) => {
    return state.configs.jobRoles;
})

export const useTimeOffApproversSelector = () => useSelector((state) => {
    return state.configs.timeOffApprovers;
})

export const useDefaultQualificationsSelector = () => useSelector((state) => {
    return state.configs.defaultQualifications;
})

export const useMoreDefaultQualificationsLeftInPaginationSelector = () => useSelector((state) => {
    return state.configs.moreDefaultQualificationsLeftInPagination;
})

// -------------------- global configs
export const useGlobalServicesSelector = () => useSelector((state) => {
    return state.configs.global.services;
});

export const useGlobalCareplansSelector = () => useSelector((state) => {
    return state.configs.global.careplans;
});

export const useGlobalEventCheckListsSelector = () => useSelector((state) => {
    return state.configs.global.eventCheckLists;
});

export const useGlobalCompliancesSelector = () => useSelector((state) => {
    return state.configs.global.compliances;
});

export const useGlobalRiskassessmentsSelector = () => useSelector((state) => {
    return state.configs.global.riskassessments;
});

export const useGlobalMedicinesSelector = () => useSelector((state) => {
    return state.configs.global.medicines;
});

export const useGlobalMedicineFormsSelector = () => useSelector((state) => {
    return state.configs.global.medicineForms;
});

export const useGlobalFeatureAccessListSelector = () => useSelector((state) => {
    return state.configs.global.featureAccessList;
});

export const useGlobalMedicineRefusalReasonsSelector = () => useSelector((state) => {
    return state.configs.global.medicineRefusalReasons;
})

export const useGlobalPolicySelector = () => useSelector((state) => {
    return state.configs.global.policy;
})

export const useGlobalAdditionalFormsSelector = () => useSelector((state) => {
    return state.configs.global.additionalForms;
});

export const useGlobalJobRolesSelector = () => useSelector((state) => {
    return state.configs.global.jobRoles;
})

//------------------------Configs Error Messages
export const useGetServicesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getServicesErrorMessage;
});
export const useUpdateServicesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateServicesErrorMessage;
});

export const useGetCompliancesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getCompliancesErrorMessage;
});
export const useUpdateCompliancesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateCompliancesErrorMessage;
});

export const useGetJobRolesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getJobRolesErrorMessage;
});
export const useUpdateJobRolesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateJobRolesErrorMessage;
});

export const useGetTimeOffApproversErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getTimeOffApproversErrorMessage;
});
export const useUpdateTimeOffApproversErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateTimeOffApproversErrorMessage;
});

export const useGetCareplansErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getCareplansErrorMessage;
});
export const useUpdateCareplansErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateCareplansErrorMessage;
});

export const useGetEventCheckListsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getEventCheckListsErrorMessage;
});
export const useUpdateEventCheckListsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateEventCheckListsErrorMessage;
});

export const useGetRiskassessmentsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getRiskassessmentsErrorMessage;
});
export const useUpdateRiskassessmentsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateRiskassessmentsErrorMessage;
});

export const useGetMedicinesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getMedicinesErrorMessage;
});
export const useUpdateMedicinesErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateMedicinesErrorMessage;
});

export const useGetMedicineRefusalReasonsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getMedicineRefusalReasonsErrorMessage;
});
export const useUpdateMedicineRefusalReasonsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateMedicineRefusalReasonsErrorMessage;
});

export const useGetPolicyErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getPolicyErrorMessage;
});
export const useUpdatePolicyErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updatePolicyErrorMessage;
});

export const useGetAdditionalFormsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getAdditionalFormsErrorMessage;
});
export const useUpdateAdditionalFormsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateAdditionalFormsErrorMessage;
});

export const useCreateDefaultQualificationsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.createDefaultQualificationsErrorMessage;
});
export const useUpdateDefaultQualificationsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.updateDefaultQualificationsErrorMessage;
});
export const useGetDefaultQualificationsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getDefaultQualificationsErrorMessage;
});
export const useDeleteDefaultQualificationsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.deleteDefaultQualificationsErrorMessage;
});

export const useGetGlobalConfigsErrorMessageSelector = () => useSelector((state) => {
    return state.configs.errorMessages.getGlobalConfigsErrorMessage;
});

//-----------------------Client Status Selectors 
export const useGetServicesStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getServicesStatus;
});
export const useServicesUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateServicesStatus;
});

export const useGetCareplansStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getCareplansStatus;
});
export const useCareplansUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateCareplansStatus;
});

export const useGetEventCheckListsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getEventCheckListsStatus;
});
export const useEventCheckListsUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateEventCheckListsStatus;
});

export const useGetCompliancesStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getCompliancesStatus;
});
export const useCompliancesUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateCompliancesStatus;
});

export const useGetJobRolesStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getJobRolesStatus;
});
export const useJobRolesUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateJobRolesStatus;
});

export const useGetTimeOffApproversStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getTimeOffApproversStatus;
});
export const useTimeOffApproversUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateTimeOffApproversStatus;
});

export const useGetRiskassessmentsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getRiskassessmentsStatus;
});
export const useRiskassessmentsUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateRiskassessmentsStatus;
});

export const useGetMedicinesStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getMedicinesStatus;
});
export const useMedicinesUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateMedicinesStatus;
});

export const useGetMedicineRefusalReasonsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getMedicineRefusalReasonsStatus;
});
export const useMedicineRefusalReasonsUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateMedicineRefusalReasonsStatus;
});

export const useGetPolicyStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getPolicyStatus;
});
export const usePolicyUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updatePolicyStatus;
});

export const useGetAdditionalFormsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getAdditionalFormsStatus;
});
export const useAdditionalFormsUpdateStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateAdditionalFormsStatus;
});

export const useCreateDefaultQualificationsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.createDefaultQualificationsStatus;
});
export const useUpdateDefaultQualificationsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.updateDefaultQualificationsStatus;
});
export const useGetDefaultQualificationsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getDefaultQualificationsStatus;
});
export const useDeleteDefaultQualificationsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.deleteDefaultQualificationsStatus;
});

export const useGetGlobalConfigsStatusSelector = () => useSelector((state) => {
    return state.configs.statuses.getGlobalConfigsStatus;
});