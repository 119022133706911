
import ApiService from "../../restapi";

export const getTeamsApiService = async ({ startAfter, limit }) => {
  return await ApiService.sendRequest({
    url: `/teams`,
    query: {
      startAfter: startAfter,
      limit: limit,
    },
    method: "GET",
    headers: {},
  });
};

export const getTeamApiService = async ({ teamId }) => {
  return await ApiService.sendRequest({
    url: `/teams/${teamId}`,
    query: {},
    method: "GET",
    headers: {},
  });
};

export const createTeamApiService = async ({ name }) => {
  return await ApiService.sendRequest({
    url: `/teams`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      name: name,
    },
  });
};

export const updateTeamApiService = async ({ name, teamId }) => {
  return await ApiService.sendRequest({
    url: `/teams/${teamId}`,
    query: {},
    method: "PUT",
    headers: {},
    body: {
      name: name,
    },
  });
};

export const deleteTeamApiService = async ({ teamId }) => {
  return await ApiService.sendRequest({
    url: `/teams/${teamId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};
