import { useSelector } from "react-redux";

export const useClientAccessSelector = () => useSelector((state) => {
    return state.clientAccess.clientAccess;
});

// ------------------- selector error messages ------------------ //
export const useClientAccessCreateErrorMessageSelector = () => useSelector((state) => {
    return state.clientAccess.errorMessages.createClientAccessErrorMessage;
});

export const useClientAccessDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.clientAccess.errorMessages.deleteClientAccessErrorMessage;
});

export const useClientAccessGetErrorMessageSelector = () => useSelector((state) => {
    return state.clientAccess.errorMessages.getClientAccessErrorMessage;
});
export const useClientAccessResendErrorMessageSelector = () => useSelector((state) => {
    return state.clientAccess.errorMessages.resendClientAccessErrorMessage;
});

// ------------ status selectors ------------ //
export const useClientAccessCreateStatusSelector = () => useSelector((state) => {
    return state.clientAccess.statuses.createClientAccessStatus;
});

export const useClientAccessDeleteStatusSelector = () => useSelector((state) => {
    return state.clientAccess.statuses.deleteClientAccessStatus;
});

export const useClientAccessGetStatusSelector = () => useSelector((state) => {
    return state.clientAccess.statuses.getClientAccessStatus;
});

export const useClientAccessResendStatusSelector = () => useSelector((state) => {
    return state.clientAccess.statuses.resendClientAccessStatus;
});

// -------------- pagination selectors -------------- //
export const useMoreClientAccessItemsLeftInPaginationSelector = () => useSelector((state) => {
    return state.clientAccess.moreClientAccessItemsLeftInPagination
})