import React, { useEffect, useMemo, useState } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../Components/Boxes/index";
import { useResponsive } from "../../../Components/Hooks";
import { Button, CheckBox } from "../../../Components/Input";
import { Text } from "../../../Components/Text";
import MuiSkeletonLoader from "../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import useTimesheetDispatchers from "../../../redux/dispatchers/useTimesheetDispatchers";
import {
  useTimesheetsGetErrorMessageSelector,
  useTimesheetsGetStatusSelector,
  useTimesheetsSelector,
  useMoreTimesheetItemsLeftInPaginationSelector,
} from "../../../redux/selectors/useTimesheetSelectors";
import * as asyncStates from "../../../redux/constants/asyncStates";
import EmptyState from "../../../Components/NoRecordFound";
import Tabs from "../../../Components/Input/Tabs";
import usePermissionsController from "../../../permissions";
import { TIME_SHEET_STATUS } from "../../../constants";
import EditTimesheet from "./EditTimesheet";
import { Grid, GridContainer } from "../../../Components/Grid";
import TimesheetCard from "./TimesheetCard";
import { FormDialog } from "../../../Components/Modals";

const TimesheetView = ({ clientId, staffId, }) => {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const { accessArchives } = usePermissionsController()

  // ---------------- states ------------------------
  const [open, setOpen] = useState(false);
  function HandleTimesheetDialog() {
    setOpen(!open);
  }
  //storing the selected timesheet and sending to the dialog
  const [timesheetToEdit, setTimesheetToEdit] = useState(null);
  const [isArchived, setIsArchived] = useState(false)
  const [timesheetStatus, setTimesheetStatus] = useState([])

  // ---------------- reducers ----------------------
  const {
    getTimesheets,
    clearAllLocalTimesheets,
    clearLocalTimesheetPdf,
    updateTimesheetStatusFilter,
  } = useTimesheetDispatchers();

  // ---------------- selectors ---------------------
  const allTimesheets = useTimesheetsSelector();
  const getTimesheetsStatus = useTimesheetsGetStatusSelector();
  const complianceGetErrorMessage = useTimesheetsGetErrorMessageSelector();
  const errorMessage = complianceGetErrorMessage;
  const moreTimesheetItemsLeftInPagination = useMoreTimesheetItemsLeftInPaginationSelector()

  // ---------------- useEffect ---------------------
  const loadMore = () => {
    getTimesheets({
      clientId: clientId,
      staffId: staffId,
      startAfter: allTimesheets[allTimesheets.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalTimesheets();
    updateTimesheetStatusFilter(timesheetStatus)
    getTimesheets({
      clientId: clientId,
      staffId: staffId,
      isArchived: isArchived,
    });
  }, [isArchived, timesheetStatus]);

  useEffect(() => {
    clearLocalTimesheetPdf()
  }, [])

  const timesheetStatusFilterOptions = useMemo(() => {
    return [
      { value: TIME_SHEET_STATUS.PENDING, label: "Pending" },
      { value: TIME_SHEET_STATUS.ACCEPTED, label: "Accepted" }
    ]
  }, [])

  return (
    <Box
      $width="100%"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}

        {/* <Button
          $onClick={() => {
            setType("add");
            setTimesheetToEdit(null);
            HandleTimesheetDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>New Timesheet</Text>
        </Button> */}
      </FlexBox>

      <Box $width="100%" $marginTop={3} />

      <GridContainer $spacing={3}>
        {timesheetStatusFilterOptions.map((value, index) => {
          return (
            <Grid key={index} xs={1}>
              <FlexBox $marginRight="3">
                <CheckBox
                  $checked={timesheetStatus.includes(value.value)}
                  $size={"medium"}
                  $onChange={(val) => {
                    const tempTimesheetStatus = new Set(timesheetStatus)
                    if (val.target.checked) {
                      tempTimesheetStatus.add(value.value)
                    } else {
                      tempTimesheetStatus.delete(value.value)
                    }
                    const timesheetStatusArray = []
                    tempTimesheetStatus.forEach((d) => {
                      timesheetStatusArray.push(d)
                    })
                    setTimesheetStatus(timesheetStatusArray)
                  }}
                />
                <Text $color={"text"} $fontSize={"body-sm"} $fontWeight={"600"}>
                  {value.label}
                </Text>
              </FlexBox>
            </Grid>
          )
        })}
      </GridContainer>

      <FlexBox $width="100%" $marginTop={3} $padding="3">
        {!staffId && !clientId && <>
          <Box $width="20%">
            <Text $fontWeight="bold">
              Staff
            </Text>
          </Box>
          <Box $width="20%">
            <Text $fontWeight="bold">
              Client
            </Text>
          </Box>
        </>}

        {(staffId || clientId) && <Box $width="40%">
          <Text $fontWeight="bold">
            Name
          </Text>
        </Box>}

        <Box $width="15%" $display="flex" $justifyContent="center">
          <Text>Date</Text>
        </Box>
        <Box $width="10%" $display="flex" $justifyContent="center">
          <Text>Start time</Text>
        </Box>
        <Box $width="10%" $display="flex" $justifyContent="center">
          <Text>End time</Text>
        </Box>
        <Box $width="10%" $display="flex" $justifyContent="center">
          <Text>Break</Text>
        </Box>
        <Box $width="10%" $display="flex" $justifyContent="center">
          <Text>Status</Text>
        </Box>
        <Box $width="5%" $display="flex" $justifyContent="center">
          <Text>Actions</Text>
        </Box>
      </FlexBox>

      {getTimesheetsStatus === asyncStates.SUCCESS && allTimesheets.length < 1 && <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
        <EmptyState />
      </FlexBox>}

      {allTimesheets?.map((value, index) => {
        return (
          <TimesheetCard
            clientId={clientId}
            staffId={staffId}
            timesheet={value}
            key={index}
            index={index}
            onClick={(e) => {
              //   if(event.target.innertext==""){ //or any condition that distinguish the iconmenu from the paper element
              //     event.stoppropagation();
              //     return 0;
              // }
              console.log(e.target.className.startsWith("MuiButtonBase-root"))
              setTimesheetToEdit(value);
              HandleTimesheetDialog();
            }}
          />
        );
      })}

      {getTimesheetsStatus === asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <Box $width="100%" $marginTop="2" key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={40}
              ></MuiSkeletonLoader>
            </Box>
          );
        })}
      {errorMessage && (
        <FlexBox $marginTop={2} $width="100%">
          <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
        </FlexBox>
      )}

      {moreTimesheetItemsLeftInPagination && <FlexBox $justifyContent="center" $marginTop="6" $width="100%">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      <FormDialog
        $open={open}
        $handleDialog={HandleTimesheetDialog}
        $title={`Timesheet (#${timesheetToEdit?.refId})`}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <EditTimesheet $handleDialog={HandleTimesheetDialog} clientId={clientId} staffId={staffId} timesheet={timesheetToEdit} />
      </FormDialog>
    </Box>
  );
};

export default TimesheetView
