import { useContext, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { Grid, GridContainer } from "../../Components/Grid";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import BottomNav from "./bottomNav";
import Home from "../Home/Index";
import Team from "../Team/Index";
import Schedule from "../Schedule";
import Analytics from "../Analytics/Index";
import Settings from "../Settings"
import { Box, CircleBox } from "../../Components/Boxes";
import styled from "styled-components";
import Icon from "../../Components/Icons";
import StaffProfile from "../Team/Staff/Profile/Index";
import ClientProfile from "../Team/Clients/Profile/Index";
import { useResponsive } from "../../Components/Hooks/index";
import { AuthContext } from "../../contextAPI's/authContext";
import { Text } from "../../Components/Text";
import { DropDownMenu, DropDownMenuItem } from "../../Components/Modals";
import CircularLoader from "../../Components/Icons/circularLoader";
import { useCookieWatcher } from "@fcannizzaro/react-use-cookie-watcher";
import useStaffDispathers from "../../redux/dispatchers/useStaffDispatchers";
import useConfigsDispatchers from "../../redux/dispatchers/useConfigsDispatchers";
import useBusinessAccountDispatchers from "../../redux/dispatchers/useBusinessAccountDispatchers";
import { localStorageService } from "../../utiles/storage";
import { useBusinessAccountSelector, useBusinessAccountsSelector } from "../../redux/selectors/useBusinessAccountSelectors";
import usePermissionsController from "../../permissions";
import { PermissionDeniedScreen } from "../../Components/Permissions";
import TimeSheetsPage from "../Timesheets";
import Subscriptions from "../Subscriptions";
import { SUBSCRIPTION_STATUS } from "../../constants";

const Notification = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
`;

const Banner = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
`;

export default function Dashboard() {
  //auth context
  const { Logout, loading } = useContext(AuthContext);
  const { getGlobalConfigs } = useConfigsDispatchers()
  const { clearSelectedBusinessAccount, getBusinessAccounts } = useBusinessAccountDispatchers()
  const {
    accessWeb,
    accessHomePage,
    accessAnalyticsPage,
    accessTeamsPage,
    accessSchedulePage,
    accessSettingsPage,
    accessTimeOffPage,
    accessTimesheetsPage,
    accessSubscriptionPage,

    accessStaffList,
    accessClientList,

    subscriptionsEnabled,
  } = usePermissionsController()

  // TODO: Make our own custom cookie watcher
  const navigate = useNavigate();
  const location = useLocation();
  // location.pathname;

  //checking whether the accessToken is expired or not
  const isNotExpired = useCookieWatcher("accessToken", {
    checkEvery: 500,
  });

  useEffect(() => {
    if (!isNotExpired) Logout();
  }, [isNotExpired]);

  useEffect(() => {
    getGlobalConfigs()
  }, [])

  // ---------------- selector ----------------------------
  const businessAccounts = useBusinessAccountsSelector()
  const businessAccount = useBusinessAccountSelector()

  // ---------------- all states ----------------------------
  const [sideBarCollapse, setSideBarCollapse] = useState(true);
  const [selected, setSelected] = useMemo(() => [location.pathname, () => { }], [location.pathname]);
  const isDesktop = useResponsive({ xs: false, md: true });

  // states for the drop down profile menu item
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownAnchor, setDropDownAnchor] = useState(null);

  // ------------------- functions --------------------------

  const onProfileClick = async () => {
    navigate(`/team/staff/${localStorageService.getItem("staffId")}`)
  };

  const switchAccounts = async () => {
    getBusinessAccounts()
    localStorageService.deleteItem("businessAccountId")
    clearSelectedBusinessAccount()
  }

  function handleSideBar() {
    setSideBarCollapse(!sideBarCollapse);
  }
  function handleSelect(value) {
    // setSelected(value);
  }

  if (!accessWeb) {
    return (
      <PermissionDeniedScreen
        title={"You do not have access to the website"}
        subtitle={"Please download the app to continue"}
        logout={() => {
          Logout()
        }}
      />
    )
  }

  if (businessAccount && subscriptionsEnabled && businessAccount?.subscriptionDetails?.subscriptionsEnabled !== false) {
    // check subscription
    if (![SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIALING].includes(businessAccount?.subscriptionDetails?.stripeSubscriptionStatus)) {
      if (!accessSubscriptionPage) {
        if (businessAccount?.subscriptionDetails?.stripeSubscriptionStatus == SUBSCRIPTION_STATUS.CANCELLED) {
          return (
            <PermissionDeniedScreen
              title={"Your subscription has been cancelled"}
              subtitle={"Please contact your account manager to continue"}
              logout={() => {
                Logout()
              }}
            />
          )
        }

        return (
          <PermissionDeniedScreen
            title={"Your subscription has some problems"}
            subtitle={"Please contact your account manager to continue"}
            logout={() => {
              Logout()
            }}
          />
        )
      } else {
        if (location.pathname !== "/subscription")
          navigate("/subscription")
      }
    }
  }

  return (
    <Box
      $width="100%"
      $minHeight="100vh"
      $backgroundColor="bg-color"
      $position="relative"
    >
      <GridContainer
        $spacing={0}
        $direction={"row"}
        $style={{ minHeight: "100vh" }}
      >
        {isDesktop && (
          <Grid
            $xs={!sideBarCollapse ? 3 : 1}
            $style={{ minHeight: "100vh", position: "relative" }}
          >
            <SideBar
              selected={selected}
              handleSelect={handleSelect}
              sideBarCollapse={sideBarCollapse}
              handleSideBar={handleSideBar}
            />
          </Grid>
        )}

        <Grid $xs={isDesktop ? !sideBarCollapse ? 9 : 11 : 12} $style={{ minHeight: "100vh" }}>
          <GridContainer
            $rowSpacing={isDesktop ? 2 : 1.5}
            $direction={"column"}
            $style={{
              minHeight: "100vh",
              paddingRight: `${!isDesktop ? "12px" : "24px"}`,
              paddingLeft: `${!isDesktop ? "12px" : "0px"}`,
            }}
          >
            <Grid
              $xs={1}
            >
              <Box $marginTop="3" />
              <GridContainer $spacing={2}>
                <Grid
                  $xs={8}
                  $sm={10}
                  $lg={11}
                  $display="flex"
                  $justifyContent="flex-end"
                  $alignItems="center"
                >
                  {/* <Banner>
                    <Icon $size="30px" $icon="Error" />
                  </Banner> */}

                </Grid>
                <Grid
                  $xs={4}
                  $sm={2}
                  $lg={1}
                  $display="flex"
                  $justifyContent="flex-end"
                  $alignItems="center"
                >
                  <Notification>
                    <Icon $size="30px" $icon="Notifications" />

                    <CircleBox
                      $width="40px"
                      $height="40px"
                      $borderRadius="50%"
                      $backgroundColor="#E6E6E6"
                      $marginLeft={5}
                      $onClick={(e) => {
                        setDropDownAnchor(e.currentTarget);
                        setShowDropDown(true);
                      }}
                    >
                      {loading ? <CircularLoader $color="primary" $size={35} /> : <Icon $size="36px" $icon="Person"></Icon>}
                    </CircleBox>
                  </Notification>

                </Grid>
              </GridContainer>
            </Grid>

            <Grid
              $xs
              $style={{
                height: "100%",
                width: "100%",
                paddingBottom: `${!isDesktop ? "72px" : "0px"}`,
              }}
            >
              <Routes>
                {(accessTimeOffPage || accessTeamsPage) && <Route path="/team" element={<Team />} />}
                {accessTeamsPage && accessClientList && <Route path="/team/clients" element={<Team defaultTab="Clients" />} />}
                {accessTeamsPage && accessStaffList && <Route path="/team/staff" element={<Team defaultTab="Staff" />} />}
                {accessTimeOffPage && <Route path="/team/timeoff" element={<Team defaultTab="Time Off" />} />}
                {accessSchedulePage && <Route path="/schedule" element={<Schedule />} />}
                {accessAnalyticsPage && <Route path="/analytics" element={<Analytics />} />}
                <Route path="/team/staff/:staffid" element={<StaffProfile />} />
                <Route path="/team/clients/:clientId" element={<ClientProfile />} />
                {accessSettingsPage && <Route path="/settings" element={<Settings />} />}
                {accessHomePage && <Route path="/" element={<Home />} />}
                {accessTimesheetsPage && <Route path="/timesheets" element={<TimeSheetsPage />} />}
                {accessSubscriptionPage && <Route path="/subscription" element={<Subscriptions />} />}
                <Route path="*" element={<Navigate to={`/team/staff/${localStorageService.getItem("staffId")}`} />} />
              </Routes>
            </Grid>
          </GridContainer>
        </Grid>
      </GridContainer>

      {!isDesktop && (
        <Box $width="100%" $position="relative">
          <BottomNav selected={selected} handleSelect={handleSelect} />
        </Box>
      )}

      {showDropDown && (
        <DropDownMenu
          $onClose={() => {
            setDropDownAnchor(null);
            setShowDropDown(false);
          }}
          $open={showDropDown}
          $anchor={dropDownAnchor}
        >
          <DropDownMenuItem
            $onClick={() => {
              onProfileClick();
            }}
          >
            <Icon $icon="PersonRounded" $size="large" $color="primary" />
            <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
              My Account
            </Text>
          </DropDownMenuItem>

          {businessAccounts && businessAccounts.length > 1 && <DropDownMenuItem
            $onClick={() => {
              switchAccounts();
            }}
          >
            <Icon $icon="Sync" $size="large" $color="primary" />
            <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
              Switch Accounts
            </Text>
          </DropDownMenuItem>}

          <DropDownMenuItem
            $onClick={() => {
              Logout();
            }}
          >
            <Icon $icon="Logout" $size="large" $color="primary" />
            <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
              Logout
            </Text>
          </DropDownMenuItem>
        </DropDownMenu>
      )}
    </Box>
  );
}
