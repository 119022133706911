import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetJobRolesErrorMessageSelector, useGetJobRolesStatusSelector, useJobRolesSelector, useJobRolesUpdateStatusSelector, useUpdateJobRolesErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useJobRolesSettingsController() {
    const { accessAddSettingsJobroles, accessEditSettingsJobroles } = usePermissionsController()
    const jobRoleNames = useJobRolesSelector()
    const getJobRolesErrorMessages = useGetJobRolesErrorMessageSelector()
    const updateJobRolesErrorMessages = useUpdateJobRolesErrorMessageSelector()

    const getJobRolesStatuses = useGetJobRolesStatusSelector()
    const updateJobRolesStatuses = useJobRolesUpdateStatusSelector()

    const names = useMemo(() => [...jobRoleNames], [jobRoleNames])

    const {
        updateJobRoles,
        getJobRoles,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getJobRoles()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateJobRoles({
            jobRoles: newNames
        }).unwrap();
        getJobRoles()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateJobRoles({
            jobRoles: newNames
        }).unwrap();
        getJobRoles()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateJobRoles({
            jobRoles: newNames
        }).unwrap();
        getJobRoles()
        return
    }

    return {
        title: "JobRoles",
        names: names,

        canEdit: accessEditSettingsJobroles,
        canAdd: accessAddSettingsJobroles,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateJobRolesStatuses == asyncStates.PENDING,
        isDeletingConfig: updateJobRolesStatuses == asyncStates.PENDING,
        isGettingConfigs: getJobRolesStatuses == asyncStates.PENDING,

        updateErrorMessage: updateJobRolesErrorMessages,
        deleteErrorMessage: updateJobRolesErrorMessages,
        gettingErrorMessage: getJobRolesErrorMessages,
    }
}