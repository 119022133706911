import { useState, useEffect, useMemo } from "react";
import { breakpoints } from "../Theme/index";

function containsBreaks(val) {
  if (val == null) return false;

  if (typeof val != "object") return false;

  if (
    val.xs == null &&
    val.sm == null &&
    val.md == null &&
    val.lg == null &&
    val.xl == null
  ) {
    return false;
  }

  return true;
}

export default function UseResponsive(resposive) {
  const { changeWhen, changeTo } = useMemo(() => {
    const points = [];
    const toWhat = [];
    if (containsBreaks(resposive)) {
      if (resposive.xs != null) {
        points.push(breakpoints.xs);
        toWhat.push(resposive.xs);
      }
      if (resposive.sm != null) {
        points.push(breakpoints.sm);
        toWhat.push(resposive.sm);
      }

      if (resposive.md != null) {
        points.push(breakpoints.md);
        toWhat.push(resposive.md);
      }

      if (resposive.lg != null) {
        points.push(breakpoints.lg);
        toWhat.push(resposive.lg);
      }

      if (resposive.xl != null) {
        points.push(breakpoints.xl);
        toWhat.push(resposive.xl);
      }
    }
    return { changeWhen: points, changeTo: toWhat };
  }, [resposive]);

  const getWidthPosition = (width) => {
    for (let i = 0; i < changeWhen.length; i++) {
      if (
        width >= changeWhen[i] &&
        (i >= changeWhen.length - 1 || width < changeWhen[i + 1])
      ) {
        return i;
      }
    }
    return 0;
  };

  const [state, setState] = useState(
    containsBreaks(resposive) ? changeTo[0] : resposive
  );

  function handleResize() {
    const newPos = getWidthPosition(window.innerWidth);
    setState(changeTo[newPos]);
  }

  useEffect(() => {
    if (containsBreaks(resposive)) {
      window.addEventListener("resize", handleResize);
      const pos = getWidthPosition(window.innerWidth);
      setState(changeTo[pos]);
    } else {
      setState(resposive);
    }
  }, [resposive]);

  return state;
}
