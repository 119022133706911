import { useEffect, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetMedicineRefusalReasonsErrorMessageSelector, useGetMedicineRefusalReasonsStatusSelector, useMedicineRefusalReasonsSelector, useMedicineRefusalReasonsUpdateStatusSelector, useUpdateMedicineRefusalReasonsErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useMedicineRefusalReasonsSettingsController() {
    const { accessAddSettingsRefusalReaosns, accessEditSettingsRefusalReaosns } = usePermissionsController()
    const medicineRefusalReasonNames = useMedicineRefusalReasonsSelector()
    const getMedicineRefusalReasonsErrorMessages = useGetMedicineRefusalReasonsErrorMessageSelector()
    const updateMedicineRefusalReasonsErrorMessages = useUpdateMedicineRefusalReasonsErrorMessageSelector()

    const getMedicineRefusalReasonsStatuses = useGetMedicineRefusalReasonsStatusSelector()
    const updateMedicineRefusalReasonsStatuses = useMedicineRefusalReasonsUpdateStatusSelector()

    const names = useMemo(() => [...medicineRefusalReasonNames], [medicineRefusalReasonNames])

    const {
        updateMedicineRefusalReasons,
        getMedicineRefusalReasons,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getMedicineRefusalReasons()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateMedicineRefusalReasons({
            medicineRefusalReasons: newNames
        }).unwrap();
        getMedicineRefusalReasons()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateMedicineRefusalReasons({
            medicineRefusalReasons: newNames
        }).unwrap();
        getMedicineRefusalReasons()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateMedicineRefusalReasons({
            medicineRefusalReasons: newNames
        }).unwrap();
        getMedicineRefusalReasons()
        return
    }

    return {
        title: "Missed Medication Reasons",
        names: names,

        canEdit: accessEditSettingsRefusalReaosns,
        canAdd: accessAddSettingsRefusalReaosns,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateMedicineRefusalReasonsStatuses == asyncStates.PENDING,
        isDeletingConfig: updateMedicineRefusalReasonsStatuses == asyncStates.PENDING,
        isGettingConfigs: getMedicineRefusalReasonsStatuses == asyncStates.PENDING,

        updateErrorMessage: updateMedicineRefusalReasonsErrorMessages,
        deleteErrorMessage: updateMedicineRefusalReasonsErrorMessages,
        gettingErrorMessage: getMedicineRefusalReasonsErrorMessages,
    }
}