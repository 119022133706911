import { useDispatch } from "react-redux";
import {
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
    getAdditionalFormsReducer,
    getCareplansReducer,
    getCompliancesReducer,
    getEventCheckListsReducer,
    getGlobalConfigsReducer,
    getJobRolesReducer,
    getTimeOffApproversReducer,
    getMedicineRefusalReasonsReducer,
    getMedicinesReducer,
    getPolicyReducer,
    getRiskassessmentsReducer,
    getServicesReducer,
    updateAdditionalFormsReducer,
    updateCareplansReducer,
    updateCompliancesReducer,
    updateEventCheckListsReducer,
    updateJobRolesReducer,
    updateTimeOffApproversReducer,
    updateMedicineRefusalReasonsReducer,
    updateMedicinesReducer,
    updatePolicyReducer,
    updateRiskassessmentsReducer,
    updateServicesReducer,

    createDefaultQualificationsReducer,
    updateDefaultQualificationsReducer,
    getDefaultQualificationsReducer,
    deleteDefaultQualificationsReducer,

} from "../../slices/configSlices";

const useConfigsDispatchers = () => {
    const dispatch = useDispatch();

    const updateServices = (data) => {
        return dispatch(updateServicesReducer(data))
    }
    const getServices = (data) => {
        return dispatch(getServicesReducer(data))
    }

    const updateCareplans = (data) => {
        return dispatch(updateCareplansReducer(data))
    }
    const getCareplans = (data) => {
        return dispatch(getCareplansReducer(data))
    }

    const updateEventCheckLists = (data) => {
        return dispatch(updateEventCheckListsReducer(data))
    }
    const getEventCheckLists = (data) => {
        return dispatch(getEventCheckListsReducer(data))
    }

    const updateCompliances = (data) => {
        return dispatch(updateCompliancesReducer(data))
    }
    const getCompliances = (data) => {
        return dispatch(getCompliancesReducer(data))
    }

    const updateJobRoles = (data) => {
        return dispatch(updateJobRolesReducer(data))
    }
    const getJobRoles = (data) => {
        return dispatch(getJobRolesReducer(data))
    }

    const updateTimeOffApprovers = (data) => {
        return dispatch(updateTimeOffApproversReducer(data))
    }
    const getTimeOffApprovers = (data) => {
        return dispatch(getTimeOffApproversReducer(data))
    }

    const updateMedicineRefusalReasons = (data) => {
        return dispatch(updateMedicineRefusalReasonsReducer(data))
    }
    const getMedicineRefusalReasons = (data) => {
        return dispatch(getMedicineRefusalReasonsReducer(data))
    }

    const updatePolicy = (data) => {
        return dispatch(updatePolicyReducer(data))
    }
    const getPolicy = (data) => {
        return dispatch(getPolicyReducer(data))
    }

    const updateRiskassessments = (data) => {
        return dispatch(updateRiskassessmentsReducer(data))
    }
    const getRiskassessments = (data) => {
        return dispatch(getRiskassessmentsReducer(data))
    }

    const updateMedicines = (data) => {
        return dispatch(updateMedicinesReducer(data))
    }
    const getMedicines = (data) => {
        return dispatch(getMedicinesReducer(data))
    }

    const updateAdditionalForms = (data) => {
        return dispatch(updateAdditionalFormsReducer(data))
    }
    const getAdditionalForms = (data) => {
        return dispatch(getAdditionalFormsReducer(data))
    }

    const createDefaultQualifications = (data) => {
        return dispatch(createDefaultQualificationsReducer(data))
    }
    const updateDefaultQualifications = (data) => {
        return dispatch(updateDefaultQualificationsReducer(data))
    }
    const getDefaultQualifications = (data) => {
        return dispatch(getDefaultQualificationsReducer(data))
    }
    const deleteDefaultQualifications = (data) => {
        return dispatch(deleteDefaultQualificationsReducer(data))
    }

    const getGlobalConfigs = (data) => {
        return dispatch(getGlobalConfigsReducer(data))
    }

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data));
    };
    const clearAllStatusMessages = (data) => {
        return dispatch(clearAllStatusesReducer(data));
    };

    return {
        updateServices,
        getServices,
        updateCareplans,
        getCareplans,
        updateEventCheckLists,
        getEventCheckLists,
        updateCompliances,
        getCompliances,
        updateJobRoles,
        getJobRoles,
        updateTimeOffApprovers,
        getTimeOffApprovers,
        updateMedicineRefusalReasons,
        getMedicineRefusalReasons,
        updatePolicy,
        getPolicy,
        updateRiskassessments,
        getRiskassessments,
        updateMedicines,
        getMedicines,
        updateAdditionalForms,
        getAdditionalForms,

        createDefaultQualifications,
        updateDefaultQualifications,
        getDefaultQualifications,
        deleteDefaultQualifications,
        
        getGlobalConfigs,

        clearAllErrorMessages,
        clearAllStatusMessages,
    };
};

export default useConfigsDispatchers;
