import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getEventAnalyticsApiService,
    getUserAnalyticsApiService,
} from "../../../services/applicationBackendApis/analytics";
import * as asyncStates from "../../constants/asyncStates";

const initialState = {
    eventAnalytics: [],
    userAnalytics: [],
    errorMessages: {
        getEventAnalyticsErrorMessage: null,
        getUserAnalyticsErrorMessage: null,
    },
    status: {
        getEventAnalyticsStatus: null,
        getUserAnalyticsStatus: null,
    },
    successMessages: {
        getEventAnalyticsSuccessMessage: null,
        getUserAnalyticsSuccessMessage: null,
    },
};

//-----------------Get Event analytics---------------//
export const getEventAnalyticsReducer = createAsyncThunk(
    "analytics/getEventAnalyticsReducer",
    async (data) => {
        try {
            const response = await getEventAnalyticsApiService({
                staffId: data.staffId,
                clientId: data.clientId,
                monthBeginningDate: data.monthBeginningDate,
                startDate: data.startDate,
                endDate: data.endDate,
                selectedDate: data.selectedDate,
                repetitionPeriods: data.repetitionPeriods,
            });
            if (data.cb) data.cb(response.data.clients)
            return {
                data: response.data,
            };
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

//-----------------Get User analytics---------------//
export const getUserAnalyticsReducer = createAsyncThunk(
    "analytics/getUserAnalyticsReducer",
    async (data) => {
        try {
            const response = await getUserAnalyticsApiService({
                monthBeginningDate: data.monthBeginningDate,
                startDate: data.startDate,
                endDate: data.endDate,
                selectedDate: data.selectedDate,
                repetitionPeriods: data.repetitionPeriods,
            });
            if (data.cb) data.cb(response.data.clients)
            return {
                data: response.data,
            };
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

//-----------------Slices-------------//
const clientSlice = createSlice({
    name: "client",
    initialState,
    //Reducers that do not require Api calls
    reducers: {
        clearAllStatusReducer: (state) => {
            state.status = {};
        },
        clearAllErrorMessagesReducer: (state) => {
            state.errorMessages = {};
        },
        clearAllSuccessMessagesReducer: (state) => {
            state.successMessages = {};
        },
    },
    //Reducers that require Api Calls
    extraReducers: (builder) => {
        //Get event analytics reducer
        builder.addCase(getEventAnalyticsReducer.rejected, (state, action) => {
            state.status.getEventAnalyticsStatus = asyncStates.FAILURE;
            state.errorMessages.getEventAnalyticsErrorMessage = action.error?.message;
        });
        builder.addCase(getEventAnalyticsReducer.pending, (state) => {
            state.status.getEventAnalyticsStatus = asyncStates.PENDING;
            state.errorMessages.getEventAnalyticsErrorMessage = null;
        });
        builder.addCase(getEventAnalyticsReducer.fulfilled, (state, action) => {
            state.status.getEventAnalyticsStatus = asyncStates.SUCCESS;
            state.errorMessages.getEventAnalyticsErrorMessage = null;
            state.eventAnalytics = action.payload.data.eventAnalytics;
        });

        //Get user analytics reducer
        builder.addCase(getUserAnalyticsReducer.rejected, (state, action) => {
            state.status.getUserAnalyticsStatus = asyncStates.FAILURE;
            state.errorMessages.getUserAnalyticsErrorMessage = action.error?.message;
        });
        builder.addCase(getUserAnalyticsReducer.pending, (state) => {
            state.status.getUserAnalyticsStatus = asyncStates.PENDING;
            state.errorMessages.getUserAnalyticsErrorMessage = null;
        });
        builder.addCase(getUserAnalyticsReducer.fulfilled, (state, action) => {
            state.status.getUserAnalyticsStatus = asyncStates.SUCCESS;
            state.errorMessages.getUserAnalyticsErrorMessage = null;
            state.userAnalytics = action.payload.data.userAnalytics;
        });
    },
});

export const {
    clearAllErrorMessagesReducer,
    clearAllStatusReducer,
    clearAllSuccessMessagesReducer,
} = clientSlice.actions;
export default clientSlice.reducer;
