import {
    DefaultSpacingModifiers,
    DefaultTextModifiers,
    DefaultSizeModifiers,
  } from "../Modifiers";
  import { color } from "../Theme";
  import styled from "styled-components";
  import { useResponsive } from "../Hooks"
    /*width: fit-content;
    white-space: normal;*/
  const TextWrapper = styled.p`
    white-space: pre-line;
    ${(props) => props.$hightLight && `
      border-bottom: 5px solid ${color('primary')};
      border-radius: 10px;
      color: ${color('primary')} !important;
      font-weight: bold;
    `}
    :hover {
      ${(props) => props.$link && `
        border-bottom: 5px solid ${color('primary')};
        border-radius: 10px;
        color: ${color('primary')} !important;
        font-weight: bold;
        cursor: pointer;
      `}
    }
  `;
  
  export default function Text({
    // $title
    $pointer,
    $color,
    $backgroundColor,
    $link,
    $textAlign,
    $hightLight,
    children,
    $onClick,
    $wordWrap,
    $borderRadius,
    $whiteSpace, //: 'pre-line',
    ...rest
  }) {
    return (
      <TextWrapper
        $link={$link === true}
        $hightLight={$hightLight === true}
        style={{
          ...DefaultTextModifiers(rest),
          ...DefaultSpacingModifiers(rest),
          ...DefaultSizeModifiers(rest),
          cursor: $pointer ? "pointer" : null,
          textAlign: useResponsive($textAlign),
          color: color(useResponsive($color || "text")),
          backgroundColor:$backgroundColor,
          wordWrap:$wordWrap,
          borderRadius:$borderRadius?$borderRadius:"opx",
          whiteSpace: useResponsive($whiteSpace)
        }}
        onClick={$onClick}
      >
        {children}
      </TextWrapper>
    );
  }