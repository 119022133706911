import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, FlexBox, ListBox } from "../../../../../../Components/Boxes";
import Icon from "../../../../../../Components/Icons";
import {
    Button,
    SelectBox,
    Textarea,
    MultiSelect,
    TextBox,
    DragAndDrop,
} from "../../../../../../Components/Input";
import { MultiLineText, Text } from "../../../../../../Components/Text";
import useAdditionalFormDispatchers from "../../../../../../redux/dispatchers/useAdditionalFormDispatchers";
import {
    useAdditionalFormCreateErrorMessageSelector,
    useAdditionalFormCreateStatusSelector,
    useAdditionalFormDeleteErrorMessageSelector,
    useAdditionalFormDeleteStatusSelector,
    useAdditionalFormRestoreErrorMessageSelector,
    useAdditionalFormRestoreStatusSelector,
    useAdditionalFormUpdateErrorMessageSelector,
    useAdditionalFormUpdateStatusSelector,
} from "../../../../../../redux/selectors/useAdditionalFormsSelectors";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";
import BasicAlerts from "../../../../../../Components/Boxes/ErrorAndSuccess";
import { ADDITIONAL_FORM_FIELD_TYPE, DOCUMENT_STATUS } from "../../../../../../constants";
import { useGlobalAdditionalFormsSelector, useGlobalServicesSelector } from "../../../../../../redux/selectors/useConfigsSelectors";
import { formateDateTextboxFormatToDate, formateDateToDateTextboxFormat } from "../../../../../../utiles/dateformate";
import { convertAdditionalFormFieldValueToType } from "../../../../../../utiles/additionalFormFields";
import { FormDialog } from "../../../../../../Components/Modals";
import Image from "../../../../../../Components/Images"
import UploadBodymap from "./UploadBodymap";

export const EditAdditionalForm = (props) => {
    // ----------------- context API's -------------------
    const { clientId } = props

    // ----------------- states --------------------------
    const additionalFormToEdit = useMemo(() => props.$additionalFormToEdit, []);

    const [title, setTitle] = useState(
        additionalFormToEdit ? additionalFormToEdit?.title : null
    )

    const [fields, setFields] = useState(
        additionalFormToEdit ? additionalFormToEdit?.submittedData?.map(e => {
            return {
                ...e,
                value: convertAdditionalFormFieldValueToType({ val: e.value, type: e.type })
            }
        }) ?? [] : []
    )

    const [name, setName] = useState(additionalFormToEdit ? additionalFormToEdit?.name : null)
    const [formInstructions, setFormInstructions] = useState(additionalFormToEdit ? additionalFormToEdit?.instructions : null)
    const [notes, setNotes] = useState(additionalFormToEdit ? additionalFormToEdit?.notes : null)

    const globalAdditionalForms = useGlobalAdditionalFormsSelector()

    const options = useMemo(() => {
        return globalAdditionalForms?.map(form => {
            return { value: form.name, label: form.name }
        })
    }, [globalAdditionalForms]);
    // ----------------- reducers ------------------------
    const {
        createAdditionalForm,
        getAdditionalForms,
        deleteAdditionalForm,
        updateAdditionalForm,
        clearAllErrorMessages,
        restoreArchivedAdditionalForm,
    } = useAdditionalFormDispatchers();
    // ----------------- selectors -----------------------

    const additionalFormCreateErrorMessage = useAdditionalFormCreateErrorMessageSelector();
    const additionalFormDeleteErrorMessage = useAdditionalFormDeleteErrorMessageSelector();
    const additionalFormUpdateErrorMessage = useAdditionalFormUpdateErrorMessageSelector();
    const additionalFormRestoreErrorMessage = useAdditionalFormRestoreErrorMessageSelector()

    const errorMessage = useMemo(() => {
        return (
            additionalFormCreateErrorMessage || additionalFormDeleteErrorMessage || additionalFormUpdateErrorMessage || additionalFormRestoreErrorMessage
        );
    }, [additionalFormCreateErrorMessage, additionalFormDeleteErrorMessage, additionalFormUpdateErrorMessage, additionalFormRestoreErrorMessage]);

    const additionalFormCreateStatus = useAdditionalFormCreateStatusSelector();
    const additionalFormDeleteStatus = useAdditionalFormDeleteStatusSelector();
    const additionalFormUpdateStatus = useAdditionalFormUpdateStatusSelector();
    const additionalFormRestoreStatus = useAdditionalFormRestoreStatusSelector();

    const [uploadFile, setUploadFile] = useState(null)
    const [uploadBodymap, setUploadBodymap] = useState(null)
    const [viewImage, setViewImage] = useState(null)

    // ----------------- functions -----------------------
    //   const handleChange = (selected) => {
    //     const values = selected.map((item) => item.value);
    //     setServices(values);
    //   };

    const formTypeChanged = ({ label, value }) => {
        const forms = globalAdditionalForms?.filter(form => form.name == value)
        setName(forms[0].name)
        setFormInstructions(forms[0].instructions)
        setFields(forms[0].fields?.map(field => {
            return {
                value: convertAdditionalFormFieldValueToType({ val: field.value, type: field.type }),
                name: field.name,
                type: field.type,
                options: field.options ? [...field.options] : []
            }
        }) ?? [])

    }

    const onCreateAdditionalFormClick = async () => {
        await createAdditionalForm({
            title: title,
            submittedData: fields,
            notes: notes,
            name: name,
            instructions: formInstructions,
            clientId: clientId,
        }).unwrap();
        getAdditionalForms({
            clientId: clientId,
        });
        return props.$handleDialog();
    };

    const onUpdateAdditionalFormClick = async () => {
        await updateAdditionalForm({
            clientId: clientId,
            additionalFormId: additionalFormToEdit.id,
            title: title,
            submittedData: fields,
            notes: notes,
            name: name,
            instructions: formInstructions,
        }).unwrap();
        getAdditionalForms({
            clientId: clientId,
        });
        return props.$handleDialog();
    };

    const onDeleteAdditionalFormClick = async () => {
        await deleteAdditionalForm({
            clientId: clientId,
            additionalFormId: additionalFormToEdit.id,
        }).unwrap();
        getAdditionalForms({
            clientId: clientId,
        });
        return props.$handleDialog();
    };

    const restoreAdditionalFormClicked = async () => {
        await restoreArchivedAdditionalForm({
            clientId: clientId,
            additionalFormId: additionalFormToEdit.id,
        }).unwrap();
        getAdditionalForms({
            clientId: clientId,
            isArchived: true,
        });
        return props.$handleDialog();
    }

    // ----------------- useEffect -----------------------
    useEffect(() => {
        clearAllErrorMessages();
    }, []);

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >

                {!additionalFormToEdit && <Text $whiteSpace={'pre-line'} $fontSize="body" $marginBottom={1}>
                    Form Type
                </Text>}
                {!additionalFormToEdit && <SelectBox
                    $hint="Incident form ..."
                    $value={name ? { value: name, label: name } : null}
                    $name={"additionalFormName"}
                    $onChange={(e) => formTypeChanged(e)}
                    $trailing={<Icon $icon="ArrowDropDown" />}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                    $options={options}
                    $marginBottom={2}
                />}

                {formInstructions && <Box $borderRadius={10} $width="100%" $paddingLeft="2" $paddingRight="2" $index={0} $marginBottom={2} $backgroundColor="note">
                    <MultiLineText $fontSize="body" $fontWeight="bold" $width="100%">
                        {formInstructions}
                    </MultiLineText>
                </Box>}

                <Text $fontSize="body" $marginBottom={1}>
                    Form Title
                </Text>
                <TextBox
                    $hint="name"
                    $type={"text"}
                    $name={"title"}
                    $value={title}
                    $onChange={(e) => setTitle(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                    $marginBottom={2}
                />

                <hr />

                {fields?.map((field, index) => {
                    return (
                        <>
                            <Text $fontSize="body" $marginBottom={1}>
                                {field.name}
                            </Text>
                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.TEXT && <Textarea
                                $hint="..."
                                $rows={5}
                                $type={"text"}
                                $name={field.name}
                                $value={field.value}
                                $onChange={(e) => {
                                    fields[index].value = e.target.value
                                    setFields([...fields])
                                }}
                                $width="100%"
                                $borderRadius="8px"
                                $border="1px solid black"
                                $marginBottom={4}
                            />}
                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.NUMBER && <TextBox
                                $hint="..."
                                $type={"number"}
                                $name={field.name}
                                $value={field.value}
                                $onChange={(e) => {
                                    fields[index].value = Number(e.target.value)
                                    setFields([...fields])
                                }}
                                $width="100%"
                                $borderRadius="8px"
                                $border="1px solid black"
                                $marginBottom={4}
                            />}
                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.DATE_TIME && <TextBox
                                $hint="..."
                                $type={"date"}
                                $name={field.name}
                                $value={field.value ? formateDateToDateTextboxFormat(field.value) : null}
                                $onChange={(e) => {
                                    fields[index].value = formateDateTextboxFormatToDate(e.target.value)
                                    setFields([...fields])
                                }}
                                $width="100%"
                                $borderRadius="8px"
                                $border="1px solid black"
                                $marginBottom={4}
                            />}
                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.OPTIONS && <SelectBox
                                $hint="..."
                                $value={name ? { value: field.value, label: field.value } : null}
                                $name={field.name}
                                $onChange={(e) => {
                                    fields[index].value = e.value
                                    setFields([...fields])
                                }}
                                $trailing={<Icon $icon="ArrowDropDown" />}
                                $width="100%"
                                $borderRadius="8px"
                                $border="1px solid black"
                                $options={field.options?.map(e => { return { value: e, label: e } })}
                                $marginBottom={4}
                            />}

                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.IMAGE && <Box $marginBottom={4}>
                                <FlexBox>
                                    {field.value && <Text $marginRight="2" $link $color="#0000FF" $fontWeight="bold" $onClick={() => {
                                        if (typeof field.value == "string") {
                                            return setViewImage(field.value)
                                        }
                                        var reader = new FileReader();
                                        reader.readAsDataURL(field.value);
                                        reader.onloadend = () => {
                                            setViewImage(reader.result)
                                        }
                                    }}>View image</Text>}
                                    <Text $color="#0000FF" $fontWeight="bold" $link $onClick={() => {
                                        setUploadFile({
                                            onFileUpload: (file) => {
                                                fields[index].value = file
                                                setFields([...fields])
                                            }
                                        })
                                    }}>Select new image</Text>
                                </FlexBox>
                            </Box>}

                            {field.type == ADDITIONAL_FORM_FIELD_TYPE.BODYMAP && <Box $marginBottom={4}>
                                <FlexBox>
                                    {field.value && <Text $marginRight="2" $link $color="#0000FF" $fontWeight="bold" $onClick={() => {
                                        if (typeof field.value == "string") {
                                            return setViewImage(field.value)
                                        }
                                        var reader = new FileReader();
                                        reader.readAsDataURL(field.value);
                                        reader.onloadend = () => {
                                            setViewImage(reader.result)
                                        }
                                    }}>View bodymap</Text>}
                                    <Text $color="#0000FF" $fontWeight="bold" $link $onClick={() => {
                                        setUploadBodymap({
                                            onFileUpload: (file) => {
                                                fields[index].value = file
                                                setFields([...fields])
                                            }
                                        })
                                    }}>Edit new bodymap</Text>
                                </FlexBox>
                            </Box>}
                        </>
                    )
                })}

                <Text $fontSize="body" $marginBottom={1}>
                    Notes
                </Text>
                <Textarea
                    $hint="..."
                    $rows={5}
                    $type={"text"}
                    $name={"notes"}
                    $value={notes}
                    $onChange={(e) => {
                        setNotes(e.target.value)
                    }}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                    $marginBottom={4}
                />

                <br />
                {errorMessage && (
                    <FlexBox $width="100%" $justifyContent="center" $marginTop={2}>
                        <BasicAlerts $type="error">{errorMessage}</BasicAlerts>
                    </FlexBox>
                )}
            </FlexBox>

            <FlexBox
                $justifyContent={props.$type === "edit" && additionalFormToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
                $padding={4}
                $backgroundColor="list"
            >
                {props.$type === "edit" && additionalFormToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => onDeleteAdditionalFormClick()}
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {
                            additionalFormDeleteStatus !== asyncStates.PENDING ? (
                                "Delete"
                            ) : (
                                <CircularLoader $color="white" $size={25} />
                            )
                        }
                    </Text>
                </Button>
                }
                {additionalFormToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() =>
                        props.$type === "add"
                            ? onCreateAdditionalFormClick()
                            : onUpdateAdditionalFormClick()
                    }
                >
                    <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
                        {
                            additionalFormUpdateStatus !== asyncStates.PENDING &&
                                additionalFormCreateStatus !== asyncStates.PENDING ? (
                                "Save"
                            ) : (
                                <CircularLoader $color="white" $size={25} />
                            )
                        }
                    </Text>
                </Button>}

                {additionalFormToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={() => {
                        restoreAdditionalFormClicked()
                    }}
                >
                    <Text $color={"white"}>
                        {additionalFormRestoreStatus !== asyncStates.PENDING ? (
                            "Restore archive"
                        ) : (
                            <CircularLoader $color="white" $size={25} />
                        )}
                    </Text>
                </Button>}

            </FlexBox>

            {uploadFile && <FormDialog
                $open={true}
                $handleDialog={() => {
                    setUploadFile(null)
                }}
                $title={"Image"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={false}
            >
                <Box $display="flex" $flexDirection="column" $alignItems="center" $justifyContent="center" $marginBottom="3">
                    <Text $fontSize="heading-3" $marginTop="3" $marginBottom="3">Upload Image</Text>
                    <DragAndDrop
                        $fileTypes={["JPG", "PNG", "GIF"]}
                        $onFileUpload={(file) => {
                            uploadFile.onFileUpload(file)
                            setUploadFile(null)
                        }}
                    />
                </Box>
            </FormDialog>}

            {uploadBodymap && <FormDialog
                $open={true}
                $handleDialog={() => {
                    setUploadBodymap(null)
                }}
                $title={"Image"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={false}
            >
                <UploadBodymap
                    $onFileUpload={(file) => {
                        uploadBodymap.onFileUpload(file)
                        setUploadBodymap(null)
                    }}
                />
            </FormDialog>}

            {viewImage && <FormDialog
                $open={true}
                $handleDialog={() => {
                    setViewImage(null)
                }}
                $title={"Image"}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={false}
            >
                <Box $display="flex" $flexDirection="column" $alignItems="center" $justifyContent="center" $marginBottom="3">
                    <Image $src={viewImage} />
                </Box>
            </FormDialog>}
        </>
    );
};
