import { EVENT_TYPE, FEATURE_LIST, REPETITION_PERIODS } from '../constants'
import { useBusinessAccountSelector } from '../redux/selectors/useBusinessAccountSelectors'
import { useGlobalFeatureAccessListSelector } from '../redux/selectors/useConfigsSelectors'

export default function usePermissionsController() {
  const featureAccessList = useGlobalFeatureAccessListSelector()
  const businessAccount = useBusinessAccountSelector()

  const subscriptionsEnabled = businessAccount?.subscriptionDetails?.subscriptionsEnabled === true

  const hasPermission = (feature) => {
    return (featureAccessList?.filter((f) => f.featureCode == feature && f.allowAccess) ?? []).length > 0
  }

  return {
    // page access
    accessHomePage: hasPermission(FEATURE_LIST.LIST_BUSINESS_ANALYTICS),
    accessAnalyticsPage: hasPermission(FEATURE_LIST.LIST_BUSINESS_ANALYTICS),
    accessTeamsPage: hasPermission(FEATURE_LIST.LIST_STAFF) || hasPermission(FEATURE_LIST.LIST_CLIENTS),
    accessSchedulePage: hasPermission(FEATURE_LIST.LIST_ALL_EVENTS),
    accessSettingsPage: hasPermission(FEATURE_LIST.LIST_SYSTEM_SETTINGS),
    accessTimeOffPage: hasPermission(FEATURE_LIST.LIST_ALL_STAFF_TIME_OFF_LIST),
    accessTimesheetsPage: hasPermission(FEATURE_LIST.LIST_STAFF_TIMESHEETS),
    accessSubscriptionPage: hasPermission(FEATURE_LIST.VIEW_SUBSCRIPTION_DETAILS) && subscriptionsEnabled,

    // staff access list
    accessStaffList: hasPermission(FEATURE_LIST.LIST_STAFF),
    addStaff: hasPermission(FEATURE_LIST.ADD_STAFF),
    editStaff: hasPermission(FEATURE_LIST.EDIT_STAFF),
    editOwnStaff: hasPermission(FEATURE_LIST.EDIT_OWN_STAFF),

    // client access list
    accessClientList: hasPermission(FEATURE_LIST.LIST_CLIENTS),
    addClient: hasPermission(FEATURE_LIST.ADD_CLIENTS),
    editClient: hasPermission(FEATURE_LIST.EDIT_CLIENTS),

    // subscriptions
    subscriptionsEnabled: hasPermission(FEATURE_LIST.SUBSCRIPTIONS_ENABLED) && subscriptionsEnabled,

    // client info access list
    accessClientDiary: hasPermission(FEATURE_LIST.LIST_CLIENTS_DIARY),
    accessClientCalendar: true,
    accessClientRiskassessments: hasPermission(FEATURE_LIST.LIST_CLIENT_RISKASSESSMENTS),
    accessClientCareplan: hasPermission(FEATURE_LIST.LIST_CLIENT_CAREPLANS),
    accessClientMedication: hasPermission(FEATURE_LIST.LIST_CLIENT_MEDICATIONS),
    accessClientEMAR: hasPermission(FEATURE_LIST.LIST_CLIENT_EMAR),
    accessClientAdditionalForm: hasPermission(FEATURE_LIST.LIST_CLIENT_ADDITIONAL_FORMS),
    accessClientFiles: hasPermission(FEATURE_LIST.LIST_CLIENT_FILES),
    accessClientTimesheets: hasPermission(FEATURE_LIST.LIST_CLIENT_TIMESHEETS),
    accessClientAccess: hasPermission(FEATURE_LIST.LIST_CLIENT_ACCESS),
    accessClientAbsense: true,
    accessClientPolicy: hasPermission(FEATURE_LIST.LIST_CLIENT_POLICY),
    accessClientBlockList: hasPermission(FEATURE_LIST.LIST_CLIENT_BLOCK_LIST),
    accessClientEmailList: hasPermission(FEATURE_LIST.LIST_CLIENT_EMAIL_LIST),

    // staff info access list
    accessSendStaffProfile: hasPermission(FEATURE_LIST.SEND_STAFF_PROFILE),
    accessStaffPolicy: hasPermission(FEATURE_LIST.LIST_STAFF_POLICY),
    accessStaffTimesheets: hasPermission(FEATURE_LIST.LIST_STAFF_TIMESHEETS),
    accessStaffBlockList: hasPermission(FEATURE_LIST.LIST_STAFF_BLOCK_LIST),
    staffJobRoleSpecified: hasPermission(FEATURE_LIST.STAFF_JOBROLE_SPECIFIED),

    // staff compliance access list
    listStaffCompliance: hasPermission(FEATURE_LIST.LIST_STAFF_COMPLIANCE),
    addStaffCompliance: hasPermission(FEATURE_LIST.ADD_STAFF_COMPLIANCE),
    editStaffCompliance: hasPermission(FEATURE_LIST.EDIT_STAFF_COMPLIANCE),

    // staff policy access list
    listStaffPolicy: hasPermission(FEATURE_LIST.LIST_STAFF_POLICY),
    addStaffPolicy: hasPermission(FEATURE_LIST.ADD_STAFF_POLICY),
    editStaffPolicy: hasPermission(FEATURE_LIST.EDIT_STAFF_POLICY),

    // client policy access list
    listClientPolicy: hasPermission(FEATURE_LIST.LIST_CLIENT_POLICY),
    addClientPolicy: hasPermission(FEATURE_LIST.ADD_CLIENT_POLICY),
    editClientPolicy: hasPermission(FEATURE_LIST.EDIT_CLIENT_POLICY),

    allowApproveTimeOff: hasPermission(FEATURE_LIST.APPROVE_STAFF_TIME_OFF_LIST),

    accessArchives: hasPermission(FEATURE_LIST.VIEW_ARCHIVES),

    clientDoctorDetailsSpecified: hasPermission(FEATURE_LIST.CLIENT_DOCTOR_DETAILS_SPECIFIED),
    clientNextOfKinDetailsSpecified: hasPermission(FEATURE_LIST.CLIENT_NEXT_OF_KIN_DETAILS_SPECIFIED),
    clientTherapistDetailsSpecified: hasPermission(FEATURE_LIST.CLIENT_THERAPIST_DETAILS_SPECIFIED),
    clientSocialworkerDetailsSpecified: hasPermission(FEATURE_LIST.CLIENT_SOCIALWORKER_DETAILS_SPECIFIED),
    clientRefIdSpecified: hasPermission(FEATURE_LIST.CLIENT_REFID_SPECIFIED),
    clientClientNotesSpecified: hasPermission(FEATURE_LIST.CLIENT_CLIENT_NOTES_SPECIFIED),
    clientDOBSpecified: hasPermission(FEATURE_LIST.CLIENT_DOB_SPECIFIED),
    clientGenderSpecified: hasPermission(FEATURE_LIST.CLIENT_GENDER_SPECIFIED),
    clientPhoneNumberSpecified: hasPermission(FEATURE_LIST.CLIENT_PHONENUMBER_SPECIFIED),
    clientEmailSpecified: hasPermission(FEATURE_LIST.CLIENT_EMAIL_SPECIFIED),
    clientEventsDashboardListFocused: hasPermission(FEATURE_LIST.CLIENT_EVENTS_DASHBOARD_LIST_FOCUSED),
    clientCalendarTitle: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED) ? "Shifts" : "Calendar",
    shiftsRequireCheckIn: hasPermission(FEATURE_LIST.EVENTS_REQUIRE_CHECKIN),

    // settings configuration access list
    accessViewSettingsRiskassessments: hasPermission(FEATURE_LIST.LIST_SYSTEM_RISKASSESSMENTS),
    accessViewSettingsTasks: hasPermission(FEATURE_LIST.LIST_SYSTEM_TASKS),
    accessViewSettingsCompliance: hasPermission(FEATURE_LIST.LIST_SYSTEM_COMPLIANCES),
    accessViewSettingsCareplans: hasPermission(FEATURE_LIST.LIST_SYSTEM_CAREPLANS),
    accessViewSettingsMedication: hasPermission(FEATURE_LIST.LIST_SYSTEM_MEDICINES),
    accessViewSettingsRefusalReaosns: hasPermission(FEATURE_LIST.LIST_SYSTEM_MISSED_MEDICATION_REASONS),
    accessViewSettingsAdditionalForms: hasPermission(FEATURE_LIST.LIST_SYSTEM_ADDITIONAL_FORMS),
    accessViewSettingsEventCheckLists: hasPermission(FEATURE_LIST.LIST_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST),
    accessViewSettingsPolicy: hasPermission(FEATURE_LIST.LIST_SYSTEM_POLICY),
    accessViewSettingsJobroles: hasPermission(FEATURE_LIST.LIST_SYSTEM_JOBROLES),
    accessViewSettingsBusinessAccount: true,
    accessViewSettingsTimeOffApprovers: hasPermission(FEATURE_LIST.LIST_SYSTEM_TIME_OFF_APPROVERS),
    accessViewSettingsDefaultQualifications: hasPermission(FEATURE_LIST.LIST_SYSTEM_DEFAULT_QUALIFICATIONS),

    // settings configuration (add) access list
    accessAddSettingsRiskassessments: hasPermission(FEATURE_LIST.ADD_SYSTEM_RISKASSESSMENTS),
    accessAddSettingsTasks: hasPermission(FEATURE_LIST.ADD_SYSTEM_TASKS),
    accessAddSettingsCompliance: hasPermission(FEATURE_LIST.ADD_SYSTEM_COMPLIANCES),
    accessAddSettingsCareplans: hasPermission(FEATURE_LIST.ADD_SYSTEM_CAREPLANS),
    accessAddSettingsMedication: hasPermission(FEATURE_LIST.ADD_SYSTEM_MEDICINES),
    accessAddSettingsRefusalReaosns: hasPermission(FEATURE_LIST.ADD_SYSTEM_MISSED_MEDICATION_REASONS),
    accessAddSettingsAdditionalForms: hasPermission(FEATURE_LIST.ADD_SYSTEM_ADDITIONAL_FORMS),
    accessAddSettingsEventCheckLists: hasPermission(FEATURE_LIST.ADD_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST),
    accessAddSettingsPolicy: hasPermission(FEATURE_LIST.ADD_SYSTEM_POLICY),
    accessAddSettingsJobroles: hasPermission(FEATURE_LIST.ADD_SYSTEM_JOBROLES),
    accessAddSettingsBusinessAccount: hasPermission(FEATURE_LIST.EDIT_BUSINESS_ACCOUNT),
    accessAddSettingsTimeOffApprovers: hasPermission(FEATURE_LIST.ADD_SYSTEM_TIME_OFF_APPROVERS),
    accessAddSettingsDefaultQualifications: hasPermission(FEATURE_LIST.ADD_SYSTEM_DEFAULT_QUALIFICATIONS),

    // settings configuration (add) access list
    accessEditSettingsRiskassessments: hasPermission(FEATURE_LIST.EDIT_SYSTEM_RISKASSESSMENTS),
    accessEditSettingsTasks: hasPermission(FEATURE_LIST.EDIT_SYSTEM_TASKS),
    accessEditSettingsCompliance: hasPermission(FEATURE_LIST.EDIT_SYSTEM_COMPLIANCES),
    accessEditSettingsCareplans: hasPermission(FEATURE_LIST.EDIT_SYSTEM_CAREPLANS),
    accessEditSettingsMedication: hasPermission(FEATURE_LIST.EDIT_SYSTEM_MEDICINES),
    accessEditSettingsRefusalReaosns: hasPermission(FEATURE_LIST.EDIT_SYSTEM_MISSED_MEDICATION_REASONS),
    accessEditSettingsAdditionalForms: hasPermission(FEATURE_LIST.EDIT_SYSTEM_ADDITIONAL_FORMS),
    accessEditSettingsEventCheckLists: hasPermission(FEATURE_LIST.EDIT_SYSTEM_DISTRUBUTED_EVENT_CHECKLIST),
    accessEditSettingsPolicy: hasPermission(FEATURE_LIST.EDIT_SYSTEM_POLICY),
    accessEditSettingsJobroles: hasPermission(FEATURE_LIST.EDIT_SYSTEM_JOBROLES),
    accessEditSettingsBusinessAccount: hasPermission(FEATURE_LIST.EDIT_BUSINESS_ACCOUNT),
    accessEditSettingsTimeOffApprovers: hasPermission(FEATURE_LIST.EDIT_SYSTEM_TIME_OFF_APPROVERS),
    accessEditSettingsDefaultQualifications: hasPermission(FEATURE_LIST.EDIT_SYSTEM_DEFAULT_QUALIFICATIONS),

    // schedule access list
    allowShiftDistribution: hasPermission(FEATURE_LIST.EVENTS_DISTRIBUTABLE),
    scheduleStaffCentered: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_STAFF_FOCUSED),
    scheduleClientCentered: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED),
    defaultScheduleView: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED) ? REPETITION_PERIODS.WEEK : REPETITION_PERIODS.DAY,
    scheduleDuplicateEventDayView: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_DUPLICATE),
    scheduleDuplicateEventWeekView: false,
    allowEventFlexibleStartTime: hasPermission(FEATURE_LIST.EVENTS_FLEXIBLE_START_SPECIFIED),
    allowEventBreakDuration: hasPermission(FEATURE_LIST.EVENTS_BREAK_SPECIFICED),
    allowMultiDayEvent: hasPermission(FEATURE_LIST.EVENTS_MULTI_DAY),
    allowShiftJobRoles: hasPermission(FEATURE_LIST.EVENTS_JOBROLE_SPECIFIED),

    // platfrom access
    accessWeb: hasPermission(FEATURE_LIST.WEB_ACCESS),

    // create event configs
    allowCreateEventSelectServices: hasPermission(FEATURE_LIST.EVENTS_SERVICES_INPUT),
    defaultCreateEventUserTitle: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED) ? "Worker" : "Carer",
    defaultCreateEventModelTitle: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED) ? "Shift" : "Event",
    defaultClientEventType: hasPermission(FEATURE_LIST.EVENTS_SCHEDULE_CLIENT_FOCUSED) ? EVENT_TYPE.SHIFT : EVENT_TYPE.CLIENT_VISIT,
    defaultStaffEventType: EVENT_TYPE.CUSTOM,
    allowSelectAdditionalStaff: hasPermission(FEATURE_LIST.EVENTS_ADDITIONAL_STAFF_INPUT),
  }
}
