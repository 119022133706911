export const cookieStorageService = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [k, v]) => ({ ...acc, [k.trim()]: v }), {})

    return cookies[key]
  },
  setItem: (key, value, length = 1000 * 36000 * 50000) => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + length;
    now.setTime(expireTime);
    //var tempExp = 'Wed, 31 Oct 2012 08:50:17 GMT';
    document.cookie = `${key}=${value}; expires=${now.toGMTString()};path=/`
  },
  deleteItem: (key) => {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time - 1200000;
    now.setTime(expireTime);
    document.cookie = `${key}=to_be_deleted; expires=${now.toGMTString()};path=/`
  }
}

export const localStorageService = {
  getItem: (key) => {
    return localStorage.getItem(key)
  },
  setItem: (key, value) => {
    localStorage.setItem(key, value)
  },
  deleteItem: (key) => {
    localStorage.removeItem(key)
  }
}