import { useDispatch } from "react-redux"
import {
    clearAllErrorMessagesReducer,
    clearAllSuccessMessagesReducer,
    clearLocalEventReducer,
    clearFiltersReducer,
    createEventReducer,
    deleteEventReducer,
    getEventsReducer,
    getEventReducer,
    updateEventReducer,
    updateFilterClientIdReducer,
    updateFilterEndDateReducer,
    updateFilterStaffIdReducer,
    updateFilterStartDateReducer,

    updateIsClientVisitFilter,
    updateIsShiftFilter,
    updateIsAvailabilityFilter,
    updateIsCustomEventFilter,
    updateIsAbsenseEventFilter,
    getStaffAvailabilitiesReducer,
    autoPilotEventsReducer,
    clearAllLocalEventsReducer,
    updateSortByFilter, updateIsDistributePendingApprovalFilter,
} from "../../slices/eventSlices"

const useEventsDispathers = () => {
    const dispatch = useDispatch()

    const getEvents = (data) => {
        return dispatch(getEventsReducer(data))
    }

    const getEvent = (data) => {
        return dispatch(getEventReducer(data))
    }

    const getStaffAvailabilities = (data) => {
        return dispatch(getStaffAvailabilitiesReducer(data))
    }

    const deleteEvent = (data) => {
        return dispatch(deleteEventReducer(data))
    }

    const createEvent = (data) => {
        return dispatch(createEventReducer(data))
    }

    const updateEvent = (data) => {
        return dispatch(updateEventReducer(data))
    }

    const autoPilotEvents = (data) => {
        return dispatch(autoPilotEventsReducer(data))
    }

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data))
    }

    const clearLocalEvent = (data) => {
        return dispatch(clearLocalEventReducer(data))
    }

    const clearLocalEvents = (data) => {
        return dispatch(clearAllLocalEventsReducer(data))
    }

    const clearAllSuccessMessages = (data) => {
        return dispatch(clearAllSuccessMessagesReducer(data))
    }

    const updateFilterStartDate = (startDateRange) => {
        return dispatch(updateFilterStartDateReducer(startDateRange))
    }

    const updateFilterEndDate = (endDateRange) => {
        return dispatch(updateFilterEndDateReducer(endDateRange))
    }

    const updateFilterClientId = (data) => {
        return dispatch(updateFilterClientIdReducer(data))
    }

    const updateFilterStaffId = (data) => {
        return dispatch(updateFilterStaffIdReducer(data))
    }

    const updateFilterIsClientVisit = (data) => {
        return dispatch(updateIsClientVisitFilter(data))
    }
    const updateFilterIsShift = (data) => {
        return dispatch(updateIsShiftFilter(data))
    }
    const updateFilterIsAvailability = (data) => {
        return dispatch(updateIsAvailabilityFilter(data))
    }
    const updateFilterIsCustomEvent = (data) => {
        return dispatch(updateIsCustomEventFilter(data))
    }
    const updateFilterIsAbsenseEvent = (data) => {
        return dispatch(updateIsAbsenseEventFilter(data))
    }

    const updateFilterIsDistributePendingApproval = (data) => {
        return dispatch(updateIsDistributePendingApprovalFilter(data))
    }

    const updateSortBy = (data) => {
        return dispatch(updateSortByFilter(data))
    }

    const clearFilters = (data) => {
        return dispatch(clearFiltersReducer(data))
    }

    return {
        getEvents,
        getEvent,
        getStaffAvailabilities,
        deleteEvent,
        createEvent,
        updateEvent,
        autoPilotEvents,
        clearAllErrorMessages,
        clearAllSuccessMessages,
        updateFilterStartDate,
        updateFilterEndDate,
        updateFilterClientId,
        updateFilterStaffId,
        updateFilterIsClientVisit,
        updateFilterIsShift,
        updateFilterIsAvailability,
        updateFilterIsCustomEvent,
        updateFilterIsAbsenseEvent,
        updateFilterIsDistributePendingApproval,
        updateSortBy,
        
        clearFilters,
        clearLocalEvent,
        clearLocalEvents,
    }

}

export default useEventsDispathers