import useEventsDispathers from '../../redux/dispatchers/useEventsDispatcher';
import {useEventsSelector, useGetEventsStatusSelector, useMoreEventsLeftInPaginationSelector} from '../../redux/selectors/useEventsSelectors';
import React, {useEffect} from 'react';
import {Box, FlexBox} from '../../Components/Boxes';
import {Grid, GridContainer} from '../../Components/Grid';
import {Text} from '../../Components/Text';
import MuiSkeletonLoader from '../../Components/LoadingSkeleton/MuiSkeletonLoader';
import {Button} from '../../Components/Input';
import EmptyState from '../../Components/NoRecordFound';
import * as asyncStates from '../../redux/constants/asyncStates';
import EventDistributableCard from './EventDistributableCard';


export const PendingApprovalView = ({ onEventClick }) => {
    const {
        getEvents,
        clearFilters,
        updateFilterClientId,
        updateFilterStaffId,
        updateFilterIsAvailability,
        updateFilterIsClientVisit,
        updateFilterIsCustomEvent,
        updateFilterIsShift,
        updateFilterIsDistributePendingApproval,
    } = useEventsDispathers()

    const events = useEventsSelector()
    const moreEventsLeft = useMoreEventsLeftInPaginationSelector()
    const getEventsStatus = useGetEventsStatusSelector()

    const refreshEvents = ({ paginate }) => {
        getEvents({
            startAfter: paginate ? events[events.length - 1]?.id : null,
        })
    }

    useEffect(() => {
        clearFilters({})
        updateFilterClientId(null)
        updateFilterStaffId(null)
        updateFilterIsDistributePendingApproval(true)
        updateFilterIsAvailability(false)
        updateFilterIsClientVisit(true)
        updateFilterIsCustomEvent(true)
        updateFilterIsShift(true)
        refreshEvents({  })
    }, [])

    return (
        <Box $width={"100%"}>
            <FlexBox $width="100%" $marginTop={3} $padding={"3"}>
                <GridContainer $spacing={1}>
                    <Grid $xs={3}>
                        <Text $fontWeight="bold">
                            Staffer
                        </Text>
                    </Grid>
                    <Grid $xs={3}>
                        <Text $fontWeight="bold">
                            Client
                        </Text>
                    </Grid>
                    <Grid $xs={2}>
                        <Text $fontWeight="bold">
                            Start Date
                        </Text>
                    </Grid>
                    <Grid $xs={2}>
                        <Text $fontWeight="bold">
                            End Date
                        </Text>
                    </Grid>
                    <Grid $xs={2}>
                        <Text $fontWeight="bold">
                            Accepted At
                        </Text>
                    </Grid>
                </GridContainer>
            </FlexBox>

            <Box $marginTop={3} />

            {events && events?.map((event, index) => {
                return <EventDistributableCard key={event.id} event={event} index={index} onClick={() => { onEventClick(event) }} />
            })}

            {(getEventsStatus === asyncStates.PENDING) && [1, 2, 3, 4].map((value) => {
                return (
                    <Box $width="100%" $marginBottom="2" key={value}>
                        <MuiSkeletonLoader
                            $variant="rectangular"
                            $width={"100%"}
                            $height={40}
                        />
                    </Box>
                );
            })}

            {moreEventsLeft && <FlexBox $justifyContent="center" $marginTop="6" $width="100%">
                <Button $onClick={() => {
                    refreshEvents({
                        paginate: true
                    })
                }} >Load More</Button>
            </FlexBox>}

            {!(getEventsStatus === asyncStates.PENDING) && events?.length < 1 && <FlexBox $width="100%" $justifyContent="center" $marginTop={7}>
                <EmptyState />
            </FlexBox>}

            <Box $marginTop={5} />
        </Box>
    )
}