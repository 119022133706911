import React, { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  ErrorAndSuccess,
  FlexBox,
  ListBox,
} from "../../../../../Components/Boxes/index";
import { useResponsive } from "../../../../../Components/Hooks";
import Icon from "../../../../../Components/Icons";
import { Button } from "../../../../../Components/Input/index";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import EditCompliance from "../Dialogs/EditCompliance";
import useComplianceDispathers from "../../../../../redux/dispatchers/useStaffComplianceDispatcher";
import { useParams } from "react-router-dom";
import {
  useAllComplianceGetStatusSelector,
  useAllComplianceSelector,
  useComplianceGetErrorMessageSelector,
  useMoreComplianceItemsLeftInPaginationSelector,
} from "../../../../../redux/selectors/useStaffComplianceSelector";
import { formateDateToReadableFormatWithoutTime, isDateInPast } from "../../../../../utiles/dateformate";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";

const Compliance = ({ canEdit, canAdd }) => {
  // ---see whether it's desktop size or not-----
  const isDesktop = useResponsive({ xs: false, sm: true });
  const {
    accessArchives,

  } = usePermissionsController()

  // -----------getting staff id from URL --------
  const params = useParams();

  //------------ dispatchers ---------------------
  const { getAllCompliance, clearAllLocalCompliance } = useComplianceDispathers();

  // ----------- selectors -------------------------
  const gettingAllCompliance = useAllComplianceSelector();
  const gettingAllComplianceStatus = useAllComplianceGetStatusSelector();
  const complianceGetErrorMessage = useComplianceGetErrorMessageSelector();
  const errorMessage = complianceGetErrorMessage;
  const moreComplianceItemsLeftInPagination = useMoreComplianceItemsLeftInPaginationSelector()

  // ----------- states ---------------------------
  const [complianceToEdit, setComplianceToEdit] = useState(null);
  const [isArchived, setIsArchived] = useState(false)
  const [open, setOpen] = useState(false);
  function HandleDialog() {
    setOpen(!open);
    setComplianceToEdit(null);
  }

  const loadMore = () => {
    getAllCompliance({
      staffId: params.staffid,
      startAfter: gettingAllCompliance[gettingAllCompliance.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalCompliance();
    getAllCompliance({
      isArchived: isArchived,
      staffId: params.staffid,
    });
  }, [isArchived]);
  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}

        {canAdd && <Button
          $onClick={() => {
            HandleDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>New Compilance</Text>
        </Button>}
      </FlexBox>

      <FlexBox $width="100%" $marginTop={3} $padding="3">
        <Box $width="50%">
          <Text $fontWeight="bold">
            Name
          </Text>
        </Box>
        <Box $width="17%" $display="flex" $justifyContent="center">
          <Text>Valid From</Text>
        </Box>
        <Box $width="17%" $display="flex" $justifyContent="center">
          <Text>Expiry</Text>
        </Box>
        <Box $width="16%" $display="flex" $justifyContent="center">
          <Text>File</Text>
        </Box>
      </FlexBox>

      {gettingAllComplianceStatus === asyncStates.SUCCESS &&
        gettingAllCompliance < 1 ? (
        <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
          <EmptyState />
        </FlexBox>
      ) : (
        gettingAllCompliance?.map((value, index) => {
          return (
            <ListBox
              $width="100%"
              key={index}
              $index={index}
              $onClick={() => {
                if (!canEdit) return;
                HandleDialog();
                setComplianceToEdit(value);
              }}
            >
              <FlexBox $width="100%" >
                <Box $width="50%">
                  <Text $fontWeight="bold">
                    {value.complianceName}
                  </Text>
                </Box>
                <Box $width="17%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {value.validFrom ? formateDateToReadableFormatWithoutTime(value.validFrom) : "-"}
                  </Text>
                </Box>
                <Box $width="17%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold" $color={value.validTo && isDateInPast(value.validTo) ? 'red' : undefined} >
                    {value.validTo ? formateDateToReadableFormatWithoutTime(value.validTo) : "-"}
                  </Text>
                </Box>
                <Box $width="16%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {value.files.length}
                  </Text>
                </Box>
              </FlexBox>
            </ListBox>
          );
        })
      )}
      {gettingAllComplianceStatus === asyncStates.PENDING &&
        [1, 2, 3, 4].map((value) => {
          return (
            <Box $width="100%" $marginTop="2" key={value}>
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={40}
              />
            </Box>
          );
        })}

      {errorMessage && (
        <FlexBox $marginTop={2} $width="100%">
          <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
        </FlexBox>
      )}

      {moreComplianceItemsLeftInPagination && <FlexBox $width="100%" $justifyContent="center" $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Compilance"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <EditCompliance
          $handleDialog={HandleDialog}
          $type={"add"}
          $compliance={complianceToEdit}
        />
      </FormDialog>
    </Box>
  );
};

export default Compliance;
