import React, { useContext, useEffect } from "react";
import Dashboard from "./Pages/Dashboard";
import { Login, ResetPassword, SelectAccount, StaffSignUp } from "./Pages/Authentication";
import { AuthContext } from "./contextAPI's/authContext";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useConnectionStatus } from "./Components/Hooks";
import useBusinessAccountDispatchers from "./redux/dispatchers/useBusinessAccountDispatchers"
import { useBusinessAccountGetErrorMessageSelector, useBusinessAccountGetStatusSelector, useBusinessAccountSelector, useBusinessAccountsGetErrorMessageSelector, useBusinessAccountsGetStatusSelector, useBusinessAccountsSelector } from "./redux/selectors/useBusinessAccountSelectors";
import { localStorageService } from "./utiles/storage";
import * as asyncStates from "./redux/constants/asyncStates"
import { Box } from "./Components/Boxes";
import SendPasswordReset from "./Pages/Authentication/SendPasswordReset";
import ClientSignUp from "./Pages/Authentication/ClientSignUp";

function App() {
  const {
    loading: authLoading,
    isLoggedIn,
    Logout,
    SwitchAccount,
  } = useContext(AuthContext);
  const location = useLocation();
  const isOnline = useConnectionStatus();

  const businessAccounts = useBusinessAccountsSelector()
  const businessAccount = useBusinessAccountSelector()
  const businessAccountsGetErrorMessage = useBusinessAccountsGetErrorMessageSelector()
  const businessAccountGetErrorMessage = useBusinessAccountGetErrorMessageSelector()
  const businessAccountsGetStatus = useBusinessAccountsGetStatusSelector()
  const businessAccountGetStatus = useBusinessAccountGetStatusSelector()

  const {
    getBusinessAccounts,
    getBusinessAccount,
    selectBusinessAccount,
    clearAllErrorMessages,
    clearAllSuccessMessages,
    clearSelectedBusinessAccount,
    clearAllLocalBusinessAccounts,
  } = useBusinessAccountDispatchers()

  useEffect(() => {
    clearAllErrorMessages()
    clearAllSuccessMessages()
    clearAllLocalBusinessAccounts()
    clearSelectedBusinessAccount()
    if (isLoggedIn) {
      // check local storage if business Id exists then get that business account
      const businessAccountId = localStorageService.getItem("businessAccountId")
      if (businessAccountId) {
        getBusinessAccount({ businessAccountId })
        if (!businessAccounts || businessAccounts.length <= 0) {
          getBusinessAccounts()
        }
      } else {
        getBusinessAccounts()
      }
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (businessAccounts && businessAccounts.length == 1) {
      // add to local storage
      selectBusinessAccount(businessAccounts[0])
      localStorageService.setItem("businessAccountId", businessAccounts[0].id)
    }
  }, [businessAccounts])

  useEffect(() => {
    if (businessAccountsGetErrorMessage || businessAccountGetErrorMessage) {
      // logout 
      Logout()
    }
  }, [businessAccountsGetErrorMessage, businessAccountGetErrorMessage])


  if (location.pathname === "/auth/signup") {
    return (
      <>
        <ToastContainer autoClose={false} />
        <StaffSignUp />
      </>
    )
  }

  if (location.pathname === "/auth/passwordreset") {
    return (
      <>
        <ToastContainer autoClose={false} />
        <ResetPassword />
      </>
    )
  }

  if (location.pathname === "/auth/sendpasswordreset") {
    return (
      <>
        <ToastContainer autoClose={false} />
        <SendPasswordReset />
      </>
    )
  }

  if (location.pathname === "/auth/client/signup") {
    return (
      <>
        <ToastContainer autoClose={false} />
        <ClientSignUp />
      </>
    )
  }

  if (!isLoggedIn) {
    return (
      <>
        <ToastContainer autoClose={false} />
        <Login />
      </>
    )
  }

  // select business account to use
  if (!businessAccount) {
    return <Box $width="100%">
      <ToastContainer autoClose={false} />
      <SelectAccount
        businessAccounts={businessAccounts}
        errorMessage={businessAccountsGetErrorMessage || businessAccountGetErrorMessage}
        loading={businessAccountsGetStatus == asyncStates.PENDING || businessAccountGetStatus == asyncStates.PENDING || authLoading}
        onSelect={async (index) => {
          const businessAccount = businessAccounts[index]
          await SwitchAccount({
            businessAccountId: !businessAccount.isClientAccess ?  businessAccount.id : null,
            clientAccessId: businessAccount.isClientAccess ?  businessAccount.clientAccessId : null,
            onSuccess: () => {
              localStorageService.setItem("businessAccountId", businessAccount.id)
              selectBusinessAccount(businessAccount)
            },
            onError: () => {
              Logout()
            },
          })

        }}
      />
    </Box>
  }

  return (
    <>
      <ToastContainer autoClose={false} />
      <Dashboard />
    </>
  );
}

export default App;
