import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalCarePlansReducer,
  clearAllSuccessMessagesReducer,
  createCarePlanReducer,
  deleteCarePlanReducer,
  getAllCarePlanReducer,
  restoreArchivedCarePlanReducer,
  updateCarePlanReducer,
} from "../../slices/clientCareplanSlice";

const useCarePlanDispatchers = () => {
  const dispatch = useDispatch();

  const createCarePlan = (data) => {
    return dispatch(createCarePlanReducer(data));
  };

  const restoreArchivedCarePlan = (data) => {
    return dispatch(restoreArchivedCarePlanReducer(data))
  }

  const updateCarePlan = (data) => {
    return dispatch(updateCarePlanReducer(data));
  };

  const getCarePlans = (data) => {
    return dispatch(getAllCarePlanReducer(data));
  };

  const deleteCarePlan = (data) => {
    return dispatch(deleteCarePlanReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  const clearAllLocalCarePlans = (data) => {
    return dispatch(clearAllLocalCarePlansReducer(data));
  };

  return {
    restoreArchivedCarePlan,
    createCarePlan,
    getCarePlans,
    clearAllErrorMessages,
    updateCarePlan,
    deleteCarePlan,
    clearAllSuccessMessages,
    clearAllLocalCarePlans,
  };
};

export default useCarePlanDispatchers;
