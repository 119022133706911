import React from "react";
const Address = ({ data }) => {
  const line1 = data?.line1 ?? "";
  const line2 = data?.line2 ?? "";
  const city = data?.city ?? "";
  const region = data?.region ?? "";
  const country = data?.country ?? "";
  const postcode = data?.postcode ?? "";
  return (
    <>
      {line1} {line2} {city} {region} {country} {postcode}
    </>
  );
};
export default Address;
