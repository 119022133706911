import { useContext, useEffect, useMemo, useState } from "react";
import { Box, FlexBox } from "../../../../Components/Boxes";
// import Icon from "../../../../Components/Icons";
import { Text } from "../../../../Components/Text";
import Diary from "./components/Diary";
import Calender from "../../Events/Calender";
import RiskAssessments from "./components/RiskAssessments";
import CarePlan from "./components/CarePlan";
import Medication from "./components/Medication";
import Files from "./components/Files";
import { useParams, useSearchParams } from "react-router-dom";
import { useResponsive } from "../../../../Components/Hooks/index";
import { ProfileCard } from "../../../../Components/BasicComponents/ProfileCard";
import { BreadCrumbs } from "../../../../Components/BasicComponents/BreadCrumbs";
import useClientDispatchers from "../../../../redux/dispatchers/useClientDispatchers";
import { AuthContext } from "../../../../contextAPI's/authContext";
import { useClientUpdateStatusSelector, useSingleClientSelector, useSingleClientStatusSelector } from "../../../../redux/selectors/useClientSelectors";
import Tabs from "../../../../Components/Input/Tabs";
import EMARChart from "./components/EMARChart";
import AdditionalForms from "./components/AdditionalForms";
import usePermissionsController from "../../../../permissions";
import * as asyncStates from "../../../../redux/constants/asyncStates"
import { EVENT_TYPE } from "../../../../constants";
import TimesheetView from "../../Timesheets/TimesheetView";
import ClientViewAccess from "./components/ClientViewAccess";
import Policy from "../../Policy/ViewPolicyList";
import BlockListView from "../../BlockList/BlockListView";
import EmailListView from "../../EmailList/EmailListView";
import UserAnalytics from "../../../Analytics/UserAnalytics";

export default function ClientProfile() {
  const isDesktop = useResponsive({ xs: false, md: true });
  const {
    accessClientDiary,
    accessClientCalendar,
    accessClientRiskassessments,
    accessClientCareplan,
    accessClientMedication,
    accessClientEMAR,
    accessClientAdditionalForm,
    accessClientTimesheets,
    accessClientFiles,
    accessClientAccess,
    accessClientAbsense,
    accessClientPolicy,
    accessClientBlockList,
    accessClientEmailList,
    accessAnalyticsPage,
    clientEventsDashboardListFocused,
    clientCalendarTitle,

    addClientPolicy,
    editClientPolicy
  } = usePermissionsController()
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState(searchParams.get("event_id") && accessClientCalendar ? 1 : 0)
  //fetching client from database

  //Get Single Client
  const { getSingleClient, updateClient } = useClientDispatchers();
  const getSingleClientIDSuccessMsg = useSingleClientSelector();
  const getClientStatus = useSingleClientStatusSelector();
  const updateClientStatus = useClientUpdateStatusSelector()

  const tabOptions = useMemo(() => {
    const opts = []

    if (accessClientDiary)
      opts.push({ name: "Diary" })
    if (accessClientCalendar)
      opts.push({ name: clientCalendarTitle /*"Calender"*/ })
    if (accessClientAbsense)
      opts.push({ name: "Absence" })
    if (accessClientRiskassessments)
      opts.push({ name: "Risk Assessments" })
    if (accessClientCareplan)
      opts.push({ name: "Care Plan" })
    if (accessClientTimesheets)
      opts.push({ name: "Timesheets" })
    if (accessClientMedication)
      opts.push({ name: "Medication" })
    if (accessClientEMAR)
      opts.push({ name: "EMAR" })
    if (accessClientAdditionalForm)
      opts.push({ name: "Additional Forms" })
    if (accessClientFiles)
      opts.push({ name: "Files" })
    if (accessClientAccess)
      opts.push({ name: "Client view" })
    if (accessClientPolicy)
      opts.push({ name: "Policy" })
    if (accessClientBlockList)
      opts.push({ name: "Block List" })
    if (accessClientEmailList)
      opts.push({ name: "Emails" })
    if (accessAnalyticsPage)
      opts.push({ name: "Analytics" })


    return opts
  }, [])

  const select = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  useEffect(() => {
    getSingleClient({
      clientId: params.clientId,
    });
  }, []);

  const updateImage = async ($file, $fileUrl) => {
    await updateClient({
      id: params.clientId,
      profilePhotoFile: $file,
    }).unwrap()
    getSingleClient({
      clientId: params.clientId,
    });
  }

  return (
    <FlexBox
      $minHeight="100%"
      $flexDirection="column"
      $alignItems="flex-start"
      $backgroundColor="#fff"
      $borderRadius="10px"
      // $border="1px solid red"
      $position="relative"
      $width="100%"
    >

      <BreadCrumbs
        $crumbs={[{
          label: "Teams",
          path: "/team"
        }, {
          label: "Clients",
          path: "/team/clients"
        }, {
          label: getSingleClientIDSuccessMsg?.name,
          path: ""
        }]}
      />

      <FlexBox
        $minHeight="100%"
        $flexDirection={{ xs: "column", sm: "row" }}
        $alignItems={{ xs: "center", sm: "flex-start" }}
        // $border="1px solid blue"
        $paddingLeft={{ xs: 0, sm: 2 }}
        $maxWidth="100%"
        $width="100%"
      >
        <ProfileCard
          data={getSingleClientIDSuccessMsg}
          type="ClientData"
          $onImageChanged={($file, $fileLocalUrl) => updateImage($file, $fileLocalUrl)}
          $imageLoading={getClientStatus == asyncStates.PENDING || updateClientStatus == asyncStates.PENDING}
        />

        <FlexBox
          $width={isDesktop ? "67%" : "95%"}
          $minHeight="100%"
          $backgroundColor="#fff"
          $borderRadius="10px"
          $flexDirection="column"
          $marginTop={{ xs: 2, sm: 0 }}
        >
          <Tabs
            $onChanged={(val) => {
              setTabIndex(val)
            }}
            $value={tabIndex}
            $tabs={tabOptions}
          />
          <Box $marginTop={2} />

          {select === "Diary" && accessClientDiary && <Diary clientId={params.clientId} />}
          {select === clientCalendarTitle /*"Calender"*/ && accessClientCalendar && <Calender clientId={params.clientId} isListView={clientEventsDashboardListFocused} />}
          {select === "Absence" ? <Calender clientId={params.clientId} eventType={EVENT_TYPE.ABSENCE} /> : <></>}
          {select === "Risk Assessments" && accessClientRiskassessments && <RiskAssessments clientId={params.clientId} />}
          {select === "Care Plan" && accessClientCareplan && <CarePlan clientId={params.clientId} />}
          {select === "Medication" && accessClientMedication && <Medication clientId={params.clientId} />}
          {select === "EMAR" && accessClientEMAR && <EMARChart clientId={params.clientId} />}
          {select === "Files" && accessClientFiles && <Files clientId={params.clientId} />}
          {select === "Additional Forms" && accessClientAdditionalForm && <AdditionalForms clientId={params.clientId} />}
          {select == "Timesheets" && accessClientTimesheets && <TimesheetView clientId={params.clientId} />}
          {select == "Client view" && accessClientAccess && <ClientViewAccess clientId={params.clientId} />}
          {select === "Policy" && accessClientPolicy && <Policy clientId={params.clientId} canAdd={addClientPolicy} canEdit={editClientPolicy} />}
          {select == "Block List" && accessClientBlockList && <BlockListView clientId={params.clientId} />}
          {select == "Emails" && accessClientEmailList && <EmailListView clientId={params.clientId} />}
          {select == "Analytics" && accessAnalyticsPage && <UserAnalytics clientId={params.clientId} />}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
