import { useSelector } from "react-redux";

export const useTimeOffsSelector = () => useSelector((state) => {
    return state.timeOff.timeOffs;
});

// ------------------- selector error messages ------------------ //
export const useTimeOffCreateErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.createTimeOffErrorMessage;
});

export const useTimeOffDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.deleteTimeOffErrorMessage;
});

export const useTimeOffUpdateErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.updateTimeOffErrorMessage;
});

export const useTimeOffsGetErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.getTimeOffsErrorMessage;
});

export const useTimeOffGetErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.getTimeOffErrorMessage;
});

export const useTimeOffRestoreErrorMessageSelector = () => useSelector((state) => {
    return state.timeOff.errorMessages.restoreTimeOffErrorMessage;
});

// ------------ status selectors ------------ //
export const useTimeOffCreateStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.createTimeOffStatus;
});

export const useTimeOffDeleteStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.deleteTimeOffStatus;
});

export const useTimeOffUpdateStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.updateTimeOffStatus;
});

export const useTimeOffsGetStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.getTimeOffsStatus;
});

export const useTimeOffGetStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.getTimeOffStatus;
});

export const useTimeOffRestoreStatusSelector = () => useSelector((state) => {
    return state.timeOff.statuses.restoreTimeOffStatus;
});

// ------------ success selectors ------------ //
export const useTimeOffUpdateSuccessSelector = () => useSelector((state) => {
    return state.timeOff.sucessMessages.updateTimeOffSuccessMessage
})

export const useMoreTimeOffsLeftInPaginationSelector = () => useSelector((state) => {
    return state.timeOff.moreTimeOffsLeftInPagination
})