import FlexBox from "./FlexBox";

export default function Index({ children, ...rest }) {
  return (
    <FlexBox
      {...{
        $borderRadius: "100%",
        $width: rest.$size,
        $height: rest.$size,
        $justifyContent: "center",
        ...rest,
      }}
    >
      {children}
    </FlexBox>
  );
}
