/* eslint-disable no-lone-blocks */
import React from "react";
import { FlexBox } from "../../../../../Components/Boxes/index";
import DiaryView from "../../../Diary/DiaryView";
import { useClientDiaryController } from "../../../Diary/DiaryControllers";

const Diary = ({ clientId }) => {
  return (
    <FlexBox
      $width="100%"
      $height="100%"
      $backgroundColor="#fff"
      $borderRadius="10px"
      $alignItems="flex-start"
      $flexDirection="column"
      $marginTop={3}
    >
      <DiaryView controller={useClientDiaryController(clientId) }/>
    </FlexBox>
  );
};

export default Diary;