import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, FlexBox } from "../../../../../../Components/Boxes";
import Icon from "../../../../../../Components/Icons";
import {
  Button,
  SelectBox,
  Textarea,
  MultiSelect,
} from "../../../../../../Components/Input";
import { Text } from "../../../../../../Components/Text";
import useCarePlanDispatchers from "../../../../../../redux/dispatchers/useClientCarePlanDispatchers";
import {
  useCarePlanCreateErrorMessageSelector,
  useCarePlanCreateStatusSelector,
  useCarePlanDeleteErrorMessageSelector,
  useCarePlanDeleteStatusSelector,
  useCarePlanRestoreErrorMessageSelector,
  useCarePlanRestoreStatusSelector,
  useCarePlanUpdateErrorMessageSelector,
  useCarePlanUpdateStatusSelector,
} from "../../../../../../redux/selectors/useClientCarePlanSelectors";
import * as asyncStates from "../../../../../../redux/constants/asyncStates";
import CircularLoader from "../../../../../../Components/Icons/circularLoader";
import BasicAlerts from "../../../../../../Components/Boxes/ErrorAndSuccess";
import { DOCUMENT_STATUS } from "../../../../../../constants";
import { useGlobalCareplansSelector, useGlobalMedicinesSelector, useGlobalServicesSelector } from "../../../../../../redux/selectors/useConfigsSelectors";

export const NewVersionforCarePlan = (props) => {
  // ----------------- context API's -------------------
  const { clientId } = props
  // ----------------- states --------------------------
  const carePlanToEdit = useMemo(() => props.$carePlanToEdit, []);
  const [careplanName, setCareplanName] = useState(
    carePlanToEdit ? carePlanToEdit?.careplanName : null
  );
  const [urgency, setUrgency] = useState(
    carePlanToEdit ? carePlanToEdit.urgency : "URGENT"
  );
  const [need, setNeed] = useState(carePlanToEdit ? carePlanToEdit?.need : null);
  const [goal, setGoal] = useState(carePlanToEdit ? carePlanToEdit?.goal : null);
  const [interventions, setInterventions] = useState(
    carePlanToEdit ? carePlanToEdit?.interventions : null
  );
  const [services, setServices] = useState(
    carePlanToEdit ? carePlanToEdit?.services : []
  );
  const [medicines, setMedicines] = useState(
    carePlanToEdit ? carePlanToEdit?.medicines : []
  );

  // ----------------- reducers ------------------------

  const {
    createCarePlan,
    getCarePlans,
    deleteCarePlan,
    updateCarePlan,
    clearAllErrorMessages,
    restoreArchivedCarePlan,
  } = useCarePlanDispatchers();
  // ----------------- selectors -----------------------

  const carePlanCreateErrorMessage = useCarePlanCreateErrorMessageSelector();
  const carePlanDeleteErrorMessage = useCarePlanDeleteErrorMessageSelector();
  const carePlanUpdateErrorMessage = useCarePlanUpdateErrorMessageSelector();
  const carePlanRestoreErrorMessage = useCarePlanRestoreErrorMessageSelector()

  const errorMessage = useMemo(() => {
    return (
      carePlanCreateErrorMessage || carePlanDeleteErrorMessage || carePlanUpdateErrorMessage || carePlanRestoreErrorMessage
    );
  }, [carePlanCreateErrorMessage, carePlanDeleteErrorMessage, carePlanUpdateErrorMessage, carePlanRestoreErrorMessage]);


  const carePlanCreateStatus = useCarePlanCreateStatusSelector();
  const carePlanDeleteStatus = useCarePlanDeleteStatusSelector();
  const carePlanUpdateStatus = useCarePlanUpdateStatusSelector();
  const carePlanRestoreStatus = useCarePlanRestoreStatusSelector();

  const globalServiceName = useGlobalServicesSelector()
  const globalCareplanNames = useGlobalCareplansSelector()
  const globalMedicines = useGlobalMedicinesSelector()

  const careplanNames = useMemo(() => {
    return globalCareplanNames?.map(name => {
      return { value: name, label: name }
    })
  }, [globalCareplanNames])

  const medicinesOptions = useMemo(() => {
    return globalMedicines?.map(name => {
      return { value: name.name, label: name.name, medicine: name }
    })
  }, [globalMedicines])
  const serviceOptions = useMemo(() => {
    return globalServiceName?.map(name => {
      return { value: name, label: name }
    })
  }, [globalServiceName]);
  // ----------------- functions -----------------------
  const handleServiceChange = (selected) => {
    const values = selected.map((item) => item.value);
    setServices(values);
  };

  const handleMedicineChange = (selected) => {
    console.log(selected);
    const values = selected.map((item) => item.medicine);
    setMedicines(values);
  };

  // function to convert the service array coming from Db to object for react-select
  const mappedServiceItems = services?.map(item => ({
    value: item,
    label: item
  }));

  const mappedMedicineItems = medicines?.map(item => ({
    value: item.name,
    label: item.name,
    medicine: item,
  }));

  const onCreateCarePlanClick = async () => {
    await createCarePlan({
      careplanName: careplanName,
      urgency: urgency,
      need: need,
      goal: goal,
      interventions: interventions,
      services: services,
      medicines: medicines,
      clientId: clientId,
    }).unwrap();
    getCarePlans({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onUpdateCarePlanClick = async () => {
    await updateCarePlan({
      careplanName: careplanName,
      urgency: urgency,
      need: need,
      goal: goal,
      interventions: interventions,
      services: services,
      medicines: medicines,
      clientId: clientId,
      carePlanId: carePlanToEdit.id,
    }).unwrap();
    getCarePlans({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const onDeleteCarePlanClick = async () => {
    await deleteCarePlan({
      clientId: clientId,
      carePlanId: carePlanToEdit.id,
    }).unwrap();
    getCarePlans({
      clientId: clientId,
    });
    return props.$handleDialog();
  };

  const restoreCarePlanClicked = async () => {
    await restoreArchivedCarePlan({
      clientId: clientId,
      carePlanId: carePlanToEdit.id,
    }).unwrap();
    getCarePlans({
      clientId: clientId,
      isArchived: true,
    });
    return props.$handleDialog();
  }

  // ----------------- useEffect -----------------------
  useEffect(() => {
    clearAllErrorMessages();
  }, []);
  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1}>
          Care Plan name
        </Text>
        <SelectBox
          $hint="Level of Understanding"
          $value={careplanName ? { value: careplanName, label: careplanName } : null}
          $name={"careplanName"}
          $onChange={(e) => setCareplanName(e.value)}
          $trailing={<Icon $icon="ArrowDropDown" />}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $options={careplanNames}
        />

        <br />

        <Text $fontSize="body" $marginBottom={1}>
          Urgency
        </Text>
        <SelectBox
          $name={"urgency"}
          $value={urgency ? {
            value: urgency, label: urgency === "URGENT"
              ? "Urgent"
              : urgency === "NEEDS_ATTENTION"
                ? "Needs Attention"
                : "Not Urgent"
          } : null}
          $onChange={(e) => setUrgency(e.value)}
          $width="35%"
          $trailing={<Icon $icon="ArrowDropDown" />}
          $color={
            urgency === "URGENT"
              ? "red"
              : urgency === "NEEDS_ATTENTION"
                ? "orange"
                : "#26E074"
          }
          $options={[
            { value: "URGENT", label: "Urgent" },
            { value: "NEEDS_ATTENTION", label: "Needs Attention" },
            { value: "NON_URGENT", label: "Not Urgent" },
          ]}
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Need
        </Text>
        <Textarea
          $hint="Type..."
          $type={"text"}
          $name={""}
          $value={need}
          $onChange={(e) => setNeed(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $height="80px"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Goal
        </Text>
        <Textarea
          $hint="Type..."
          $type={"text"}
          $name={""}
          $value={goal}
          $onChange={(e) => setGoal(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $height="80px"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Interventions
        </Text>
        <Textarea
          $hint="Type..."
          $type={"text"}
          $name={""}
          $value={interventions}
          $onChange={(e) => setInterventions(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
          $height="80px"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Services
        </Text>
        <Box $width="100%">
          <SelectBox
            $isMulti={true}
            $options={serviceOptions}
            $onChange={handleServiceChange}
            $value={mappedServiceItems}
          ></SelectBox>
        </Box>
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Medicines
        </Text>
        <Box $width="100%">
          <SelectBox
            $isMulti={true}
            $options={medicinesOptions}
            $onChange={handleMedicineChange}
            $value={mappedMedicineItems}
          ></SelectBox>
        </Box>

        <br />
        {errorMessage && (
          <FlexBox $width="100%" $justifyContent="center" $marginTop={2}>
            <BasicAlerts $type="error">{errorMessage}</BasicAlerts>
          </FlexBox>
        )}
      </FlexBox>

      <FlexBox
        $justifyContent={props.$type === "edit" && carePlanToEdit?.status !== DOCUMENT_STATUS.ARCHIVED ? "space-between" : "flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        {props.$type === "edit" && carePlanToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => onDeleteCarePlanClick()}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {
              carePlanDeleteStatus !== asyncStates.PENDING ? (
                "Delete"
              ) : (
                <CircularLoader $color="white" $size={25} />
              )
            }
          </Text>
        </Button>
        }
        {carePlanToEdit?.status !== DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() =>
            props.$type === "add"
              ? onCreateCarePlanClick()
              : onUpdateCarePlanClick()
          }
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {
              carePlanUpdateStatus !== asyncStates.PENDING &&
                carePlanCreateStatus !== asyncStates.PENDING ? (
                "Save"
              ) : (
                <CircularLoader $color="white" $size={25} />
              )
            }
          </Text>
        </Button>}

        {carePlanToEdit?.status === DOCUMENT_STATUS.ARCHIVED && <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            restoreCarePlanClicked()
          }}
        >
          <Text $color={"white"}>
            {carePlanRestoreStatus !== asyncStates.PENDING ? (
              "Restore archive"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>}

      </FlexBox>
    </>
  );
};
