import { Box, Card, CircleBox, FlexBox } from "../../../Components/Boxes";
import Icon from "../../../Components/Icons";
import { Text } from "../../../Components/Text";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useResponsive } from "../../../Components/Hooks/index";
import { Button, TextBox } from "../../../Components/Input";
import { useContext, useEffect, useMemo, useState } from "react";
import FormDialog from "../../../Components/Modals/FormDialog";
import MuiSkeletonLoader from "../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import { AddClientDialog } from "./Profile/Dialogs/AddClient";
import useClientDispatchers from "../../../redux/dispatchers/useClientDispatchers";
import { AuthContext } from "../../../contextAPI's/authContext";
import {
  useClientsPaginationNextPage,
  useGetAllClientsErrorMsgSelector,
  useGetAllClientsSelector,
  useGetAllClientsStatusSelector,
  useGetAllClientsSuccessMsgSelector,
  useMoreClientsLeftInPaginationSelector,
} from "../../../redux/selectors/useClientSelectors";
import * as asyncStates from "../../../redux/constants/asyncStates";
import StaffAndClientCard from "../StaffAndClientCard";
import Index from "../../../Components/Boxes/Box";
import StaffAndClientHeader from "../StaffAndClientHeader";
import EmptyState from "../../../Components/NoRecordFound";
import ListBox from "../../../Components/Boxes/ListBox";
import { useFilterTeamsSelector } from "../../../redux/selectors/useTeamSelectors";
import usePermissionsController from "../../../permissions";

const Mapping = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  padding-top: 25px;
`;

export default function Clients() {
  // see whether it's desktop size or not
  const isDesktop = useResponsive({ xs: false, sm: true });

  const { addClient } = usePermissionsController();

  //Get all Clients Dispatcher
  const { getAllClients, clearAllErrorMessages, clearAllSuccessMessages, clearAllLocalClients, } =
    useClientDispatchers();

  //Get all Clients Selectors
  const allClients = useGetAllClientsSelector();
  const getAllClientsStatus = useGetAllClientsStatusSelector();
  const moreClientsLeftInPagination = useMoreClientsLeftInPaginationSelector()
  const filteredTeams = useFilterTeamsSelector()

  const nextPage = useClientsPaginationNextPage();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTimer, setSearchQueryTimer] = useState(null);

  const getAllClientStatus = useMemo(() => {
    return getAllClientsStatus;
  }, [getAllClientsStatus]);

  const [open, setOpen] = useState(false);
  function HandleDialog() {
    setOpen(!open);
  }

  const loadMore = () => {
    getAllClients({
      query: searchQuery,
      startAfter: nextPage,
      teams: filteredTeams?.map(e => e.id),
    });
  }

  const handleQuery = (e) => {
    setSearchQuery(e.target.value);
    if (searchQueryTimer) {
      clearTimeout(searchQueryTimer);
    }

    const timeout = setTimeout(() => {
      getAllClients({
        query: e.target.value,
        teams: filteredTeams?.map(e => e.id),
      });
    }, 500);
    setSearchQueryTimer(timeout);
  };

  //fetching client from database
  useEffect(() => {
    clearAllLocalClients({})
    getAllClients({
      query: searchQuery,
      teams: filteredTeams?.map(e => e.id),
    });
  }, [filteredTeams]);

  return (
    <>
      <FlexBox
        $justifyContent="space-between"
        $width="98%"
        $marginTop={2}
        $marginBottom={2}
      >
        {/* Search box */}
        <TextBox
          $width="40%"
          $hint={"Search ..."}
          $type={"text"}
          $name={"search"}
          $value={searchQuery}
          $onChange={handleQuery}
          $borderRadius="8px"
          $border="1px solid black"
          $trailing={
            <Icon $icon="Search" $size="20px" />
          }
        />
        {addClient && <Button
          $onClick={() => {
            HandleDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Add</Text>
        </Button>}
      </FlexBox>

      <Box $width="100%">
        <StaffAndClientHeader $type="client" />
        <Box $width="100%" $marginTop="2">
          {allClients?.map((value, index) => {
            return (
              <Link
                key={Index}
                to={`/team/clients/${value.id}`}
                style={{
                  color: "inherit",
                  textDecoration: "inherit",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <StaffAndClientCard $value={value} $index={index} $type="client" />
              </Link>
            );
          })}
        </Box>

        {getAllClientStatus === asyncStates.PENDING && <FlexBox $width="100%" $marginTop={2} $flexDirection="column">
          {[1, 2, 3].map((val, index) => {
            return (
              <Box
                $marginBottom="2"
                $width="100%"
              >
                <MuiSkeletonLoader
                  $variant="rectangular"
                  $width={"100%"}
                  $height={"40px"}
                />
              </Box>
            );
          })}
        </FlexBox>}
      </Box>

      {getAllClientStatus === asyncStates.SUCCESS && allClients.length < 1 && (
        <FlexBox $marginTop={7} $justifyContent="center">
          <EmptyState />
        </FlexBox>
      )}

      {moreClientsLeftInPagination && <FlexBox $width="100%" $justifyContent="center" $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Add Client"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <AddClientDialog $handleDialog={HandleDialog} $type="add" />
      </FormDialog>
    </>
  );
}
