import { useDispatch } from "react-redux";
import {
    clearAllErrorMessagesReducer,
    clearAllLocalBusinessAccountsReducer,
    clearAllSuccessMessagesReducer,
    clearSelectedBusinessAccountReducer,
    getBusinessAccountReducer,
    getBusinessAccountsReducer,
    selectBusinessAccountReducer,
    editBusinessAccountReducer,
} from "../../slices/businessAccountSlice";

const useBusinessAccountDispatchers = () => {
    const dispatch = useDispatch();

    const getBusinessAccounts = (data) => {
        return dispatch(getBusinessAccountsReducer(data));
    }

    const getBusinessAccount = (data) => {
        return dispatch(getBusinessAccountReducer(data));
    }

    const editBusinessAccount = (data) => {
        return dispatch(editBusinessAccountReducer(data));
    }

    const selectBusinessAccount = (data) => {
        return dispatch(selectBusinessAccountReducer(data))
    }

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data));
    };

    const clearAllSuccessMessages = (data) => {
        return dispatch(clearAllSuccessMessagesReducer(data));
    };

    const clearAllLocalBusinessAccounts = (data) => {
        return dispatch(clearAllLocalBusinessAccountsReducer(data));
    };

    const clearSelectedBusinessAccount = (data) => {
        return dispatch(clearSelectedBusinessAccountReducer(data));
    };

    return {
        getBusinessAccounts,
        getBusinessAccount,
        editBusinessAccount,
        selectBusinessAccount,
        clearAllErrorMessages,
        clearAllSuccessMessages,
        clearAllLocalBusinessAccounts,
        clearSelectedBusinessAccount,
    };
};

export default useBusinessAccountDispatchers;
