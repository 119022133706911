import ApiService from "../../restapi"

//Getting events analytics
export const getEventAnalyticsApiService = async ({
    staffId,
    clientId,
    // monthBeginningDate,
    // startDate,
    // endDate,
    selectedDate,
    repetitionPeriods,
}) => {
    return await ApiService.sendRequest({
        url: `/analytics/events`,
        query: {
            staffId,
            clientId,
            // monthBeginningDate,
            // startDate,
            // endDate,
            selectedDate,
            repetitionPeriods,
        },
        method: "GET",
        headers: {},
    })
}

//Getting events analytics
export const getUserAnalyticsApiService = async ({
    // monthBeginningDate,
    // startDate,
    // endDate,
    selectedDate,
    repetitionPeriods,
}) => {
    return await ApiService.sendRequest({
        url: `/analytics/users`,
        query: {
            // monthBeginningDate,
            // startDate,
            // endDate,
            selectedDate,
            repetitionPeriods,
        },
        method: "GET",
        headers: {},
    })
}