import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllEMARsApiService,
} from "../../../services/applicationBackendApis/eMAR";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allEMARs: [],
  moreEMARsLeftInPagination: false,
  errorMessages: {
    getAllEMARsErrorMessage: null,
  },
  status: {
    getAllEMARsStatus: null,
  },
  successMessages: {
    getAllEMARsSuccessMessage: null,
  },
};

//-----------------Get All EMARs---------------//
export const getAllEMARsReducer = createAsyncThunk(
  "eMAR/getAllEMAR",
  async (data) => {
    try {
      const response = await getAllEMARsApiService({
        clientId: data.clientId,
        monthBeginningDate: data.monthBeginningDate,
        medicineId: data.medicineId,
        limit: data.limit ?? PAGINATION_LIMITS.EMAR_LIST,
        startAfter: data.startAfter,
      });
      return {
        data: response.data,
        limit: data.limit ?? PAGINATION_LIMITS.EMAR_LIST,
        startAfter: data.startAfter,
      };
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//-----------------Slices-------------//
const eMARSlice = createSlice({
  name: "eMAR",
  initialState,
  //Reducers that do not require Api calls
  reducers: {
    clearAllStatusReducer: (state) => {
      state.status = {};
    },
    clearAllErrorMessagesReducer: (state) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state) => {
      state.successMessages = {};
    },
  },
  //Reducers that require Api Calls
  extraReducers: (builder) => {
    //Get All EMARs Async Calls
    builder.addCase(getAllEMARsReducer.rejected, (state, action) => {
      state.status.getAllEMARsStatus = asyncStates.FAILURE;
      state.errorMessages.getAllEMARsErrorMessage = action.error?.message;
    });
    builder.addCase(getAllEMARsReducer.pending, (state) => {
      state.status.getAllEMARsStatus = asyncStates.PENDING;
      state.errorMessages.createEMARErrorMessage = null;
    });
    builder.addCase(getAllEMARsReducer.fulfilled, (state, action) => {
      state.status.getAllEMARsStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllEMARsErrorMessage = null;
      state.allEMARs = action.payload.startAfter ? [...state.allEMARs, ...action.payload.data.eMARs] : action.payload.data.eMARs;
      console.log("state.allEMARs", action.payload);
      if (action.payload.data.eMARs.length >= action.payload.limit) {
        state.moreEMARsLeftInPagination = true;
      } else {
        state.moreEMARsLeftInPagination = false;
      }
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusReducer,
  clearAllSuccessMessagesReducer,
} = eMARSlice.actions;
export default eMARSlice.reducer;
