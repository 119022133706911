import styled from "styled-components"
import { color } from "../Theme"
import { Text } from "../Text"

const Table = styled.table`
    width: 100%;
    border: 1px solid #E0E0E0;
    padding: 5px;
    border-collapse: collapse;
    vertical-align: top;
`

const TableHeader = styled.th`
    text-align: left;
    border: 1px solid #E0E0E0;
    padding: 5px;
    background-color: ${color("list")};
    vertical-align: top;
`

const TableRow = styled.tr`
    border: 1px solid #E0E0E0;
    padding: 5px;
    vertical-align: top;
`

const TableCell = styled.td`
    border: 1px solid #E0E0E0;
    padding: 5px;
    vertical-align: top;
`

export function CustomTable({ $headers, $rows, $onRowClick, $minRowHeight, children }) {

    return (
        <Table>
            <TableRow>
                {$headers?.map((header) => {
                    return (
                        <TableHeader>
                            <Text $fontWeight="bold">
                                {header}
                            </Text>
                        </TableHeader>
                    )
                })}
            </TableRow>
            {$rows?.map((row) => {
                return <TableRow onClick={$onRowClick}>
                    {row.map((cell) => {
                        return (
                            <TableCell style={{ minHeight: $minRowHeight, width: `${100/row.length}%` }}>
                                {["string", "number", "boolean"].includes(typeof cell) ? <Text $wordWrap="anywhere">{cell}</Text> : cell}
                            </TableCell>
                        )
                    })}
                </TableRow>
            })}
        </Table>
    )
}