import MUIGrid from "@mui/material/Grid";

export default function GridContainer({
  $spacing,
  $columnSpacing,
  $rowSpacing,
  children,
  $style,
  ...rest
}) {
  return (
    <MUIGrid
      container
      spacing={$spacing}
      columnSpacing={$columnSpacing}
      rowSpacing={$rowSpacing}
      direction={rest.$direction}
      style={$style}
    >
      {children}
    </MUIGrid>
  );
}
