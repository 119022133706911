import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  getAllComplianceReducer,
  createComplianceReducer,
  deleteComplianceReducer,
  updateComplianceReducer,
  clearAllLocalCompliancesReducer,
  restoreArchivedComplianceReducer,
} from "../../slices/staffComplianceSlices";

const useComplianceDispathers = () => {
  const dispatch = useDispatch();

  const createCompliance = (data) => {
    return dispatch(createComplianceReducer(data));
  };

  const restoreArchivedCompliance = (data) => {
    return dispatch(restoreArchivedComplianceReducer(data))
  }

  const getAllCompliance = (data) => {
    return dispatch(getAllComplianceReducer(data));
  };

  const deleteCompliance = (data) => {
    return dispatch(deleteComplianceReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const updateCompliance = (data) => {
    console.log(data);
    return dispatch(updateComplianceReducer(data));
  };

  const clearAllLocalCompliance = (data) => {
    return dispatch(clearAllLocalCompliancesReducer(data));
  };

  return {
    restoreArchivedCompliance,
    getAllCompliance,
    createCompliance,
    deleteCompliance,
    clearAllErrorMessages,
    updateCompliance,
    clearAllLocalCompliance,
  };
};

export default useComplianceDispathers;
