import ApiService from "../../restapi";

export const getAllQualificationApiService = async ({
    staffId,
    startAfter,
    limit,
    isArchived,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/qualifications`,
        query: {
            startAfter: startAfter,
            limit: limit,
            isArchived: isArchived,
        },
        method: "GET",
        headers: {},
    });
}

export const createQualificationApiService = async ({
    staffId,
    name,
    grade,
    date,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/qualifications`,
        query: {},
        method: "POST",
        headers: {},
        body: {
            name,
            grade,
            date,
        },
    });
}

export const deleteQualificationApiService = async ({
    staffId,
    qualificationId,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/qualifications/${qualificationId}`,
        query: {},
        method: "DELETE",
        headers: {},
    });
}

export const updateQualificationApiService = async ({
    staffId,
    qualificationId,
    name,
    grade,
    date,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/qualifications/${qualificationId}`,
        query: {},
        method: "PUT",
        headers: {},
        body: {
            name,
            grade,
            date,
        },
    });
}

export const restoreArchivedQualificationApiService = async ({
    staffId,
    qualificationId,
}) => {
    return await ApiService.sendRequest({
        url: `/staff/${staffId}/qualifications/${qualificationId}/restore`,
        query: {},
        method: "PUT",
        headers: {},
    });
}