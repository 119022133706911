import { useDispatch } from "react-redux";
import {
    clearAllErrorMessagesReducer,
    clearAllStatusReducer,
    clearAllSuccessMessagesReducer,
    getEventAnalyticsReducer,
    getUserAnalyticsReducer,
} from "../../slices/analyticsSlice";

const useAnalyticsDispatchers = () => {
    const dispatch = useDispatch();

    const getEventAnalytics = (data) => {
        return dispatch(getEventAnalyticsReducer(data));
    };

    const getUserAnalytics = (data) => {
        return dispatch(getUserAnalyticsReducer(data));
    };

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data));
    };

    const clearAllSuccessMessages = (data) => {
        return dispatch(clearAllSuccessMessagesReducer(data));
    };

    const clearAllStatus = (data) => {
        return dispatch(clearAllStatusReducer(data));
    };

    return {
        getEventAnalytics,
        getUserAnalytics,
        clearAllErrorMessages,
        clearAllSuccessMessages,
        clearAllStatus,
    };
};

export default useAnalyticsDispatchers;
