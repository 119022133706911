import { useState, useEffect, useMemo, useCallback } from "react";
import { Box, FlexBox } from "../../../../Components/Boxes";
import Diary from "./components/Diary";
import Compliance from "./components/Compliance";
import { useParams, useSearchParams } from "react-router-dom";
import { useResponsive } from "../../../../Components/Hooks/index";
import { ProfileCard } from "../../../../Components/BasicComponents/ProfileCard";
import useStaffDispathers from "../../../../redux/dispatchers/useStaffDispatchers";
import { useSingleStaffSelector, useSingleStaffStatusSelector, useStaffUpdateStatusSelector } from "../../../../redux/selectors/useStaffSelectors";
import Calender from "../../Events/Calender";
import { BreadCrumbs } from "../../../../Components/BasicComponents/BreadCrumbs";
import Tabs from "../../../../Components/Input/Tabs";
import { EVENT_TYPE } from "../../../../constants";
import * as asyncStates from "../../../../redux/constants/asyncStates"
import TimesheetView from "../../Timesheets/TimesheetView";
import usePermissionsController from "../../../../permissions";
import Policy from "../../Policy/ViewPolicyList";
import StaffProfileBuild from "./components/StaffProfile";
import BlockListView from "../../BlockList/BlockListView";
import TimeOff from "../../TimeOff";
import UserAnalytics from "../../../Analytics/UserAnalytics";
import { useLoggedInStaffId } from "../../../../hooks/useLoggedInStaff";

export default function StaffProfile() {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const { getSingleStaff, updateStaff } = useStaffDispathers();
  const getSingleStaffIDSuccessMsg = useSingleStaffSelector();
  const getStaffStatus = useSingleStaffStatusSelector();
  const updateStaffStatus = useStaffUpdateStatusSelector()
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const [tabIndex, setTabIndex] = useState(searchParams.get("event_id") ? 1 : 0)

  const { staffId } = useLoggedInStaffId()

  const {
    accessStaffTimesheets,
    accessStaffPolicy,
    accessStaffBlockList,
    accessTimeOffPage,
    accessAnalyticsPage,
    editStaff,
    editOwnStaff,

    listStaffCompliance,
    addStaffCompliance,
    editStaffCompliance,

    listStaffPolicy,
    addStaffPolicy,
    editStaffPolicy,
  } = usePermissionsController()

  const canEditStaff = useCallback(() => {
    if (editStaff) return true
    if (editOwnStaff && staffId == params.staffid) return true
    return false
  }, [editStaff, editOwnStaff, staffId, params.staffid])

  const tabOptions = useMemo(() => {
    const opts = [
      { name: "Diary" },
      { name: "Calender" },
      { name: "Availability" },
      { name: "Absence" },
    ]

    if (listStaffCompliance)
      opts.push({ name: "Compliance" })

    if (accessStaffTimesheets)
      opts.push({ name: "Timesheets" })
    if (accessStaffPolicy)
      opts.push({ name: "Policy" })
    if (accessStaffBlockList)
      opts.push({ name: "Block List" })
    if (accessTimeOffPage)
      opts.push({ name: "Time Off" })

    opts.push({ name: "Staff Profile" })

    if (accessAnalyticsPage)
      opts.push({ name: "Analytics" })

    return opts
  }, [])

  const select = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  //fetching staff from database
  useEffect(() => {
    getSingleStaff({
      staffId: params.staffid,
    });
  }, []);

  const updateImage = async ($file, $fileUrl) => {
    await updateStaff({
      id: params.staffid,
      profilePhotoFile: $file,
    }).unwrap()
    getSingleStaff({
      staffId: params.staffid,
    });
  }

  return (
    <FlexBox
      $minHeight="100%"
      $flexDirection="column"
      $alignItems="flex-start"
      $backgroundColor="#fff"
      $borderRadius="10px"
    >

      <BreadCrumbs
        $crumbs={[{
          label: "Teams",
          path: "/team"
        }, {
          label: "Staff",
          path: "/team/staff"
        }, {
          label: getSingleStaffIDSuccessMsg?.name,
          path: ""
        }]}
      />


      <FlexBox
        $minHeight="100%"
        $flexDirection={{ xs: "column", sm: "row" }}
        $alignItems={isDesktop ? "flex-start" : "center"}
        // $border="1px solid blue"
        $paddingLeft={{ xs: 0, sm: 2 }}
        $maxWidth="100%"
        $width="100%"
      >
        <ProfileCard
          data={getSingleStaffIDSuccessMsg}
          type="StaffData"
          $onImageChanged={canEditStaff() ? ($file, $fileLocalUrl) => updateImage($file, $fileLocalUrl) : null}
          $imageLoading={getStaffStatus == asyncStates.PENDING || updateStaffStatus == asyncStates.PENDING}
        />

        <FlexBox
          $width={isDesktop ? "67%" : "95%"}
          $minHeight="100%"
          $backgroundColor="#fff"
          $borderRadius="10px"
          $flexDirection="column"
          $marginTop={{ xs: 2, sm: 0 }}
        >
          <Tabs
            $onChanged={(val) => {
              setTabIndex(val)
            }}
            $value={tabIndex}
            $tabs={tabOptions}
          />

          <Box $marginTop={2} />

          {select === "Diary" ? <Diary staffId={params.staffid} /> : <></>}
          {select === "Calender" ? <Calender staffId={params.staffid} /> : <></>}
          {select === "Availability" ? <Calender staffId={params.staffid} eventType={EVENT_TYPE.AVAILABILITY} /> : <></>}
          {select === "Absence" ? <Calender staffId={params.staffid} eventType={EVENT_TYPE.ABSENCE} /> : <></>}
          {select == "Timesheets" && accessStaffTimesheets && <TimesheetView staffId={params.staffid} />}
          {select === "Compliance" ? <Compliance staffId={params.staffid} canAdd={addStaffCompliance} canEdit={editStaffCompliance} /> : <></>}
          {select === "Policy" && accessStaffPolicy && <Policy staffId={params.staffid} canAdd={addStaffPolicy} canEdit={editStaffPolicy} />}
          {select == "Staff Profile" && <StaffProfileBuild staffId={params.staffid} />}
          {select == "Block List" && accessStaffBlockList && <BlockListView staffId={params.staffid} />}
          {select == "Time Off" && accessTimeOffPage && <TimeOff staffId={params.staffid} />}
          {select == "Analytics" && accessAnalyticsPage && <UserAnalytics staffId={params.staffid} />}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}
