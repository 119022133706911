import { useSelector } from "react-redux"

export const useStaffInviteSelector = () => useSelector((state) => {
    return state.staff.invitelink;
})

export const useAllStaffSelector = () => useSelector((state) => {
    return state.staff.allStaff;
})

export const useNewStaffForProfileSelector = () => useSelector((state) => {
    return state.staff.singleStaffForProfile;
})

export const useSingleStaffSelector = () => useSelector((state) => {
    return state.staff.singleStaff;
})

export const useStaffProfileSelector = () => useSelector((state) => {
    return state.staff.staffProfile;
})

export const useStaffPaginationNextPage = () => useSelector((state) => {
    return state.staff.allStaffPagination.nextPage;
})

// ------------------- selector error messages ------------------ //
export const useStaffCreateErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.createStaffErrorMessage;
})

export const useGetStaffInviteErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.getStaffInviteErrorMessage;
})

export const useGetNewStaffErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.getNewStaffErrorMessage;
})

export const useGetSingleStaffErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.getSingleStaffErrorMessage;
})

export const useUpdateStaffErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.updateStaffErrorMessage
})

export const useStaffDeleteErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.deleteStaffErrorMessage;
});

export const useGetStaffProfileErrorMessageSelector = () => useSelector((state) => {
    return state.staff.errorMessages.getStaffProfileErrorMessage;
})

// ------------ Create Staff Status selectors ------------ //
export const useStaffCreateStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.createStaffStatus;
})

export const useNewStaffStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.getNewStaffStatus;
})

export const useSingleStaffStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.getSingleStaffStatus;
})
export const useStaffUpdateStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.updateStaffStatus
})

export const useStaffDeleteStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.deleteStaffStatus;
});

export const useGetStaffProfileStatusSelector = () => useSelector((state) => {
    return state.staff.statuses.getStaffProfileStatus;
})

// ------------ Invite Staff Success selectors ------------ //

export const useStaffInviteDataSelector = () => useSelector((state) => {
    return state.staff.inviteData;
})

export const useNewStaffInviteDataSelector = () => useSelector((state) => {
    return state.staff.inviteData;
})

export const useSingleStaffInviteDataSelector = () => useSelector((state) => {
    return state.staff.inviteData;
})

// ------------ success selectors ------------ //
export const useStaffCreateSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.createStaffSuccessMessage
})

export const useNewStaffSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.getNewStaffSuccessMessage;
})

export const useSingleStaffSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.getSingleStaffSuccessMessage;
})

export const useUpdateStaffSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.getUpdateStaffSuccessMessage;
})
export const useDeleteStaffSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.deleteStaffSuccessMessage;
});

export const useMoreStaffLeftInPaginationSelector = () => useSelector((state) => {
    return state.staff.moreStaffLeftInPagination
})

export const useGetStaffProfileSuccessSelector = () => useSelector((state) => {
    return state.staff.successMessages.getStaffProfileSuccessMessage;
})