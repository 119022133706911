import React, { useState } from 'react'
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes'
import { Button, TextBox } from '../../../Components/Input'
import { Text } from '../../../Components/Text'
import { SearchUserSelectBox } from '../../components/searchSelectBox';
import CircularLoader from '../../../Components/Icons/circularLoader'

export default function AddConfigNamesDialog(props) {
    const {
        isStaffList,
        name: editingName,
        loading,
        errorMessage,
        onDelete,
        onSave,
        onUpdate,
    } = props
    const [name, setName] = useState(editingName)
    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1}>
                    Name
                </Text>

                {!isStaffList && <TextBox
                    $hint={"1234..."}
                    $type={"text"}
                    $name={"text"}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />}

                {isStaffList && <SearchUserSelectBox
                    searchByStaff
                    selected={name}
                    onChange={(user) => {
                        setName(user)
                    }}
                />}
            </FlexBox>

            <Box
                $padding={2}
            >
                {errorMessage && (
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                )}
            </Box>

            <FlexBox
                $justifyContent={!editingName ? "flex-end" : "space-between"}
                $padding={2}
                $backgroundColor="list"
            >
                {editingName && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onDelete()
                    }}
                >
                    {!loading ? 'Delete' : <CircularLoader $color="white" $size={25} />}
                </Button>}
                <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        if (editingName) {
                            await onUpdate(name)
                        } else {
                            await onSave(name)
                        }
                    }}
                >
                    {!loading ? 'Save' : <CircularLoader $color="white" $size={25} />}
                </Button>
            </FlexBox>
        </>
    )
}
