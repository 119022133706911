import { useDispatch } from "react-redux";
import {
  createStaffReducer,
  getStaffInviteReducer,
  getNewStaffReducer,
  getStaffReducer,
  updateStaffReducer,
  deleteStaffReducer,
  clearAllStatusesReducer,
  clearAllErrorMessagesReducer,
  clearAllSuccessMessagesReducer,
  clearAllLocalStaffReducer,
  getNewStaffForProfileReducer,
  getStaffProfileReducer,
  clearLocalStaffProfileReducer,
} from "../../slices/staffSlice";

const useStaffDispathers = () => {
  const dispatch = useDispatch();

  const createStaff = (data) => {
    return dispatch(createStaffReducer(data));
  };

  const getStaffInvite = (inviteLink) => {
    return dispatch(getStaffInviteReducer(inviteLink));
  };

  const getNewStaff = (newStaffData) => {
    return dispatch(getNewStaffReducer(newStaffData));
  }

  const getNewStaffForProfile = (newStaffData) => {
    return dispatch(getNewStaffForProfileReducer(newStaffData));
  }

  const getSingleStaff = (data) => {
    return dispatch(getStaffReducer(data));
  }

  const getStaffProfile = (data) => {
    return dispatch(getStaffProfileReducer(data));
  }

  const updateStaff = (data) => {
    return dispatch(updateStaffReducer(data));
  }
  const deleteStaff = (data) => {
    return dispatch(deleteStaffReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };
  const clearAllStatusMessages = (data) => {
    return dispatch(clearAllStatusesReducer(data));
  }
  const clearAllLocalStaff = (data) => {
    return dispatch(clearAllLocalStaffReducer(data));
  };

  const clearLocalStaffProfile = (data) => {
    return dispatch(clearLocalStaffProfileReducer(data));
  };

  return {
    createStaff,
    getStaffInvite,
    getNewStaff,
    getNewStaffForProfile,
    getSingleStaff,
    getStaffProfile,
    updateStaff,
    deleteStaff,
    clearAllStatusMessages,
    clearAllLocalStaff,
    clearAllErrorMessages,
    clearAllSuccessMessages,
    clearLocalStaffProfile,
  };
};

export default useStaffDispathers;
