import ApiService from "../../restapi";

//Get single business account
export const getBusinessSubscriptionDetailsApiService = async ({
    id,
}) => {
    return await ApiService.sendRequest({
        url: `/businessAccounts/${id}/subscriptionDetails`,
        query: {},
        method: "GET",
        headers: {},
    })
}

export const getBusinessSubscriptionLoginPortalApiService = async ({
    id,
}) => {
    return await ApiService.sendRequest({
        url: `/businessAccounts/${id}/subscriptionLoginPortal`,
        query: {},
        method: "GET",
        headers: {},
    })
}