import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    createAdditionalFormApiService,
    deleteAdditionalFormApiService,
    getAllAdditionalFormApiService,
    restoreArchivedAdditionalFormApiService,
    updateAdditionalFormApiService,
} from "../../../services/applicationBackendApis/additionalForms";
import * as asyncStates from "../../constants/asyncStates";
import { ADDITIONAL_FORM_FIELD_TYPE, PAGINATION_LIMITS } from "../../../constants";

const initialState = {
    additionalForms: [],
    additionalForm: null,
    moreAdditionalFormItemsLeftInPagination: false,
    errorMessages: {
        restoreAdditionalFormErrorMessage: null,
        createAdditionalFormErrorMessage: null,
        deleteAdditionalFormErrorMessage: null,
        updateAdditionalFormErrorMessage: null,
        getAdditionalFormErrorMessage: null,
    },
    statuses: {
        restoreAdditionalFormStatus: null,
        createAdditionalFormStatus: null,
        deleteAdditionalFormStatus: null,
        updateAdditionalFormStatus: null,
        getAdditionalFormsStatus: null,
    },
    sucessMessages: {
        restoreAdditionalFormSuccessMessage: null,
        updateAdditionalFormSuccessMessage: null,
    },
};

//------- getting all additionalForm from database ------------\\
export const getAllAdditionalFormReducer = createAsyncThunk(
    "additionalForm/getAllAdditionalForm",
    async (additionalFormData) => {
        try {
            const response = await getAllAdditionalFormApiService({
                clientId: additionalFormData.clientId,
                startAfter: additionalFormData.startAfter,
                limit: additionalFormData.limit ?? PAGINATION_LIMITS.ADDITIONAL_FORM_LIST,
                isArchived: additionalFormData.isArchived,
            });
            return {
                data: response.data,
                limit: additionalFormData.limit ?? PAGINATION_LIMITS.ADDITIONAL_FORM_LIST,
                startAfter: additionalFormData.startAfter,
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

//------------- creating new risk Assessment ---------------\\
export const createAdditionalFormReducer = createAsyncThunk(
    "additionalForm/createAdditionalFormReducer",
    async (additionalFormData) => {
        try {
            const files = [];
            const processedData = additionalFormData.submittedData?.map(data => {
                let value = data.value
                if (value && (data.type == ADDITIONAL_FORM_FIELD_TYPE.IMAGE || data.type == ADDITIONAL_FORM_FIELD_TYPE.BODYMAP) && typeof value != "string") {
                    files.push(data.value)
                    value = files.length - 1
                }
                return {
                    name: data.name,
                    value: value,
                    type: data.type,
                    options: data.options,
                }
            })
            const response = await createAdditionalFormApiService({
                clientId: additionalFormData.clientId,
                title: additionalFormData.title,
                submittedData: processedData,
                notes: additionalFormData.notes,
                name: additionalFormData.name,
                instructions: additionalFormData.instructions,
                files
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const restoreArchivedAdditionalFormReducer = createAsyncThunk(
    "additionalForm/restoreArchivedAdditionalForm",
    async (additionalFormData) => {
        try {
            const response = await restoreArchivedAdditionalFormApiService({
                clientId: additionalFormData.clientId,
                id: additionalFormData.additionalFormId,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const updateAdditionalFormReducer = createAsyncThunk(
    "additionalForm/updateAdditionalFormReducer",
    async (additionalFormData) => {
        try {
            const files = [];
            const processedData = additionalFormData.submittedData?.map(data => {
                let value = data.value
                if (value && (data.type == ADDITIONAL_FORM_FIELD_TYPE.IMAGE || data.type == ADDITIONAL_FORM_FIELD_TYPE.BODYMAP) && typeof value != "string") {
                    files.push(data.value)
                    value = files.length - 1
                }
                return {
                    name: data.name,
                    value: value,
                    type: data.type,
                    options: data.options,
                }
            })
            const response = await updateAdditionalFormApiService({
                additionalFormId: additionalFormData.additionalFormId,
                clientId: additionalFormData.clientId,
                title: additionalFormData.title,
                submittedData: processedData,
                notes: additionalFormData.notes,
                name: additionalFormData.name,
                instructions: additionalFormData.instructions,
                files
            });
            console.log(response.data);
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const deleteAdditionalFormReducer = createAsyncThunk(
    "additionalForm/deleteAdditionalFormReducer",
    async (additionalFormData) => {
        try {
            const response = await deleteAdditionalFormApiService({
                clientId: additionalFormData.clientId,
                additionalFormId: additionalFormData.additionalFormId,
            });
            console.log(response.data)
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

const additionalFormSlice = createSlice({
    name: "additionalFormSlice",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {};
        },
        clearAllLocalAdditionalFormsReducer: (state, action) => {
            state.additionalForms = [];
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {};
        },
        clearAllSuccessMessagesReducer: (state, action) => {
            state.sucessMessages = {};
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {
        // start handling create file async calls
        builder.addCase(createAdditionalFormReducer.pending, (state, action) => {
            state.statuses.createAdditionalFormStatus = asyncStates.PENDING;
            state.errorMessages.createAdditionalFormErrorMessage = null;
        });
        builder.addCase(createAdditionalFormReducer.rejected, (state, action) => {
            state.statuses.createAdditionalFormStatus = asyncStates.FAILURE;
            state.errorMessages.createAdditionalFormErrorMessage = action.error?.message;
        });
        builder.addCase(createAdditionalFormReducer.fulfilled, (state, action) => {
            state.statuses.createAdditionalFormStatus = asyncStates.SUCCESS;
            state.errorMessages.createAdditionalFormErrorMessage = null;
        });

        builder.addCase(restoreArchivedAdditionalFormReducer.pending, (state, action) => {
            state.statuses.restoreAdditionalFormStatus = asyncStates.PENDING;
            state.errorMessages.restoreAdditionalFormErrorMessage = null;
        });
        builder.addCase(restoreArchivedAdditionalFormReducer.rejected, (state, action) => {
            state.statuses.restoreAdditionalFormStatus = asyncStates.FAILURE;
            state.errorMessages.restoreAdditionalFormErrorMessage =
                action.error?.message;
        });
        builder.addCase(restoreArchivedAdditionalFormReducer.fulfilled, (state, action) => {
            state.statuses.restoreAdditionalFormStatus = asyncStates.SUCCESS;
            state.errorMessages.restoreAdditionalFormErrorMessage = null;
        });

        // start handling update file async calls
        builder.addCase(updateAdditionalFormReducer.pending, (state, action) => {
            state.statuses.updateAdditionalFormStatus = asyncStates.PENDING;
            state.errorMessages.updateAdditionalFormErrorMessage = null;
        });
        builder.addCase(updateAdditionalFormReducer.rejected, (state, action) => {
            state.statuses.updateAdditionalFormStatus = asyncStates.FAILURE;
            state.errorMessages.updateAdditionalFormErrorMessage = action.error?.message;
        });
        builder.addCase(updateAdditionalFormReducer.fulfilled, (state, action) => {
            state.statuses.updateAdditionalFormStatus = asyncStates.SUCCESS;
            state.errorMessages.updateAdditionalFormErrorMessage = null;
            state.sucessMessages.updateAdditionalFormSuccessMessage =
                action.payload.message;
        });

        // start handling get files async calls
        builder.addCase(getAllAdditionalFormReducer.pending, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.PENDING;
            state.errorMessages.getAdditionalFormsErrorMessage = null;
        });
        builder.addCase(getAllAdditionalFormReducer.rejected, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.FAILURE;
            state.errorMessages.getAdditionalFormsErrorMessage = action.error?.message;
        });
        builder.addCase(getAllAdditionalFormReducer.fulfilled, (state, action) => {
            state.statuses.getAdditionalFormsStatus = asyncStates.SUCCESS;
            state.errorMessages.getAdditionalFormsErrorMessage = null;
            state.additionalForms = action.payload.startAfter ? [...state.additionalForms, ...action.payload.data.additionalForms] : action.payload.data.additionalForms;

            if (action.payload.data.additionalForms.length >= action.payload.limit) {
                state.moreAdditionalFormItemsLeftInPagination = true;
            } else {
                state.moreAdditionalFormItemsLeftInPagination = false;
            }
        });

        // start handling delete file async calls
        builder.addCase(deleteAdditionalFormReducer.pending, (state, action) => {
            state.statuses.deleteAdditionalFormStatus = asyncStates.PENDING;
            state.errorMessages.deleteAdditionalFormErrorMessage = null;
        });
        builder.addCase(deleteAdditionalFormReducer.rejected, (state, action) => {
            state.statuses.deleteAdditionalFormStatus = asyncStates.FAILURE;
            state.errorMessages.deleteAdditionalFormErrorMessage = action.error?.message;
        });
        builder.addCase(deleteAdditionalFormReducer.fulfilled, (state, action) => {
            state.statuses.deleteAdditionalFormStatus = asyncStates.SUCCESS;
            state.errorMessages.deleteAdditionalFormErrorMessage = null;
        });
    },
});

export const {
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
    clearAllLocalAdditionalFormsReducer,
    clearAllSuccessMessagesReducer,
} = additionalFormSlice.actions;

export default additionalFormSlice.reducer;
