import React from 'react'
import AddConfigsNames from '../AddConfigsNames'
import { useCareplansSettingsController } from '../SettingsControllers/useCareplansController'

export default function CareplansSettings() {
  const controller = useCareplansSettingsController()
  return (
    <AddConfigsNames {...controller} />
  )
}
