import { spacing, color } from "../Theme/index";
import useResponsive from "../Hooks/Responsive";

export function DefaultSpacingModifiers({
  $margin,
  $padding,
  $marginLeft,
  $marginRight,
  $marginTop,
  $marginBottom,
  $paddingLeft,
  $paddingRight,
  $paddingTop,
  $paddingBottom,
}) {
  const styles = {};
  const passedMargin = useResponsive($margin);
  const passedPadding = useResponsive($padding);

  const passedMarginLeft = useResponsive($marginLeft);
  const passedMarginRight = useResponsive($marginRight);
  const passedMarginTop = useResponsive($marginTop);
  const passedMarginBottom = useResponsive($marginBottom);

  const passedPaddingLeft = useResponsive($paddingLeft);
  const passedPaddingRight = useResponsive($paddingRight);
  const passedPaddingTop = useResponsive($paddingTop);
  const passedPaddingBottom = useResponsive($paddingBottom);

  /* Margin start */
  if (passedMargin) {
    styles.margin = spacing(passedMargin);
  }

  if (passedMarginLeft) {
    styles.marginLeft = spacing(passedMarginLeft);
  }

  if (passedMarginRight) {
    styles.marginRight = spacing(passedMarginRight);
  }
  if (passedMarginTop) {
    styles.marginTop = spacing(passedMarginTop);
  }
  if (passedMarginBottom) {
    styles.marginBottom = spacing(passedMarginBottom);
  }

  /* Padding start */
  if (passedPadding) {
    styles.padding = spacing(passedPadding);
  }

  if (passedPaddingLeft) {
    styles.paddingLeft = spacing(passedPaddingLeft);
  }

  if (passedPaddingRight) {
    styles.paddingRight = spacing(passedPaddingRight);
  }
  if (passedPaddingTop) {
    styles.paddingTop = spacing(passedPaddingTop);
  }
  if (passedPaddingBottom) {
    styles.paddingBottom = spacing(passedPaddingBottom);
  }

  return styles;
}

export function DefaultTextModifiers({ $fontSize, $fontWeight, $fontStyle, $textOverflow }) {
  const passedValue = useResponsive($fontSize);
  const passedFontWeight = useResponsive($fontWeight);
  const passedFontStyle = useResponsive($fontStyle);
  const passedTextOverflow = useResponsive($textOverflow);

  let fontSize = 12;
  let fontWeight = passedFontWeight;
  if (passedValue === "heading-1") {
    fontSize = 44;
    fontWeight = passedFontWeight ?? "bold";
  } else if (passedValue === "heading-2") {
    fontSize = 24;
    fontWeight = passedFontWeight ?? "bold";
  } else if (passedValue === "heading-3") {
    fontSize = 20;
    fontWeight = passedFontWeight ?? "bold";
  } else if (passedValue === "heading-4") {
    fontSize = 18;
    fontWeight = passedFontWeight ?? "bold";
  } else if (passedValue === "heading-5") {
    fontSize = 16;
    fontWeight = passedFontWeight ?? "bolder";
  } else if (passedValue === "body-lg") {
    fontSize = 18;
  } else if (passedValue === "body") {
    fontSize = 16;
  } else if (passedValue === "body-sm") {
    fontSize = 12;
  } else if (passedValue === "body-xs") {
    fontSize = 8;
  }

  return {
    fontSize: `${fontSize}px`,
    fontWeight: fontWeight,
    fontStyle: passedFontStyle,
    textOverflow: passedTextOverflow,
  };
}

export function DefaultFlexModifiers({
  $flexDirection,
  $flexWrap,
  $justifyContent,
  $alignItems,
  $verticalAlign,
  $display,
}) {
  const passedFlexDirection = useResponsive($flexDirection);
  const passedJustifyContent = useResponsive($justifyContent);
  const passedAlignItems = useResponsive($alignItems);
  const passedVerticalAlign = useResponsive($verticalAlign);
  const passedDisplay = useResponsive($display);
  const passedFlexWrap = useResponsive($flexWrap);

  const styles = {};
  if (passedFlexDirection) {
    styles.flexDirection = passedFlexDirection;
  }

  if (passedFlexWrap) {
    styles.flexWrap = passedFlexWrap
  }

  if (passedJustifyContent) {
    styles.justifyContent = passedJustifyContent;
  }

  if (passedAlignItems) {
    styles.alignItems = passedAlignItems;
  }

  if (passedVerticalAlign) {
    styles.verticalAlign = passedVerticalAlign;
  }

  if (passedDisplay) {
    styles.display = passedDisplay;
  }

  return styles;
}

export function DefaultPositionModifiers({
  $position,
  $top,
  $right,
  $bottom,
  $left,
  $zIndex,
}) {
  const passedPosition = $position;
  const passedTop = $top;
  const passedRight = $right;
  const passedBottom = $bottom;
  const passedLeft = $left;
  const passedIndex = $zIndex;

  const styles = {};

  if (passedPosition) {
    styles.position = passedPosition;
  }

  if (passedRight != null) {
    styles.right = passedRight;
  }

  if (passedBottom != null) {
    styles.bottom = passedBottom;
  }

  if (passedTop != null) {
    styles.top = passedTop;
  }

  if (passedLeft != null) {
    styles.left = passedLeft;
  }

  if (passedIndex != null) {
    styles.zIndex = passedIndex;
  }

  return styles;
}

export function DefaultSizeModifiers({
  $height,
  $width,
  $maxHeight,
  $minHeight,
  $maxWidth,
  $overflow,
  $aspectRatio,
}) {
  const passedMaxWidth = useResponsive($maxWidth);
  const passedMaxHeight = useResponsive($maxHeight);
  const passedMinHeight = useResponsive($minHeight);
  const passedWidth = useResponsive($width);
  const passedHeight = useResponsive($height);
  const passedOverflow = useResponsive($overflow);
  const passedAspectRatio = useResponsive($aspectRatio);
  const styles = {
    maxHeight: passedMaxHeight, // ?? "100%",
    maxWidth: passedMaxWidth, // ?? "100%",
    minHeight: passedMinHeight,
    height: passedHeight,
    width: passedWidth,
    overflow: passedOverflow,
    aspectRatio: passedAspectRatio,
  };

  return styles;
}

export function DefaultBackgroundModifier({
  $background,
  $backgroundImage,
  $backgroundColor,
  $backgroundSize,
  $backdropFilter,
  $boxShadow,
}) {
  let styles = {};
  const col = useResponsive($backgroundColor);

  if ($backgroundColor) {
    styles.background = color(col);
    styles.backgroundSize = $backgroundSize ?? "auto 100%";
  }

  if ($backgroundImage) {
    styles.backgroundImage = `url(${$backgroundImage})`;
    styles.backgroundRepeat = "no-repeat";
    styles.backgroundSize = $backgroundSize ?? "auto 100%";
  }

  if ($background) {
    styles.background = $background
  }

  if ($backdropFilter) {
    styles.backdropFilter = $backdropFilter;
  }

  if ($boxShadow) {
    styles.boxShadow = $boxShadow;
  }

  return styles;
}

export function DefaultRadiusModifiers({
  $borderRadius,
  $borderTopLeftRadius,
  $borderTopRightRadius,
  $borderBottomLeftRadius,
  $borderBottomRightRadius,
}) {
  const passedRadius = useResponsive($borderRadius);
  const passedTopLeftRadius = useResponsive($borderTopLeftRadius);
  const passedTopRightRadius = useResponsive($borderTopRightRadius);
  const passedBottomLeftRadius = useResponsive($borderBottomLeftRadius);
  const passedBottomRightRadius = useResponsive($borderBottomRightRadius);

  const style = {
    borderRadius: passedRadius,
  };

  if (passedTopLeftRadius) style.borderTopLeftRadius = passedTopLeftRadius;
  if (passedTopRightRadius) style.borderTopRightRadius = passedTopRightRadius;
  if (passedBottomLeftRadius)
    style.borderBottomLeftRadius = passedBottomLeftRadius;
  if (passedBottomRightRadius)
    style.borderBottomRightRadius = passedBottomRightRadius;

  return style;
}

export function DefaultBorderModifiers({
  $border,
  $borderTop,
  $borderRight,
  $borderLeft,
  $borderBottom,
  $borderStyle,
}) {
  const passedBorder = useResponsive($border);
  const passedBorderTop = useResponsive($borderTop);
  const passedBorderRight = useResponsive($borderRight);
  const passedBorderLeft = useResponsive($borderLeft);
  const passedBorderBottom = useResponsive($borderBottom);
  const passedBorderStyle = useResponsive($borderStyle);

  const style = {
    border: passedBorder,
  };

  if (passedBorderTop) style.borderTop = passedBorderTop;
  if (passedBorderRight) style.borderRight = passedBorderRight;
  if (passedBorderLeft) style.borderLeft = passedBorderLeft;
  if (passedBorderBottom) style.borderBottom = passedBorderBottom;
  if (passedBorderStyle) style.borderStyle = passedBorderStyle;

  return style;
}

export function DefaultModifiers({ $opacity, $overflow, $zIndex }) {
  const styles = {};

  if ($opacity != null) {
    styles.opacity = $opacity;
  }

  if ($overflow != null) {
    styles.overflow = $overflow
  }

  if ($zIndex != null) {
    styles.zIndex = $zIndex
  }

  return styles;
}
