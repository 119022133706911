import React from 'react';
import MUITabs from '@mui/material/Tabs';
import MUITab from '@mui/material/Tab';
import { styled } from '@mui/material';
import { color } from "../Theme"
import { Box } from '../Boxes';
// style={{ color: color("primary"), fontWeight: "600" }}


const TabMiddleWare = (rest) => {
    const AntTab = styled((props) => <MUITab disableRipple {...props} />)(
        ({ theme }) => ({
            textTransform: 'none',
            minWidth: 0,
            [theme.breakpoints.up('sm')]: {
                minWidth: 0,
            },
            fontWeight: "600",
            marginRight: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(3),
            color: "#697A8D",
            '&:hover': {
                color: color("primary"),
                opacity: 1,
            },
            '&.Mui-selected': {
                color: '#111111',
                fontWeight: "600",
                borderTop: rest.$isVertical && rest.index > 0 ? "0.5px solid #ccc" : "",
                borderBottom: rest.$isVertical ? "0.5px solid #ccc" : "",
            },
            '&.Mui-focusVisible': {
                backgroundColor: '#d1eaff',
            },
        }),
    );

    return <AntTab {...rest} />
}

export default function Tabs({
    $onChanged, // onChanged
    $value, // number
    $tabs, // [ string ]
    $width,
    $isVertical,
}) {

    const handleChange = (event, newValue) => {
        $onChanged(newValue)
    };

    return (
        <Box
            // $backgroundColor="grey"
            $width={$width ?? "100%"}
            $height={$isVertical ? "100%" : ""}
            $borderBottom={!$isVertical ? "0.5px solid #ccc" : ""}
            $borderRight={$isVertical ? "0.5px solid #ccc" : ""}
        >
            <MUITabs
                style={{
                    width: '100%',
                    height: $isVertical ? "100vh" : "",
                }}
                value={$value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor={"primary"}
                orientation={$isVertical ? "vertical" : "horizontal"}
            >
                {$tabs?.map((tab, index) => {
                    return (
                        <TabMiddleWare index={index} label={tab?.name} $isVertical={$isVertical} />
                    )
                })}
            </MUITabs>
        </Box>
    );
}