import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createTimeOffApiService,
  getAllTimeOffApiService,
  updateTimeOffApiService,
} from "../../../services/applicationBackendApis/timeOff";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  timeOffs: [],
  timeOff: null,
  moreTimeOffsLeftInPagination: false,
  searchFilters: {
    timeOffStatus: null,
  },
  errorMessages: {
    restoreTimeOffErrorMessage: null,
    createTimeOffErrorMessage: null,
    deleteTimeOffErrorMessage: null,
    updateTimeOffErrorMessage: null,
    getTimeOffsErrorMessage: null,
    getTimeOffErrorMessage: null,
  },
  statuses: {
    restoreTimeOffStatus: null,
    createTimeOffStatus: null,
    deleteTimeOffStatus: null,
    updateTimeOffStatus: null,
    getTimeOffsStatus: null,
    getTimeOffStatus: null,
  },
  sucessMessages: {
    restoreTimeOffSuccessMessage: null,
    updateTimeOffSuccessMessage: null,
  },
};

//------------- creating new risk Assessment ---------------\\
export const createTimeOffReducer = createAsyncThunk(
  "timeOff/createTimeOff",
  async (timeOffData) => {
    try {
      const response = await createTimeOffApiService({
        staffId: timeOffData.staffId,
        name: timeOffData.name,
        reason: timeOffData.reason,
        notes: timeOffData.notes,
        startDate: timeOffData.startDate,
        endDate: timeOffData.endDate,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------- getting all timeOff from database ------------\\
export const getAllTimeOffReducer = createAsyncThunk(
  "timeOff/getAllTimeOff",
  async (timeOffData, thunkApi) => {
    try {
      const { getState } = thunkApi
      const { timeOff } = getState()
      const { searchFilters: {
        timeOffStatus,
      } } = timeOff
      const response = await getAllTimeOffApiService({
        staffId: timeOffData.staffId,
        timeOffStatus: timeOffStatus,
        startAfter: timeOffData.startAfter,
        limit: timeOffData.limit ?? PAGINATION_LIMITS.TIME_OFF_LIST,
        isArchived: timeOffData.isArchived,
      });
      return {
        data: response.data,
        limit: timeOffData.limit ?? PAGINATION_LIMITS.TIME_OFF_LIST,
        startAfter: timeOffData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateTimeOffReducer = createAsyncThunk(
  "timeOff/updateTimeOff",
  async (timeOffData) => {
    try {
      const response = await updateTimeOffApiService({
        staffId: timeOffData.staffId,
        timeOffId: timeOffData.timeOffId,
        timeOffStatus: timeOffData.timeOffStatus,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const timeOffSlice = createSlice({
  name: "timeOff",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalTimeOffsReducer: (state, action) => {
      state.timeOffs = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.sucessMessages = {};
    },
    updateTimeOffStatusFilterReducer: (state, action) => {
      state.searchFilters.timeOffStatus = action.payload;
    },
    clearFiltersReducer: (state, action) => {
      state.searchFilters = {}
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createTimeOffReducer.pending, (state, action) => {
      state.statuses.createTimeOffStatus = asyncStates.PENDING;
      state.errorMessages.createTimeOffErrorMessage = null;
    });
    builder.addCase(createTimeOffReducer.rejected, (state, action) => {
      state.statuses.createTimeOffStatus = asyncStates.FAILURE;
      state.errorMessages.createTimeOffErrorMessage =
        action.error?.message;
    });
    builder.addCase(createTimeOffReducer.fulfilled, (state, action) => {
      state.statuses.createTimeOffStatus = asyncStates.SUCCESS;
      state.errorMessages.createTimeOffErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateTimeOffReducer.pending, (state, action) => {
      state.statuses.updateTimeOffStatus = asyncStates.PENDING;
      state.errorMessages.updateTimeOffErrorMessage = null;
    });
    builder.addCase(updateTimeOffReducer.rejected, (state, action) => {
      state.statuses.updateTimeOffStatus = asyncStates.FAILURE;
      state.errorMessages.updateTimeOffErrorMessage =
        action.error?.message;
    });
    builder.addCase(updateTimeOffReducer.fulfilled, (state, action) => {
      state.statuses.updateTimeOffStatus = asyncStates.SUCCESS;
      state.errorMessages.updateTimeOffErrorMessage = null;
      state.sucessMessages.updateTimeOffSuccessMessage =
        action.payload.message;
    });

    // start handling get files async calls
    builder.addCase(getAllTimeOffReducer.pending, (state, action) => {
      state.statuses.getTimeOffsStatus = asyncStates.PENDING;
      state.errorMessages.getTimeOffsErrorMessage = null;
    });
    builder.addCase(getAllTimeOffReducer.rejected, (state, action) => {
      state.statuses.getTimeOffsStatus = asyncStates.FAILURE;
      state.errorMessages.getTimeOffsErrorMessage =
        action.error?.message;
    });
    builder.addCase(getAllTimeOffReducer.fulfilled, (state, action) => {
      state.statuses.getTimeOffsStatus = asyncStates.SUCCESS;
      state.errorMessages.getTimeOffsErrorMessage = null;
      state.timeOffs = action.payload.startAfter ? [...state.timeOffs, ...action.payload.data.timeOffs] : action.payload.data.timeOffs;

      if (action.payload.data.timeOffs.length >= action.payload.limit) {
        state.moreTimeOffsLeftInPagination = true;
      } else {
        state.moreTimeOffsLeftInPagination = false;
      }
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllLocalTimeOffsReducer,
  clearAllSuccessMessagesReducer,
  updateTimeOffStatusFilterReducer,
  clearFiltersReducer,
} = timeOffSlice.actions;

export default timeOffSlice.reducer;
