import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import Icon from "../../../../../Components/Icons";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import {
  Button,
  CheckBox,
  MultiSelect,
  SelectBox,
  Textarea,
  TextBox,
} from "../../../../../Components/Input";
import { SpanText, Text } from "../../../../../Components/Text";
import useStaffDispathers from "../../../../../redux/dispatchers/useStaffDispatchers";
import {
  useDeleteStaffSuccessSelector,
  useStaffDeleteErrorMessageSelector,
  useStaffDeleteStatusSelector,
  useStaffUpdateStatusSelector,
  useUpdateStaffErrorMessageSelector,
  useUpdateStaffSuccessSelector,
} from "../../../../../redux/selectors/useStaffSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import useTeamDispathers from "../../../../../redux/dispatchers/useTeamDispatchers";
import { useTeamsSelector } from "../../../../../redux/selectors/useTeamSelectors";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";
import { Grid, GridContainer } from "../../../../../Components/Grid";
import { useGlobalJobRolesSelector } from "../../../../../redux/selectors/useConfigsSelectors";

//------------STAFF EDIT DIALOG BOX----------//
export const StaffEditDialog = ({ staffData, $handleDialog }) => {
  const [showAddressFields, setShowAddressFields] = useState(false);
  const dateFormat = staffData?.dateOfBirth ? moment(staffData?.dateOfBirth).format("YYYY-MM-DD") : null
  const [submitted, setSubmitted] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState(staffData?.teams?.map(item => ({
    value: item.id,
    label: item.name
  })) || [])
  const [tabIndex, setTabIndex] = useState(0)
  const navigate = useNavigate();

  //-------Dispatchers----------//
  const { updateStaff, getSingleStaff, deleteStaff, clearAllErrorMessages, clearAllSuccessMessages, clearAllStatusMessages } =
    useStaffDispathers();

  const {
    staffJobRoleSpecified,
  } = usePermissionsController()

  //----------Selector Services----------//
  //Update Staff
  const updateStaffSuccessMessage = useUpdateStaffSuccessSelector();
  const updateStaffErrorMessage = useUpdateStaffErrorMessageSelector();
  const staffUpdateStatus = useStaffUpdateStatusSelector();
  const [staffUpdateChangeTracker, setStaffUpdateChange] = useState(null);
  //Delete Staff
  const deleteStaffSuccessMessage = useDeleteStaffSuccessSelector();
  const deleteStaffErrorMessage = useStaffDeleteErrorMessageSelector();
  const deleteStaffStatus = useStaffDeleteStatusSelector();

  const jobRoles = useGlobalJobRolesSelector()

  //----------Responses-------//
  const staffErrorMessage = useMemo(() => {
    return updateStaffErrorMessage || deleteStaffErrorMessage;
  }, [updateStaffErrorMessage, deleteStaffErrorMessage]);

  const staffSuccessMessage = useMemo(() => {
    return updateStaffSuccessMessage || deleteStaffSuccessMessage;
  }, [updateStaffSuccessMessage, deleteStaffSuccessMessage]);

  const staffStatus = useMemo(() => {
    return staffUpdateStatus;
  }, [staffUpdateStatus]);

  // ------------------------Team Dispatcher ------------------- //
  const { getTeams } = useTeamDispathers()
  // teams
  const allTeams = useTeamsSelector();

  //Form Data
  const [data, setData] = useState({
    id: staffData?.id,
    name: staffData?.name ?? "",
    email: "",
    jobRole: staffData?.jobRole,
    contact: staffData?.contact?.contact ?? "",
    phonecode: staffData?.contact?.phonecode || null,
    dateOfBirth: dateFormat ?? null,
    gender: staffData?.gender,
    addressLine1: staffData?.address?.line1 ?? "",
    addressLine2: staffData?.address?.line2 ?? "",
    city: staffData?.address?.city ?? "",
    postcode: staffData?.address?.postcode ?? "",
    country: staffData?.address?.country ?? "",
    region: staffData?.address?.region ?? "",
    notes: staffData?.notes ?? "",
    refId: staffData?.refId ?? "",

    about: staffData?.about,
    preferredName: staffData?.preferredName,
    dbsNo: staffData?.dbsNo,
    rightToWork: staffData?.rightToWork,
  });

  const tabOptions = useMemo(() => [
    { name: "Details" },
    { name: "Address" },
    { name: "Staff Profile" }
  ], [])

  const mappedJObRoles = jobRoles?.map((item) => ({
    value: item,
    label: item,
  }))

  const selectedTab = useMemo(() => {
    return tabOptions[tabIndex].name
  }, [tabIndex])

  //handling form data
  function handleChange(val) {
    console.log(val)
    setData({ ...data, [val.target.name]: val.target.value });
  }

  //Timer
  function timer(seconds) {
    const timeOut = setTimeout(() => {
      $handleDialog(false);
    }, seconds);
    return () => clearTimeout(timeOut);
  }

  const mappedAllTeamItems = allTeams?.map(item => ({
    value: item.id,
    label: item.name
  }));

  const handleTeamChange = (selected) => {
    setSelectedTeams([...selected]);
  };
  //Clear Messages
  useEffect(() => {
    clearAllStatusMessages();
    clearAllSuccessMessages();
    setStaffUpdateChange(null);
    clearAllErrorMessages();
    if (!allTeams || allTeams.length <= 0) {
      getTeams({})
    }
  }, []);

  //--Update Functions--//
  const onUpdateStaffClicked = async () => {
    await updateStaff({
      ...data,
      teams: selectedTeams?.map(item => item.value),
    }).unwrap();
    await getSingleStaff({
      staffId: staffData.id,
    }).unwrap();
    setStaffUpdateChange(null);
    setSubmitted(true);
    $handleDialog()
  };

  //---Delete function---//
  const onDeleteStaffClick = async () => {
    await deleteStaff({
      id: data.id,
    }).unwrap();
    timer(3000);
    navigate("/team");
  };

  return (
    <Box>
      <Tabs
        $onChanged={(val) => {
          setTabIndex(val)
        }}
        $value={tabIndex}
        $tabs={tabOptions}
      />

      <Box $marginTop={2} />

      <Box
        $padding={5}
        $marginRight={3}
      >
        {selectedTab == "Details" && <Box>
          <Text $fontSize="body" $marginBottom={1}>
            Name<SpanText $color={"red"}>*</SpanText>
          </Text>
          <TextBox
            $type={"text"}
            $name={"name"}
            $value={data?.name}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Email
          </Text>
          <TextBox
            $hint={staffData?.email ? staffData?.email : "xyz@gmail.com"}
            $name={"email"}
            $value={data.email}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
            $disabled={"true"}
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Staff Ref ID
          </Text>
          <TextBox
            $name={"refId"}
            $value={data.refId}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          {staffJobRoleSpecified && <>
            <br />
            <Text $fontSize="body" $marginBottom={1}>
              Job Role
            </Text>
            <SelectBox
              $name={"jobRole"}
              $value={data.jobRole ? { value: data.jobRole, label: data.jobRole } : null}
              $onChange={(val) => handleChange({ target: val })}
              $trailing={<Icon $icon="ArrowDropDown" />}
              // $width="50%"
              $options={mappedJObRoles}
            />
          </>}
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Contact
          </Text>
          <TextBox
            $type={"text"}
            $name={"contact"}
            $onChange={handleChange}
            $value={data.contact}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Date of Birth
          </Text>
          <TextBox
            $type={"date"}
            $name={"dateOfBirth"}
            $value={data.dateOfBirth}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />

          <GridContainer $spacing={1}>
            <Grid $xs={12} $sm={6}>
              <Text $fontSize="body" $marginBottom={1}>
                Gender
              </Text>
              <SelectBox
                $name={"gender"}
                $value={data?.gender ? { value: data?.gender, label: data?.gender } : null}
                $onChange={(val) => handleChange({ target: val })}
                $options={[
                  { value: "Male", label: "Male" },
                  { value: "Female", label: "Female" },
                  { value: "Other", label: "Other" },
                ]}
              />
            </Grid>
            <Grid $xs={12} $sm={6}>
              <Text $fontSize="body" $marginBottom={1}>
                Role
              </Text>
              <SelectBox
                $name={"role"}
                $disabled={true}
                $options={[
                  { value: "OWNER", label: "Owner" },
                  { value: "ADMIN", label: "Admin" },
                  { value: "MODERATOR", label: "Moderator" },
                  { value: "STAFF", label: "Staff" },
                ]}
                $value={staffData?.role ? { value: staffData?.role, label: staffData?.role } : null}
              />
            </Grid>
          </GridContainer>

          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Teams
          </Text>
          <Box $width="100%">
            <SelectBox
              $isMulti={true}
              $options={mappedAllTeamItems}
              $value={selectedTeams}
              $onChange={handleTeamChange}
            ></SelectBox>
          </Box>
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Note
          </Text>
          <Textarea
            $name={"notes"}
            $rows={5}
            $value={data.notes}
            $onChange={handleChange}
            $width="100%"
          />
        </Box>}

        {selectedTab == "Address" && <Box>
          <Text $fontSize="body" $marginBottom={1}>
            Address Line 1
          </Text>
          <TextBox
            $type={"address"}
            $name={"addressLine1"}
            $value={data.addressLine1}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Address Line 2
          </Text>
          <TextBox
            $type={"address"}
            $name={"addressLine2"}
            $value={data.addressLine2}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            City
          </Text>
          <TextBox
            $type={"text"}
            $name={"city"}
            $value={data.city}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Region
          </Text>
          <TextBox
            $type={"number"}
            $name={"region"}
            $value={data.region}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Postcode
          </Text>
          <TextBox
            $type={"text"}
            $name={"postcode"}
            $value={data.postcode}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Country
          </Text>
          <TextBox
            $type={"text"}
            $name={"country"}
            $value={data.country}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />
          <br />
        </Box>}

        {selectedTab == "Staff Profile" && <Box>

          <Text $fontSize="body" $marginBottom={1}>
            Preferred Name
          </Text>
          <TextBox
            $type={"text"}
            $name={"preferredName"}
            $value={data.preferredName}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />

          <br />

          <Text $fontSize="body" $marginBottom={1}>
            DBS Number
          </Text>
          <TextBox
            $type={"text"}
            $name={"dbsNo"}
            $value={data.dbsNo}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />

          <br />

          <Text $fontSize="body" $marginBottom={1}>
            Right to Work
          </Text>
          <TextBox
            $type={"text"}
            $name={"rightToWork"}
            $value={data.rightToWork}
            $onChange={handleChange}
            $width="100%"
            $borderRadius="8px"
            $border="1px solid black"
          />

          <br />

          <Text $fontSize="body" $marginBottom={1}>
            About
          </Text>
          <Textarea
            $name={"about"}
            $rows={5}
            $value={data.about}
            $onChange={handleChange}
            $width="100%"
          />

        </Box>}

        <br />
        {staffErrorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="error">{staffErrorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {staffUpdateChangeTracker === "you haven't made any changes" && !staffErrorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            <ErrorAndSuccess $type="error">
              <Text>{staffUpdateChangeTracker}</Text>
            </ErrorAndSuccess>
          </FlexBox>
        )}
        {submitted &&
          staffSuccessMessage &&
          staffStatus === asyncStates.SUCCESS && (
            <FlexBox $marginTop={2} $width="100%">
              {" "}
              <ErrorAndSuccess $type="success">
                {staffSuccessMessage}
              </ErrorAndSuccess>
            </FlexBox>
          )}
      </Box>

      {/* Save and Delete buttons */}
      <FlexBox
        $justifyContent="space-between"
        $padding={4}
        $backgroundColor="list"
      >
        {/* delete Staff */}
        <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={20}
          $onClick={() => onDeleteStaffClick()}
        >
          <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
            {deleteStaffStatus !== asyncStates.PENDING ? (
              "Delete"
            ) : (
              <CircularLoader $color="white" $size={25} />
            )}
          </Text>
        </Button>
        {staffStatus === asyncStates.PENDING ? (
          <Button
            $sx={{
              borderRadius: 2,
              width: "100px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            $marginRight={100}
          >
            <CircularLoader $color="white" $size={25} />
          </Button>
        ) : (
          <Button
            $sx={{ borderRadius: 2 }}
            $marginRight={40}
            $onClick={() => {
              if (staffStatus === asyncStates.PENDING) return;
              onUpdateStaffClicked()
            }}
          >
            <Text $color={"white"} $padding={"0px 20px 0px 20px"}>
              Save
            </Text>
          </Button>
        )}
      </FlexBox>
    </Box>
  );
};
