import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import {
    useGetDefaultQualificationsErrorMessageSelector,
    useGetDefaultQualificationsStatusSelector,
    useDefaultQualificationsSelector,
    useDefaultQualificationsUpdateStatusSelector,
    useUpdateDefaultQualificationsErrorMessageSelector,
    useUpdateDefaultQualificationsStatusSelector,
    useCreateDefaultQualificationsStatusSelector,
    useDeleteDefaultQualificationsStatusSelector,
    useCreateDefaultQualificationsErrorMessageSelector,
    useDeleteDefaultQualificationsErrorMessageSelector,
    useMoreDefaultQualificationsLeftInPaginationSelector
} from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useDefaultQualificationsSettingsController() {
    const { accessAddSettingsDefaultQualifications, accessEditSettingsDefaultQualifications } = usePermissionsController()
    const defaultQualifications = useDefaultQualificationsSelector()

    const getDefaultQualificationsErrorMessages = useGetDefaultQualificationsErrorMessageSelector()
    const updateDefaultQualificationsErrorMessages = useUpdateDefaultQualificationsErrorMessageSelector()
    const createDefaultQualificationsErrorMessages = useCreateDefaultQualificationsErrorMessageSelector()
    const deleteDefaultQualificationsErrorMessages = useDeleteDefaultQualificationsErrorMessageSelector()

    const getDefaultQualificationsStatuses = useGetDefaultQualificationsStatusSelector()
    const updateDefaultQualificationsStatuses = useUpdateDefaultQualificationsStatusSelector()
    const createDefaultQualificationsStatuses = useCreateDefaultQualificationsStatusSelector()
    const deleteDefaultQualificationsStatuses = useDeleteDefaultQualificationsStatusSelector()

    const moreItemsLeftInPagination = useMoreDefaultQualificationsLeftInPaginationSelector()

    const {
        createDefaultQualifications,
        updateDefaultQualifications,
        getDefaultQualifications,
        deleteDefaultQualifications,

        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getDefaultQualifications({})
    }, [])

    const onLoadMore = async () => {
        await getDefaultQualifications({
            startAfter: defaultQualifications[defaultQualifications.length - 1].id,
        }).unwrap();
    }

    const onCreate = async (val) => {
        await createDefaultQualifications({
            name: val.name,
            grade: val.grade,
        }).unwrap();
        return await getDefaultQualifications({}).unwrap();
    }

    const onUpdate = async (newValue) => {
        await updateDefaultQualifications({
            id: newValue.id,
            name: newValue.name,
            grade: newValue.grade,
        }).unwrap();
        return await getDefaultQualifications({}).unwrap();
    }

    const onDelete = async (prevValue) => {
        await deleteDefaultQualifications({
            id: prevValue.id
        }).unwrap();
        return await getDefaultQualifications({}).unwrap();
    }

    return {
        title: "Default Qualification",
        defaultQualifications: defaultQualifications,

        canEdit: accessEditSettingsDefaultQualifications,
        canAdd: accessAddSettingsDefaultQualifications,

        isCreatingConfig: createDefaultQualificationsStatuses == asyncStates.PENDING,
        isUpdatingConfig: updateDefaultQualificationsStatuses == asyncStates.PENDING,
        isDeletingConfig: updateDefaultQualificationsStatuses == asyncStates.PENDING,
        isGettingConfigs: getDefaultQualificationsStatuses == asyncStates.PENDING,

        createErrorMessage: createDefaultQualificationsErrorMessages,
        updateErrorMessage: updateDefaultQualificationsErrorMessages,
        deleteErrorMessage: updateDefaultQualificationsErrorMessages,
        gettingErrorMessage: getDefaultQualificationsErrorMessages,

        moreItemsLeftInPagination: moreItemsLeftInPagination,

        onLoadMore: onLoadMore,
        onCreate: onCreate,
        onUpdate: onUpdate,
        onDelete: onDelete,
    }
}