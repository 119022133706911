import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createStaffApiService,
  getStaffInviteDataApiService,
  getAllStaffApiService,
  getStaffApiService,
  updateStaffApiService,
  deleteStaffApiService,
  getStaffProfileApiServices,
} from "../../../services/applicationBackendApis/staff";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allStaff: [],
  allStaffPagination: {
    nextPage: null,
  },
  moreStaffLeftInPagination: false,
  singleStaff: null,
  singleStaffForProfile: null,
  inviteData: null,
  staffProfile: null,
  errorMessages: {
    createStaffErrorMessage: null,
    getStaffInviteErrorMessage: null,
    getNewStaffErrorMessage: null,
    getNewStaffForProfilErroreMessage: null,
    getSingleStaffErrorMessage: null,
    getStaffProfileErrorMessage: null,
    updateStaffErrorMessage: null,
    deleteStaffErrorMessage: null,
  },
  statuses: {
    createStaffStatus: null,
    getStaffInviteStatus: null,
    getNewStaffStatus: null,
    getNewStaffForProfileStatus: null,
    getSingleStaffStatus: null,
    getStaffProfileStatus: null,
    updateStaffStatus: null,
    deleteStaffStatus: null,
  },
  successMessages: {
    createStaffSuccessMessage: null,
    getStaffInviteSuccessMessage: null,
    getNewStaffSuccessMessage: null,
    getSingleStaffSuccessMessage: null,
    getStaffProfileSuccessMessage: null,
    getUpdateStaffSuccessMessage: null,
    deleteStaffSuccessMessage: null,
  },
};

//Create Staff Reducer
export const createStaffReducer = createAsyncThunk(
  "staff/createStaff",
  async (staffData) => {
    try {
      const response = await createStaffApiService({
        email: staffData.email,
        jobRole: staffData.jobRole,
        role: staffData.role,
        teams: staffData.teams,
        refId: staffData.refId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//Invite Staff Reducer
export const getStaffInviteReducer = createAsyncThunk(
  "staff/getInvite",
  async (inviteData) => {
    try {
      const response = await getStaffInviteDataApiService({
        oobCode: inviteData,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//All Staff Reducer
export const getNewStaffReducer = createAsyncThunk(
  "staff/getNewStaffMember",
  async (newStaff) => {
    try {
      const response = await getAllStaffApiService({
        limit: newStaff.limit ?? PAGINATION_LIMITS.CLIENT_LIST,
        token: newStaff.token,
        startAfter: newStaff.startAfter,
        query: newStaff.query,
        teams: newStaff.teams,
      });
      if (newStaff.cb) newStaff.cb(response.data.staff)
      return {
        data: response.data,
        limit: newStaff.limit ?? PAGINATION_LIMITS.CLIENT_LIST,
        startAfter: newStaff.startAfter,
      };
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//Single staff for profile Reducer
export const getNewStaffForProfileReducer = createAsyncThunk(
  "staff/getNewStaffMemberForProfile",
  async (newStaff) => {
    try {
      const response = await getAllStaffApiService({
        userId: localStorage.getItem("userId"),
      });
      if (newStaff.cb) newStaff.cb(response.data.staff)
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//Single Staff Reducer
export const getStaffReducer = createAsyncThunk(
  "staffs/getStaff",
  async (staffData) => {
    try {
      const response = await getStaffApiService({
        staffId: staffData.staffId,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//Update Staff Reducer
export const updateStaffReducer = createAsyncThunk(
  "staff/updateStaff",
  async (data) => {
    try {
      const body = {};
      body.file = data.profilePhotoFile
      body.name = data.name
      body.contact = data.contact ? {
        contact: data.contact,
        phonecode: data.phonecode,
      } : null
      body.dateOfBirth = data.dateOfBirth
      body.gender = data.gender
      body.address = data.addressLine1 || data.addressLine2 || data.city || data.postcode || data.country || data.region ? {
        line1: data.addressLine1,
        line2: data.addressLine2,
        city: data.city,
        postcode: data.postcode,
        country: data.country,
        region: data.region,
      } : null
      body.notes = data.notes
      body.teams = data.teams
      body.refId = data.refId
      body.jobRole = data.jobRole

      body.about = data.about
      body.preferredName = data.preferredName
      body.dbsNo = data.dbsNo
      body.rightToWork = data.rightToWork
      const response = await updateStaffApiService(body, data.id);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//Delete Staff
export const deleteStaffReducer = createAsyncThunk(
  "staff/deleteStaff",
  async (staffData) => {
    try {
      const response = await deleteStaffApiService({
        staffId: staffData.id,
      });
      console.log("DELETE", response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const getStaffProfileReducer = createAsyncThunk(
  "staff/getStaffProfile",
  async (staffData) => {
    try {
      const response = await getStaffProfileApiServices({
        staffId: staffData.staffId,
      });

      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.successMessages = {};
    },
    clearAllLocalStaffReducer: (state, action) => {
      state.allStaff = [];
      state.allStaffPagination = {}
    },
    clearLocalStaffProfileReducer: (state, action) => {
      state.staffProfile = null;
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create Staff async calls
    builder.addCase(createStaffReducer.pending, (state, action) => {
      state.statuses.createStaffStatus = asyncStates.PENDING;
      state.errorMessages.createStaffErrorMessage = null;
    });
    builder.addCase(createStaffReducer.rejected, (state, action) => {
      state.statuses.createStaffStatus = asyncStates.FAILURE;
      state.errorMessages.createStaffErrorMessage = action.error?.message;
    });
    builder.addCase(createStaffReducer.fulfilled, (state, action) => {
      state.statuses.createStaffStatus = asyncStates.SUCCESS;
      state.errorMessages.createStaffErrorMessage = null;
      state.successMessages.createStaffSuccessMessage = action.payload.message;
    });

    //Get the Staff Invite Data
    builder.addCase(getStaffInviteReducer.pending, (state, action) => {
      state.statuses.getStaffInviteStatus = asyncStates.PENDING;
      state.errorMessages.getStaffInviteErrorMessage = null;
    });
    builder.addCase(getStaffInviteReducer.rejected, (state, action) => {
      state.statuses.getStaffInviteStatus = asyncStates.FAILURE;
      state.errorMessages.getStaffInviteErrorMessage = action.error?.message;
    });
    builder.addCase(getStaffInviteReducer.fulfilled, (state, action) => {
      state.statuses.getStaffInviteStatus = asyncStates.SUCCESS;
      state.errorMessages.getStaffInviteErrorMessage = null;
      state.inviteData = action.payload;
    });

    //Get the New Staff Data
    builder.addCase(getNewStaffReducer.pending, (state, action) => {
      state.statuses.getNewStaffStatus = asyncStates.PENDING;
      state.errorMessages.getNewStaffErrorMessage = null;
    });
    builder.addCase(getNewStaffReducer.rejected, (state, action) => {
      state.statuses.getNewStaffStatus = asyncStates.FAILURE;
      state.errorMessages.getNewStaffErrorMessage = action.error?.message;
    });
    builder.addCase(getNewStaffReducer.fulfilled, (state, action) => {
      state.statuses.getNewStaffStatus = asyncStates.SUCCESS;
      state.errorMessages.getNewStaffErrorMessage = null;
      state.allStaff = action.payload.startAfter ? [...state.allStaff, ...action.payload.data.staff] : action.payload.data.staff;

      if (action.payload.data.staff.length >= action.payload.limit && action?.payload?.data?.pagination?.nextPage) {
        state.moreStaffLeftInPagination = true;
        state.allStaffPagination.nextPage = action?.payload?.data?.pagination?.nextPage;
      } else {
        state.moreStaffLeftInPagination = false;
        state.allStaffPagination.nextPage = null;
      }
    });

    // Get the single staff for profile button
    builder.addCase(getNewStaffForProfileReducer.pending, (state, action) => {
      state.statuses.getNewStaffForProfileStatus = asyncStates.PENDING;
      state.errorMessages.getNewStaffForProfilErroreMessage = null;
    });
    builder.addCase(getNewStaffForProfileReducer.rejected, (state, action) => {
      state.statuses.getNewStaffForProfileStatus = asyncStates.FAILURE;
      state.errorMessages.getNewStaffForProfilErroreMessage =
        action.error?.message;
    });
    builder.addCase(getNewStaffForProfileReducer.fulfilled, (state, action) => {
      state.statuses.getNewStaffForProfileStatus = asyncStates.SUCCESS;
      state.errorMessages.getNewStaffForProfilErroreMessage = null;
      state.singleStaffForProfile = action.payload.staff;
    });

    //Get the Single Staff ID
    builder.addCase(getStaffReducer.pending, (state, action) => {
      state.statuses.getSingleStaffStatus = asyncStates.PENDING;
      state.errorMessages.getSingleStaffErrorMessage = null;
    });
    builder.addCase(getStaffReducer.rejected, (state, action) => {
      state.statuses.getSingleStaffStatus = asyncStates.FAILURE;
      state.errorMessages.getSingleStaffErrorMessage = action.error?.message;
    });
    builder.addCase(getStaffReducer.fulfilled, (state, action) => {
      state.statuses.getSingleStaffStatus = asyncStates.SUCCESS;
      state.errorMessages.getSingleStaffErrorMessage = null;
      state.singleStaff = action.payload;
    });
    // start handling update Staff async calls
    builder.addCase(updateStaffReducer.pending, (state, action) => {
      state.statuses.updateStaffStatus = asyncStates.PENDING;
      state.errorMessages.updateStaffErrorMessage = null;
    });
    builder.addCase(updateStaffReducer.rejected, (state, action) => {
      state.statuses.updateStaffStatus = asyncStates.FAILURE;
      state.errorMessages.updateStaffErrorMessage = action.error?.message;
    });
    builder.addCase(updateStaffReducer.fulfilled, (state, action) => {
      state.statuses.updateStaffStatus = asyncStates.SUCCESS;
      state.errorMessages.updateStaffErrorMessage = null;
      state.successMessages.getUpdateStaffSuccessMessage =
        action.payload.message;
    });
    //Delete Staff
    builder.addCase(deleteStaffReducer.pending, (state, action) => {
      state.statuses.deleteStaffStatus = asyncStates.PENDING;
      state.errorMessages.deleteStaffErrorMessage = null;
    });
    builder.addCase(deleteStaffReducer.rejected, (state, action) => {
      state.statuses.deleteStaffStatus = asyncStates.FAILURE;
      state.errorMessages.deleteStaffErrorMessage = action.error?.message;
    });
    builder.addCase(deleteStaffReducer.fulfilled, (state, action) => {
      state.statuses.deleteStaffStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteStaffErrorMessage = null;
      state.successMessages.deleteStaffSuccessMessage = action.payload?.message;
    });

    // Get staff profile
    builder.addCase(getStaffProfileReducer.pending, (state, action) => {
      state.statuses.getStaffProfileStatus = asyncStates.PENDING;
      state.errorMessages.getStaffProfileErrorMessage = null;
    });
    builder.addCase(getStaffProfileReducer.rejected, (state, action) => {
      state.statuses.getStaffProfileStatus = asyncStates.FAILURE;
      state.errorMessages.getStaffProfileErrorMessage = action.error?.message;
    });
    builder.addCase(getStaffProfileReducer.fulfilled, (state, action) => {
      state.statuses.getStaffProfileStatus = asyncStates.SUCCESS;
      state.errorMessages.getStaffProfileErrorMessage = null;
      state.successMessages.getStaffProfileSuccessMessage = "Staff profile downloaded successfully";
      state.staffProfile = action.payload.base64File;
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllSuccessMessagesReducer,
  clearAllLocalStaffReducer,
  clearLocalStaffProfileReducer,
} = staffSlice.actions;

export default staffSlice.reducer;
