import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

// const fileTypes = ["*"];

function DragDrop({$onFileUpload, $fileTypes, ...rest }) {
  const handleChange = (file) => {
    $onFileUpload(file);
  };
  // const fileTypes = ["JPG", "PNG", "GIF"];
  return (
    <FileUploader handleChange={handleChange} name="file" types={$fileTypes} />
  );
}

export default DragDrop;