import React, { useState, createContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { cookieStorageService, localStorageService } from "../utiles/storage";

export const AuthContext = createContext();

export const AuthContextWrapper = ({ children }) => {
  //cookies
  const [cookies, setCookie, removeCookie] = useCookies();

  //tokens
  const [refreshToken, setRefreshToken] = useState(cookies.refreshToken || "");
  const [accessToken, setAccessToken] = useState(cookies.accessToken || "");
  const navigate = useNavigate();

  //response error state for login page
  const [loginError, setLoginError] = useState(null);

  //loading state for login page
  const [loading, setLoading] = useState(false);

  //isLoggedIn state
  var isLoggedIn = useMemo(() => {
    return refreshToken && accessToken ? true : false
  }, [refreshToken, accessToken]);

  //login request function from server
  const Login = async ({ email, password }) => {
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/signIn`, {
        email: email,
        password: password,
      })
      .then(function (res) {
        setCookie("refreshToken", res.data.refreshToken, { path: "/" });
        setCookie("accessToken", res.data.accessToken, { path: "/" });
        setRefreshToken(res.data.refreshToken);
        setAccessToken(res.data.accessToken);
        localStorageService.setItem("staffId", res.data.staffId)
        // console.log(res.data.user.id)
        setLoading(false);
      })
      .catch(function (err) {
        setLoginError(err.response.data?.error?.message);
        setLoading(false);
      });
  };

  //Switch account request function from server
  const SwitchAccount = async ({ businessAccountId, clientAccessId, onSuccess, onError }) => {
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/switchAccount`, {
        businessAccountId: businessAccountId,
        clientAccessId: clientAccessId,
      }, {
        headers: {
          Authorization: `Bearer ${cookieStorageService.getItem("refreshToken")}`,
        },
      })
      .then(function (res) {
        setCookie("refreshToken", res.data.refreshToken, { path: "/" });
        setCookie("accessToken", res.data.accessToken, { path: "/" });
        setRefreshToken(res.data.refreshToken);
        setAccessToken(res.data.accessToken);
        localStorageService.setItem("staffId", res.data.staffId)
        // console.log(res.data.user.id)
        setLoading(false);
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(function (err) {
        if (onError) {
          onError()
        }
        setLoginError(err.response.data?.error?.message);
        setLoading(false);
      });
  };

  //SignUp request function from server
  const SignUp = async ({ staffname, oobCode, password }) => {
    console.log(staffname);
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/signup`, {
        name: staffname,
        oobCode: oobCode,
        password: password,
      })
      .then(function (res) {
        setCookie("refreshToken", res.data.refreshToken, { path: "/" });
        setCookie("accessToken", res.data.accessToken, { path: "/" });
        setRefreshToken(res.data.refreshToken);
        setAccessToken(res.data.accessToken);
        setLoading(false);
        localStorageService.setItem("staffId", res.data.staffId)
        navigate("/");
      })
      .catch(function (err) {
        setLoginError(err.response.data?.error?.message);
        setLoading(false);
      });
  };

  //SignUp request function from server
  const ClientSignUp = async ({ username, password, oobCode, onSuccess, onError }) => {
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/client/signUp`, {
        username: username,
        password: password,
        oobCode: oobCode,
      })
      .then( async function (res){
        await onSuccess(res.data.message ?? "Successfully signed up")
        setCookie("refreshToken", res.data.refreshToken, { path: "/" });
        setCookie("accessToken", res.data.accessToken, { path: "/" });
        setRefreshToken(res.data.refreshToken);
        setAccessToken(res.data.accessToken);
        setLoading(false);
        localStorageService.setItem("staffId", res.data.staffId)
        navigate("/");
      })
      .catch( async function (err) {
        await onError(err.response.data?.error?.message ?? "Error signing up")
        setLoginError(err.response.data?.error?.message);
        setLoading(false);
      });
  };

  //logout request
  const Logout = async () => {
    setLoginError(null);
    setLoading(true);
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/auth/logout`,
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      )
      .then(function (res) {
        removeCookie("accessToken");
        removeCookie("refreshToken");
        localStorageService.deleteItem("businessAccountId")
        setAccessToken(null);
        setLoading(false);
        navigate("/");
      })
      .catch(function (err) {
        removeCookie("accessToken");
        removeCookie("refreshToken");
        localStorageService.deleteItem("businessAccountId")
        setAccessToken(null);
        setLoading(false);
        navigate("/");
      });
  };

  const ConfirmPasswordReset = async ({ password, oobCode, onSuccess, onError }) => {
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/passwordreset/confirmation`, {
        password: password,
        oobCode: oobCode,
      })
      .then(function (res) {
        setLoading(false);
        onSuccess(res.data.message ?? "Password reset successfully! Please login with the new password")
      })
      .catch(function (err) {
        onError(err.response.data?.error?.message);
        setLoading(false);
      });
  }

  const SendPasswordReset = async ({ email, onSuccess, onError }) => {
    setLoginError(null);
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/auth/passwordreset`, {
        email: email,
      })
      .then(function (res) {
        setLoading(false);
        onSuccess(res.data.message ?? `Password reset link sent to the email ${email}`)
      })
      .catch(function (err) {
        onError(err.response.data?.error?.message);
        setLoading(false);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        accessToken,
        isLoggedIn,
        loginError,
        setLoginError,
        loading,
        Login,
        SignUp,
        ClientSignUp,
        Logout,
        SwitchAccount,
        ConfirmPasswordReset,
        SendPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
