import {
  DefaultFlexModifiers,
  DefaultSpacingModifiers,
  DefaultSizeModifiers,
  DefaultBackgroundModifier,
  DefaultPositionModifiers,
  DefaultRadiusModifiers,
  DefaultModifiers,
} from "../Modifiers/index.js";
import { useResponsive } from "../Hooks/index";

export default function DefaultImage({
  $src,
  $alt,
  $objectFit,
  children,
  ...rest
}) {
  const styles = {
    ...DefaultSizeModifiers(rest),
    ...DefaultSpacingModifiers(rest),
    ...DefaultFlexModifiers(rest),
    ...DefaultPositionModifiers(rest),
    ...DefaultBackgroundModifier(rest),
    ...DefaultRadiusModifiers(rest),
    ...DefaultModifiers(rest),
    objectFit: useResponsive($objectFit),
    border: useResponsive(rest.$border),
  };

  return <img src={$src} style={{ ...styles }} alt={$alt} />;
}

export function CircleAvatar({ $size, children, ...rest }) {
  return (
    <DefaultImage
      {...{
        $width: $size,
        $height: $size,
        $borderRadius: "50%",
        $border: "1px solid rgba(0, 0, 0, 0.1)",
        ...rest,
      }}
    />
  );
}

export * from "./ProfilePhoto.js"