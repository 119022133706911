import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetPolicyErrorMessageSelector, useGetPolicyStatusSelector, usePolicySelector, usePolicyUpdateStatusSelector, useUpdatePolicyErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function usePolicySettingsController() {
    const { accessAddSettingsPolicy, accessEditSettingsPolicy } = usePermissionsController()
    const policyNames = usePolicySelector()
    const getPolicyErrorMessages = useGetPolicyErrorMessageSelector()
    const updatePolicyErrorMessages = useUpdatePolicyErrorMessageSelector()

    const getPolicyStatuses = useGetPolicyStatusSelector()
    const updatePolicyStatuses = usePolicyUpdateStatusSelector()

    const names = useMemo(() => [...policyNames], [policyNames])

    const {
        updatePolicy,
        getPolicy,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getPolicy()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updatePolicy({
            policy: newNames
        }).unwrap();
        getPolicy()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updatePolicy({
            policy: newNames
        }).unwrap();
        getPolicy()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updatePolicy({
            policy: newNames
        }).unwrap();
        getPolicy()
        return
    }

    return {
        title: "Policy List",
        names: names,
        
        canEdit: accessEditSettingsPolicy,
        canAdd: accessAddSettingsPolicy,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updatePolicyStatuses == asyncStates.PENDING,
        isDeletingConfig: updatePolicyStatuses == asyncStates.PENDING,
        isGettingConfigs: getPolicyStatuses == asyncStates.PENDING,

        updateErrorMessage: updatePolicyErrorMessages,
        deleteErrorMessage: updatePolicyErrorMessages,
        gettingErrorMessage: getPolicyErrorMessages,
    }
}