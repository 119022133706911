import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as asyncStates from "../../constants/asyncStates";
import {
    createMedicineApiService,
    deleteMedicineApiService,
    getAllMedicineApiService,
    restoreArchivedMedicineApiService,
    updateMedicineApiService,
} from "../../../services/applicationBackendApis/medicine";
import { formateDateToISOString } from "../../../utiles/dateformate";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
    allMedicine: [],
    singleMedicine: null,
    moreMedicineItemsLeftInPagination: false,
    medicineUploadPercentage: null,
    errorMessages: {
        restoreMedicineErrorMessage: null,
        createMedicineErrorMessage: null,
        deleteMedicineErrorMessage: null,
        updateMedicineErrorMessage: null,
        getAllMedicineErrorMessage: null,
        getSingleMedicineErrorMessage: null,
    },
    statuses: {
        restoreMedicineStatus: null,
        createMedicineStatus: null,
        deleteMedicineStatus: null,
        updateMedicineStatus: null,
        getAllMedicineStatus: null,
        getSingleMedicineStatus: null,
    },
};

//---------------- to get all the medicine from database ------------
export const getAllMedicineReducer = createAsyncThunk(
    "medicine/getAllMedicine",
    async (medicineData) => {
        try {
            const response = await getAllMedicineApiService({
                clientId: medicineData.clientId,
                startAfter: medicineData.startAfter,
                limit: medicineData.limit ?? PAGINATION_LIMITS.MEDICINE,
                isArchived: medicineData.isArchived,
            });
            return {
                data: response.data,
                limit: medicineData.limit ?? PAGINATION_LIMITS.MEDICINE,
                startAfter: medicineData.startAfter,
            }
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// ------------create compilance ------------------
export const createMedicineReducer = createAsyncThunk(
    "medicine/createMedicine",
    async (medicineData, thunkApi) => {
        const { dispatch } = thunkApi;
        try {
            const response = await createMedicineApiService({
                clientId: medicineData.clientId,
                startDate: medicineData.startDate,
                endDate: medicineData.endDate,
                repetitionEndDateType: medicineData.repetitionEndDateType,
                notes: medicineData.notes,
                dosageInstructions: medicineData.dosageInstructions,
                medicine: medicineData.medicine,
                dosage: medicineData.dosage,
                repetitionType: medicineData.repetitionType,
                days: medicineData.days,
                repetitionPeriod: medicineData.repetitionPeriod,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// -------------updating medicine ---------------------
export const updateMedicineReducer = createAsyncThunk(
    "medicine/updateMedicine",
    async (medicineData) => {
        try {
            const response = await updateMedicineApiService({
                medicineId: medicineData.medicineId,
                clientId: medicineData.clientId,
                startDate: medicineData.startDate,
                endDate: medicineData.endDate,
                repetitionEndDateType: medicineData.repetitionEndDateType,
                notes: medicineData.notes,
                dosageInstructions: medicineData.dosageInstructions,
                medicine: medicineData.medicine,
                dosage: medicineData.dosage,
                repetitionType: medicineData.repetitionType,
                days: medicineData.days,
                repetitionPeriod: medicineData.repetitionPeriod,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

export const restoreArchivedMedicineReducer = createAsyncThunk(
    "medicine/restoreArchivedMedicine",
    async (medicineData,) => {
        try {
            const response = await restoreArchivedMedicineApiService({
                clientId: medicineData.clientId,
                id: medicineData.medicineId,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

// -------------deleting medicine ---------------------
export const deleteMedicineReducer = createAsyncThunk(
    "medicine/deleteMedicine",
    async (medicineData) => {
        try {
            const response = await deleteMedicineApiService({
                clientId: medicineData.clientId,
                medicineId: medicineData.medicineId,
            });
            return response.data;
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error");
        }
    }
);

const medicineSlice = createSlice({
    name: "files",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        medicineUploadPercentageUpdatedReducer: (state, action) => {
            state.medicineUploadPercentage = action.payload;
        },
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {};
        },
        clearAllLocalMedicinesReducer: (state, action) => {
            state.allMedicine = [];
        },
        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {};
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {
        // start handling get files async calls
        builder.addCase(getAllMedicineReducer.pending, (state, action) => {
            state.statuses.getAllMedicineStatus = asyncStates.PENDING;
            state.errorMessages.getAllMedicineErrorMessage = null;
        });
        builder.addCase(getAllMedicineReducer.rejected, (state, action) => {
            state.statuses.getAllMedicineStatus = asyncStates.FAILURE;
            state.errorMessages.getAllMedicineErrorMessage = action.error?.message;
        });
        builder.addCase(getAllMedicineReducer.fulfilled, (state, action) => {
            state.statuses.getAllMedicineStatus = asyncStates.SUCCESS;
            state.errorMessages.getAllMedicineErrorMessage = null;
            state.allMedicine = action.payload.startAfter ? [...state.allMedicine, ...action.payload.data.medicines] : action.payload.data.medicines;

            if (action.payload.data.medicines.length >= action.payload.limit) {
                state.moreMedicineItemsLeftInPagination = true;
            } else {
                state.moreMedicineItemsLeftInPagination = false;
            }
        });

        // start handling create file async calls
        builder.addCase(createMedicineReducer.pending, (state, action) => {
            state.statuses.createMedicineStatus = asyncStates.PENDING;
            state.errorMessages.createMedicineErrorMessage = null;
        });
        builder.addCase(createMedicineReducer.rejected, (state, action) => {
            state.statuses.createMedicineStatus = asyncStates.FAILURE;
            state.errorMessages.createMedicineErrorMessage = action.error?.message;
        });
        builder.addCase(createMedicineReducer.fulfilled, (state, action) => {
            state.statuses.createMedicineStatus = asyncStates.SUCCESS;
            state.errorMessages.createMedicineErrorMessage = null;
        });

        builder.addCase(restoreArchivedMedicineReducer.pending, (state, action) => {
            state.statuses.restoreMedicineStatus = asyncStates.PENDING;
            state.errorMessages.restoreMedicineErrorMessage = null;
        });
        builder.addCase(restoreArchivedMedicineReducer.rejected, (state, action) => {
            state.statuses.restoreMedicineStatus = asyncStates.FAILURE;
            state.errorMessages.restoreMedicineErrorMessage =
                action.error?.message;
        });
        builder.addCase(restoreArchivedMedicineReducer.fulfilled, (state, action) => {
            state.statuses.restoreMedicineStatus = asyncStates.SUCCESS;
            state.errorMessages.restoreMedicineErrorMessage = null;
        });

        // start handling delete file async calls
        builder.addCase(deleteMedicineReducer.pending, (state, action) => {
            state.statuses.deleteMedicineStatus = asyncStates.PENDING;
            state.errorMessages.deleteMedicineErrorMessage = null;
        });
        builder.addCase(deleteMedicineReducer.rejected, (state, action) => {
            state.statuses.deleteMedicineStatus = asyncStates.FAILURE;
            state.errorMessages.deleteMedicineErrorMessage = action.error?.message;
        });
        builder.addCase(deleteMedicineReducer.fulfilled, (state, action) => {
            state.statuses.deleteMedicineStatus = asyncStates.SUCCESS;
            state.errorMessages.deleteMedicineErrorMessage = null;
        });

        // start handling update file async calls
        builder.addCase(updateMedicineReducer.pending, (state, action) => {
            state.statuses.updateMedicineStatus = asyncStates.PENDING;
            state.errorMessages.updateMedicineErrorMessage = null;
        });
        builder.addCase(updateMedicineReducer.rejected, (state, action) => {
            state.statuses.updateMedicineStatus = asyncStates.FAILURE;
            state.errorMessages.updateMedicineErrorMessage = action.error?.message;
        });
        builder.addCase(updateMedicineReducer.fulfilled, (state, action) => {
            state.statuses.updateMedicineStatus = asyncStates.SUCCESS;
            state.errorMessages.updateMedicineErrorMessage = null;
        });
    },
});

export const {
    medicineUploadPercentageUpdatedReducer,
    clearAllLocalMedicinesReducer,
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
} = medicineSlice.actions;

export default medicineSlice.reducer;
