import React, { useMemo, useState } from 'react'
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes'
import { Button, CheckBox, SelectBox, TextBox, Textarea } from '../../../Components/Input'
import CircularLoader from '../../../Components/Icons/circularLoader'
import { Grid, GridContainer } from '../../../Components/Grid'
import { Text } from '../../../Components/Text'
import Icon from '../../../Components/Icons'
import { ADDITIONAL_FORM_FIELD_TYPE } from '../../../constants'
import { convertAdditionalFormFieldToText } from '../../../utiles/additionalFormFields'

export default function AddConfigAdditionalFormsDialog(props) {
  const {
    additionalForm: editingAdditionalForm,
    loading,
    onDelete,
    onSave,
    handleDialog,
    errorMessage
  } = props

  const [name, setName] = useState(editingAdditionalForm ? editingAdditionalForm.name : null)
  const [instructions, setInstructions] = useState(editingAdditionalForm ? editingAdditionalForm.instructions : null)
  let [fields, setFields] = useState(editingAdditionalForm ? [...(editingAdditionalForm.fields.map(e => {
    return { ...e, options: e.options ? [...e.options] : [] }
  }))] ?? [] : [])

  const mappedFieldNames = useMemo(() => {
    const vals = Object.values(ADDITIONAL_FORM_FIELD_TYPE)
    return vals.map(e => {
      return {
        label: convertAdditionalFormFieldToText(e),
        value: e,
      }
    })
  }, [])

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
          Name
        </Text>
        <TextBox
          $hint={"Incident Form"}
          $type={"text"}
          $name={"text"}
          $value={name}
          $onChange={(e) => setName(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />

        <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
          Tnstructions
        </Text>
        <Textarea
          $hint={"Enter instructions here..."}
          $type={"text"}
          $name={"text"}
          $rows={5}
          $value={instructions}
          $onChange={(e) => setInstructions(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />


        <Box $marginTop={2} $width="100%">
          {fields && fields.length > 0 && <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
            Fields
          </Text>}
          <hr />
          {fields?.map((field, index) => {
            return (
              <>
                <GridContainer $spacing={4}>
                  <Grid $xs={6}>
                    <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                      Field Name
                    </Text>
                    <TextBox
                      $hint={"Enter field name"}
                      $type={"text"}
                      $name={"text"}
                      $value={field.name}
                      $onChange={(e) => {
                        fields[index].name = e.target.value
                        setFields([...fields])
                      }}
                      $width="100%"
                      $borderRadius="8px"
                      $border="1px solid black"
                    />
                  </Grid>
                  <Grid $xs={4}>
                    <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                      Field Name
                    </Text>
                    <SelectBox
                      $value={field.type ? { value: field.type, label: convertAdditionalFormFieldToText(field.type) } : null}
                      $name={"type"}
                      $onChange={(e) => {
                        fields[index].type = e.value
                        setFields([...fields])
                      }}
                      $trailing={<Icon $icon="ArrowDropDown" />}
                      $width="100%"
                      $options={mappedFieldNames}
                    />
                  </Grid>
                  <Grid $xs={2}>
                    <FlexBox $alignItems="flex-end" $height="100%">
                      <Icon
                        $icon="Delete"
                        $size={24}
                        $pointer="pointer"
                        $color={"red"}
                        $marginBottom={2}
                        $onClick={() => {
                          const newFields = fields.filter(function (item) {
                            return item !== field
                          })
                          setFields([...newFields])
                        }}
                      />
                    </FlexBox>
                  </Grid>
                  {field.type == ADDITIONAL_FORM_FIELD_TYPE.OPTIONS && <Grid $xs={12}>
                    <GridContainer $spacing={4}>
                      {field?.options.map((option, optionIndex) => {
                        return (
                          <>
                            <Grid $xs={8}>
                              <TextBox
                                $hint={"Enter field name"}
                                $type={"text"}
                                $name={"text"}
                                $value={option}
                                $onChange={(e) => {
                                  fields[index].options[optionIndex] = e.target.value
                                  setFields([...fields])
                                }}
                                $width="100%"
                                $borderRadius="8px"
                                $border="1px solid black"
                              />
                            </Grid>
                            <Grid $xs={4}>
                              <FlexBox $alignItems="flex-end" $height="100%">
                                <Icon
                                  $icon="Delete"
                                  $size={24}
                                  $pointer="pointer"
                                  $color={"red"}
                                  $marginBottom={2}
                                  $onClick={() => {
                                    const newFields = fields[index].options.filter((item, i) => {
                                      return i !== optionIndex
                                    })
                                    fields[index].options = newFields
                                    setFields([...fields])
                                  }}
                                />
                              </FlexBox>
                            </Grid>
                          </>
                        )
                      })}
                    </GridContainer>
                  </Grid>}
                  {field.type == ADDITIONAL_FORM_FIELD_TYPE.OPTIONS && <Grid $xs={12}>
                    <FlexBox
                      $width="100%"
                      $justifyContent="flex-start"
                      $onClick={() => {
                        fields[index].options.push("")
                        setFields([...fields])
                      }}>
                      <Icon
                        $icon="Add"
                        $size={18}
                        $pointer="pointer"
                      />
                      <Text $fontWeight="600" $pointer="pointer">
                        Add Option
                      </Text>
                    </FlexBox>
                  </Grid>}
                </GridContainer>
                <hr />
              </>
            )
          })}
        </Box>

        <FlexBox $width="100%" $justifyContent="flex-end" $marginTop={2} $onClick={() => {
          fields.push({
            name: "",
            type: "",
            options: [],
          })
          setFields([...fields])
        }}>
          <Icon
            $icon="Add"
            $size={18}
            $pointer="pointer"
          />
          <Text $fontWeight="600" $pointer="pointer">
            Add Field
          </Text>
        </FlexBox>
      </FlexBox>

      <Box
        $padding={2}
      >
        {errorMessage && (
          <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
        )}
      </Box>

      <FlexBox
        $justifyContent={!editingAdditionalForm ? "flex-end" : "space-between"}
        $padding={2}
        $backgroundColor="list"
      >
        {editingAdditionalForm && <Button
          $color={"error"}
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={async () => {
            await onDelete()
          }}
        >
          {!loading ? 'Delete' : <CircularLoader $color="white" $size={25} />}
        </Button>}
        <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={async () => {
            await onSave({
              name,
              instructions,
              fields,
            })
          }}
        >
          {!loading ? 'Save' : <CircularLoader $color="white" $size={25} />}
        </Button>
      </FlexBox>
    </>
  )
}
