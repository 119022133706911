import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalTimeOffsReducer,
  createTimeOffReducer,
  getAllTimeOffReducer,
  updateTimeOffReducer,
  clearAllSuccessMessagesReducer,
  updateTimeOffStatusFilterReducer,
  clearFiltersReducer,
} from "../../slices/timeOffSlice";

const useTimeOffDispatchers = () => {
  const dispatch = useDispatch();

  const createTimeOff = (data) => {
    return dispatch(createTimeOffReducer(data));
  };

  const updateTimeOff = (data) => {
    return dispatch(updateTimeOffReducer(data));
  };

  const getTimeOffs = (data) => {
    return dispatch(getAllTimeOffReducer(data));
  };

  const clearAllLocalTimeOffs = (data) => {
    return dispatch(clearAllLocalTimeOffsReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  const clearFilters = (data) => {
    return dispatch(clearFiltersReducer(data));
  };

  const updateTimeOffStatusFilter = (data) => {
    return dispatch(updateTimeOffStatusFilterReducer(data));
  };

  return {
    createTimeOff,
    getTimeOffs,
    updateTimeOffStatusFilter,
    clearAllErrorMessages,
    updateTimeOff,
    clearAllLocalTimeOffs,
    clearAllSuccessMessages,
    clearFilters,
  };
};

export default useTimeOffDispatchers;
