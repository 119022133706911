import React, { useEffect, useMemo, useState } from 'react'
import useTimeOffDispatchers from "../../../redux/dispatchers/useTimeOffDispatchers"
import { useMoreTimeOffsLeftInPaginationSelector, useTimeOffsGetErrorMessageSelector, useTimeOffsGetStatusSelector, useTimeOffsSelector } from "../../../redux/selectors/useTimeOffSelectors"
import usePermissionsController from '../../../permissions'
import { FormDialog } from '../../../Components/Modals'
import { Box, CircleBox, ErrorAndSuccess, FlexBox, ListBox } from '../../../Components/Boxes'
import MuiSkeletonLoader from '../../../Components/LoadingSkeleton/MuiSkeletonLoader'
import { Text } from '../../../Components/Text'
import { formateDateToReadableFormatWithTime } from '../../../utiles/dateformate'
import { useResponsive } from '../../../Components/Hooks'
import { Button, CheckBox } from '../../../Components/Input'
import Icon from '../../../Components/Icons'
import EmptyState from '../../../Components/NoRecordFound'
import { GridContainer, Grid } from '../../../Components/Grid'
import * as asyncStates from "../../../redux/constants/asyncStates";
import { TIME_OFF_STATUS, TIME_SHEET_STATUS } from '../../../constants'
import AddTimeOffDialog from './AddTimeOffDialog'

export default function TimeOff(props) {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const { staffId } = props
  const timeOffs = useTimeOffsSelector()
  const getAllTimeOffStatus = useTimeOffsGetStatusSelector()
  const getAllTimeOffError = useTimeOffsGetErrorMessageSelector()
  const moreTimeOffItemsLeftInPagination = useMoreTimeOffsLeftInPaginationSelector()

  const [timeOffStatus, setTimeOffStatus] = useState([])

  const {
    allowApproveTimeOff,
  } = usePermissionsController()

  const {
    clearAllErrorMessages,
    clearAllLocalTimeOffs,
    clearAllSuccessMessages,
    clearFilters,
    updateTimeOffStatusFilter,
    getTimeOffs,
  } = useTimeOffDispatchers()

  const [open, setOpen] = useState(false);
  const [timeOffToEdit, setTimeOffToEdit] = useState(null);

  function HandleDialog() {
    setOpen(!open);
    setTimeOffToEdit(null);
  }

  const loadMore = () => {
    getTimeOffs({
      staffId: staffId,
      startAfter: timeOffs[timeOffs.length - 1].id,
    });
  }

  useEffect(() => {
    clearAllErrorMessages()
    clearAllSuccessMessages()
    clearAllLocalTimeOffs()
    clearFilters()
    updateTimeOffStatusFilter(timeOffStatus)
    getTimeOffs({
      staffId: staffId,
    })
  }, [timeOffStatus])

  const timeOffStatusFilterOptions = useMemo(() => {
    return [
      { value: TIME_OFF_STATUS.PENDING, label: "Pending" },
      { value: TIME_OFF_STATUS.ACCEPTED, label: "Accepted" },
      { value: TIME_OFF_STATUS.REJECTED, label: "Rejected" },
      { value: TIME_OFF_STATUS.CANCELLED, label: "Cancelled" },
    ]
  }, [])

  const statusColor = (status) => {
    switch (status) {
      case TIME_OFF_STATUS.PENDING:
        return "orange"
      case TIME_OFF_STATUS.ACCEPTED:
        return "green"
      case TIME_OFF_STATUS.REJECTED:
        return "red"
      case TIME_OFF_STATUS.CANCELLED:
        return "red"
      default:
        return "orange"
    }
  }

  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={"flex-end"} $width="100%" $marginTop={2}>
        <Button
          $onClick={() => {
            HandleDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Add time off</Text>
        </Button>
      </FlexBox>

      <GridContainer $spacing={3}>
        {timeOffStatusFilterOptions.map((value, index) => {
          return (
            <Grid key={index} xs={1}>
              <FlexBox $marginRight="3">
                <CheckBox
                  $checked={timeOffStatus.includes(value.value)}
                  $size={"medium"}
                  $onChange={(val) => {
                    const tempTimeOffStatus = new Set(timeOffStatus)
                    if (val.target.checked) {
                      tempTimeOffStatus.add(value.value)
                    } else {
                      tempTimeOffStatus.delete(value.value)
                    }
                    const timeOffStatusArray = []
                    tempTimeOffStatus.forEach((d) => {
                      timeOffStatusArray.push(d)
                    })
                    setTimeOffStatus(timeOffStatusArray)
                  }}
                />
                <Text $color={"text"} $fontSize={"body-sm"} $fontWeight={"600"}>
                  {value.label}
                </Text>
              </FlexBox>
            </Grid>
          )
        })}
      </GridContainer>

      <FlexBox $marginTop={3} $padding="3">
        <Box $width="20%">
          <Text $fontWeight="bold">
            Staff
          </Text>
        </Box>
        <Box $width="15%">
          <Text>Start</Text>
        </Box>
        <Box $width="15%">
          <Text>End</Text>
        </Box>
        <Box $width="40%">
          <Text>Reason</Text>
        </Box>
        <Box $width="10%">
          <Text $textAlign={"center"}>Status</Text>
        </Box>
      </FlexBox>

      {getAllTimeOffStatus === asyncStates.SUCCESS && timeOffs.length < 1 && <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
        <EmptyState />
      </FlexBox>}

      {timeOffs?.map((value, index) => {
        return (
          <ListBox
            $width={"none"}
            key={index}
            $index={index}
            $onClick={() => {
              HandleDialog();
              setTimeOffToEdit(value);
            }}
          >
            <FlexBox>
              <Box $width="20%">
                <Text $fontWeight="bold">
                  {value?.staff?.name}
                </Text>
              </Box>
              <Box $width="15%">
                <Text>{value.creationDate ? formateDateToReadableFormatWithTime(new Date(value.startDate)) : "-"}</Text>
              </Box>

              <Box $width="15%">
                <Text>{value.creationDate ? formateDateToReadableFormatWithTime(new Date(value.endDate)) : "-"}</Text>
              </Box>
              <Box $width="40%">
                <Text>{value?.reason ?? ""}</Text>
              </Box>
              <Box $width="10%" $display="flex" $justifyContent="center">
                <CircleBox
                  $size={10}
                  $backgroundColor={statusColor(value.timeOffStatus)}
                  $color={statusColor(value.timeOffStatus)}
                />
              </Box>
            </FlexBox>
          </ListBox>
        );
      })}

      {getAllTimeOffStatus === asyncStates.PENDING && [1, 2, 3, 4].map((value) => {
        return (
          <Box $width="100%" $marginTop="2" key={value}>
            <MuiSkeletonLoader
              $variant="rectangular"
              $width={"100%"}
              $height={40}
            />
          </Box>
        );
      })}

      {getAllTimeOffError && <FlexBox $marginTop={2} $width="100%">
        <ErrorAndSuccess $type="error">{getAllTimeOffError}</ErrorAndSuccess>
      </FlexBox>}

      {moreTimeOffItemsLeftInPagination && <FlexBox $marginTop="6" $justifyContent="center">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      {open && <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Time Off"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        <AddTimeOffDialog
          $handleDialog={HandleDialog}
          $staffId={staffId}
          $timeOffToEdit={timeOffToEdit}
        />
      </FormDialog>}

    </Box>
  )
}
