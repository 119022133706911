import React, { useState, useMemo } from "react";
import { Box, FlexBox } from "../../../Components/Boxes/index";
import Icon from "../../../Components/Icons";
import { SpanText, Text } from "../../../Components/Text";
import { formateDateToReadableFormatWithTime, formateDateToReadableFormatWithoutTime, getDurationOfTwoDatesText } from "../../../utiles/dateformate"
import { DIARY_CHECKIN_STATUS, DIARY_TYPE } from "../../../constants";
import { displayMedicineText } from "../../../utiles/medicineFormatter";
import { GridContainer, Grid } from "../../../Components/Grid"
import ProfilePhoto from "../../../Components/Images/ProfilePhoto";

export default function DiaryCard({ diary, user }) {

    const [open, setOpen] = useState(false);
    const diaryColor = useMemo(() => {
        if (diary.checkInStatus == DIARY_CHECKIN_STATUS.COMPLETED) {
            return "diary-completed";
        } else if (diary.checkInStatus == DIARY_CHECKIN_STATUS.CHECKED_IN) {
            return "diary-inprogressed";
        } else if (diary.checkInStatus == DIARY_CHECKIN_STATUS.LATE_CHECK_IN) {
            return "diary-late-checkin";
        } else {
            return "diary-missed";
        }
    }, [diary])

    // late, ontime and ongoing, note
    if (diary.diaryType == DIARY_TYPE.NOTE) {
        return (
            <FlexBox
                $width="100%"
                $border="1px solid #ccc"
                $backgroundColor="#eee"
                $onClick={() => { }}
            >
                <Box $padding="2">
                    <GridContainer>
                        <Grid $xs={12}>
                            <FlexBox $width="100%" $alignItems="flex-start">
                                <Icon $size="25px" $icon="Message"></Icon>
                                <Text $wordWrap="anywhere" $alignText="center" $marginLeft={2}>
                                    {diary.notes}
                                </Text>
                            </FlexBox>
                        </Grid>
                        <Grid $xs={12}>
                            {diary?.additionalStaff && diary?.additionalStaff?.length > 0 && <Box $marginTop="2">
                                <Text $fontWeight="700">
                                    Additional carers : &nbsp;
                                    {diary?.additionalStaff?.map((staff, index) => {
                                        return (
                                            <SpanText $color={"#4200FF"} $fontWeight="700">
                                                {staff?.name}, &nbsp;
                                            </SpanText>
                                        )
                                    })}
                                </Text>
                            </Box>}
                        </Grid>
                    </GridContainer>
                </Box>
            </FlexBox>
        )
    }

    const getStatusText = () => {
        if (diary.checkInStatus == DIARY_CHECKIN_STATUS.LATE_CHECK_IN) {
            return (<Text $color="red" $fontWeight="700" $marginLeft="2">
                (Completed {getDurationOfTwoDatesText(new Date(diary.originalStartDate), new Date(diary.startDate))} Late)
            </Text>
            )
        } else if (diary.checkInStatus == DIARY_CHECKIN_STATUS.MISSED) {
            return (<Text $color="red" $fontWeight="700" $marginLeft="2">
                (Missed)
            </Text>
            )
        } else if (diary.checkInStatus == DIARY_CHECKIN_STATUS.CHECKED_IN) {
            return (<Text $color="#ffaa00" $fontWeight="700" $marginLeft="2">
                (Currently Checked In)
            </Text>
            )
        } else if (diary.checkInStatus == DIARY_CHECKIN_STATUS.COMPLETED) {
            return (<Text $color="green" $fontWeight="700" $marginLeft="2">
                (Completed on time)
            </Text>
            )
        }

        return <></>
    }

    return (
        <FlexBox
            $backgroundColor={diaryColor}
            $width="100%"
            $onClick={() => {
                setOpen(!open);
            }}
        >
            <FlexBox $width="100%" $flexDirection="column" $alignItems="flex-start">
                <GridContainer>
                    <Grid $xs={12} $sm={5}>
                        <FlexBox $minHeight="60px">
                            <Box>
                                <ProfilePhoto $marginLeft="2" $size="40px" $url={user?.profilePhotoUrl} />
                            </Box>
                            <Box $padding={2}>
                                <Text
                                    $fontSize="body-sm"
                                    $fontWeight="700"
                                    $wordWrap="anywhere"
                                >
                                    {user?.name}
                                </Text>

                                <Text
                                    $fontSize="body-sm"
                                    $fontWeight="600"
                                    $paddingTop={1}
                                >
                                    {formateDateToReadableFormatWithoutTime(diary?.startDate ?? diary?.originalStartDate)}
                                </Text>
                            </Box>
                        </FlexBox>
                    </Grid>

                    <Grid $xs={12} $sm={7}>
                        <Box
                            $flexDirection="column"
                            $alignItems="flex-start"
                            $justifyContent="center"
                        >
                            <FlexBox $justifyContent="flex-start" $padding={1}>
                                <Icon $size="25px" $icon="Home" $color={diary.checkInStatus != DIARY_CHECKIN_STATUS.MISSED ? "black" : "red"}></Icon>
                                {diary.checkInStatus != DIARY_CHECKIN_STATUS.MISSED && <Text $color={diary.checkInStatus != DIARY_CHECKIN_STATUS.MISSED ? "black" : "red"} $fontWeight="700">
                                    {getDurationOfTwoDatesText(new Date(diary.startDate), diary.endDate ? new Date(diary.endDate) : new Date())}
                                </Text>}
                                {getStatusText()}
                            </FlexBox>
                            {!open && diary.notes && (
                                <FlexBox
                                    $width="100%"
                                    $padding={1}
                                    $justifyContent="flex-start"
                                    $alignItems="flex-start"
                                >
                                    <Icon $size="25px" $icon="Message"></Icon>
                                    <Text $wordWrap="anywhere" $alignText="center" $marginLeft={2}>
                                        {diary.notes}
                                    </Text>
                                </FlexBox>
                            )}
                        </Box>
                    </Grid>
                </GridContainer>

                {open && (
                    <FlexBox
                        $marginLeft={2}
                        $flexDirection="column"
                        $alignItems="flex-start"
                        $width="100%"
                    >
                        {diary?.additionalStaff && diary?.additionalStaff?.length > 0 && <Box>
                            <Text $fontWeight="700">
                                Additional carers : &nbsp;
                                {diary?.additionalStaff?.map((staff, index) => {
                                    return (
                                        <SpanText $color={"#4200FF"} $fontWeight="700">
                                            {staff?.name}, &nbsp;
                                        </SpanText>
                                    )
                                })}
                            </Text>
                        </Box>}

                        <GridContainer $marginTop={3}>
                            {diary?.services && diary?.services?.length > 0 && <Grid $xs={12} $sm={5}>
                                <Text $fontWeight="bold">
                                    Task
                                </Text>
                                {diary?.services?.map((value, index) => {
                                    return (
                                        <Text key={index}>
                                            {value}
                                            <SpanText>
                                                <Icon $size="18px" $icon="Done" $color="green" />
                                            </SpanText>
                                        </Text>
                                    )
                                })}
                            </Grid>}

                            {diary?.medicines && diary?.medicines?.length > 0 && <Grid $xs={12} $sm={7}>
                                <Text $fontWeight="bold">
                                    Medication Administured
                                </Text>
                                {diary?.medicines?.map((medicine, index) => {
                                    if (medicine.refused) {
                                        return (
                                            <>
                                                <Text key={index}>
                                                    {displayMedicineText(medicine?.medicineDetails?.medicine)}
                                                    {medicine?.dateTaken && <SpanText $marginLeft="1">
                                                        {formateDateToReadableFormatWithTime(medicine?.dateTaken)}
                                                    </SpanText>}
                                                    <SpanText>
                                                        <Icon $size="18px" $icon="Clear" $color="red" />
                                                    </SpanText>
                                                    {medicine.refusalReason && <Text $fontStyle="italic">
                                                        - {medicine.refusalReason}
                                                    </Text>}
                                                    {medicine.notes && <Text $fontStyle="italic">
                                                        - {medicine.notes}
                                                    </Text>}
                                                </Text>
                                            </>
                                        )
                                    }

                                    return (
                                        <Text key={index}>
                                            {medicine.dosage} x {displayMedicineText(medicine?.medicineDetails?.medicine)}
                                            {medicine?.dateTaken && <SpanText $marginLeft="1">
                                                {formateDateToReadableFormatWithTime(medicine?.dateTaken)}
                                            </SpanText>}
                                            <SpanText>
                                                <Icon $size="18px" $icon="Done" $color="green" />
                                            </SpanText>
                                            {medicine.notes && <Text $fontStyle="italic">
                                                - {medicine.notes}
                                            </Text>}
                                        </Text>
                                    )
                                })}
                            </Grid>}
                        </GridContainer>
                        {diary.notes && <FlexBox
                            $width="100%"
                            $justifyContent="flex-start"
                            $alignItems="flex-start"
                            $marginTop={2}
                        >
                            <Icon $size="25px" $icon="Message"></Icon>
                            <Text $wordWrap="anywhere" $marginLeft={2}>
                                {diary.notes ?? "-"}
                            </Text>
                        </FlexBox>}
                    </FlexBox>
                )}
            </FlexBox>
        </FlexBox>
    )
}
