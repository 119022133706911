import { useSelector } from "react-redux";

export const useAllMedicineSelector = () => useSelector((state) => {
  return state.medicine.allMedicine;
});

export const useMedicineUploadPercentageSelector = () => useSelector((state) => {
  return state.medicine.medicineUploadPercentage;
});

// ------------------- selector error messages ------------------ //
export const useMedicineCreateErrorMessageSelector = () => useSelector((state) => {
  return state.medicine.errorMessages.createMedicineErrorMessage;
});

export const useMedicineGetErrorMessageSelector = () => useSelector((state) => {
  return state.medicine.errorMessages.getAllMedicineErrorMessage;
});

export const useMedicineDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.medicine.errorMessages.deleteMedicineErrorMessage;
});

export const useMedicineUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.medicine.errorMessages.updateMedicineErrorMessage;
});

export const useMedicineRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.medicine.errorMessages.restoreMedicineErrorMessage;
});

// ------------ status selectors ------------ //
export const useMedicineCreateStatusSelector = () => useSelector((state) => {
  return state.medicine.statuses.createMedicineStatus;
});

export const useAllMedicineGetStatusSelector = () => useSelector((state) => {
  return state.medicine.statuses.getAllMedicineStatus;
});

export const useMedicineDeleteStatusSelector = () => useSelector((state) => {
  return state.medicine.statuses.deleteMedicineStatus;
});

export const useMedicineUpdateStatusSelector = () => useSelector((state) => {
  return state.medicine.statuses.updateMedicineStatus;
});

export const useMoreMedicineItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.medicine.moreMedicineItemsLeftInPagination
})

export const useMedicineRestoreStatusSelector = () => useSelector((state) => {
  return state.medicine.statuses.restoreMedicineStatus;
});