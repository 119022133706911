import React, { useEffect } from "react";
import { useState } from "react";
import { Box, ErrorAndSuccess, FlexBox } from "../../../../../Components/Boxes";
import Icon from "../../../../../Components/Icons";
import { Button, MultiSelect, SelectBox, TextBox } from "../../../../../Components/Input";
import { Text } from "../../../../../Components/Text";
import useStaffDispathers from "../../../../../redux/dispatchers/useStaffDispatchers";
import {
  useStaffCreateErrorMessageSelector,
  useStaffCreateStatusSelector,
  useStaffCreateSuccessSelector,
} from "../../../../../redux/selectors/useStaffSelectors";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import { useMemo } from "react";
import CircularLoader from "../../../../../Components/Icons/circularLoader";
import useTeamDispathers from "../../../../../redux/dispatchers/useTeamDispatchers";
import { useTeamsSelector } from "../../../../../redux/selectors/useTeamSelectors";
import { Grid, GridContainer } from "../../../../../Components/Grid"
import usePermissionsController from "../../../../../permissions";
import { useGlobalJobRolesSelector } from "../../../../../redux/selectors/useConfigsSelectors";

export const AddStaff = () => {

  // ---------------- states ----------------------
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("OWNER");
  const [team, setTeam] = useState([]);
  const [refId, setRefId] = useState("")
  const [jobRole, setJobRole] = useState(null)

  // ---------------- selectors --------------------

  const staffCreateErrorMessage = useStaffCreateErrorMessageSelector();
  const staffCreatStatus = useStaffCreateStatusSelector();
  const staffCreatSuccessMessage = useStaffCreateSuccessSelector();
  const jobRoles = useGlobalJobRolesSelector()

  const getAllTeams = useTeamsSelector();

  // ---------------- useMemoStates ----------------

  const errorMessage = useMemo(() => {
    return staffCreateErrorMessage;
  }, [staffCreateErrorMessage]);

  const status = useMemo(() => {
    return staffCreatStatus;
  }, [staffCreatStatus]);

  const successMessage = useMemo(() => {
    return staffCreatSuccessMessage;
  }, [staffCreatSuccessMessage])

  // ------------------ dispatchers --------------

  const { createStaff, clearAllErrorMessages, clearAllSuccessMessages } = useStaffDispathers();
  const { getTeams } = useTeamDispathers();
  const {
    staffJobRoleSpecified,
  } = usePermissionsController()

  // ------------------ functions -----------------
  const mappedItems = getAllTeams?.map(item => ({
    value: item.id,
    label: item.name
  }));

  const mappedJObRoles = jobRoles?.map((item) => ({
    value: item,
    label: item,
  }))

  const handleChange = (selected) => {
    const values = selected.map((item) => item.value);
    setTeam(values);
  };

  const onCreateStaffClicked = async () => {
    await createStaff({
      email: email,
      role: role,
      jobRole: jobRole,
      teams: team,
      refId: refId,
    }).unwrap();
  };

  const roleOptions = [
    { value: "OWNER", label: "Owner" },
    { value: "ADMIN", label: "Admin" },
    { value: "MODERATOR", label: "Moderator" },
    { value: "STAFF", label: "Staff" },
  ]

  // ------------------ useEffect -----------------

  useEffect(() => {
    getTeams({})
    clearAllErrorMessages();
    clearAllSuccessMessages();
  }, [])

  return (
    <>
      <FlexBox
        $flexDirection="column"
        $alignItems="flex-start"
        $padding={5}
        $marginRight={3}
      >
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Email
        </Text>
        <TextBox
          $hint={"xyz@gmail.com"}
          $type={"email"}
          $name={"email"}
          $value={email}
          $onChange={(e) => setEmail(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        <br />
        <Text $fontSize="body" $marginBottom={1}>
          Staff Ref ID
        </Text>
        <TextBox
          $hint={"11D5"}
          $type={"text"}
          $name={"refId"}
          $value={refId}
          $onChange={(e) => setRefId(e.target.value)}
          $width="100%"
          $borderRadius="8px"
          $border="1px solid black"
        />
        {staffJobRoleSpecified && <>
          <br />
          <Text $fontSize="body" $marginBottom={1}>
            Job Role
          </Text>
          <SelectBox
            $name={"role"}
            $value={jobRole ? { value: jobRole, label: jobRole } : null}
            $onChange={(e) => setJobRole(e.value)}
            $trailing={<Icon $icon="ArrowDropDown" />}
            // $width="50%"
            $options={mappedJObRoles}
          />
        </>}
        <br />
        <GridContainer $spacing={1}>
          <Grid $xs={12} $sm={6}>
            <Text $fontSize="body" $marginBottom={1}>
              Role
            </Text>
            <SelectBox
              $name={"role"}
              $onChange={(e) => setRole(e.value)}
              $trailing={<Icon $icon="ArrowDropDown" />}
              // $width="50%"
              $options={roleOptions}
            />
          </Grid>
          <Grid $xs={12} $sm={6}>
            <Text $fontSize="body" $marginBottom={1}>
              Teams
            </Text>
            <SelectBox
              $isMulti={true}
              $options={mappedItems}
              $onChange={handleChange}
            ></SelectBox>
          </Grid>
        </GridContainer>

        {errorMessage && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
        {(successMessage && status === asyncStates.SUCCESS) && (
          <FlexBox $marginTop={2} $width="100%">
            {" "}
            <ErrorAndSuccess $type="success">{successMessage}</ErrorAndSuccess>
          </FlexBox>
        )}
      </FlexBox>

      {/* The last buttons */}
      <FlexBox
        $justifyContent={"flex-end"}
        $padding={4}
        $backgroundColor="list"
      >
        <Button
          $sx={{ borderRadius: 2 }}
          $marginRight={3}
          $onClick={() => {
            if (status === asyncStates.PENDING) return;
            onCreateStaffClicked();
          }}
        >
          {status !== asyncStates.PENDING ? 'Send Invitation Code' : <CircularLoader $color="white" $size={25} />}
        </Button>
      </FlexBox>
    </>
  );
};
