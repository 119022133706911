import React, { useEffect, createRef, useState, useRef } from 'react'
import { Box, FlexBox } from '../../Components/Boxes';
import { Button } from '../../Components/Input';

export default function UploadSignature({ $onFileUpload }) {
    const canvasRef = useRef(null);
    const [ctx, setCtx] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [lineWidth, setLineWidth] = useState(5);
    const [strokeColor, setStrokeColor] = useState("#000000");
    const [drawnArea, setDrawnArea] = useState(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        setCtx(canvas.getContext('2d'));
    }, []);

    useEffect(() => {
        if (ctx) {
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = strokeColor;
        }
    }, [ctx, lineWidth, strokeColor])

    const startDrawing = () => {
        setIsDrawing(true);
        ctx.beginPath();
    }

    const stopDrawing = () => {
        setIsDrawing(false);
        const boundingBox = getBoundingBox();
        setDrawnArea(boundingBox);
    }

    const draw = (e) => {
        if (!isDrawing) return;
        ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
        ctx.stroke();
    }

    const getBoundingBox = () => {
        const canvas = canvasRef.current;
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        let minX = canvas.width;
        let minY = canvas.height;
        let maxX = 0;
        let maxY = 0;
        for (let i = 0; i < data.length; i += 4) {
            const x = (i / 4) % canvas.width;
            const y = Math.floor((i / 4) / canvas.width);
            if (data[i + 3] !== 0) {
                if (x < minX) minX = x;
                if (x > maxX) maxX = x;
                if (y < minY) minY = y;
                if (y > maxY) maxY = y;
            }
        }
        return { minX, minY, maxX, maxY };
    }

    const handleLineWidthChange = (e) => {
        setLineWidth(e.target.value);
    }

    const handleStrokeColorChange = (e) => {
        setStrokeColor(e.target.value);
    }

    const handleFormSubmit = (e) => {
        // const canvas = canvasRef.current;
        // const dataURL = canvas.toDataURL('image/png'); // Retrieve the image data from the canvas
        // var blobBin = atob(dataURL.split(',')[1]);
        // var array = [];
        // for (var i = 0; i < blobBin.length; i++) {
        //     array.push(blobBin.charCodeAt(i));
        // }
        // const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
        // $onFileUpload(file)

        const canvas = canvasRef.current;
        let imageData;
        if (drawnArea) {
            const { minX, minY, maxX, maxY } = drawnArea;
            const croppedWidth = maxX - minX;
            const croppedHeight = maxY - minY;
            const croppedCanvas = document.createElement('canvas');
            croppedCanvas.width = croppedWidth;
            croppedCanvas.height = croppedHeight;
            const croppedCtx = croppedCanvas.getContext('2d');
            croppedCtx.drawImage(canvas, minX, minY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);
            imageData = croppedCanvas.toDataURL('image/png');
        } else {
            imageData = canvas.toDataURL('image/png');
        }

        const file = dataURItoBlob(imageData);
        $onFileUpload(file)
    }

    const dataURItoBlob = (dataURL) => {
        var blobBin = atob(dataURL.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: 'image/png' });
    }

    return (
        <div style={{ minHeight: "70vh" }}>
            <canvas
                ref={canvasRef}
                style={{ top: 0, left: 0, zIndex: 1, width: "100%", minHeight: "70vh" }}
                onMouseDown={startDrawing}
                onMouseUp={stopDrawing}
                onMouseMove={draw}
            />

            <FlexBox
                $justifyContent="space-between"
                // $position="absolute"
                $bottom="0"
                $width="100%"
                $zIndex={3}
                $paddingBottom="2"
            >
                <FlexBox $marginLeft="2">
                    {/* <input
                        type="color"
                        value={strokeColor}
                        onChange={handleStrokeColorChange}
                    />
                    <Box $marginRight="2" /> */}
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={lineWidth}
                        onChange={handleLineWidthChange}
                    />
                </FlexBox>
                <Box $marginRight="2">
                    <Button $onClick={handleFormSubmit}>Submit</Button>
                </Box>
            </FlexBox>
        </div>
    );
}
