import { useSelector } from "react-redux";

export const useCreateClientSelector = () => useSelector((state) => {
  return state.client;
})
export const useGetAllClientsSelector = () => useSelector((state) => {
  return state.client.allClients;
})
export const useSingleClientSelector = () => useSelector((state) => {
  return state.client.singleClient;
})
export const useDeleteClientSelector = () => useSelector((state) => {
  return state.client;
})
export const useClientsPaginationNextPage = () => useSelector((state) => {
  return state.client.allClientsPagination.nextPage;
})

//------------------------Client Error Messages-------------------//
export const useCreateClientErrorMessageSelector = () => useSelector((state) => {
  return state.client.errorMessages.createClientErrorMessage;
})
export const useGetAllClientsErrorMsgSelector = () => useSelector((state) => {
  return state.client.errorMessages.getAllClientsErrorMessage;
})
export const useGetSingleClientErrorMessageSelector = () => useSelector((state) => {
  return state.client.errorMessages.getSingleClientErrorMessage;
})
export const useClientDeleteErrorMessageSelector = () => useSelector((state) => {
  console.log(state.client.errorMessages.deleteClientErrorMessage)
  return state.client.errorMessages.deleteClientErrorMessage;
})
export const useUpdateClientErrorMessageSelector = () => useSelector((state) => {
  return state.client.errorMessages.updateClientErrorMessage;
})
export const useSendStaffProfileErrorMessageSelector = () => useSelector((state) => {
  return state.client.errorMessages.sendStaffProfileErrorMessage;
})
//-----------------------Client Status Selectors----------------//
export const useCreateClientStatusSelector = () => useSelector((state) => {
  console.log(state.client.status.createClientStatus);
  return state.client.status.createClientStatus;
})
export const useGetAllClientsStatusSelector = () => useSelector((state) => {
  return state.client.status.getAllClientsStatus;
})
export const useSingleClientStatusSelector = () => useSelector((state) => {
  return state.client.status.getSingleClientStatus;
})
export const useClientUpdateStatusSelector = () => useSelector((state) => {
  return state.client.status.updateClientStatus;
})
export const useClientDeleteStatusSelector = () => useSelector((state) => {
  return state.client.status.deleteClientStatus;
})
export const useSendStaffProfileStatusSelector = () => useSelector((state) => {
  return state.client.status.sendStaffProfileStatus;
})
//------------------------Success Client Selectors---------------------//
export const useCreateClientSuccessMessageSelector = () => useSelector((state) => {
  console.log(state.client.successMessages.createClientSuccessMessage)
  return state.client.successMessages.createClientSuccessMessage;
})
export const useGetAllClientsSuccessMsgSelector = () => useSelector((state) => {
  return state.client.successMessages.getAllClientsSuccessMessage;
})
export const useSingleClientSuccessSelector = () => useSelector((state) => {
  return state.client.successMessages.getSingleClientSuccessMessage;
})
export const useUpdateClientSuccessSelector = () => useSelector((state) => {
  return state.client.successMessages.updateClientSuccessMessage;
})
export const useDeleteClientSuccessSelector = () => useSelector((state) => {
  return state.client.successMessages.deleteClientSuccessMessage;
})
export const useSendStaffProfileSuccessSelector = () => useSelector((state) => {
  return state.client.successMessages.sendStaffProfileSuccessMessage;
})
export const useMoreClientsLeftInPaginationSelector = () => useSelector((state) => {
  return state.client.moreClientsLeftInPagination
})