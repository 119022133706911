import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalRiskAssessmentsReducer,
  createRiskAssessmentReducer,
  deleteRiskAssessmentReducer,
  getAllRiskAssessmentReducer,
  updateRiskAssessmentReducer,
  clearAllSuccessMessagesReducer,
  restoreArchivedRiskAssessmentReducer,
} from "../../slices/riskAssessmentSlice";

const useRiskAssessmentDispatchers = () => {
  const dispatch = useDispatch();

  const createRiskAssessment = (data) => {
    return dispatch(createRiskAssessmentReducer(data));
  };

  const updateRiskAssessment = (data) => {
    return dispatch(updateRiskAssessmentReducer(data));
  };

  const getRiskAssessments = (data) => {
    return dispatch(getAllRiskAssessmentReducer(data));
  };

  const deleteRiskAssessment = (data) => {
    return dispatch(deleteRiskAssessmentReducer(data));
  };

  const restoreArchivedRiskAssessment = (data) => {
    return dispatch(restoreArchivedRiskAssessmentReducer(data))
  }

  const clearAllLocalRiskAssessments = (data) => {
    return dispatch(clearAllLocalRiskAssessmentsReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  return {
    createRiskAssessment,
    getRiskAssessments,
    restoreArchivedRiskAssessment,
    clearAllErrorMessages,
    updateRiskAssessment,
    deleteRiskAssessment,
    clearAllLocalRiskAssessments,
    clearAllSuccessMessages,
  };
};

export default useRiskAssessmentDispatchers;
