import {
	DefaultFlexModifiers,
	DefaultSpacingModifiers,
	DefaultSizeModifiers,
	DefaultBackgroundModifier,
	DefaultPositionModifiers,
	DefaultTextModifiers,
} from "../Modifiers";
import {color, spacing} from "../Theme";
import styled from "styled-components";

const Input = styled.input`
    color: ${color("black")};
    border: none;
    border-radius: 0px;
    outline: none;
    width: 100%;

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
`;

const TextWrapper = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    ${(props) => props.disabled ? `
    background-color: #E0E0E099;
  ` : ''}
`;

export default function TextBox({
	$hint,
	$defaultValue,
	$name,
	$type,
	$value,
	$onChange,
	$onClick,
	$borderRadius,
	$leading,
	$trailing,
	$secondTrailing,
	$backgroundColor,
	$boxShadow,
	$fontSize,
	$color,
	$fontWeight,
	$disabled,
	$maxLength,
	...rest
}) {
	return (
		<TextWrapper
			style={{
				...DefaultPositionModifiers(rest),
				...DefaultSpacingModifiers({$padding: 2, ...rest}),
				...DefaultFlexModifiers(rest),
				...DefaultSizeModifiers({$width: "100%", ...rest}),
				...DefaultBackgroundModifier(
					($backgroundColor = {$backgroundColor}),
					rest
				),
				borderRadius: $borderRadius || 10,
				border: rest.$border || "1px solid black",
				boxShadow: $boxShadow,
			}}
			onClick={$onClick}
			disabled={$disabled}
		>
			{$leading && (
				<div
					style={{
						marginRight: spacing(2),
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
					// onClick={$onClick}
				>
					{$leading}
				</div>
			)}
			<Input
				type={$type}
				style={{
					...DefaultTextModifiers({$fontSize: "body-sm"}),
					background: "transparent",
					fontSize: {$fontSize},
					color: {$color},
					fontWeight: {$fontWeight},
				}}
				placeholder={$hint}
				name={$name}
				value={$value}
				onChange={$onChange}
				disabled={$disabled}
				defaultValue={$defaultValue}
				maxLength={$maxLength}
			/>

			{$trailing && (
				<div
					style={{
						marginLeft: spacing(2),
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
					// onClick={$onClick}
				>
					{$trailing}
				</div>
			)}
			{$secondTrailing && (
				<div
					style={{
						marginLeft: spacing(2),
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
					// onClick={$onClick}
				>
					{$secondTrailing}
				</div>
			)}

		</TextWrapper>
	);
}
