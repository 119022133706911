import { useSelector } from "react-redux";

export const useAllEmailListSelector = () => useSelector((state) => {
  return state.emailList.allEmailList;
});

// ------------------- selector error messages ------------------ //
export const useEmailListCreateErrorMessageSelector = () => useSelector((state) => {
  return state.emailList.errorMessages.createEmailListErrorMessage;
});

export const useEmailListGetErrorMessageSelector = () => useSelector((state) => {
  return state.emailList.errorMessages.getAllEmailListErrorMessage;
});

export const useEmailListDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.emailList.errorMessages.deleteEmailListErrorMessage;
});

export const useEmailListUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.emailList.errorMessages.updateEmailListErrorMessage;
});

export const useEmailListRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.emailList.errorMessages.restoreEmailListErrorMessage;
});

// ------------ status selectors ------------ //
export const useEmailListCreateStatusSelector = () => useSelector((state) => {
  return state.emailList.statuses.createEmailListStatus;
});

export const useAllEmailListGetStatusSelector = () => useSelector((state) => {
  return state.emailList.statuses.getAllEmailListStatus;
});

export const useEmailListDeleteStatusSelector = () => useSelector((state) => {
  return state.emailList.statuses.deleteEmailListStatus;
});

export const useEmailListUpdateStatusSelector = () => useSelector((state) => {
  return state.emailList.statuses.updateEmailListStatus;
});

export const useMoreEmailListItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.emailList.moreEmailListItemsLeftInPagination
})

export const useEmailListRestoreStatusSelector = () => useSelector((state) => {
  return state.emailList.statuses.restoreEmailListStatus;
});