import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {
    autoPilotEventsApiService,
    createEventApiService,
    deleteEventApiService,
    getEventApiService,
    getEventsApiService,
    updateEventApiService
} from "../../../services/applicationBackendApis/events"
import * as asyncStates from "../../constants/asyncStates"
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
    events: [],
    event: null,
    staffAvailabilities: [],
    moreEventsLeftInPagination: false,
    searchFilters: {
        startDateFilter: null,
        endDateFilter: null, // sortBy
        clientIdFilter: null,
        staffIdFilter: null,
        isClientVisitFilter: null,
        isShiftFilter: null,
        isAvailabilityFilter: null,
        isCustomEventFilter: null,
        isAbsenseFilter: null,
        sortByFilter: null,
        isDistributePendingApproval: null,
    },
    errorMessages: {
        createEventErrorMessage: null,
        deleteEventErrorMessage: null,
        updateEventErrorMessage: null,
        getEventsErrorMessage: null,
        getEventErrorMessage: null,
        autoPilotEventsErrorMessage: null,
        getStaffAvailabilitiesErrorMessage: null,
    },
    successMessages: {
        createEventSuccessMessage: null,
        getEventsSuccessMessage: null,
        getEventSuccessMessage: null,
        updateEventSuccessMessage: null,
        deleteEventSuccessMessage: null,
        autoPilotEventsSuccessMessage: null,
        getStaffAvailabilitiesSuccessMessage: null,
    },
    statuses: {
        createEventStatus: null,
        deleteEventStatus: null,
        updateEventStatus: null,
        getEventsStatus: null,
        getEventStatus: null,
        autoPilotEventsStatus: null,
        getStaffAvailabilitiesStatus: null,
    },
}

export const getEventsReducer = createAsyncThunk(
    'events/getEvents',
    async (eventData, thunkApi) => {
        try {
            const { getState } = thunkApi
            const { events } = getState()
            const { searchFilters: {
                startDateFilter,
                endDateFilter,
                clientIdFilter,
                staffIdFilter,

                isDistributePendingApproval,
                isClientVisitFilter,
                isShiftFilter,
                isAvailabilityFilter,
                isCustomEventFilter,
                isAbsenseFilter,
                sortByFilter,
            } } = events

            const {
                limit,
                startAfter,
                staffUnassigned,
                clientUnassigned,
            } = eventData

            const response = await getEventsApiService({
                startDateRange: startDateFilter ? new Date(startDateFilter) : null,
                endDateRange: endDateFilter ? new Date(endDateFilter) : null,
                clientId: clientIdFilter,
                staffId: staffIdFilter,
                isClientVisit: isClientVisitFilter,
                isShift: isShiftFilter,
                isAvailability: isAvailabilityFilter,
                isCustomEvent: isCustomEventFilter,
                isAbsense: isAbsenseFilter,
                isDistributePendingApproval: isDistributePendingApproval,
                limit: !startDateFilter || !endDateFilter ? limit ?? PAGINATION_LIMITS.EVENT_LIST : null,
                sortBy: sortByFilter,
                startAfter,
                staffUnassigned,
                clientUnassigned,
            })

            return {
                data: response.data,
                limit: !startDateFilter || !endDateFilter ? limit ?? PAGINATION_LIMITS.EVENT_LIST : null,
                startAfter: startAfter,
            };
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getEventReducer = createAsyncThunk(
    'events/getEvent',
    async (eventData, thunkApi) => {
        try {
            const { getState } = thunkApi
            const { events } = getState()
            const { searchFilters: {
                clientIdFilter,
                staffIdFilter,
            } } = events
            const { id } = eventData
            const response = await getEventApiService({
                id,
                clientId: clientIdFilter,
                staffId: staffIdFilter,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const createEventReducer = createAsyncThunk(
    'events/createEvent',
    async (eventData, thunkApi) => {
        try {
            const {
                clientId,
                staffId,

                eventType,
                name,
                isRepeating,
                startDate,
                endDate,
                address,

                distribute,
                services,
                flexibleStart,
                breakDuration,
                jobRole,
                instructions,
                notice,
                additionalStaff,
                requiredNumStaff,

                repetition,
                eventColor,
                requiredChecklistAcceptance,
            } = eventData
            const response = await createEventApiService({
                clientId,
                staffId,

                eventType,
                name,
                isRepeating,
                startDate,
                endDate,
                distribute,

                services,
                flexibleStart,
                breakDuration,
                jobRole,
                instructions,
                notice,
                additionalStaff,
                requiredNumStaff,

                address: address ? {
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    region: address.region,
                    postcode: address.postcode,
                    country: address.country,
                } : null,
                repetition: repetition && isRepeating ? {
                    repetitionType: repetition.repetitionType,
                    days: repetition.days,
                    repetitionPeriod: repetition.repetitionPeriod ? {
                        value: repetition.repetitionPeriod.value,
                        every: repetition.repetitionPeriod.every,
                    } : null,
                    repetitionStartDate: repetition.repetitionStartDate,
                    repetitionEndDate: repetition.repetitionEndDate,
                    repetitionEndDateType: repetition.repetitionEndDateType,
                } : null,
                eventColor,
                requiredChecklistAcceptance,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const autoPilotEventsReducer = createAsyncThunk(
    'events/autoPilotEvents',
    async (eventData, thunkApi) => {
        try {
            const {
                dateToCopyFrom,
                dateToCopyTo,
                numDaysToCopyToInSeries,
            } = eventData
            const response = await autoPilotEventsApiService({
                dateToCopyFrom,
                dateToCopyTo,
                numDaysToCopyToInSeries,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const getStaffAvailabilitiesReducer = createAsyncThunk(
    'events/getStaffAvailabilities',
    async (eventData, thunkApi) => {
        try {
            const { getState } = thunkApi
            const { events } = getState()
            const { searchFilters: { startDateFilter, endDateFilter } } = events
            const { staffIds } = eventData

            const response = await getEventsApiService({
                startDateRange: new Date(startDateFilter),
                endDateRange: new Date(endDateFilter),
                staffId: staffIds,
                isAvailability: true,
                isAbsense: true,
            })

            return response.data

        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const updateEventReducer = createAsyncThunk(
    'events/updateEvent',
    async (eventData, thunkApi) => {
        try {
            const {
                id,
                clientId,
                staffId,

                eventType,
                name,
                isRepeating,
                startDate,
                endDate,
                address,
                distribute,
                onlyThisEvent,

                services,
                flexibleStart,
                breakDuration,
                jobRole,
                instructions,
                notice,
                additionalStaff,
                requiredNumStaff,

                repetition,
                eventColor,
                distributeStatus,
                requiredChecklistAcceptance,
            } = eventData

            console.log(eventData)
            const response = await updateEventApiService({
                id,
                clientId,
                staffId,

                eventType,
                name,
                isRepeating,
                startDate,
                endDate,
                distribute,
                onlyThisEvent,

                services,
                flexibleStart,
                breakDuration,
                jobRole,
                instructions,
                notice,
                additionalStaff,
                requiredNumStaff,

                address: address ? {
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    region: address.region,
                    postcode: address.postcode,
                    country: address.country,
                } : null,
                repetition: repetition && isRepeating ? {
                    repetitionType: repetition.repetitionType,
                    days: repetition.days,
                    repetitionPeriod: repetition.repetitionPeriod ? {
                        value: repetition.repetitionPeriod.value,
                        every: repetition.repetitionPeriod.every,
                    } : null,
                    repetitionEndDate: repetition.repetitionEndDate,
                    repetitionEndDateType: repetition.repetitionEndDateType,
                } : null,
                eventColor,
                distributeStatus,
                requiredChecklistAcceptance,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

export const deleteEventReducer = createAsyncThunk(
    'events/deleteEvents',
    async (eventData) => {
        try {
            const {
                id,
                clientId,
                staffId,
                cancel,
                cancellationReason,
                onlyThisEvent,
            } = eventData

            const response = await deleteEventApiService({
                id,
                clientId,
                staffId,
                cancel,
                cancellationReason,
                onlyThisEvent,
            })
            return response.data
        } catch (error) {
            throw new Error(error?.data?.error?.message || "Error")
        }
    }
)

const eventsSlice = createSlice({
    name: "events",
    initialState,
    // reducers that do not require api call go here in reducers
    reducers: {
        clearAllStatusesReducer: (state, action) => {
            state.statuses = {}
        },
        clearAllLocalEventsReducer: (state, action) => {
            state.events = []
        },
        clearLocalEventReducer: (state, action) => {
            state.event = null
        },

        clearAllErrorMessagesReducer: (state, action) => {
            state.errorMessages = {}
        },
        clearAllSuccessMessagesReducer: (state) => {
            state.successMessages = {};
        },

        updateFilterStartDateReducer: (state, action) => {
            state.searchFilters.startDateFilter = action.payload
        },
        updateFilterEndDateReducer: (state, action) => {
            state.searchFilters.endDateFilter = action.payload
        },

        updateFilterClientIdReducer: (state, action) => {
            state.searchFilters.clientIdFilter = action.payload
        },
        updateFilterStaffIdReducer: (state, action) => {
            state.searchFilters.staffIdFilter = action.payload
        },

        updateIsClientVisitFilter: (state, action) => {
            state.searchFilters.isClientVisitFilter = action.payload
        },
        updateIsShiftFilter: (state, action) => {
            state.searchFilters.isShiftFilter = action.payload
        },
        updateIsAvailabilityFilter: (state, action) => {
            state.searchFilters.isAvailabilityFilter = action.payload
        },
        updateIsCustomEventFilter: (state, action) => {
            state.searchFilters.isCustomEventFilter = action.payload
        },
        updateIsAbsenseEventFilter: (state, action) => {
            state.searchFilters.isAbsenseFilter = action.payload
        },
        updateIsDistributePendingApprovalFilter: (state, action) => {
            state.searchFilters.isDistributePendingApproval = action.payload
        },

        updateSortByFilter: (state, action) => {
            state.searchFilters.sortByFilter = action.payload
        },

        clearFiltersReducer: (state, action) => {
            state.searchFilters = {}
        },
    },
    // reducers that require api calls go here in extra reducers
    extraReducers: (builder) => {
        // start handling get events async calls
        builder.addCase(getEventsReducer.pending, (state, action) => {
            state.statuses.getEventsStatus = asyncStates.PENDING
            state.errorMessages.getEventsErrorMessage = null
        })
        builder.addCase(getEventsReducer.rejected, (state, action) => {
            state.statuses.getEventsStatus = asyncStates.FAILURE
            state.errorMessages.getEventsErrorMessage = action.error?.message
        })
        builder.addCase(getEventsReducer.fulfilled, (state, action) => {
            state.statuses.getEventsStatus = asyncStates.SUCCESS
            state.errorMessages.getEventsErrorMessage = null
            state.events = action.payload.startAfter ? [...state.events, ...action.payload.data.events] : action.payload.data.events;

            if (action.payload.limit) {
                if (action.payload.data.events.length >= action.payload.limit) {
                    state.moreEventsLeftInPagination = true;
                } else {
                    state.moreEventsLeftInPagination = false;
                }
            } else {
                state.moreEventsLeftInPagination = false;
            }
        })

        builder.addCase(getEventReducer.pending, (state, action) => {
            state.statuses.getEventStatus = asyncStates.PENDING
            state.errorMessages.getEventErrorMessage = null
        })
        builder.addCase(getEventReducer.rejected, (state, action) => {
            state.statuses.getEventStatus = asyncStates.FAILURE
            state.errorMessages.getEventErrorMessage = action.error?.message
        })
        builder.addCase(getEventReducer.fulfilled, (state, action) => {
            state.statuses.getEventStatus = asyncStates.SUCCESS
            state.errorMessages.getEventErrorMessage = null
            state.event = action.payload ? { ...action.payload, endDate: action.payload.originalEndDate } : null
        })

        builder.addCase(createEventReducer.pending, (state, action) => {
            state.statuses.createEventStatus = asyncStates.PENDING
            state.errorMessages.createEventErrorMessage = null
        })
        builder.addCase(createEventReducer.rejected, (state, action) => {
            state.statuses.createEventStatus = asyncStates.FAILURE
            state.errorMessages.createEventErrorMessage = action.error?.message
        })
        builder.addCase(createEventReducer.fulfilled, (state, action) => {
            state.statuses.createEventStatus = asyncStates.SUCCESS
            state.errorMessages.createEventErrorMessage = null
            state.successMessages.createEventSuccessMessage = action.payload.message
        })

        builder.addCase(updateEventReducer.pending, (state, action) => {
            state.statuses.updateEventStatus = asyncStates.PENDING
            state.errorMessages.updateEventErrorMessage = null
        })
        builder.addCase(updateEventReducer.rejected, (state, action) => {
            state.statuses.updateEventStatus = asyncStates.FAILURE
            state.errorMessages.updateEventErrorMessage = action.error?.message
        })
        builder.addCase(updateEventReducer.fulfilled, (state, action) => {
            state.statuses.updateEventStatus = asyncStates.SUCCESS
            state.errorMessages.updateEventErrorMessage = null
            state.successMessages.updateEventSuccessMessage = action.payload.message
        })

        builder.addCase(deleteEventReducer.pending, (state, action) => {
            state.statuses.deleteEventStatus = asyncStates.PENDING
            state.errorMessages.deleteEventErrorMessage = null
        })
        builder.addCase(deleteEventReducer.rejected, (state, action) => {
            state.statuses.deleteEventStatus = asyncStates.FAILURE
            state.errorMessages.deleteEventErrorMessage = action.error?.message
        })
        builder.addCase(deleteEventReducer.fulfilled, (state, action) => {
            state.statuses.deleteEventStatus = asyncStates.SUCCESS
            state.errorMessages.deleteEventErrorMessage = null
            state.successMessages.deleteEventSuccessMessage = action.payload.message
        })

        builder.addCase(autoPilotEventsReducer.pending, (state, action) => {
            state.statuses.autoPilotEventsStatus = asyncStates.PENDING
            state.errorMessages.autoPilotEventsErrorMessage = null
        })
        builder.addCase(autoPilotEventsReducer.rejected, (state, action) => {
            state.statuses.autoPilotEventsStatus = asyncStates.FAILURE
            state.errorMessages.autoPilotEventsErrorMessage = action.error?.message
        })
        builder.addCase(autoPilotEventsReducer.fulfilled, (state, action) => {
            state.statuses.autoPilotEventsStatus = asyncStates.SUCCESS
            state.errorMessages.autoPilotEventsErrorMessage = null
            state.successMessages.autoPilotEventsSuccessMessage = action.payload.message
        })

        builder.addCase(getStaffAvailabilitiesReducer.pending, (state, action) => {
            state.statuses.getStaffAvailabilitiesStatus = asyncStates.PENDING
            state.errorMessages.getStaffAvailabilitiesErrorMessage = null
        })
        builder.addCase(getStaffAvailabilitiesReducer.rejected, (state, action) => {
            state.statuses.getStaffAvailabilitiesStatus = asyncStates.FAILURE
            state.errorMessages.getStaffAvailabilitiesErrorMessage = action.error?.message
        })
        builder.addCase(getStaffAvailabilitiesReducer.fulfilled, (state, action) => {
            state.statuses.getStaffAvailabilitiesStatus = asyncStates.SUCCESS
            state.errorMessages.getStaffAvailabilitiesErrorMessage = null
            state.successMessages.getStaffAvailabilitiesSuccessMessage = action.payload.message
            state.staffAvailabilities = action.payload.events
        })
    }
})

export const {
    updateFilterStartDateReducer,
    updateFilterEndDateReducer,
    updateFilterClientIdReducer,
    updateFilterStaffIdReducer,
    clearFiltersReducer,
    clearAllLocalEventsReducer,
    clearAllErrorMessagesReducer,
    clearAllStatusesReducer,
    clearAllSuccessMessagesReducer,
    clearLocalEventReducer,

    updateIsClientVisitFilter,
    updateIsShiftFilter,
    updateIsAvailabilityFilter,
    updateIsCustomEventFilter,
    updateIsAbsenseEventFilter,
    updateSortByFilter,
    updateIsDistributePendingApprovalFilter,
} = eventsSlice.actions

export default eventsSlice.reducer