import React, { useState } from 'react'
import { Box, ErrorAndSuccess, FlexBox, ListBox } from '../../../Components/Boxes'
import { Button, SelectBox, TextBox } from '../../../Components/Input'
import { Text } from '../../../Components/Text'
import CircularLoader from '../../../Components/Icons/circularLoader'
import { useGlobalMedicineFormsSelector } from '../../../redux/selectors/useConfigsSelectors'
import Icon from '../../../Components/Icons'
import FormDialog from '../../../Components/Modals/FormDialog'
import AddConfigMedicationsIngredientsDialog from '../Dialogs/AddConfigMedicationsIngredientsDialog';
import { useResponsive } from '../../../Components/Hooks'
import { areAllKeysEmpty, isAnyKeyEmpty } from '../../../utiles/validators'
import { DOCUMENT_STATUS } from '../../../constants'

export default function AddConfigMedicationsDialog(props) {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        medicine: editingMedicine,
        loading,
        errorMessage,
        onDelete,
        onSave,
    } = props

    const [name, setName] = useState(editingMedicine ? editingMedicine.name : null)
    const [strength, setStrength] = useState(editingMedicine ? editingMedicine.strength : null)
    const [status, setStatus] = useState(editingMedicine ? editingMedicine.status : DOCUMENT_STATUS.ACTIVE)
    const [form, setForm] = useState(editingMedicine ? editingMedicine.form : null)
    const [ingredients, setIngredients] = useState(editingMedicine ? editingMedicine.ingredients ?? [] : [])
    /**
        name: medicine.name,
        strength: medicine.strength,
        status: medicine.status,
        form: medicine.form,
        ingredients: [{
            name: ingredient?.name,
            status: ingredient?.status,
            strengthByUnit: {
                value: {
                    value: ingredient?.strengthByUnit?.value?.value,
                    units: ingredient?.strengthByUnit?.value?.units,
                },
                per: {
                    value: ingredient?.strengthByUnit?.per?.value,
                    units: ingredient?.strengthByUnit?.per?.units,
                }
            }
        }]
     */
    const medicineForms = useGlobalMedicineFormsSelector()

    const mappedMedicineFormItems = medicineForms?.map(item => ({
        value: item,
        label: item
    }));

    const [open, setOpen] = useState(false);
    const [editingIngredient, setEditingIngredient] = useState(false);
    const [ingredientErrorMessage, setIngredientErrorMessage] = useState("");

    function handleDialog(ingredient) {
        setOpen(!open)
        setEditingIngredient(ingredient)
        setIngredientErrorMessage(null)
    }

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Name
                </Text>
                <TextBox
                    $hint={"Paracetamol"}
                    $type={"text"}
                    $name={"text"}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />

                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Strength
                </Text>
                <TextBox
                    $hint={"2mg"}
                    $type={"text"}
                    $name={"text"}
                    $value={strength}
                    $onChange={(e) => setStrength(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />

                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Form
                </Text>
                <SelectBox
                    $value={form ? { value: form, label: form } : null}
                    $name={"form"}
                    $onChange={(e) => setForm(e.value)}
                    $trailing={<Icon $icon="ArrowDropDown" />}
                    $width="100%"
                    $options={mappedMedicineFormItems}
                />
                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Ingredients
                </Text>
                <Box $marginTop={2} $width="100%">
                    {ingredients?.map((ingredient, index) => {
                        let strength = ""
                        if (!areAllKeysEmpty(ingredient.strengthByUnit)) {
                            if (!areAllKeysEmpty(ingredient.strengthByUnit.value)) {
                                strength = `${ingredient.strengthByUnit.value.value ?? "1"}${ingredient.strengthByUnit.value.units ?? ""}`
                                if (!areAllKeysEmpty(ingredient.strengthByUnit.per)) {
                                    strength += ` / ${ingredient.strengthByUnit.per.value ?? "1"} ${ingredient.strengthByUnit.per.units ?? ""}`
                                }
                            }
                        }
                        return (
                            <ListBox
                                $index={index}
                                $onClick={() => {
                                    handleDialog(ingredient)
                                }}
                            >
                                <Text>
                                    {ingredient.name} {strength ? `(${strength})` : ""}
                                </Text>
                            </ListBox>
                        )
                    })}
                </Box>

                <FlexBox $width="100%" $justifyContent="flex-end" $marginTop={2} $onClick={() => {
                    handleDialog()
                }}>
                    <Icon
                        $icon="Add"
                        $size={18}
                        $pointer="pointer"
                    ></Icon>
                    <Text $fontWeight="600" $pointer="pointer">
                        Add Ingredient
                    </Text>
                </FlexBox>
            </FlexBox>

            <Box
                $padding={2}
            >
                {errorMessage && (
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                )}
            </Box>

            <FlexBox
                $justifyContent={!editingMedicine ? "flex-end" : "space-between"}
                $padding={2}
                $backgroundColor="list"
            >
                {editingMedicine && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onDelete()
                    }}
                >
                    {!loading ? 'Delete' : <CircularLoader $color="white" $size={25} />}
                </Button>}
                <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onSave({
                            name,
                            strength,
                            status,
                            form,
                            ingredients,
                        })
                    }}
                >
                    {!loading ? 'Save' : <CircularLoader $color="white" $size={25} />}
                </Button>
            </FlexBox>

            {open && <FormDialog
                $open={open}
                $handleDialog={handleDialog}
                $title={`Add Ingredient`}
                $maxWidth="sm"
                $fullWidth
                $fullScreen={!isDesktop}
            >
                <AddConfigMedicationsIngredientsDialog
                    ingredient={editingIngredient}
                    errorMessage={ingredientErrorMessage}
                    onDelete={async () => {
                        const index = ingredients.indexOf(editingIngredient)
                        if (index < 0) return handleDialog()

                        ingredients.splice(index, 1)
                        setIngredients([...ingredients])
                        handleDialog()
                    }}
                    onSave={async (val) => {
                        if (!val.name) {
                            return setIngredientErrorMessage("Please enter a valid name for the ingredient")
                        }

                        if (isAnyKeyEmpty(val)) {
                            return setIngredientErrorMessage("Please enter all the strength details")
                        }

                        let newIngredients = [...ingredients]

                        if (editingIngredient) {
                            const index = ingredients.indexOf(editingIngredient)
                            if (index < 0) return handleDialog()
                            newIngredients[index] = { ...val }
                        } else {
                            newIngredients.push({ ...val })
                        }
                        setIngredients([...newIngredients])
                        handleDialog()
                    }}
                />
            </FormDialog>}
        </>
    )
}
