import React, { useState, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import LogonWithName from "../../Images/Logos/gps_care_logo.png";
import DefaultImage from "../../Components/Images/index";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AuthContext } from "../../contextAPI's/authContext";
import BasicAlerts from "../../Components/Boxes/ErrorAndSuccess";
import { FlexBox } from "../../Components/Boxes/index";
import CircularLoader from "../../Components/Icons/circularLoader";
import { useResponsive } from "../../Components/Hooks/index";
import { Link } from "react-router-dom";
import { color } from "../../Components/Theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color("primary")};
`;

const LoginWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 10px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function SendPasswordReset() {
    const isDesktop = useResponsive({ xs: false, md: true });
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")

    //auth context
    const { loading, ConfirmPasswordReset, SendPasswordReset, } = useContext(AuthContext)

    //States
    const [values, setValues] = useState({
        email: "",
    });

    //event change func
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    //handle submit func
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage(null)
        setErrorMessage(null)
        SendPasswordReset({
            email: values.email,
            onSuccess: (msg) => {
                setSuccessMessage(msg)
            },
            onError: (err) => {
                setErrorMessage(err)
            }
        })
    };


    return (
        <Wrapper>
            <LoginWrapper>
                <DefaultImage $width={"20%"} $src={LogonWithName} $objectFit="contain" />
                <br />
                <br />
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: isDesktop ? "80%" : "90%",
                    }}
                >
                    <TextField
                        id="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange("email")}
                        label="Email"
                        required
                        fullWidth
                    />
                    <br />
                    {errorMessage && (
                        <FlexBox $marginTop={2}>
                            <BasicAlerts $type="error">{errorMessage}</BasicAlerts>
                        </FlexBox>
                    )}
                    {successMessage && (
                        <FlexBox $marginTop={2}>
                            <BasicAlerts $type="success">{successMessage}</BasicAlerts>
                        </FlexBox>
                    )}
                    <br />
                    {!loading ? (
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                        >
                            Submit
                        </Button>
                    ) : (
                        <Button variant="contained" size="large" disabled >
                            {/* {newStaffGetStatus !== asyncStates.PENDING ? 'Send Invitation Code' : <CircularLoader $color="white" $size={25} />} */}
                            <CircularLoader $color="white" $size={25} />
                        </Button>
                    )}
                    <br />
                    <Link to="/">Login</Link>
                </form>
            </LoginWrapper>
        </Wrapper>
    );
}
