import React, { useMemo, useState } from "react";
import { Box } from "../../Components/Boxes";
import { BarChart } from "../../Components/Charts";
import { Grid, GridContainer } from "../../Components/Grid";
import CalendarControlls from "../../Components/Calendar/ScheduleCalendar/CalendarControlls";
import { EVENT_ANALYTICS_EVENT_TYPE, REPETITION_PERIODS, USER_ANALYTICS_TYPE } from "../../constants";
import { useEffect } from "react";
import useAnalyticsDispatchers from "../../redux/dispatchers/useAnalyticsDispatchers";
import {
  useEventAnalyticsSelector,
  useUserAnalyticsSelector,
  useGetEventAnalyticsStatusSelector,
  useGetUserAnalyticsStatusSelector,
} from "../../redux/selectors/useAnalyticsSelectors"

const Analytics = () => {
  const [selectedCalendarView, setSelectedCalendarView] = useState(REPETITION_PERIODS.MONTH)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const eventAnalytics = useEventAnalyticsSelector()
  const userAnalytics = useUserAnalyticsSelector()

  const newStaff = useMemo(() => {
    return userAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalNewStaff,
      }
    })
  }, [userAnalytics]);
  const newClients = useMemo(() => {
    return userAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalNewClients,
      }
    })
  }, [userAnalytics]);

  const deletedStaff = useMemo(() => {
    return userAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalArchivedStaff,
      }
    })
  }, [userAnalytics]);

  const deletedClients = useMemo(() => {
    return userAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalArchivedClients,
      }
    })
  }, [userAnalytics]);

  const completedTime = useMemo(() => {
    return eventAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalCompletedTime,
      }
    })
  }, [eventAnalytics]);

  const completedVisits = useMemo(() => {
    return eventAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalCompletedEvents,
      }
    })
  }, [eventAnalytics]);

  const cancelledVisits = useMemo(() => {
    return eventAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalCancelledEvents,
      }
    })
  }, [eventAnalytics]);

  const missedVisits = useMemo(() => {
    return eventAnalytics?.map(analytic => {
      return {
        label: analytic.label,
        value: analytic.analytics.totalMissedEvents,
      }
    })
  }, [eventAnalytics]);

  const {
    getEventAnalytics,
    getUserAnalytics,
  } = useAnalyticsDispatchers()

  const getEventAnalyticsStatus = useGetEventAnalyticsStatusSelector()
  const getUserAnalyticsStatus = useGetUserAnalyticsStatusSelector()

  const status = useMemo(() => {
    return getEventAnalyticsStatus || getUserAnalyticsStatus
  }, [getEventAnalyticsStatus, getUserAnalyticsStatus])

  useEffect(() => {
    getEventAnalytics({
      selectedDate: selectedDate,
      repetitionPeriods: selectedCalendarView,
    })
    getUserAnalytics({
      selectedDate: selectedDate,
      repetitionPeriods: selectedCalendarView,
    })
  }, [selectedCalendarView, selectedDate])

  return (
    <>
      <GridContainer $spacing={3}>
        {/* <Grid $xs={12} $sm={6} $md={8}></Grid> */}
        <Grid $xs={12}>
          <Box
            $marginLeft="auto"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
            $paddingLeft="5"
            $paddingRight="5"
          >
            <CalendarControlls
              // hideViews={true}
              availableViews={[REPETITION_PERIODS.DAY, REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH, REPETITION_PERIODS.YEAR]}
              currentView={selectedCalendarView}
              date={selectedDate}
              onViewChanged={(view) => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                setSelectedDate(newDate)
                setSelectedCalendarView(view)
              }}
              onPrev={() => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                  newDate.setDate(newDate.getDate() - 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                  newDate.setDate(newDate.getDate() - 7)
                } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                  newDate.setMonth(newDate.getMonth() - 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                  newDate.setFullYear(newDate.getFullYear() - 1)
                }
                setSelectedDate(newDate)
              }}
              onNext={() => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                  newDate.setDate(newDate.getDate() + 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                  newDate.setDate(newDate.getDate() + 7)
                } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                  newDate.setMonth(newDate.getMonth() + 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                  newDate.setFullYear(newDate.getFullYear() + 1)
                }
                setSelectedDate(newDate)
              }}
            />
          </Box>
        </Grid>
      </GridContainer>
      <Box $marginTop="5" />

      <GridContainer $spacing={3}>
        <Grid $xs={12}>
          {/* Completed time */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Completed time (Mins)" units="Mins" data={completedTime} />
          </Box>
        </Grid>
      </GridContainer>

      <Box $marginTop="5" />

      <GridContainer $spacing={3}>
        <Grid $xs={12} $sm={4}>
          {/* Completed visits */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Total completed events" data={completedVisits} />
          </Box>
        </Grid>
        <Grid $xs={12} $sm={4}>
          {/* Cancelled visits */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Total cancelled events" data={cancelledVisits} />
          </Box>
        </Grid>
        <Grid $xs={12} $sm={4}>
          {/* Missed visits */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Total missed events" data={missedVisits} />
          </Box>
        </Grid>
      </GridContainer>

      <Box $marginTop="5" />

      <GridContainer $spacing={3}>
        <Grid $xs={12} $sm={6}>
          {/* New clients */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="New Clients" data={newClients} />
          </Box>
        </Grid>
        <Grid $xs={12} $sm={6}>
          {/* New staff */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="New Staff" data={newStaff} />
          </Box>
        </Grid>
      </GridContainer>

      <Box $marginTop="5" />

      <GridContainer $spacing={3}>
        <Grid $xs={12} $sm={6}>
          {/* Deleted clients */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Archived Clients" data={deletedClients} />
          </Box>
        </Grid>
        <Grid $xs={12} $sm={6}>
          {/* Deleted staff */}
          <Box
            $width="100%"
            $height="50vh"
            $backgroundColor="#fff"
            $borderRadius="10px"
            $justifyContent="center"
            $alignItems="center"
          >
            <BarChart title="Archived Staff" data={deletedStaff} />
          </Box>
        </Grid>
      </GridContainer>

      <Box $marginTop="5" />
    </>
  );
};

export default Analytics;
