import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetCareplansErrorMessageSelector, useGetCareplansStatusSelector, useCareplansSelector, useCareplansUpdateStatusSelector, useUpdateCareplansErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useCareplansSettingsController() {
    const { accessAddSettingsCareplans, accessEditSettingsCareplans } = usePermissionsController()
    const careplanNames = useCareplansSelector()
    const getCareplansErrorMessages = useGetCareplansErrorMessageSelector()
    const updateCareplansErrorMessages = useUpdateCareplansErrorMessageSelector()

    const getCareplansStatuses = useGetCareplansStatusSelector()
    const updateCareplansStatuses = useCareplansUpdateStatusSelector()

    const names = useMemo(() => [...careplanNames], [careplanNames])

    const {
        updateCareplans,
        getCareplans,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getCareplans()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateCareplans({
            careplans: newNames
        }).unwrap();
        getCareplans()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateCareplans({
            careplans: newNames
        }).unwrap();
        getCareplans()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateCareplans({
            careplans: newNames
        }).unwrap();
        getCareplans()
        return
    }

    return {
        title: "Careplans",
        names: names,

        canEdit: accessEditSettingsCareplans,
        canAdd: accessAddSettingsCareplans,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateCareplansStatuses == asyncStates.PENDING,
        isDeletingConfig: updateCareplansStatuses == asyncStates.PENDING,
        isGettingConfigs: getCareplansStatuses == asyncStates.PENDING,

        updateErrorMessage: updateCareplansErrorMessages,
        deleteErrorMessage: updateCareplansErrorMessages,
        gettingErrorMessage: getCareplansErrorMessages,
    }
}