import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { FlexBox } from "../Boxes";
import Icon from "../Icons";
import { DialogActions, DialogContent } from "@mui/material";

export default function FormDialog({
  $open,
  $handleDialog,
  $maxWidth,
  $fullWidth,
  $style,
  children,
  $title,
  $fullScreen,
  $disableHeader,
  $headerColor,
}) {
  return (
    <div>
      <Dialog
        open={$open}
        onClose={$handleDialog}
        maxWidth={$maxWidth}
        fullWidth={$fullWidth}
        style={$style}
        fullScreen={$fullScreen}
      >
        {!$disableHeader && <FlexBox
          $justifyContent="space-between"
          $backgroundColor={$headerColor ?? "primary"}
          $height="45px"
        >
          <DialogTitle style={{ color: "white" }}>{$title}</DialogTitle>
          <Icon
            $icon="Close"
            $color="white"
            $paddingRight={2}
            $onClick={() => $handleDialog()}
            $pointer="pointer"
            $style
          />
        </FlexBox>}
        <DialogContent style={{ padding: 0 }}>
          {children}
        </DialogContent>
        {/* <DialogActions>  </DialogActions> */}
      </Dialog>
    </div>
  );
}
