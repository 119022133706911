import { useDispatch } from "react-redux"
import {
    createFileReducer,
    deleteFileReducer,
    getFilesReducer,
    getFileReducer,
    updateFileReducer,
    clearAllLocalFilesReducer,
    clearAllErrorMessagesReducer,
    restoreArchivedFileReducer,
} from "../../slices/fileSlices"

const useFileDispathers = () => {
    const dispatch = useDispatch()

    const createFile = (data) => {
        return dispatch(createFileReducer(data))
    }

    const restoreArchivedFile = (data) => {
        return dispatch(restoreArchivedFileReducer(data))
    }

    const updateFile = (data) => {
        return dispatch(updateFileReducer(data))
    }

    const deleteFile = (data) => {
        return dispatch(deleteFileReducer(data))
    }

    const getFiles = (data) => {
        return dispatch(getFilesReducer(data))
    }

    const getFile = (data) => {
        return dispatch(getFileReducer(data))
    }

    const clearAllLocalFiles = (data) => {
        return dispatch(clearAllLocalFilesReducer(data))
    }

    const clearAllErrorMessages = (data) => {
        return dispatch(clearAllErrorMessagesReducer(data))
    }

    return {
        restoreArchivedFile,
        createFile,
        updateFile,
        deleteFile,
        getFiles,
        getFile,
        clearAllLocalFiles,
        clearAllErrorMessages,
    }

}

export default useFileDispathers