import React, { useEffect, useMemo, useState } from "react"
import { Box } from "../../Components/Boxes"
import CalendarControlls from "../../Components/Calendar/ScheduleCalendar/CalendarControlls"
import { REPETITION_PERIODS } from "../../constants"
import { useEventAnalyticsSelector, useGetEventAnalyticsStatusSelector, useGetUserAnalyticsStatusSelector, useUserAnalyticsSelector } from "../../redux/selectors/useAnalyticsSelectors"
import useAnalyticsDispatchers from "../../redux/dispatchers/useAnalyticsDispatchers"
import { Grid, GridContainer } from "../../Components/Grid"
import { BarChart } from "../../Components/Charts"

const UserAnalytics = ({ staffId, clientId }) => {
    const [selectedCalendarView, setSelectedCalendarView] = useState(REPETITION_PERIODS.MONTH)
    const [selectedDate, setSelectedDate] = useState(new Date())

    const eventAnalytics = useEventAnalyticsSelector()
    const userAnalytics = useUserAnalyticsSelector()

    const {
        getEventAnalytics,
        getUserAnalytics,
    } = useAnalyticsDispatchers()

    const getEventAnalyticsStatus = useGetEventAnalyticsStatusSelector()
    const getUserAnalyticsStatus = useGetUserAnalyticsStatusSelector()

    const completedTime = useMemo(() => {
        return eventAnalytics?.map(analytic => {
            return {
                label: analytic.label,
                value: analytic.analytics.totalCompletedTime,
            }
        })
    }, [eventAnalytics]);

    const completedVisits = useMemo(() => {
        return eventAnalytics?.map(analytic => {
            return {
                label: analytic.label,
                value: analytic.analytics.totalCompletedEvents,
            }
        })
    }, [eventAnalytics]);

    const cancelledVisits = useMemo(() => {
        return eventAnalytics?.map(analytic => {
            return {
                label: analytic.label,
                value: analytic.analytics.totalCancelledEvents,
            }
        })
    }, [eventAnalytics]);

    const missedVisits = useMemo(() => {
        return eventAnalytics?.map(analytic => {
            return {
                label: analytic.label,
                value: analytic.analytics.totalMissedEvents,
            }
        })
    }, [eventAnalytics]);

    const status = useMemo(() => {
        return getEventAnalyticsStatus || getUserAnalyticsStatus
    }, [getEventAnalyticsStatus, getUserAnalyticsStatus])

    useEffect(() => {
        getEventAnalytics({
            selectedDate: selectedDate,
            repetitionPeriods: selectedCalendarView,
            staffId: staffId,
            clientId: clientId
        })
    }, [selectedCalendarView, selectedDate])

    return (
        <Box
            $width="100%"
            $backgroundColor="#fff"
        >
            <CalendarControlls
                // hideViews={true}
                availableViews={[REPETITION_PERIODS.DAY, REPETITION_PERIODS.WEEK, REPETITION_PERIODS.MONTH, REPETITION_PERIODS.YEAR]}
                currentView={selectedCalendarView}
                date={selectedDate}
                onViewChanged={(view) => {
                    const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                    setSelectedDate(newDate)
                    setSelectedCalendarView(view)
                }}
                onPrev={() => {
                    const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                    if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                        newDate.setDate(newDate.getDate() - 1)
                    } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                        newDate.setDate(newDate.getDate() - 7)
                    } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                        newDate.setMonth(newDate.getMonth() - 1)
                    } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                        newDate.setFullYear(newDate.getFullYear() - 1)
                    }
                    setSelectedDate(newDate)
                }}
                onNext={() => {
                    const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                    if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                        newDate.setDate(newDate.getDate() + 1)
                    } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                        newDate.setDate(newDate.getDate() + 7)
                    } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                        newDate.setMonth(newDate.getMonth() + 1)
                    } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                        newDate.setFullYear(newDate.getFullYear() + 1)
                    }
                    setSelectedDate(newDate)
                }}
            />
            <Box $marginTop="5" />

            <GridContainer $spacing={3}>
                <Grid $xs={12} $sm={6}>
                    {/* Completed time */}
                    <Box
                        $width="100%"
                        $height="50vh"
                        $backgroundColor="#fff"
                        $borderRadius="10px"
                        $justifyContent="center"
                        $alignItems="center"
                    >
                        <BarChart title="Completed time (Mins)" units="Mins" data={completedTime} />
                    </Box>
                </Grid>

                <Box $marginTop="5" />

                <Grid $xs={12} $sm={6}>
                    {/* Completed visits */}
                    <Box
                        $width="100%"
                        $height="50vh"
                        $backgroundColor="#fff"
                        $borderRadius="10px"
                        $justifyContent="center"
                        $alignItems="center"
                    >
                        <BarChart title="Total completed events" data={completedVisits} />
                    </Box>
                </Grid>
                <Grid $xs={12} $sm={6}>
                    {/* Cancelled visits */}
                    <Box
                        $width="100%"
                        $height="50vh"
                        $backgroundColor="#fff"
                        $borderRadius="10px"
                        $justifyContent="center"
                        $alignItems="center"
                    >
                        <BarChart title="Total cancelled events" data={cancelledVisits} />
                    </Box>
                </Grid>
                <Grid $xs={12} $sm={6}>
                    {/* Missed visits */}
                    <Box
                        $width="100%"
                        $height="50vh"
                        $backgroundColor="#fff"
                        $borderRadius="10px"
                        $justifyContent="center"
                        $alignItems="center"
                    >
                        <BarChart title="Total missed events" data={missedVisits} />
                    </Box>
                </Grid>
            </GridContainer>

            <Box $marginTop="5" />
        </Box>
    )
}

export default UserAnalytics