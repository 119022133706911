import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalTeamsReducer,
  createTeamReducer,
  deleteTeamReducer,
  filterByTeamReducer,
  getTeamReducer,
  getTeamsReducer,
  updateTeamReducer,
} from "../../slices/teamSlice";

const useTeamDispathers = () => {
  const dispatch = useDispatch();

  const createTeam = (data) => {
    return dispatch(createTeamReducer(data));
  };

  const updateTeam = (data) => {
    return dispatch(updateTeamReducer(data));
  };

  const deleteTeam = (data) => {
    return dispatch(deleteTeamReducer(data));
  };

  const getTeams = (data) => {
    return dispatch(getTeamsReducer(data));
  };

  const getTeam = (data) => {
    return dispatch(getTeamReducer(data));
  };

  const filterByTeam = (data) => {
    return dispatch(filterByTeamReducer(data));
  };

  const clearAllLocalTeams = (data) => {
    return dispatch(clearAllLocalTeamsReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  return {
    createTeam,
    updateTeam,
    deleteTeam,
    getTeams,
    getTeam,
    filterByTeam,
    clearAllLocalTeams,
    clearAllErrorMessages,
  };
};

export default useTeamDispathers;
