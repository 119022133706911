//the below code will generate this formate of Time 12:05
//this function will add zero to time when their value is 0-9
function addingZero(value) {
    return ("0" + value).slice(-2);
}
export const formateTimeMinutesToTimeTextboxFormat = (timeToBeChanged) => {
    const hours = Math.floor(timeToBeChanged / 60)
    const minutes = timeToBeChanged - (hours * 60)
    return `${addingZero(hours)}:${addingZero(minutes)}`
};

export const formateTimeTextboxFormatToTimeMinutes = (timeToBeChanged) => {
    const splitTime = timeToBeChanged.split(":")
    return (Number(splitTime[0]) * 60) + Number(splitTime[1])
};

export const getHoursAndMinutesFromMinutes = (timeToBeChanged) => {
    const hours = Math.floor(timeToBeChanged / 60)
    const minutes = timeToBeChanged - (hours * 60)
    return {hours, minutes}
};

export const formatteMinutesToReadableFormat = (timeToBeChanged) => {
    const {hours, minutes} = getHoursAndMinutesFromMinutes(timeToBeChanged)
    if (hours <= 0) return `${minutes}m`
    return `${hours}h ${minutes}m`
}