import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createClientAccessApiService,
  deleteClientAccessApiService,
  getAllClientAccessApiService,
  resendClientAccessInviteApiService,
} from "../../../services/applicationBackendApis/clientAccess";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  clientAccess: [],
  clientAccess: null,
  moreClientAccessItemsLeftInPagination: false,
  errorMessages: {
    createClientAccessErrorMessage: null,
    deleteClientAccessErrorMessage: null,
    getClientAccessErrorMessage: null,
    resendClientAccessErrorMessage: null,
  },
  statuses: {
    createClientAccessStatus: null,
    deleteClientAccessStatus: null,
    getClientAccessStatus: null,
    resendClientAccessStatus: null,
  },
  sucessMessages: {},
};

//------- getting all clientAccess from database ------------\\
export const getAllClientAccessReducer = createAsyncThunk(
  "ClientAccess/getAllClientAccess",
  async (clientAccessData) => {
    try {
      const response = await getAllClientAccessApiService({
        clientId: clientAccessData.clientId,
        startAfter: clientAccessData.startAfter,
        limit: clientAccessData.limit ?? PAGINATION_LIMITS.CLIENT_ACCESS,
        isArchived: clientAccessData.isArchived,
      });
      return {
        data: response.data,
        limit: clientAccessData.limit ?? PAGINATION_LIMITS.CLIENT_ACCESS,
        startAfter: clientAccessData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------------- creating new risk Assessment ---------------\\
export const createClientAccessReducer = createAsyncThunk(
  "clientAccess/createClientAccessReducer",
  async (clientAccessData) => {
    try {
      const response = await createClientAccessApiService({
        clientId: clientAccessData.clientId,
        name: clientAccessData.name,
        email: clientAccessData.email,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------------- resend client access ---------------\\
export const resendClientAccessReducer = createAsyncThunk(
  "clientAccess/resendClientAccessReducer",
  async (clientAccessData) => {
    try {
      const response = await resendClientAccessInviteApiService({
        clientAccessId: clientAccessData.clientAccessId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const deleteClientAccessReducer = createAsyncThunk(
  "clientAccess/deleteClientAccessReducer",
  async (clientAccessData) => {
    try {
      const response = await deleteClientAccessApiService({
        clientId: clientAccessData.clientId,
        clientAccessId: clientAccessData.clientAccessId,
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const clientAccessSlice = createSlice({
  name: "clientAccessSlice",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalClientAccessReducer: (state, action) => {
      state.clientAccess = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.sucessMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createClientAccessReducer.pending, (state, action) => {
      state.statuses.createClientAccessStatus = asyncStates.PENDING;
      state.errorMessages.createClientAccessErrorMessage = null;
    });
    builder.addCase(createClientAccessReducer.rejected, (state, action) => {
      state.statuses.createClientAccessStatus = asyncStates.FAILURE;
      state.errorMessages.createClientAccessErrorMessage = action.error?.message;
    });
    builder.addCase(createClientAccessReducer.fulfilled, (state, action) => {
      state.statuses.createClientAccessStatus = asyncStates.SUCCESS;
      state.errorMessages.createClientAccessErrorMessage = null;
    });

    // start handling resend file async calls
    builder.addCase(resendClientAccessReducer.pending, (state, action) => {
      state.statuses.resendClientAccessStatus = asyncStates.PENDING;
      state.errorMessages.resendClientAccessErrorMessage = null;
    });
    builder.addCase(resendClientAccessReducer.rejected, (state, action) => {
      state.statuses.resendClientAccessStatus = asyncStates.FAILURE;
      state.errorMessages.resendClientAccessErrorMessage = action.error?.message;
    });
    builder.addCase(resendClientAccessReducer.fulfilled, (state, action) => {
      state.statuses.resendClientAccessStatus = asyncStates.SUCCESS;
      state.errorMessages.resendClientAccessErrorMessage = null;
    });

    // start handling get files async calls
    builder.addCase(getAllClientAccessReducer.pending, (state, action) => {
      state.statuses.getClientAccessStatus = asyncStates.PENDING;
      state.errorMessages.getClientAccessErrorMessage = null;
    });
    builder.addCase(getAllClientAccessReducer.rejected, (state, action) => {
      state.statuses.getClientAccessStatus = asyncStates.FAILURE;
      state.errorMessages.getClientAccessErrorMessage = action.error?.message;
    });
    builder.addCase(getAllClientAccessReducer.fulfilled, (state, action) => {
      state.statuses.getClientAccessStatus = asyncStates.SUCCESS;
      state.errorMessages.getClientAccessErrorMessage = null;
      state.clientAccess = action.payload.startAfter ? [...state.clientAccess, ...action.payload.data.clientAccess] : action.payload.data.clientAccess;

      if (action.payload.data.clientAccess.length >= action.payload.limit) {
        state.moreClientAccessItemsLeftInPagination = true;
      } else {
        state.moreClientAccessItemsLeftInPagination = false;
      }
    });

    // start handling delete file async calls
    builder.addCase(deleteClientAccessReducer.pending, (state, action) => {
      state.statuses.deleteClientAccessStatus = asyncStates.PENDING;
      state.errorMessages.deleteClientAccessErrorMessage = null;
    });
    builder.addCase(deleteClientAccessReducer.rejected, (state, action) => {
      state.statuses.deleteClientAccessStatus = asyncStates.FAILURE;
      state.errorMessages.deleteClientAccessErrorMessage = action.error?.message;
    });
    builder.addCase(deleteClientAccessReducer.fulfilled, (state, action) => {
      state.statuses.deleteClientAccessStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteClientAccessErrorMessage = null;
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllLocalClientAccessReducer,
  clearAllSuccessMessagesReducer,
} = clientAccessSlice.actions;

export default clientAccessSlice.reducer;
