import { useEffect } from "react"
import { useDiariesSelector, useDiariesGetStatusSelector, useMoreDirayItemsLeftInPaginationSelector, useDiaryCreateStatusSelector, useDiaryCreateErrorMessageSelector } from "../../../../redux/selectors/useStaffDiarySelector"
import useStaffDiaryDispatchers from "../../../../redux/dispatchers/useStaffDiaryDispatchers"

export function useStaffDiaryController(staffId) {
    const diaries = useDiariesSelector()
    const getDiariesState = useDiariesGetStatusSelector()

    const createDiaryErrorMessage = useDiaryCreateErrorMessageSelector()
    const createDiaryState = useDiaryCreateStatusSelector()

    const { getDiaries, clearAllErrorMessages, clearAllLocalDiaries, clearAllStatuses, createDiary } = useStaffDiaryDispatchers()

    const moreDirayItemsLeftInPaginationSelector = useMoreDirayItemsLeftInPaginationSelector()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllLocalDiaries()
        clearAllStatuses()
        clearAllLocalDiaries()
        getDiaries({
            staffId: staffId,
        })
    }, [])

    const loadMore = () => {
        getDiaries({
            staffId: staffId,
            startAfter: diaries[diaries.length - 1].id
        })
    }

    const createDiaryEntry = async ({ note, users, }) => {
        await createDiary({
            notes: note,
            additionalStaff: users,
            staffId: staffId,
        }).unwrap()
        getDiaries({
            staffId: staffId,
        })
    }

    return {
        diaries,
        getDiariesState,
        isStaff: true,
        isClient: false,
        moreDirayItemsLeftInPaginationSelector,
        
        loadMore,

        createDiaryEntry,
        createDiaryState,
        createDiaryErrorMessage,
    }
}