import moment from "moment";
import { formatteMinutesToReadableFormat } from "./timeformate";

//the below code will generate this formate of Date "12/31/2022 20:00"
export const formateDateToReadableFormatWithTime = (date) => {
  return moment(new Date(date)).format("DD/MM/YYYY HH:mm") //.toLocaleDateString();
};

//the below code will generate this formate of Date "12/31/2022"
export const formateDateToReadableFormatWithoutTime = (date) => {
  return moment(new Date(date)).format("DD/MM/YYYY") //.toLocaleDateString();
};

//the below code will generate this formate of Date "20:00"
export const formateDateToTime = (date) => {
  return moment(new Date(date)).format("HH:mm") //.toLocaleDateString();
}

export const formatteDateToDayOfWeek = (date) => {
  return moment(new Date(date)).format("ddd")
}

//the below code will generate this formate of Date "2023-01-10T00:00:00.000Z"
export const formateDateToISOString = (date) => {
  return new Date(date).toISOString();
};

//the below code will generate this formate of Date  2022-11-22
//this function will add zero to month or day when their value is 0-9
export function addingZero(value) {
  return ("0" + value).slice(-2);
}

export const formateDateToDateTextboxFormat = (dateToBeChanged) => {
  let dateObj = new Date(dateToBeChanged);
  let month = addingZero(dateObj.getMonth() + 1);
  let year = dateObj.getFullYear();
  let date = addingZero(dateObj.getDate());
  return `${year}-${month}-${date}`;
};

export const formateDateTextboxFormatToDate = (dateToBeChanged) => {
  const splitDate = dateToBeChanged.split("-")
  return new Date(Number(splitDate[0]), Number(splitDate[1]) - 1, Number(splitDate[2]))
};

export const getDurationOfTwoDatesText = (startDate, endDate) => {
  return formatteMinutesToReadableFormat(Math.round((endDate.getTime() - startDate.getTime()) / 60000))
  // return `${Math.round((endDate.getTime() - startDate.getTime()) / 60000)} Min`
}

export const returnNewDateObjectFromDate = (date) => {
  return new Date(`${date}`);
}

export const getAllDatesInAMonth = (date) => {
  const processedDates = []
  const currentDate = new Date(date.getFullYear(), date.getMonth())
  while (currentDate.getMonth() == date.getMonth()) {
    processedDates.push(returnNewDateObjectFromDate(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return processedDates
}

export const getPreviousMondayDate = (date) => {
  const prevMonday = new Date(`${date}`);
  prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
  prevMonday.setHours(0)
  prevMonday.setMinutes(0)
  prevMonday.setSeconds(0)
  prevMonday.setMilliseconds(0)
  return prevMonday
}

export const getNextSundayDate = (date) => {
  const prevMonday = getPreviousMondayDate(date)
  const nextSunday = new Date(`${prevMonday}`);
  nextSunday.setDate(nextSunday.getDate() + 6)
  nextSunday.setHours(23)
  nextSunday.setMinutes(59)
  nextSunday.setSeconds(59)
  nextSunday.setMilliseconds(59)
  return nextSunday
}

// [{ start, end }]
export const getDaysNotWorkingFromWorkingHours = ({ workingHoursList, start, end }) => {
  const processedBgEvents = []
  const processedBgEventsByDate = {}
  for (let i = 0; i < workingHoursList.length; i++) {
    const eventStart = workingHoursList[i].start
    const dateString = `${eventStart.getDate()}-${eventStart.getMonth()}-${eventStart.getFullYear()}`
    if (processedBgEventsByDate[dateString]) {
      processedBgEventsByDate[dateString].push(workingHoursList[i])
    } else {
      processedBgEventsByDate[dateString] = [workingHoursList[i]]
    }
  }

  const prevMonday = new Date(`${start}`);
  prevMonday.setHours(0)
  prevMonday.setMinutes(0)
  prevMonday.setSeconds(0)
  prevMonday.setMilliseconds(0)

  console.log(start, end)

  // const length = Math.floor(end.getDate() - start.getDate()) + 1
  const length = Math.floor((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1

  for (let i = 0; i < length; i++) {
    const dateString = `${prevMonday.getDate()}-${prevMonday.getMonth()}-${prevMonday.getFullYear()}`
    const workingHours = processedBgEventsByDate[dateString] ?? []
    workingHours.reverse()
    let currentDate = new Date(`${prevMonday}`)

    while (workingHours.length > 0) {
      const e = workingHours.pop()
      processedBgEvents.push({
        start: new Date(`${currentDate}`),
        end: e.start
      })
      currentDate = new Date(`${e.end}`)
    }

    const finalEnd = new Date(`${currentDate}`)
    finalEnd.setHours(23)
    finalEnd.setMinutes(59)

    processedBgEvents.push({
      start: new Date(`${currentDate}`),
      end: finalEnd,
    })

    prevMonday.setDate(prevMonday.getDate() + 1)
  }

  return processedBgEvents
}

export const splitStartAndEndDateIntoMultipleDays = ({ start, end }) => {
  const processedBgEvents = []
  const processedBgEventsByDate = {}
  const prevMonday = new Date(`${start}`);

  const length = end.getDate() != start.getDate() ? Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1 : 1
  
  for (let i = 0; i < length; i++) {
    const dateString = `${prevMonday.getDate()}-${prevMonday.getMonth()}-${prevMonday.getFullYear()}`
    processedBgEventsByDate[dateString] = {
      start: new Date(`${prevMonday}`),
      end: new Date(`${prevMonday}`)
    }
    prevMonday.setDate(prevMonday.getDate() + 1)
  }

  const keys = Object.keys(processedBgEventsByDate)
  
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const e = processedBgEventsByDate[key]
    const finalStart = new Date(`${e.start}`)
    if (i > 0) {
      finalStart.setHours(0)
      finalStart.setMinutes(0)
      finalStart.setSeconds(0)
    }
    const finalEnd = new Date(`${e.end}`)
    if (i < keys.length - 1) {
      finalEnd.setHours(23)
      finalEnd.setMinutes(59)
      finalEnd.setSeconds(59)
    } else {
      finalEnd.setHours(end.getHours())
      finalEnd.setMinutes(end.getMinutes())
      finalEnd.setSeconds(end.getSeconds()) 
    }
    processedBgEvents.push({
      start: finalStart,
      end: finalEnd,
    })
  }

  return processedBgEvents
}

export const isDateInPast = (date) => {
  return new Date(date) < new Date()
}


export const millisecondsToMinutes = (milliseconds) => {
  return milliseconds / 60000
}