import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  getAllEmailListReducer,
  createEmailListReducer,
  updateEmailListReducer,
  deleteEmailListReducer,
  clearAllLocalEmailListReducer,
} from "../../slices/emailListSlice";

const useEmailListDispathers = () => {
  const dispatch = useDispatch();

  const createEmailList = (data) => {
    return dispatch(createEmailListReducer(data));
  };

  const updateEmailList = (data) => {
    return dispatch(updateEmailListReducer(data));
  };

  const getAllEmailList = (data) => {
    return dispatch(getAllEmailListReducer(data));
  };

  const deleteEmailList = (data) => {
    return dispatch(deleteEmailListReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllLocalEmailList = (data) => {
    return dispatch(clearAllLocalEmailListReducer(data));
  };

  return {
    getAllEmailList,
    createEmailList,
    updateEmailList,
    deleteEmailList,
    clearAllErrorMessages,
    clearAllLocalEmailList,
  };
};

export default useEmailListDispathers;
