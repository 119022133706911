import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as asyncStates from "../../constants/asyncStates";
import {
  createEmailListApiService,
  deleteEmailListApiService,
  getAllEmailListApiService,
  updateEmailListApiService,
} from "../../../services/applicationBackendApis/emailList";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  allEmailList: [],
  singleEmailList: null,
  moreEmailListItemsLeftInPagination: false,
  errorMessages: {
    createEmailListErrorMessage: null,
    updateEmailListErrorMessage: null,
    deleteEmailListErrorMessage: null,
    getAllEmailListErrorMessage: null,
    getSingleEmailListErrorMessage: null,
  },
  statuses: {
    createEmailListStatus: null,
    updateEmailListStatus: null,
    deleteEmailListStatus: null,
    getAllEmailListStatus: null,
    getSingleEmailListStatus: null,
  },
};

//---------------- to get all the emailList from database ------------
export const getAllEmailListReducer = createAsyncThunk(
  "emailList/getAllEmailList",
  async (emailListData) => {
    try {
      const response = await getAllEmailListApiService({
        staffId: emailListData.staffId,
        clientId: emailListData.clientId,
        startAfter: emailListData.startAfter,
        limit: emailListData.limit ?? PAGINATION_LIMITS.EMAIL_LIST,
        isArchived: emailListData.isArchived,
      });
      return {
        data: response.data,
        limit: emailListData.limit ?? PAGINATION_LIMITS.EMAIL_LIST,
        startAfter: emailListData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// ------------create compilance ------------------
export const createEmailListReducer = createAsyncThunk(
  "emailList/createEmailList",
  async (emailListData, thunkApi) => {
    try {
      const response = await createEmailListApiService({
        staffId: emailListData.staffId,
        clientId: emailListData.clientId,
        notes: emailListData.notes,
        email: emailListData.email,
        purpose: emailListData.purpose ? {
          id: emailListData.purpose.id,
          name: emailListData.purpose.name,
        } : null,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateEmailListReducer = createAsyncThunk(
  "emailList/updateEmailList",
  async (emailListData, thunkApi) => {
    try {
      const response = await updateEmailListApiService({
        emailListId: emailListData.emailListId,
        staffId: emailListData.staffId,
        clientId: emailListData.clientId,
        notes: emailListData.notes,
        email: emailListData.email,
        purpose: emailListData.purpose ? {
          id: emailListData.purpose.id,
          name: emailListData.purpose.name,
        } : null,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

// -------------deleting emailList ---------------------
export const deleteEmailListReducer = createAsyncThunk(
  "emailList/deleteEmailList",
  async (emailListData) => {
    try {
      const response = await deleteEmailListApiService({
        staffId: emailListData.staffId,
        clientId: emailListData.clientId,
        emailListId: emailListData.emailListId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const emailListSlice = createSlice({
  name: "emailList",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalEmailListReducer: (state, action) => {
      state.allEmailList = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling get files async calls
    builder.addCase(getAllEmailListReducer.pending, (state, action) => {
      state.statuses.getAllEmailListStatus = asyncStates.PENDING;
      state.errorMessages.getAllEmailListErrorMessage = null;
    });
    builder.addCase(getAllEmailListReducer.rejected, (state, action) => {
      state.statuses.getAllEmailListStatus = asyncStates.FAILURE;
      state.errorMessages.getAllEmailListErrorMessage = action.error?.message;
    });
    builder.addCase(getAllEmailListReducer.fulfilled, (state, action) => {
      state.statuses.getAllEmailListStatus = asyncStates.SUCCESS;
      state.errorMessages.getAllEmailListErrorMessage = null;
      state.allEmailList = action.payload.startAfter ? [...state.allEmailList, ...action.payload.data.emailList] : action.payload.data.emailList;

      if (action.payload.data.emailList.length >= action.payload.limit) {
        state.moreEmailListItemsLeftInPagination = true;
      } else {
        state.moreEmailListItemsLeftInPagination = false;
      }
    });

    // start handling create file async calls
    builder.addCase(createEmailListReducer.pending, (state, action) => {
      state.statuses.createEmailListStatus = asyncStates.PENDING;
      state.errorMessages.createEmailListErrorMessage = null;
    });
    builder.addCase(createEmailListReducer.rejected, (state, action) => {
      state.statuses.createEmailListStatus = asyncStates.FAILURE;
      state.errorMessages.createEmailListErrorMessage = action.error?.message;
    });
    builder.addCase(createEmailListReducer.fulfilled, (state, action) => {
      state.statuses.createEmailListStatus = asyncStates.SUCCESS;
      state.errorMessages.createEmailListErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateEmailListReducer.pending, (state, action) => {
      state.statuses.updateEmailListStatus = asyncStates.PENDING;
      state.errorMessages.updateEmailListErrorMessage = null;
    });
    builder.addCase(updateEmailListReducer.rejected, (state, action) => {
      state.statuses.updateEmailListStatus = asyncStates.FAILURE;
      state.errorMessages.updateEmailListErrorMessage = action.error?.message;
    });
    builder.addCase(updateEmailListReducer.fulfilled, (state, action) => {
      state.statuses.updateEmailListStatus = asyncStates.SUCCESS;
      state.errorMessages.updateEmailListErrorMessage = null;
    });

    // start handling delete file async calls
    builder.addCase(deleteEmailListReducer.pending, (state, action) => {
      state.statuses.deleteEmailListStatus = asyncStates.PENDING;
      state.errorMessages.deleteEmailListErrorMessage = null;
    });
    builder.addCase(deleteEmailListReducer.rejected, (state, action) => {
      state.statuses.deleteEmailListStatus = asyncStates.FAILURE;
      state.errorMessages.deleteEmailListErrorMessage = action.error?.message;
    });
    builder.addCase(deleteEmailListReducer.fulfilled, (state, action) => {
      state.statuses.deleteEmailListStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteEmailListErrorMessage = null;
    });
  },
});

export const {
  clearAllLocalEmailListReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
} = emailListSlice.actions;

export default emailListSlice.reducer;
