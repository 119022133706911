import ApiService from "../../restapi";

//Get Invite Data from the OobCode
export const getStaffInviteDataApiService = async ({ oobCode }) => {
  return await ApiService.sendRequest({
    url: `/staff/invites/${oobCode}`,
    query: {},
    method: "GET",
    authOptional: true
  });
};

//Get All Staff Data
export const getAllStaffApiService = async ({ limit, startAfter, query, teams, userId }) => {
  return await ApiService.sendRequest({
    url: `/staff`,
    query: {
      limit: limit,
      startAfter: startAfter,
      q: query,
      teams: teams,
      userId: userId
    },
    method: "GET",
    headers: {}
  });
};

//Get Single Staff Data
export const getStaffApiService = async ({
  staffId,
}) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}`,
    query: {},
    method: "GET",
    headers: {},
  })
}

//Update the Staff
export const updateStaffApiService = async (staffData, id) => {
  return await ApiService.sendRequest({
    url: `/staff/${id}`,
    query: {},
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: staffData
  })
}

//Create Staff
export const createStaffApiService = async ({ email, jobRole, role, teams, refId }) => {
  return await ApiService.sendRequest({
    url: `/staff/invites`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      email: email,
      jobRole: jobRole,
      role: role,
      teams: teams,
      refId: refId,
    },
  });
};

// Delete Staff
export const deleteStaffApiService = async ({
  staffId,
}) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};

export const getStaffProfileApiServices = async ({ staffId }) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/profile`,
    query: {},
    method: "GET",
    headers: {},
    body: {}
  })
}