import { useDispatch } from "react-redux";
import {
  getAllEMARsReducer,
  clearAllErrorMessagesReducer,
  clearAllStatusReducer,
  clearAllSuccessMessagesReducer,
} from "../../slices/eMARSlices";

const useEMARDispatchers = () => {
  const dispatch = useDispatch();

  const getAllEMARs = (data) => {
    return dispatch(getAllEMARsReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllStatusMessages = (data) => {
    return dispatch(clearAllStatusReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  return {
    getAllEMARs,
    clearAllErrorMessages,
    clearAllStatusMessages,
    clearAllSuccessMessages,
  };
};

export default useEMARDispatchers;
