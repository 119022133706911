import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  clearAllLocalClientAccessReducer,
  clearAllSuccessMessagesReducer,
  createClientAccessReducer,
  deleteClientAccessReducer,
  getAllClientAccessReducer,
  resendClientAccessReducer,
} from "../../slices/clientAccessSlice";

const useClientAccessDispatchers = () => {
  const dispatch = useDispatch();

  const createClientAccess = (data) => {
    return dispatch(createClientAccessReducer(data));
  };

  const resendClientAccess = (data) => {
    return dispatch(resendClientAccessReducer(data));
  };

  const getClientAccess = (data) => {
    return dispatch(getAllClientAccessReducer(data));
  };

  const deleteClientAccess = (data) => {
    return dispatch(deleteClientAccessReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const clearAllSuccessMessages = (data) => {
    return dispatch(clearAllSuccessMessagesReducer(data));
  };

  const clearAllLocalClientAccess = (data) => {
    return dispatch(clearAllLocalClientAccessReducer(data));
  };

  return {
    createClientAccess,
    resendClientAccess,
    getClientAccess,
    clearAllErrorMessages,
    deleteClientAccess,
    clearAllSuccessMessages,
    clearAllLocalClientAccess,
  };
};

export default useClientAccessDispatchers;
