import ApiService from "../../restapi";

export const createCarePlanApiService = async ({
  careplanName,
  urgency,
  need,
  goal,
  interventions,
  services,
  medicines,
  clientId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/careplans`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      careplanName: careplanName,
      urgency: urgency,
      need: need,
      goal: goal,
      interventions: interventions,
      services: services,
      medicines: medicines,
    },
  });
};

export const getAllCarePlanApiService = async ({
  clientId,
  startAfter,
  limit,
  isArchived,
}) => {
  console.log(clientId);
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/careplans`,
    query: {
      startAfter,
      limit: limit,
      isArchived: isArchived,
    },
    method: "GET",
    headers: {},
  });
};

export const getSingleCarePlanApiService = async ({
  clientId,
  carePlanId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}}/careplans/${carePlanId}`,
    query: {},
    method: "GET",
    headers: {},
  });
};

export const updateCarePlanApiService = async ({
  careplanName,
  urgency,
  need,
  goal,
  interventions,
  services,
  medicines,
  carePlanId,
  clientId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}}/careplans/${carePlanId}`,
    query: {},
    method: "PUT",
    headers: {},
    body:{
      careplanName: careplanName,
      urgency: urgency,
      need: need,
      goal: goal,
      interventions: interventions,
      services: services,
      medicines: medicines,
    },
  });
};

export const deleteCarePlanApiService = async ({
  clientId,
  carePlanId
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}}/careplans/${carePlanId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};

export const restoreArchivedCareplanApiService = async ({
  clientId,
  id,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}}/careplans/${id}/restore`,
    query: {},
    method: "PUT",
    headers: {},
  })
}