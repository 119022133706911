import React from 'react'
import { Box, ErrorAndSuccess, FlexBox } from '../../Components/Boxes'
import { Grid, GridContainer } from '../../Components/Grid'
import { useState } from 'react'
import { getBusinessSubscriptionDetailsApiService, getBusinessSubscriptionLoginPortalApiService } from '../../services/applicationBackendApis/subscrptions'
import { localStorageService } from '../../utiles/storage'
import { useEffect } from 'react'
import { useBusinessAccountSelector } from '../../redux/selectors/useBusinessAccountSelectors'
import { useMemo } from 'react'
import { SUBSCRIPTION_STATUS } from '../../constants'
import { Button } from '../../Components/Input'
import { SpanText, Text } from '../../Components/Text'
import Icon from '../../Components/Icons'

export default function Subscriptions() {
  const [pricingTableId, setPricingTableId] = useState()
  const [publishableKey, setPublishableKey] = useState()
  const [clientReferenceId, setClientReferenceId] = useState()
  const [customerEmail, setCustomerEmail] = useState()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const businessAccount = useBusinessAccountSelector()

  const getSubscriptionDetails = async () => {
    const response = await getBusinessSubscriptionDetailsApiService({
      id: localStorageService.getItem("businessAccountId")
    })
    const details = response.data
    setPricingTableId(details.pricingTableId)
    setPublishableKey(details.publishableKey)
    setClientReferenceId(details.clientReferenceId)
    setCustomerEmail(details.customerEmail)
  }

  const goToPortal = async () => {
    setLoading(true)
    try {
      const response = await getBusinessSubscriptionLoginPortalApiService({
        id: localStorageService.getItem("businessAccountId")
      }).catch((err) => {
        console.log(err)
        throw new Error(err?.data?.error?.message || "Error");
      })

      window.open(response.data.loginPortalLink, "_self")
      setErrorMessage(null)
    } catch (error) {
      setErrorMessage(error.message)
    }

    setLoading(false)
  }

  useEffect(() => {
    getSubscriptionDetails()
  }, [])

  const viewSubscriptionTable = useMemo(() => {
    if (businessAccount?.subscriptionDetails?.subscriptionsEnabled) {
      if (businessAccount?.subscriptionDetails?.stripeSubscriptionStatus == SUBSCRIPTION_STATUS.CANCELLED) {
        return true
      }
      return !businessAccount?.subscriptionDetails?.stripeSubscriptionId && pricingTableId && publishableKey && clientReferenceId
    }
    return false
  }, [businessAccount, pricingTableId, publishableKey, clientReferenceId])

  const isSubscriptionActive = useMemo(() => {
    if (businessAccount?.subscriptionDetails?.subscriptionsEnabled) {

      return [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.TRIALING].includes(businessAccount?.subscriptionDetails?.stripeSubscriptionStatus)
    }
    return false
  }, [businessAccount])

  const subscriptionHasProblems = useMemo(() => {
    if (businessAccount?.subscriptionDetails?.subscriptionsEnabled) {
      if (isSubscriptionActive) {
        if ((businessAccount?.subscriptionDetails?.quantityPurchased ?? 1) < (businessAccount.totalNumStaff ?? 0)) {
          return true
        }
      }
      return [
        SUBSCRIPTION_STATUS.PAST_DUE,
        SUBSCRIPTION_STATUS.CANCELLED,
        SUBSCRIPTION_STATUS.INCOMPLETE,
        SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED,
        SUBSCRIPTION_STATUS.PAUSED,
        SUBSCRIPTION_STATUS.UNPAID
      ].includes(businessAccount?.subscriptionDetails?.stripeSubscriptionStatus)

    }

    return false
  }, [businessAccount, isSubscriptionActive])

  const subscriptionMessage = useMemo(() => {
    if (businessAccount?.subscriptionDetails?.subscriptionsEnabled) {
      const subscriptionStatus = businessAccount?.subscriptionDetails?.stripeSubscriptionStatus
      if (subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
        if ((businessAccount?.subscriptionDetails?.quantityPurchased ?? 1) < (businessAccount.totalNumStaff ?? 0)) {
          return "You have more staff than your subscription allows, please update your subscription to match the number of staff you have."
        }
        return "Subscription active. To view more details, click the \"Manage subscription\" button"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.CANCELLED) {
        return "Subscription cancelled, click the \"Manage subscription\" button to reactivate"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.PAST_DUE) {
        return "Subscription past due, click the \"Manage subscription\" button to update payment details"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.TRIALING) {
        return "Currently in your trial period. To view more details, click the \"Manage subscription\" button"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.INCOMPLETE) {
        return "Subscription incomplete, click the \"Manage subscription\" button to update payment details"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED) {
        return "Subscription incomplete, click the \"Manage subscription\" button to update payment details"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.PAUSED) {
        return "Subscription paused, click the \"Manage subscription\" button to reactivate"
      } else if (subscriptionStatus == SUBSCRIPTION_STATUS.UNPAID) {
        return "Subscription unpaid, click the \"Manage subscription\" button to update payment details"
      } else {
        return "Subscription enabled. To view more details, click the \"Manage subscription\" button"
      }
    }

    return "Subscriptions disabled";

  }, [businessAccount])

  return (
    <>
      <GridContainer $spacing={3}>
        <Grid $xs={12} $sm={6}>
          <Box
            $width={"100%"}
            $minHeight={"70vh"}
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding="2"
          >
            {viewSubscriptionTable && <stripe-pricing-table
              style={{ width: "100%" }}
              pricing-table-id={pricingTableId}
              publishable-key={publishableKey}
              client-reference-id={clientReferenceId}
              customer-email={customerEmail}
            >
            </stripe-pricing-table>}

            {!viewSubscriptionTable && (isSubscriptionActive || subscriptionHasProblems) && <Box
              $display="flex"
              $flexDirection="column"
              $width="100%"
              $alignItems="center"
            >
              <Icon $icon="Person" $size="100" $color="primary" />
              <Text $fontSize="heading-1">
                {businessAccount?.subscriptionDetails?.quantityPurchased}
              </Text>
              <SpanText $fontSize="body" $fontWeight="normal"> Members of your team can be added to your subscription. </SpanText>
              <hr style={{ width: "100%" }} />

              <ErrorAndSuccess $type={isSubscriptionActive ? "success" : "error"}>{subscriptionMessage}</ErrorAndSuccess>
            </Box>}
          </Box>
        </Grid>
        <Grid $xs={12} $sm={6}>
          <Box
            $width={"100%"}
            $backgroundColor="#fff"
            $borderRadius="10px"
            $padding="2"
          >
            <Box $marginTop="5" />
            <Text $fontSize="body" $marginBottom={3} $width="100%" $textAlign={"center"}>
              To manage your subscription click the button below
            </Text>
            <Box $marginTop="5" />
            {errorMessage && <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>}
            {errorMessage && <Box $marginTop="5" />}
            <Button
              $width={"100%"}
              $marginRight="5"
              $onClick={goToPortal}
              $loading={loading}
              $disabled={viewSubscriptionTable}
            >
              Manage Subscription
            </Button>
            <Box $marginTop="5" />
          </Box>
        </Grid>
      </GridContainer>
    </>
  )
}
