import * as React from "react";
import Button from "@mui/material/Button";
import CircularLoader from "../Icons/circularLoader";

export default function BasicButtons({
  $color,
  $size,
  $onClick,
  $sx,
  $type,
  $endIcon,
  $variant,
  $loading,
  $width,
  $disabled,
  children,
}) {
  return (
    <Button
      variant={$variant ?? "contained"}
      loading={$loading}
      color={$color}
      size={$size}
      onClick={!$loading ? $onClick : null}
      sx={$sx}
      type={$type}
      endIcon={$endIcon}
      fullWidth={$width ? true : false}
      disabled={$disabled}
    >
      {$loading && <CircularLoader $color={$variant == "contained" ? "white" : null} $size={25} />}
      {!$loading && children}
    </Button>
  );
}
