import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogonWithName from "../../Images/Logos/gps_care_logo.png";
import DefaultImage from "../../Components/Images/index";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { AuthContext } from "../../contextAPI's/authContext";
import BasicAlerts from "../../Components/Boxes/ErrorAndSuccess";
import { Box, FlexBox } from "../../Components/Boxes/index";
import CircularLoader from "../../Components/Icons/circularLoader";
import { useResponsive } from "../../Components/Hooks/index";
import { color } from "../../Components/Theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color("primary")};
`;

const LoginWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 10px;
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Login() {

  const isDesktop = useResponsive({ xs: false, md: true });
  //auth context
  const { loading, loginError, Login } = useContext(AuthContext);

  //States
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  //event change func
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //handle submit func
  const handleSubmit = async (e) => {
    e.preventDefault();
    Login(values);
  };

  return (
    <Wrapper>
      <LoginWrapper>
        <DefaultImage $width={"20%"} $src={LogonWithName} $objectFit="contain" />
        <br />
        <br />

        <form
          onSubmit={handleSubmit}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: isDesktop ? "80%" : "90%",
            // border:"1px solid red"
          }}
        >
          <TextField
            id="email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            label="Email"
            required
            fullWidth
          />
          <br />

          <FormControl
            variant="outlined"
            style={{
              width: "100%",
            }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              required
            />
          </FormControl>
          <br />

          {!loading ? (
            <Button type="submit" variant="contained" size="large">
              Login
            </Button>
          ) : (
            <Button variant="contained" size="large" disabled>
              <CircularLoader $color="white" $size={25} />
            </Button>
          )}
        </form>
        {loginError && (
          <FlexBox $marginTop={2}>
            <BasicAlerts $type="error">{loginError}</BasicAlerts>
          </FlexBox>
        )}
        <br />
        <Link to="/auth/sendpasswordreset">Forgot your password? Reset here</Link>
      </LoginWrapper>
    </Wrapper>
  );
}
