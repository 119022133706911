import React, { useEffect } from 'react'
import styled from "styled-components"
import { Box, FlexBox } from '../../Boxes'
import CalendarControlls from './CalendarControlls'
import Grid from "../../Grid/Grid"
import GridContainer from '../../Grid/GridContainer'
import ScheduleBlockContainer, { ScheduleBlock } from './ScheduleBlockContainer'
import { Text, SpanText } from '../../Text'
import { useRef } from 'react'
import Icon from '../../Icons'
import { REPETITION_PERIODS } from '../../../constants'
import { useMemo } from 'react'
import { arrayEmpty } from '../../../utiles/validators'
import { useResponsive } from '../../Hooks'
import { formateDateToTime, formatteDateToDayOfWeek, getPreviousMondayDate } from '../../../utiles/dateformate'
import { DropDownButton } from '../../Input'
import { DropDownMenuItem } from '../../Modals'

const Wrapper = styled.div`
    width: 100%;
`

const TextMaxLengthWrapper = styled.span`
    width: 100%;
    /* white-space: nowrap;
    overflow-wrap: break-word;
    text-overflow: ellipsis; */
`

export default function ScheduleCalendar(props) {
  const {
    onNavigate,
    selectedDate,
    onSelectEvent,
    calendarView,
    events,
    backgroundEvents,
    unAssignedEvents,
    scrollToTime,
    loading,
    label,
    onStyle,
    actions, // [{ title, value }]
    onActionClick,
    actionLoading,
  } = props
  const isDesktop = useResponsive({ xs: false, md: true });

  const selectedCalendarView = useMemo(() => {
    return calendarView ?? REPETITION_PERIODS.DAY
  }, [calendarView])

  const processedEvents = useMemo(() => {
    // TODO: Check there are no clashes
    // TODO: If no clashes then return
    // TODO: If there are clashes then order clashing events in terms of which goes first in ordering
    const tempUnAssignedArray = events?.map(e => {
      return {
        ...e,
        events: e?.events?.map(event => {
          return {
            ...event,
            startTime: (event.startDate.getHours() * 60) + event.startDate.getMinutes(),
            endTime: (event.endDate.getHours() * 60) + event.endDate.getMinutes(),
          }
        })
      }
    })
    return tempUnAssignedArray || []
  }, [events])

  const processedBackgroundEvents = useMemo(() => {
    const tempUnAssignedArray = backgroundEvents?.map(e => {
      return {
        ...e,
        events: e?.events?.map(event => {
          return {
            ...event,
            startTime: (event.startDate.getHours() * 60) + event.startDate.getMinutes(),
            endTime: (event.endDate.getHours() * 60) + event.endDate.getMinutes(),
          }
        })
      }
    })
    return tempUnAssignedArray || []
  }, [backgroundEvents])


  const processedUnassignedEvents = useMemo(() => {
    if (!Array.isArray(unAssignedEvents)) return []
    const tempUnAssignedArray = []
    for (let i = 0; i < unAssignedEvents.length; i++) {
      tempUnAssignedArray.push([{
        ...unAssignedEvents[i],
        startTime: (unAssignedEvents[i].startDate.getHours() * 60) + unAssignedEvents[i].startDate.getMinutes(),
        endTime: (unAssignedEvents[i].endDate.getHours() * 60) + unAssignedEvents[i].endDate.getMinutes(),
      }])
    }

    return tempUnAssignedArray
  }, [unAssignedEvents])

  const daysOfWeek = useMemo(() => {
    if (calendarView == REPETITION_PERIODS.WEEK) {
      const prevMonday = getPreviousMondayDate(selectedDate)
      return [0, 1, 2, 3, 4, 5, 6].map(i => {
        const d = new Date(`${prevMonday}`)
        d.setDate(d.getDate() + i)
        return d
      })
    }
    return []
  }, [selectedDate])

  const scheduleHeight = 40
  const minuteWidth = 2
  const hourWidth = minuteWidth * 60

  const scrollRef = useRef()

  const getPostionedTime = ({
    event,
    startTime,
    endTime,
    userIndex,
    title,
    borderRadius = 10,
    onClick,
  }) => {
    const duration = endTime - startTime
    return (
      <Box
        $width={duration * minuteWidth}
        $height={scheduleHeight}
        $paddingTop={"2px"}
        $paddingBottom={"2px"}
        $position="absolute"
        $left={minuteWidth * startTime}
        $top={((userIndex + 1) * scheduleHeight)}
        $justifyContent="center"
        $onClick={onClick}
      >
        <FlexBox
          $width={"100%"}
          $height={scheduleHeight - 4}
          $backgroundColor={getEventStyle(event)?.backgroundColor ?? "secondary"}
          $justifyContent="center"
          $borderRadius={borderRadius}
        >
          {title && <Text $textOverflow="ellipsis" $color="white">
            <TextMaxLengthWrapper> {title} </TextMaxLengthWrapper>
          </Text>}
        </FlexBox>
      </Box>
    )
  }

  useEffect(() => {
    if (calendarView == REPETITION_PERIODS.DAY) {
      if (scrollToTime) {
        scrollRef.current.scrollBy((hourWidth * scrollToTime.getHours()) + (minuteWidth * scrollToTime.getMinutes()), 1000)
      } else {
        scrollRef.current.scrollBy((hourWidth * 8) + (minuteWidth * 10), 100)
      }
    }
  }, [scrollToTime])

  const getEventStyle = (event) => {
    if (onStyle) return onStyle(event)
    return null
  }

  return (
    <Wrapper>
      <Box $padding={isDesktop ? "0 25px" : "3px"}>
        <GridContainer>
          <Grid $xs={12} $sm={12}>
            <CalendarControlls
              availableViews={[REPETITION_PERIODS.DAY, REPETITION_PERIODS.WEEK]}
              currentView={selectedCalendarView}
              date={selectedDate}
              onViewChanged={(view) => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                onNavigate(newDate, view)
              }}
              onPrev={() => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                  newDate.setDate(newDate.getDate() - 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                  newDate.setDate(newDate.getDate() - 7)
                } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                  newDate.setMonth(newDate.getMonth() - 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                  newDate.setFullYear(newDate.getFullYear() - 1)
                }

                onNavigate(newDate, selectedCalendarView)
              }}
              onNext={() => {
                const newDate = selectedDate ? new Date(`${selectedDate}`) : new Date()
                if (selectedCalendarView == REPETITION_PERIODS.DAY) {
                  newDate.setDate(newDate.getDate() + 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.WEEK) {
                  newDate.setDate(newDate.getDate() + 7)
                } else if (selectedCalendarView == REPETITION_PERIODS.MONTH) {
                  newDate.setMonth(newDate.getMonth() + 1)
                } else if (selectedCalendarView == REPETITION_PERIODS.YEAR) {
                  newDate.setFullYear(newDate.getFullYear() + 1)
                }
                onNavigate(newDate, selectedCalendarView)
              }}
            />
          </Grid>
          <Grid $xs={12}>
            <FlexBox $width="100%" $justifyContent="flex-end" $marginBottom="4">
              <DropDownButton
                $sx={{ borderRadius: 2 }}
                $marginRight={3}
                $loading={actionLoading}
                $disabled={actions && actions?.length > 0 ? false : true}
              >
                {actions?.map(action => {
                  return (
                    <DropDownMenuItem
                      $onClick={() => {
                        if (onActionClick) {
                          onActionClick(action?.value)
                        }
                      }}
                    >
                      {action?.label && <Text $fontSize={"body"} $marginLeft="10px" $color="primary">
                        {action?.label}
                      </Text>}
                    </DropDownMenuItem>
                  )
                })}
              </DropDownButton>
            </FlexBox>
          </Grid>
        </GridContainer>
      </Box>

      <div style={{ margin: "10px 0px" }} />

      {calendarView == REPETITION_PERIODS.WEEK && <Box $maxWidth="100vw" $paddingLeft="5" $paddingRight="5">
        <GridContainer>
          <Grid $xs={2}>
            <ScheduleBlockContainer $height={"100%"}>
              {label && <Text $fontSize="body" $fontWeight="bold" $paddingLeft="2">{label}</Text>}
            </ScheduleBlockContainer>
          </Grid>
          <Grid $xs={10}>
            <FlexBox>
              {daysOfWeek?.map(date => {
                return (
                  <ScheduleBlockContainer $height="100%" $width="100%">
                    <Box $width="100%">
                      <Text $width="100%" $textAlign="center" $fontSize="body">
                        {formatteDateToDayOfWeek(date)}
                      </Text>
                      <Text $width="100%" $textAlign="center" $fontSize="body">
                        {date.getDate()}
                      </Text>
                    </Box>
                  </ScheduleBlockContainer>
                )
              })}
            </FlexBox>
          </Grid>
        </GridContainer>
        {(!processedEvents || processedEvents.length <= 0) && <ScheduleBlockContainer $width={"100%"} $height={scheduleHeight}>
          <Text $width="100%" $textAlign="center">No Events found</Text>
        </ScheduleBlockContainer>}

        {processedEvents?.map(userData => {
          return (
            <GridContainer>
              <Grid $xs={2}>
                <ScheduleBlockContainer $height="100%">
                  {userData?.user?.name && <Text $fontSize="body" $fontWeight="bold" $paddingLeft="2">{userData?.user?.name}</Text>}
                </ScheduleBlockContainer>
              </Grid>
              <Grid $xs={10}>
                <FlexBox $height="100%">
                  {daysOfWeek?.map(date => {
                    // get events in this date range
                    const eventsInDateRange = userData?.events?.filter(eventData => {
                      return eventData.startDate.getDate() == date.getDate() && eventData.startDate.getMonth() == date.getMonth() && eventData.startDate.getFullYear() == date.getFullYear()
                    })
                    return (
                      <ScheduleBlockContainer $height="100%" $width="100%">
                        {eventsInDateRange && eventsInDateRange.length > 0 && <Box $width="100%" $height="100%">
                          {eventsInDateRange?.map(event => {
                            return (
                              <FlexBox
                                $width={"100%"}
                                $height={scheduleHeight}
                                $justifyContent="center"
                                $onClick={() => {
                                  onSelectEvent(event)
                                }}
                              >
                                <FlexBox
                                  $width={"95%"}
                                  $height={scheduleHeight - 4}
                                  $backgroundColor={getEventStyle(event)?.backgroundColor ?? "secondary"}
                                  $borderRadius={10}
                                >
                                  {event.startDate && <SpanText $color="white" $fontWeight="bold" $marginRight="1" $marginLeft="1"> <TextMaxLengthWrapper>{formateDateToTime(event.startDate)}</TextMaxLengthWrapper> </SpanText>}
                                  {event.startDate && <SpanText $color="white" $fontWeight="bold" $marginRight="1" $marginLeft="1"> <TextMaxLengthWrapper> : </TextMaxLengthWrapper> </SpanText>}
                                  {event.title && <SpanText $color="white"><TextMaxLengthWrapper>{event.title} </TextMaxLengthWrapper></SpanText>}
                                </FlexBox>
                              </FlexBox>
                            )
                          })}
                        </Box>}
                      </ScheduleBlockContainer>
                    )
                  })}
                </FlexBox>
              </Grid>
            </GridContainer>
          )
        })}
      </Box>}

      {
        calendarView == REPETITION_PERIODS.DAY && <Box $maxWidth="100vw" $paddingLeft="5" $paddingRight="5">
          <GridContainer>
            <Grid $xs={2}>
              <Box $width="100%" $horizontalScroll $minHeight="100vh">
                <ScheduleBlockContainer $height={scheduleHeight}>
                  {label && <Text $fontSize="body" $fontWeight="bold" $paddingLeft="2">{label}</Text>}
                </ScheduleBlockContainer>
                {processedEvents?.map(event => {
                  return (
                    <ScheduleBlockContainer $height={scheduleHeight}>{event?.user?.name}</ScheduleBlockContainer>
                  )
                })}

                {(!processedEvents || processedEvents.length <= 0) && <ScheduleBlockContainer $height={scheduleHeight + 2} $border="none" />}

                <ScheduleBlockContainer $height={scheduleHeight + 2} $border="none" />
                <ScheduleBlockContainer $height={scheduleHeight + 2} $border="none" />

                <ScheduleBlockContainer $height={scheduleHeight} $backgroundColor="primary">
                  <Text $color="white" $fontSize="body" $fontWeight="bold" $paddingLeft="2">
                    Unfilled events
                  </Text>
                </ScheduleBlockContainer>

              </Box>
            </Grid>
            <Grid $xs={10}>
              <Box $position="relative">
                {/* Time boxes */}
                <Box $horizontalScroll $showScrollBar $position="relative" $ref={scrollRef} /*$minHeight="70vh"*/ >
                  <table style={{ border: 'none', borderSpacing: 0 }} cellspacing="0">
                    <tr style={{ border: 'none' }} cellspacing="0">
                      <ScheduleBlockContainer $height={scheduleHeight}>
                        {Array.from(Array(24), (e, i) => {
                          return (
                            <ScheduleBlock $width={hourWidth - (i <= 0 ? minuteWidth * 5 : 0)} $height={scheduleHeight} $borderRight="none">
                              {`${i}`}
                            </ScheduleBlock>
                          )
                        })}
                      </ScheduleBlockContainer>
                    </tr>

                    {processedEvents?.map(event => {
                      return (
                        <tr style={{ border: 'none' }} cellspacing="0">
                          <ScheduleBlockContainer $height={scheduleHeight}>
                            {Array.from(Array(24), (e, i) => {
                              return (
                                <ScheduleBlock $width={hourWidth} $height={scheduleHeight} />
                              )
                            })}
                          </ScheduleBlockContainer>
                        </tr>
                      )
                    })}

                    {arrayEmpty(processedEvents) && <ScheduleBlockContainer $height={scheduleHeight}>
                      <Text>No event found</Text>
                    </ScheduleBlockContainer>}

                    <ScheduleBlockContainer $height={scheduleHeight + 2} $border="none" />
                    <ScheduleBlockContainer $height={scheduleHeight + 2} $border="none" />

                    {processedUnassignedEvents.length > 0 && <ScheduleBlockContainer $height={scheduleHeight} $backgroundColor="primary" />}

                    {processedUnassignedEvents?.map(event => {
                      return (
                        <tr style={{ border: 'none' }} cellspacing="0" height={scheduleHeight}>
                          <ScheduleBlockContainer $height={scheduleHeight}>
                            {Array.from(Array(24), (e, i) => {
                              return (
                                <ScheduleBlock $width={hourWidth} $height={scheduleHeight} />
                              )
                            })}
                          </ScheduleBlockContainer>
                        </tr>
                      )
                    })}

                    {arrayEmpty(processedUnassignedEvents) && <ScheduleBlockContainer $width={hourWidth * 24} $height={scheduleHeight}>
                      <Text>No unfilled events found</Text>
                    </ScheduleBlockContainer>}
                  </table>

                  {/* Background events */}
                  {processedBackgroundEvents?.map((event, index) => {
                    return event.events?.map(e => {
                      return getPostionedTime({
                        event: e,
                        startTime: e.startTime,
                        endTime: e.endTime,
                        userIndex: index,
                        title: e.title,
                        borderRadius: 0,
                        onClick: () => {
                          onSelectEvent(e)
                        }
                      })
                    })
                  })}

                  {/* Events assigned */}
                  {processedEvents?.map((event, index) => {
                    return event.events?.map(e => {
                      return getPostionedTime({
                        event: e,
                        startTime: e.startTime,
                        endTime: e.endTime,
                        userIndex: index,
                        title: e.title,
                        onClick: () => {
                          onSelectEvent(e)
                        }
                      })
                    })
                  })}

                  {processedUnassignedEvents?.map((unAssignedeventsList, index) => {

                    return unAssignedeventsList?.map(e => {
                      return getPostionedTime({
                        event: e,
                        startTime: e.startTime,
                        endTime: e.endTime,
                        userIndex: index + 3 + (processedEvents?.length || 1),
                        title: e.title,
                        onClick: () => {
                          onSelectEvent(e)
                        }
                      })
                    })
                  })}

                  <Box $height={20} />

                </Box>

                <FlexBox $justifyContent="space-between" $position="absolute" $top="0" $width="100%" $height={scheduleHeight}>
                  <Box $height={scheduleHeight} $paddingLeft={5}>
                    <Icon $icon="KeyboardArrowLeft" $onClick={() => { scrollRef.current.scrollBy(-hourWidth, 100) }} $pointer="pointer" />
                  </Box>

                  <Box $height={scheduleHeight} $paddingRight={5}>
                    <Icon $icon="KeyboardArrowRight" $onClick={() => { scrollRef.current.scrollBy(hourWidth, 100) }} $pointer="pointer" />
                  </Box>
                </FlexBox>
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      }
    </Wrapper >
  )
}
