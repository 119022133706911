import { useSelector } from "react-redux";

export const useAllComplianceSelector = () => useSelector((state) => {
  return state.compliance.allCompliance;
});

export const useComplianceUploadPercentageSelector = () => useSelector((state) => {
  return state.compliance.complianceUploadPercentage;
});

// ------------------- selector error messages ------------------ //
export const useComplianceCreateErrorMessageSelector = () => useSelector((state) => {
  return state.compliance.errorMessages.createComplianceErrorMessage;
});

export const useComplianceGetErrorMessageSelector = () => useSelector((state) => {
  return state.compliance.errorMessages.getAllComplianceErrorMessage;
});

export const useComplianceDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.compliance.errorMessages.deleteComplianceErrorMessage;
});

export const useComplianceUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.compliance.errorMessages.updateComplianceErrorMessage;
});

export const useComplianceRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.compliance.errorMessages.restoreComplianceErrorMessage;
});

// ------------ status selectors ------------ //
export const useComplianceCreateStatusSelector = () => useSelector((state) => {
  return state.compliance.statuses.createComplianceStatus;
});

export const useAllComplianceGetStatusSelector = () => useSelector((state) => {
  return state.compliance.statuses.getAllComplianceStatus;
});

export const useComplianceDeleteStatusSelector = () => useSelector((state) => {
  return state.compliance.statuses.deleteComplianceStatus;
});

export const useComplianceUpdateStatusSelector = () => useSelector((state) => {
  return state.compliance.statuses.updateComplianceStatus;
});

export const useMoreComplianceItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.compliance.moreComplianceItemsLeftInPagination
})

export const useComplianceRestoreStatusSelector = () => useSelector((state) => {
  return state.compliance.statuses.restoreComplianceStatus;
});