import { useMemo } from "react";
import { Text } from "../../../Components/Text";
import { POLICY_STATUS } from "../../../constants";

export const PolicyStatus = ({ status }) => {
    const policystatusMap = useMemo(() => {
        return {
            [POLICY_STATUS.ACCEPTED]: "green",
            [POLICY_STATUS.OPENED]: "orange",
            [POLICY_STATUS.CREATED]: "red",
        };
    }, []);

    const policyStatusNameMap = useMemo(() => {
        return {
            [POLICY_STATUS.ACCEPTED]: "Accepted",
            [POLICY_STATUS.OPENED]: "Opened",
            [POLICY_STATUS.CREATED]: "Created",
        };
    }, []);

    return (
        <Text
            $fontWeight="bold"
            $backgroundColor={policystatusMap[status] ?? "gray"}
            $borderRadius={'5px'}
            $padding={'2px'}
            $paddingLeft={'10px'}
            $paddingRight={'10px'}
            $color='white'
        >
            {policyStatusNameMap[status] ?? status}
        </Text>
    );
}