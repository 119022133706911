import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCarePlanApiService,
  deleteCarePlanApiService,
  getAllCarePlanApiService,
  restoreArchivedCareplanApiService,
  updateCarePlanApiService,
} from "../../../services/applicationBackendApis/clientCarePlan";
import * as asyncStates from "../../constants/asyncStates";
import { PAGINATION_LIMITS } from "../../../constants";

const initialState = {
  carePlans: [],
  carePlan: null,
  moreCareplanItemsLeftInPagination: false,
  errorMessages: {
    restoreCarePlanErrorMessage: null,
    createCarePlanErrorMessage: null,
    deleteCarePlanErrorMessage: null,
    updateCarePlanErrorMessage: null,
    getCarePlanErrorMessage: null,
  },
  statuses: {
    restoreCarePlanStatus: null,
    createCarePlanStatus: null,
    deleteCarePlanStatus: null,
    updateCarePlanStatus: null,
    getCarePlansStatus: null,
  },
  sucessMessages: {
    restoreCarePlanSuccessMessage: null,
    updateCarePlanSuccessMessage: null,
  },
};

//------- getting all careplan from database ------------\\
export const getAllCarePlanReducer = createAsyncThunk(
  "CarePlan/getAllCarePlan",
  async (carePlanData) => {
    try {
      const response = await getAllCarePlanApiService({
        clientId: carePlanData.clientId,
        startAfter: carePlanData.startAfter,
        limit: carePlanData.limit ?? PAGINATION_LIMITS.CAREPLAN,
        isArchived: carePlanData.isArchived,
      });
      return {
        data: response.data,
        limit: carePlanData.limit ?? PAGINATION_LIMITS.CAREPLAN,
        startAfter: carePlanData.startAfter,
      }
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

//------------- creating new risk Assessment ---------------\\
export const createCarePlanReducer = createAsyncThunk(
  "carePlan/createCarePlanReducer",
  async (carePlanData) => {
    try {
      const response = await createCarePlanApiService({
        careplanName: carePlanData.careplanName,
        urgency: carePlanData.urgency,
        need: carePlanData.need,
        goal: carePlanData.goal,
        interventions: carePlanData.interventions,
        services: carePlanData.services,
        medicines: carePlanData.medicines,
        clientId: carePlanData.clientId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const restoreArchivedCarePlanReducer = createAsyncThunk(
  "carePlan/restoreArchivedCarePlan",
  async (carePlanData) => {
    try {
      const response = await restoreArchivedCareplanApiService({
        clientId: carePlanData.clientId,
        id: carePlanData.carePlanId,
      });
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const updateCarePlanReducer = createAsyncThunk(
  "carePlan/updateCarePlanReducer",
  async (carePlanData) => {
    try {
      const response = await updateCarePlanApiService({
        careplanName: carePlanData.careplanName,
        urgency: carePlanData.urgency,
        need: carePlanData.need,
        goal: carePlanData.goal,
        interventions: carePlanData.interventions,
        services: carePlanData.services,
        medicines: carePlanData.medicines,
        carePlanId: carePlanData.carePlanId,
        clientId: carePlanData.clientId,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

export const deleteCarePlanReducer = createAsyncThunk(
  "carePlan/deleteCarePlanReducer",
  async (carePlanData) => {
    try {
      const response = await deleteCarePlanApiService({
        clientId: carePlanData.clientId,
        carePlanId: carePlanData.carePlanId,
      });
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw new Error(error?.data?.error?.message || "Error");
    }
  }
);

const carePlanSlice = createSlice({
  name: "carePlanSlice",
  initialState,
  // reducers that do not require api call go here in reducers
  reducers: {
    clearAllStatusesReducer: (state, action) => {
      state.statuses = {};
    },
    clearAllLocalCarePlansReducer: (state, action) => {
      state.carePlans = [];
    },
    clearAllErrorMessagesReducer: (state, action) => {
      state.errorMessages = {};
    },
    clearAllSuccessMessagesReducer: (state, action) => {
      state.sucessMessages = {};
    },
  },
  // reducers that require api calls go here in extra reducers
  extraReducers: (builder) => {
    // start handling create file async calls
    builder.addCase(createCarePlanReducer.pending, (state, action) => {
      state.statuses.createCarePlanStatus = asyncStates.PENDING;
      state.errorMessages.createCarePlanErrorMessage = null;
    });
    builder.addCase(createCarePlanReducer.rejected, (state, action) => {
      state.statuses.createCarePlanStatus = asyncStates.FAILURE;
      state.errorMessages.createCarePlanErrorMessage = action.error?.message;
    });
    builder.addCase(createCarePlanReducer.fulfilled, (state, action) => {
      state.statuses.createCarePlanStatus = asyncStates.SUCCESS;
      state.errorMessages.createCarePlanErrorMessage = null;
    });

    builder.addCase(restoreArchivedCarePlanReducer.pending, (state, action) => {
      state.statuses.restoreCarePlanStatus = asyncStates.PENDING;
      state.errorMessages.restoreCarePlanErrorMessage = null;
    });
    builder.addCase(restoreArchivedCarePlanReducer.rejected, (state, action) => {
      state.statuses.restoreCarePlanStatus = asyncStates.FAILURE;
      state.errorMessages.restoreCarePlanErrorMessage =
        action.error?.message;
    });
    builder.addCase(restoreArchivedCarePlanReducer.fulfilled, (state, action) => {
      state.statuses.restoreCarePlanStatus = asyncStates.SUCCESS;
      state.errorMessages.restoreCarePlanErrorMessage = null;
    });

    // start handling update file async calls
    builder.addCase(updateCarePlanReducer.pending, (state, action) => {
      state.statuses.updateCarePlanStatus = asyncStates.PENDING;
      state.errorMessages.updateCarePlanErrorMessage = null;
    });
    builder.addCase(updateCarePlanReducer.rejected, (state, action) => {
      state.statuses.updateCarePlanStatus = asyncStates.FAILURE;
      state.errorMessages.updateCarePlanErrorMessage = action.error?.message;
    });
    builder.addCase(updateCarePlanReducer.fulfilled, (state, action) => {
      state.statuses.updateCarePlanStatus = asyncStates.SUCCESS;
      state.errorMessages.updateCarePlanErrorMessage = null;
      state.sucessMessages.updateCarePlanSuccessMessage =
        action.payload.message;
    });

    // start handling get files async calls
    builder.addCase(getAllCarePlanReducer.pending, (state, action) => {
      state.statuses.getCarePlansStatus = asyncStates.PENDING;
      state.errorMessages.getCarePlansErrorMessage = null;
    });
    builder.addCase(getAllCarePlanReducer.rejected, (state, action) => {
      state.statuses.getCarePlansStatus = asyncStates.FAILURE;
      state.errorMessages.getCarePlansErrorMessage = action.error?.message;
    });
    builder.addCase(getAllCarePlanReducer.fulfilled, (state, action) => {
      state.statuses.getCarePlansStatus = asyncStates.SUCCESS;
      state.errorMessages.getCarePlansErrorMessage = null;
      state.carePlans = action.payload.startAfter ? [...state.carePlans, ...action.payload.data.careplans] : action.payload.data.careplans;

      if (action.payload.data.careplans.length >= action.payload.limit) {
        state.moreCareplanItemsLeftInPagination = true;
      } else {
        state.moreCareplanItemsLeftInPagination = false;
      }
    });

    // start handling delete file async calls
    builder.addCase(deleteCarePlanReducer.pending, (state, action) => {
      state.statuses.deleteCarePlanStatus = asyncStates.PENDING;
      state.errorMessages.deleteCarePlanErrorMessage = null;
    });
    builder.addCase(deleteCarePlanReducer.rejected, (state, action) => {
      state.statuses.deleteCarePlanStatus = asyncStates.FAILURE;
      state.errorMessages.deleteCarePlanErrorMessage = action.error?.message;
    });
    builder.addCase(deleteCarePlanReducer.fulfilled, (state, action) => {
      state.statuses.deleteCarePlanStatus = asyncStates.SUCCESS;
      state.errorMessages.deleteCarePlanErrorMessage = null;
    });
  },
});

export const {
  clearAllErrorMessagesReducer,
  clearAllStatusesReducer,
  clearAllLocalCarePlansReducer,
  clearAllSuccessMessagesReducer,
} = carePlanSlice.actions;

export default carePlanSlice.reducer;
