import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  ErrorAndSuccess,
  FlexBox,
  ListBox,
} from "../../../../../Components/Boxes";
import { useResponsive } from "../../../../../Components/Hooks";
import Icon from "../../../../../Components/Icons";
import { Button } from "../../../../../Components/Input";
import FormDialog from "../../../../../Components/Modals/FormDialog";
import { Text } from "../../../../../Components/Text";
import { formateDateToReadableFormatWithoutTime } from "../../../../../utiles/dateformate";
import { EditRiskAssessment } from "../Dialogs/RiskAssessmnet/EditRiskAssessment";
import { NewVersion } from "../Dialogs/RiskAssessmnet/NewVersion";
import MuiSkeletonLoader from "../../../../../Components/LoadingSkeleton/MuiSkeletonLoader";
import useRiskAssessmentDispatchers from "../../../../../redux/dispatchers/useRiskAssessmentDispatchers";
import {
  useMoreRiskAssessmentsLeftInPaginationSelector,
  useRiskAssessmentsGetErrorMessageSelector,
  useRiskAssessmentsGetStatusSelector,
  useRiskAssessmentsSelector,
} from "../../../../../redux/selectors/useRiskAssessmentSlector";
import * as asyncStates from "../../../../../redux/constants/asyncStates";
import EmptyState from "../../../../../Components/NoRecordFound";
import Tabs from "../../../../../Components/Input/Tabs";
import usePermissionsController from "../../../../../permissions";

const Files = ({ clientId }) => {

  // -------------- selectors -------------------
  const riskAssessments = useRiskAssessmentsSelector();
  const riskAssessmentGetStatus = useRiskAssessmentsGetStatusSelector();
  const riskAssessmentGetErrorMessage =
    useRiskAssessmentsGetErrorMessageSelector();
  const errorMessage = riskAssessmentGetErrorMessage;
  const moreRiskAssessmentsLeftInPagination = useMoreRiskAssessmentsLeftInPaginationSelector()

  // -------------- dispatchers -------------------
  const { getRiskAssessments, clearAllLocalRiskAssessments } =
    useRiskAssessmentDispatchers();

  // -------------- states -----------------------
  const [addRiskAssessmentDialogState, setAddriskAssessmentDialog] =
    useState(false);
  const [editRiskAssessmentDialogstate, setEditRiskAssessmentState] =
    useState(false);
  /*storing the slected file and sending to dialog */
  const [riskAssessmentToEdit, setRiskAssessmentToEdit] = useState(null);
  const [isArchived, setIsArchived] = useState(false)
  const {
    accessArchives
  } = usePermissionsController()

  // -------------- functions -------------------
  function addingNewRiskAssessmentDialog() {
    setAddriskAssessmentDialog(!addRiskAssessmentDialogState);
  }

  function EditRiskAssessmentDialog() {
    setEditRiskAssessmentState(!editRiskAssessmentDialogstate);
  }
  // -------------- context api -------------------

  const isDesktop = useResponsive({ xs: false, sm: true });

  const loadMore = () => {
    getRiskAssessments({
      clientId: clientId,
      startAfter: riskAssessments[riskAssessments.length - 1].id,
      isArchived: isArchived,
    });
  }

  useEffect(() => {
    clearAllLocalRiskAssessments();
    getRiskAssessments({
      clientId: clientId,
      isArchived: isArchived,
    });
  }, [isArchived]);

  return (
    <Box
      $width="100%"
      $backgroundColor="#fff"
    >
      <FlexBox $justifyContent={accessArchives ? "space-between" : "flex-end"} $width="100%" $marginTop={2}>
        {accessArchives && <Tabs
          $onChanged={(val) => {
            if (val == 0) {
              setIsArchived(false)
            } else {
              setIsArchived(true)
            }
          }}
          $value={isArchived ? 1 : 0}
          $tabs={[
            { name: "Active" },
            { name: "Archived" },
          ]}
          $width={"fit-content"}
        />}
        <Button
          $onClick={() => {
            addingNewRiskAssessmentDialog();
          }}
        >
          <Icon $icon="Add" $color="white"></Icon>
          <Text $color={"white"}>Create new versions</Text>
        </Button>
      </FlexBox>

      <FlexBox $width="100%" $marginTop={3} $padding="3">
        <Box $width="30%">
          <Text $fontWeight="bold">
            Risk Assessment
          </Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Rating</Text>
        </Box>
        <Box $width="30%" $display="flex" $justifyContent="center">
          <Text>Assessment Date</Text>
        </Box>
        <Box $width="20%" $display="flex" $justifyContent="center">
          <Text>Next Review Date</Text>
        </Box>
      </FlexBox>

      {riskAssessmentGetStatus === asyncStates.SUCCESS &&
        riskAssessments.length < 1 ? (
        <FlexBox $marginTop={7} $width="100%" $justifyContent="center">
          <EmptyState />
        </FlexBox>
      ) : (
        riskAssessments?.map((value, index) => {
          return (
            <ListBox
              key={index}
              $width="100%"
              $index={index}
              $onClick={() => {
                EditRiskAssessmentDialog();
                setRiskAssessmentToEdit(value);
              }}
              $marginTop="2"
            >
              <FlexBox
                $width="100%"
              >
                <Box $width="30%">
                  <Text $fontWeight="bold">
                    {value.name}
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <Text
                    $fontWeight="bold"
                    $color={
                      value.riskRating === "HIGH"
                        ? "red"
                        : value.riskRating === "MED"
                          ? "orange"
                          : value.riskRating === "LOW"
                            ? "green"
                            : "black"
                    }
                  >
                    {value.riskRating}
                  </Text>
                </Box>
                <Box $width="30%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {formateDateToReadableFormatWithoutTime(value.assessmentDate)}
                  </Text>
                </Box>
                <Box $width="20%" $display="flex" $justifyContent="center">
                  <Text $fontWeight="bold">
                    {formateDateToReadableFormatWithoutTime(value.nextReviewDate)}
                  </Text>
                </Box>
              </FlexBox>
            </ListBox>
          );
        })
      )}

      {riskAssessmentGetStatus === asyncStates.PENDING &&
        [1, 2, 3, 4].map((value, index) => {
          return (
            <Box
              $width="100%"
              $marginTop="2"
              key={index}
            >
              <MuiSkeletonLoader
                $variant="rectangular"
                $width={"100%"}
                $height={"40px"}
              />
            </Box>
          );
        })}

      {errorMessage && (
        <FlexBox $marginTop={2} $width="100%">
          <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
        </FlexBox>
      )}

      {moreRiskAssessmentsLeftInPagination && <FlexBox $marginTop="6">
        <Button $onClick={() => {
          loadMore()
        }} >Load More</Button>
      </FlexBox>}

      {addingNewRiskAssessmentDialog && (
        <FormDialog
          $open={addRiskAssessmentDialogState}
          $handleDialog={addingNewRiskAssessmentDialog}
          $title={"Risk Assessment"}
          $maxWidth="sm"
          $fullWidth
          $fullScreen={!isDesktop}
        >
          <NewVersion $handleDialog={addingNewRiskAssessmentDialog} clientId={clientId} />
        </FormDialog>
      )}
      {EditRiskAssessmentDialog && (
        <FormDialog
          $open={editRiskAssessmentDialogstate}
          $handleDialog={EditRiskAssessmentDialog}
          $title={"Risk Assessment"}
          $maxWidth="sm"
          $fullWidth
          $fullScreen={!isDesktop}
        >
          <EditRiskAssessment
            $handleDialog={EditRiskAssessmentDialog}
            $riskAssessmentToEdit={riskAssessmentToEdit}
            clientId={clientId}
          />
        </FormDialog>
      )}
    </Box>
  );
};

export default Files;
