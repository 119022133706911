import React, { useState } from 'react'
import { Box, ErrorAndSuccess, FlexBox } from '../../../Components/Boxes'
import { Button, TextBox } from '../../../Components/Input'
import { Text } from '../../../Components/Text'
import CircularLoader from '../../../Components/Icons/circularLoader'
import { useResponsive } from '../../../Components/Hooks'

export default function AddDefaultQualificationsDialog(props) {
    const isDesktop = useResponsive({ xs: false, sm: true });
    const {
        defaultQualification: editingDefaultQualification,
        loading,
        errorMessage,
        onDelete,
        onSave,
    } = props

    const [name, setName] = useState(editingDefaultQualification ? editingDefaultQualification.name : null)
    const [grade, setGrade] = useState(editingDefaultQualification ? editingDefaultQualification.grade : null)

    return (
        <>
            <FlexBox
                $flexDirection="column"
                $alignItems="flex-start"
                $padding={5}
                $marginRight={3}
            >
                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Name
                </Text>
                <TextBox
                    $hint={"Health And Safety"}
                    $type={"text"}
                    $name={"text"}
                    $value={name}
                    $onChange={(e) => setName(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />

                <Text $fontSize="body" $marginBottom={1} $marginTop={2}>
                    Default Grade
                </Text>
                <TextBox
                    $hint={"Pass/Completed"}
                    $type={"text"}
                    $name={"text"}
                    $value={grade}
                    $onChange={(e) => setGrade(e.target.value)}
                    $width="100%"
                    $borderRadius="8px"
                    $border="1px solid black"
                />
            </FlexBox>

            <Box
                $padding={2}
            >
                {errorMessage && (
                    <ErrorAndSuccess $type="error">{errorMessage}</ErrorAndSuccess>
                )}
            </Box>

            <FlexBox
                $justifyContent={!editingDefaultQualification ? "flex-end" : "space-between"}
                $padding={2}
                $backgroundColor="list"
            >
                {editingDefaultQualification && <Button
                    $color={"error"}
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onDelete()
                    }}
                >
                    {!loading ? 'Delete' : <CircularLoader $color="white" $size={25} />}
                </Button>}
                
                <Button
                    $sx={{ borderRadius: 2 }}
                    $marginRight={3}
                    $onClick={async () => {
                        await onSave({
                            name,
                            grade,
                        })
                    }}
                >
                    {!loading ? 'Save' : <CircularLoader $color="white" $size={25} />}
                </Button>
            </FlexBox>
        </>
    )
}
