import { useEffect } from "react"
import { useDiariesSelector, useDiariesGetStatusSelector, useMoreDirayItemsLeftInPaginationSelector, useDiaryCreateStatusSelector, useDiaryCreateErrorMessageSelector } from "../../../../redux/selectors/useClientsDiarySelector"
import useClientDiaryDispatchers from "../../../../redux/dispatchers/useClientDiaryDispatchers"

export function useClientDiaryController(clientId) {
    const diaries = useDiariesSelector()
    const getDiariesState = useDiariesGetStatusSelector()

    const createDiaryErrorMessage = useDiaryCreateErrorMessageSelector()
    const createDiaryState = useDiaryCreateStatusSelector()

    const { getDiaries, clearAllErrorMessages, clearAllLocalDiaries, clearAllStatuses, createDiary } = useClientDiaryDispatchers()

    const moreDirayItemsLeftInPaginationSelector = useMoreDirayItemsLeftInPaginationSelector()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllLocalDiaries()
        clearAllStatuses()
        clearAllLocalDiaries()
        getDiaries({
            clientId: clientId,
        })
    }, [])

    const loadMore = () => {
        getDiaries({
            clientId: clientId,
            startAfter: diaries[diaries.length - 1].id
        })
    }

    const createDiaryEntry = async ({ note, users, }) => {
        await createDiary({
            notes: note,
            additionalStaff: users,
            clientId: clientId,
        }).unwrap()
        getDiaries({
            clientId: clientId,
        })
    }

    return {
        diaries,
        getDiariesState,
        isStaff: false,
        isClient: true,
        moreDirayItemsLeftInPaginationSelector,
        
        loadMore,

        createDiaryEntry,
        createDiaryState,
        createDiaryErrorMessage,
    }
}