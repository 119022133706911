import ApiService from "../../restapi";

export const getStaffDiaryApiService = async ({ staffId, startAfter, limit }) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/diary`,
    query: {
      startAfter: startAfter,
      limit: limit,
    },
    method: "GET",
    headers: {},
  });
};

export const createStaffDiaryEntryApiService = async ({ staffId, notes, additionalStaff }) => {
  return await ApiService.sendRequest({
    url: `/staff/${staffId}/diary`,
    query: {},
    method: "POST",
    headers: {},
    body: {
      notes: notes,
      additionalStaff: additionalStaff,
    },
  });
};