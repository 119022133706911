import {
  DefaultSpacingModifiers,
  DefaultSizeModifiers,
  DefaultTextModifiers,
} from "../Modifiers";
import { color} from "../Theme";
import styled from "styled-components";

const Input = styled.textarea`
  color: ${color("black")};
  border: 1px solid black;
  border-radius: 8px;
  background-color:white;
  outline: none;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  ${(props) => props.disabled ? `
    background-color: #E0E0E099;
  ` : ''}
`;

export default function Textarea({$onChange, $hint, $name, $value, $rows, $cols, $disabled, ...rest }) {
  return (
    <Input
      style={{
        ...DefaultSpacingModifiers({ $padding: 2, ...rest }),
        ...DefaultSizeModifiers({ $width: "100%", ...rest }),
        ...DefaultTextModifiers(rest),
      }}
      name={$name}
      value={$value}
      placeholder={$hint}
      onChange={$onChange}
      rows={$rows}
      cols={$cols}
      disabled={$disabled}
    />
  );
}
