import React, { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from ".";
import { DropDownMenu, DropDownMenuItem } from "../Modals";
import Icon from "../Icons";
import { Text } from "../Text";
import CircularLoader from "../Icons/circularLoader";

export default function DropDownButton({
    children,
    $title,
    $loading,
    $disabled,
    $icon,
    $contentPadding,
    ...rest
}) {
    const [anchor, setAnchor] = useState(null)
    const [open, setOpen] = useState(false)
    return (
        <>
            {!$icon && <Button
                {...rest}
                $loading={$loading}
                $variant={"outlined"}
                $endIcon={<KeyboardArrowDownIcon />}
                $disabled={$disabled}
                $onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setAnchor(e.currentTarget);
                    setOpen(true)
                }}
            >
                <Text $color={$disabled ? '#ccc' : null} $fontWeight="bold" $padding={$contentPadding ?? "0px 20px 0px 20px"}>
                    {$title ?? "Actions"}
                </Text>
            </Button>}
            {$icon && <>
                {$loading && <CircularLoader $size={25} />}
                {!$loading && <Icon
                    $onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setAnchor(e.currentTarget);
                        setOpen(true)
                    }}
                    $icon={ typeof $icon == "boolean" ? "MoreVert" : $icon || "MoreVert"}
                />}
            </>}
            {open && (
                <DropDownMenu
                    $onClose={() => {
                        setAnchor(null);
                        setOpen(false);
                    }}
                    $open={open}
                    $anchor={anchor}
                >
                    {children}
                </DropDownMenu>
            )}
        </>
    );
}
