import * as CustomIcon from "@mui/icons-material";
import {
  DefaultFlexModifiers,
  DefaultSpacingModifiers,
  DefaultSizeModifiers,
  DefaultBackgroundModifier,
  DefaultPositionModifiers,
  DefaultRadiusModifiers,
} from "../Modifiers/index.js";
import { useResponsive } from "../Hooks/index.js";
import { color } from "../Theme/index.js";

const IconProperties = ({ $onClick, $size, $pointer, ...rest }) => {
  return {
    style: {
      color: color(useResponsive(rest.$color)),
      fontSize: useResponsive($size),
      ...DefaultPositionModifiers(rest),
      ...DefaultSpacingModifiers(rest),
      ...DefaultFlexModifiers(rest),
      ...DefaultSizeModifiers(rest),
      ...DefaultBackgroundModifier(rest),
      ...DefaultRadiusModifiers(rest),
    },
    onClick: $onClick,
    cursor: $pointer,
  };
};

export default function Icon({ ...rest }) {
  const FinalIcon = CustomIcon[rest.$icon];
  return <FinalIcon {...IconProperties(rest)} />;
}
