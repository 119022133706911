import ApiService from "../../restapi";

//Creating the Client
export const createClientApiService = async (data) => {
  return await ApiService.sendRequest({
    url: `/clients`,
    query: {},
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: data
  })
}

//Getting all Clients
export const getAllClientsApiService = async ({ startAfter, limit, query, teams }) => {
  return await ApiService.sendRequest({
    url: `/clients`,
    query: {
      limit: limit,
      startAfter: startAfter,
      q: query,
      teams: teams,
    },
    method: "GET",
    headers: {},
  })
}

//Updating all Clients
export const updateClientApiService = async (data, id) => {
  return await ApiService.sendRequest({
    url: `/clients/${id}`,
    query: {},
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: data
  })
}

//Get Single Client Data
export const getSingleClientApiService = async ({
  clientId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}`,
    query: {},
    method: "GET",
    headers: {},
  })
}

//Delete Client
export const deleteClientApiService = async ({
  clientId,
}) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}`,
    query: {},
    method: "DELETE",
    headers: {},
  });
};

export const sendStaffProfile = async ({ clientId, staffId }) => {
  return await ApiService.sendRequest({
    url: `/clients/${clientId}/profile/staff/${staffId}`,
    query: {},
    method: "POST",
    headers: {},
    body: {}
  })
}