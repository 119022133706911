import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetEventCheckListsErrorMessageSelector, useGetEventCheckListsStatusSelector, useEventCheckListsSelector, useEventCheckListsUpdateStatusSelector, useUpdateEventCheckListsErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useEventCheckListsSettingsController() {
    const { accessAddSettingsEventCheckLists, accessEditSettingsEventCheckLists } = usePermissionsController()
    const eventCheckListNames = useEventCheckListsSelector()
    const getEventCheckListsErrorMessages = useGetEventCheckListsErrorMessageSelector()
    const updateEventCheckListsErrorMessages = useUpdateEventCheckListsErrorMessageSelector()

    const getEventCheckListsStatuses = useGetEventCheckListsStatusSelector()
    const updateEventCheckListsStatuses = useEventCheckListsUpdateStatusSelector()

    const names = useMemo(() => [...eventCheckListNames], [eventCheckListNames])

    const {
        updateEventCheckLists,
        getEventCheckLists,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getEventCheckLists()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateEventCheckLists({
            eventCheckLists: newNames
        }).unwrap();
        getEventCheckLists()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateEventCheckLists({
            eventCheckLists: newNames
        }).unwrap();
        getEventCheckLists()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateEventCheckLists({
            eventCheckLists: newNames
        }).unwrap();
        getEventCheckLists()
        return
    }

    return {
        title: "Shift Checklists",
        names: names,

        canEdit: accessEditSettingsEventCheckLists,
        canAdd: accessAddSettingsEventCheckLists,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateEventCheckListsStatuses == asyncStates.PENDING,
        isDeletingConfig: updateEventCheckListsStatuses == asyncStates.PENDING,
        isGettingConfigs: getEventCheckListsStatuses == asyncStates.PENDING,

        updateErrorMessage: updateEventCheckListsErrorMessages,
        deleteErrorMessage: updateEventCheckListsErrorMessages,
        gettingErrorMessage: getEventCheckListsErrorMessages,
    }
}