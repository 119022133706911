import React, { useState } from 'react'
import FormDialog from './FormDialog'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'

export default function ConfimationDialog({
    $title,
    $description,
    $onClose,
    $onConfirm,
    $enterReason,
}) {
    const [reason, setReason] = useState("")
    return (
        <div>
            <Dialog
                open={true}
                maxWidth={"sm"}
                fullWidth={"100%"}
                onClose={$onClose}
            >
                <DialogTitle>{$title ?? "Confirmation"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {$description ?? "Are you sure?"}
                    </DialogContentText>
                    {$enterReason && <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Reasons"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={reason}
                        onChange={(e) => {
                            setReason(e.target.value)
                        }}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={$onClose}>Cancel</Button>
                    <Button onClick={() => $onConfirm({ reason })}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
