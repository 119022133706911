import { useDispatch } from "react-redux";
import {
  clearAllErrorMessagesReducer,
  getAllQualificationReducer,
  createQualificationReducer,
  deleteQualificationReducer,
  updateQualificationReducer,
  clearAllLocalQualificationsReducer,
  restoreArchivedQualificationReducer,
} from "../../slices/qualificationsSlice";

const useQualificationDispathers = () => {
  const dispatch = useDispatch();

  const createQualification = (data) => {
    return dispatch(createQualificationReducer(data));
  };

  const restoreArchivedQualification = (data) => {
    return dispatch(restoreArchivedQualificationReducer(data))
  }

  const getAllQualification = (data) => {
    return dispatch(getAllQualificationReducer(data));
  };

  const deleteQualification = (data) => {
    return dispatch(deleteQualificationReducer(data));
  };

  const clearAllErrorMessages = (data) => {
    return dispatch(clearAllErrorMessagesReducer(data));
  };

  const updateQualification = (data) => {
    console.log(data);
    return dispatch(updateQualificationReducer(data));
  };

  const clearAllLocalQualification = (data) => {
    return dispatch(clearAllLocalQualificationsReducer(data));
  };

  return {
    restoreArchivedQualification,
    getAllQualification,
    createQualification,
    deleteQualification,
    clearAllErrorMessages,
    updateQualification,
    clearAllLocalQualification,
  };
};

export default useQualificationDispathers;
