import React from 'react'
import TimesheetView from '../Team/Timesheets/TimesheetView'
import { Grid, GridContainer } from '../../Components/Grid'
import { FlexBox } from '../../Components/Boxes'

export default function TimeSheetsPage() {
  return (
    <>
      <FlexBox
        $width={"100%"}
        $minHeight={"70vh"}
        $backgroundColor="#fff"
        $borderRadius="10px"
        $flexDirection="column"
        $padding="2"
      >
        <TimesheetView />
      </FlexBox>
    </>
  )
}
