import React from 'react'
import { Box, FlexBox } from '../../Boxes'

export default function ScheduleBlockContainer(props) {
    const {
        children,
        $height,
        $backgroundColor,
        $border,
        $width,
        ...rest
    } = props
    return (
        <FlexBox
            $height={typeof $height == "string" ? $height : $height - 2}
            $border={$border || "1px solid grey"}
            $backgroundColor={$backgroundColor}
            $width={$width}
            {...rest}
        >
            {children}
        </FlexBox>
    )
}

export function ScheduleBlock(props) {
    const { children, $height, $width, $backgroundColor, $borderRight } = props
    return (
        <td height={$height} width={$width - 2} cellspacing="0">
            <Box $width={$width - 2} $height={$height} $backgroundColor={$backgroundColor} $borderRight={$borderRight || 'solid 1px grey'}>
                {children}
            </Box>
        </td>
    )
}