import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
// import Stack from "@mui/material/Stack";

export default function MuiSkeletonLoader({ $variant, $width, $height, $sx }) {
  return (
    // <Stack spacing={1} direction="column">
    <Skeleton variant={$variant} width={$width} height={$height} sx={$sx} />
    // </Stack>
  );
}
