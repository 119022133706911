import TextField from "@mui/material/TextField";

export default function BasicTextFields({
  $onClick,
  $onChange,
  $value,
  $name
}) {
  return (
    <TextField
      variant="standard"
      onClick={$onClick}
      onChange={$onChange}
      value={$value}
      name={$name}
    />
  );
}
