import React, { useCallback, useState } from "react";
import { useResponsive } from "../Hooks/index";
import { Box, FlexBox, CircleBox } from "../Boxes/index";
import Icon from "../Icons/index";
import Address from "./ProfileCardAddress";
import { Text } from "../Text/index";
import FormDialog from "../Modals/FormDialog";
import { formateDateToReadableFormatWithoutTime } from "../../utiles/dateformate";
import { ClientEditDialog } from "../../Pages/Team/Clients/Profile/Dialogs/ClientEditDialog";
import { StaffEditDialog } from "../../Pages/Team/Staff/Profile/Dialogs/EditStaffDialog";
import Contact from "./ProfileCardContact";
import { color } from "../Theme";
import usePermissionsController from "../../permissions";
import ProfilePhoto from "../Images/ProfilePhoto";
import { Button } from "../Input";
import { SearchUserSelectBox } from "../../Pages/components/searchSelectBox";
import { useSendStaffProfileErrorMessageSelector, useSendStaffProfileStatusSelector, useSendStaffProfileSuccessSelector } from "../../redux/selectors/useClientSelectors";
import useClientDispatchers from "../../redux/dispatchers/useClientDispatchers";
import { sleepForMilliseconds } from "../../utiles/timers";
import * as asyncStates from "../../redux/constants/asyncStates";
import CircularLoader from "../Icons/circularLoader";
import BasicAlerts from "../Boxes/ErrorAndSuccess";
import { useLoggedInStaffId } from "../../hooks";

export function ProfileCard({ data, type, $onImageChanged, $imageLoading }) {
  const isDesktop = useResponsive({ xs: false, sm: true });
  const [open, setOpen] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const { staffId: loggedInStaffId } = useLoggedInStaffId()
  function HandleDialog() {
    setOpen(!open);
  }
  const {
    clientDoctorDetailsSpecified,
    clientNextOfKinDetailsSpecified,
    clientTherapistDetailsSpecified,
    clientSocialworkerDetailsSpecified,
    accessSendStaffProfile,
    clientEmailSpecified,
    clientRefIdSpecified,
    clientClientNotesSpecified,
    clientDOBSpecified,
    clientGenderSpecified,
    clientPhoneNumberSpecified,
    staffJobRoleSpecified,

    editStaff,
    editOwnStaff,
    editClient,
  } = usePermissionsController()

  const canEditProfile = useCallback(() => {
    if (type === "ClientData") return editClient == true

    if (type === "StaffData") {
      if (editStaff) return true
      if (editOwnStaff && loggedInStaffId == data?.id) return true
    }
    return false
  }, [type, editStaff, editOwnStaff, loggedInStaffId, data?.id])

  const sendStaffProfileErrorMessage = useSendStaffProfileErrorMessageSelector()
  const sendStaffProfileStatus = useSendStaffProfileStatusSelector()
  const sendStaffProfileSuccessMessage = useSendStaffProfileSuccessSelector()

  const {
    clearAllSuccessMessages,
    clearAllStatusMessages,
    clearAllErrorMessages,
    sendStaffProfile
  } = useClientDispatchers()


  const sendStaffProfileHandler = async () => {
    await sendStaffProfile({
      staffId: selectedUser.id,
      clientId: data.id
    }).unwrap()
    await sleepForMilliseconds(1000 * 5)
    clearAllErrorMessages()
    clearAllStatusMessages()
    clearAllSuccessMessages()
    setSelectedUser(null)
    setSearchUser(null)
  }

  return (
    <>
      <FlexBox
        $maxWidth={isDesktop ? "30%" : "95%"}
        $width="100%"
        $flexDirection="column"
        $border="1px solid #E6E6E6"
        $borderRadius="8px"
        $marginRight={isDesktop ? 2 : 0}
      >
        <FlexBox
          $justifyContent="space-between"
          $alignItems="center"
          $width="100%"
          $height="38px"
          $backgroundColor="primary-light"
          $borderTopLeftRadius="8px"
          $borderTopRightRadius="8px"
        >
          <Text $color="primary" $fontSize={"heading-5"} $marginLeft={2}>
            {data?.name}
          </Text>
          {canEditProfile() && <Icon
            $icon="ModeEdit"
            $pointer="pointer"
            $size={20}
            $marginLeft={"6px"}
            $marginRight={2}
            $onClick={() => {
              HandleDialog();
            }}
          />}
        </FlexBox>

        <FlexBox
          $borderBottom="1px dashed black"
          $width="100%"
          // $alignItems="center"
          $minHeight="80px"
        >
          <Box $marginLeft={3} $backgroundColor="white">
            <ProfilePhoto $loading={$imageLoading} $url={data?.profilePhotoUrl} $onImageChanged={$onImageChanged} />
          </Box>
          <Box $marginLeft={2} $backgroundColor="white" $padding={3}>
            {clientEmailSpecified && <Text
              $color="text"
              $fontSize="body-sm"
              $fontWeight="600"
              $wordWrap="anywhere"
            >
              {data?.email}
            </Text>}

            {clientPhoneNumberSpecified && <Text $color="text" $fontSize="body-sm" $fontWeight="600">
              {data?.contact ? (
                <Contact data={data?.contact} />
              ) : (
                "Contact not provided"
              )}
            </Text>}
          </Box>
        </FlexBox>

        <FlexBox
          $width="100%"
          $alignItems="center"
          $justifyContent="center"
          $flexDirection="column"
          $marginTop={3}
          $paddingBottom={3}
          $borderBottom="1px dashed black"
        >
          {[
            type === "StaffData" && staffJobRoleSpecified ? {
              data: "Job Role",
              value: `${data?.jobRole ? data?.jobRole : "Not Provided"}`,
            } : null,
            ((type === "ClientData" && clientRefIdSpecified) || type != "ClientData") ? {
              data: "Ref ID",
              value: `${data?.refId ? data?.refId : "Not Provided"}`,
            } : null,
            ((type === "ClientData" && clientGenderSpecified) || type != "ClientData") ? {
              data: "Gender",
              value: `${data?.gender ? data?.gender : "Not Provided"}`,
            } : null,
            ((type === "ClientData" && clientDOBSpecified) || type != "ClientData") ? {
              data: "DOB",
              value: data?.dateOfBirth ? formateDateToReadableFormatWithoutTime(data?.dateOfBirth) : "Not Provided",
            } : null,
            {
              data: "Address",
              value: (
                <div style={{ display: "inline-block" }}>
                  {data?.address ? (
                    <Address data={data?.address} />
                  ) : (
                    "Not Provided"
                  )}
                </div>
              ),
            },/**doctorDetails nextOfKin therapistDetails socialWorkerContacts */
            type === "ClientData" && clientDoctorDetailsSpecified ? {
              data: "Doctor Details",
              value: `${data?.doctorDetails ? data?.doctorDetails : "Not Provided"}`,
            } : null,
            type === "ClientData" && clientNextOfKinDetailsSpecified ? {
              data: "Next Of Kin",
              value: `${data?.nextOfKin ? data?.nextOfKin : "Not Provided"}`,
            } : null,
            type === "ClientData" && clientTherapistDetailsSpecified ? {
              data: "Therapist Details",
              value: `${data?.therapistDetails ? data?.therapistDetails : "Not Provided"}`,
            } : null,
            type === "ClientData" && clientSocialworkerDetailsSpecified ? {
              data: "Social Worker",
              value: `${data?.socialWorkerContacts ? data?.socialWorkerContacts : "Not Provided"}`,
            } : null,
          ].map((value, index) => {
            if (!value) return <div />
            return (
              <FlexBox
                $width="100%"
                $justifyContent="space-between"
                $padding={1}
                $marginTop={1}
              >
                <Box $marginLeft={3} $width="30%">
                  <Text
                    $color="text"
                    $fontSize="body-sm"
                    $fontWeight="600"
                    $marginLeft={2}
                  >
                    {value.data}
                  </Text>
                </Box>
                <Box $width="70%" $marginRight={1}>
                  <Text
                    $color="text"
                    $fontSize="body-sm"
                    $fontWeight="600"
                    $marginLeft={2}
                  >
                    {value.value}
                  </Text>
                </Box>
              </FlexBox>
            );
          })}
          {data?.teams && (
            <FlexBox
              $width="100%"
              $justifyContent="space-between"
              $marginTop={1}
            >
              <Box $marginLeft={4} $width="30%">
                <Text
                  $color="text"
                  $fontSize="body-sm"
                  $fontWeight="600"
                  $marginLeft={1}
                >
                  Team
                </Text>
              </Box>
              <Box $width="70%" $marginRight={1}>
                <FlexBox
                  $alignItems="flex-start"
                  $flexWrap="wrap"
                  $width="100%"
                >
                  {data?.teams.map((team, index) => {
                    return (
                      <FlexBox
                        key={index}
                        $backgroundColor={color("primary")}
                        $padding={"2px"}
                        $paddingLeft="8px"
                        $paddingRight="8px"
                        $marginRight="2px"
                        $borderRadius="10px"
                        $alignItems="center"
                        $justifyContent="center"
                        $width="fit-content"
                      >
                        <Text
                          key={index}
                          $color="white"
                          $fontWeight={"bolder"}
                          $fontSize="body-sm"
                        >
                          {team.name}
                        </Text>
                      </FlexBox>
                    );
                  })}
                </FlexBox>
              </Box>
            </FlexBox>
          )}
        </FlexBox>

        {data?.about && <FlexBox $width="100%">
          <Box $margin={2} $backgroundColor="#FEFF9C" $borderRadius="5px" $width="100%">
            <FlexBox $justifyContent="flex-end" $paddingBottom={1}>
              <Text $wordWrap="anywhere" $paddingLeft={2} $paddingRight={2} $paddingTop={1} $fontWeight={"bold"} $fontStyle="italic">About {type == "ClientData" ? "client" : "staff"}</Text>
            </FlexBox>
            <Text $wordWrap="anywhere" $paddingLeft={2} $paddingRight={2} $paddingBottom={3}>
              {data?.about}
            </Text>
          </Box>
        </FlexBox>}

        {clientClientNotesSpecified && data?.notes && <FlexBox $width="100%">
          <Box $margin={2} $backgroundColor="#FEFF9C" $borderRadius="5px" $width="100%">
            <FlexBox $justifyContent="flex-end" $paddingBottom={1}>
              <Text $wordWrap="anywhere" $paddingLeft={2} $paddingRight={2} $paddingTop={1} $fontWeight={"bold"} $fontStyle="italic">Client notes</Text>
            </FlexBox>
            <Text $wordWrap="anywhere" $paddingLeft={2} $paddingRight={2} $paddingBottom={3}>
              {data?.notes}
            </Text>
          </Box>
        </FlexBox>}

        {type === "ClientData" && accessSendStaffProfile && <Box $width="100%" $paddingTop={2} $paddingBottom={2} $borderTop="1px dashed black">
          <FlexBox>
            <Box $marginLeft={2} />
            {!searchUser && <Button $onClick={() => {
              setSearchUser(true)
            }}>Send staff profile</Button>}

            {searchUser && <Box $width="100%">
              <Text $fontSize="body" $marginBottom={1} >
                Staff
              </Text>

              {sendStaffProfileErrorMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
                <BasicAlerts $type="error">{sendStaffProfileErrorMessage}</BasicAlerts>
              </FlexBox>}

              {sendStaffProfileSuccessMessage && <FlexBox $width="100%" $justifyContent="center" $marginBottom={1}>
                <BasicAlerts $type="success">{sendStaffProfileSuccessMessage}</BasicAlerts>
              </FlexBox>}

              <FlexBox $width="100%">
                <SearchUserSelectBox
                  searchByStaff
                  selected={selectedUser}
                  onChange={(user) => {
                    setSelectedUser(user)
                  }}
                />
                {sendStaffProfileStatus == asyncStates.PENDING ? <Box $marginLeft={2} $marginRight={2}>
                  <CircularLoader $size={20} />
                </Box> : <Icon
                  $icon="Send"
                  $size={20}
                  $color={selectedUser ? "primary" : "grey"}
                  $pointer={selectedUser ? "pointer" : null}
                  $marginLeft={2}
                  $onClick={() => {
                    if (selectedUser) {
                      sendStaffProfileHandler()
                    }
                  }} />}
                <Box $marginLeft={2} />
              </FlexBox>
            </Box>}
          </FlexBox>
        </Box>}

      </FlexBox>
      <FormDialog
        $open={open}
        $handleDialog={HandleDialog}
        $title={"Update Details"}
        $maxWidth="sm"
        $fullWidth
        $fullScreen={!isDesktop}
      >
        {type === "ClientData" && (
          <ClientEditDialog
            $handleDialog={HandleDialog}
            $type="Clientedit"
            clientData={data}
          />
        )}
        {type === "StaffData" && (
          <StaffEditDialog
            $handleDialog={HandleDialog}
            staffData={data}
          />
        )}
      </FormDialog>
    </>
  );
}
