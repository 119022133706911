import { useSelector } from "react-redux";

export const useAllQualificationSelector = () => useSelector((state) => {
  return state.qualification.allQualification;
});

export const useQualificationUploadPercentageSelector = () => useSelector((state) => {
  return state.qualification.qualificationUploadPercentage;
});

// ------------------- selector error messages ------------------ //
export const useQualificationCreateErrorMessageSelector = () => useSelector((state) => {
  return state.qualification.errorMessages.createQualificationErrorMessage;
});

export const useQualificationGetErrorMessageSelector = () => useSelector((state) => {
  return state.qualification.errorMessages.getAllQualificationErrorMessage;
});

export const useQualificationDeleteErrorMessageSelector = () => useSelector((state) => {
  return state.qualification.errorMessages.deleteQualificationErrorMessage;
});

export const useQualificationUpdateErrorMessageSelector = () => useSelector((state) => {
  return state.qualification.errorMessages.updateQualificationErrorMessage;
});

export const useQualificationRestoreErrorMessageSelector = () => useSelector((state) => {
  return state.qualification.errorMessages.restoreQualificationErrorMessage;
});

// ------------ status selectors ------------ //
export const useQualificationCreateStatusSelector = () => useSelector((state) => {
  return state.qualification.statuses.createQualificationStatus;
});

export const useAllQualificationGetStatusSelector = () => useSelector((state) => {
  return state.qualification.statuses.getAllQualificationStatus;
});

export const useQualificationDeleteStatusSelector = () => useSelector((state) => {
  return state.qualification.statuses.deleteQualificationStatus;
});

export const useQualificationUpdateStatusSelector = () => useSelector((state) => {
  return state.qualification.statuses.updateQualificationStatus;
});

export const useMoreQualificationItemsLeftInPaginationSelector = () => useSelector((state) => {
  return state.qualification.moreQualificationItemsLeftInPagination
})

export const useQualificationRestoreStatusSelector = () => useSelector((state) => {
  return state.qualification.statuses.restoreQualificationStatus;
});