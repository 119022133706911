import { useEffect, useState, useMemo } from "react"
import useConfigsDispatchers from "../../../redux/dispatchers/useConfigsDispatchers"
import { useGetServicesErrorMessageSelector, useGetServicesStatusSelector, useServicesSelector, useServicesUpdateStatusSelector, useUpdateServicesErrorMessageSelector } from "../../../redux/selectors/useConfigsSelectors"
import * as asyncStates from "../../../redux/constants/asyncStates"
import usePermissionsController from "../../../permissions"

export function useServicesSettingsController() {
    const { accessAddSettingsTasks, accessEditSettingsTasks } = usePermissionsController()
    const serviceNames = useServicesSelector()
    const getServicesErrorMessages = useGetServicesErrorMessageSelector()
    const updateServicesErrorMessages = useUpdateServicesErrorMessageSelector()

    const getServicesStatuses = useGetServicesStatusSelector()
    const updateServicesStatuses = useServicesUpdateStatusSelector()

    const names = useMemo(() => [...serviceNames], [serviceNames])

    const {
        updateServices,
        getServices,
        clearAllErrorMessages,
        clearAllStatusMessages,
    } = useConfigsDispatchers()

    useEffect(() => {
        clearAllErrorMessages()
        clearAllStatusMessages()
        getServices()
    }, [])

    const onSave = async (val) => {
        const newNames = [...names]
        newNames.push(val)
        await updateServices({
            services: newNames
        }).unwrap();
        getServices()
        return
    }

    const onUpdate = async (prevValue, newValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames[index] = newValue
        }
        await updateServices({
            services: newNames
        }).unwrap();
        getServices()
        return
    }

    const onDelete = async (prevValue) => {
        const index = names.indexOf(prevValue)
        const newNames = [...names]
        if (index >= 0) {
            newNames.splice(index, 1);
        }
        await updateServices({
            services: newNames
        }).unwrap();
        getServices()
        return
    }

    return {
        title: "Tasks",
        names: names,

        canEdit: accessEditSettingsTasks,
        canAdd: accessAddSettingsTasks,

        onSave: onSave,
        onUpdate: onUpdate,
        onDelete: onDelete,

        isUpdatingConfig: updateServicesStatuses == asyncStates.PENDING,
        isDeletingConfig: updateServicesStatuses == asyncStates.PENDING,
        isGettingConfigs: getServicesStatuses == asyncStates.PENDING,

        updateErrorMessage: updateServicesErrorMessages,
        deleteErrorMessage: updateServicesErrorMessages,
        gettingErrorMessage: getServicesErrorMessages,
    }
}